// example axios wrapper
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon, ChevronUpIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { formatDistanceToThousandKm, formatPrice, getFormatDate } from '@/utils/common';

interface ResponseData {
  products: Products;
  productsImage: ProductsImages;
  manufacturerCategories: ManufacturerCategories;
  model: Model;
  accidentsHistory: AccidentsHistory;
  carOption: CarOption;
  maintenance: Maintenance;
}

const SimilarProductsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showItem, setShowItem] = useState<ShowItemState>({});
  const [similarProduct, setSimilarProduct] = useState<ProductDetailResponse[]>([]);
  const [responseData, setResponseData] = useState<ResponseData[]>([]);
  const params = location.state.queryParams;
  const truckName = location.state.truckName;

  const queryParam = new URLSearchParams(window.location.search);
  useEffect(() => {
    apiManager
      .get('/api/v1/products/similar?' + queryParam)
      .then((response) => {
        const responseData: ResponseData[] = response.data;
        setResponseData(responseData);
      })
      .catch((error) => {
        console.log('에러');
      });
  }, []);

  const toggleItem = (id: number) => {
    setShowItem((prevShowItem) => ({
      ...prevShowItem,
      [id]: !prevShowItem[id],
    }));
  };

  const showPrice = (item: ProductDetailResponse) => {
    return formatPrice(String(item?.actualSalePrice ? item.actualSalePrice : item?.price));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.append('year', params.year || '');
    queryParams.append('tons', params.tons || '');
    queryParams.append('manufacturerCategoriesId', params.manufacturerCategoriesId?.toString() || '');
    queryParams.append('modelId', params.modelId?.toString() || '');
    queryParams.append('loaded', params.loaded || '');
    queryParams.append('loadedInnerLength', params.loadedInnerLength || '');

    apiManager
      .get('/api/v1/products/similar?', { params: queryParams })
      .then((response) => {
        const responseData: ProductDetailResponse[] = response.data;
        setSimilarProduct(responseData);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <MenuHeader title="유사차량"></MenuHeader>
      <div className="w-full p-4 pb-24 pt-[60px]">
        <div className="mt-6">
          <span className="bg-gray-100 rounded-lg p-2">{truckName}</span> 와 유사한 차량
        </div>
        {similarProduct.length !== 0 && (
          <h2 className="text-lg font-semibold mt-6 mb-3 pt-6">{similarProduct.length}건</h2>
        )}
        {similarProduct &&
          similarProduct.map((item, index) => (
            <div key={index}>
              <ul className="mb-3.5 min-w-[312px] min-h-[160px]">
                <li
                  className={`flex relative border border-gray-3 p-4 items-center ${
                    showItem[item.id] ? 'rounded-t-lg' : 'rounded-lg'
                  }`}
                  onClick={() => toggleItem(item.id)}
                >
                  <div className="h-32 w-32">
                    <img
                      src={item?.productsImage?.frontSideImageUrl}
                      alt="트럭이미지"
                      className="rounded-lg w-full h-full object-cover"
                    ></img>
                  </div>
                  <div className="px-4 flex-1 justify-between min-w-[160px] min-h-[128px]">
                    <dl className="">
                      <dt className="font-semibold">
                        {item.tons}t・{getFormatDate(item.firstRegistrationDate, ['year', 'month'], true)}・
                        {item.loaded?.desc}
                      </dt>
                      <dd className="text-gray-6">{item.truckName}</dd>
                      <dd className="font-semibold text-lg mt-2">{showPrice(item)}</dd>
                    </dl>
                  </div>
                  <div>
                    {showItem[item.id] ? (
                      <ChevronUpIcon color={colors.gray[4]}></ChevronUpIcon>
                    ) : (
                      <ChevronDownIcon color={colors.gray[4]}></ChevronDownIcon>
                    )}
                  </div>
                </li>

                {showItem[item.id] ? (
                  <dl className="table w-full bg-gray-2 rounded-b-lg border-b border-x border-gray-4 text-gray-8 p-4">
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">톤수</dt>
                      <dd className="table-cell font-semibold">{item?.tons}t</dd>
                    </div>
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">연식</dt>
                      <dd className="table-cell font-semibold">{item?.year}</dd>
                    </div>
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">제조사</dt>
                      <dd className="table-cell font-semibold">{item?.manufacturerCategories?.name}</dd>
                    </div>
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">적재함 종류</dt>
                      <dd className="table-cell font-semibold">{item?.loaded?.desc}</dd>
                    </div>
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">가격</dt>
                      <dd className="table-cell font-semibold">{showPrice(item)}</dd>
                    </div>
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">주행거리</dt>
                      <dd className="table-cell font-semibold">
                        {formatDistanceToThousandKm(String(item?.distance))}만km
                      </dd>
                    </div>
                    <div className="table-row">
                      <dt className="table-cell min-w-[100px] text-gray-7 py-1">가변축</dt>
                      <dd className="table-cell font-semibold">{item?.axis?.desc}</dd>
                    </div>
                  </dl>
                ) : null}
              </ul>
            </div>
          ))}
      </div>
    </>
  );
};
export default SimilarProductsList;

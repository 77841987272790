import React, { ReactNode, forwardRef, useState } from 'react';
import { colors } from '@/const/colors';

import '@/css/inputStyle.css';
import { TextField } from '@mui/material';
interface TextInputLabelOffProps {
  name: string;
  placeholder: string;
  value: string;
  minRows: number;
  maxRows: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  errorMsg?: string;
  borderRadius?: number;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  suffix?: ReactNode;
  autoFocus?: boolean;
  maxLength?: number;
}

const ChatInputComponent = forwardRef<HTMLTextAreaElement, TextInputLabelOffProps>(
  (
    {
      name,
      placeholder,
      value,
      minRows,
      maxRows,
      onChange,
      onBlur,
      onFocus,
      error,
      errorMsg,
      fontSize = 16,
      width,
      height,
      readOnly = false,
      disabled = false,
      autoFocus,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(true);
      if (onFocus) onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(false);
      if (onBlur) onBlur(e);
    };

    return (
      <div className="relative w-full h-auto">
        <TextField
          placeholder={placeholder}
          name={name}
          value={value}
          multiline
          minRows={minRows}
          maxRows={maxRows}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          autoFocus={autoFocus}
          disabled={disabled}
          sx={{
            fontSize: `${fontSize}px`,
            width: width ? `${width}px` : '100%',
            paddingX: '16px',
            paddingY: '16px',
            minHeight: '17px',
            backgroundColor: colors.gray[2],
            color: disabled ? colors.gray[5] : colors.gray[8],
            cursor: readOnly ? 'pointer' : '',
            justifyContent: 'center',
            borderRadius: '50px',
            '.MuiInputBase-root': {
              padding: '0px',
              minHeight: '17px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />

        {error && (
          <p className={`absolute text-red text-sm ${height ? `top-[${height}px]` : 'top-[36px]'}`}>{errorMsg}</p>
        )}
      </div>
    );
  },
);
export default ChatInputComponent;

import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import BasicButton from '../Common/Button/BasicButton';
import TextInputLabelOff from '../Common/Input/TextInputLabelOff';
import { XMarkIcon } from '../Icon';
import { deleteSearchHistory, getSearchHistory } from '@/api/public';
import { colors } from '@/const/colors';
import { DRIVE_HISTORY_TRANSPORT_COMPANY } from '@/const/searchHistory';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface TransportInfoSearchFormProps {
  placeholder: string;
  searchHistoryType: string;
  value: string;
  onClickHistory: (item: SearchHistory) => void;
  onChangeFormInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterPress: () => void;
}

const TransportInfoSearchForm = ({
  placeholder,
  searchHistoryType,
  value,
  onClickHistory,
  onChangeFormInput,
  onEnterPress,
}: TransportInfoSearchFormProps) => {
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  const getSearchHistories = async () => {
    try {
      const params = new URLSearchParams({
        type: searchHistoryType,
        page: '1',
        size: '10',
      });
      const response = await getSearchHistory(params);
      const data: SearchHistory[] = response.data.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data } = useQuery(['get-search-history-transport-info'], getSearchHistories, {
    onError: () => {},
    refetchOnWindowFocus: false,
  });

  const deleteSearchHistoryMutation = useMutation((searchHistoryId: number) => deleteSearchHistory(searchHistoryId), {
    onMutate: async (id) => {
      await queryClient.cancelQueries('get-search-history-transport-info');
      const currentData = queryClient.getQueryData<SearchHistory[]>(['get-search-history-transport-info']);

      if (!currentData) return;

      const prevData = Array.isArray(currentData) ? [...currentData] : [];

      const updatedData = prevData.filter((item) => item.id !== id);
      queryClient.setQueryData(['get-search-history-transport-info'], updatedData);

      return { prevData };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(['get-search-history-transport-info'], { ...context?.prevData });
      showToast('문제가 생겼어요. 다시 시도해주세요.', 'error', 'bottom');
    },
  });

  const onClickDeleteSearchHistory = (searchHistoryId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    deleteSearchHistoryMutation.mutate(searchHistoryId);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onEnterPress) {
      onEnterPress();
    }
  };

  const inputName = searchHistoryType === DRIVE_HISTORY_TRANSPORT_COMPANY ? 'transportCompany' : 'transportItem';

  return (
    <>
      <div>
        <div className="px-4 pt-2">
          <div className="py-1 relative w-full flex justify-between align-center">
            <TextInputLabelOff
              placeholder={placeholder + ' 입력'}
              name={inputName}
              value={value || ''}
              onChange={(e) => onChangeFormInput(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              enterKeyHint="done"
              maxLength={25}
              paddingRight={90}
            ></TextInputLabelOff>
            <div className="absolute right-0 bottom-2">
              <BasicButton
                name="저장"
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={14}
                width={80}
                height={30}
                borderRadius={8}
                fontWeight="semibold"
                onClick={() => onEnterPress()}
                isDisabled={!value}
              ></BasicButton>
            </div>
          </div>
        </div>
        <div className="px-4 pt-[30px]">
          <div className="text-gray-8 font-semibold text-sm">최근 입력한 {placeholder}</div>
          {data && data.length > 0 ? (
            <div className="flex flex-wrap pt-6">
              {data.map((item) => (
                <div
                  key={item.id}
                  className={`flex items-center py-2 px-3 bg-gray-1 rounded-xl mr-2 mb-2`}
                  onClick={() => onClickHistory(item)}
                >
                  <span className="text-gray-8 text-base">{item.keyword}</span>
                  <div onClick={(e) => onClickDeleteSearchHistory(item.id, e)} className="pl-3 pr-2">
                    <XMarkIcon color={colors.gray[7]}></XMarkIcon>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-7 text-sm pt-6">최근 입력 내역이 없습니다.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default TransportInfoSearchForm;

import React, { SVGProps } from 'react';

const SvgCarInspectionColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 36 36" {...props}>
    <path
      fill="#3C5FBF"
      d="M29.361 27.324 13.637 11.973l1.772-1.73c.664-.865.664-1.946 0-2.594l-2.215-2.163c-.664-.648-1.772-.648-2.657 0l-5.094 5.19C5.22 11.108 5 11.54 5 11.973V15c0 .432.221.865.443 1.297l.443.433c.664.648 1.772.648 2.657 0L10.315 15 26.04 30.351c.886.865 2.215.865 3.1 0 1.108-.864 1.108-2.162.222-3.027Z"
    />
    <path
      fill="#414141"
      d="m13.466 25.573 11.94-11.94.218-.217c1.52.434 3.039 0 4.124-1.086 1.086-1.085 1.52-2.822 1.086-4.124l-2.605 2.605h-3.04V7.77l2.605-2.605c-1.52-.434-3.039 0-4.124 1.086-1.086 1.085-1.52 2.822-1.086 4.125L10.427 22.532c-1.52-.434-3.039 0-4.124 1.086-1.737 1.737-1.737 4.342 0 6.079 1.736 1.736 4.341 1.736 6.078 0 1.085-1.086 1.52-2.606 1.085-4.125Zm-5.644 2.605c-.868-.869-.868-2.171 0-3.04.869-.868 2.171-.868 3.04 0 .868.869.868 2.171 0 3.04-.869.868-2.171.868-3.04 0Z"
    />
  </svg>
);
SvgCarInspectionColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCarInspectionColorIcon;

import React, { SVGProps } from 'react';

const SvgCarPaymentBlueColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 36 36" {...props}>
    <rect width={24} height={16} x={8} y={12} fill="#737373" rx={3} />
    <rect width={24} height={16} x={4} y={8} fill="#D1DDFF" rx={3} />
    <path
      fill="#3C5FBF"
      d="M12.027 16.355h-1.312V15.16h1.043l-.82-3.644h1.804l.686 3.644h.972l.791-3.644h1.63l.79 3.644h.979l.68-3.644h1.804l-.826 3.644h1.049v1.195h-1.319L19.152 20h-1.91l-.762-3.645h-.96L14.758 20h-1.91l-.82-3.645Zm1.817 1.032h.07l.223-1.032h-.487l.194 1.032Zm2.39-2.227-.199-.95h-.07l-.2.95h.47Zm1.864 2.238h.07l.193-1.043h-.492l.229 1.043Z"
    />
  </svg>
);
SvgCarPaymentBlueColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCarPaymentBlueColorIcon;

import React, { ReactNode } from 'react';

import BaseModal from '@/components/Common/Modal/BaseModal';

interface AlertModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  content?: ReactNode;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onLeftButtonClick?: ((e?: React.MouseEvent<HTMLDivElement>) => void) | undefined;
  onRightButtonClick?: ((e?: React.MouseEvent<HTMLDivElement>) => void) | undefined;
  paddingType?: 'padding-top-48' | 'padding-top-32' | 'padding-top-20';
}

const AlertModal = ({ isOpen, onClose, title, content, paddingType = 'padding-top-48' }: AlertModalProps) => {
  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        content={content}
        rightButtonLabel="확인"
        onRightButtonClick={onClose}
        paddingType={paddingType}
      />
    </>
  );
};

export default AlertModal;

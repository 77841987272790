import React from 'react';
import { useLocation } from 'react-router-dom';

import MenuHeader from '@/components/Header/MenuHeader';
import PriceTrendResult from '@/components/PriceTrend/PriceTrendResult';

const PriceTrendFilterSearchResult = () => {
  const location = useLocation();
  const productSearchParams = location.state.productSearchParams;

  return (
    <>
      <MenuHeader title="시세검색"></MenuHeader>
      <div className="flex flex-col w-full p-4 pb-24 pt-[60px]">
        <div className="pb-24">
          <PriceTrendResult productSearchParams={productSearchParams}></PriceTrendResult>
        </div>
      </div>
    </>
  );
};

export default PriceTrendFilterSearchResult;

import React, { SVGProps } from 'react';

const SvgInformationCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx={12} cy={12} r={8.1} stroke={props.color || 'currentColor'} strokeWidth={1.8} />
    <path fill={props.color || 'currentColor'} d="M11.121 7.5h1.8v1.8h-1.8V7.5ZM11.121 11h1.8v5.5h-1.8V11Z" />
  </svg>
);
SvgInformationCircleIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgInformationCircleIcon;

import React, { ReactNode, createContext, useContext, useState } from 'react';

type PriceSearchFormContextType = {
  priceProductSearchParams: ProductSearchParams | null;
  setPriceProductSearchParams: (newParams: ProductSearchParams) => void;
};
const PriceSearchFormContext = createContext<PriceSearchFormContextType | null>(null);

const PriceSearchFormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [priceProductSearchParams, setPriceProductSearchParams] = useState<ProductSearchParams | null>(null);
  return (
    <PriceSearchFormContext.Provider value={{ priceProductSearchParams, setPriceProductSearchParams }}>
      {children}
    </PriceSearchFormContext.Provider>
  );
};

export const usePriceSearchFormContext = () => {
  const context = useContext(PriceSearchFormContext) as PriceSearchFormContextType;
  if (context === undefined) {
    throw new Error('usePriceSearchFormContext must be used within a PriceSearchFormProvider');
  }
  return context;
};

export { PriceSearchFormContext, PriceSearchFormProvider };

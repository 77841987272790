// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDialog-root.MuiModal-root {
  padding: 0 20px;
}

.MuiDialog-container .MuiPaper-root {
  border-radius: 12px;
  margin: 0px;
  width: 100%;
}

.MuiDialog-container .MuiPaper-root.padding-top-48 {
  padding-top: 48px;
}

.MuiDialog-container .MuiPaper-root.padding-top-32 {
  padding-top: 32px;
}

.MuiDialog-container .MuiPaper-root.padding-top-20 {
  padding-top: 20px;
}

.MuiDialog-container .MuiPaper-root.padding-top-0,
.MuiPaper-root.none {
  padding-top: 0px;
}

.MuiDialog-container .MuiPaper-root.padding-top-20 .MuiDialogTitle-root {
  padding: 0px 16px 20px 16px !important;
}

.MuiDialogTitle-root {
  padding: 0px 16px 24px 16px !important;
}

.MuiDialog-container .MuiPaper-root.padding-top-0 .MuiDialogContent-root {
  padding: 0px 0px 59px 0px !important;
}

@media (max-width: 360px) {
  .MuiDialog-container .MuiPaper-root.padding-top-0 .MuiDialogContent-root {
    padding: 0px 0px 47px 0px !important;
  }
}

.MuiDialog-container .MuiPaper-root.none .MuiDialogContent-root {
  padding: 0px 0px 0px 0px !important;
}

.MuiDialogContent-root {
  padding: 0px 16px 32px 16px !important;
}

.MuiDialog-container .MuiPaper-root.padding-top-20 .MuiDialogContent-root {
  padding: 0px 16px 16px 16px !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/baseModalStyle.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".MuiDialog-root.MuiModal-root {\n  padding: 0 20px;\n}\n\n.MuiDialog-container .MuiPaper-root {\n  border-radius: 12px;\n  margin: 0px;\n  width: 100%;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-48 {\n  padding-top: 48px;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-32 {\n  padding-top: 32px;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-20 {\n  padding-top: 20px;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-0,\n.MuiPaper-root.none {\n  padding-top: 0px;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-20 .MuiDialogTitle-root {\n  padding: 0px 16px 20px 16px !important;\n}\n\n.MuiDialogTitle-root {\n  padding: 0px 16px 24px 16px !important;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-0 .MuiDialogContent-root {\n  padding: 0px 0px 59px 0px !important;\n}\n\n@media (max-width: 360px) {\n  .MuiDialog-container .MuiPaper-root.padding-top-0 .MuiDialogContent-root {\n    padding: 0px 0px 47px 0px !important;\n  }\n}\n\n.MuiDialog-container .MuiPaper-root.none .MuiDialogContent-root {\n  padding: 0px 0px 0px 0px !important;\n}\n\n.MuiDialogContent-root {\n  padding: 0px 16px 32px 16px !important;\n}\n\n.MuiDialog-container .MuiPaper-root.padding-top-20 .MuiDialogContent-root {\n  padding: 0px 16px 16px 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

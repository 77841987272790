import React, { SVGProps } from 'react';

const SvgSellLicenseBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M2 5a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16.69a10.517 10.517 0 0 1 5.91-7.537h-1.78v-1.252l3.221-5.022h1.99v5H29v.311c1.545 0 3.012.334 4.333.933V7a2 2 0 0 0-2-2H2Zm.835 4a1.167 1.167 0 1 0 0-2.333 1.167 1.167 0 0 0 0 2.333Zm28.834-1.167a1.167 1.167 0 1 1-2.333 0 1.167 1.167 0 0 1 2.333 0ZM4.001 22.166a1.167 1.167 0 1 1-2.333 0 1.167 1.167 0 0 1 2.333 0Zm22.546-5.977H24.42v-.063l2.053-3.2h.074v3.263Zm-7.657 2.727c-1.674 0-2.874-.926-2.906-2.253h1.6c.032.569.58.948 1.316.948.758 0 1.306-.432 1.295-1.043.01-.62-.547-1.063-1.442-1.063h-.705v-1.168h.705c.747 0 1.274-.41 1.274-1.01 0-.58-.442-.98-1.116-.98-.674 0-1.232.38-1.253.958h-1.516c.021-1.316 1.21-2.221 2.769-2.221 1.59 0 2.653.937 2.642 2.148.01.852-.59 1.463-1.442 1.61v.063c1.116.137 1.737.821 1.726 1.769.01 1.305-1.231 2.242-2.947 2.242Zm-9.42-1.253.01 1.148h5.453v-1.306H11.68v-.042l1.158-1.126c1.569-1.421 1.99-2.137 1.99-3 0-1.327-1.085-2.253-2.716-2.253-1.61 0-2.716.947-2.706 2.442h1.506c0-.726.463-1.179 1.179-1.179.695 0 1.21.421 1.21 1.095 0 .6-.379 1.021-1.095 1.705L9.47 17.663ZM7.714 11.19v7.622h-1.59v-6.116h-.042l-1.747 1.094v-1.4l1.884-1.2h1.495Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M29 34a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0 2a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="m26 24 1.286 6L29 24.5l1.571 5.5L32 24M25 26h8"
    />
  </svg>
);
SvgSellLicenseBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSellLicenseBoldIcon;

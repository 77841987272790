import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DaumPostcode, { Address } from 'react-daum-postcode';
import { useLocation, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import ConfirmModal from '../Common/Modal/ConfirmModal';
import BottomSheetPopup from '../Common/Popup/BottomSheetPopup';
import apiManager from '@/api/AxiosInstance';
import BasicButton from '@/components/Common/Button/BasicButton';
import TextAreaLabelOff from '@/components/Common/Input/TextAreaLabelOff';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import CalendarPopup from '@/components/Common/Popup/CalendarPopup';
import { ChevronRightIcon, ResetIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { TRANSFEREE, TRANSFEROR } from '@/const/contract';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { positiveIntegerAndNullRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatNumber } from '@/utils/common';
import {
  Validation,
  validateBusinessNumber,
  validateName,
  validateNumber,
  validatePositiveInteger,
  validateRegistrationNumber,
} from '@/utils/validation';

const postCodeStyle = {
  width: '100%',
  height: 'calc(100vh - 112px)',
};
interface ContractProps {
  chatRoomId: string;
  contractInfo: ContractInfo | undefined;
  setContractInfo: React.Dispatch<React.SetStateAction<ContractInfo>>;
  contractWriterType: string;
  setPageNum: (pageNum: number) => void;
}

type ContractRequest = {
  balance?: number | null;
  balancePaymentDate?: string;
  carName?: string;
  carNumber?: string;
  carType?: string;
  carUse?: string;
  chatRoomId: number;
  contractWriterType?: string;
  downPayment?: number | null;
  downPaymentDate?: string;
  identificationNumber?: string;
  intermediatePayment?: number | null;
  intermediatePaymentDate?: string;
  motorType?: string;
  tradingAmount?: number;
  transfereeAddress?: string;
  transfereeName?: string;
  transfereePhoneNumber?: string;
  transfereeRegistrationNumber?: string;
  transfereeSignImageUrl?: string;
  transferorAddress?: string;
  transferorName?: string;
  transferorPhoneNumber?: string;
  transferorRegistrationNumber?: string;
  transferorSignImageUrl?: string;
  year?: string;
  additionalConditions?: string;
};

type ContractErrorInfoType = {
  licenseNumberError: boolean;
  licenseNumberErrorMessage: string;
  ownerNameError: boolean;
  ownerNameErrorMessage: string;
};

const ContractForm: React.FC<ContractProps> = ({
  chatRoomId,
  contractInfo,
  setContractInfo,
  contractWriterType,
  setPageNum,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);
  const [formDate, setFormDate] = useState(dayjs(new Date()).format('YYYY-MM-DD')?.toString());
  const [selectedKey, setSelectedKey] = useState('');

  const [isShowAdressPopup, setIsShowAdressPopup] = useState(false);
  const [adressPopupTitle, setAdressPopupTitle] = useState('');
  const [daumPostCodeType, setDaumPostCodeType] = useState('');

  const [isShow, setIsShow] = useState(false);
  const [isShowSignPopup, setIsShowSignPopup] = useState<boolean>(false);
  const [contractRequest, setContractRequest] = useState<ContractRequest>();

  const [error, setError] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);
  const [signatureInput, setSignatureInput] = useState<string | null>(null);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const writerList: KeyValueListType = { TRANSFEROR: '양도인', TRANSFEREE: '양수인' };

  const [registrationNumerErrorMessage, setRegistrationNumerErrorMessage] = useState('');
  const [tradingAmount, setTradingAmount] = useState<string>('');

  const [tradingAmountInfo, setTradingAmountInfo] = useState(false);
  const [tradingAmountError, setTradingAmountError] = useState(false);
  const [tradingAmountErrorMessage, setTradingAmountErrorMessage] = useState('');
  const [tradingAmountInfoMessage, setTradingAmountInfoMessage] = useState('');

  const [downPaymentError, setDownPaymentError] = useState(false);
  const [downPaymentErrorMessage, setDownPaymentErrorMessage] = useState('');

  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const { showToast } = useToastContext();

  let padRef = useRef<SignatureCanvas>(null);

  const clear = () => {
    setSignatureInput('');
    setIsSigned(false);
    padRef.current?.clear();
  };

  const onClickSign = () => {
    const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
    if (url) {
      setSignatureUrl(url);
      if (contractWriterType === TRANSFEROR) {
        setContractInfo((prevContractInfo) => ({
          ...prevContractInfo,
          transferorSignImageUrl: url !== undefined ? url : '',
        }));
      } else {
        setContractInfo((prevContractInfo) => ({
          ...prevContractInfo,
          transfereeSignImageUrl: url !== undefined ? url : '',
        }));
      }
    }
    setIsShowSignPopup(false);
  };

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    const { value } = e.target;

    const updateContractInfo = (newValue: string) => {
      setContractInfo((prevContractInfo) => ({
        ...prevContractInfo,
        [key]: newValue,
      }));
    };

    const validateAndUpdate = (validator: (val: string) => Validation) => {
      setError(validator(value).isValid);
      setRegistrationNumerErrorMessage(validator(value).errorMessage);
      updateContractInfo(value);
    };

    switch (key) {
      case 'transferorRegistrationNumber':
        value.replace(/-/g, '').length === 10
          ? validateAndUpdate(validateBusinessNumber)
          : validateAndUpdate(validateRegistrationNumber);
      case 'transfereeRegistrationNumber':
        value.replace(/-/g, '').length === 10
          ? validateAndUpdate(validateBusinessNumber)
          : validateAndUpdate(validateRegistrationNumber);
        break;
      case 'year':
      case 'transferorPhoneNumber':
      case 'transfereePhoneNumber':
        const str = value === '' ? '' : value;
        if ((validateNumber(str).isValid && !value.startsWith('-')) || str === '') {
          updateContractInfo(str);
        }
        break;
      case 'transferorName':
      case 'transfereeName':
        updateContractInfo(value);
        setNameError(!validateName(value));
        setNameErrorMessage(value === '' ? '성명은 필수값입니다.' : '올바른 성명이 아닙니다.');
        break;
      default:
        updateContractInfo(value !== undefined ? value : '');
        break;
    }
  };

  const updateContractInfo = (key: string, newValue: string) => {
    if (positiveIntegerAndNullRegex.test(newValue) && !newValue.startsWith('-')) {
      setContractInfo((prevContractInfo) => ({
        ...prevContractInfo,
        [key]: newValue,
      }));
    }
  };

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    const { value } = e.target;
    const sanitizedPrice = String(value).replace(/[^0-9]/g, '');
    if (positiveIntegerAndNullRegex.test(sanitizedPrice) && !sanitizedPrice.startsWith('-')) {
      const str = sanitizedPrice === '' ? '' : sanitizedPrice;
      updateContractInfo(key, str);
    }

    switch (key) {
      case 'tradingAmount':
        if (!validatePositiveInteger(sanitizedPrice).isValid) {
          setTradingAmountError(!validatePositiveInteger(sanitizedPrice).isValid);
          setTradingAmountErrorMessage(sanitizedPrice === '' ? '매매금액은 필수값입니다.' : '매매금액은 필수값입니다.');
          setTradingAmountInfo(false);
          setTradingAmountInfoMessage('');
        } else if (sanitizedPrice && tradingAmount !== sanitizedPrice) {
          setTradingAmountInfo(true);
          setTradingAmountInfoMessage(
            '판매중인 금액과 계약서 상 매매금액이 다릅니다. 올바르게 입력했는지 다시 한번 확인해주세요.',
          );
          setTradingAmountError(false);
          setTradingAmountErrorMessage('');
        } else {
          setTradingAmountInfo(false);
          setTradingAmountInfoMessage('');
          setTradingAmountError(false);
          setTradingAmountErrorMessage('');
        }
        break;
      case 'downPayment':
        setDownPaymentError(!validatePositiveInteger(sanitizedPrice).isValid);
        setDownPaymentErrorMessage(
          sanitizedPrice === '' ? '계약금은 필수값입니다.' : validatePositiveInteger(sanitizedPrice).errorMessage,
        );
        break;
      case 'intermediatePayment':
      case 'balance':
        break;

      default:
        updateContractInfo(key, sanitizedPrice !== undefined ? sanitizedPrice : '');
        break;
    }
  };

  const isValidateForm = useMemo(() => {
    if (contractWriterType === TRANSFEROR) {
      const commonConditions =
        contractInfo?.tradingAmount &&
        contractInfo?.downPayment &&
        contractInfo?.downPaymentDate &&
        contractInfo?.transferorAddress &&
        contractInfo?.transferorName &&
        contractInfo?.transferorPhoneNumber &&
        contractInfo?.transferorRegistrationNumber &&
        contractInfo?.transferorSignImageUrl &&
        !tradingAmountError &&
        !downPaymentError &&
        !nameError;

      if (contractInfo?.balance) {
        return commonConditions && contractInfo?.balancePaymentDate;
      } else if (contractInfo?.intermediatePayment) {
        return commonConditions && contractInfo?.intermediatePaymentDate;
      } else {
        return commonConditions;
      }
    } else {
      return (
        contractInfo?.transfereeAddress &&
        contractInfo?.transfereeName &&
        contractInfo?.transfereePhoneNumber &&
        contractInfo?.transfereeRegistrationNumber &&
        contractInfo?.transfereeSignImageUrl &&
        !nameError
      );
    }
  }, [contractInfo]);

  const onClickCompleteContract = () => {
    setIsShow(false);
    if (!contractInfo) {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      return;
    }

    let requestData: ContractRequest;
    if (contractWriterType === TRANSFEROR) {
      requestData = {
        balance: Number(contractInfo?.balance),
        balancePaymentDate: contractInfo?.balancePaymentDate,
        carName: contractInfo?.carName,
        carNumber: contractInfo?.carNumber,
        carType: contractInfo?.carType,
        carUse: contractInfo?.carUse,
        chatRoomId: Number(chatRoomId),
        contractWriterType: contractWriterType,
        downPayment: Number(contractInfo?.downPayment),
        downPaymentDate: contractInfo?.downPaymentDate,
        identificationNumber: contractInfo?.identificationNumber || '',
        intermediatePayment: Number(contractInfo?.intermediatePayment),
        intermediatePaymentDate: contractInfo?.intermediatePaymentDate,
        motorType: contractInfo?.motorType || '',
        tradingAmount: contractInfo?.tradingAmount || 0,
        transferorAddress: contractInfo?.transferorAddress || '',
        transferorName: contractInfo?.transferorName || '',
        transferorPhoneNumber: contractInfo?.transferorPhoneNumber || '',
        transferorRegistrationNumber: (contractInfo?.transferorRegistrationNumber || '').replace(/-/g, '') || '',
        transferorSignImageUrl: contractInfo?.transferorSignImageUrl || '',
        year: contractInfo?.year || '',
        additionalConditions: contractInfo?.additionalConditions || '',
      };
      setContractRequest(requestData);
    } else {
      requestData = {
        chatRoomId: Number(chatRoomId),
        contractWriterType: contractWriterType,
        transfereeAddress: contractInfo?.transfereeAddress || '',
        transfereeName: contractInfo?.transfereeName || '',
        transfereePhoneNumber: contractInfo?.transfereePhoneNumber || '',
        transfereeRegistrationNumber: (contractInfo?.transfereeRegistrationNumber || '').replace(/-/g, '') || '',
        transfereeSignImageUrl: contractInfo?.transfereeSignImageUrl || '',
      };
      setContractRequest(requestData);
    }

    if (contractInfo.id && contractWriterType === TRANSFEROR) {
      apiManager
        .patch(`/api/v1/contracts/${contractInfo.id}`, requestData)
        .then((response) => {
          if (response) {
            showToast('전자계약서가 수정되었어요.', 'success', 'bottom');
            setPageNum(1);
          }
        })
        .catch((error) => {
          if (error.code === 'REGISTRACTION_NUMBER_NOT_VALID') {
            showToast(error.message, 'error', 'bottom');
          } else {
            showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
          }
        });
    } else {
      apiManager
        .post('/api/v1/contracts', requestData)
        .then((response) => {
          if (response) {
            showToast('전자계약서가 저장되었어요.', 'success', 'bottom');
            setPageNum(1);
          }
        })
        .catch((error) => {
          if (error.code === 'REGISTRACTION_NUMBER_NOT_VALID') {
            showToast(error.message, 'error', 'bottom');
          } else {
            showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
          }
        });
    }
  };

  const handleSignatureEnd = () => {
    setIsSigned(true);
  };

  useEffect(() => {
    if (contractInfo) {
      const { tradingAmount, downPayment, intermediatePayment } = contractInfo;
      if (tradingAmount && (downPayment || intermediatePayment)) {
        const downPaymentValue = downPayment || 0;
        const intermediatePaymentValue = intermediatePayment || 0;
        const balanceValue = tradingAmount - downPaymentValue - intermediatePaymentValue;
        setContractInfo((prevContractInfo) => ({
          ...prevContractInfo,
          balance: balanceValue,
        }));
      }
    }
  }, [contractInfo?.tradingAmount, contractInfo?.downPayment, contractInfo?.intermediatePayment]);

  useEffect(() => {
    if (contractInfo && contractWriterType === TRANSFEROR) {
      setSignatureUrl(contractInfo.transferorSignImageUrl);
    }
  }, [contractInfo]);

  useEffect(() => {
    if (contractInfo) {
      setTradingAmount(contractInfo.tradingAmount.toString());
    }
  }, []);

  const onClickInputAdress = (e: React.MouseEvent, index?: number) => {
    let { name } = e.target as HTMLInputElement;
    setIsShowAdressPopup(true);
    setAdressPopupTitle('주소');
    setDaumPostCodeType(name);
  };

  const onCompletePost = (data: Address) => {
    const adress = data.address;
    setContractInfo((prevContractInfo) => ({
      ...prevContractInfo,
      [daumPostCodeType]: adress,
    }));
    setIsShowAdressPopup(false);
  };

  const onClickDateSelector = (key: string) => {
    const date = contractInfo?.[key] || dayjs(new Date()).format('YYYY-MM-DD')?.toString();
    setFormDate(date);
    setIsOpenCalendarPopup(true);
    setSelectedKey(key);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const onChangeDate = (key: string, date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setContractInfo((prevContractInfo) => ({
        ...prevContractInfo,
        [key]: date,
      }));
      setFormDate(date);
    }
  };

  return (
    <>
      {contractWriterType === TRANSFEROR && (
        <div className="flex flex-col gap-[30px] pb-[100px]">
          <TextInputLabelUp
            name="carNumber"
            label="차량번호"
            placeholder="차량번호 입력"
            value={contractInfo?.carNumber || ''}
            onChange={() => {}}
            disabled
          />
          <TextInputLabelUp
            name="carType"
            label="차종"
            placeholder="차종 입력"
            value={contractInfo?.carType || ''}
            onChange={() => {}}
            disabled
          />
          <TextInputLabelUp
            name="carUse"
            label="용도"
            placeholder="용도 입력"
            value={contractInfo?.carUse || ''}
            onChange={() => {}}
            disabled
          />
          <TextInputLabelUp
            name="year"
            label="연식"
            placeholder="연식 입력"
            value={contractInfo?.year || ''}
            onChange={() => {}}
            disabled
          />
          <TextInputLabelUp
            name="motorType"
            label="원동기형식"
            placeholder="원동기형식 입력"
            value={contractInfo?.year || ''}
            onChange={() => {}}
            disabled
          />
          <TextInputLabelUp
            name="identificationNumber"
            label="차대번호"
            placeholder="차대번호 입력"
            value={contractInfo?.identificationNumber || ''}
            onChange={() => {}}
            disabled
          />
          <TextInputLabelUp
            name="tradingAmount"
            label="매매금액"
            placeholder="매매금액 입력"
            value={formatNumber(contractInfo?.tradingAmount?.toString(), true) || ''}
            onChange={(e) => onChangePrice(e, 'tradingAmount')}
            required
            maxLength={6}
            info={tradingAmountInfo}
            error={tradingAmountError}
            errorMsg={tradingAmountErrorMessage}
            infoMsg={tradingAmountInfoMessage}
            suffix="만원"
          />
          <TextInputLabelUp
            name="downPayment"
            label="계약금"
            placeholder="계약금 입력"
            value={formatNumber(contractInfo?.downPayment?.toString(), true) || ''}
            onChange={(e) => onChangePrice(e, 'downPayment')}
            required
            maxLength={6}
            error={downPaymentError}
            errorMsg={'계약금은 필수값입니다.'}
            suffix="만원"
          />
          <div onClick={() => onClickDateSelector('downPaymentDate')}>
            <TextInputLabelUp
              name="downPaymentDate"
              label="계약금 지급일"
              placeholder="계약금 지급일 입력"
              value={contractInfo?.downPaymentDate ? String(contractInfo.downPaymentDate) : ''}
              onChange={() => {}}
              readOnly
              required
              error={!!contractInfo?.downPayment && !contractInfo?.downPaymentDate}
              errorMsg={'계약금 지급일은 필수값입니다.'}
              suffix={<ChevronRightIcon color={colors.gray[6]} />}
            />
          </div>
          <TextInputLabelUp
            name="intermediatePayment"
            label="중도금"
            placeholder="중도금 입력"
            value={formatNumber(contractInfo?.intermediatePayment?.toString(), true) || ''}
            onChange={(e) => onChangePrice(e, 'intermediatePayment')}
            maxLength={6}
            suffix="만원"
          />
          <div onClick={() => onClickDateSelector('intermediatePaymentDate')}>
            <TextInputLabelUp
              name="intermediatePaymentDate"
              label="중도금 지급일"
              placeholder="중도금 지급일 입력"
              value={contractInfo?.intermediatePaymentDate ? String(contractInfo.intermediatePaymentDate) : ''}
              onChange={() => {}}
              readOnly
              error={!!contractInfo?.intermediatePayment && !contractInfo?.intermediatePaymentDate}
              errorMsg={'중도금 지급일을 입력해주세요.'}
              suffix={<ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>}
            />
          </div>
          <TextInputLabelUp
            name="balance"
            label="잔금"
            placeholder="잔금 입력"
            value={contractInfo?.balance ? formatNumber(String(contractInfo.balance)) : ''}
            onChange={(e) => onChangePrice(e, 'balance')}
            suffix="만원"
            maxLength={6}
          />

          <div onClick={() => onClickDateSelector('balancePaymentDate')}>
            <TextInputLabelUp
              name="balancePaymentDate"
              label="잔금 지급일"
              placeholder="잔금 지급일 입력"
              value={contractInfo?.balancePaymentDate ? String(contractInfo.balancePaymentDate) : ''}
              onChange={() => {}}
              readOnly
              error={!!contractInfo?.balance && !contractInfo?.balancePaymentDate}
              errorMsg={'잔금 지급일을 입력해주세요.'}
              suffix={<ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>}
            />
          </div>

          <div onClick={onClickInputAdress}>
            <TextInputLabelUp
              name="transferorAddress"
              label="주소"
              placeholder="주소 검색"
              value={contractInfo?.transferorAddress || ''}
              onChange={() => {}}
              readOnly
              suffix={<ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>}
              required
            />
          </div>
          <TextInputLabelUp
            name="transferorName"
            label="성명"
            placeholder="성명 입력"
            value={contractInfo?.transferorName || ''}
            onChange={(e) => onChangeFormInput(e, 'transferorName')}
            required
            maxLength={20}
            error={nameError}
            errorMsg={nameErrorMessage}
          />
          <TextInputLabelUp
            name="transferorPhoneNumber"
            label="전화번호"
            placeholder="전화번호 '-'를 제외 숫자만 입력"
            value={String(contractInfo?.transferorPhoneNumber) || ''}
            onChange={(e) => onChangeFormInput(e, 'transferorPhoneNumber')}
            required
            inputMode="numeric"
            maxLength={11}
            error={!contractInfo?.transferorPhoneNumber}
            errorMsg={'전화번호는 필수값입니다.'}
          />
          <TextInputLabelUp
            name="transferorRegistrationNumber"
            label="주민등록번호(사업자번호)"
            placeholder="주민등록번호(사업자번호) 입력"
            value={contractInfo?.transferorRegistrationNumber ? String(contractInfo?.transferorRegistrationNumber) : ''}
            onChange={(e) => onChangeFormInput(e, 'transferorRegistrationNumber')}
            required
            inputMode="numeric"
            maxLength={contractInfo?.transferorRegistrationNumber?.includes('-') ? 14 : 13}
            error={!!registrationNumerErrorMessage}
            errorMsg={registrationNumerErrorMessage}
          />
          <div>
            <label className="mb-3 text-base font-medium text-gray-8">특약사항</label>
            <TextAreaLabelOff
              name="additionalConditions"
              placeholder="특약사항 입력"
              value={contractInfo?.additionalConditions || ''}
              fontSize={18}
              minRows={1}
              maxRows={5}
              onChange={(e) => onChangeFormInput(e, 'additionalConditions')}
            />
          </div>

          <div>
            <label className="mb-2 text-base font-medium text-gray-8">서명</label>
            <div className="w-full pb-3 text-sm text-gray-7">* 계약 내용에 이상이 없음을 확인 후 서명합니다.</div>
            <div
              className="bg-gray-1 py-6 text-center text-gray-7 h-[85px] border border-gray-3 rounded-lg"
              onClick={() => {
                setIsShowSignPopup(true);
                setSignatureInput(signatureUrl);
              }}
            >
              {signatureUrl ? (
                <img src={signatureUrl} alt="user generated signature" className="object-center w-full h-full" />
              ) : (
                '서명하기'
              )}
            </div>
          </div>
        </div>
      )}
      {contractWriterType === TRANSFEREE && (
        <div className="flex flex-col gap-[30px] pb-[100px]">
          <div onClick={onClickInputAdress}>
            <TextInputLabelUp
              name="transfereeAddress"
              label="주소"
              placeholder="주소 검색"
              value={contractInfo?.transfereeAddress || ''}
              onChange={() => {}}
              readOnly
              suffix={<ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>}
              required
            ></TextInputLabelUp>
          </div>

          <TextInputLabelUp
            name="transfereeName"
            label="성명"
            placeholder="성명 입력"
            value={contractInfo?.transfereeName || ''}
            onChange={(e) => onChangeFormInput(e, 'transfereeName')}
            required
            maxLength={20}
            error={nameError}
            errorMsg={nameErrorMessage}
          />

          <TextInputLabelUp
            name="transfereePhoneNumber"
            label="전화번호"
            placeholder="전화번호 '-'를 제외 숫자만 입력"
            value={String(contractInfo?.transfereePhoneNumber) || ''}
            onChange={(e) => onChangeFormInput(e, 'transfereePhoneNumber')}
            required
            inputMode="numeric"
            error={!contractInfo?.transfereePhoneNumber}
            errorMsg={'전화번호는 필수값입니다.'}
            maxLength={11}
          />

          <TextInputLabelUp
            name="transfereeRegistrationNumber"
            label="주민등록번호(사업자번호)"
            placeholder="주민등록번호(사업자번호) 입력"
            value={contractInfo?.transfereeRegistrationNumber ? String(contractInfo?.transfereeRegistrationNumber) : ''}
            onChange={(e) => onChangeFormInput(e, 'transfereeRegistrationNumber')}
            required
            inputMode="numeric"
            maxLength={contractInfo?.transfereeRegistrationNumber?.includes('-') ? 14 : 13}
            error={!!registrationNumerErrorMessage}
            errorMsg={registrationNumerErrorMessage}
          />

          <div>
            <label className="mb-2 text-base font-medium text-gray-8">서명</label>
            <div className="w-full pb-3 text-sm text-gray-7">* 계약 내용에 이상이 없음을 확인 후 서명합니다.</div>
            <div
              className="bg-gray-1 py-6 text-center text-gray-7 h-[85px] border border-gray-3 rounded-lg"
              onClick={() => {
                setIsShowSignPopup(true);
                setSignatureInput(signatureUrl);
              }}
            >
              {signatureUrl ? (
                <img src={signatureUrl} alt="user generated signature" className="object-center w-full h-full" />
              ) : (
                '서명하기'
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-gray-0">
        <div
          className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
        ></div>
        <BasicButton
          name="저장하기"
          bgColor={colors.primary}
          borderColor={colors.primary}
          textColor={colors.gray[0]}
          fontSize={16}
          height={48}
          borderRadius={12}
          fontWeight="bold"
          isDisabled={!isValidateForm}
          onClick={() => setIsShow(true)}
        ></BasicButton>
      </div>

      <CalendarPopup
        isOpen={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        selectedDate={formDate}
        onChangeSelectedDate={(date) => onChangeDate(selectedKey, date)}
      />

      <ConfirmModal
        isOpen={isShow}
        title="작성 완료하기"
        content={
          <>
            양도인, 양수인 모두 작성 완료 시<br />
            수정이 불가능합니다.
            <br />
            계약서 작성을 완료하시겠습니까?
            <br />
            <br />
            <span className="font-bold">
              * 전자계약 사고 방지를 위해서
              <br />
              대면 후 계약해주세요.
            </span>
          </>
        }
        onLeftButtonClick={() => setIsShow(false)}
        rightButtonLabel="저장하기"
        onRightButtonClick={() => onClickCompleteContract()}
      />
      <BottomSheetPopup
        isOpen={isShowSignPopup}
        onClose={() => setIsShowSignPopup(false)}
        title={`${writerList[contractWriterType]} 서명 입력`}
      >
        <div className="px-4">
          {!signatureInput ? (
            <div className="min-h-[130px]">
              <SignatureCanvas
                ref={padRef}
                onEnd={handleSignatureEnd}
                canvasProps={{
                  className: 'signCanvas bg-gray-1 border border-gray-3 h-[130px] min-h-[130px] rounded-[10px] w-full',
                }}
              />
            </div>
          ) : (
            <div
              className="bg-gray-1 py-6 text-center text-gray-5 h-[130px] border border-gray-3 rounded-lg"
              onClick={() => setIsShowSignPopup(true)}
            >
              <img src={signatureInput} alt="user generated signature" className="object-center w-full h-full" />
            </div>
          )}

          <div className="flex justify-end mt-4 mb-[14px]">
            <BasicButton
              name="다시 입력"
              icon={<ResetIcon color={colors.gray[9]}></ResetIcon>}
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[8]}
              fontSize={16}
              width={120}
              height={36}
              borderRadius={8}
              fontWeight="medium"
              onClick={clear}
            ></BasicButton>
          </div>

          <div className="py-2">
            <BasicButton
              name="저장하기"
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={16}
              height={48}
              borderRadius={12}
              fontWeight="bold"
              isDisabled={!isSigned}
              onClick={onClickSign}
            ></BasicButton>
          </div>
        </div>
      </BottomSheetPopup>

      <BottomSheetPopup
        isOpen={isShowAdressPopup}
        onClose={() => setIsShowAdressPopup(false)}
        title={adressPopupTitle}
        isFullHeight
      >
        <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} submitMode={false}></DaumPostcode>
      </BottomSheetPopup>
    </>
  );
};

export default ContractForm;

import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import apiManager from '@/api/AxiosInstance';
import { productsFormAtom } from '@/store/products';
import { getFormatDateOnlyNum } from '@/utils/common';

const useFetchProductData = (id: string | undefined, shouldFetch: boolean) => {
  const setProductFormData = useSetAtom(productsFormAtom);
  const [productSearchParams, setProductSearchParams] = useState<ProductSearchParams>();

  useEffect(() => {
    const fetchData = async () => {
      if (shouldFetch && id) {
        try {
          const response = await apiManager.get(`/api/v1/products/${id}`);
          const responseData: ProductDetailResponse = response.data;
          setProductFormData(responseData);
          const { tons, model, loaded, loadedInnerLength, manufacturerCategories, axis, firstRegistrationDate } =
            responseData;
          const searchParams = {
            tons,
            model,
            loaded,
            loadedInnerLength,
            manufacturerCategories,
            axis,
            year: getFormatDateOnlyNum(firstRegistrationDate, ['year']),
          };
          setProductSearchParams(searchParams);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [id, shouldFetch, setProductFormData]);

  return productSearchParams;
};

export default useFetchProductData;

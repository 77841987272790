import React, { SVGProps } from 'react';

const SvgTruckBackSideSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M7.857 31c2.068 0 3.744-1.654 3.744-3.693s-1.676-3.692-3.744-3.692c-.444 0-.87.077-1.266.217A.923.923 0 0 1 6 25.463H4.613a3.636 3.636 0 0 0-.5 1.844C4.113 29.347 5.79 31 7.857 31Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M2.242 23.615H2a1 1 0 0 0-1 .987v1.718a1 1 0 0 0 1 .987h16.154c0-.51.419-.923.936-.923a.93.93 0 0 1 .936.923h5.634l.045.001h3.684v-.001a.93.93 0 0 1 .936-.923.93.93 0 0 1 .936.923v.001h4.878a1 1 0 0 0 1-1v-1.693a1 1 0 0 0-1-1h-.202v-3.496a.999.999 0 0 0-.073-.376l-.675-1.666h.252a.5.5 0 0 0 .5-.5V13.04a.5.5 0 0 0-.5-.5h-1.808a.5.5 0 0 0-.5.472l-.687-1.694a1 1 0 0 0-.926-.624h-3.07V8a1 1 0 0 0-1-1H3.243a1 1 0 0 0-1 1v15.615Zm26.209-4.1v-6.802a.504.504 0 0 1 .404-.207h2.09a.5.5 0 0 1 .464.312l2.52 6.216a.5.5 0 0 1-.462.688h-4.612a.5.5 0 0 1-.404-.206ZM3.178 24.54c0 .51.414.923.923.923H6a.923.923 0 0 0 0-1.846H4.101a.923.923 0 0 0-.923.923Zm9.349.923a.923.923 0 0 1 0-1.846h1.898a.923.923 0 0 1 0 1.846h-1.898Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M30.322 31c2.068 0 3.744-1.653 3.744-3.692s-1.676-3.692-3.744-3.692c-2.068 0-3.744 1.653-3.744 3.692 0 2.04 1.676 3.692 3.744 3.692Zm0-2.767a.93.93 0 0 0 .935-.923.93.93 0 0 0-.936-.923.93.93 0 0 0-.936.923c0 .51.42.923.936.923ZM19.092 31c2.068 0 3.744-1.653 3.744-3.692 0-2.04-1.676-3.692-3.744-3.692-2.068 0-3.744 1.653-3.744 3.692S17.024 31 19.092 31Zm-.001-2.767a.93.93 0 0 0 .936-.923.93.93 0 0 0-.936-.923.93.93 0 0 0-.936.923c0 .51.419.923.936.923Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M20.024 27.308a.93.93 0 0 1-.936.923.93.93 0 0 1-.936-.923c0-.51.42-.923.936-.923a.93.93 0 0 1 .936.923Z"
    />
  </svg>
);
SvgTruckBackSideSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgTruckBackSideSolidIcon;

import { atom } from 'jotai';

const initialRepairHistoryFormState: RepairForm = {
  id: null,
  repairDay: '',
  repairItems: [{ name: '', price: null }],
};

const initialRepairHistorySearchParamsState: RepairHistorySearchParams = {};

export const repairHistoryFormAtom = atom<RepairForm>(initialRepairHistoryFormState);
export const repairHistorySearchParamsAtom = atom<RepairHistorySearchParams>(initialRepairHistorySearchParamsState);

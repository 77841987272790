import React, { SVGProps } from 'react';

const SvgProfileNoneColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 52 52" {...props}>
    <rect width={52} height={52} fill="#DCDCDC" rx={26} />
    <path
      fill="#FAFAFA"
      fillRule="evenodd"
      d="M20 17.786C20 14.59 22.685 12 26 12s6 2.59 6 5.786c0 3.195-2.686 5.785-6 5.785s-6-2.59-6-5.785ZM15 35.921C15.103 30.15 19.988 25.5 26 25.5s10.897 4.649 11 10.422a.962.962 0 0 1-.583.893A25.697 25.697 0 0 1 26 39c-3.715 0-7.244-.782-10.417-2.186a.962.962 0 0 1-.583-.893Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgProfileNoneColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgProfileNoneColorIcon;

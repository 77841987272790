import React from 'react';

import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface ItemBoxWithRightArrowProps {
  data: KeyValueLabel[];
}

const ItemBoxWithRightArrow = ({ data }: ItemBoxWithRightArrowProps) => {
  return (
    <>
      <ul className="grid grid-cols-1 gap-3 w-full">
        <li className="flex items-center justify-between gap-3 p-3 border rounded-lg px-4">
          <div className="flex flex-1 flex-col gap-2">
            {data?.map((item) => (
              <div className="flex items-center text-base" key={item.key}>
                <span className="flex-1 text-gray-7 pr-[10px] text-ellipsis break-all line-clamp-1">{item.label}</span>
                <span className="flex-2 text-gray-8 text-ellipsis break-all line-clamp-1">{item.value}</span>
              </div>
            ))}
          </div>
          <ChevronRightIcon color={colors.gray[7]}></ChevronRightIcon>
        </li>
      </ul>
    </>
  );
};

export default ItemBoxWithRightArrow;

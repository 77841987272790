import React, { ReactNode, forwardRef, useState } from 'react';

import { colors } from '@/const/colors';
import '@/css/inputStyle.css';

interface SimpleInputProps {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  info?: boolean;
  infoMsg?: string;
  maxHeight?: number;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  type?: string;
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  suffix?: ReactNode;
  required?: boolean;
  maxLength?: number;
  tooltip?: ReactNode;
}

const TextInputLabelUp = forwardRef<HTMLInputElement, SimpleInputProps>(
  (
    {
      name,
      label,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      error,
      errorMsg,
      info,
      infoMsg,
      fontSize = 18,
      width,
      height,
      readOnly = false,
      type = 'text',
      inputMode = 'text',
      onKeyUp,
      onKeyDown,
      disabled = false,
      suffix,
      required = false,
      maxLength,
      tooltip,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      if (onFocus) onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (onBlur) onBlur(e);
    };

    return (
      <div className="relative w-full h-[60px] z-0">
        <label className="text-base mb-2">
          <span className="font-medium vertical-middle text-gray-8">{label}</span>
          {required && <span className="font-normal text-red ml-1">(필수)</span>}
          {tooltip}
        </label>
        <input
          className="simple-input z-10"
          ref={ref}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={onKeyUp}
          readOnly={readOnly}
          type={type}
          style={{
            fontSize: '18px',
            width: '100%',
            height: '36px',
            borderBottom: `1px solid ${error ? colors.red : isFocused ? colors.blue[3] : colors.gray[3]}`,
            backgroundColor: disabled ? colors.gray[1] : 'transparent',
            color: colors.gray[9],
            borderRadius: '0px',
          }}
          inputMode={inputMode}
          onKeyDown={onKeyDown}
          disabled={disabled}
          maxLength={maxLength}
        ></input>
        <span className="absolute text-gray-8 text-[18px] right-2 top-[30px]">{suffix}</span>

        {error && <p className="absolute text-red text-[13px] xxs:text-[12px] leading-[16px] top-[62px]">{errorMsg}</p>}

        {info && (
          <p className="absolute text-blue-4 text-[13px] xxs:text-[12px] leading-[16px] top-[62px]">{infoMsg}</p>
        )}
      </div>
    );
  },
);
export default TextInputLabelUp;

import React, { SVGProps } from 'react';

const SvgEmptyChatMessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 120 120" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M30 32a3 3 0 0 0-3 3v38.172c0 .097.005.192.013.285.016.167.01.33-.013.488v14.258l13.325-11.104a4 4 0 0 1 2.56-.927H90a3 3 0 0 0 3-3V35a3 3 0 0 0-3-3H30Zm-7 3a7 7 0 0 1 7-7h60a7 7 0 0 1 7 7v38.172a7 7 0 0 1-7 7H42.885L29.561 91.276C26.955 93.447 23 91.594 23 88.203V73.667c0-.058.003-.116.007-.173a7.177 7.177 0 0 1-.007-.322V35Z"
      clipRule="evenodd"
    />
    <circle cx={60.377} cy={54.839} r={3.763} fill={props.color || 'currentColor'} />
    <circle cx={74.677} cy={54.839} r={3.763} fill={props.color || 'currentColor'} />
    <circle cx={46.076} cy={54.839} r={3.763} fill={props.color || 'currentColor'} />
  </svg>
);
SvgEmptyChatMessageIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEmptyChatMessageIcon;

import React, { SVGProps } from 'react';

const SvgDocumentTextOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="m10.571 3.012-.072-.009A.516.516 0 0 0 10.44 3H6a.5.5 0 0 0-.5.5v17a.5.5 0 0 0 .5.5h12.5a.5.5 0 0 0 .5-.5V12c0-4.735-3.816-8.9-8.356-8.987l-.073-.001ZM4 3.5a2 2 0 0 1 2-2h4.44c.079 0 .156.005.232.013C16.115 1.616 20.5 6.535 20.5 12v8.5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-17Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M10.25 4a.75.75 0 0 1 .75-.75c1.042 0 1.806.409 2.271 1.106.43.644.538 1.437.538 2.115v1.47c0 .139.111.25.25.25h1.47c.678 0 1.471.109 2.115.538.697.465 1.106 1.229 1.106 2.271a.75.75 0 0 1-1.5 0c0-.605-.209-.87-.438-1.023-.283-.189-.725-.286-1.283-.286h-1.47a1.75 1.75 0 0 1-1.75-1.75v-1.47c0-.558-.098-1-.286-1.283-.153-.229-.418-.438-1.023-.438a.75.75 0 0 1-.75-.75ZM8.25 14.5a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM8.25 17.5a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgDocumentTextOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgDocumentTextOutlineIcon;

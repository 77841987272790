import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import MenuHeader from '@/components/Header/MenuHeader';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import useFetchProductData from '@/hooks/useFetchProductData';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { productsFormAtom, useResetProductsForm } from '@/store/products';
import { formatNumber } from '@/utils/common';
import { validatePrice } from '@/utils/validation';

type SalesTypeKey = keyof typeof SALESTYPE;

const PriceInputForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const productSearchParams = useFetchProductData(id, true);

  const productStatusRef = useRef<string>();
  const priceRef = useRef<HTMLInputElement>(null);
  const [priceError, setPriceError] = useState(false);
  const [priceErrorMsg, setPriceErrorMsg] = useState('');

  const checkForChanges = () => {
    return !!productFormData.price && prevData?.price !== productFormData.price;
  };

  const saveChanges = () => {
    const request = { id: Number(productFormData?.id), price: Number(productFormData?.price), status: 'BEFORE_SALE' };
    updateProductTemporarySaveMutation.mutate(request);
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    saveChanges,
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  const handleGoPrev = () => {
    navigate(`/products/sales/photo/${id}`, { replace: true });
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/${id}`,
    isFinalStep: true,
  });

  const handleFinishRegisterForm = () => {
    const request = {
      id: Number(productFormData?.id),
      price: Number(productFormData?.price),
      status: 'SALE',
    };
    updateProductNextStepMutation.mutate(request);
  };

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({});

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { isValid, errorMessage, formattedValue } = validatePrice(value);

    setProductFormData({
      ...productFormData,
      price: formattedValue,
    } as ProductDetailResponse);

    if (!isValid) {
      setPriceError(true);
      setPriceErrorMsg(errorMessage);
    } else {
      setPriceError(false);
      setPriceErrorMsg('');
    }
  };

  const scrollIntoView = () => {
    if (priceRef.current) {
      priceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => scrollIntoView();
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  }, []);

  useEffect(() => {
    productStatusRef.current = productFormData?.status.code;
  }, [productFormData]);

  return (
    <>
      <DefaultHelmet page="sale" />

      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full p-4 pb-24 pt-[60px]">
        <div className="py-4 pb-24 my-2">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">판매 금액을 입력해주세요.</h3>
            <RoundBadge text="9/9" />
          </div>
          <div className="flex flex-col gap-[30px] pt-[30px]">
            <TextInputLabelUp
              ref={priceRef}
              name="price"
              label="판매 금액"
              placeholder="판매 금액 입력"
              value={formatNumber(productFormData?.price?.toString(), true) || ''}
              onChange={handleChangePrice}
              onFocus={scrollIntoView}
              inputMode="numeric"
              error={priceError}
              errorMsg={priceErrorMsg}
              suffix="만원"
              maxLength={8}
            />
          </div>
          <div>
            <CarPriceTrendInfo priceSearchParams={productSearchParams} showPriceComparison={false} apiType="private" />
            <SimilarProductList similarSearchParams={productSearchParams} />
          </div>
          <DualFooterButton
            leftButtonText="이전"
            onClickLeftButton={handleGoPrev}
            rightButtonText="등록완료"
            disabledRight={!productFormData.price}
            onClickRightButton={handleFinishRegisterForm}
          />
        </div>
      </div>
      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />
    </>
  );
};

export default PriceInputForm;

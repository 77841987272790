import React, { SVGProps } from 'react';

const SvgSearchOutlineBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M10.678 18.965c4.784 0 8.678-3.805 8.678-8.482 0-4.676-3.894-8.482-8.678-8.482C5.894 2.001 2 5.807 2 10.483c0 4.677 3.894 8.482 8.678 8.482Zm0-14.137c3.19 0 5.785 2.538 5.785 5.655 0 3.118-2.595 5.655-5.785 5.655s-5.785-2.537-5.785-5.655c0-3.117 2.595-5.655 5.785-5.655Z"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M15.06 15.216a1.411 1.411 0 0 1 1.963 0l4.572 4.469c.542.53.542 1.389 0 1.919-.543.53-1.422.53-1.964 0l-4.572-4.469a1.336 1.336 0 0 1 0-1.919Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgSearchOutlineBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSearchOutlineBoldIcon;

import React, { SVGProps } from 'react';

const SvgLicensePlateBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M32 11.5H6A1.5 1.5 0 0 0 4.5 13v11.833a1.5 1.5 0 0 0 1.5 1.5h26a1.5 1.5 0 0 0 1.5-1.5V13a1.5 1.5 0 0 0-1.5-1.5ZM6 9a4 4 0 0 0-4 4v11.833a4 4 0 0 0 4 4h26a4 4 0 0 0 4-4V13a4 4 0 0 0-4-4H6Zm17.7 10.714 2.512-3.954h2.067v3.945H29v1.278h-.72v.986h-1.56v-.986h-3.02v-1.27Zm3.053-.009v-2.247H26.7l-1.39 2.204v.043h1.442Zm-7.625 2.097a3.2 3.2 0 0 0 1.29.248c.498 0 .941-.078 1.33-.236.389-.16.692-.38.909-.66.217-.283.326-.605.326-.965 0-.389-.129-.707-.386-.956-.255-.252-.623-.405-1.106-.459v-.051a1.66 1.66 0 0 0 .651-.202c.192-.111.34-.257.446-.437.109-.183.163-.388.163-.613 0-.344-.098-.65-.296-.922a1.921 1.921 0 0 0-.819-.644 2.913 2.913 0 0 0-1.2-.236 2.93 2.93 0 0 0-1.231.25c-.363.162-.648.39-.853.68a1.717 1.717 0 0 0-.31.991h1.562a.592.592 0 0 1 .111-.338.677.677 0 0 1 .296-.236c.126-.057.267-.086.425-.086a.86.86 0 0 1 .381.082.619.619 0 0 1 .266.231.569.569 0 0 1 .09.33.556.556 0 0 1-.107.348.708.708 0 0 1-.309.236 1.162 1.162 0 0 1-.459.085h-.626v1.133h.626c.19 0 .356.028.502.085a.774.774 0 0 1 .339.24c.08.103.12.22.12.352a.601.601 0 0 1-.107.352.713.713 0 0 1-.296.24 1.025 1.025 0 0 1-.429.086c-.163 0-.31-.028-.442-.082a.728.728 0 0 1-.308-.227.546.546 0 0 1-.116-.326h-1.647c.003.374.112.71.326 1.008.215.297.51.53.888.699Zm-6.61-1.012 2.307-1.939c.18-.154.323-.287.429-.398.106-.112.187-.228.244-.348a.87.87 0 0 0 .09-.394.727.727 0 0 0-.231-.566.828.828 0 0 0-.592-.215c-.243 0-.439.073-.588.219-.145.146-.218.35-.218.613h-1.553c0-.426.098-.796.292-1.11.194-.318.469-.56.823-.73.358-.168.772-.253 1.244-.253.483 0 .906.079 1.27.236a1.9 1.9 0 0 1 .844.67c.197.285.296.618.296.998 0 .252-.052.494-.155.725-.1.232-.277.489-.531.772a10.52 10.52 0 0 1-1.072 1.008l-.626.54v.034h2.453v1.313h-4.726V20.79ZM9.59 21.97h1.638v-6.21h-1.51l-1.534.935v1.398l1.372-.823h.034v4.7ZM8 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm25-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1-9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgLicensePlateBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgLicensePlateBoldIcon;

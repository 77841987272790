import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { createProductsImagesMulti } from '@/api/products/createProductsImages';
import BasicButton from '@/components/Common/Button/BasicButton';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import ImageUploader from '@/components/Common/Uploader/ImageUploader';
import { PlusSmallIcon, UploadOutlineIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';

type UploadType = 'all' | 'additional';

const TOTAL_MAX_FILE_SIZE = 13;
const ADDITIONAL_MAX_FILE_SIZE = 10;

const SAMPLE_IMAGE_URL_1 = 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/sample_1.png';
const SAMPLE_IMAGE_URL_2 = 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/sample_2.png';
const SAMPLE_IMAGE_URL_3 = 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/sample_3.png';

interface VehicleImageUploaderProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
  updateDisabledBtn?: (value: boolean) => void;
}

interface ProductImageComponentType {
  item: productImageType;
  truckNumber: string;
  index: number;
  productImageListData: productImageType[];
  setProductImageListData: React.Dispatch<React.SetStateAction<productImageType[]>>;
  loading?: boolean;
}

const ProductImageComponent = ({
  item,
  truckNumber,
  index,
  productImageListData,
  setProductImageListData,
  loading,
}: ProductImageComponentType) => {
  const [file, setFile] = useState(productImageListData[index]);

  useEffect(() => {
    const updatedList = productImageListData.map((image: productImageType) =>
      image.id === file.id ? { ...image, imageUrl: file.imageUrl } : image,
    );
    setProductImageListData(updatedList);
  }, [file]);

  const onClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation();

    const updatedList = productImageListData.map((image) =>
      image.id === item.id ? { ...image, imageUrl: '' } : image,
    );

    let count = 0;
    const sortedList = updatedList
      .sort((a, b) => {
        if (a.imageUrl && !b.imageUrl) return -1;
        if (!a.imageUrl && b.imageUrl) return 1;
        return 0;
      })
      .map((image) => {
        if (image.imageUrl !== '') {
          count++;
          return { ...image, imageTitle: `추가 ${count}` };
        } else {
          return { ...image, imageTitle: '' };
        }
      });

    setProductImageListData(sortedList);
  };

  return (
    <div className="relative">
      <XMarkIcon
        className="absolute z-10 top-1 right-1"
        color={colors.gray[0]}
        style={{ filter: 'drop-shadow(0 0 2px rgba(0, 0, 0, 0.7))' }}
        onClick={(e) => onClickDelete(e)}
      />
      <ImageUploader
        truckNumber={truckNumber}
        imageType={item.imageType}
        fileData={item}
        setFileData={setFile}
        icon="photo"
        accept="image/*"
        isShowXmark={false}
        isLoading={loading}
      />
    </div>
  );
};

const VehicleImageUploader = ({ productInfo, setProductInfo, updateDisabledBtn }: VehicleImageUploaderProps) => {
  const { showToast } = useToastContext();
  const { id } = useParams();

  const [isShowImageLimitPopup, setIsShowImageLimitPopup] = useState(false);
  const [pendingFiles, setPendingFiles] = useState<File[]>();
  const [uploadType, setUploadType] = useState<UploadType>();

  const [productFormData, setProductFormData] = useState<ProductDetailResponse>();

  useEffect(() => {
    setProductFormData(productInfo);
  }, []);

  const [loadingList, setLoadingList] = useState<boolean[]>([]);

  const [certificateData, setCerticicateData] = useState<productImageType>({
    id: 'certificateImageUrl',
    imageTitle: '차량등록증',
    imageUrl: productFormData?.productsImage?.certificateImageUrl || '',
    imageType: 'CERTIFICATE_IMAGE',
    sampleImageUrl: '',
    isRequired: false,
  });

  const [frontSideImageData, setFrontSideImageData] = useState<productImageType>({
    id: 'frontSideImageUrl',
    imageTitle: '앞측면',
    imageUrl: productFormData?.productsImage?.frontSideImageUrl || '',
    imageType: 'FRONT_SIDE_IMAGE',
    sampleImageUrl: SAMPLE_IMAGE_URL_1,
    isRequired: true,
  });

  const [backSideImageData, setBackSideImageData] = useState<productImageType>({
    id: 'backSideImageUrl',
    imageTitle: '뒷측면',
    imageUrl: productFormData?.productsImage?.frontSideImageUrl || '',
    imageType: 'BACK_SIDE_IMAGE',
    sampleImageUrl: SAMPLE_IMAGE_URL_2,

    isRequired: true,
  });

  const [frontImageData, setFrontImageData] = useState<productImageType>({
    id: 'frontImageUrl',
    imageTitle: '전면',
    imageUrl: productFormData?.productsImage?.frontSideImageUrl || '',
    imageType: 'FRONT_IMAGE',
    sampleImageUrl: SAMPLE_IMAGE_URL_3,

    isRequired: true,
  });

  const [optionalImageList, setOptionalImageList] = useState<productImageType[]>(() => {
    let visibleIndex = 0;

    return [
      {
        id: 'backImageUrl',
        imageTitle: productFormData?.productsImage?.backImageUrl ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.backImageUrl || '',
        imageType: 'BACK_IMAGE',
      },
      {
        id: 'tireImageUrl',
        imageTitle: productFormData?.productsImage?.tireImageUrl ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.tireImageUrl || '',
        imageType: 'TIRE_IMAGE',
      },
      {
        id: 'engineImageUrl',
        imageTitle: productFormData?.productsImage?.engineImageUrl ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.engineImageUrl || '',
        imageType: 'ENGINE_IMAGE',
      },
      {
        id: 'insideImageUrl',
        imageTitle: productFormData?.productsImage?.insideImageUrl ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.insideImageUrl || '',
        imageType: 'INSIDE_IMAGE',
      },
      {
        id: 'dashboardImageUrl',
        imageTitle: productFormData?.productsImage?.dashboardImageUrl ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.dashboardImageUrl || '',
        imageType: 'DASHBOARD_IMAGE',
      },
      {
        id: 'sheetImageUrl',
        imageTitle: productFormData?.productsImage?.sheetImageUrl ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.sheetImageUrl || '',
        imageType: 'SHEET_IMAGE',
      },
      {
        id: 'optionImageUrl1',
        imageTitle: productFormData?.productsImage?.optionImageUrl?.[0] ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.optionImageUrl?.[0] || '',
        imageType: 'OPTION_IMAGE',
      },
      {
        id: 'optionImageUrl2',
        imageTitle: productFormData?.productsImage?.optionImageUrl?.[1] ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.optionImageUrl?.[1] || '',
        imageType: 'OPTION_IMAGE',
      },
      {
        id: 'optionImageUrl3',
        imageTitle: productFormData?.productsImage?.optionImageUrl?.[2] ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.optionImageUrl?.[2] || '',
        imageType: 'OPTION_IMAGE',
      },
      {
        id: 'optionImageUrl4',
        imageTitle: productFormData?.productsImage?.optionImageUrl?.[3] ? `추가 ${++visibleIndex}` : '',
        imageUrl: productFormData?.productsImage?.optionImageUrl?.[3] || '',
        imageType: 'OPTION_IMAGE',
      },
    ];
  });

  const onClickUploadAll = (type: UploadType) => {
    setUploadType(type);

    const inputElement =
      type === 'all'
        ? (document.getElementById(`upload-images-all`) as HTMLInputElement)
        : (document.getElementById(`upload-additional-images-all`) as HTMLInputElement);
    if (inputElement) {
      inputElement.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: UploadType) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const filesList = e.target.files;

    const registeredOptionalImageCount = optionalImageList.filter((item) => item.imageUrl !== '').length || 0;

    const maxFiles = type === 'all' ? TOTAL_MAX_FILE_SIZE : ADDITIONAL_MAX_FILE_SIZE - registeredOptionalImageCount;
    const filesArray = Array.from(filesList);

    if (filesArray.length > maxFiles) {
      setPendingFiles(filesArray.slice(0, maxFiles));
      setIsShowImageLimitPopup(true);
    } else {
      uploadImages(filesList);
    }

    e.target.value = '';
  };

  const uploadImages = async (filesList: FileList) => {
    const formData = new FormData();
    const filesArray = Array.from(filesList);

    if (uploadType === 'all') {
      setLoadingList(Array.from({ length: filesList.length }, () => true));
    } else {
      const registeredOptionalImageCount = optionalImageList.filter((item) => item.imageUrl !== '').length || 0;
      setLoadingList(
        Array.from({ length: filesList.length + registeredOptionalImageCount + 3 }, (_, index) =>
          index < 3 + registeredOptionalImageCount ? false : true,
        ),
      );
    }

    filesArray.forEach((file) => {
      formData.append('uploadFile', file);
    });
    formData.append('truckNumber', productFormData?.truckNumber || '');

    try {
      const response = await createProductsImagesMulti(formData);

      if (response.data) {
        const uploadedFiles = response.data;

        if (uploadType === 'all') {
          setFrontSideImageData({
            id: 'frontSideImageUrl',
            imageTitle: '앞측면',
            imageUrl: uploadedFiles[0]?.url || '',
            imageType: 'FRONT_SIDE_IMAGE',
            sampleImageUrl: SAMPLE_IMAGE_URL_1,
            isRequired: true,
          });

          setBackSideImageData({
            id: 'backSideImageUrl',
            imageTitle: '뒷측면',
            imageUrl: uploadedFiles[1]?.url || '',
            imageType: 'BACK_SIDE_IMAGE',
            sampleImageUrl: SAMPLE_IMAGE_URL_2,
            isRequired: true,
          });

          setFrontImageData({
            id: 'frontImageUrl',
            imageTitle: '전면',
            imageUrl: uploadedFiles[2]?.url || '',
            imageType: 'FRONT_IMAGE',
            sampleImageUrl: SAMPLE_IMAGE_URL_3,
            isRequired: true,
          });

          const optionalList = optionalImageList.map((image, index) => ({
            id: image.id,
            imageTitle: `추가 ${index + 1}` || '',
            imageUrl: uploadedFiles[index + 3]?.url || '',
            imageType: image.imageType,
          }));

          setOptionalImageList(optionalList);

          const optionImagesUrls: string[] = optionalList
            .filter((item, index) => index > 5)
            .map((item) => item.imageUrl);

          const request: any = {
            certificateImageUrl: certificateData.imageUrl,
            frontSideImageUrl: frontSideImageData.imageUrl,
            backSideImageUrl: backSideImageData.imageUrl,
            frontImageUrl: frontImageData.imageUrl,
            backImageUrl: optionalList[0].imageUrl,
            tireImageUrl: optionalList[1].imageUrl,
            engineImageUrl: optionalList[2].imageUrl,
            insideImageUrl: optionalList[3].imageUrl,
            dashboardImageUrl: optionalList[4].imageUrl,
            sheetImageUrl: optionalImageList[5].imageUrl,
            optionImageUrl: optionImagesUrls,
            id: productInfo?.productsImage.id || 0,
            engineVideoUrl: '',
          };

          setProductInfo((prevInfo) => ({
            ...prevInfo,
            productsImage: request,
          }));
        } else if (uploadType === 'additional') {
          const sortedOptionalImageList = [...optionalImageList].sort((a, b) => {
            if (a.imageUrl && !b.imageUrl) return -1;
            if (!a.imageUrl && b.imageUrl) return 1;
            return 0;
          });

          let fileIndex = 0;
          const optionalList = sortedOptionalImageList.map((image, index) => {
            let imageUrl = image.imageUrl;

            if (!imageUrl) {
              while (fileIndex < uploadedFiles.length && !uploadedFiles[fileIndex]?.url) {
                fileIndex++;
              }
              imageUrl = uploadedFiles[fileIndex]?.url || '';
              fileIndex++;
            }

            return {
              id: image.id,
              imageTitle: `추가 ${index + 1}`,
              imageUrl,
              imageType: image.imageType,
            };
          });

          setOptionalImageList(optionalList);

          const optionImagesUrls: string[] = optionalList
            .filter((item, index) => index > 5)
            .map((item) => item.imageUrl);

          const request: any = {
            certificateImageUrl: certificateData.imageUrl,
            frontSideImageUrl: frontSideImageData.imageUrl,
            backSideImageUrl: backSideImageData.imageUrl,
            frontImageUrl: frontImageData.imageUrl,
            backImageUrl: optionalList[0].imageUrl,
            tireImageUrl: optionalList[1].imageUrl,
            engineImageUrl: optionalList[2].imageUrl,
            insideImageUrl: optionalList[3].imageUrl,
            dashboardImageUrl: optionalList[4].imageUrl,
            sheetImageUrl: optionalImageList[5].imageUrl,
            optionImageUrl: optionImagesUrls,
            id: productInfo?.productsImage.id || 0,
            engineVideoUrl: '',
          };

          setProductInfo((prevInfo) => ({
            ...prevInfo,
            productsImage: request,
          }));
        }
      } else {
        console.error('File upload failed');
      }
    } catch (error) {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    } finally {
      setLoadingList((prev) => {
        return prev.map(() => false);
      });
    }
  };

  useEffect(() => {
    if (productFormData?.id === 0) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          setProductFormData(responseData);
        })
        .catch(() => {});
    } else {
      setCerticicateData({
        id: 'certificateImageUrl',
        imageTitle: '차량등록증',
        imageUrl: productFormData?.productsImage?.certificateImageUrl || '',
        imageType: 'CERTIFICATE_IMAGE',
      });

      setFrontSideImageData({
        id: 'frontSideImageUrl',
        imageTitle: '앞측면',
        imageUrl: productFormData?.productsImage?.frontSideImageUrl || '',
        imageType: 'FRONT_SIDE_IMAGE',
        sampleImageUrl: SAMPLE_IMAGE_URL_1,
        isRequired: true,
      });

      setBackSideImageData({
        id: 'backSideImageUrl',
        imageTitle: '뒷측면',
        imageUrl: productFormData?.productsImage?.backSideImageUrl || '',
        imageType: 'BACK_SIDE_IMAGE',
        sampleImageUrl: SAMPLE_IMAGE_URL_2,
        isRequired: true,
      });

      setFrontImageData({
        id: 'frontImageUrl',
        imageTitle: '전면',
        imageUrl: productFormData?.productsImage?.frontImageUrl || '',
        imageType: 'FRONT_IMAGE',
        sampleImageUrl: SAMPLE_IMAGE_URL_3,
        isRequired: true,
      });

      let visibleIndex = 0;

      setOptionalImageList([
        {
          id: 'backImageUrl',
          imageTitle: productFormData?.productsImage?.backImageUrl ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.backImageUrl || '',
          imageType: 'BACK_IMAGE',
        },
        {
          id: 'tireImageUrl',
          imageTitle: productFormData?.productsImage?.tireImageUrl ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.tireImageUrl || '',
          imageType: 'TIRE_IMAGE',
        },
        {
          id: 'engineImageUrl',
          imageTitle: productFormData?.productsImage?.engineImageUrl ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.engineImageUrl || '',
          imageType: 'ENGINE_IMAGE',
        },
        {
          id: 'insideImageUrl',
          imageTitle: productFormData?.productsImage?.insideImageUrl ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.insideImageUrl || '',
          imageType: 'INSIDE_IMAGE',
        },
        {
          id: 'dashboardImageUrl',
          imageTitle: productFormData?.productsImage?.dashboardImageUrl ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.dashboardImageUrl || '',
          imageType: 'DASHBOARD_IMAGE',
        },
        {
          id: 'sheetImageUrl',
          imageTitle: productFormData?.productsImage?.sheetImageUrl ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.sheetImageUrl || '',
          imageType: 'SHEET_IMAGE',
        },
        {
          id: 'optionImageUrl1',
          imageTitle: productFormData?.productsImage?.optionImageUrl?.[0] ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.optionImageUrl?.[0] || '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl2',
          imageTitle: productFormData?.productsImage?.optionImageUrl?.[1] ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.optionImageUrl?.[1] || '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl3',
          imageTitle: productFormData?.productsImage?.optionImageUrl?.[2] ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.optionImageUrl?.[2] || '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl4',
          imageTitle: productFormData?.productsImage?.optionImageUrl?.[3] ? `추가 ${++visibleIndex}` : '',
          imageUrl: productFormData?.productsImage?.optionImageUrl?.[3] || '',
          imageType: 'OPTION_IMAGE',
        },
      ]);
    }
  }, [productFormData]);

  const handleClickSend = () => {
    if (pendingFiles) {
      if (pendingFiles instanceof FileList) {
        uploadImages(pendingFiles);
      } else if (Array.isArray(pendingFiles)) {
        const fileList = new DataTransfer();
        pendingFiles.forEach((file) => {
          fileList.items.add(file);
        });
        uploadImages(fileList.files);
      } else {
        console.error('Invalid pendingFiles type.');
      }
    }
    setIsShowImageLimitPopup(false);
  };

  const handleClickReSelect = () => {
    setIsShowImageLimitPopup(false);

    if (uploadType) {
      onClickUploadAll(uploadType);
    }
  };

  useEffect(() => {
    const optionImagesUrls: string[] = optionalImageList
      .filter((item, index) => index > 5)
      .map((item) => item.imageUrl);

    const request: ProductsImages = {
      certificateImageUrl: certificateData.imageUrl,
      frontSideImageUrl: frontSideImageData.imageUrl,
      backSideImageUrl: backSideImageData.imageUrl,
      frontImageUrl: frontImageData.imageUrl,
      backImageUrl: optionalImageList?.[0].imageUrl || '',
      tireImageUrl: optionalImageList?.[1].imageUrl || '',
      engineImageUrl: optionalImageList?.[2].imageUrl || '',
      insideImageUrl: optionalImageList?.[3].imageUrl || '',
      dashboardImageUrl: optionalImageList?.[4].imageUrl || '',
      sheetImageUrl: optionalImageList?.[5].imageUrl || '',
      optionImageUrl: optionImagesUrls,
      id: productInfo.productsImage.id,
      engineVideoUrl: '',
    };
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      productsImage: request,
    }));
    if (updateDisabledBtn) {
      updateDisabledBtn(!frontSideImageData.imageUrl || !backSideImageData.imageUrl || !frontImageData.imageUrl);
    }
  }, [optionalImageList, certificateData, frontSideImageData, backSideImageData, frontImageData]);

  return (
    <>
      <div className="text-base">
        <div className="flex items-center justify-between mb-2">
          <label className="text-base font-semibold text-gray-8">차량 사진</label>

          <BasicButton
            name="한번에 불러오기"
            icon={<UploadOutlineIcon color={colors.primary} width={14} height={14} />}
            bgColor={colors.blue[1]}
            borderColor={colors.blue[1]}
            textColor={colors.primary}
            fontSize={12}
            width={'auto'}
            height={24}
            fontWeight="semibold"
            shadow={false}
            borderRadius={8}
            isDisabled={loadingList.some((item) => item === true)}
            onClick={() => onClickUploadAll('all')}
          />
          <input
            id="upload-images-all"
            accept="image/*"
            multiple
            type="file"
            onChange={(e) => {
              onUpload(e, 'all');
              e.target.value = '';
            }}
            className="hidden"
          />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <ImageUploader
            truckNumber={productFormData?.truckNumber || ''}
            imageType={frontSideImageData.imageType}
            fileData={frontSideImageData}
            setFileData={setFrontSideImageData}
            isCoverGradient
            icon="photo"
            accept="image/*"
            isLoading={loadingList[0]}
          />
          <ImageUploader
            truckNumber={productFormData?.truckNumber || ''}
            imageType={backSideImageData.imageType}
            fileData={backSideImageData}
            setFileData={setBackSideImageData}
            isCoverGradient
            icon="photo"
            accept="image/*"
            isLoading={loadingList[1]}
          />
          <ImageUploader
            truckNumber={productFormData?.truckNumber || ''}
            imageType={frontImageData.imageType}
            fileData={frontImageData}
            setFileData={setFrontImageData}
            isCoverGradient
            icon="photo"
            accept="image/*"
            isLoading={loadingList[2]}
          />
        </div>

        <div className="grid grid-cols-3 gap-2 mt-2">
          {optionalImageList?.map((item, idx) => {
            const loading = loadingList[idx + 3];
            return loading || !!item.imageUrl ? (
              <div className="flex flex-col" key={item.id}>
                <ProductImageComponent
                  item={item}
                  truckNumber={productFormData?.truckNumber || ''}
                  index={idx}
                  productImageListData={optionalImageList}
                  setProductImageListData={setOptionalImageList}
                  loading={loading}
                />
              </div>
            ) : null;
          })}
          {optionalImageList.filter((item) => !!item.imageUrl).length < ADDITIONAL_MAX_FILE_SIZE &&
            (loadingList.every((item) => item === false) || loadingList.length === 0) && (
              <div
                className="relative flex flex-col items-center justify-center sm280:h-[80px] sm280:text-[12px]  h-[104px] rounded-lg text-[14px] min-w-[80px] overflow-hidden whitespace-no-wrap overflow-ellipsis bg-gray-2 text-gray-6 cursor-pointer"
                onClick={() => onClickUploadAll('additional')}
              >
                <PlusSmallIcon color={colors.gray[5]} width={35} height={34} />
                <span className="text-gray-5">추가</span>
                <span>
                  {optionalImageList.filter((item) => !!item.imageUrl).length}/{ADDITIONAL_MAX_FILE_SIZE}
                </span>
                <input
                  id="upload-additional-images-all"
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e) => {
                    onUpload(e, 'additional');
                    e.target.value = '';
                  }}
                  className="hidden"
                />
              </div>
            )}
        </div>
      </div>
      <div className="my-2">
        <div className="py-2 text-base">
          <label className="text-base font-semibold text-gray-8">차량등록증</label>
          <div className="grid grid-cols-3 gap-2 mt-2">
            <ImageUploader
              truckNumber={productFormData?.truckNumber || ''}
              imageType="CERTIFICATE_IMAGE"
              fileData={certificateData}
              setFileData={setCerticicateData}
              icon="file"
              accept="image/*, application/pdf"
              isShowTitle={false}
            />
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={isShowImageLimitPopup}
        content={
          <span>
            이미지는 최대 {uploadType === 'all' ? TOTAL_MAX_FILE_SIZE : ADDITIONAL_MAX_FILE_SIZE}개까지
            <br />
            불러올 수 있습니다.
            <br />
            초과된 파일을 제외하고 불러올까요?
          </span>
        }
        leftButtonLabel="재선택"
        onLeftButtonClick={() => handleClickReSelect()}
        rightButtonLabel="불러오기"
        onRightButtonClick={() => handleClickSend()}
      />
    </>
  );
};

export default VehicleImageUploader;

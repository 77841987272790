import React, { SVGProps } from 'react';

const SvgCalendarColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width={31.579} height={31.579} x={4.211} y={4.211} fill="#fff" rx={3.158} />
    <path
      fill="#E84A53"
      d="M4.21 7.368A3.158 3.158 0 0 1 7.37 4.211h25.263a3.158 3.158 0 0 1 3.158 3.157v4.211H4.21v-4.21Z"
    />
    <path
      fill="#414141"
      d="M16.502 29.684h-3.141v-9.013h-.066l-2.632 1.579v-2.68l2.944-1.794h2.895v11.908Zm7.582.165c-1.069 0-1.992-.239-2.77-.716-.78-.482-1.38-1.181-1.802-2.097-.416-.92-.625-2.023-.625-3.306 0-1.288.209-2.39.625-3.306.422-.92 1.023-1.62 1.801-2.097.779-.477 1.702-.715 2.771-.715 1.07 0 1.993.241 2.772.724.778.477 1.376 1.176 1.793 2.097.422.915.633 2.014.633 3.297 0 1.289-.211 2.393-.633 3.314-.417.916-1.015 1.612-1.793 2.09-.779.476-1.702.715-2.772.715Zm0-2.517c.61-.01 1.086-.31 1.431-.896.351-.587.527-1.489.527-2.706 0-.8-.083-1.469-.247-2.006-.164-.538-.392-.938-.683-1.201a1.482 1.482 0 0 0-1.027-.395c-.598 0-1.072.302-1.423.905s-.53 1.502-.535 2.697c0 1.217.173 2.12.518 2.706.351.587.83.885 1.44.896Z"
    />
  </svg>
);
SvgCalendarColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCalendarColorIcon;

import React, { SVGProps } from 'react';

const SvgResetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#reset-icon_svg__a)">
      <path
        fill={props.color || 'currentColor'}
        d="M6.555 18.219c-.267.305-.238.772.088 1.013a9 9 0 1 0-3.631-6.763c.021.405.394.687.796.633.402-.054.68-.424.665-.83a7.531 7.531 0 1 1 3.141 5.85c-.33-.236-.792-.208-1.059.097Z"
      />
      <path
        stroke={props.color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m1.5 11 2.262 2.5 2.5-2.5"
      />
    </g>
    <defs>
      <clipPath id="reset-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
SvgResetIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgResetIcon;

import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ChatOutlineIcon,
  EllipsisHorizontalIcon,
  HomeOutlineIcon,
  SearchOutlineIcon,
  SellOutlineIcon,
} from '@/components/Icon';
import { THEUNBAN } from '@/const/referralCode';
import { useChatContext } from '@/contexts/Chat/ChatContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import usePartnerSession from '@/hooks/usePartnerSession';
import { isExistNotReadChatMessageAtom } from '@/store/chatting';

type FooterProps = {
  isMain?: boolean;
};

const Footer = ({ isMain }: FooterProps) => {
  const { unreadMessages } = useChatContext();
  const authRedirect = useAuthRedirect();
  const navigate = useNavigate();
  const [isExistNotReadChatMessage] = useAtom(isExistNotReadChatMessageAtom);
  const setIsExistNotReadChatMessage = useSetAtom(isExistNotReadChatMessageAtom);
  const isTheunban = usePartnerSession() === THEUNBAN;

  const menuList: Menu[] = isTheunban
    ? [
        {
          name: '내차사기',
          key: 'purchase',
          link: '/products',
          icon: SearchOutlineIcon,
        },
        {
          name: '채팅',
          key: 'chat',
          link: '/chatting',
          icon: ChatOutlineIcon,
        },
      ]
    : [
        {
          name: '홈',
          key: 'home',
          link: '/',
          icon: HomeOutlineIcon,
        },
        {
          name: '내차구매',
          key: 'purchase',
          link: '/products',
          icon: SearchOutlineIcon,
        },
        {
          name: '내차판매',
          key: 'sale',
          link: '/sell-car',
          icon: SellOutlineIcon,
        },
        {
          name: '채팅',
          key: 'chat',
          link: '/chatting',
          icon: ChatOutlineIcon,
        },
        {
          name: '더보기',
          key: 'more',
          link: '/more',
          icon: EllipsisHorizontalIcon,
        },
      ];

  useEffect(() => {
    const isEmpty = (obj: { [chatRoomId: number]: number }) => {
      return Object.keys(obj).length === 0;
    };

    if (!isEmpty(unreadMessages)) {
      setIsExistNotReadChatMessage(Object.values(unreadMessages).some((item) => item > 0));
    }
  }, [unreadMessages]);

  const handleNavigation = (link: string) => {
    const authRequiredMenuItems = ['/sell-car', '/chatting'];
    if (authRequiredMenuItems.includes(link) && !authRedirect(window.location.pathname)) {
      return;
    }

    navigate(link);
  };

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 bg-white w-full mx-auto my-0 max-w-[720px] min-w-[280px] text-sm">
        {isMain && (
          <ul className="flex shadow-[0_-2px_4px_rgba(0,0,0,0.05)] py-2">
            {menuList.map((menu, index) => (
              <li className={`text-center flex justify-center ${isTheunban ? 'w-1/2' : 'w-1/5'}`} key={menu.key}>
                <button
                  onClick={() => handleNavigation(menu.link)}
                  className={`flex flex-col justify-center items-center relative ${
                    menu.link === window.location.pathname ? 'active-link' : ''
                  }`}
                  style={{
                    color: menu.link === window.location.pathname ? '#1e40af' : 'gray',
                    fontWeight: menu.link === window.location.pathname ? '600' : 'normal',
                  }}
                >
                  <div className="relative">
                    {React.createElement(menu.icon, {
                      color: menu.link === window.location.pathname ? '#1e40af' : '#919191',
                      strokeWidth: 0.1,
                      alt: `${menu.name} 아이콘`,
                      width: '28',
                      height: '28',
                    })}
                    {isExistNotReadChatMessage && menu.key === 'chat' && (
                      <div className="absolute top-0 right-[-4px] w-1 h-1 bg-red rounded"></div>
                    )}
                  </div>
                  {menu.name}
                </button>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </>
  );
};

export default Footer;

import React from 'react';
import { useLocation } from 'react-router-dom';

import BasicFooterButton from '@/components/Common/Button/BasicFooterButton';
import MenuHeader from '@/components/Header/MenuHeader';
import { CEO_NUMBER, COMPLETED } from '@/const/common';
import { formatJobTitle, formatPrice, formatTimeRange, phoneCall } from '@/utils/common';

interface JobDetailInfo {
  key: string;
  value: string;
}

const JobDetail = () => {
  const location = useLocation();
  const data = location.state.data;
  const isButtonDisable = data.status.code === COMPLETED;

  const detailInfo = [
    { key: '운송 품목', value: data.transportItem },
    { key: '운송 구간', value: data.transportSection },
    { key: '적재함 길이', value: data.loadedInnerLength + 'm' },
    { key: '근무 기간', value: data.period?.desc },
    { key: '근무 요일', value: data.workingDays?.desc },
    { key: '근무 시간', value: formatTimeRange(data.workingStartHour, data.workingEndHour) },
    { key: '급여', value: formatPrice(data.salary.toString()) + ' (' + data.salaryType?.desc + ')' },
  ] as JobDetailInfo[];

  const renderTextContent = (textContent: string) => {
    const paragraphs = textContent.split('\n');
    return (
      <>
        {paragraphs.map((paragraph, index) => (
          <span key={index}>
            {index > 0 && <br />}
            {paragraph}
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      <MenuHeader title={formatJobTitle(data)}></MenuHeader>

      <div className="flex flex-col w-full pt-[60px] pb-[100px] overflow-scroll">
        <div className="px-4 border-b-8 border-gray-1 py-6">
          <p className="text-gray-8 font-semibold text-lg">상세 내용</p>
          <div className="flex flex-col gap-3 bg-gray-1 rounded-[4px] p-[18px]">
            {detailInfo.map(
              (item, key) =>
                item.value && (
                  <div className="flex items-center" key={key}>
                    <span className="flex-1 text-gray-7 text-base pr-4">{item.key}</span>
                    <span className="text-base text-gray-8">{item.value}</span>
                  </div>
                ),
            )}
          </div>
        </div>

        <div className="px-4 py-6">
          <p className="text-gray-8 font-semibold text-lg">상세 내역</p>
          <div className="bg-gray-1 rounded-[4px] p-[18px]">{renderTextContent(data.detailContents)}</div>
        </div>
      </div>
      <BasicFooterButton
        text="전화 문의"
        onClick={() => phoneCall(CEO_NUMBER)}
        disabled={isButtonDisable}
      ></BasicFooterButton>
    </>
  );
};

export default JobDetail;

import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

interface LoadingComponentProps {
  size?: number;
  margin?: number;
}

const LoadingComponent = ({ size = 40, margin = 11 }: LoadingComponentProps) => {
  return (
    <>
      <div className="relative flex flex-col justify-center items-center">
        <CircularProgress
          variant="determinate"
          sx={{
            color: ' #e5e7eb',
            margin: `${margin}px`,
            opacity: '1',
          }}
          size={size}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#1e40af;',
            animationDuration: '1500ms',
            position: 'absolute',
            margin: `${margin}px`,
            left: 0,
            opacity: '1',
          }}
          size={size}
          thickness={4}
        />
      </div>
    </>
  );
};

export default LoadingComponent;

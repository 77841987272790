import React, { SVGProps } from 'react';

const SvgHeadphoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M21.496 17.54V11.6h-.01c-.113-2.575-1.14-5.02-2.887-6.852-1.855-1.943-4.37-3.035-6.994-3.035-2.623 0-5.138 1.092-6.993 3.035-1.748 1.831-2.774 4.277-2.887 6.852h-.01v5.938c.011 1.083.893 1.975 1.978 1.975H6.66V11.6H3.706c.111-2.077.948-4.03 2.337-5.486 1.491-1.563 3.495-2.423 5.562-2.423 2.069 0 4.072.86 5.563 2.423 1.39 1.456 2.226 3.41 2.337 5.486h-2.954v7.913h.989v.994h-3.05a1.484 1.484 0 0 0-1.4-.988h-1.977a1.484 1.484 0 1 0 0 2.967h1.978l.067-.001h5.371a.989.989 0 0 0 .989-.99v-1.982a1.978 1.978 0 0 0 1.978-1.975Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgHeadphoneIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgHeadphoneIcon;

import React, { SVGProps } from 'react';

const SvgCautionColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 33 33" {...props}>
    <path
      fill="#FFF065"
      d="M13.902 4.5c1.155-2 4.041-2 5.196 0l9.093 15.75c1.155 2-.288 4.5-2.598 4.5H7.407c-2.31 0-3.753-2.5-2.598-4.5L13.902 4.5Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M17.685 17.723h-2.37L15.067 9.5h2.867l-.249 8.223ZM16.492 21.5a1.51 1.51 0 0 1-.754-.196A1.435 1.435 0 0 1 15 20.012a1.343 1.343 0 0 1 .19-.727 1.51 1.51 0 0 1 1.301-.727c.26 0 .504.065.73.195.232.131.417.308.555.532.144.223.218.466.224.727a1.489 1.489 0 0 1-.215.752 1.586 1.586 0 0 1-.556.54 1.48 1.48 0 0 1-.737.196Z"
    />
  </svg>
);
SvgCautionColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCautionColorIcon;

import React, { SVGProps } from 'react';

const SvgPaperAirplaneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M3.479 2.404a.75.75 0 0 0-.926.941l2.432 7.905h8.516a.75.75 0 0 1 0 1.5H4.985l-2.432 7.905a.75.75 0 0 0 .926.94 60.517 60.517 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.479 2.404Z"
    />
  </svg>
);
SvgPaperAirplaneIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPaperAirplaneIcon;

import dayjs, { Dayjs } from 'dayjs';
import React, { Suspense, lazy, useEffect, useState } from 'react';

import BaseModal from '../Modal/BaseModal';
import { colors } from '@/const/colors';
import '@/css/calendarPopupStyle.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateCalendar = lazy(() =>
  import('@mui/x-date-pickers/DateCalendar').then((module) => ({ default: module.DateCalendar })),
);

interface CalendarModal extends BaseModal {
  selectedDate: string;
  onChangeSelectedDate: (date: string) => void;
  isDisabledAfterToday?: boolean;
}

const CalendarPopup = ({
  isOpen,
  selectedDate,
  onChangeSelectedDate,
  onClose,
  isDisabledAfterToday = false,
}: CalendarModal) => {
  const [value, setValue] = useState<any>(selectedDate);

  useEffect(() => {
    if (selectedDate !== value) {
      onChangeSelectedDate(dayjs(value).format('YYYY-MM-DD'));
      onClose && onClose();
    }
  }, [value]);

  useEffect(() => {
    setValue(selectedDate);
  }, [selectedDate]);

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        paddingType="none"
        leftButtonLabel="닫기"
        onLeftButtonClick={onClose}
        content={
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <div>
              <Suspense fallback={<></>}>
                <DateCalendar
                  value={dayjs(value)}
                  onChange={(newValue) => setValue(newValue)}
                  shouldDisableDate={(date) => {
                    if (!date || !isDisabledAfterToday) return false;

                    const day = dayjs(date as string | number | Date | Dayjs);
                    return day.isAfter(dayjs(), 'day');
                  }}
                  sx={{
                    '& .Mui-selected': {
                      backgroundColor: `${colors.primary} !important`,
                      color: 'white !important',
                    },
                  }}
                />
              </Suspense>
            </div>
          </LocalizationProvider>
        }
      />
    </>
  );
};

export default CalendarPopup;

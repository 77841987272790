import React, { SVGProps } from 'react';

const SvgPhoneCancleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M15.427 2.576a.6.6 0 1 0-.849.848L17.154 6l-2.576 2.576a.6.6 0 0 0 .849.848l2.575-2.576 2.576 2.576a.6.6 0 1 0 .849-.848L18.85 6l2.576-2.576a.6.6 0 0 0-.849-.848L18.002 5.15l-2.575-2.575Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgPhoneCancleIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPhoneCancleIcon;

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { colors } from '@/const/colors';
import Button from '@mui/material/Button';

type Props = {
  title?: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  buttons?: Button[];
  isDisabled?: boolean;
  path?: string;
  onClick?: (() => void) | undefined;
};

const ButtonFooter = ({ title, isDisabled, path, onClick, bgColor, borderColor, textColor, buttons }: Props) => {
  const navigate = useNavigate();

  const onClickBtn = (button?: Button) => {
    if (button) {
      if (button.onClick) {
        button.onClick();
      } else if (button.path) {
        navigate(button.path);
      }
    } else {
      if (onClick) {
        onClick();
      } else if (path) {
        navigate(path);
      }
    }
  };

  return (
    <>
      {buttons ? (
        buttons.map((item, index) => (
          <div className={`flex-1 p-2 bg-white ${item.flex}`} key={index}>
            <Button
              variant="contained"
              className="w-full max-w-[672px] min-w-[180px] m-2 break-keep"
              sx={{
                bgcolor: `${item.bgColor}`,
                color: `${item.textColor}`,
                '&:hover': {
                  backgroundColor: `${item.bgColor}`,
                },
                border: `1px solid ${item.borderColor ? item.borderColor : item.bgColor}`,
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: 1,
                '&.Mui-disabled': {
                  color: colors.gray[6],
                  backgroundColor: colors.gray[4],
                  border: 'none',
                },
              }}
              onClick={() => onClickBtn(item)}
              disabled={item.isDisabled}
            >
              {item.title}
            </Button>
            {item.description ? (
              <div className="text-xs xxs:text-[0.6rem] text-blue-800 text-center mt-2">
                {item.description?.split('\n').map((text, index) => {
                  return (
                    <span key={index}>
                      {text}
                      <br />
                    </span>
                  );
                })}
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <div className="w-full flex-1 bg-white">
          <Button
            variant="contained"
            className="w-full"
            disabled={isDisabled}
            sx={{
              bgcolor: `${bgColor}`,
              border: `${textColor}`,
              color: `${textColor}`,
              fontSize: '16px',
              fontWeight: 'bold',
              lineHeight: 1,
              '&.Mui-disabled': {
                color: colors.gray[6],
                backgroundColor: colors.gray[4],
                border: 'none',
              },
            }}
            onClick={() => onClickBtn()}
          >
            {title}
          </Button>
        </div>
      )}
    </>
  );
};

export default ButtonFooter;

import React, { ReactNode } from 'react';

import { XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { truncateText } from '@/utils/common';
import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';

export interface ButtonPopupProps {
  isShow: boolean;
  onClose: () => void;
  title?: string;
  buttons: Button[];
  children?: ReactNode;
  heightRate?: number;
  minWidth?: number;
}

export interface Button {
  icon?: ReactNode;
  label: string;
  description?: string;
  onClick?: () => void;
}

const ButtonPopup = ({
  isShow,
  onClose,
  title,
  buttons,
  children,
  heightRate = 100,
  minWidth = 280,
}: ButtonPopupProps) => {
  return (
    <>
      <Dialog
        open={isShow}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minWidth: '280px' }}
      >
        <DialogTitle
          className="flex text-center w-full justify-center"
          sx={{ paddingY: '20px', paddingX: '16px', minWidth: `${minWidth - 40}px` }}
        >
          <p className="font-semibold text-lg xxs:text-base break-keep text-gray-9 pl-3 text-center w-full">
            {truncateText(title, 20)}
          </p>
          <div className="cursor-pointer ml-2 mt-1 flex" onClick={onClose}>
            <XMarkIcon color={colors.gray[9]}></XMarkIcon>
          </div>
        </DialogTitle>
        <DialogContent
          className={`flex flex-col w-full`}
          sx={{ paddingBottom: '16px', minWidth: `${minWidth - 40}px`, paddingX: '16px' }}
        >
          <div
            className={`overflow-y-auto flex flex-col items-center`}
            style={{
              maxHeight: `calc(${heightRate}dvh - 112px)`,
            }}
          >
            {children}
          </div>
          <Stack spacing={1.5}>
            {buttons.map((item, index) => (
              <Button
                key={index}
                size="medium"
                sx={{
                  minWidth: '168px',
                  height: 'fit-content',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#919191',
                  marginRight: '10px',
                  bgcolor: '#FFFFFF',
                  borderRadius: '8px',
                  paddingY: '15px',
                  border: '1px solid #E8E8E8',
                  '&:hover': {
                    backgroundColor: 'White',
                  },
                }}
                onClick={item.onClick}
              >
                <div className="flex flex-col items-center w-full px-3">
                  <div className="flex justify-center items-center">
                    {item.icon}
                    <p className="font-medium text-lg text-gray-8 ml-2">{item.label}</p>
                  </div>

                  {item.description && (
                    <p className="font-medium text-[13px] text-gray-7 break-keep leading-[16px] mt-[10px] max-w-[180px]">
                      {item.description}
                    </p>
                  )}
                </div>
              </Button>
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ButtonPopup;

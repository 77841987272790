import { useAtom, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getJobFilterInfo } from '@/api/public';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import SliderComponent from '@/components/Common/Input/Slider/SliderComponent';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import Tags from '@/components/Common/Tags';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { TONS_IS_LESS_THAN_27, TONS_IS_OVER_1 } from '@/const/errorMessage';
import { positiveFloatRegex } from '@/const/regex';
import { jobSearchParamsFromAtom } from '@/store/job';
import { getInputErrorMessage } from '@/utils/common';

const JobSearch = () => {
  const navigate = useNavigate();
  const [searchParams] = useAtom(jobSearchParamsFromAtom);
  const setSearchParams = useSetAtom(jobSearchParamsFromAtom);

  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const [title, setTitle] = useState('');
  const [isShow, setIsShow] = useState(false);

  const keyValueList: KeyValueListType = {
    workingArea: '근무 지역',
    workingDays: '근무 요일',
    workingHours: '근무 시간',
  };

  const [selectedWorkingArea, setSelectedWorkingArea] = useState<OptionDataType[]>([]);
  const [selectedWorkingDays, setSelectedWorkingDays] = useState<OptionDataType[]>([]);
  const [selectedworkingHours, setSelectedworkingHours] = useState<OptionDataType[]>([]);

  const [minTonsError, setMinTonsError] = useState(false);
  const [maxTonsError, setMaxTonsError] = useState(false);

  const [minTonsErrorMsg, setMinTonsErrorMsg] = useState('');
  const [maxTonsErrorMsg, setMaxTonsErrorMsg] = useState('');

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = value.trim() === '' ? '' : value.trim();
      setSearchParams({ ...searchParams, [name]: str });

      switch (name) {
        case 'minTons':
          if (Number(value) > Number(searchParams.maxTons)) {
            setMinTonsError(true);
            setMinTonsErrorMsg(getInputErrorMessage('min', 'tons'));
            return;
          } else if (Number(value) < 1) {
            setMinTonsError(true);
            setMinTonsErrorMsg(TONS_IS_OVER_1);
            return;
          } else {
            setMinTonsError(false);
            setMinTonsErrorMsg('');
          }
          break;
        case 'maxTons':
          if (Number(value) < Number(searchParams.minTons)) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(getInputErrorMessage('max', 'tons'));
            return;
          } else if (Number(value) > 27) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
            return;
          } else {
            setMaxTonsError(false);
            setMaxTonsErrorMsg('');
          }
          break;
      }
    }
  };

  const clear = () => {
    setSearchParams({ minTons: 1, maxTons: 27 });
    setSelectedWorkingArea([]);
    setSelectedWorkingDays([]);
    setSelectedworkingHours([]);
    setMinTonsError(false);
    setMaxTonsError(false);
  };

  const search = () => {
    navigate('/job', { state: { jobSearchParams: searchParams } });
  };

  const getJobFilterInfoData = async () => {
    try {
      const response = await getJobFilterInfo();
      const data: JobFilterInfo = response.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const {
    data: filterInfo,
    isError,
    isLoading,
  } = useQuery(['get-job-filter-info'], getJobFilterInfoData, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: () => {},
    onError: () => {},
  });

  const onClickSelectBox = (key: string) => {
    if (!filterInfo) {
      return;
    }

    if (key === 'workingArea') {
      setOptionData(filterInfo.workingArea);
    } else if (key === 'workingDays') {
      setOptionData(filterInfo.workingDays);
    } else {
      setOptionData(filterInfo.workingHours);
    }

    setTitle(key);
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    if (item) {
      switch (key) {
        case 'workingArea':
          setSelectedWorkingArea([item]);
          setSearchParams({ ...searchParams, workingArea: item as EnumPresenter });
          break;
        case 'workingDays':
          setSelectedWorkingDays([item]);
          setSearchParams({ ...searchParams, workingDays: item as EnumPresenter });
          break;
        case 'workingHours':
          setSelectedworkingHours([item]);
          setSearchParams({ ...searchParams, workingHours: item as EnumPresenter });
          break;
      }
      setTimeout(() => {
        setIsShow(false);
      }, 200);
    }
  };

  const onChangeSliderInput = (e: Event, values: number | number[], key: string) => {
    if (!Array.isArray(values)) {
      return;
    }
    setSearchParams({ ...searchParams, [`min${key}`]: values[0].toString(), [`max${key}`]: values[1].toString() });
  };

  const removeItem = (item: OptionDataType, key: string) => {
    switch (key) {
      case 'workingArea':
        setSelectedWorkingArea([]);
        setSearchParams({ ...searchParams, workingArea: { code: '', desc: '' } });
        break;
      case 'workingDays':
        setSelectedWorkingDays([]);
        setSearchParams({ ...searchParams, workingDays: { code: '', desc: '' } });
        break;
      case 'workingHours':
        setSelectedworkingHours([]);
        setSearchParams({ ...searchParams, workingHours: { code: '', desc: '' } });
        break;
    }
  };

  return (
    <>
      <MenuHeader title="일자리 검색"></MenuHeader>

      <div className="w-full pt-[60px] pb-24">
        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">톤수</p>
          <SliderComponent
            name="Tons"
            value={[Number(searchParams.minTons) || 0, Number(searchParams.maxTons) || 27]}
            onChange={(e, value) => onChangeSliderInput(e, value, 'Tons')}
            step={1}
            min={1}
            max={27}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minTons"
              placeholder="최소 톤수"
              value={String(searchParams.minTons) || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e)}
              error={minTonsError}
              errorMsg={minTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxTons"
              placeholder="최대 톤수"
              value={String(searchParams.maxTons) || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e)}
              error={maxTonsError}
              errorMsg={maxTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t-8 border-gray-1">
          <div className="flex items-center py-5 px-4" onClick={() => onClickSelectBox('workingArea')}>
            <span className="flex-1 flex items-center">
              <p className="flex-1 font-semibold text-gray-8">근무 지역</p>
              <ChevronRightIcon color={colors.gray[4]}></ChevronRightIcon>
            </span>
          </div>
          <div className={`px-4 ${selectedWorkingArea.length !== 0 ? 'pb-2' : ''}`}>
            <Tags
              items={selectedWorkingArea}
              setItems={setSelectedWorkingArea}
              onClickDeleteBtn={removeItem}
              itemName={'workingArea'}
            ></Tags>
          </div>
        </div>

        <div className="text-base border-t border-gray-3">
          <div className="flex items-center py-5 px-4" onClick={() => onClickSelectBox('workingDays')}>
            <span className="flex-1 flex items-center">
              <p className="flex-1 font-semibold text-gray-8">근무 요일</p>
              <ChevronRightIcon color={colors.gray[4]}></ChevronRightIcon>
            </span>
          </div>
          <div className={`px-4 ${selectedWorkingDays.length !== 0 ? 'pb-2' : ''}`}>
            <Tags
              items={selectedWorkingDays}
              setItems={setSelectedWorkingDays}
              onClickDeleteBtn={removeItem}
              itemName={'workingDays'}
            ></Tags>
          </div>
        </div>

        <div className="text-base border-t border-b border-gray-3">
          <div className="flex items-center py-5 px-4" onClick={() => onClickSelectBox('workingHours')}>
            <span className="flex-1 flex items-center">
              <p className="flex-1 font-semibold text-gray-8">근무 시간</p>
              <ChevronRightIcon color={colors.gray[4]}></ChevronRightIcon>
            </span>
          </div>
          <div className={`px-4 ${selectedworkingHours.length !== 0 ? 'pb-2' : ''}`}>
            <Tags
              items={selectedworkingHours}
              setItems={setSelectedworkingHours}
              onClickDeleteBtn={removeItem}
              itemName={'workingHours'}
            ></Tags>
          </div>
        </div>

        <DualFooterButton
          leftButtonText="초기화"
          onClickLeftButton={() => {
            clear();
          }}
          rightButtonText="검색"
          onClickRightButton={() => {
            search();
          }}
        ></DualFooterButton>
      </div>
      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={
              title === 'workingArea'
                ? selectedWorkingArea[0]?.code || ''
                : title === 'workingDays'
                ? selectedWorkingDays[0]?.code || ''
                : selectedworkingHours[0]?.code || ''
            }
            onChange={(val) => {
              onClickItem(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
        {/* <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={searchParams[title as keyof JobFilterInfo]?.code || ''}
        >
          {optionData?.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.code}
              control={
                <Radio
                  icon={<LensIcon sx={{ color: '#E5E7EB' }} />}
                  checkedIcon={<CheckCircleIcon sx={{ color: '#1E42A6' }} />}
                />
              }
              label={item.desc}
              onClick={(e) => onClickItem(item, title)}
            />
          ))}
        </RadioGroup> */}
      </DownToUpDetailPopup>
    </>
  );
};

export default JobSearch;

import React, { useLayoutEffect, useRef, useState } from 'react';

import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { formatNumber, getFormatDateOnlyNum } from '@/utils/common';
import { validatePrice } from '@/utils/validation';

interface ProductProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
}

const SalePrice: React.FC<ProductProps> = ({ productInfo, setProductInfo }) => {
  const [priceError, setPriceError] = useState<boolean>(false);
  const [priceErrorMsg, setPriceErrorMsg] = useState('');
  const priceRef = useRef<HTMLInputElement>(null);

  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { isValid, errorMessage, formattedValue } = validatePrice(value);

    setProductInfo((prevProductInfo) => {
      return {
        ...prevProductInfo,
        price: formattedValue,
      };
    });

    if (!isValid) {
      setPriceError(true);
      setPriceErrorMsg(errorMessage);
    } else {
      setPriceError(false);
      setPriceErrorMsg('');
    }
  };

  const { tons, model, loaded, loadedInnerLength, manufacturerCategories, axis, firstRegistrationDate } = productInfo;
  const searchParams = {
    tons,
    model,
    loaded,
    loadedInnerLength,
    manufacturerCategories,
    axis,
    year: getFormatDateOnlyNum(firstRegistrationDate, ['year']),
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (priceRef.current) {
      priceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[30px]">
        <TextInputLabelUp
          ref={priceRef}
          name="price"
          label="판매 금액"
          placeholder="판매 금액 입력"
          value={formatNumber(productInfo?.price?.toString(), true) || ''}
          onChange={(e) => handleChangePrice(e)}
          onFocus={(e) => scrollIntoView(e)}
          inputMode="numeric"
          error={priceError}
          errorMsg={priceErrorMsg}
          suffix="만원"
          maxLength={8}
        ></TextInputLabelUp>
      </div>

      <div>
        <CarPriceTrendInfo
          priceSearchParams={searchParams as ProductSearchParams}
          showPriceComparison={false}
          apiType="private"
        ></CarPriceTrendInfo>
      </div>
      <div className="py-2 text-base">
        <SimilarProductList similarSearchParams={searchParams as ProductSearchParams}></SimilarProductList>
      </div>
    </div>
  );
};

export default SalePrice;

import React, { SVGProps } from 'react';

const SvgNewCarLeaseColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <ellipse cx={20} cy={36} fill="#737373" rx={15} ry={1} />
    <path
      fill="#737373"
      d="M2.151 22.386a.155.155 0 0 1 .126-.064h1.23c.474 0 .858.384.858.858v.834a.677.677 0 0 1-.678.677H1.91a.253.253 0 0 1-.253-.253v-.53c0-.548.174-1.08.495-1.522Z"
    />
    <path
      fill="#BEBEBE"
      stroke={props.color || 'currentColor'}
      strokeWidth={1.3}
      d="M33.933 33.792c0 1.28-.807 2.059-1.516 2.059-.71 0-1.517-.779-1.517-2.059 0-1.28.807-2.058 1.517-2.058.71 0 1.516.779 1.516 2.058ZM27.433 33.792c0 1.28-.807 2.059-1.516 2.059-.71 0-1.517-.779-1.517-2.059 0-1.28.807-2.058 1.517-2.058.71 0 1.516.779 1.516 2.058Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M10.753 33.25c0 1.795-1.213 3.25-2.709 3.25s-2.708-1.455-2.708-3.25S6.548 30 8.044 30c1.496 0 2.709 1.455 2.709 3.25Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M11.83 33.25c0 1.795-1.212 3.25-2.708 3.25-1.495 0-2.708-1.455-2.708-3.25S7.627 30 9.122 30c1.496 0 2.709 1.455 2.709 3.25ZM23.75 30h10.263l.214.811c.236.9.356 1.827.356 2.757v.765H23.75V30Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M15.086 28.917h12.316l.354 1.402c.22.868.33 1.759.33 2.654v1.36h-13v-5.416Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M21.582 28.05c0-.12.097-.217.217-.217h15.984c.12 0 .217.097.217.217v3.9c0 .12-.097.216-.217.216H21.8a.217.217 0 0 1-.217-.216v-3.9Z"
    />
    <path
      fill="#919191"
      d="M17 25.542c0-.3.242-.542.542-.542h20.916c.3 0 .542.242.542.542v.916c0 .3-.242.542-.542.542H17.542a.542.542 0 0 1-.542-.542v-.916Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M18.33 33.25c0 1.795-1.212 3.25-2.708 3.25-1.495 0-2.708-1.455-2.708-3.25S14.127 30 15.622 30c1.496 0 2.709 1.455 2.709 3.25Z"
    />
    <path
      fill="#DDE0E4"
      d="M11.832 26.209c0-.3.242-.542.542-.542h26.084c.3 0 .542.242.542.542v4.333c0 .3-.242.542-.542.542H12.374a.542.542 0 0 1-.542-.542v-4.333Z"
    />
    <path
      fill="#ECEFF4"
      d="M4.678 18.685A3 3 0 0 1 7.375 17H20.11c.534 0 .99.39 1.07.919l.826 5.367c.078.503.116 1.011.116 1.52V30c0 .598-.485 1.083-1.083 1.083h-1.068a6.575 6.575 0 0 0-4.65 1.926.822.822 0 0 1-.58.241H2.621a1.083 1.083 0 0 1-1.083-1.083v-5.042c0-1.316.3-2.615.876-3.798l2.263-4.642Z"
    />
    <path
      fill="#BEBEBE"
      stroke={props.color || 'currentColor'}
      strokeWidth={1.3}
      d="M35.015 33.792c0 1.28-.806 2.059-1.516 2.059s-1.517-.779-1.517-2.059c0-1.28.807-2.058 1.517-2.058.71 0 1.516.779 1.516 2.058Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M29.17 33.792c0 1.496-.97 2.709-2.167 2.709-1.197 0-2.167-1.213-2.167-2.709 0-1.495.97-2.708 2.167-2.708 1.196 0 2.166 1.213 2.166 2.708ZM15.086 35.417h1.625v.975c0 .06-.049.108-.108.108h-.975l-.542-1.083ZM31.875 35.417H33.5v.975c0 .06-.048.108-.108.108h-.975l-.542-1.083Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M25.375 35.417H27v.975c0 .06-.049.108-.108.108h-.975l-.542-1.083ZM8.04 35.417h1.624l-.512 1.023a.108.108 0 0 1-.097.06H8.04v-1.083Z"
    />
    <path
      fill="#ECEFF4"
      d="M1 29.917a1 1 0 0 1 1-1h10.918c.3 0 .542.242.542.542v3.25c0 .299-.243.541-.542.541H2.001a1 1 0 0 1-1-1v-2.333Z"
    />
    <path
      fill="#BEBEBE"
      stroke={props.color || 'currentColor'}
      strokeWidth={1.517}
      d="M18.658 33.25c0 1.511-.996 2.492-1.95 2.492-.953 0-1.95-.98-1.95-2.492 0-1.511.997-2.492 1.95-2.492.954 0 1.95.98 1.95 2.492Z"
    />
    <path
      fill="#8A95B6"
      d="M5.182 18.382c.092-.183.28-.299.485-.299h8.999c.211 0 .366.199.315.404l-1.421 5.686a.542.542 0 0 1-.526.41H2.608a.325.325 0 0 1-.29-.47l2.864-5.73Z"
    />
    <path
      fill="#353C51"
      d="m14.606 24.314 1.353-5.41c.12-.483.554-.821 1.05-.821h1.734c.387 0 .718.28.781.662l.021.126a30.16 30.16 0 0 1 .41 4.958.754.754 0 0 1-.753.754h-4.385a.217.217 0 0 1-.21-.27Z"
    />
    <path
      fill="#3C5FBF"
      d="M1.945 28.917h-.403a2.422 2.422 0 0 1 0-2.167l.508 2.032a.108.108 0 0 1-.105.135ZM11.29 28.917H8.902a.217.217 0 0 1-.201-.297l.91-2.275a.217.217 0 0 1 .201-.136h1.478c.3 0 .542.242.542.542v1.625c0 .299-.243.541-.542.541Z"
    />
    <path
      fill="#414141"
      d="M2.6 31.27a.217.217 0 0 1 .215-.186h5.048c.104 0 .194.075.213.178l.215 1.192a.217.217 0 0 1-.213.255h-5.43a.217.217 0 0 1-.215-.247l.08-.566.088-.626Z"
    />
    <path stroke="#737373" strokeLinecap="round" strokeWidth={0.5} d="M2.816 28.808h4.685" />
    <path
      fill="#737373"
      d="M19.414 26.426c0-.12.097-.217.217-.217h.216c.06 0 .109.049.109.108v1.409c0 .06-.049.108-.109.108h-.216a.217.217 0 0 1-.217-.217v-1.191ZM15.453 22.224a.266.266 0 0 1 .238-.147h1.243c.419 0 .759.34.759.76v1.271a.677.677 0 0 1-.677.677h-1.702a.33.33 0 0 1-.33-.329v-.249c0-.688.16-1.367.469-1.983Z"
    />
    <path fill="#737373" d="M14.787 22.375a.217.217 0 1 1 .43-.051l.207 1.72a.217.217 0 1 1-.43.052l-.207-1.72Z" />
    <path
      fill="#353C51"
      fillRule="evenodd"
      d="M8.974 18.084h5.693c.212 0 .367.199.315.404l-1.421 5.686a.542.542 0 0 1-.526.41H2.61a.325.325 0 0 1-.29-.47l.506-1.014 6.15-5.016Z"
      clipRule="evenodd"
    />
    <rect width={19.03} height={9.475} x={19.4} y={7.4} fill="#fff" rx={4.738} />
    <rect width={19.03} height={9.475} x={19.4} y={7.4} stroke="#1E42A6" strokeWidth={0.8} rx={4.738} />
    <path
      fill="#1E42A6"
      d="M31.29 10.941c0 .301.048.6.146.9a2.389 2.389 0 0 0 1.275 1.439l-.54.738a2.378 2.378 0 0 1-.82-.602 3.024 3.024 0 0 1-.544-.91c-.139.374-.33.701-.57.982a2.347 2.347 0 0 1-.876.632l-.533-.759c.335-.146.613-.35.834-.612.221-.261.384-.549.489-.86a3.05 3.05 0 0 0 .16-.948v-.157h-1.25v-.78h1.244V9.15h.99v.855h1.231v.78H31.29v.156Zm2.651.452h.89v.82h-.89v3.062h-.984V9h.984v2.393ZM28.325 13.58h-.984V9.007h.984v4.573Zm.157 1.593h-4.409v-2.044h.998v1.258h3.411v.786ZM25.516 9.95c-.005.435.108.825.338 1.17.23.34.596.59 1.097.744l-.499.773a2.454 2.454 0 0 1-.871-.475c-.24-.21-.431-.46-.575-.749a2.408 2.408 0 0 1-1.494 1.326L23 11.967c.349-.114.634-.276.855-.486.223-.21.385-.445.485-.707.1-.264.15-.539.15-.824V9.35h1.026v.601Z"
    />
  </svg>
);
SvgNewCarLeaseColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgNewCarLeaseColorIcon;

interface PageMetadata {
  [key: string]: {
    title: string;
    siteUrl: string;
    description?: string;
  };
}

const metadata: PageMetadata = {
  home: {
    title: '중고화물차 직트럭',
    siteUrl: 'https://www.zigtruck.io',
  },
  purchase: {
    title: '내차구매',
    siteUrl: 'https://www.zigtruck.io/products',
  },
  sale: {
    title: '내차판매',
    siteUrl: 'https://www.zigtruck.io/sell-car',
  },
  license: {
    title: '번호판 거래',
    siteUrl: 'https://www.zigtruck.io/license',
  },
  drive: {
    title: '운행일지',
    siteUrl: 'https://www.zigtruck.io/drive',
  },
};

export default metadata;

import React, { SVGProps } from 'react';

const SvgDocumentArrowUpSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M20 2H9C7 2 6 3 6 5v22c0 2 1 3 3 3h16c2 0 3-1 3-3V10h-6c-.228 0-.48-.01-.72-.063a1.587 1.587 0 0 1-.798-.42 1.587 1.587 0 0 1-.42-.797A3.402 3.402 0 0 1 20 8V2Zm7.5 6L22 2.5V8h5.5Zm-9.793 5.293a1 1 0 0 0-1.414 0l-4 4a1 1 0 0 0 1.414 1.414L16 16.414V22a1 1 0 1 0 2 0v-5.586l2.293 2.293a1 1 0 0 0 1.414-1.414l-4-4Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgDocumentArrowUpSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgDocumentArrowUpSolidIcon;

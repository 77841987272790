import React, { SVGProps } from 'react';

const SvgTruckBackSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <g fill={props.color || 'currentColor'} clipPath="url(#truck-back-solid-icon_svg__a)">
      <path
        fillRule="evenodd"
        d="M29.488 8.816V9h-.008v11h.008v4.548h.38c.532 0 .988.418.988.988v1.71a.981.981 0 0 1-.989.988h-1.291v1.938a.981.981 0 0 1-.989.988H25.08a.981.981 0 0 1-.988-.988v-1.938H13.908v1.938a.981.981 0 0 1-.988.988h-2.546a.981.981 0 0 1-.988-.988v-1.938H8.093a.981.981 0 0 1-.988-.988v-1.71c0-.532.418-.988.988-.988h.38V18.81l-.004.005v-8.48l.004.001v-1.52c0-1.102.912-2.014 2.014-2.014h16.986c1.103 0 2.015.912 2.015 2.014ZM10.183 10.07c0-.459.296-.82.689-.942a1 1 0 0 1 .312-.05h15.629c.076 0 .15.009.221.025a.982.982 0 0 1 .782.967v7.106c0 .027-.002.053-.003.079v5.533a1 1 0 0 1-1 1H11.184a1 1 0 0 1-1-1V10.07Z"
        clipRule="evenodd"
      />
      <path d="M2 9a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V9ZM30 9a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V9ZM8 10h1v2H8zM29 10h1v2h-1zM29 21h1v2h-1zM8 21h1v2H8z" />
    </g>
    <defs>
      <clipPath id="truck-back-solid-icon_svg__a">
        <path fill="#fff" d="M0 0h38v38H0z" />
      </clipPath>
    </defs>
  </svg>
);
SvgTruckBackSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgTruckBackSolidIcon;

import React, { useEffect, useState } from 'react';

import BasicButton from '../../Button/BasicButton';
import TextInputLabelLeft from '../../Input/TextInputLabelLeft';
import { colors } from '@/const/colors';
import { useMemberContext } from '@/contexts/Member/MemberContext';

interface ProductInquiryQrPopupProps {
  isNotDirectProduct: boolean;
  productId: number;
  purchaseInquiryInfo?: PurchaseInquiryInfo;
  updateInquiryInfo?: (data: PurchaseInquiryInfo) => void;
  handleClickButton?: () => void;
}
interface PurchaseInquiryInfo {
  productId: number;
  name: string;
  phoneNumber: string;
  loaded: string;
}

const ProductInquiryQr = ({
  isNotDirectProduct,
  productId,
  purchaseInquiryInfo,
  updateInquiryInfo,
  handleClickButton,
}: ProductInquiryQrPopupProps) => {
  const { member } = useMemberContext();
  const [inquiryInfo, setInquiryInfo] = useState<PurchaseInquiryInfo>({
    productId: 0,
    name: '',
    phoneNumber: '',
    loaded: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInquiryInfo({ ...inquiryInfo, [name]: value });
  };

  useEffect(() => {
    if (member && purchaseInquiryInfo) {
      setInquiryInfo({
        name: member.name,
        phoneNumber: member.phoneNumber,
        productId: productId,
        loaded: purchaseInquiryInfo.loaded || '',
      });
    }
  }, [member]);

  useEffect(() => {
    if (inquiryInfo.productId !== 0 && updateInquiryInfo) {
      updateInquiryInfo(inquiryInfo);
    }
  }, [inquiryInfo]);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <span className="font-semibold text-gray-9 text-[20px] mb-2">직트럭 앱 다운로드</span>
        <span className="font-medium text-gray-8 text-[14px] leading-[18px] text-center">
          직트럭 어플 다운로드 후 차주에게
          <br />
          직접 구매문의를 할 수 있어요.
        </span>
        <div className="my-5 sm-only:w-[100px] sm680:w-[120px] sm680:h-[120px]">
          <img
            className="w-full h-full"
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/zigtruck_io.png"
            alt="qr_code"
          />
        </div>
        <span className="w-full font-medium text-gray-8 text-[16px] xxs:text-[15px] leading-[20px] text-center break-keep">
          휴대폰 카메라로 QR코드를 <br className="hidden sm280:block" />
          인식 후 <br className="sm280:hidden" />
          앱을 다운로드 받으세요.
        </span>
      </div>

      {isNotDirectProduct && (
        <>
          <div className="flex mt-[30px] flex-col py-[30px] border-t border-gray-4">
            <span className="font-semibold text-gray-9 text-[16px] text-center">구매 문의 남기기</span>
            <span className="font-medium text-gray-8 text-[12px] leading-[15px] pb-5 pt-[10px] text-center">
              인적 사항을 남겨주시면 <br className="hidden sm280:block" />
              담당자가 확인 후 연락드려요.
            </span>
            <div className="flex flex-col gap-4">
              <TextInputLabelLeft
                name="name"
                label="이름"
                placeholder="이름 입력"
                value={inquiryInfo.name}
                bottomPadding={1}
                onChange={handleInputChange}
                minWidth={70}
              />
              <TextInputLabelLeft
                name="phoneNumber"
                label="연락처"
                placeholder="연락처 입력"
                value={inquiryInfo.phoneNumber}
                bottomPadding={1}
                onChange={handleInputChange}
                minWidth={70}
              />
            </div>
          </div>
          <BasicButton
            name="문의 남기기"
            bgColor={colors.gray[0]}
            borderColor={colors.gray[3]}
            textColor={colors.gray[7]}
            fontSize={16}
            height={52}
            fontWeight="semibold"
            onClick={() => {
              if (handleClickButton) {
                handleClickButton();
              }
            }}
          ></BasicButton>
        </>
      )}
    </>
  );
};

export default ProductInquiryQr;

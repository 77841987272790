import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { createReferralCodes } from '@/api/referralCode/createReferralCode';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { EmptyCheckCircleIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { useMemberContext } from '@/contexts/Member/MemberContext';

const ReferralCodeRegister = () => {
  const navigate = useNavigate();
  const { member, updateReferralCodes } = useMemberContext();
  const [referralCode, setReferralCode] = useState('');
  const [referralCodeError, setReferralCodeError] = useState(false);
  const [referralCodeErrorMessage, setReferralCodeErrorMessage] = useState('');

  const [isValidCode, setIsValidCode] = useState(false);

  const [isShowBasicPopup, setIsShowBasicPopup] = useState(false);

  useEffect(() => {
    if (member?.referralCodes?.length) {
      const [firstReferralCode] = member.referralCodes;
      setReferralCode(firstReferralCode.code);
      setIsValidCode(true);
    }
  }, [member]);

  const handleClickRegister = () => {
    postReferralCodesMutation.mutate({ referralCode });
  };

  const handleChangeReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setReferralCode(value);
    setIsValidCode(false);
  };

  const handleClickOk = () => {
    setIsShowBasicPopup(false);
    navigate(-1);
  };

  const postReferralCodesMutation = useMutation((request: { referralCode: string }) => createReferralCodes(request), {
    onSuccess: (response) => {
      setIsShowBasicPopup(true);
      updateReferralCodes(response.data);
      setReferralCodeError(false);
      setReferralCodeErrorMessage('');
      setIsValidCode(true);
    },
    onError: (error: AxiosError) => {
      setReferralCodeError(true);
      setReferralCodeErrorMessage(error.message);
      setIsValidCode(false);
    },
  });

  return (
    <>
      <MenuHeader title="추천인 등록"></MenuHeader>
      <div className="w-full p-4 pt-[60px] h-[calc(100vh-90px)]">
        <section className="flex flex-col gap-[30px] my-4">
          <div className="relative w-full flex justify-between align-center">
            <TextInputLabelUp
              name="referralCode"
              label="추천인"
              placeholder="추천인 코드를 입력하세요."
              value={referralCode}
              onChange={handleChangeReferralCode}
              error={referralCodeError}
              errorMsg={referralCodeErrorMessage}
              type="text"
              disabled={isValidCode}
            ></TextInputLabelUp>
          </div>
        </section>
        {isValidCode && (
          <div className="w-full h-full flex flex-col justify-center items-center pb-[80px]">
            <EmptyCheckCircleIcon color={colors.gray[4]} width={120} height={120}></EmptyCheckCircleIcon>
            <div className="break-keep text-gray-7 mt-[20px]">이미 추천인 등록을 완료했어요.</div>
          </div>
        )}

        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-3 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white">
          <ButtonFooter
            title="등록하기"
            isDisabled={!referralCode || isValidCode}
            onClick={handleClickRegister}
          ></ButtonFooter>
        </div>
      </div>
      <BasicPopup
        isShow={isShowBasicPopup}
        title=""
        textContent={`<div class="w-[288px] sm280:w-[200px]">추천인 등록이 완료되었어요.<div>`}
        textRightBtn="확인"
        onClickRightBtn={() => handleClickOk()}
      ></BasicPopup>
    </>
  );
};

export default ReferralCodeRegister;

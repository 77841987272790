// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInToast {
  from {
    opacity: 0;
    transform: translateZ(0px);
  }
  to {
    opacity: 1;
    transform: translateZ(0px);
  }
}

@keyframes fadeOutToast {
  from {
    opacity: 1;
    transform: translateZ(0px);
  }
  to {
    opacity: 0;
    transform: translateZ(0px);
  }
}

.fadeIn {
  animation: fadeInToast 0.2s ease-in-out forwards;
}

.fadeOut {
  animation: fadeOutToast 0.2s ease-in-out forwards;
}
`, "",{"version":3,"sources":["webpack://./src/css/toastStyle.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,0BAA0B;EAC5B;EACA;IACE,UAAU;IACV,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,UAAU;IACV,0BAA0B;EAC5B;EACA;IACE,UAAU;IACV,0BAA0B;EAC5B;AACF;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,iDAAiD;AACnD","sourcesContent":["@keyframes fadeInToast {\n  from {\n    opacity: 0;\n    transform: translateZ(0px);\n  }\n  to {\n    opacity: 1;\n    transform: translateZ(0px);\n  }\n}\n\n@keyframes fadeOutToast {\n  from {\n    opacity: 1;\n    transform: translateZ(0px);\n  }\n  to {\n    opacity: 0;\n    transform: translateZ(0px);\n  }\n}\n\n.fadeIn {\n  animation: fadeInToast 0.2s ease-in-out forwards;\n}\n\n.fadeOut {\n  animation: fadeOutToast 0.2s ease-in-out forwards;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import { ExclaimationCircleIcon } from '../Icon';

interface WarningMessageProps {
  message: string;
}

const WarningMessage = ({ message }: WarningMessageProps) => {
  return (
    <>
      <div className="w-full bg-brown-1 rounded-lg my-[14px] text-brown-0 p-[18px] ">
        <div className="flex justify-start sm680:justify-center sm680:items-center text-[13px] text-brown-0 font-medium ">
          <div className="w-6 h-6">
            <ExclaimationCircleIcon width={24} height={24} />
          </div>
          <div className="ml-1 text-left leading-[18px]">{message}</div>
        </div>
      </div>
    </>
  );
};

export default WarningMessage;

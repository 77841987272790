import React, { SVGProps } from 'react';

const SvgErrorCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 144 144" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M72 12c-33.137 0-60 26.863-60 60s26.863 60 60 60 60-26.863 60-60-26.863-60-60-60ZM6 72C6 35.55 35.55 6 72 6c36.451 0 66 29.55 66 66 0 36.451-29.549 66-66 66-36.45 0-66-29.549-66-66Z"
      clipRule="evenodd"
    />
    <path fill={props.color || 'currentColor'} fillRule="evenodd" d="M69 85.5v-45h6v45h-6Z" clipRule="evenodd" />
    <path fill={props.color || 'currentColor'} d="M68.969 95.495h6v6h-6v-6Z" />
  </svg>
);
SvgErrorCircleIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgErrorCircleIcon;

import React, { MouseEvent, ReactNode } from 'react';

import BaseModal from '@/components/Common/Modal/BaseModal';

interface ConfirmModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  content?: ReactNode;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onLeftButtonClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  onRightButtonClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  paddingType?: 'padding-top-48' | 'padding-top-32' | 'padding-top-20';
}

const ConfirmModal = ({
  isOpen,
  onClose,
  title,
  content,
  leftButtonLabel = '취소',
  onLeftButtonClick,
  rightButtonLabel = '확인',
  onRightButtonClick,
  paddingType = 'padding-top-48',
}: ConfirmModalProps) => {
  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose ? onClose : onLeftButtonClick}
        title={title}
        content={content}
        leftButtonLabel={leftButtonLabel}
        onLeftButtonClick={(e) => onLeftButtonClick?.(e)}
        rightButtonLabel={rightButtonLabel}
        onRightButtonClick={(e) => onRightButtonClick?.(e)}
        paddingType={paddingType}
      />
    </>
  );
};

export default ConfirmModal;

import React, { SVGProps } from 'react';

const SvgCalendarCheckSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path stroke={props.color || 'currentColor'} strokeLinecap="round" strokeWidth={2.5} d="M13.5 8v4m11-4v4" />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M21.988 12V8.58h-5.976L16 12.008a2.5 2.5 0 1 1-5-.018l.01-2.988a5.001 5.001 0 0 0-2.983 4.576v11.949a5 5 0 0 0 5 5h11.949a5 5 0 0 0 5-5V13.579a5.001 5.001 0 0 0-2.988-4.578V12a2.5 2.5 0 0 1-5 0Zm2.137 6.006a1 1 0 1 0-1.47-1.356l-4.743 5.139-2.313-2.57a1 1 0 0 0-1.486 1.34l3.047 3.383a1 1 0 0 0 1.478.009l5.487-5.945Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgCalendarCheckSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCalendarCheckSolidIcon;

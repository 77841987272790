import React, { SVGProps } from 'react';

const SvgMoneyCircleBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M14.75 28.5c7.594 0 13.75-6.156 13.75-13.75S22.344 1 14.75 1 1 7.156 1 14.75 7.156 28.5 14.75 28.5Zm4.885-13.857h1.055c.54 0 .935-.282.935-.87 0-.368-.357-.866-.773-.866h-.974l.417-1.938c.097-.609-.243-1.182-.757-1.182-.638 0-.995.402-1.033.755l-.395 2.365H15.83l-.438-1.364c-.14-.387-.4-.574-.914-.574-.438 0-.692.332-.773.72l-.276 1.218h-2.341l-.454-2.733c-.081-.533-.655-.664-1.13-.533-.514.146-.752.609-.655 1.107l.417 2.159h-.833c-.416 0-.557.442-.557 1.016 0 .297.297.72.692.72h1.13l1.07 4.468c.076.518.73.795 1.428.795s1.011-.407 1.152-.87l1.168-4.393h.216l1.39 4.468c.178.594.713.795 1.33.795.697 0 1.151-.367 1.233-.94l.951-4.323Zm-7.543 3.1-.692-3.1h1.487l-.795 3.1Zm5.202-.146-.854-2.954h1.449l-.595 2.953Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgMoneyCircleBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMoneyCircleBoldIcon;

import dayjs from 'dayjs';
import React, { Suspense, lazy, useEffect, useState } from 'react';

import '@/css/calendarPopupStyle.css';
import { Dialog } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateCalendar = lazy(() =>
  import('@mui/x-date-pickers/DateCalendar').then((module) => ({ default: module.DateCalendar })),
);

interface BasicPopupProps {
  isShow: boolean;
  selectedDate: string;
  onChangeSelectedDate: (date: string) => void;
  onClose: () => void;
  textRightBtn?: string;
  onClickRightBtn?: () => void;
  textLeftBtn?: string;
  onClickLeftBtn?: () => void;
}

const CalendarPopup: React.FC<BasicPopupProps> = ({
  isShow,
  selectedDate,
  onChangeSelectedDate,
  onClose,
  textRightBtn,
  onClickRightBtn,
  textLeftBtn,
  onClickLeftBtn,
}) => {
  const [value, setValue] = useState<any>(selectedDate);

  useEffect(() => {
    if (selectedDate !== value) {
      onChangeSelectedDate(dayjs(value).format('YYYY-MM-DD'));
      onClose();
    }
  }, [value]);

  useEffect(() => {
    setValue(selectedDate);
  }, [selectedDate]);

  return (
    <>
      <Dialog
        open={isShow}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="min-w-[320px]"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
          <div style={{ width: '256px', maxHeight: '330px' }}>
            <Suspense fallback={<></>}>
              <DateCalendar value={dayjs(value)} onChange={(newValue) => setValue(newValue)} />
            </Suspense>
          </div>
        </LocalizationProvider>
        <div className="border-t w-full flex justify-between items-center text-center">
          {textLeftBtn && onClickLeftBtn && (
            <div
              className="w-full cursor-pointer"
              style={{
                fontSize: '18px',
                fontWeight: '500',
                color: '#919191',
                paddingTop: '12px',
                paddingBottom: '12px',
              }}
              onClick={() => onClickLeftBtn()}
            >
              {textLeftBtn}
            </div>
          )}
          {textRightBtn && onClickRightBtn && (
            <div
              className="w-full border-l cursor-pointer"
              style={{
                fontSize: '18px',
                fontWeight: '500',
                color: '#1E42A6',
                paddingTop: '12px',
                paddingBottom: '12px',
              }}
              onClick={() => onClickRightBtn()}
            >
              {textRightBtn}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CalendarPopup;

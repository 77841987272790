import { useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { NavigationType, useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';

import { deleteFuelingHistory, patchFuelingHistory, postFuelingHistory } from '@/api/public';
import BasicFooterButton from '@/components/Common/Button/BasicFooterButton';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import AlertModal from '@/components/Common/Modal/AlertModal';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import CalendarPopup from '@/components/Common/Popup/CalendarPopup';
import TooltipComponent from '@/components/Common/Tooltip/TooltipComponent';
import FuelingReceiptUploader from '@/components/Drive/FuelingReceiptUploader';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR, QUANTITY_IS_LESS_THAN_10000L } from '@/const/errorMessage';
import { nonNumericOrDecimalPattern, positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { fuelingHistoryFormAtom } from '@/store/fuelingHistory';
import { formatNumber, formatYYYYMMDD } from '@/utils/common';

const FuelForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data: FuelingHistory = location.state?.data;
  const { id } = useParams();
  const { showToast } = useToastContext();
  const { member } = useMemberContext();
  const queryClient = useQueryClient();
  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);

  const setFuelingFormAtom = useSetAtom(fuelingHistoryFormAtom);

  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      navigate(-2);
    }
  }, [navigationType]);

  const [amountError, setAmountError] = useState(false);
  const [amountErrorMsg, setAmountErrorMsg] = useState('');

  const [fuelingForm, setFuelingForm] = useState<FuelingForm>({
    driveVehicleInfoId: null,
    receiptImageUrl: data?.receiptImageUrl,
    unitPrice: data?.unitPrice,
    amount: data?.amount,
    price: data?.price,
    refuelDay: data?.refuelDay || formatYYYYMMDD(new Date()),
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    content: <></>,
  });

  const [alertModalConfig, setAlertModalConfig] = useState<ModalConfig>({
    content: null,
  });

  useEffect(() => {
    if (member) {
      setFuelingForm({ ...fuelingForm, driveVehicleInfoId: member.driveVehicleInfo.id });
    }
  }, [member]);

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber: boolean) => {
    let { name, value } = e.target;
    let convertedValue = String(value).replace(nonNumericOrDecimalPattern, '');
    if (name === 'amount') {
      if (Number(convertedValue) < 10000) {
        setAmountError(false);
        setAmountErrorMsg('');
      } else {
        setAmountError(true);
        setAmountErrorMsg(QUANTITY_IS_LESS_THAN_10000L);
      }
    }

    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(convertedValue) && !convertedValue.startsWith('-')) {
        const str = convertedValue === '' ? null : convertedValue;
        calculateValues(name, Number(str));
      }
    } else {
      if (positiveFloatRegex.test(convertedValue.trim()) || convertedValue.trim() === '') {
        const str = convertedValue.trim() === '' ? null : convertedValue.trim();
        calculateValues(name, str);
      }
    }
  };

  const calculateValues = (name: string, value: string | number | null) => {
    let updatedForm = { ...fuelingForm, [name]: value };

    const { unitPrice, amount, price } = updatedForm;
    if (name === 'unitPrice' && amount && unitPrice) {
      updatedForm.price = Math.floor(unitPrice * amount);
    } else if (name === 'amount' && unitPrice && amount) {
      updatedForm.price = Math.floor(unitPrice * amount);
    } else if (name === 'price' && unitPrice && price) {
      updatedForm.amount = parseFloat((price / unitPrice).toFixed(2));
    } else if (name === 'price' && amount && price) {
      updatedForm.unitPrice = Math.floor(price / amount);
    }

    setFuelingForm(updatedForm);
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setFuelingForm({ ...fuelingForm, refuelDay: date });
    }
  };

  const postFuelingHistoryMutation = useMutation((request: FuelingForm) => postFuelingHistory(request), {
    onSuccess: (response) => {
      showToast('주유비가 추가되었어요.', 'success', 'bottom');
      setFuelingFormAtom({ ...fuelingForm, id: response.data.id });
      if (location.state?.searchParams) {
        queryClient.invalidateQueries({ queryKey: ['get-drive-info', location.state.searchParams] });
      }

      navigate('/drive/fuel', { state: { selectedDate: fuelingForm.refuelDay } });
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const patchFuelingHistoryMutation = useMutation(
    (request: { id: string; body: FuelingForm }) => patchFuelingHistory(request),
    {
      onSuccess: () => {
        showToast('주유비가 수정되었어요.', 'success', 'bottom');
        setFuelingFormAtom({ ...fuelingForm, id: Number(id) });

        navigate('/drive/fuel', { state: { selectedDate: fuelingForm.refuelDay } });
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const onClickSaveBtn = () => {
    if (validateForm()) {
      if (id) {
        if (patchFuelingHistoryMutation.isLoading) {
          return;
        }
        const request = {
          id: id,
          body: fuelingForm,
        };
        patchFuelingHistoryMutation.mutate(request);
      } else {
        if (postFuelingHistoryMutation.isLoading) {
          return;
        }
        postFuelingHistoryMutation.mutate(fuelingForm);
      }
    }
  };

  const setPopupInfoForValidForm = (content: string) => {
    setIsOpenAlertModal(true);
    setAlertModalConfig({
      content: content,
    });
  };

  const validateForm = () => {
    const { refuelDay, price } = fuelingForm;
    if (!refuelDay) {
      setPopupInfoForValidForm('주유일자를 입력해주세요.');
      return false;
    } else if (!price) {
      setPopupInfoForValidForm('주유금액을 입력해주세요.');
      return false;
    }

    return true;
  };

  const updateFuelingForm = (data: FuelingForm) => {
    setFuelingForm(data);
  };

  const onClickDateSelector = () => {
    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const onClickDelete = () => {
    setIsOpenModal(true);
    setModalConfig({
      content: (
        <span>
          {fuelingForm.refuelDay}
          <br />
          주유비를 삭제할까요?
        </span>
      ),
    });
  };

  const handleDeleteConfirmation = () => {
    setIsOpenModal(false);
    if (data?.id) {
      deleteFuelingHistoryMutation.mutate(data.id);
    }
  };

  const deleteFuelingHistoryMutation = useMutation((id: number) => deleteFuelingHistory(id), {
    onSuccess: () => {
      showToast('주유비가 삭제되었어요.', 'success', 'bottom');
      navigate('/drive/fuel');
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  return (
    <>
      <DefaultHelmet page="drive" />

      <MenuHeader title={`주유비 ${id ? '수정' : '추가'}`}></MenuHeader>
      <div className="w-full pt-[60px]">
        <section className="px-4 py-2 pb-24">
          <div className="pb-2 text-base">
            <div className="flex flex-col mb-2">
              <span className="flex-1 font-medium text-gray-8">영수증</span>
            </div>
            {fuelingForm.driveVehicleInfoId && (
              <FuelingReceiptUploader
                driveVehicleInfoId={fuelingForm.driveVehicleInfoId}
                receiptImageUrl={fuelingForm.receiptImageUrl}
                updateFuelingForm={updateFuelingForm}
              ></FuelingReceiptUploader>
            )}
          </div>

          <div className="flex flex-col gap-[30px] mt-[30px]">
            <div onClick={onClickDateSelector}>
              <TextInputLabelUp
                name="refuelDay"
                label="주유일자"
                placeholder="날짜 선택"
                value={fuelingForm.refuelDay}
                suffix={<ChevronRightIcon color={colors.gray[8]}></ChevronRightIcon>}
                readOnly
              />
            </div>

            <TextInputLabelUp
              name="unitPrice"
              label="단가"
              placeholder="단가 입력"
              value={fuelingForm?.unitPrice ? formatNumber(String(fuelingForm?.unitPrice)) : ''}
              onChange={(e) => onChangeFormInput(e, true)}
              maxLength={5}
              suffix="원"
              inputMode="numeric"
              tooltip={
                <TooltipComponent
                  title=""
                  content="단가는 예상 주유비 계산에 포함됩니다."
                  minWidth={220}
                  position="left"
                  alignmentTipbox="left"
                />
              }
            />

            <TextInputLabelUp
              name="amount"
              label="수량"
              placeholder="수량 입력"
              value={fuelingForm?.amount ? String(fuelingForm?.amount) : ''}
              onChange={(e) => onChangeFormInput(e, false)}
              error={amountError}
              errorMsg={amountErrorMsg}
              suffix="L"
              maxLength={6}
              inputMode="numeric"
              tooltip={
                <TooltipComponent
                  title=""
                  content="수량은 유가 보조금 계산에 포함됩니다."
                  minWidth={220}
                  position="left"
                  alignmentTipbox="left"
                />
              }
            />

            <TextInputLabelUp
              name="price"
              label="주유금액"
              placeholder="금액 입력"
              value={fuelingForm?.price ? formatNumber(String(fuelingForm?.price)) : ''}
              onChange={(e) => onChangeFormInput(e, true)}
              suffix="원"
              inputMode="numeric"
              maxLength={9}
              required
            />
          </div>
        </section>
      </div>
      {!id ? (
        <BasicFooterButton text="저장" onClick={() => onClickSaveBtn()} disabled={amountError}></BasicFooterButton>
      ) : (
        <DualFooterButton
          leftButtonText="삭제"
          onClickLeftButton={() => {
            onClickDelete();
          }}
          rightButtonText="저장"
          onClickRightButton={() => {
            onClickSaveBtn();
          }}
          disabledRight={!!id && (!fuelingForm.price || amountError)}
        ></DualFooterButton>
      )}

      <CalendarPopup
        isOpen={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        selectedDate={fuelingForm.refuelDay}
        onChangeSelectedDate={onChangeDate}
      />

      <ConfirmModal
        isOpen={isOpenModal}
        title={modalConfig.title}
        content={modalConfig.content}
        onLeftButtonClick={() => {
          setIsOpenModal(false);
        }}
        rightButtonLabel="삭제하기"
        onRightButtonClick={handleDeleteConfirmation}
      />

      <AlertModal
        isOpen={isOpenAlertModal}
        content={alertModalConfig.content}
        onClose={() => {
          setIsOpenAlertModal(false);
        }}
      />
    </>
  );
};

export default FuelForm;

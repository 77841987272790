import React, { ReactNode, forwardRef, useState } from 'react';

import { colors } from '@/const/colors';
import '@/css/inputStyle.css';

interface TextInputLabelOffProps {
  name: string;
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  type?: string;
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  suffix?: ReactNode;
  autoFocus?: boolean;
  maxLength?: number;
  enterKeyHint?: 'search' | 'next' | 'enter' | 'done' | 'go' | 'previous' | 'send' | undefined;
  paddingRight?: number;
}

const TextInputLabelOff = forwardRef<HTMLInputElement, TextInputLabelOffProps>(
  (
    {
      name,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      error,
      errorMsg,
      fontSize = 16,
      width,
      height,
      readOnly = false,
      type = 'text',
      inputMode = 'text',
      onKeyDown,
      disabled = false,
      suffix,
      autoFocus,
      maxLength,
      enterKeyHint = 'next',
      paddingRight = 0,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      if (onFocus) onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (onBlur) onBlur(e);
    };

    return (
      <div className="relative w-full">
        <div className="flex">
          <input
            className="simple-input"
            ref={ref}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={readOnly}
            type={type}
            style={{
              fontSize: `${fontSize}px`,
              width: width ? `${width}px` : '100%',
              height: height ? `${height}px` : '36px',
              borderBottom: `1px solid ${error ? colors.red : isFocused ? colors.blue[3] : colors.gray[3]}`,
              padding: '8px 4px',
              backgroundColor: disabled ? colors.gray[1] : 'transparent',
              color: disabled ? colors.gray[5] : colors.gray[8],
              cursor: readOnly ? 'pointer' : '',
              borderRadius: '0px',
              paddingRight: paddingRight ? `${paddingRight}px` : '',
            }}
            inputMode={inputMode}
            onKeyDown={onKeyDown}
            disabled={disabled}
            autoFocus={autoFocus}
            maxLength={maxLength}
            enterKeyHint={enterKeyHint}
          ></input>
          <div className="absolute top-0 right-2 flex justify-center items-center h-full">{suffix}</div>
        </div>

        {error && errorMsg && (
          <p
            className={`absolute text-red text-[13px] xxs:text-[12px] leading-[16px] ${
              height ? `top-[${height + 2}px]` : 'top-[38px]'
            }`}
          >
            {errorMsg}
          </p>
        )}
      </div>
    );
  },
);
export default TextInputLabelOff;

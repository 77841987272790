import React, { SVGProps } from 'react';

const SvgMemoBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 36 36" {...props}>
    <path stroke={props.color || 'currentColor'} strokeWidth={1.5} d="M8 11.25h12M8 16h8M8 21h4" />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M5.5 29.5v-23h17v10.215l1.5-.866V6a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-8.92l-1.5.865V29.5h-17Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="m20.243 19.549 11.259-6.5 1 1.732-11.259 6.5-1-1.732ZM31.185 11.5a1 1 0 0 1 1.366.366l1.5 2.598a1 1 0 0 1-.366 1.366l-11.692 6.75-1.291.746.002.004-6.446.836 3.94-5.159-.004-.007 1.3-.75 11.69-6.75Zm-12.176 8.912-1.38 1.806 2.255-.292-.875-1.514Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgMemoBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMemoBoldIcon;

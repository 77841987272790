import { AxiosError } from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import React, { Suspense, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation } from 'react-query';
import { NavigationType, useLocation, useNavigate, useNavigationType } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { getProductList } from '@/api/public';
import loadingLottie from '@/assets/lottie/loadingLottie.json';
import SearchBox from '@/components/Common/Input/SearchBox';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import ProductCard from '@/components/Common/ProductCard';
import PullToRefresh from '@/components/Common/PullToRefresh';
import ProductCardSkeleton from '@/components/Common/Skeleton/ProductListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import { BarsArrowDownOutlineIcon, BellIcon, EmptyTruckIcon, SearchOutlineIcon } from '@/components/Icon';
import ResetIcon from '@/components/Icon/ResetIcon';
import { colors } from '@/const/colors';
import { ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { SALES_TYPE_ASSURANCE, SALES_TYPE_THIRD_PARTY_DEALER } from '@/const/products';
import { THEUNBAN } from '@/const/referralCode';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import '@/css/customFabStyle.css';
import useSearchProductQuery from '@/hooks/query/useSearchProductQuery';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import usePartnerSession from '@/hooks/usePartnerSession';
import metadata from '@/metadatas/metadata';
import { PageMetas } from '@/metadatas/metadatas';
import { productsSearchParamsAtom } from '@/store/products';
import { compareObjects, getCurrentYear } from '@/utils/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Fab, MenuItem, SxProps, Zoom, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const Lottie = React.lazy(() => import('lottie-react'));

interface ResponseData {
  totalElements: number;
  totalPage: number;
  data: ProductsList[];
}

const ProductsList = () => {
  const authRedirect = useAuthRedirect();
  const { showToast } = useToastContext();
  const { productEnum } = useProductEnumContext();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  const setProductSearchParams = useSetAtom(productsSearchParamsAtom);
  const [title, setTitle] = useState('');
  const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [currentYear, setCurrentYear] = useState(getCurrentYear());
  const [searchParams] = useAtom(productsSearchParamsAtom);
  const setSearchParams = useSetAtom(productsSearchParamsAtom);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [isScrolling, setIsScrolling] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const theme = useTheme();
  const { ref, inView } = useInView();

  const isTheunban = usePartnerSession() === THEUNBAN;

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (location.state?.salesType) {
      setProductSearchParams({
        keyword: '',
        manufacturerCategoriesId: new Set([]),
        minYear: '2000',
        maxYear: (currentYear + 1).toString(),
        minTons: '1',
        maxTons: '27',
        minDistance: '',
        maxDistance: '',
        axis: '',
        transmission: '',
        loaded: new Set([]),
        minLoadedInnerLength: '',
        maxLoadedInnerLength: '',
        salesType: location.state.salesType,
        productsSortType: { code: 'CREATED_DATE', desc: '기본 정렬순' },
        page: 1,
      });
    }
  }, [location.state]);

  const keyValueList: KeyValueListType = {
    salesType: '판매구분',
    productsSortType: '정렬',
  };

  const handleChangeSalesType = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    const updatedParams = { ...searchParams, salesType: value };
    setSearchParams(updatedParams);
  };

  const handleSearch = async (page: number) => {
    try {
      const {
        keyword,
        manufacturerCategoriesId,
        minYear,
        maxYear,
        minTons,
        maxTons,
        minDistance,
        maxDistance,
        axis,
        transmission,
        loaded,
        minLoadedInnerLength,
        maxLoadedInnerLength,
        productsSortType,
        salesType,
      } = searchParams;

      const queryParams = new URLSearchParams({
        keyword: keyword || '',
        manufacturerCategoriesId: Array.from(manufacturerCategoriesId).toString(),
        minYear: minYear || '',
        maxYear: maxYear || '',
        minTons: minTons || '',
        maxTons: maxTons || '',
        minDistance: minDistance !== '' ? String(Number(minDistance) * 10000) : '',
        maxDistance: maxDistance !== '' ? String(Number(maxDistance) * 10000) : '',
        axis: axis || '',
        transmission: transmission || '',
        loaded: Array.from(loaded).toString(),
        minLoadedInnerLength: minLoadedInnerLength || '',
        maxLoadedInnerLength: maxLoadedInnerLength || '',
        productsSortType: productsSortType.code || 'CREATED_DATE',
        page: page.toString(),
        salesType: salesType || '',
      });
      const response = await getProductList(queryParams);
      const responseData: ResponseData = response?.data;

      return responseData;
    } catch (error) {
      console.log(error);
    }
  };

  const { products, totalCounts, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useSearchProductQuery({
    rowsPerPage: 10,
    queryFn: ({ queryKey, pageParam = 1 }) => {
      return handleSearch(pageParam);
    },
    searchParams,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const onClickSelectBox = (key: string) => {
    if (!productEnum) {
      return;
    }
    setTitle(key);
    setOptionData(productEnum.productsSortType.filter((item) => item.code !== 'MODIFIED_PRICE_DATE'));
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    setSearchParams({ ...searchParams, [key]: item });
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const isShowInterestProductNotification = () => {
    const { loaded, maxLoadedInnerLength, maxTons, maxYear, minLoadedInnerLength, minTons, minYear } = searchParams;

    return minTons && maxTons && minYear && maxYear && loaded && minLoadedInnerLength && maxLoadedInnerLength;
  };

  const setInterestProduct = async (searchParams: ProductsSearch): Promise<any> => {
    const {
      axis,
      loaded,
      maxDistance,
      maxLoadedInnerLength,
      maxTons,
      maxYear,
      minDistance,
      minLoadedInnerLength,
      manufacturerCategoriesId,
      minTons,
      minYear,
      transmission,
    } = searchParams;

    const manufacturerCategoriesIdString = Array.from(manufacturerCategoriesId).join(',');
    const loadedAllArray = productEnum?.loaded?.map((item) => item.code) ?? [];

    const loadedSet = new Set<string>(loadedAllArray);

    const loadedString = loaded.size > 0 ? Array.from(loaded).join(',') : Array.from(loadedSet).join(',');

    const requestData = {
      axis,
      loaded: loadedString === '' ? null : loadedString,
      maxDistance: maxDistance === '' ? null : Number(maxDistance),
      maxLoadedInnerLength: Number(maxLoadedInnerLength),
      maxTons: Number(maxTons),
      maxYear: Number(maxYear),
      minDistance: minDistance === '' ? null : Number(minDistance),
      minLoadedInnerLength: Number(minLoadedInnerLength),
      manufacturerCategoriesId: manufacturerCategoriesIdString === '' ? null : manufacturerCategoriesIdString,
      minTons: Number(minTons),
      minYear: Number(minYear),
      transmission,
    };

    const { data } = await apiManager.post(`/api/v1/interest-product-notification-settings`, requestData);
    return data;
  };

  const { mutate, error, isSuccess } = useMutation(setInterestProduct, {
    onSuccess: () => {
      showToast('관심 차량이 등록되었습니다.', 'success', 'bottom');
    },
    onError: (error: AxiosError) => {
      if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const onClickSearch = () => {
    navigate('/products/search');
  };

  const isShowFilterResetBtn = () => {
    const initalSearchParams = {
      keyword: '',
      manufacturerCategoriesId: new Set([]),
      minYear: '2000',
      maxYear: (currentYear + 1).toString(),
      minTons: '1',
      maxTons: '27',
      minDistance: '0',
      maxDistance: '200',
      axis: '',
      transmission: '',
      loaded: new Set([]),
      minLoadedInnerLength: '',
      maxLoadedInnerLength: '',
      salesType: 'ALL',
      productsSortType: { code: 'CREATED_DATE', desc: '기본 정렬순' },
      page: 1,
    };

    return !compareObjects(searchParams, initalSearchParams);
  };

  const onClickFilterClearBtn = () => {
    setSearchParams({
      keyword: '',
      manufacturerCategoriesId: new Set([]),
      minYear: '2000',
      maxYear: (currentYear + 1).toString(),
      minTons: '1',
      maxTons: '27',
      minDistance: '0',
      maxDistance: '200',
      axis: '',
      transmission: '',
      loaded: new Set([]),
      minLoadedInnerLength: '',
      maxLoadedInnerLength: '',
      salesType: 'ALL',
      productsSortType: { code: 'CREATED_DATE', desc: '기본 정렬순' },
      page: 1,
    });
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabs = [
    {
      key: 1,
      variant: 'circular' as 'circular',
      className: 'bg-gradient-to-b from-[#1E42A6] to-[#376AEF] flex',
      sx: fabStyle as SxProps,
      icon: <SearchOutlineIcon color={colors.gray[0]} width={21} height={22} />,
    },
    {
      key: 2,
      variant: 'extended' as 'extended',
      className: 'bg-gradient-to-b from-[#1E42A6] to-[#376AEF] flex',
      sx: fabStyle as SxProps,
      icon: (
        <>
          <span>
            <SearchOutlineIcon color={colors.gray[0]} width={21} height={22} />
          </span>
          <span className="text-white text-base font-semibold ml-[10px]">내가 찾는 차량이 없다면?</span>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (
      navigationType !== NavigationType.Pop &&
      location.state?.salesType !== SALES_TYPE_ASSURANCE &&
      !location.state?.isSearch
    ) {
      onClickFilterClearBtn();
    }
  }, []);

  const handleClickFab = () => {
    if (!authRedirect(window.location.pathname)) {
      return;
    }
    navigate('/notification/products/form');
  };

  return (
    <>
      <PageMetas {...metadata.purchase}></PageMetas>
      <div className="w-full h-full">
        <MenuHeader title={`${isTheunban ? '내차사기' : '내차구매'}`} isMain={true}></MenuHeader>
        <div className="p-4 pb-28 pt-[120px] h-full">
          <div className="fixed items-center flex z-30 top-[55px] mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] h-15 bg-white">
            <SearchBox name="truckName" placeholder="차량을 검색해보세요." value="" onClick={onClickSearch}></SearchBox>
          </div>
          <PullToRefresh
            onRefresh={() => refetch()}
            maxDistance={100}
            disabled={isSelectOpen}
            loadingComponent={
              <div className="flex justify-center items-center h-[100px]">
                <div className="w-[54px] h-[54px]">
                  <Suspense fallback={<></>}>
                    <Lottie animationData={loadingLottie} />
                  </Suspense>
                </div>
              </div>
            }
          >
            <div className="flex justify-between">
              <div className="min-w-[70px]">
                <Select
                  sx={{
                    minWidth: 70,
                    minHeight: 32,
                    height: '32px',
                    fontSize: '14px',
                    borderRadius: '5px',
                    paddingX: '0px',
                  }}
                  inputProps={{ MenuProps: { disableScrollLock: true } }}
                  IconComponent={ExpandMoreIcon}
                  value={searchParams.salesType || 'ALL'}
                  onChange={handleChangeSalesType}
                  onOpen={() => setIsSelectOpen(true)}
                  onClose={() => setIsSelectOpen(false)}
                >
                  {productEnum?.salesType.map(
                    (data, index) =>
                      data.code !== SALES_TYPE_THIRD_PARTY_DEALER && (
                        <MenuItem value={data.code} key={index} sx={{ paddingX: '12px', paddingY: '8px' }}>
                          {data?.desc}
                        </MenuItem>
                      ),
                  )}
                </Select>
              </div>

              <div className="flex flex-wrap flex-wrap-reverse items-center justify-end">
                {isShowFilterResetBtn() ? (
                  <div className="flex items-center cursor-pointer h-[33px]" onClick={onClickFilterClearBtn}>
                    <ResetIcon color={colors.gray[8]}></ResetIcon>
                    <span className="text-gray-8 text-sm ml-1">필터해제</span>
                  </div>
                ) : null}

                <Button
                  className="flex px-2 items-center border-none text-sm tracking-tight my-1"
                  onClick={() => onClickSelectBox('productsSortType')}
                  disableRipple
                  sx={{
                    border: '0',
                    backgroundColor: 'white',
                    color: '#414141',
                    height: '33px',
                    fontSize: '14px',
                    padding: '0px',
                    marginLeft: '8px',
                    fontWeight: '400',
                    '&:hover': {
                      backgroundColor: 'white',
                      border: '0',
                      color: '#414141',
                    },
                  }}
                >
                  <BarsArrowDownOutlineIcon color={colors.gray[8]}></BarsArrowDownOutlineIcon>
                  <span className="tracking-tight ml-[2px]">{searchParams.productsSortType.desc}</span>
                </Button>
              </div>
            </div>

            <div className="h-full min-h-[calc(100vh-270px)] flex flex-col">
              {isFetching || isLoading ? (
                <div>
                  <ProductCardSkeleton />
                </div>
              ) : products?.length !== 0 ? (
                <section className="flex flex-col">
                  <h2 className="hidden">차량보기 리스트</h2>
                  {products?.map((item, index) => (
                    <ProductCard key={index} products={item} index={index} />
                  ))}
                  <div
                    ref={ref}
                    className={`h-40 flex flex-col justify-center items-center pb-[76px] ${
                      hasNextPage ? '' : 'hidden'
                    }`}
                  >
                    <MoreVertIcon sx={{ fontSize: 80, color: colors.gray[2] }}></MoreVertIcon>
                  </div>
                </section>
              ) : (
                <div className="flex flex-col justify-center items-center min-h-[calc(100vh-270px)]">
                  {!isShowInterestProductNotification() && (
                    <EmptyTruckIcon color={colors.gray[4]} width={120} height={120}></EmptyTruckIcon>
                  )}
                  <span className="text-gray-7 text-lg mt-4 mb-[26px] text-center">
                    판매 중인 차량이 없습니다.
                    <br />
                    필터를 다시 설정해주세요.
                  </span>
                  {isShowInterestProductNotification() && (
                    <Button
                      variant="contained"
                      onClick={() => mutate(searchParams)}
                      sx={{ fontSize: '16px', fontWeight: 'bold' }}
                      className="w-[200px]"
                    >
                      <BellIcon color={'white'}></BellIcon>
                      <span className="ml-2">관심 차량 알림 받기</span>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </PullToRefresh>
        </div>
      </div>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="my-2">
          {optionData.map((item, index) => (
            <div
              key={index}
              className={`p-4 ${item.code === 'DELETE' && 'text-red'}`}
              onClick={() => onClickItem(item, title)}
            >
              {item.desc}
            </div>
          ))}
        </div>
      </DownToUpDetailPopup>
      <div className="flex items-center justify-end fixed w-full mx-auto my-0 pr-4 pt-2 pb-4 bottom-[80px] left-0 right-0 max-w-[720px] min-w-[280px]">
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.key}
            in={isScrolling ? index % 2 === 0 : index % 2 !== 0}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${isScrolling ? transitionDuration.exit : 0}ms`,
            }}
            mountOnEnter
            unmountOnExit
          >
            <Fab sx={fab.sx} className={fab.className} variant={fab.variant} onClick={handleClickFab}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </div>
    </>
  );
};

export default ProductsList;

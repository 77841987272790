import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import SvgPlusCircleOutlineIcon from '../../Icon/PlusCircleOutlineIcon';
import { createOtherExpensesCategory } from '@/api/drive/createDrive';
import { deleteOtherExpensesCategory } from '@/api/drive/deleteDrive';
import { updateOtherExpensesCategory } from '@/api/drive/updateDrive';
import BasicButton from '@/components/Common/Button/BasicButton';
import RadioList from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import BottomSheetPopup from '@/components/Common/Popup/BottomSheetPopup';
import { MinusCircleSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { addCategoryAtom, categoriesAtom, deleteCategoryAtom, updateCategoryAtom } from '@/store/category';

interface CategorySelectModalProps {
  type: string | undefined;
  isEditMode: boolean;
  onClosePopup: () => void;
  driveVehicleInfoId: number;
  selectedCategoryId: number | null;
  updateSelectedCategoryId: (categoryId: number) => void;
  data?: FuelingHistory;
  refetch: () => void;
}

const CategorySelectModal = ({
  type,
  isEditMode,
  onClosePopup,
  driveVehicleInfoId,
  selectedCategoryId,
  updateSelectedCategoryId,
  data,
  refetch,
}: CategorySelectModalProps) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isOpenAddCategoryPopup, setIsOpenAddCateogryPopup] = useState(false);
  const [categoryInput, setCategoryInput] = useState('');
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState<OtherExpensesCategory | null>(null);
  const [selectedCategoryForDelete, setSelectedCategoryForDelete] = useState<OtherExpensesCategory | null>(null);

  const [categoryList, setCategoryList] = useAtom(categoriesAtom);

  const { showToast } = useToastContext();

  const [, addCategory] = useAtom(addCategoryAtom);
  const [, updateCategory] = useAtom(updateCategoryAtom);
  const [, deleteCategory] = useAtom(deleteCategoryAtom);

  useEffect(() => {
    if (selectedCategoryId) {
      setSelectedCategory(String(selectedCategoryId));
    }
  }, [selectedCategoryId]);

  const popupTitle = {
    EXPENSE: '지출',
    INCOME: '수익',
  };

  const handleChangeCategoryRadio = (val: EnumPresenter) => {
    setSelectedCategory(val.code);
  };

  const handleClickSelectBtn = () => {
    updateSelectedCategoryId(Number(selectedCategory));
    onClosePopup();
  };

  const handleConfirmDelete = () => {
    if (selectedCategoryForDelete) {
      deleteOtherExpensesCategoryMutation.mutate(selectedCategoryForDelete?.id);
    }
  };

  const handleOpenCategoryModal = (item?: OtherExpensesCategory) => {
    if (item) {
      setSelectedCategoryForEdit(item);
      setCategoryInput(item.name);
      setIsOpenAddCateogryPopup(true);
    } else {
      setCategoryInput('');
      setSelectedCategoryForEdit(null);
      setIsOpenAddCateogryPopup(true);
    }
  };

  const handleCategorySave = () => {
    setIsOpenAddCateogryPopup(false);
    if (selectedCategoryForEdit?.id) {
      const requestData = {
        otherExpensesCategoryId: selectedCategoryForEdit?.id,
        name: categoryInput,
      };

      updateOtherExpensesCategoryMutation.mutate(requestData);
    } else {
      const requestData = {
        driveVehicleInfoId: driveVehicleInfoId,
        name: categoryInput,
        type: type || '',
      };

      postOtherExpensesCategoryMutation.mutate(requestData);
    }
  };

  const handleChangeCateogryInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCategoryInput(value);
  };

  const openDeleteConfirmation = (item: OtherExpensesCategory) => {
    setSelectedCategoryForDelete(item);
    setIsOpenConfirmModal(true);
  };

  const postOtherExpensesCategoryMutation = useMutation(
    (request: OtherExpensesCategoryRequest) => createOtherExpensesCategory(request),
    {
      onSuccess: (data) => {
        addCategory(data.data);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const updateOtherExpensesCategoryMutation = useMutation(
    (request: OtherExpensesCategoryUpdateRequest) => updateOtherExpensesCategory(request),
    {
      onSuccess: (data) => {
        updateCategory(data.data);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const deleteOtherExpensesCategoryMutation = useMutation((id: number) => deleteOtherExpensesCategory(id), {
    onSuccess: (data, id) => {
      showToast('선택한 카테고리를 삭제했어요.', 'success', 'bottom');
      deleteCategory(id);
      refetch();

      if (id === selectedCategoryId) {
        updateSelectedCategoryId(0);
      }
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    onSettled: () => {
      setIsOpenConfirmModal(false);
    },
  });

  const typeCategories = categoryList.filter((item) => item.type.code === type);

  const getFilteredCategories = () =>
    categoryList
      .filter((item) => item.type.code === type)
      .map((item) => ({
        code: String(item.id),
        desc: item.name,
      }));

  const renderAddCategoryButton = () => (
    <div className="flex mt-3 text-gray-6" onClick={() => handleOpenCategoryModal()}>
      <SvgPlusCircleOutlineIcon color={colors.gray[4]} width={24} height={24} />
      <span className="ml-[6px]">카테고리 추가</span>
    </div>
  );

  const renderEmptyCategoryMessage = () => (
    <div className="flex flex-col justify-center items-center w-full h-[calc(100dvh-212px)]">
      <span className="text-gray-7 mb-[30px]">등록된 카테고리가 없습니다.</span>
      <BasicButton
        name={`새 카테고리 추가하기`}
        bgColor={colors.gray[2]}
        borderColor={colors.gray[2]}
        textColor={colors.gray[7]}
        width={230}
        height={48}
        fontSize={16}
        fontWeight="semibold"
        onClick={handleOpenCategoryModal}
      />
    </div>
  );

  const renderSelectButton = () => (
    <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white z-30">
      <div className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`} />
      <div className="w-full min-w-[240px]">
        <BasicButton
          name={`선택`}
          bgColor={colors.primary}
          borderColor={colors.primary}
          textColor={colors.gray[0]}
          fontSize={16}
          height={48}
          fontWeight="bold"
          onClick={handleClickSelectBtn}
          isDisabled={!selectedCategory}
        />
      </div>
    </div>
  );

  const renderEditMode = () => (
    <>
      {typeCategories.map((item, index) => (
        <div className="flex items-center h-[42px] w-full" key={index}>
          <MinusCircleSolidIcon
            color={colors.red}
            width={24}
            height={24}
            onClick={() => openDeleteConfirmation(item)}
          />
          <span
            className="text-gray-9 h-[42px] w-full border-b border-gray-4 ml-[6px] leading-[42px]"
            onClick={() => handleOpenCategoryModal(item)}
          >
            {item.name}
          </span>
        </div>
      ))}
      {renderAddCategoryButton()}
    </>
  );

  const renderViewMode = () => {
    const filteredCategories = getFilteredCategories();

    return filteredCategories.length > 0 ? (
      <>
        <RadioList
          name={''}
          list={filteredCategories}
          value={selectedCategory}
          onChange={handleChangeCategoryRadio}
          horizontal={false}
        />
        {renderSelectButton()}
      </>
    ) : (
      renderEmptyCategoryMessage()
    );
  };

  return (
    <>
      <div className="mb-24">
        <div className="px-4">{isEditMode ? renderEditMode() : renderViewMode()}</div>
      </div>

      <BottomSheetPopup
        isOpen={isOpenAddCategoryPopup}
        onClose={() => {
          setIsOpenAddCateogryPopup(false);
        }}
        title={`${popupTitle[type as CategoryType]} 카테고리 ${selectedCategoryForEdit?.id ? '수정' : '추가'}`}
      >
        <div className="px-4 py-6">
          <div className="relative flex justify-between w-full align-center">
            <TextInputLabelOff
              name="categoryName"
              placeholder="카테고리명 입력"
              value={categoryInput}
              onChange={handleChangeCateogryInput}
              type="text"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleCategorySave();
                }
              }}
              maxLength={15}
              fontSize={18}
              paddingRight={96}
            />
            <div className="absolute right-0 bottom-2">
              <BasicButton
                name="확인"
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={14}
                width={80}
                height={30}
                borderRadius={12}
                fontWeight="semibold"
                onClick={() => handleCategorySave()}
                isDisabled={!categoryInput}
              />
            </div>
          </div>
          <div className="text-gray-6 text-[14px]">최대 15자까지 입력 가능합니다.</div>
        </div>
      </BottomSheetPopup>

      <ConfirmModal
        isOpen={isOpenConfirmModal}
        title={`'${selectedCategoryForDelete?.name}' 카테고리를<br/> 삭제할까요?`}
        content={
          <span className="text-gray-7">
            *카테고리에 포함된 내역은 모두
            <br />
            '미분류'로 구분됩니다.
          </span>
        }
        onLeftButtonClick={() => {
          setIsOpenConfirmModal(false);
        }}
        rightButtonLabel="삭제"
        onRightButtonClick={handleConfirmDelete}
      />
    </>
  );
};

export default CategorySelectModal;

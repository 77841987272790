import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import FileUploader from '@/components/Common/Uploader/FileUploader';
import MenuHeader from '@/components/Header/MenuHeader';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { PRODUCT_SALE_URL } from '@/const/url';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import { productsFormAtom, useResetProductsForm } from '@/store/products';

type SalesTypeKey = keyof typeof SALESTYPE;

interface productImageType {
  id: string;
  name: string;
  url: string;
  imageType: string;
  isLoading?: boolean;
}

interface ProductImageComponentType {
  item: productImageType;
  truckNumber: string;
  index: number;
  productImageListData: productImageType[];
  setProductImageListData: React.Dispatch<React.SetStateAction<productImageType[]>>;
  isLoading: boolean;
}

const ProductImageComponent = ({
  item,
  truckNumber,
  index,
  productImageListData,
  setProductImageListData,
  isLoading,
}: ProductImageComponentType) => {
  const [file, setFile] = useState(productImageListData[index]);

  useEffect(() => {
    const updatedList = productImageListData.map((image: productImageType) =>
      image.id === file.id ? { ...image, url: file.url } : image,
    );
    setProductImageListData(updatedList);
  }, [file]);

  return (
    <>
      <label className="text-base sm280:text-[14px] font-medium text-gray-7">
        {item.name}
        {index < 2 && <span className="font-normal text-red">(필수)</span>}
      </label>
      <FileUploader
        truckNumber={truckNumber}
        imageType={item.imageType}
        fileData={item}
        setFileData={setFile}
        isLoading={isLoading}
        icon="photo"
      />
    </>
  );
};

const PhotoUploadForm = () => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const { id } = useParams();

  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false);
  useBlockNavigation(setIsShowConfirmPopup);

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);
  const [isShowGuidePopup, setIsShowGuidePopup] = useState(true);

  const [loadingList, setLoadingList] = useState<boolean[]>([]);

  const [certificateData, setCerticicateData] = useState<productImageType>({
    id: 'certificateImageUrl',
    name: '차량등록증',
    url: productFormData?.productsImage?.certificateImageUrl || '',
    imageType: 'CERTIFICATE_IMAGE',
  });

  const [frontSideImageData, setFrontsideImageData] = useState<productImageType>({
    id: 'frontSideImageUrl',
    name: '앞측면',
    url: productFormData?.productsImage?.frontSideImageUrl || '',
    imageType: 'FRONT_SIDE_IMAGE',
  });

  const optionImageData = () => {
    const optionImages: productImageType[] = [];

    const optionImageUrl = productFormData?.productsImage?.optionImageUrl || [];

    for (let i = 0; i < optionImageUrl?.length - 4; i++) {
      optionImages.push({
        id: `optionImageUrl${i + 5}`,
        name: `옵션${i + 5}`,
        url: optionImageUrl[i + 4] || '',
        imageType: 'OPTION_IMAGE',
      });
    }

    return optionImages;
  };

  const [productImageListData, setProductImageListData] = useState<productImageType[]>([
    {
      id: 'backSideImageUrl',
      name: '뒷측면',
      url: productFormData?.productsImage?.backSideImageUrl || '',
      imageType: 'BACK_SIDE_IMAGE',
    },
    {
      id: 'frontImageUrl',
      name: '전면',
      url: productFormData?.productsImage?.frontImageUrl || '',
      imageType: 'FRONT_IMAGE',
    },
    {
      id: 'backImageUrl',
      name: '후면',
      url: productFormData?.productsImage?.backImageUrl || '',
      imageType: 'BACK_IMAGE',
    },
    {
      id: 'tireImageUrl',
      name: '휠/타이어',
      url: productFormData?.productsImage?.tireImageUrl || '',
      imageType: 'TIRE_IMAGE',
    },
    {
      id: 'engineImageUrl',
      name: '엔진',
      url: productFormData?.productsImage?.engineImageUrl || '',
      imageType: 'ENGINE_IMAGE',
    },
    {
      id: 'insideImageUrl',
      name: '실내',
      url: productFormData?.productsImage?.insideImageUrl || '',
      imageType: 'INSIDE_IMAGE',
    },
    {
      id: 'dashboardImageUrl',
      name: '계기판',
      url: productFormData?.productsImage?.dashboardImageUrl || '',
      imageType: 'DASHBOARD_IMAGE',
    },
    {
      id: 'sheetImageUrl',
      name: '시트',
      url: productFormData?.productsImage?.sheetImageUrl || '',
      imageType: 'SHEET_IMAGE',
    },
    {
      id: 'optionImageUrl1',
      name: '옵션1',
      url: productFormData?.productsImage?.optionImageUrl?.[0] || '',
      imageType: 'OPTION_IMAGE',
    },
    {
      id: 'optionImageUrl2',
      name: '옵션2',
      url: productFormData?.productsImage?.optionImageUrl?.[1] || '',
      imageType: 'OPTION_IMAGE',
    },
    {
      id: 'optionImageUrl3',
      name: '옵션3',
      url: productFormData?.productsImage?.optionImageUrl?.[2] || '',
      imageType: 'OPTION_IMAGE',
    },
    {
      id: 'optionImageUrl4',
      name: '옵션4',
      url: productFormData?.productsImage?.optionImageUrl?.[3] || '',
      imageType: 'OPTION_IMAGE',
    },
    ...optionImageData(),
  ]);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({
    url: PRODUCT_SALE_URL,
  });

  const handleClickExitBtn = () => {
    setIsShowConfirmPopup(false);

    const changedData = getChangedData();

    if (Object.keys(changedData).length > 0) {
      const request = {
        id: Number(productFormData?.id),
        certificateImageUrl: changedData.certificateImageUrl,
        frontSideImageUrl: changedData.frontSideImageUrl,
        backSideImageUrl: changedData.backSideImageUrl,
        frontImageUrl: changedData.frontImageUrl,
        backImageUrl: changedData.backImageUrl,
        tireImageUrl: changedData.tireImageUrl,
        engineImageUrl: changedData.engineImageUrl,
        insideImageUrl: changedData.insideImageUrl,
        dashboardImageUrl: changedData.dashboardImageUrl,
        sheetImageUrl: changedData.sheetImageUrl,
        optionImageUrls: changedData.optionImageUrls,
      };
      updateProductTemporarySaveMutation.mutate(request);
    } else {
      navigate(PRODUCT_SALE_URL, { replace: true });
      resetProductsForm();
    }
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/price/${id}`,
  });

  const handleClickNext = () => {
    const changedData = getChangedData();

    if (Object.keys(changedData).length > 0) {
      const request = {
        id: Number(productFormData?.id),
        certificateImageUrl: changedData.certificateImageUrl,
        frontSideImageUrl: changedData.frontSideImageUrl,
        backSideImageUrl: changedData.backSideImageUrl,
        frontImageUrl: changedData.frontImageUrl,
        backImageUrl: changedData.backImageUrl,
        tireImageUrl: changedData.tireImageUrl,
        engineImageUrl: changedData.engineImageUrl,
        insideImageUrl: changedData.insideImageUrl,
        dashboardImageUrl: changedData.dashboardImageUrl,
        sheetImageUrl: changedData.sheetImageUrl,
        optionImageUrls: changedData.optionImageUrls,
      };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/price/${id}`, { replace: true });
    }
  };

  function arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const onClickUploadAll = () => {
    const inputElement = document.getElementById(`upload-images-all`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const filesList = e.target.files;
    uploadProductImage(filesList);
  };

  const uploadProductImage = async (filesList: FileList) => {
    if (filesList.length > 15) {
      showToast('이미지는 최대 15개까지 첨부할 수 있어요.', 'error', 'top');
      return;
    }
    setLoadingList(Array.from({ length: filesList.length }, () => true));

    const reader = new FileReader();
    const formData = new FormData();
    const filesArray = Array.from(filesList);

    filesArray.forEach((file, index) => {
      formData.append(`uploadFile`, file);
    });
    formData.append('truckNumber', productFormData.truckName ? productFormData.truckName : '');

    try {
      setProductImageListData([
        {
          id: 'backSideImageUrl',
          name: '뒷측면',
          url: '',
          imageType: 'BACK_SIDE_IMAGE',
        },
        {
          id: 'frontImageUrl',
          name: '전면',
          url: '',
          imageType: 'FRONT_IMAGE',
        },
        {
          id: 'backImageUrl',
          name: '후면',
          url: '',
          imageType: 'BACK_IMAGE',
        },
        {
          id: 'tireImageUrl',
          name: '휠/타이어',
          url: '',
          imageType: 'TIRE_IMAGE',
        },
        {
          id: 'engineImageUrl',
          name: '엔진',
          url: '',
          imageType: 'ENGINE_IMAGE',
        },
        {
          id: 'insideImageUrl',
          name: '실내',
          url: '',
          imageType: 'INSIDE_IMAGE',
        },
        {
          id: 'dashboardImageUrl',
          name: '계기판',
          url: '',
          imageType: 'DASHBOARD_IMAGE',
        },
        {
          id: 'sheetImageUrl',
          name: '시트',
          url: '',
          imageType: 'SHEET_IMAGE',
        },
        {
          id: 'optionImageUrl1',
          name: '옵션1',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl2',
          name: '옵션2',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl3',
          name: '옵션3',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl4',
          name: '옵션4',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
      ]);

      const path = '/api/v1/products-images/multi';
      const response = await apiManager.post(path, formData, {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      });

      if (response.data) {
        if (filesList) {
          const filesArray = response.data;

          setProductImageListData((prevList) => {
            const newList = prevList.map((image, index) => ({
              id: image.id,
              name: image.name,
              url: index < filesArray.length ? filesArray[index].url.toString() : '',
              imageType: image.imageType,
            }));

            const remainingImagesCount = filesArray.length - prevList.length;
            if (remainingImagesCount > 0) {
              for (let i = prevList.length; i < prevList.length + remainingImagesCount; i++) {
                newList.push({
                  id: `optionImageUrl${i - 7}`,
                  name: `옵션${i - 7}`,
                  url: filesArray[i].url.toString(),
                  imageType: 'OPTION_IMAGE',
                });
              }
            }

            return newList;
          });
        }
      } else {
        console.error('File upload failed');
      }
    } catch (error) {
      showToast('이미지 업로드에 문제가 생겼어요.', 'error', 'bottom');
    } finally {
      setLoadingList(Array.from({ length: filesList.length }, () => false));
    }
  };

  useEffect(() => {
    if (productFormData.id === 0) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          setProductFormData(responseData);
        })
        .catch(() => {});
    } else {
      setCerticicateData({
        id: 'certificateImageUrl',
        name: '차량등록증',
        url: productFormData?.productsImage?.certificateImageUrl || '',
        imageType: 'CERTIFICATE_IMAGE',
      });

      setFrontsideImageData({
        id: 'frontSideImageUrl',
        name: '앞측면',
        url: productFormData?.productsImage?.frontSideImageUrl || '',
        imageType: 'FRONT_SIDE_IMAGE',
      });

      setProductImageListData([
        {
          id: 'backSideImageUrl',
          name: '뒷측면',
          url: productFormData?.productsImage?.backSideImageUrl || '',
          imageType: 'BACK_SIDE_IMAGE',
        },
        {
          id: 'frontImageUrl',
          name: '전면',
          url: productFormData?.productsImage?.frontImageUrl || '',
          imageType: 'FRONT_IMAGE',
        },
        {
          id: 'backImageUrl',
          name: '후면',
          url: productFormData?.productsImage?.backImageUrl || '',
          imageType: 'BACK_IMAGE',
        },
        {
          id: 'tireImageUrl',
          name: '휠/타이어',
          url: productFormData?.productsImage?.tireImageUrl || '',
          imageType: 'TIRE_IMAGE',
        },
        {
          id: 'engineImageUrl',
          name: '엔진',
          url: productFormData?.productsImage?.engineImageUrl || '',
          imageType: 'ENGINE_IMAGE',
        },
        {
          id: 'insideImageUrl',
          name: '실내',
          url: productFormData?.productsImage?.insideImageUrl || '',
          imageType: 'INSIDE_IMAGE',
        },
        {
          id: 'dashboardImageUrl',
          name: '계기판',
          url: productFormData?.productsImage?.dashboardImageUrl || '',
          imageType: 'DASHBOARD_IMAGE',
        },
        {
          id: 'sheetImageUrl',
          name: '시트',
          url: productFormData?.productsImage?.sheetImageUrl || '',
          imageType: 'SHEET_IMAGE',
        },
        {
          id: 'optionImageUrl1',
          name: '옵션1',
          url: productFormData?.productsImage?.optionImageUrl?.[0] || '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl2',
          name: '옵션2',
          url: productFormData?.productsImage?.optionImageUrl?.[1] || '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl3',
          name: '옵션3',
          url: productFormData?.productsImage?.optionImageUrl?.[2] || '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl4',
          name: '옵션4',
          url: productFormData?.productsImage?.optionImageUrl?.[3] || '',
          imageType: 'OPTION_IMAGE',
        },
        ...optionImageData(),
      ]);
    }
  }, [productFormData]);

  const handleClosePopup = () => {
    setIsShowConfirmPopup(false);
  };

  const getChangedData = () => {
    const changedData: Partial<ProductRegisterRequest> = {};

    if (prevData?.productsImage?.certificateImageUrl !== certificateData.url) {
      changedData.certificateImageUrl = certificateData.url;
    }
    if (prevData?.productsImage?.frontSideImageUrl !== frontSideImageData.url) {
      changedData.frontSideImageUrl = frontSideImageData?.url;
    }
    if (prevData?.productsImage?.backSideImageUrl !== productImageListData[0].url) {
      changedData.backSideImageUrl = productImageListData[0].url;
    }
    if (prevData?.productsImage?.frontImageUrl !== productImageListData[1].url) {
      changedData.frontImageUrl = productImageListData[1].url;
    }
    if (prevData?.productsImage?.backImageUrl !== productImageListData[2].url) {
      changedData.backImageUrl = productImageListData[2].url;
    }
    if (prevData?.productsImage?.tireImageUrl !== productImageListData[3].url) {
      changedData.tireImageUrl = productImageListData[3].url;
    }
    if (prevData?.productsImage?.engineImageUrl !== productImageListData[4].url) {
      changedData.engineImageUrl = productImageListData[4].url;
    }
    if (prevData?.productsImage?.insideImageUrl !== productImageListData[5].url) {
      changedData.insideImageUrl = productImageListData[5].url;
    }
    if (prevData?.productsImage?.dashboardImageUrl !== productImageListData[6].url) {
      changedData.dashboardImageUrl = productImageListData[6].url;
    }
    if (prevData?.productsImage?.sheetImageUrl !== productImageListData[7].url) {
      changedData.sheetImageUrl = productImageListData[7].url;
    }

    const optionImages = productImageListData.slice(8, 15).filter((image) => image.url !== '');

    const prevOptionImages = prevData?.productsImage?.optionImageUrls || [];
    if (!arraysEqual(optionImages, prevOptionImages)) {
      changedData.optionImageUrls = optionImages.map((image) => image.url);
    }

    return changedData;
  };

  return (
    <>
      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="py-4 my-2 pb-24">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">차량 사진을 등록해주세요.</h3>
            <RoundBadge text="8/9" />
          </div>
          <div className="my-2">
            <div className="text-base py-2">
              <label className="text-base font-semibold mb-3 text-gray-8">차량등록증 업로드</label>
              <div className="grid grid-cols-3 gap-2">
                <FileUploader
                  truckNumber={productFormData?.truckNumber || ''}
                  imageType="CERTIFICATE_IMAGE"
                  fileData={certificateData}
                  setFileData={setCerticicateData}
                  icon="file"
                />
              </div>
            </div>
          </div>

          <div className="my-2">
            <div className="text-base py-2">
              <label className="text-base font-semibold text-gray-8">차량 대표 사진 업로드</label>
              <p className="text-gray-7 text-sm mb-2">TIP. 앞측면을 대표 사진으로 업로드하면 판매 확률이 높아져요.</p>
              <div className="grid grid-cols-3 gap-2">
                <div>
                  <label className="text-base sm280:text-[14px] font-medium mb-3 text-gray-7">
                    앞측면<span className="font-normal text-red">(필수)</span>
                  </label>
                  <FileUploader
                    truckNumber={productFormData?.truckNumber || ''}
                    imageType="FRONT_SIDE_IMAGE"
                    fileData={frontSideImageData}
                    setFileData={setFrontsideImageData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="my-2">
            <div className="text-base py-2">
              <div className="flex justify-between items-center">
                <label className="text-base font-semibold text-gray-8">차량 사진 업로드</label>
                <span className="text-gray-6 text-sm underline underline-offset-4" onClick={onClickUploadAll}>
                  한번에 불러오기
                </span>
                <input
                  id="upload-images-all"
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e) => onUpload(e)}
                  className="hidden"
                />
              </div>
              <p className="text-gray-7 text-sm mb-3">TIP. 차량 사진은 최대 15장까지 업로드가 가능합니다.</p>

              <div className="grid grid-cols-3 gap-2">
                {productImageListData?.map((item, index) => (
                  <div className="flex flex-col" key={item.id}>
                    <ProductImageComponent
                      item={item}
                      truckNumber={productFormData?.truckNumber || ''}
                      index={index}
                      productImageListData={productImageListData}
                      setProductImageListData={setProductImageListData}
                      isLoading={loadingList[index]}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={() => navigate(`/products/sales/detail-info/${id}`, { replace: true })}
          rightButtonText="다음(판매가격)"
          disabledRight={!frontSideImageData.url || !productImageListData[0].url || !productImageListData[1].url}
          onClickRightButton={handleClickNext}
        />
      </div>

      <BasicPopup
        isShow={isShowGuidePopup}
        title="삭제 대상 이미지"
        textContent={
          '<div class="leading-[20px]">텍스트 삽입 및 번호판을 블러 처리한 <br class="sm280:hidden"/>사진 업로드 시 무통보 삭제될 수 있습니다.<div>'
        }
        componentContent={
          <>
            <img
              src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/delete_standard_img.jpg"
              className="min-w-[184px]"
              alt="delete_standard_img"
            ></img>
            <div className="text-[16px] font-medium text-red pt-2">[삭제 대상 이미지 예시]</div>
          </>
        }
        textRightBtn="확인"
        onClickRightBtn={() => setIsShowGuidePopup(false)}
      />

      <BasicPopup
        isShow={isShowConfirmPopup}
        title="정말 나가시겠어요?"
        textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleClosePopup}
        textRightBtn="나가기"
        onClickRightBtn={handleClickExitBtn}
      />
    </>
  );
};

export default PhotoUploadForm;

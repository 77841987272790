import React from 'react';

interface RoundBadge {
  text: string;
}

function RoundBadge({ text }: RoundBadge) {
  return (
    <div className="bg-primary rounded-full w-16 h-9 text-gray-0 items-center flex text-center justify-center font-bold text-xl px-3">
      {text}
    </div>
  );
}

export default RoundBadge;

import React, { ReactNode } from 'react';

import { Fab } from '@mui/material';

interface FloatingIconButton {
  bgColor: string;
  icon: ReactNode;
  width: number;
  height: number;
  onClick: () => void;
}

function FloatingIconButton({ bgColor, icon, width, height, onClick }: FloatingIconButton) {
  return (
    <div className="sticky flex flex-col w-full mx-auto my-0 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px]">
      <div className="text-right p-4">
        <Fab
          sx={{
            backgroundColor: bgColor,
            width: width,
            height: height,
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: bgColor,
            },
          }}
          onClick={onClick}
        >
          {icon}
        </Fab>
      </div>
    </div>
  );
}

export default FloatingIconButton;

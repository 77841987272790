import React, { SVGProps } from 'react';

const SvgIncreasingTrendGraphGray4ColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width={6} height={18} x={2} y={17.018} fill="#E8E8E8" rx={1} />
    <rect width={6} height={22} x={10} y={13.018} fill="#E8E8E8" rx={1} />
    <rect width={6} height={20} x={18} y={15.018} fill="#E8E8E8" rx={1} />
    <rect width={6} height={24} x={26} y={11.018} fill="#E8E8E8" rx={1} />
    <path
      stroke="#DCDCDC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m3 14.018 10-5 8 3.5 10-6.5"
    />
    <circle cx={27.336} cy={26.018} r={6.5} fill="#fff" stroke="#DCDCDC" strokeWidth={3} />
    <path
      fill="#DCDCDC"
      d="M31.67 32.476a1.5 1.5 0 1 1 2.121-2.122l3.689 3.689a1.5 1.5 0 1 1-2.121 2.121l-3.689-3.688Z"
    />
    <path stroke="#DCDCDC" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m27.5 5.517 3.5.5-1 3.5" />
  </svg>
);
SvgIncreasingTrendGraphGray4ColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgIncreasingTrendGraphGray4ColorIcon;

import React from 'react';

import { colors } from '@/const/colors';
import { Box, Slider } from '@mui/material';

interface SliderComponentProps {
  name: string;
  value: number | number[];
  onChange: (e: Event, value: number | number[], key: string) => void;
  step: number;
  min: number;
  max: number;
}

const SliderComponent = ({ name, value, onChange, step, min, max }: SliderComponentProps) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '36px',
          paddingX: '12px',
          paddingY: '0px',
          '& .MuiSlider-root': { pointerEvents: 'none' },
          '& .MuiSlider-rail': { backgroundColor: colors.gray[3] },
          '& .MuiSlider-track': { color: colors.primary },
          '& .MuiSlider-thumb': { backgroundColor: colors.gray[0], pointerEvents: 'all' },
        }}
      >
        <Slider
          value={value}
          onChange={(event, value) => onChange(event, value, name)}
          valueLabelDisplay="auto"
          disableSwap
          step={step}
          min={min}
          max={max}
        />
      </Box>
    </>
  );
};

export default SliderComponent;

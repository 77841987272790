import React from 'react';
import { useNavigate } from 'react-router-dom';

import ProductFlicking from '../Common/ProductFlicking';
import { colors } from '@/const/colors';
import { SALES_TYPE_ASSURANCE } from '@/const/products';
import { Button } from '@mui/material';

const AssuranceProduts = ({ products }: ProductsProps) => {
  const navigate = useNavigate();

  const onClickMore = () => {
    navigate('/products', { state: { salesType: SALES_TYPE_ASSURANCE } });
  };

  return (
    <>
      <div className="flex flex-col pb-6 border-t-8 border-gray-1 w-full min-h-[572px] max-h-[612px]">
        <div className="p-6">
          <h2 className="text-lg font-semibold text-gray-700">직트럭 상품용</h2>
          <span className="text-base xxs:text-sm text-gray-6 pb-2 break-keep">
            직트럭에서 직접 검수를 거친 차량이에요.
          </span>
        </div>
        <ProductFlicking products={products}></ProductFlicking>
        <div className="p-3 pt-0">
          <Button
            className="w-full"
            onClick={() => onClickMore()}
            sx={{
              fontSize: '18px',
              color: colors.gray[8],
              bgcolor: colors.gray[0],
              borderRadius: '8px',
              border: `1px solid ${colors.gray[3]}`,
              paddingRight: '6px',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            더보기
          </Button>
        </div>
      </div>
    </>
  );
};

export default AssuranceProduts;

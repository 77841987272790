import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

import PhoneAuthAndTerms from '@/components/SignUp/PhoneAuthAndTerms';
import UserRegistrationForm from '@/components/SignUp/UserRegistrationForm';
import { signUpFormAtom } from '@/store/signUp';

const SignUp = () => {
  const [isFirst, setIsFirst] = useState(true);
  const [signUpFormData] = useAtom(signUpFormAtom);
  const setSignUpFormData = useSetAtom(signUpFormAtom);

  const [memberInfo, setMemberInfo] = useState<MemberInfo>({
    marketing: false,
    terms: false,
    personalInfo: false,
    name: '',
    password: '',
    phoneNumber: '',
    authNumber: '',
    isSentCode: false,
    isAuthenticateCode: false,
  });

  const updateMemberInfo = (data: MemberInfo) => {
    setSignUpFormData(data);
  };

  useEffect(() => {
    setMemberInfo(signUpFormData);
  }, [signUpFormData]);

  return (
    <>
      {isFirst ? (
        <PhoneAuthAndTerms
          memberInfo={memberInfo}
          updateMemberInfo={(data) => updateMemberInfo(data)}
          isFirst={isFirst}
          setIsFirst={setIsFirst}
        ></PhoneAuthAndTerms>
      ) : (
        <UserRegistrationForm
          memberInfo={memberInfo}
          updateMemberInfo={(data) => updateMemberInfo(data)}
          isFirst={isFirst}
          setIsFirst={setIsFirst}
        ></UserRegistrationForm>
      )}
    </>
  );
};

export default SignUp;

import React from 'react';
import { useMutation } from 'react-query';

import LoadingSpinner from '../Common/LoadingSpinner';
import { DocumentArrowUpSolidIcon } from '../Icon';
import { postFuelingHistoryReceipt } from '@/api/public';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface FuelingReceiptUploaderProps {
  driveVehicleInfoId: number;
  receiptImageUrl?: string;
  updateFuelingForm: (data: FuelingForm) => void;
}

const FuelingReceiptUploader = ({
  driveVehicleInfoId,
  receiptImageUrl,
  updateFuelingForm,
}: FuelingReceiptUploaderProps) => {
  const { showToast } = useToastContext();

  const postFuelingReceiptMutation = useMutation((file: File) => postFuelingHistoryReceipt(file), {
    onSuccess: (response) => {
      const data: FuelingForm = {
        driveVehicleInfoId: driveVehicleInfoId,
        receiptImageUrl: response.data.receiptImageUrl,
        unitPrice: response.data.unitPrice,
        amount: response.data.amount,
        price: response.data.price,
        refuelDay: response.data.refuelDay,
      };
      updateFuelingForm(data);
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const onChangeFuelingReceipt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      postFuelingReceiptMutation.mutate(file);
    }
  };

  return (
    <>
      <label htmlFor="fueling-receipt">
        <div
          className={`flex flex-col justify-center items-center w-full bg-gray-1 text-center text-gray-7 h-[180px] border border-gray-4 rounded-lg ${
            receiptImageUrl ? 'overflow-hidden' : ''
          }`}
        >
          {receiptImageUrl ? (
            <img src={receiptImageUrl} alt="영수증" className="w-full" />
          ) : (
            <div className="flex flex-col items-center justify-center p-3">
              <DocumentArrowUpSolidIcon color={colors.gray[5]} width={32} height={32}></DocumentArrowUpSolidIcon>
              <span className="text-base font-semibold text-gray-7 pt-3 break-keep">
                여기를 클릭해 영수증 이미지를 올려주세요.
              </span>
              <span className="text-sm text-gray-7 pt-[10px] leading-[16px]">
                영수증 이미지를 업로드하면
                <br />
                모든 항목이 자동 입력됩니다.
              </span>
            </div>
          )}
          <input
            id="fueling-receipt"
            accept="image/*"
            type="file"
            hidden
            className="w-full h-full"
            onChange={(e) => onChangeFuelingReceipt(e)}
          ></input>
        </div>
      </label>
      {postFuelingReceiptMutation.isLoading && (
        <LoadingSpinner text={'영수증 내역을 가져오고 있어요.'}></LoadingSpinner>
      )}
    </>
  );
};

export default FuelingReceiptUploader;

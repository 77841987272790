import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { patchProductsAfterPriceSearch } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import FloatingIconButton from '@/components/Common/Button/FloatingIconButton';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import MenuHeader from '@/components/Header/MenuHeader';
import { PhoneIcon } from '@/components/Icon';
import DirectGuide from '@/components/Products/Sale/DirectGuide';
import SpeedGuide from '@/components/Products/Sale/SpeedGuide';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { PRODUCTS_CAN_SALES_MAX_THREE } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED, SALESTYPE } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { isLengthOnlyLoadedType } from '@/utils/Products/productUtils';
import { phoneCall } from '@/utils/common';

type SalesTypeKey = keyof typeof SALESTYPE;

const MyCarSaleGuide = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToastContext();
  const [isShowErrorPopup, setIsShowErrorPopup] = useState(false);

  const [salesType, setSalesType] = useState<SalesTypeKey>();

  const handleClickSalesType = () => {
    if (!!location.state?.productId) {
      patchAfterPriceSearchMutation.mutate(String(location.state?.productId));
    } else {
      navigate('/products/sales', { state: { type: salesType }, replace: true });
    }
  };

  const onClickGoMyProductPage = () => {
    navigate('/products/my');
  };

  const patchAfterPriceSearchMutation = useMutation((request: string) => patchProductsAfterPriceSearch(request), {
    onSuccess: (response) => {
      if (isLengthOnlyLoadedType(response) || response?.loaded === '' || response?.loaded === null) {
        navigate(`/products/sales/price-trend/${response.id}`, { state: { type: salesType } });
      } else {
        navigate(`/products/sales/loaded/${response.id}`, { state: { type: salesType } });
      }
    },
    onError: (error: AxiosError) => {
      if (error.code === PRODUCTS_CAN_SALES_MAX_THREE) {
        showToast(error.message, 'error', 'bottom');
      } else if (error.code === 'ALREADY_EXIST_SALE_OR_PAUSE_PRODUCT') {
        setIsShowErrorPopup(true);
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  useEffect(() => {
    if (location.state?.type) {
      setSalesType(location.state?.type);
    }
  }, []);

  return (
    <>
      <DefaultHelmet page="sale" />
      <MenuHeader title={SALESTYPE[location.state?.type as SalesTypeKey]} />
      <div className="w-full pt-[60px] pb-20">
        {salesType === PRODUCT_TYPE_DIRECT ? <DirectGuide /> : <SpeedGuide />}

        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-[68px] left-0 right-0 h-14 max-w-[720px] min-w-[280px]">
          {salesType === PRODUCT_TYPE_SPEED && (
            <FloatingIconButton
              bgColor={colors.gray[0]}
              icon={<PhoneIcon color={colors.primary} />}
              width={52}
              height={52}
              onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
            />
          )}
        </div>
        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
          <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
          <BasicButton
            name={SALESTYPE[location.state?.type as SalesTypeKey]}
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight={'bold'}
            onClick={handleClickSalesType}
          />
        </div>
      </div>

      <ConfirmModal
        isOpen={isShowErrorPopup}
        content="이미 판매중이거나 판매중지된 차량입니다."
        onLeftButtonClick={() => setIsShowErrorPopup(false)}
        rightButtonLabel="내차관리로"
        onRightButtonClick={onClickGoMyProductPage}
      />
    </>
  );
};

export default MyCarSaleGuide;

import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { getOtherExpensesHistory } from '@/api/drive/getDrive';
import BasicButton from '@/components/Common/Button/BasicButton';
import FloatingButton from '@/components/Common/Button/FloatingButton';
import CheckBoxComponent from '@/components/Common/Input/CheckBox/CheckBoxComponent';
import CheckBoxList from '@/components/Common/Input/CheckBox/CheckBoxList';
import AlertModal from '@/components/Common/Modal/AlertModal';
import BottomSheetPopup from '@/components/Common/Popup/BottomSheetPopup';
import OtherExpenseHistoryForm from '@/components/Drive/OtherExpense/OtherExpenseHistoryForm';
import OtherExpenseItemBox from '@/components/Drive/OtherExpense/OtherExpenseItemBox';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon, PlusSmallIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { TODAY } from '@/const/date';
import { EXPENSE, EXPENSE_UNCLASSIFIED, INCOME, INCOME_UNCLASSIFIED } from '@/const/drive';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { categoriesAtom, fetchCategoriesAtom } from '@/store/category';
import { memberAtom } from '@/store/member';
import { otherExpenseHistorySearchParamsAtom } from '@/store/otherExpenseHistory';
import { asYYYYMM, formatNumber } from '@/utils/common';
import { isSameMonth } from '@/utils/dateUtils';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Input, ListItemText, MenuItem } from '@mui/material';

interface OptionData {
  income: EnumPresenter[];
  expense: EnumPresenter[];
}

const OtherExpenseList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToastContext();
  const [member] = useAtom(memberAtom);

  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [isOpenCategoryFilterPopup, setIsOpenCategoryFilterPopup] = useState(false);
  const [isOpenCategoryListPopup, setIsOpenCategoryListPopup] = useState(false);

  const [isShowAddFormPopup, setIsShowAddFormPopup] = useState(false);
  const [popupKey, setPopupKey] = useState<'income' | 'expense'>();
  const [isShowWarningPopup, setIsShowWarningPopup] = useState(false);

  const [categoryFilterText, setCategoryFilterText] = useState('');

  const [menuItems, setMenuItems] = useState([
    { key: 'expense', label: '지출', isChecked: true },
    { key: 'income', label: '수익', isChecked: true },
  ]);

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [categoryList] = useAtom(categoriesAtom);
  const [, fetchCategories] = useAtom(fetchCategoriesAtom);

  const [optionData, setOptionData] = useState({
    income: [{ code: 'ALL', desc: '전체' }],
    expense: [{ code: 'ALL', desc: '전체' }],
  });

  const [selectedOptionData, setSelectedOptionData] = useState({
    income: [{ code: 'ALL', desc: '전체' }],
    expense: [{ code: 'ALL', desc: '전체' }],
  });

  const [searchParams] = useAtom(otherExpenseHistorySearchParamsAtom);
  const setSearchParams = useSetAtom(otherExpenseHistorySearchParamsAtom);

  const [baseDate, setBaseDate] = useState(dayjs(location.state.selectedDate) || TODAY);

  const updateSelectedDate = (date: Date) => {
    setSelectedDate(date);
    setSearchParams((prev) => {
      return { ...prev, baseYearAndMonth: dayjs(date).format('YYYY-MM') || searchParams.baseYearAndMonth };
    });
  };

  useEffect(() => {
    handleClickFilterClear();
  }, []);

  useEffect(() => {
    if (member) {
      setSearchParams((prev) => {
        return {
          ...prev,
          driveVehicleInfoId: member.driveVehicleInfo.id,
          baseYearAndMonth: baseDate.format('YYYY-MM') || searchParams.baseYearAndMonth,
        };
      });

      setDriveVehicleInfoId(member.driveVehicleInfo.id);
      const params = new URLSearchParams({
        driveVehicleInfoId: String(member?.driveVehicleInfo?.id),
      });

      if (categoryList.length < 1) {
        fetchCategories(params);
      }
    }
  }, [member, baseDate]);

  useEffect(() => {
    const incomeData = categoryList.filter(
      (item) => item.type.code === INCOME_UNCLASSIFIED || item.type.code === INCOME,
    );
    const expenseData = categoryList.filter(
      (item) => item.type.code === EXPENSE_UNCLASSIFIED || item.type.code === EXPENSE,
    );

    setOptionData((prev) => ({
      income: [
        { code: 'ALL', desc: '전체' },
        ...incomeData.map((item) => ({ code: String(item.id), desc: item.name })),
      ],
      expense: [
        { code: 'ALL', desc: '전체' },
        ...expenseData.map((item) => ({ code: String(item.id), desc: item.name })),
      ],
    }));

    setSelectedOptionData((prev) => ({
      income: [
        { code: 'ALL', desc: '전체' },
        ...incomeData.map((item) => ({ code: String(item.id), desc: item.name })),
      ],
      expense: [
        { code: 'ALL', desc: '전체' },
        ...expenseData.map((item) => ({ code: String(item.id), desc: item.name })),
      ],
    }));
  }, [categoryList]);

  const onClickAddOtherExpense = () => {
    setIsShowAddFormPopup(true);
  };

  const getOtherExpensesHistoryList = async () => {
    try {
      if (searchParams.driveVehicleInfoId && searchParams?.baseYearAndMonth) {
        setBaseDate(dayjs(searchParams.baseYearAndMonth));

        const params = new URLSearchParams({
          driveVehicleInfoId: searchParams.driveVehicleInfoId.toString(),
          baseYearAndMonth: searchParams.baseYearAndMonth,
          otherExpensesCategoriesId: (searchParams.otherExpensesCategoriesId || []).join(','),
        });

        const response = await getOtherExpensesHistory(params);
        return response;
      }
    } catch (error) {
      throw new Error('Error');
    }
  };

  const memoizedSearchParams = useMemo(() => searchParams, [searchParams]);
  const {
    data: otherExpenseHistory,
    refetch,
    isSuccess,
  } = useQuery(['get-other-expense-history', memoizedSearchParams], () => getOtherExpensesHistoryList(), {
    onSuccess: () => {},
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    refetchOnWindowFocus: false,
    enabled: !!searchParams.driveVehicleInfoId && !!baseDate.format('YYYY-MM'),
  });

  const onClickPrevMonth = () => {
    setBaseDate(dayjs(baseDate).subtract(1, 'month'));

    setSearchParams((prev) => {
      return {
        ...prev,
        driveVehicleInfoId: member?.driveVehicleInfo.id,
        baseYearAndMonth: dayjs(baseDate).subtract(1, 'month').format('YYYY-MM'),
      };
    });
  };

  const onClickNextMonth = () => {
    if (!isSameMonth(TODAY, baseDate)) {
      setBaseDate(dayjs(baseDate).add(1, 'month'));

      setSearchParams((prev) => {
        return {
          ...prev,
          driveVehicleInfoId: member?.driveVehicleInfo.id,
          baseYearAndMonth: dayjs(baseDate).add(1, 'month').format('YYYY-MM'),
        };
      });
    }
  };

  const onClickCategoryItem = (categoryItem: EnumPresenter) => {
    setSelectedOptionData((prev) => {
      const currentItems = prev[popupKey as keyof OptionData] || [];
      const isSelected = currentItems.some((item) => item.code === categoryItem.code);

      if (categoryItem.code === 'ALL') {
        if (isSelected) {
          return {
            ...prev,
            [popupKey as keyof OptionData]: [],
          };
        } else {
          const allCategories = optionData[popupKey as keyof OptionData];
          return {
            ...prev,
            [popupKey as keyof OptionData]: allCategories,
          };
        }
      } else {
        if (isSelected) {
          const updatedItems = currentItems.filter((item) => item.code !== categoryItem.code);

          return {
            ...prev,
            [popupKey as keyof OptionData]: updatedItems.filter((item) => item.code !== 'ALL'),
          };
        }

        const updatedItems = [...currentItems, categoryItem];

        const allCategoriesCodes = optionData[popupKey as keyof OptionData].map((item) => item.code);

        const isAllSelected = allCategoriesCodes
          .filter((code) => code !== 'ALL')
          .every((code) => updatedItems.some((item) => item.code === code));

        if (isAllSelected) {
          return {
            ...prev,
            [popupKey as keyof OptionData]: [
              ...updatedItems.filter((item) => item.code !== 'ALL'),
              { code: 'ALL', desc: '전체' },
            ],
          };
        }

        return {
          ...prev,
          [popupKey as keyof OptionData]: updatedItems,
        };
      }
    });
  };

  const handleClickFilterClear = (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (e) {
      e.stopPropagation();
    }

    setCategoryFilterText('');
    setSearchParams((prev) => {
      return {
        ...prev,
        otherExpensesCategoriesId: [],
      };
    });

    const incomeData = categoryList.filter(
      (item) => item.type.code === INCOME_UNCLASSIFIED || item.type.code === INCOME,
    );
    const expenseData = categoryList.filter(
      (item) => item.type.code === EXPENSE_UNCLASSIFIED || item.type.code === EXPENSE,
    );

    setSelectedOptionData((prev) => ({
      income: [
        { code: 'ALL', desc: '전체' },
        ...incomeData.map((item) => ({ code: String(item.id), desc: item.name })),
      ],
      expense: [
        { code: 'ALL', desc: '전체' },
        ...expenseData.map((item) => ({ code: String(item.id), desc: item.name })),
      ],
    }));
  };

  const handleCloseCategoryFilterPopup = () => {
    setIsOpenCategoryFilterPopup(false);
    setIsFilterApplied(true);
  };

  const validatePopupClose = () => {
    const hasSelectedOptions = [...selectedOptionData.income, ...selectedOptionData.expense].length > 0;

    if (!hasSelectedOptions) {
      setIsShowWarningPopup(true);
    }

    return hasSelectedOptions;
  };

  useEffect(() => {
    if (isFilterApplied) {
      const isAllSelected =
        selectedOptionData.income.some((item) => item.code === 'ALL') &&
        selectedOptionData.expense.some((item) => item.code === 'ALL');

      if (isAllSelected) {
        setCategoryFilterText('');

        setSearchParams((prev) => {
          return {
            ...prev,
            otherExpensesCategoriesId: [],
          };
        });
      } else {
        let itemList: EnumPresenter[] = [];

        if (selectedOptionData.income.map((item) => item.desc).includes('전체')) {
          itemList = [
            { code: 'ALL', desc: '전체' },
            ...(selectedOptionData.expense.map((item) => item.desc).includes('전체')
              ? [{ code: 'ALL', desc: '전체' }]
              : selectedOptionData.expense),
          ];
        } else {
          itemList = [
            ...selectedOptionData.income,
            ...(selectedOptionData.expense.map((item) => item.desc).includes('전체')
              ? [{ code: 'ALL', desc: '전체' }]
              : selectedOptionData.expense),
          ];
        }

        setCategoryFilterText(() => {
          const filteredItems = itemList.filter((item) => item.code !== 'ALL');
          const cateogry = menuItems.filter((item) => item.isChecked);

          let filterText = '';
          if (filteredItems[0]?.desc) {
            if (itemList.length > 1) {
              filterText = `${filteredItems[0]?.desc} 외 ${itemList.length - 1}건`;
            } else {
              filterText = filteredItems[0]?.desc;
            }
          } else {
            filterText = `${cateogry[0]?.label} 전체`;
          }

          return filterText;
        });

        setSearchParams((prev) => {
          return {
            ...prev,
            otherExpensesCategoriesId: [
              ...selectedOptionData.expense.filter((item) => item.code !== 'ALL').map((item) => item.code),
              ...selectedOptionData.income.filter((item) => item.code !== 'ALL').map((item) => item.code),
            ],
          };
        });
      }
    }
  }, [isFilterApplied]);

  useEffect(() => {
    if (selectedOptionData.income.length > 0) {
      setMenuItems((prev) =>
        prev.map((menuItem) => (menuItem.key === 'income' ? { ...menuItem, isChecked: true } : menuItem)),
      );
    } else {
      setMenuItems((prev) =>
        prev.map((menuItem) => (menuItem.key === 'income' ? { ...menuItem, isChecked: false } : menuItem)),
      );
    }
    if (selectedOptionData.expense.length > 0) {
      setMenuItems((prev) =>
        prev.map((menuItem) => (menuItem.key === 'expense' ? { ...menuItem, isChecked: true } : menuItem)),
      );
    } else {
      setMenuItems((prev) =>
        prev.map((menuItem) => (menuItem.key === 'expense' ? { ...menuItem, isChecked: false } : menuItem)),
      );
    }
  }, [selectedOptionData]);

  const handleClickDetail = (item: OtherExpenseHistory) => {
    navigate('/drive/other-expense/form', { state: { date: item.baseDay, data: item } });
  };

  const handleClickCategoryFilterInput = () => {
    setIsOpenCategoryFilterPopup(true);
    setIsFilterApplied(false);
  };

  const renderFilterInput = () => (
    <Input
      className="text-sm w-[110px]"
      placeholder="카테고리 검색"
      endAdornment={
        categoryFilterText === '' ? (
          <ChevronDownIcon color={colors.gray[6]} width={20} height={20} />
        ) : (
          <XMarkIcon color={colors.gray[6]} width={20} height={20} onClick={handleClickFilterClear} />
        )
      }
      value={categoryFilterText}
      readOnly
      onClick={handleClickCategoryFilterInput}
      sx={{
        fontSize: '14px',
        color: colors.primary,
        '& .MuiInputBase-input::placeholder': {
          color: colors.gray[6],
        },
      }}
    />
  );

  const renderExpenseList = () =>
    otherExpenseHistory?.response?.map((item: OtherExpenseHistory, index: number) => (
      <div key={`${item.baseDay}-${index}`} onClick={() => handleClickDetail(item)}>
        <OtherExpenseItemBox data={item} />
      </div>
    ));

  const renderNoExpenses = () => (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <span className="mt-4 mb-6 text-lg text-center text-gray-7">등록된 기타내역이 없습니다.</span>
      <BasicButton
        name="기타내역 추가하기"
        bgColor={colors.primary}
        borderColor={colors.primary}
        textColor={colors.gray[0]}
        fontSize={16}
        width={230}
        height={48}
        fontWeight="bold"
        onClick={onClickAddOtherExpense}
      />
    </div>
  );

  const getCategoryData = (type: string) => {
    return categoryList
      .filter((item) => item.type.code === type || item.type.code === `${type}_UNCLASSIFIED`)
      .map((item) => ({ code: String(item.id), desc: item.name }));
  };

  const updateSelectedOptionData = (key: string, isChecked: boolean, data: any) => {
    if (key === 'income') {
      return {
        income: !isChecked ? [{ code: 'ALL', desc: '전체' }, ...data] : [],
        expense: selectedOptionData.expense,
      };
    } else if (key === 'expense') {
      return {
        income: selectedOptionData.income,
        expense: !isChecked ? [{ code: 'ALL', desc: '전체' }, ...data] : [],
      };
    }
    return selectedOptionData;
  };

  const handleClickCategoryItem = (item: any) => {
    const incomeData = getCategoryData(INCOME);
    const expenseData = getCategoryData(EXPENSE);

    setSelectedOptionData((prev) =>
      updateSelectedOptionData(item.key, item.isChecked, item.key === 'income' ? incomeData : expenseData),
    );

    setMenuItems((prev) =>
      prev.map((menuItem) => (menuItem.key === item.key ? { ...menuItem, isChecked: !item.isChecked } : menuItem)),
    );
  };

  return (
    <>
      <DefaultHelmet page="drive" />

      <MenuHeader title="기타내역" path="/drive"></MenuHeader>
      <div className="relative w-full pt-[60px]">
        <div className="px-4 pb-5">
          <div className="flex items-center justify-between p-4 rounded-lg sm280:flex-col-reverse sm280:items-start md-only:justify-around bg-blue-0">
            <div className="sm680:flex sm680:gap-[16px]">
              <div className="pb-1 font-semibold text-gray-8 sm280:mt-2">
                <span className="text-gray-7 font-medium text-[16px]">수익 </span>
                <span className="text-primary">{formatNumber(otherExpenseHistory?.totalIncome)}원</span>
              </div>
              <div className="pb-1 font-semibold text-gray-8 sm280:mt-2">
                <span className="text-gray-7 font-medium text-[16px]">지출 </span>
                <span className="text-gray-8">{formatNumber(otherExpenseHistory?.totalExpense)}원</span>
              </div>
            </div>

            <div className="border-l border-gray-3 h-[43px] ml-2 pl-2 sm280:hidden"></div>

            <div className="w-[120px] flex items-center justify-center">
              <span className="flex items-center pr-2" onClick={onClickPrevMonth}>
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: 16, color: colors.gray[8] }}></ArrowBackIosNewRoundedIcon>
              </span>
              <span className="text-lg font-semibold text-gray-8">{asYYYYMM(baseDate)}</span>
              <span className="flex items-center pl-2" onClick={onClickNextMonth}>
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: 16, color: isSameMonth(TODAY, baseDate) ? colors.gray[5] : colors.gray[8] }}
                />
              </span>
            </div>
          </div>
        </div>

        <section className="px-4 py-2 pb-24 border-t-8 border-gray-1" style={{ height: `calc(100vh - 168px)` }}>
          {renderFilterInput()}
          {isSuccess ? (
            otherExpenseHistory?.response?.length > 0 ? (
              <div className="flex flex-col gap-4 pt-9 pb-[64px]">
                {renderExpenseList()}
                <FloatingButton
                  bgColor={colors.primary}
                  textColor={colors.gray[0]}
                  icon={<PlusSmallIcon color={colors.gray[0]} />}
                  title="기타내역 추가"
                  minWidth={150}
                  minHeight={48}
                  onClick={onClickAddOtherExpense}
                />
              </div>
            ) : (
              <div className="w-full h-full px-4">{renderNoExpenses()}</div>
            )
          ) : null}
        </section>
      </div>

      <BottomSheetPopup
        isOpen={isShowAddFormPopup}
        onClose={() => setIsShowAddFormPopup(false)}
        title="기타내역 추가"
        isFullHeight
      >
        <OtherExpenseHistoryForm
          selectedDate={selectedDate}
          driveVehicleInfoId={driveVehicleInfoId || 0}
          data={undefined}
          onClosePopup={() => setIsShowAddFormPopup(false)}
          updateSelectedDate={updateSelectedDate}
          refetch={refetch}
        />
      </BottomSheetPopup>

      <BottomSheetPopup
        isOpen={isOpenCategoryFilterPopup}
        onClose={handleCloseCategoryFilterPopup}
        canClose={validatePopupClose}
        title="카테고리 필터"
      >
        <div className="p-4">
          {menuItems.map((item) => (
            <MenuItem
              key={item.key}
              value={item.key}
              className="items-center"
              sx={{
                padding: '0px',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => handleClickCategoryItem(item)}
              disableRipple
            >
              <CheckBoxComponent checked={item.isChecked} />
              <ListItemText
                primary={
                  <div className="flex items-center pl-[6px]">
                    <span className="flex-1 mr-4 text-base text-gray-9">{item.label}</span>
                    <Input
                      placeholder="카테고리 필터"
                      endAdornment={<ChevronDownIcon color={colors.gray[6]} width={20} height={20} />}
                      sx={{
                        fontSize: '16px',
                        borderColor: colors.gray[4],
                        width: '100%',
                        '&:focus, &:active': {
                          borderColor: colors.gray[4],
                        },
                        '&:before': {
                          borderColor: colors.gray[4],
                        },
                      }}
                      inputProps={{
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      value={
                        selectedOptionData[item.key as keyof OptionData]?.some((option) => option.code === 'ALL')
                          ? '전체'
                          : selectedOptionData[item.key as keyof OptionData]
                              ?.filter((option) => option.code !== 'ALL')
                              .map((option) => option.desc)
                              .join(', ') || ''
                      }
                      readOnly
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsFilterApplied(false);
                        setIsOpenCategoryListPopup(true);
                        setPopupKey(item.key as keyof OptionData);
                      }}
                    />
                  </div>
                }
              />
            </MenuItem>
          ))}

          <div className="mt-6 text-right">
            <span
              className="underline underline-offset-4 text-gray-7 text-[16px] cursor-pointer"
              onClick={() => handleClickFilterClear()}
            >
              필터 초기화
            </span>
          </div>
        </div>
      </BottomSheetPopup>

      <BottomSheetPopup
        isOpen={isOpenCategoryListPopup}
        onClose={() => {
          setIsOpenCategoryListPopup(false);
        }}
        canClose={validatePopupClose}
        title={`${popupKey === 'income' ? '수익' : '지출'} 카테고리 선택`}
        isBackdrop={false}
      >
        <div className="p-4">
          <CheckBoxList
            optionData={optionData[popupKey as keyof OptionData]}
            selectedData={selectedOptionData[popupKey as keyof OptionData]}
            onClickItem={(item) => onClickCategoryItem(item)}
          />
        </div>
      </BottomSheetPopup>

      <AlertModal
        isOpen={isShowWarningPopup}
        title="선택된 카테고리가 없습니다"
        content={
          <>
            1개 이상의 카테고리를 선택하면
            <br />
            필터 적용이 가능합니다.
          </>
        }
        onClose={() => {
          setIsShowWarningPopup(false);
        }}
      />
    </>
  );
};

export default OtherExpenseList;

import React, { SVGProps } from 'react';

const SvgHeadphoneColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 50 50" {...props}>
    <path
      fill="#3C5FBF"
      fillRule="evenodd"
      d="M44.768 36.54h.017V24.17h-.021c-.235-5.365-2.373-10.46-6.014-14.274-3.864-4.05-9.105-6.324-14.57-6.324S13.474 5.846 9.61 9.895c-3.641 3.814-5.779 8.91-6.014 14.274h-.022V36.54h.02c.204 2.023 1.908 3.63 3.98 3.63h6v-16H7.722c.232-4.328 1.975-8.398 4.869-11.43 3.107-3.256 7.28-5.047 11.589-5.047 4.308 0 8.481 1.79 11.59 5.047 2.893 3.032 4.636 7.102 4.868 11.43h-5.853v16h1.758v2.554h-6.355a3.092 3.092 0 0 0-2.913-2.057h-4.122a3.09 3.09 0 0 0 0 6.181h4.122c.047 0 .093 0 .14-.003h11.189a2.06 2.06 0 0 0 2.06-2.06v-4.616h.121a4 4 0 0 0 3.983-3.629Z"
      clipRule="evenodd"
    />
    <rect width={6} height={18} x={9} y={23} fill="#414141" rx={2} />
    <rect width={6} height={18} x={33} y={23} fill="#414141" rx={2} />
  </svg>
);
SvgHeadphoneColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgHeadphoneColorIcon;

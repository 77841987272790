import { useMemo } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

interface useSearchProductQueryProps {
  rowsPerPage: number;
  queryFn: (context: QueryFunctionContext<any>) => Promise<any>;
  searchParams: ProductsSearch;
}

const useSearchProductQuery = ({ rowsPerPage, queryFn, searchParams }: useSearchProductQueryProps) => {
  const queryKey = ['product-search', searchParams];
  const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery<any>(queryKey, queryFn, {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage?.data.length === 0 || lastPage?.data.length < rowsPerPage ? undefined : nextPage;
      },
      cacheTime: Infinity,
      staleTime: Infinity,
      keepPreviousData: true,
    });

  const products = useMemo(() => {
    const productsList = data?.pages.flatMap((page) => page?.data);
    return productsList;
  }, [data]);

  const totalCounts = useMemo(() => {
    const totalCount = data?.pages[0]?.totalElements;
    return totalCount;
  }, [data]);

  return {
    products,
    totalCounts,
    isLoading,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  };
};

export default useSearchProductQuery;

import React from 'react';

const SaleAfterCare = () => {
  return (
    <div className="min-w-[320px]">
      <div className="w-full flex justify-center items-center">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_sale_after_care_cover.png"
          alt="판매 후 주의사항 커버"
          className="w-full"
          loading="lazy"
        />
      </div>

      <div className="text-gray-700 border-b-4 border-gray-100 pt-4 pb-28 px-4">
        <h3 className="font-semibold text-xl py-4">판매 후 주의 사항을 확인하세요</h3>
        <p className="pb-8 leading-8">판매 완료 후 주의해야 할 사항은 아래와 같아요.</p>
        <ol className="pb-8 leading-8">
          <li>• 명의 이전이 완료된 등록증을 확인하세요</li>
          <li>• 자동차 보험 해지 확인과 선납한 금액에 대한 환급 요청</li>
          <li>• 선납 자동차세가 있으면 환급 요청</li>
        </ol>
        <p className="pb-8 leading-8 text-sm">
          ※ 1년치 선납 자동차세가 있는 경우
          <br />
          차량 판매 후 해당 구(군)청 세무과 담당자과 연락하여 명의가 변경된 자동차 등록증을 통해 내용 확인 후 선납
          자동차세를 일할 계산하여 환급 받을 수 있습니다.
        </p>
      </div>
    </div>
  );
};
export default SaleAfterCare;

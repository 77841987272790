import React, { SVGProps } from 'react';

const SvgSpeedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 67 32" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M15.116 11.467c.227-1.06-.466-1.69-1.892-1.69-1.484 0-2.516.65-2.825 1.541-.333.966.596 1.412 1.722 1.672l1.173.297c2.253.52 3.69 1.69 2.959 3.938-.803 2.47-3.34 4.03-6.778 4.03-3.418 0-5.117-1.504-4.26-4.383h2.852c-.343 1.356.566 2.043 2.129 2.043 1.543 0 2.762-.687 3.088-1.69.296-.91-.444-1.337-1.866-1.709l-1.429-.353c-2.201-.557-3.298-1.69-2.658-3.66.796-2.451 3.587-4.067 6.673-4.067 3.125 0 4.658 1.653 3.925 4.03h-2.813Zm-.488 13.392 4.508-13.875h2.852l-.55 1.69h.118c.656-.817 1.841-1.82 3.619-1.82 2.343 0 3.735 1.727 2.613 5.182-1.093 3.362-3.565 5.201-5.987 5.201-1.738 0-2.31-.947-2.448-1.783h-.078l-1.756 5.405h-2.891Zm5.705-8.841c-.592 1.82-.164 3.027 1.262 3.027 1.485 0 2.67-1.244 3.25-3.027.573-1.765.184-2.973-1.3-2.973-1.446 0-2.62 1.152-3.212 2.973Zm11.432 5.256c-3.242 0-4.582-2.006-3.544-5.2 1.014-3.121 3.708-5.22 6.813-5.22 2.793 0 4.437 1.671 3.32 5.108l-.253.78h-7.246c-.495 1.523.155 2.47 1.639 2.47.977 0 1.812-.408 2.305-1.021h2.754c-1.014 1.857-3.17 3.083-5.788 3.083Zm-.348-6.26h4.511c.399-1.225-.216-2.098-1.505-2.098-1.328 0-2.58.938-3.006 2.098Zm10.833 6.26c-3.242 0-4.583-2.006-3.545-5.2 1.014-3.121 3.708-5.22 6.814-5.22 2.793 0 4.437 1.671 3.32 5.108l-.253.78H41.34c-.495 1.523.154 2.47 1.638 2.47.977 0 1.813-.408 2.305-1.021h2.754c-1.014 1.857-3.17 3.083-5.787 3.083Zm-.35-6.26h4.513c.398-1.225-.217-2.098-1.506-2.098-1.328 0-2.58.938-3.006 2.098Zm9.948 6.223c-2.422 0-3.72-1.839-2.627-5.2 1.123-3.456 3.657-5.183 6-5.183 1.778 0 2.292 1.003 2.417 1.82h.098l1.641-5.052h2.89L57.899 21.07h-2.851l.525-1.616h-.137c-.682.836-1.869 1.783-3.587 1.783Zm1.61-2.192c1.446 0 2.658-1.207 3.25-3.027.591-1.82.145-2.973-1.3-2.973-1.465 0-2.639 1.208-3.212 2.973-.58 1.783-.202 3.027 1.262 3.027Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="m65.39 20.85-7.5.216v-.433l7.5.216ZM65.972 19.089l-7.5.216v-.433l7.5.217ZM66.5 17.338l-7.5.216v-.433l7.5.217ZM25.047 8.968l-7.5.216v-.433l7.5.217ZM22.502 7.717l-7.5.216V7.5l7.5.217ZM25.002 24.642l-7.5.216v-.433l7.5.217ZM25.445 23.216l-7.5.217V23l7.5.216Z"
    />
  </svg>
);
SvgSpeedIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSpeedIcon;

import { AxiosError } from 'axios';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { patchProductsInfo } from '@/api/products/updateProducts';
import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import SalesTypeSelectButton from '@/components/Common/Button/SalesTypeSelectButton';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import BottomSheetPopup from '@/components/Common/Popup/BottomSheetPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { PRODUCTS_CAN_SALES_MAX_THREE } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED, SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import useFetchProductData from '@/hooks/useFetchProductData';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { productsFormAtom, useResetProductsForm } from '@/store/products';
import { getFormatDateOnlyNum } from '@/utils/common';

type SalesTypeKey = keyof typeof SALESTYPE;

const PriceTrendResultPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showToast } = useToastContext();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const checkForChanges = () => {
    return false;
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    () => {},
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  const [isShowSelectPopup, setIsShowSelectPopup] = useState(false);

  const productSearchParams = useFetchProductData(id, true);

  useEffect(() => {
    if (productFormData.type?.code === '') {
      setIsShowSelectPopup(true);
    }
  }, [productFormData.type?.code]);

  const handleClickAdditionalInfo = () => {
    navigate(`/products/sales/additional-info/${id}`, { replace: true });
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/axis/${id}`, { replace: true });
  };

  const handleClickSaleHelp = () => {
    navigate('/one-stop-service');
  };

  const updateProductsInfoMutation = useMutation(
    (request: { productId: number; type: string }) => patchProductsInfo(request),
    {
      onSuccess: (response) => {
        setProductFormData(response.data);
      },
      onError: (error: AxiosError) => {
        if (error.code === PRODUCTS_CAN_SALES_MAX_THREE) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickSalesType = (type: string) => {
    setIsShowSelectPopup(false);
    updateProductsInfoMutation.mutate({ productId: Number(id), type });
  };

  return (
    <>
      <DefaultHelmet page="sale" />

      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full p-4 pb-24 pt-[60px]">
        <div className="pt-6 pb-24">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold break-keep">차량 시세를 확인해주세요.</h3>
            <RoundBadge text="5/9" />
          </div>
          <div className="p-4 mt-6 rounded-lg bg-blue-0">
            <p className="mb-2 text-lg font-bold text-gray-8">
              {getFormatDateOnlyNum(productFormData?.firstRegistrationDate, ['year'])}년{' '}
              {productFormData?.manufacturerCategories?.name} {productFormData?.model?.name} {productFormData?.tons}톤{' '}
              {productFormData?.axis?.code === 'NONE' ? '' : productFormData?.axis?.desc}{' '}
              {productFormData?.loaded?.desc}
            </p>
            <p className="text-gray-8 text-[14px] leading-[17px]">해당 차종의 평균 시세 정보를 불러왔어요.</p>
          </div>
          <div className="mt-3">
            <CarPriceTrendInfo
              priceSearchParams={productSearchParams}
              showPriceComparison={false}
              isShowStickyBanner={true}
              stickBannerPosition="bottom-[64px]"
              apiType="private"
            />
            <SimilarProductList similarSearchParams={productSearchParams} />
          </div>

          <DualFooterButton
            leftButtonText="이전"
            onClickLeftButton={handleClickPrev}
            rightButtonText="추가 정보 입력하기"
            onClickRightButton={handleClickAdditionalInfo}
          />
        </div>
      </div>
      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />

      <BottomSheetPopup
        isOpen={isShowSelectPopup}
        onClose={() => setIsShowSelectPopup(false)}
        title="판매 방식 선택"
        isXMarkVisible={false}
      >
        <SalesTypeSelectButton
          onClickLeft={() => handleClickSalesType(PRODUCT_TYPE_DIRECT)}
          onClickRight={() => handleClickSalesType(PRODUCT_TYPE_SPEED)}
        ></SalesTypeSelectButton>
        <div
          className="my-[30px] text-center font-medium text-[14px] text-gray-7 underline underline-offset-2"
          onClick={handleClickSaleHelp}
        >
          차량 판매에 어려움이 있으신가요?
        </div>
      </BottomSheetPopup>
    </>
  );
};

export default PriceTrendResultPage;

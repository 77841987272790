import React, { forwardRef } from 'react';

import '@/css/inputStyle.css';

interface SimpleInputProps {
  name: string;
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  error?: boolean;
  errorMsg?: string;
  borderRadius?: number;
  maxHeight?: number;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  type?: string;
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  enterKeyHint?: 'search' | 'next' | 'enter' | 'done' | 'go' | 'previous' | 'send' | undefined;
  maxLength?: number;
}

const SimpleInput = forwardRef<HTMLInputElement, SimpleInputProps>(
  (
    {
      name,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
      fontSize = 16,
      width,
      height,
      readOnly = false,
      type = 'text',
      inputMode = 'text',
      enterKeyHint = 'next',
      maxLength,
    },
    ref,
  ) => (
    <>
      <input
        className="simple-input"
        ref={ref}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        type={type}
        style={{
          fontSize: `${fontSize}px`,
          width: '100%',
        }}
        inputMode={inputMode}
        enterKeyHint={enterKeyHint}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
      ></input>
    </>
  ),
);
export default SimpleInput;

import React from 'react';

import { colors } from '@/const/colors';
import Skeleton from '@mui/material/Skeleton';

const MyProductListSkeleton = () => {
  const renderSkeletonItem = (index: number) => (
    <li className={`flex flex-col gap-4 px-4 py-6 ${index === 0 ? '' : 'border-t'} border-gray-3`}>
      <div className="flex gap-3">
        <Skeleton variant="rounded" width={100} height={100} sx={{ bgcolor: colors.gray[2] }} />
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex gap-[6px]">
              <Skeleton variant="rounded" width={65} height={24} sx={{ bgcolor: colors.gray[2] }} />
              <div className="xxs:hidden">
                <Skeleton variant="rounded" width={65} height={24} sx={{ bgcolor: colors.gray[2] }} />
              </div>
            </div>
          </div>
          <Skeleton variant="rounded" width={85} animation="wave" height={19} sx={{ bgcolor: colors.gray[2] }} />
          <Skeleton variant="rounded" width={77} animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
        </div>
      </div>
    </li>
  );

  return (
    <>
      <ul className="px-4">
        {Array.from({ length: 6 }).map((_, index) => (
          <React.Fragment key={index}>{renderSkeletonItem(index)}</React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default MyProductListSkeleton;

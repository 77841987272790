import React, { SVGProps } from 'react';

const SvgTruckPreventionColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width={12.952} height={13.714} x={9.668} y={12.432} fill="#D1DDFF" rx={3} />
    <path
      fill="#3C5FBF"
      d="M9.668 23.099h21.333v.047a3 3 0 0 1-3 3H12.668a3 3 0 0 1-3-3V23.1ZM22.617 14.717h2.317a4 4 0 0 1 3.073 1.44l.705.846 1.323 1.543a4 4 0 0 1 .963 2.604v1.949h-8.38v-8.382Z"
    />
    <circle cx={14.239} cy={25.384} r={1.548} fill="#F5F5F5" stroke="#414141" strokeWidth={3} />
    <circle cx={26.43} cy={25.384} r={1.548} fill="#F5F5F5" stroke="#414141" strokeWidth={3} />
    <path
      fill="#D1DDFF"
      fillRule="evenodd"
      d="M27.286 15.48a4 4 0 0 1 .725.677l.705.846 1.323 1.544c.166.194.313.402.438.622a.996.996 0 0 1-.476.12h-4.095a1 1 0 0 1-1-1v-1.81a1 1 0 0 1 1-1h1.38Z"
      clipRule="evenodd"
    />
    <circle cx={20} cy={19.932} r={14.5} stroke="#F5222D" strokeWidth={3} />
    <path
      fill="#F5222D"
      fillRule="evenodd"
      d="m8.629 29.06 20.5-20.5 2.121 2.122-20.5 20.5-2.121-2.121Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgTruckPreventionColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgTruckPreventionColorIcon;

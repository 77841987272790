import React from 'react';

const SpeedGuide = () => {
  return (
    <>
      <main className="flex flex-col w-full">
        <div className="bg-gray-1 py-[30px] px-6">
          <div className="text-center">
            <h1 className="hidden font-medium text-[16px] leading-[20px] text-gray-8 mb-2">직트럭 판매</h1>
            <p className="text-[18px] leading-[22px] text-gray-8 break-keep font-semibold">
              직트럭 전문가를 통해
              <br />
              <span className="leading-[26px] font-bold">안전하고 빠르게 판매하세요.</span>
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center pt-[46px]">
          <p className="font-bold text-[20px] leading-[24px] text-gray-8 text-center mb-[30px]">
            차량을 급하게 <br className="sm280:block hidden" />
            처분해야 하나요?
          </p>
          <div className="flex flex-col gap-4 font-semibold">
            <div className="flex items-center">
              <div className="flex justify-center w-6 h-6 bg-primary rounded-full text-gray-0 font-bold mr-2">1</div>
              <div className="flex flex-col">
                <span>신차 출고 후</span>
                <span className="text-blue-8">내 차량을 빠르게 판매해야할 때</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex justify-center w-6 h-6 bg-primary rounded-full text-gray-0 font-bold mr-2">2</div>
              <div className="flex flex-col">
                <span>직거래 판매가</span>
                <span className="text-blue-8">원활하게 이루어지지 않을 때</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex justify-center w-6 h-6 bg-primary rounded-full text-gray-0 font-bold mr-2">3</div>
              <div className="flex flex-col">
                <span>별도 사정으로</span>
                <span className="text-blue-8">급하게 차량을 처분해야 할 때</span>
              </div>
            </div>
          </div>
          <div className="flex justify-center sm360:flex-wrap sm280:flex-col items-center gap-2 pt-6 pb-2">
            <div className="flex justify-center items-center px-3 py-[10px] bg-gray-2 rounded-[20px] font-semibold text-[11px]">
              #기다림 NO! 당일 판매 가능
            </div>
            <div className="flex justify-center items-center px-3 py-[10px] bg-gray-2 rounded-[20px] font-semibold text-[11px]">
              #부당 감가없이 정확한 견적
            </div>
            <div className="flex justify-center items-center px-3 py-[10px] bg-gray-2 rounded-[20px] font-semibold text-[11px]">
              #전국 어디든 직트럭에서 직접 방문
            </div>
          </div>
          <div className="text-gray-7 font-medium text-[14px] pt-12 pb-5">
            차량 등록 후 담당자가 개별 연락을 드립니다.
          </div>
        </div>
      </main>
    </>
  );
};

export default SpeedGuide;

import React, { SVGProps } from 'react';

const SvgChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke={props.color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.5 8.25-7.5 7.5-7.5-7.5"
    />
  </svg>
);
SvgChevronDownIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgChevronDownIcon;

import React, { SVGProps } from 'react';

const SvgQuestionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M3.75 12a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0ZM12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Z"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || 'currentColor'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M10 9.5c0-.5.5-1.5 2-1.5.47 0 1.27.198 1.702.874.418.654.144 1.54-.493 1.984-.327.229-.672.42-.95.748-.2.237-.259.556-.259.866V14"
    />
    <path fill={props.color || 'currentColor'} d="M12.75 16.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
  </svg>
);
SvgQuestionsIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgQuestionsIcon;

import React, { SVGProps } from 'react';

const SvgHandShakeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M30.666 8.033v8.907s-1.907.373-2.3.427c-.393.053-1.62.446-2.513-.414-1.374-1.333-6.26-6.173-6.26-6.173a1.854 1.854 0 0 0-2.194-.113c-1.24.666-3.08 1.613-3.846 2a1.772 1.772 0 0 1-2.667-1.387A1.807 1.807 0 0 1 12 9.687c1.754-1.06 5.447-3.134 6.987-3.954.933-.5 1.607-.54 2.893.54 1.58 1.334 2.987 2.547 2.987 2.547a1.467 1.467 0 0 0 1.187.227c1.793-.38 4.613-1.014 4.613-1.014Zm-19.44 15.094a1.559 1.559 0 0 0-1.893-2.154 1.633 1.633 0 0 0-.34-1.586 1.647 1.647 0 0 0-1.574-.394 1.587 1.587 0 0 0-.34-1.58 1.774 1.774 0 0 0-2.48.06c-.726.7-1.186 1.96-.54 2.7.647.74 1.387.294 1.954.16-.154.567-.627 1.087-.047 1.82.58.734 1.387.294 1.953.167-.153.56-.606 1.133-.046 1.813.56.68 1.486.32 2.093.134-.233.6-.74 1.253-.087 2 .654.746 2.14.393 2.867-.3a1.74 1.74 0 0 0 .16-2.46 1.646 1.646 0 0 0-1.68-.38Zm13.333-4.634-5.633-5.64a1.847 1.847 0 0 0-2-.36c-.787.347-1.8.82-2.567 1.18a4.16 4.16 0 0 1-1.733.574A2.933 2.933 0 0 1 11.08 8.82c1.174-.813 3.874-2.247 3.874-2.247a3.408 3.408 0 0 0-2.627-1.04c-1.8 0-5.56 2.46-5.56 2.46a2.92 2.92 0 0 1-2.587.067L1.246 7.04v10.193s.833.24 1.587.547a3.46 3.46 0 0 1 .826-1.333 3.253 3.253 0 0 1 4.454 0c.337.35.578.782.7 1.253a2.8 2.8 0 0 1 1.906 2c.459.137.875.39 1.207.733.363.375.619.84.74 1.347.476.138.908.398 1.253.753a2.946 2.946 0 0 1 .78 2.567s.42.473.667.733A1.303 1.303 0 1 0 17.22 24s-1.813-1.9-1.673-2.047C15.686 21.807 18 24.2 18 24.247a1.294 1.294 0 1 0 1.827-1.834l-.167-.16s-2.273-2-2.1-2.193c.174-.193 2.994 2.46 3 2.46a1.294 1.294 0 0 0 1.774-.04 1.273 1.273 0 0 0 .066-1.727s-2.146-2.266-2-2.433c.147-.167 2.287 2 2.287 2a1.294 1.294 0 1 0 1.833-1.827h.04Z"
    />
  </svg>
);
SvgHandShakeIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgHandShakeIcon;

import React, { SVGProps } from 'react';

const SvgJobBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M14.668 9.5h8a.5.5 0 0 1 .5.5v3.5h-9V10a.5.5 0 0 1 .5-.5Zm-3 4V10a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v3.5h3a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3h-20a3 3 0 0 1-3-3v-11a3 3 0 0 1 3-3h3Zm14 2.5h-17a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h20a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-3Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgJobBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgJobBoldIcon;

import React from 'react';

import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

export interface IconInfoButton {
  onClick: () => void;
  icon: React.ReactNode;
  title: string;
  description: string;
  bgColor: string;
}

const IconInfoButton = ({ onClick, icon, title, description, bgColor }: IconInfoButton) => (
  <section className="flex justify-between items-center py-6 cursor-pointer" onClick={onClick}>
    <div className="flex gap-[10px]">
      <div className={`flex justify-center items-center ${bgColor} rounded-[12px] w-10 h-10`}>{icon}</div>
      <div className="flex flex-col">
        <div className="text-gray-8 font-semibold">{title}</div>
        <div className="text-gray-7 text-[12px]">{description}</div>
      </div>
    </div>
    <ChevronRightIcon color={colors.gray[5]} />
  </section>
);

export default IconInfoButton;

import React, { useEffect, useState } from 'react';

import LoadingComponent from './LoadingComponent';
import apiManager from '@/api/AxiosInstance';
import { DocumentArrowUpSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { useToastContext } from '@/contexts/Common/ToastContext';

type UploaderComponentProps = {
  icon: string;
  name: string;
  desc?: string;
  code: string;
  carNumber?: string;
  files: FileUploader | undefined;
  setFiles: React.Dispatch<React.SetStateAction<FileUploader | undefined>>;
};

const UploadButton = ({ id, icon, onUpload, src, isUploaded }: any) => {
  const acceptValues: { [key: string]: string } = {
    file: 'image/*, application/pdf',
    photo: 'image/*',
    video: 'video/*',
  };

  const acceptValue = acceptValues[icon] || '';

  return (
    <>
      {!src && !isUploaded && (
        <span>{icon === 'file' && <DocumentArrowUpSolidIcon color={colors.gray[5]} width={32} height={32} />}</span>
      )}
      <input id={id} accept={acceptValue} type="file" onChange={onUpload} className="hidden" />
    </>
  );
};

const UploaderComponent = ({ icon, name, desc, code, carNumber, files, setFiles }: UploaderComponentProps) => {
  const [src, setSrc] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (code === 'CERTIFICATE_IMAGE') {
      setSrc(files?.certificationImage ? String(files?.certificationImage) : '');
      const parts = Array.isArray(files?.certificationImage)
        ? files?.certificationImage
        : files?.certificationImage?.split('/');
      if (parts && parts.length > 0) {
        setFileName(parts[parts.length - 1]);
      } else {
        setFileName('');
      }
    } else {
      setSrc(files?.licenseImage ? String(files?.licenseImage) : '');
      const parts = Array.isArray(files?.licenseImage) ? files?.licenseImage : files?.licenseImage?.split('/');
      if (parts && parts.length > 0) {
        setFileName(parts[parts.length - 1]);
      } else {
        setFileName('');
      }
    }
  }, [files]);

  const onUploadClick = () => {
    const inputElement = document.getElementById(`upload-${name}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const uploadLicenseImage = async (file: File) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('carNumber', carNumber ? carNumber : '');
    formData.append('licenseImageType', code);

    reader.onload = () => {
      setSrc(reader.result as string);
      setFileName(file.name);
    };

    reader.readAsDataURL(file);
    const response = await apiManager.post('/api/v1/license/upload', formData);

    if (response.data) {
      setFiles({ ...files, [name]: response.data.url.toString() } as FileUploader);
    }
  };

  const onClickDelete = (e: React.MouseEvent) => {
    setSrc(undefined);
    setFileName('');

    setIsUploaded(false);
    e.stopPropagation();

    setFiles({ ...files, [name]: '' } as FileUploader);
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploaded(true);
    if (!e.target.files) return;

    const file = e.target.files[0];
    uploadLicenseImage(file);
  };

  const renderContent = () => {
    if (src) {
      if (icon === 'file') {
        return (
          <>
            <span>{fileName}</span>
            {loading ? (
              <LoadingComponent></LoadingComponent>
            ) : (
              <>
                <img
                  src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/clear.png"
                  alt="clear"
                  className="absolute top-1 right-1"
                  onClick={(e) => onClickDelete(e)}
                />
                <UploadButton id={`upload-${name}`} icon={icon} onUpload={onUpload} src={src} isUploaded={isUploaded} />
              </>
            )}
          </>
        );
      }
    } else {
      return (
        <>
          {loading ? (
            <LoadingComponent></LoadingComponent>
          ) : (
            <>
              <span>
                {fileName}{' '}
                <UploadButton id={`upload-${name}`} icon={icon} onUpload={onUpload} src={src} isUploaded={isUploaded} />
              </span>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <div>
        <p className="mb-1 text-sm text-gray-6">{desc && <span className="text-red">{desc}</span>}</p>
        <div
          className={`relative flex flex-col items-center justify-center h-20 rounded-lg border text-sm min-w-[80px] overflow-hidden whitespace-no-wrap overflow-ellipsis bg-gray-1 border-gray-3 text-gray-6 ${
            src && !isUploaded ? '' : 'cursor-pointer'
          }`}
          onClick={() => onUploadClick()}
        >
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default UploaderComponent;

import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { MIN_LENGTH_ERROR_MESSAGE } from '@/const/errorMessage';
import { SALESTYPE } from '@/const/products';
import { positiveFloatRegex } from '@/const/regex';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { PRODUCT_SALE_URL } from '@/const/url';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom, useResetProductsForm } from '@/store/products';
import { isAllLoadedInfoAvailable, shouldShowOnlyLength } from '@/utils/Products/productUtils';
import { formatValueToDecimalPlaces, updateSearchParams } from '@/utils/common';
import { validateLoadedInnerLength } from '@/utils/validation';

type SalesTypeKey = keyof typeof SALESTYPE;

const LoadedForm = () => {
  const { productEnum } = useProductEnumContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);
  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);

  const [isOpenOptionModal, setIsOpenOptionModal] = useState(false);
  const [optionData, setOptionData] = useState<OptionType[]>([]);

  const loadedLengthInputRef = useRef<HTMLInputElement>(null);
  const loadedAreaInputRef = useRef<HTMLInputElement>(null);
  const loadedHeightInputRef = useRef<HTMLInputElement>(null);

  const [loadedInnerLengthError, setLoadedInnerLengthError] = useState<boolean>(false);
  const [loadedInnerLengthErrorMsg, setLoadedInnerLengthErrorMsg] = useState('');
  const [loadedInnerAreaError, setLoadedInnerAreaError] = useState<boolean>(false);
  const [loadedInnerAreaErrorMsg, setLoadedInnerAreaErrorMsg] = useState('');
  const [loadedInnerHeightError, setLoadedInnerHeightError] = useState<boolean>(false);
  const [loadedInnerHeightErrorMsg, setLoadedInnerHeightErrorMsg] = useState('');

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  useBlockNavigation(setIsShowExitConfirmPopup);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({
    url: PRODUCT_SALE_URL,
  });

  const checkForChanges = () => {
    return (
      prevData?.loaded?.code !== productFormData.loaded?.code ||
      prevData?.loadedInnerLength !== productFormData.loadedInnerLength ||
      prevData?.loadedInnerArea !== productFormData.loadedInnerArea ||
      prevData?.loadedInnerHeight !== productFormData.loadedInnerHeight
    );
  };

  const handleClickExitBtn = () => {
    setIsShowExitConfirmPopup(false);

    const hasChanges = checkForChanges();

    if (hasChanges) {
      const request = {
        id: Number(productFormData.id),
        loaded: productFormData.loaded?.code,
        loadedInnerLength: Number(productFormData.loadedInnerLength),
        loadedInnerArea: Number(productFormData.loadedInnerArea),
        loadedInnerHeight: Number(productFormData.loadedInnerHeight),
      };
      updateProductTemporarySaveMutation.mutate(request);
    } else {
      navigate(PRODUCT_SALE_URL, { replace: true });
      resetProductsForm();
    }
  };

  const handleCloseExitPopup = () => {
    setIsShowExitConfirmPopup(false);
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/axis/${id}`,
  });

  const handleClickNext = () => {
    const hasChanges = checkForChanges();

    if (hasChanges) {
      const request = {
        id: Number(productFormData.id),
        loaded: productFormData.loaded?.code,
        loadedInnerLength: Number(productFormData.loadedInnerLength),
        loadedInnerArea: Number(productFormData.loadedInnerArea),
        loadedInnerHeight: Number(productFormData.loadedInnerHeight),
      };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/axis/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/tons/${id}`, { replace: true });
  };

  const handleCloseOptionModal = () => {
    setIsOpenOptionModal(false);
  };

  const handleClickSelectBox = (key: string) => {
    if (!productEnum) return;
    setOptionData(productEnum[key]);

    setIsOpenOptionModal(true);
  };

  const handleClickOption = (item: OptionType, key: string) => {
    setProductFormData({ ...productFormData, [key]: item } as ProductDetailResponse);
    setTimeout(() => {
      setIsOpenOptionModal(false);
      if (loadedLengthInputRef && loadedLengthInputRef.current && !productFormData?.loadedInnerLength) {
        loadedLengthInputRef.current.focus();
      }
    }, 200);
  };

  const handleChangeLoadedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const decimalPlaces = 1;
    const formattedValue = formatValueToDecimalPlaces(value, decimalPlaces);
    const isInvalidInput = formattedValue.startsWith('0') && formattedValue.length > 1;

    if (isInvalidInput) {
      setProductFormData({ ...productFormData, [name]: '0' } as ProductDetailResponse);
      return;
    }

    if (name === 'loadedInnerLength' && productFormData) {
      const { hasError, errorMsg } = validateLoadedInnerLength(
        Number(formattedValue),
        Number(productFormData.tons),
        productFormData.manufacturerCategories.code,
        productFormData.model.id,
      );
      setLoadedInnerLengthError(hasError);
      setLoadedInnerLengthErrorMsg(errorMsg);
    } else if (name === 'loadedInnerArea' || name === 'loadedInnerHeight') {
      const isBelowMinimum = formattedValue && Number(formattedValue) < 1;
      setLoadedInnerAreaError(name === 'loadedInnerArea' && (!formattedValue || !!isBelowMinimum));
      setLoadedInnerAreaErrorMsg(name === 'loadedInnerArea' && isBelowMinimum ? MIN_LENGTH_ERROR_MESSAGE : '');
      setLoadedInnerHeightError(name === 'loadedInnerHeight' && (!formattedValue || !!isBelowMinimum));
      setLoadedInnerHeightErrorMsg(name === 'loadedInnerHeight' && isBelowMinimum ? MIN_LENGTH_ERROR_MESSAGE : '');
    }

    if (positiveFloatRegex.test(formattedValue.trim()) || formattedValue.trim() === '') {
      const str = formattedValue.trim() === '' ? null : formattedValue.trim();
      setProductFormData({ ...productFormData, [name]: str } as ProductDetailResponse);
    }
  };

  const handleBlurInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);

    if (valueNum >= 100) {
      const { updatedParams, error, errorMsg } = updateSearchParams(productFormData, key, valueNum);
      setProductFormData(updatedParams);
      switch (key) {
        case 'loadedInnerLength':
          setLoadedInnerLengthError(error);
          setLoadedInnerLengthErrorMsg(errorMsg);
          break;
        case 'loadedInnerArea':
          setLoadedInnerAreaError(error);
          setLoadedInnerAreaErrorMsg(errorMsg);
          break;
        case 'loadedInnerHeight':
          setLoadedInnerHeightError(error);
          setLoadedInnerHeightErrorMsg(errorMsg);
          break;
      }
    }
  };

  useEffect(() => {
    if (productFormData?.loadedInnerLength) {
      const { hasError, errorMsg } = validateLoadedInnerLength(
        productFormData.loadedInnerLength,
        Number(productFormData.tons),
        productFormData.manufacturerCategories.code,
        productFormData.model.id,
      );
      setLoadedInnerLengthError(hasError);
      setLoadedInnerLengthErrorMsg(errorMsg);
    }
  }, [productFormData?.loadedInnerLength]);

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'loadedInnerLength' && prevData?.loadedInnerLength === null) {
        loadedLengthInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerArea' && prevData?.loadedInnerArea === null) {
        loadedAreaInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerHeight' && prevData?.loadedInnerHeight === null) {
        loadedHeightInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const isDisabledBtn = () => {
    if (!productFormData?.loaded) {
      return true;
    } else if (productFormData?.loaded?.code === 'CARGO') {
      return !productFormData?.loadedInnerLength || loadedInnerLengthError;
    } else {
      return (
        !productFormData?.loadedInnerLength ||
        !productFormData?.loadedInnerArea ||
        !productFormData?.loadedInnerHeight ||
        loadedInnerLengthError ||
        loadedInnerAreaError ||
        loadedInnerHeightError
      );
    }
  };

  const isShowOnlyLength = (prevData: any, productFormData: any) => {
    const isShowAllLoadedInfo = isAllLoadedInfoAvailable(prevData) || isAllLoadedInfoAvailable(productFormData);

    return !isShowAllLoadedInfo && shouldShowOnlyLength(productFormData);
  };

  return (
    <>
      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="pt-6 text-gray-8">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold break-keep">
              적재함 종류와 <br />
              길이(내측 길이)를 입력해주세요.
            </h3>
            <RoundBadge text="3/9"></RoundBadge>
          </div>

          <div className="text-base pt-[30px] pb-24">
            <div
              className="w-full flex justify-center items-center py-3"
              onClick={() => handleClickSelectBox('loaded')}
            >
              <TextInputLabelOff
                name="loaded"
                placeholder="적재함 종류 선택"
                value={productFormData?.loaded?.desc || ''}
                fontSize={18}
                height={36}
                type="text"
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              ></TextInputLabelOff>
            </div>
            {isShowOnlyLength(prevData, productFormData) ? (
              <div className="w-full flex justify-center items-center py-3">
                <TextInputLabelOff
                  ref={loadedLengthInputRef}
                  name="loadedInnerLength"
                  placeholder="길이 입력"
                  value={productFormData?.loadedInnerLength ? String(productFormData?.loadedInnerLength) : ''}
                  onChange={(e) => handleChangeLoadedInput(e)}
                  onBlur={(e) => handleBlurInput(e, 'loadedInnerLength')}
                  onFocus={(e) => scrollIntoView(e)}
                  error={loadedInnerLengthError}
                  errorMsg={loadedInnerLengthErrorMsg}
                  fontSize={18}
                  height={36}
                  maxLength={4}
                  inputMode="numeric"
                  suffix="m"
                ></TextInputLabelOff>
              </div>
            ) : (
              <>
                <div className="w-full flex justify-center items-center py-3">
                  <label className="text-base font-medium text-gray-8 min-w-[60px]">길이</label>
                  <TextInputLabelOff
                    ref={loadedLengthInputRef}
                    name="loadedInnerLength"
                    placeholder="길이 입력"
                    value={productFormData?.loadedInnerLength ? String(productFormData?.loadedInnerLength) : ''}
                    onChange={(e) => handleChangeLoadedInput(e)}
                    onBlur={(e) => handleBlurInput(e, 'loadedInnerLength')}
                    onFocus={(e) => scrollIntoView(e)}
                    error={loadedInnerLengthError}
                    errorMsg={loadedInnerLengthErrorMsg}
                    fontSize={18}
                    height={36}
                    inputMode="numeric"
                    suffix="m"
                    maxLength={4}
                  ></TextInputLabelOff>
                </div>
                <div className="w-full flex justify-center items-center py-3">
                  <label className="text-base font-medium text-gray-8 min-w-[60px]">너비</label>
                  <TextInputLabelOff
                    ref={loadedAreaInputRef}
                    name="loadedInnerArea"
                    placeholder="너비 입력"
                    value={productFormData?.loadedInnerArea ? String(productFormData?.loadedInnerArea) : ''}
                    onChange={(e) => handleChangeLoadedInput(e)}
                    onBlur={(e) => handleBlurInput(e, 'loadedInnerArea')}
                    onFocus={(e) => scrollIntoView(e)}
                    error={loadedInnerAreaError}
                    errorMsg={loadedInnerAreaErrorMsg}
                    fontSize={18}
                    height={36}
                    inputMode="numeric"
                    suffix="m"
                    maxLength={4}
                  ></TextInputLabelOff>
                </div>
                <div className="w-full flex justify-center items-center py-3">
                  <label className="text-base font-medium text-gray-8 min-w-[60px]">높이</label>
                  <TextInputLabelOff
                    ref={loadedHeightInputRef}
                    name="loadedInnerHeight"
                    placeholder="높이 입력"
                    value={productFormData?.loadedInnerHeight ? String(productFormData?.loadedInnerHeight) : ''}
                    onChange={(e) => handleChangeLoadedInput(e)}
                    onBlur={(e) => handleBlurInput(e, 'loadedInnerHeight')}
                    onFocus={(e) => scrollIntoView(e)}
                    error={loadedInnerHeightError}
                    errorMsg={loadedInnerHeightErrorMsg}
                    fontSize={18}
                    height={36}
                    inputMode="numeric"
                    suffix="m"
                    maxLength={5}
                  ></TextInputLabelOff>
                </div>
              </>
            )}
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="다음(가변축)"
          disabledRight={isDisabledBtn()}
          onClickRightButton={handleClickNext}
        ></DualFooterButton>
      </div>

      <DownToUpDetailPopup isShow={isOpenOptionModal} onClosePopup={handleCloseOptionModal} title={'적재함 종류'}>
        <div className="px-4 pb-8">
          <RadioList
            name="loaded"
            list={optionData}
            value={productFormData?.loaded?.code || ''}
            onChange={(val) => {
              handleClickOption(val, 'loaded');
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </DownToUpDetailPopup>

      <BasicPopup
        isShow={isShowExitConfirmPopup}
        title="정말 나가시겠어요?"
        textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
        textLeftBtn="취소"
        onClickLeftBtn={handleCloseExitPopup}
        textRightBtn="나가기"
        onClickRightBtn={handleClickExitBtn}
      />
    </>
  );
};

export default LoadedForm;

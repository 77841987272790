import React, { SVGProps } from 'react';

const SvgZigtruckRoundedColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width={40} height={40} fill="#1E42A6" rx={6} />
    <g fill="#fff" clipPath="url(#zigtruck-rounded-color-icon_svg__a)">
      <path d="M7.507 15.964v1.244h1.864S9.056 18.607 7 19.878l.822 1.01s.733-.227 1.885-1.619l1.37 1.332.966-.915-1.507-1.541s.514-.83.514-2.181H7.506ZM9.719 21.895v1.265h3.24s-.015 1.234-.79 2.112L13.36 26s1.14-1.305 1.069-4.105H9.719ZM13.133 20.888h1.398v-5.436s.027-.193.23-.251h16.585s.215.017.27.224v2.53h-1.445v1.022h1.445v2.017H33v-6.486s-.044-.418-.455-.508H13.647s-.418.04-.514.529v6.36Z" />
      <path d="M20.379 15.823h4.543v1.174h-3.258v.864h3.258v1.084h-3.245v.953h3.245v1.096h-4.543v-5.17ZM25.828 15.823v1.164h2.337v.87h-2.337v3.137h3.72v-1.096h-2.404v-.933h2.405v-3.142h-3.72ZM15.523 21.895v1.271H31.52s.046 1.118-.797 2.101l1.187.723S33 24.89 33 21.895H15.522Z" />
    </g>
    <defs>
      <clipPath id="zigtruck-rounded-color-icon_svg__a">
        <path fill="#fff" d="M7 14h26v12H7z" />
      </clipPath>
    </defs>
  </svg>
);
SvgZigtruckRoundedColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgZigtruckRoundedColorIcon;

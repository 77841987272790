import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DualFooterButton from '../Common/Button/DualFooterButton';
import RadioList, { OptionType } from '../Common/Input/Radio/RadioList';
import TextInputLabelUp from '../Common/Input/TextInputLabelUp';
import AlertModal from '../Common/Modal/AlertModal';
import BottomSheetPopup from '../Common/Popup/BottomSheetPopup';
import { ChevronDownIcon } from '../Icon';
import apiManager from '@/api/AxiosInstance';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import UploaderComponent from '@/components/Common/UploaderComponent';
import MenuHeader from '@/components/Header/MenuHeader';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { BASE_TONNAGE, INDIVIDUAL } from '@/const/license';
import { positiveFloatRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import NotFoundPage from '@/pages/Error/NotFoundPage';
import { determineLicensePlateDescription } from '@/utils/Products/productUtils';
import { compareObjects, formatNumber, getCurrentYear } from '@/utils/common';
import { getLicenseTypeValue } from '@/utils/license/licenseUtils';
import { getTonnageErrorMessage, getYearErrorMessage, processTonnageInput, processYearInput } from '@/utils/validation';

interface License {
  year?: string;
  tons?: string;
  licenseType?: EnumPresenter;
  licenseSalesType: EnumPresenter;
  useClassification?: EnumPresenter;
  locate?: EnumPresenter;
  insuranceRate?: string;
  price?: number;
  fee?: number;
  certificationImage?: string;
  licenseImage?: string;
  [key: string]: any;
}

type LicneseImageType = {
  certificationImage: string;
  licenseImage: string;
};

const LicenseForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isClicked = useRef<boolean>();

  const yearRef = useRef<HTMLInputElement>(null);
  const tonsRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const insuranceRateRef = useRef<HTMLInputElement>(null);
  const feeRef = useRef<HTMLInputElement>(null);

  const [yearError, setYearError] = useState(false);
  const [tonsError, setTonsError] = useState(false);
  const [yearErrorMsg, setYearErrorMsg] = useState('');
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');

  const [descriptionOfMaxTons, setDescriptionOfMaxTons] = useState(BASE_TONNAGE);

  const [priceError, setPriceError] = useState<boolean>(false);

  const [isShow, setIsShow] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [title, setTitle] = useState('');
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const { licenseEnum, setLicenseEnum } = useLicenseEnumContext();
  const [license, setLicense] = useState<License | null>();
  const [prevLicense, setPrevLicense] = useState<License>();
  const [licneseImage, setLicenseImage] = useState<LicneseImageType>({ certificationImage: '', licenseImage: '' });
  const [files, setFiles] = useState<FileUploader | undefined>({});
  const [loading, setLoading] = useState<boolean>(false);

  const isModify = location.state?.isModify;
  const menuTitle = isModify ? '번호판 정보 수정' : '번호판 판매';
  const [isShowErrorPage, setIsShowErrorPage] = useState(false);
  const licenseInfo = location.state?.licenseInfo;
  const [productId, setProductId] = useState<number>();

  const { showToast } = useToastContext();

  useEffect(() => {
    if (!licenseEnum) {
      apiManager
        .get('/api/v1/public/license/filter-info')
        .then((response) => {
          const responseData: LicenseEnum = response.data;
          setLicenseEnum(responseData);
        })
        .catch(() => {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        });
    }

    if (id) {
      setLoading(true);
      apiManager
        .get(`/api/v1/license/${id}`)
        .then((response) => {
          setLoading(false);
          const updateData = response.data;
          setLicense(updateData);
          setDescriptionOfMaxTons(updateData.maxTons ? Number(updateData.maxTons) : BASE_TONNAGE);
          setLicenseImage({
            certificationImage: updateData?.certificationImage,
            licenseImage: updateData?.licenseImage,
          });
          setPrevLicense(updateData);
        })
        .catch(() => {
          setIsShowErrorPage(true);
        });
    }
  }, []);

  useEffect(() => {
    if (files) {
      setLicense({
        ...license,
        certificationImage: files.certificationImage,
        licenseImage: files.licenseImage,
      } as License);

      setLicenseImage({
        certificationImage: files.certificationImage ? String(files.certificationImage) : '',
        licenseImage: files?.licenseImage ? String(files?.licenseImage) : '',
      });
    }
  }, [files]);

  useEffect(() => {
    if (licenseInfo) {
      const { year, tons, productId } = licenseInfo;
      setLicense({ ...license, year: year, tons: tons, licenseSalesType: { code: 'TRADE', desc: '매매' } } as License);
      setProductId(productId);
    } else {
      setLicense({ ...license, licenseSalesType: { code: 'TRADE', desc: '매매' } } as License);
    }
  }, [licenseInfo]);

  const keyValueList: KeyValueListType = {
    licenseType: '번호판 종류',
    useClassification: '번호판 용도',
    locate: '지역',
    licenseSalesType: '거래 방식',
  };

  const onClickCreateOrModify = () => {
    if (isClicked.current === true) {
      return;
    }
    isClicked.current = true;
    if (license) {
      if (isModify) {
        const request = {
          productId: productId,
          year: license.year,
          tons: license.tons,
          licenseType: license.licenseType?.code,
          useClassification: license.useClassification?.code,
          licenseSalesType: license.licenseSalesType?.code,
          locate: license.locate?.code,
          insuranceRate: license.insuranceRate,
          fee: license.fee,
          price: license.price,
          certificationImageUrl: license.certificationImage,
          licenseImageUrl: license.licenseImage,
          maxTons: descriptionOfMaxTons ? String(descriptionOfMaxTons) : null,
        };

        const changedData: Partial<any> = {};

        if (prevLicense?.year !== license?.year) {
          changedData.year = license?.year;
        }
        if (prevLicense?.tons !== license?.tons) {
          changedData.tons = license?.tons;
        }
        if (prevLicense?.licenseType?.code !== license?.licenseType?.code) {
          changedData.licenseType = license?.licenseType?.code;
        }
        if (prevLicense?.useClassification?.code !== license?.useClassification?.code) {
          changedData.useClassification = license?.useClassification?.code;
        }
        if (prevLicense?.licenseSalesType?.code !== license?.licenseSalesType?.code) {
          changedData.licenseSalesType = license?.licenseSalesType?.code;
        }
        if (prevLicense?.locate?.code !== license?.locate?.code) {
          changedData.locate = license?.locate?.code;
        }
        if (prevLicense?.insuranceRate !== license?.insuranceRate) {
          changedData.insuranceRate = license.insuranceRate;
        }
        if (prevLicense?.fee !== license?.fee) {
          changedData.fee = license.fee;
        }
        if (prevLicense?.price !== license?.price) {
          changedData.price = license.price;
        }
        if (prevLicense?.certificationImage !== license?.certificationImage) {
          changedData.certificationImageUrl = license.certificationImage;
        }
        if (prevLicense?.maxTons !== descriptionOfMaxTons) {
          changedData.maxTons = descriptionOfMaxTons ? String(descriptionOfMaxTons) : null;
        }
        if (prevLicense?.licenseImage !== license?.licenseImage) {
          changedData.licenseImageUrl = license.licenseImage;
        }
        if (productId) {
          changedData.productId = productId;
        }

        if (Object.keys(changedData).length > 0) {
          const request = {
            ...changedData,
          };

          apiManager
            .patch(`/api/v1/license/${id}`, request)
            .then(() => {
              showToast('번호판이 수정되었어요.', 'success', 'bottom');
              navigate('/license/my');
            })
            .catch(() => {
              showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
            })
            .finally(() => {
              isClicked.current = false;
            });
        }
      } else {
        const request = {
          productId: productId,
          year: license.year,
          tons: license.tons,
          licenseType: license.licenseType?.code,
          useClassification: license.useClassification?.code,
          licenseSalesType: license.licenseSalesType?.code,
          locate: license.locate?.code,
          insuranceRate: license.insuranceRate,
          fee: license.fee,
          price: license.price,
          certificationImageUrl: license.certificationImage,
          licenseImageUrl: license.licenseImage,
          maxTons: descriptionOfMaxTons ? String(descriptionOfMaxTons) : null,
        };

        apiManager
          .post('/api/v1/license', request)
          .then(() => {
            setIsOpenModal(true);
          })
          .catch(() => {
            showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
          })
          .finally(() => {
            isClicked.current = false;
          });
      }
    }
  };

  const handleChangeInsuranceRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = value.trim() === '' ? null : value.trim();
      setLicense({ ...license, [name]: str } as License);
    }
  };

  const handleChangeYearInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const trimmedValue = processYearInput(value);

    setLicense({ ...license, [name]: trimmedValue } as License);

    const errorMessage = getYearErrorMessage(trimmedValue);

    if (errorMessage) {
      setYearError(true);
      setYearErrorMsg(errorMessage);
    } else {
      setYearError(false);
      setYearErrorMsg('');
    }
  };

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processTonnageInput(value, 1);

    setLicense({ ...license, [name]: trimmedValue } as License);
    setDescriptionOfMaxTons(determineLicensePlateDescription(Number(value)));

    const errorMessage = getTonnageErrorMessage(trimmedValue);

    if (errorMessage) {
      setTonsError(true);
      setTonsErrorMsg(errorMessage);
    } else {
      setTonsError(false);
      setTonsErrorMsg('');
    }
  };

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let price = String(value).replace(/[^0-9.]/g, '');
    if (!value) {
      setLicense({
        ...license,
        [name]: undefined,
      } as License);
      setPriceError(true);
    } else if (/^[0-9]*$/.test(price) || price === '0') {
      setPriceError(false);
      if (Number(price) >= 100000) {
        setLicense({
          ...license,
          [name]: 99999,
        } as License);
      } else {
        setPriceError(false);
        setLicense({
          ...license,
          [name]: Number(price),
        } as License);
      }
    }
  };

  const onClickSelectBox = (key: string) => {
    if (!location.state) {
      return;
    }

    const arr: OptionDataType[] = [];
    setTitle(key);

    if (key === 'licenseType') {
      const newOptionData = licenseEnum.licenseType.map((item) => {
        if (item?.code === INDIVIDUAL) {
          return {
            ...item,
            desc: `${item.desc} (1.5톤 이상 ~ ${descriptionOfMaxTons}톤)`,
          };
        }
        return item;
      });

      setOptionData(newOptionData);
    } else if (key === 'useClassification') {
      setOptionData(licenseEnum.useClassification);
    } else {
      setOptionData(licenseEnum.locate);
    }
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    setLicense({ ...license, [key]: item } as License);
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const isDisabledBtn = () => {
    if (compareObjects(prevLicense, license)) {
      return true;
    } else if (
      !license?.licenseSalesType.code ||
      !license?.price ||
      !license?.year ||
      !license?.tons ||
      yearError ||
      tonsError
    ) {
      return true;
    } else if (license?.licenseSalesType?.code === 'TRADE') {
      if (license?.licenseType?.code === 'GENERAL_CARGO') {
        return !license?.licenseType?.code || !license?.useClassification?.code || !license?.locate?.code;
      } else {
        return !license?.licenseType?.code || !license?.useClassification?.code;
      }
    } else {
      return !license?.insuranceRate || !license?.fee || !license?.locate?.code;
    }
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'year') {
        yearRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'tons') {
        tonsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'price') {
        priceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'insuranceRate') {
        insuranceRateRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'fee') {
        feeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setLicense({ ...license, [key]: newVal } as License);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    navigate('/license', { state: { isForm: true }, replace: true });
  };

  const clear = () => {
    setLicense(null);
    setLicenseImage({ certificationImage: '', licenseImage: '' });
    setYearError(false);
    setYearErrorMsg('');
    setTonsError(false);
    setTonsErrorMsg('');
    setPriceError(false);
  };

  return (
    <>
      <DefaultHelmet page="license" />

      {isShowErrorPage ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <>
          <MenuHeader title={menuTitle}></MenuHeader>
          <div className="w-full">
            <div className="px-4 pt-[60px] pb-[100px]">
              {loading && <LoadingSpinner text={'로딩중입니다.'}></LoadingSpinner>}
              <form className="w-full mt-4">
                <div className="p-4 my-4 rounded-lg bg-blue-0">
                  <p className="text-lg font-bold text-gray-8">번호판 판매 문의를 남겨주세요.</p>
                  <p className="text-sm mt-3 text-gray-8 font-light	leading-[17px]">
                    번호판 판매 문의를 남겨주시면<br></br>
                    확인 후 담당자가 연락드릴 예정입니다.<br></br>
                  </p>
                </div>
                <div className="flex flex-col gap-[30px]">
                  <TextInputLabelUp
                    ref={yearRef}
                    name="year"
                    label="연식"
                    placeholder={`연식 입력 ex) ${getCurrentYear()}`}
                    value={license?.year || ''}
                    onChange={(e) => handleChangeYearInput(e)}
                    onFocus={(e) => scrollIntoView(e)}
                    inputMode="numeric"
                    required
                    error={yearError}
                    errorMsg={yearErrorMsg}
                    maxLength={4}
                  ></TextInputLabelUp>

                  <TextInputLabelUp
                    ref={tonsRef}
                    name="tons"
                    label="톤수"
                    placeholder="톤수 입력 ex) 8.5"
                    value={license?.tons || ''}
                    onChange={(e) => handleChangeTonsInput(e)}
                    onFocus={(e) => scrollIntoView(e)}
                    required
                    error={tonsError}
                    errorMsg={tonsErrorMsg}
                    suffix="t"
                    maxLength={4}
                  ></TextInputLabelUp>

                  <div className="text-base">
                    <p className="mb-2 font-semibold text-gray-8">
                      거래 방식
                      <span className="font-normal text-red">(필수)</span>
                    </p>
                    <div className="px-1">
                      <RadioList
                        name="licenseSalesType"
                        list={licenseEnum.licenseSalesType}
                        value={license?.licenseSalesType.code || ''}
                        onChange={(val) => {
                          onChangeRadioInput(val, 'licenseSalesType');
                        }}
                        justify="equal"
                      ></RadioList>
                    </div>
                  </div>

                  {license?.licenseSalesType?.code === 'RENTAL' && (
                    <>
                      <TextInputLabelUp
                        ref={insuranceRateRef}
                        name="insuranceRate"
                        label="보험요율"
                        placeholder="보험요율 입력"
                        value={license?.insuranceRate || ''}
                        onChange={(e) => handleChangeInsuranceRate(e)}
                        onFocus={(e) => scrollIntoView(e)}
                        suffix="%"
                        required
                      />

                      <TextInputLabelUp
                        ref={feeRef}
                        name="fee"
                        label="지입료"
                        placeholder="지입료 입력"
                        value={license?.fee ? formatNumber(String(license?.fee)) : ''}
                        onChange={(e) => onChangePrice(e)}
                        onFocus={(e) => scrollIntoView(e)}
                        inputMode="numeric"
                        suffix="만원"
                        required
                      />
                    </>
                  )}

                  {license?.licenseSalesType?.code === 'TRADE' && (
                    <>
                      <div onClick={() => onClickSelectBox('licenseType')}>
                        <TextInputLabelUp
                          name="licenseType"
                          label="번호판 종류"
                          placeholder="번호판 종류 선택"
                          value={getLicenseTypeValue(license?.licenseType, descriptionOfMaxTons)}
                          type="text"
                          readOnly
                          required
                          suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                        />
                      </div>

                      <div onClick={() => onClickSelectBox('useClassification')}>
                        <TextInputLabelUp
                          name="useClassification"
                          label="번호판 용도"
                          placeholder="번호판 용도 선택"
                          value={license?.useClassification?.desc || ''}
                          type="text"
                          readOnly
                          required
                          suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                        />
                      </div>
                    </>
                  )}

                  {(license?.licenseType?.code === 'GENERAL_CARGO' || license?.licenseSalesType?.code === 'RENTAL') && (
                    <div onClick={() => onClickSelectBox('locate')}>
                      <TextInputLabelUp
                        name="locate"
                        label="지역"
                        placeholder="지역 선택"
                        value={license?.locate?.desc || ''}
                        type="text"
                        readOnly
                        required
                        suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                      ></TextInputLabelUp>
                    </div>
                  )}

                  <TextInputLabelUp
                    ref={priceRef}
                    name="price"
                    label="가격"
                    placeholder="가격 입력"
                    value={formatNumber(license?.price?.toString(), true) || ''}
                    onChange={(e) => onChangePrice(e)}
                    onFocus={(e) => scrollIntoView(e)}
                    inputMode="numeric"
                    suffix="만원"
                    required
                  ></TextInputLabelUp>

                  <div className="flex grid items-center grid-cols-2 gap-3">
                    <div>
                      <label className="mb-3 text-base font-medium xxs:text-sm text-gray-8">차량등록증 업로드</label>
                      <UploaderComponent
                        icon="file"
                        name="certificationImage"
                        code="CERTIFICATE_IMAGE"
                        files={licneseImage}
                        setFiles={setFiles}
                        carNumber=""
                      ></UploaderComponent>
                    </div>
                    <div>
                      <label className="mb-3 text-base font-medium xxs:text-sm text-gray-8">번호판 허가증 업로드</label>
                      <UploaderComponent
                        icon="file"
                        name="licenseImage"
                        code="LICENSE"
                        files={licneseImage}
                        setFiles={setFiles}
                        carNumber=""
                      ></UploaderComponent>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <DualFooterButton
              leftButtonText="초기화"
              onClickLeftButton={() => {
                clear();
              }}
              rightButtonText="저장"
              onClickRightButton={() => {
                onClickCreateOrModify();
              }}
              disabledRight={isDisabledBtn()}
            ></DualFooterButton>
          </div>
          <BottomSheetPopup isOpen={isShow} onClose={() => setIsShow(false)} title={keyValueList[title]}>
            <div className="px-4 pb-8">
              <RadioList
                name="license"
                list={optionData}
                value={
                  (license && license[title as keyof License]?.id) ||
                  (license && license[title as keyof License]?.code) ||
                  ''
                }
                onChange={(val) => {
                  onClickItem(val, title);
                }}
                horizontal={false}
              ></RadioList>
            </div>
          </BottomSheetPopup>

          <AlertModal
            isOpen={isOpenModal}
            title="판매문의 등록 완료"
            content={
              <span>
                담당자가 확인 후<br />
                연락 드릴 예정입니다.
              </span>
            }
            onClose={handleCloseModal}
          ></AlertModal>
        </>
      )}
    </>
  );
};

export default LicenseForm;

import apiManager from '../AxiosInstance';
import { MULTIPART_FORM_DATA } from '@/const/headers';

export const createChatMessages = async (formData: FormData) => {
  const response = await apiManager.post('api/v1/chat-messages', formData, {
    headers: {
      'Content-Type': MULTIPART_FORM_DATA,
    },
  });
  return response.data;
};

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import LicensePlateInput from '@/components/Common/LicensePlateInput';
import NameInputComponent from '@/components/Common/NameInputComponent';
import Footer from '@/components/Footer';
import MenuHeader from '@/components/Header/MenuHeader';

interface ErrorInfo {
  licenseNumberError: boolean;
  licenseNumberErrorMessage: string;
  ownerNameError: boolean;
  ownerNameErrorMessage: string;
}

const PriceTrendSearch = () => {
  const navigate = useNavigate();
  const [productOriginData, setProductOriginData] = useState<ProductDetailResponse>();
  const [ownerInfo, setOwnerInfo] = useState<OwnerInfo>({
    licenseNumber: '',
    name: '',
  });

  const [errorInfo, setErrorInfo] = useState<ErrorInfo>();
  const [pageNum, setPageNum] = useState(1);

  const updatePage = (pageNum: number) => {
    setPageNum(pageNum);
  };

  const goPrev = () => {
    if (pageNum === 1) {
      navigate(-1);
    } else {
      setPageNum((prev) => prev - 1);
    }
  };

  return (
    <>
      <MenuHeader
        title="시세검색"
        onClickPrevBtn={() => {
          goPrev();
        }}
      ></MenuHeader>
      <div className="w-full pb-20 pt-[60px]">
        {pageNum === 1 && (
          <section className="p-4">
            <h2 className="text-2xl font-bold mt-2 mb-6 text-gray-8">차량번호로 시세 알아보기</h2>
            <LicensePlateInput
              updatePage={updatePage}
              ownerInfo={ownerInfo}
              setOwnerInfo={setOwnerInfo}
              errorInfo={errorInfo}
              setErrorInfo={setErrorInfo}
            ></LicensePlateInput>
            <Link to={'/price-trend/form'}>
              <p className="underline underline-offset-4 text-gray-7 pt-6 text-center cursor-pointer xxs:text-sm">
                차량번호를 모를 때, 필터로 시세 검색하기
              </p>
            </Link>
          </section>
        )}
        {pageNum === 2 && (
          <div className="p-4 text-gray-8">
            <h2 className="text-2xl font-bold mt-2">소유자명을 입력해주세요</h2>
            <div className="text-gray-7 font-medium text-[15px] leading-[19px] mt-3">
              차량번호와 소유자명을 입력하면
              <br /> 소유한 차량의 기본 정보를 확인할 수 있어요.
            </div>
            <div className="px-[30px] mt-[30px] mb-2">
              <img
                src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/vehicle_registration_certificate.png"
                alt="vehicle_registration_certificate"
                className="w-full h-full"
              />
            </div>
            <div className="rounded-lg bg-blue-0 p-4 mb-[30px] mt-0 mb-0 text-sm">
              <p>* 소유자명은 자동차 등록증에서 확인이 가능합니다.</p>
              <p className="text-red">* 법인차량은 "(주)", "주식회사" 단어까지 정확히 입력해주세요.</p>
            </div>
            <NameInputComponent
              updatePage={updatePage}
              ownerInfo={ownerInfo}
              setOwnerInfo={setOwnerInfo}
              errorInfo={errorInfo}
              setErrorInfo={setErrorInfo}
              productOriginData={productOriginData}
              setProductOriginData={setProductOriginData}
            ></NameInputComponent>
          </div>
        )}
      </div>
      <Footer isMain={pageNum === 1}></Footer>
    </>
  );
};

export default PriceTrendSearch;

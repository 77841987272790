import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { getFuelingHistory } from '@/api/public';
import ItemBoxWithRightArrow from '@/components/Common/Box/ItemBoxWithRightArrow';
import FloatingButton from '@/components/Common/Button/FloatingButton';
import MenuHeader from '@/components/Header/MenuHeader';
import { PlusSmallIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { TODAY } from '@/const/date';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { feulingHistorySearchParamsFromAtom } from '@/store/fuelingHistory';
import { asYYYYMM, formatNumber, getDayOfWeek } from '@/utils/common';
import { isSameMonth } from '@/utils/dateUtils';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Button } from '@mui/material';

const FuelList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToastContext();
  const { member } = useMemberContext();
  const [baseDate, setBaseDate] = useState(dayjs(location.state.selectedDate) || TODAY);
  const [searchParams] = useAtom(feulingHistorySearchParamsFromAtom);
  const setSearchParams = useSetAtom(feulingHistorySearchParamsFromAtom);

  useEffect(() => {
    if (member) {
      setSearchParams({
        driveVehicleInfoId: member.driveVehicleInfo.id,
        fuelingYearAndMonth: baseDate.format('YYYY-MM') || searchParams.fuelingYearAndMonth,
      });
    }
  }, [member, baseDate]);

  const onClickAddFuel = () => {
    navigate(`/drive/fuel/form`);
  };

  const onClickEdit = (id: number) => {
    if (fuelingHistory?.fuelingHistoryByBaseDayList) {
      const fuelingData = fuelingHistory.fuelingHistoryByBaseDayList
        .flatMap((item) => item.fuelingHistoryList)
        .find((item) => item.id === id);

      if (fuelingData) {
        navigate(`/drive/fuel/${id}`, { state: { data: fuelingData } });
      } else {
        console.error(`No fueling data found with id: ${id}`);
      }
    }
  };

  const getFuelingHistoryList = async () => {
    try {
      if (searchParams.driveVehicleInfoId && baseDate.format('YYYY-MM')) {
        const params = new URLSearchParams({
          driveVehicleInfoId: searchParams.driveVehicleInfoId.toString(),
          fuelingYearAndMonth: baseDate.format('YYYY-MM'),
        });
        const response = await getFuelingHistory(params);
        const data: FuelingHistoryResponse = response.data;
        return data;
      }
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data: fuelingHistory, isSuccess } = useQuery(
    ['get-drive-info', searchParams],
    () => getFuelingHistoryList(),
    {
      onSuccess: () => {},
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      refetchOnWindowFocus: false,
      enabled: !!searchParams.driveVehicleInfoId && !!baseDate.format('YYYY-MM'),
    },
  );

  const formatAmount = (amount: number): string => {
    return amount ? amount + 'L' : '';
  };

  const getDetailContents = (item: FuelingHistory) => {
    const data: KeyValueLabel[] = [];
    if (item.amount) {
      data.push({ key: 'amount', label: '수량', value: formatAmount(item.amount) });
    }
    data.push({ key: 'price', label: '주유 금액', value: formatNumber(item.price.toString()) + '원' });
    if (item.subsidyForFuel) {
      data.push({
        key: 'subsidyForFuel',
        label: '예상 유가보조금',
        value: formatNumber(item.subsidyForFuel.toString()) + '원',
      });
    }
    return data;
  };

  const onClickPrevMonth = () => {
    setBaseDate(dayjs(baseDate).subtract(1, 'month'));
    setSearchParams({
      driveVehicleInfoId: member?.driveVehicleInfo.id,
      fuelingYearAndMonth: dayjs(baseDate).subtract(1, 'month').format('YYYY-MM'),
    });
  };

  const onClickNextMonth = () => {
    if (!isSameMonth(TODAY, baseDate)) {
      setBaseDate(dayjs(baseDate).add(1, 'month'));
      setSearchParams({
        driveVehicleInfoId: member?.driveVehicleInfo.id,
        fuelingYearAndMonth: dayjs(baseDate).add(1, 'month').format('YYYY-MM'),
      });
    }
  };

  return (
    <>
      <MenuHeader title="주유비" path="/drive"></MenuHeader>

      <div className="relative w-full pt-[60px]">
        <div className="px-4 pb-5">
          <div className="flex sm280:flex-col-reverse items-center sm280:items-start justify-between md-only:justify-around bg-blue-0 rounded-lg p-4">
            <div className="text-gray-8 font-semibold pb-1 sm280:mt-2">
              <span className="text-gray-7">총 </span>
              {formatNumber(fuelingHistory?.totalFuelingCostOfMonth?.toString())}원
            </div>

            <div className="border-l border-gray-3 h-[43px] ml-2 pl-2 sm280:hidden"></div>

            <div className="flex items-center justify-center w-[120px]">
              <div className="flex items-center pr-2" onClick={onClickPrevMonth}>
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: 16, color: colors.gray[8] }} />
              </div>
              <span className="text-gray-8 font-semibold text-lg">{asYYYYMM(baseDate)}</span>
              <div className="flex items-center pl-2" onClick={onClickNextMonth}>
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: 16, color: isSameMonth(TODAY, baseDate) ? colors.gray[5] : colors.gray[8] }}
                />
              </div>
            </div>
          </div>
        </div>

        <section className="py-2 px-4 pb-24 border-t-8 border-gray-1">
          {isSuccess && fuelingHistory && fuelingHistory?.fuelingHistoryByBaseDayList?.length > 0 ? (
            <>
              {fuelingHistory?.fuelingHistoryByBaseDayList.map((item, index) => (
                <div className="pt-9" key={index}>
                  <div className="flex items-center justify-between border-b border-gray-3 pb-[10px]">
                    <span className="text-gray-6 text-sm font-medium">
                      {dayjs(item.baseDay).date()}일 {getDayOfWeek(item.baseDay)}
                    </span>
                    <span className="text-gray-7 text-sm font-medium">
                      {formatNumber(item.totalFuelingCostOfDay.toString())}원
                    </span>
                  </div>
                  {item.fuelingHistoryList.map((fuelingHistory, key) => (
                    <div
                      key={key}
                      className={`cursor-pointer ${key > 0 ? 'pt-6' : 'pt-4'}`}
                      onClick={() => onClickEdit(fuelingHistory.id)}
                    >
                      <ItemBoxWithRightArrow data={getDetailContents(fuelingHistory)}></ItemBoxWithRightArrow>
                    </div>
                  ))}
                </div>
              ))}
              <FloatingButton
                bgColor={colors.primary}
                textColor={colors.gray[0]}
                icon={<PlusSmallIcon color={colors.gray[0]}></PlusSmallIcon>}
                title={'주유비 추가'}
                minWidth={150}
                minHeight={48}
                onClick={onClickAddFuel}
              ></FloatingButton>
            </>
          ) : (
            isSuccess && (
              <div className="w-full h-full px-4" style={{ height: `calc(100vh - 180px)` }}>
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <span className="text-gray-7 text-lg text-center">등록된 주유비가 없습니다.</span>
                  <Button
                    variant="contained"
                    onClick={onClickAddFuel}
                    sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '30px' }}
                    className="w-[200px]"
                  >
                    주유비 추가하기
                  </Button>
                </div>
              </div>
            )
          )}
        </section>
      </div>
    </>
  );
};

export default FuelList;

import React, { SVGProps } from 'react';

const SvgVehicleInspectionSellColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 50 50" {...props}>
    <path
      fill="#D1DDFF"
      fillRule="evenodd"
      d="M11.25 25c5.523 0 10-4.477 10-10a9.971 9.971 0 0 0-.727-3.75h7.42a4.167 4.167 0 0 1 4.167 4.167v19.047a4.167 4.167 0 0 1-4.167 4.167H10.417a4.167 4.167 0 0 1-4.167-4.167V23.662a9.954 9.954 0 0 0 5 1.338Z"
      clipRule="evenodd"
    />
    <path
      fill="#3C5FBF"
      d="M6.25 32.546h42.593v1.918a4.167 4.167 0 0 1-4.167 4.167h-34.26a4.167 4.167 0 0 1-4.166-4.167v-1.918ZM32.11 15.813h5.764c1.648 0 3.212.733 4.268 2l2.137 2.564 3.226 3.764a5.556 5.556 0 0 1 1.337 3.615v4.79H32.11V15.813Z"
    />
    <circle cx={15.378} cy={37.11} r={4.001} fill="#F5F5F5" stroke="#414141" strokeWidth={4.167} />
    <circle cx={39.718} cy={37.11} r={4.001} fill="#F5F5F5" stroke="#414141" strokeWidth={4.167} />
    <path
      fill="#D1DDFF"
      fillRule="evenodd"
      d="M41.705 17.335c.158.149.308.308.448.477l2.137 2.565 3.226 3.763c.186.216.353.445.501.684-.17.075-.359.116-.557.116H38.07a1.389 1.389 0 0 1-1.39-1.389v-4.827c0-.768.622-1.39 1.39-1.39h3.636Z"
      clipRule="evenodd"
    />
    <path
      fill="#3C5FBF"
      fillRule="evenodd"
      d="M11.002 23.125a8.44 8.44 0 1 0 0-16.88 8.44 8.44 0 0 0 0 16.88Zm3.55-8.208h.768c.393 0 .68-.219.68-.676 0-.285-.26-.671-.562-.671h-.708l.303-1.503c.07-.472-.177-.917-.55-.917-.465 0-.724.312-.752.585l-.287 1.835h-1.66l-.318-1.058c-.102-.3-.291-.445-.665-.445-.318 0-.503.258-.562.558l-.2.945H8.336l-.33-2.12c-.06-.414-.476-.515-.822-.414-.374.114-.547.473-.476.86l.303 1.674h-.606c-.303 0-.405.343-.405.788 0 .23.216.559.503.559h.822l.779 3.466c.055.402.53.617 1.038.617.507 0 .735-.316.838-.675l.849-3.408h.157l1.01 3.466c.13.46.52.617.968.617.508 0 .838-.285.897-.73l.692-3.353ZM9.068 17.32l-.503-2.404h1.081l-.578 2.404Zm3.783-.113-.621-2.291h1.054l-.433 2.291Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgVehicleInspectionSellColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgVehicleInspectionSellColorIcon;

import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import MenuHeader from '@/components/Header/MenuHeader';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import { SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import useFetchProductData from '@/hooks/useFetchProductData';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { productsFormAtom, useResetProductsForm } from '@/store/products';
import { getTonnageErrorMessage, processTonnageInput } from '@/utils/validation';

type SalesTypeKey = keyof typeof SALESTYPE;

function TonsForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);
  const resetProductsForm = useResetProductsForm();

  const checkForChanges = () => {
    return prevData?.tons !== productFormData?.tons;
  };

  const saveChanges = () => {
    const tonsValue = Number(productFormData?.tons);
    const idValue = Number(productFormData?.id);

    const request = {
      id: idValue,
      tons: tonsValue,
    };

    updateProductTemporarySaveMutation.mutate(request);
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    saveChanges,
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  const [tonsError, setTonsError] = useState(false);
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({});

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/loaded/${id}`,
  });

  const handleClickNext = () => {
    const hasChanges = prevData?.tons !== productFormData?.tons;
    const tonsValue = Number(productFormData?.tons);
    const idValue = Number(productFormData?.id);

    if (!hasChanges) {
      return navigate(`/products/sales/loaded/${id}`, { replace: true });
    }

    const request = {
      id: idValue,
      tons: tonsValue,
    };

    updateProductNextStepMutation.mutate(request);
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/model/${id}`, { replace: true });
  };

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processTonnageInput(value, 1);

    setProductFormData({ ...productFormData, [name]: trimmedValue } as ProductDetailResponse);

    const errorMessage = getTonnageErrorMessage(trimmedValue);

    if (errorMessage) {
      setTonsError(true);
      setTonsErrorMsg(errorMessage);
    } else {
      setTonsError(false);
      setTonsErrorMsg('');
    }
  };

  return (
    <>
      <DefaultHelmet page="sale" />

      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="pt-6 text-gray-8">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold break-keep">톤수를 입력해주세요.</h3>
            <RoundBadge text="2/9" />
          </div>
          <div className="text-base pt-[30px] pb-24">
            <TextInputLabelOff
              name="tons"
              placeholder="톤수 입력"
              value={productFormData?.tons || ''}
              onChange={(e) => handleChangeTonsInput(e)}
              error={tonsError}
              errorMsg={tonsErrorMsg}
              fontSize={18}
              height={36}
              suffix="t"
              maxLength={4}
            />
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="다음(적재함)"
          onClickRightButton={handleClickNext}
          disabledRight={!productFormData?.tons || tonsError}
        />
      </div>
      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />
    </>
  );
}

export default TonsForm;

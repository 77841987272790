import React, { SVGProps } from 'react';

const SvgLoanConsulationSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M13.715 12.837a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm-2.13-7.431a.471.471 0 1 0-.883.33l.243.65h-.403a.471.471 0 0 0 0 .943h.757l.689 1.837a.471.471 0 0 0 .883 0l.844-2.252.844 2.252a.471.471 0 0 0 .883 0l.689-1.837h.756a.471.471 0 1 0 0-.943h-.403l.244-.65a.471.471 0 0 0-.883-.33L15 7.657l-.845-2.251a.471.471 0 0 0-.883 0l-.844 2.251-.844-2.251Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M21.432 12.031a1.935 1.935 0 0 0-2.748 0l-3.187 3.07a2.176 2.176 0 0 1-.391 1.129c-.318.44-.843.77-1.606.77H10a.5.5 0 0 1 0-1h3.5c.437 0 .662-.17.794-.355.15-.209.206-.475.206-.645 0-.437-.17-.662-.355-.794a.954.954 0 0 0-.11-.069 2.152 2.152 0 0 0-.719-.119H10.98c-.612-.612-1.48-1.186-2.425-1.186a3.889 3.889 0 0 0-3.009 1.422L1.696 18.5c-.35.353-.544.822-.544 1.321 0 .5.193.968.544 1.321l2.018 1.692a1.848 1.848 0 0 0 2.788-.19l2.228-2.836a.15.15 0 0 1 .12-.06h6.091c.117.021.237.021.354 0a2.05 2.05 0 0 0 1.444-.61l4.693-4.339c.367-.37.57-.861.57-1.384 0-.523-.202-1.014-.57-1.384Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgLoanConsulationSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgLoanConsulationSolidIcon;

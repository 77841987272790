import React from 'react';
import { Helmet } from 'react-helmet-async';

import metadata from './metadata';

const DefaultHelmet = ({ page }: { page?: keyof typeof metadata }) => {
  const defaultMeta = metadata.home;
  const pageMeta = page ? metadata[page] : defaultMeta;

  return (
    <Helmet>
      <title>{pageMeta.title || defaultMeta.title}</title>
      <meta name="description" content={pageMeta.description || defaultMeta.description} />
      <meta property="og:title" content={pageMeta.title || defaultMeta.title} />
      <meta property="og:url" content={pageMeta.siteUrl || defaultMeta.siteUrl} />
      <meta property="og:description" content={pageMeta.description || defaultMeta.description} />
    </Helmet>
  );
};

export default DefaultHelmet;

import { formatYYYYMM, formatYYYYMMDD } from '@/utils/common';
import { atom } from 'jotai';

const initialDriveInfo = {};
const initialDriveAddressParamsState: DriveAddressInfo = { transferStartLocation: null, transferEndLocation: null };
const initialDriveTransportParamsState: TransportForm[] = [];
const driveSearchParams: DriveHistorySearchParams = {
  driveVehicleInfoId: 0,
  baseYearMonth: formatYYYYMM(new Date()),
  baseDay: formatYYYYMMDD(new Date()),
};

export const driveAddressParamsAtom = atom<DriveAddressInfo>(initialDriveAddressParamsState);
export const driveTransportParamsAtom = atom<TransportForm[]>(initialDriveTransportParamsState);
export const driveInfoAtom = atom<{ toll?: number | null; memo?: string | null }>(initialDriveInfo);
export const driveSearchParamsAtom = atom<DriveHistorySearchParams>(driveSearchParams);

import React, { SVGProps } from 'react';

const SvgBellSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M11.999 2.25A6.75 6.75 0 0 0 5.249 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .015 1.02c3.144 1.31 10.343 2.923 17.708 0a.75.75 0 0 0 .014-1.02 8.217 8.217 0 0 1-2.118-5.52V9a6.75 6.75 0 0 0-6.75-6.75Zm3.618 16.74c-2.588.323-5.08.243-7.246-.036a3.752 3.752 0 0 0 7.246.036Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgBellSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgBellSolidIcon;

import React, { SVGProps } from 'react';

const SvgCameraSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M7 12.932v15.886a2 2 0 0 0 2 2h21a2 2 0 0 0 2-2V12.932a2 2 0 0 0-2-2h-4.25l-2.5-2.841h-7.5l-2.5 2.84H9a2 2 0 0 0-2 2Zm16.477 7.074a3.977 3.977 0 1 1-7.954 0 3.977 3.977 0 0 1 7.954 0Zm2.273 0a6.25 6.25 0 1 1-12.5 0 6.25 6.25 0 0 1 12.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgCameraSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCameraSolidIcon;

import React, { SVGProps } from 'react';

const SvgLoadingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <circle cx={10} cy={10} r={8.75} stroke="#E8E8E8" strokeWidth={2.5} />
    <path
      fill={props.color || 'currentColor'}
      d="M10 0a10 10 0 1 0 8.717 14.9l-2.163-1.215A7.519 7.519 0 1 1 10 2.48V0Z"
    />
  </svg>
);
SvgLoadingIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgLoadingIcon;

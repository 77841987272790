import apiManager from '../AxiosInstance';

export const fetchMyProducts = async () => {
  const res = await apiManager.get('/api/v1/products/my');
  return res.data;
};

export const fetchSimilarProducts = async (queryParams: URLSearchParams) => {
  const res = await apiManager.get('/api/v1/products/similar?', { params: queryParams });
  return res.data;
};

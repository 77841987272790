import React, { useState } from 'react';

const SplashComponent = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center w-full h-screen bg-blue-800 z-100">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/logo_white.png"
          alt="직트럭 로고"
          className="w-[164px]"
        />
        <span className="font-bold text-white mt-6 break-keep text-[26px] xxs:text-[22px]">
          화물차 거래의 새로운 기준
        </span>
      </div>
    </>
  );
};

export default SplashComponent;

import apiManager from '@/api/AxiosInstance';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import SettingSwitch, { SwitchProps } from '@/components/Common/Switch';
import MenuHeader from '@/components/Header/MenuHeader';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import React, { useEffect, useState } from 'react';

const NotificationSettings = () => {
  const { showToast } = useToastContext();
  const { member, updateNotificationSettings } = useMemberContext();
  const [isShowBasicPopup, setIsShowBasicPopup] = useState<boolean>(false);

  const keyValueList: { [key: string]: string } = {
    chatting: '채팅',
    interestProduct: '관심 차량',
    marketing: '마케팅',
    trade: '거래',
    driveHistory: '운행일지',
    driveHistoryTime: '운행일지 시간',
  };

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    componentContent: null,
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });

  const checkAlermOff = () => {
    setIsShowBasicPopup(true);
    setPopupInfo({
      isShow: true,
      title: '채팅 알림 해제',
      textContent: '새로운 메시지나<br/>중요한 소식을 놓칠 수 있어요.<br/>정말 채팅 알림을 해제하시겠어요?',
      textRightBtn: '확인',
      onClickRightBtn: () => {
        patchNotificationSetting('chatting', false), setIsShowBasicPopup(false);
      },
      textLeftBtn: '취소',
      onClickLeftBtn: () => {
        setIsShowBasicPopup(false);
      },
    });
  };

  const patchNotificationSetting = (key: string, checked: boolean) => {
    let modifyRequest = { [key]: checked };

    apiManager
      .patch(`/api/v1/notification-settings/${member?.notificationSettings?.id}`, modifyRequest)
      .then((response) => {
        if (response) {
          updateNotificationSettings(key, checked);
          showToast(`${keyValueList[key]} 알림을 ${checked ? '설정' : '해제'}했습니다.`, 'success', 'bottom');
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };

  const handleChange = (key: string, checked: boolean) => {
    if (key === 'driveHistoryTime') {
      return;
    }

    if (key === 'chatting' && !checked) {
      checkAlermOff();
      return;
    }
    patchNotificationSetting(key, checked);
  };

  const marketingAgreeDate = member?.notificationSettings?.marketing
    ? member?.notificationSettings.marketingAgreeDate
    : '';

  let notificationSettings: SwitchProps[] = [
    {
      keyName: 'chatting',
      title: '채팅알림',
      desc: '채팅 메시지 알림',
      checked: member?.notificationSettings?.chatting || false,
      handleChange: handleChange,
    },
    {
      keyName: 'interestProduct',
      title: '관심 차량 알림',
      desc: '관심 차량 등록, 변경 알림',
      checked: member?.notificationSettings?.interestProduct || false,
      handleChange: handleChange,
    },
    {
      keyName: 'trade',
      title: '거래 알림',
      desc: '가격 인하 알림',
      checked: member?.notificationSettings?.trade || false,
      handleChange: handleChange,
    },
    {
      keyName: 'driveHistory',
      title: '운행일지 알림',
      desc: '운행일지 작성 알림',
      checked: member?.notificationSettings?.driveHistory || false,
      handleChange: handleChange,
    },
    {
      keyName: 'driveHistoryTime',
      title: '운행일지 시간 설정',
      timeSetting: true,
      time: member?.notificationSettings?.driveHistoryTime,
      handleChange: handleChange,
    },
    {
      keyName: 'marketing',
      title: '마케팅 알림',
      desc: '마케팅 정보 수신 동의',
      date: marketingAgreeDate,
      checked: member?.notificationSettings?.marketing || false,
      handleChange: handleChange,
    },
  ];

  useEffect(() => {
    notificationSettings = [
      {
        keyName: 'chatting',
        title: '채팅알림',
        desc: '채팅 메시지 알림',
        checked: member?.notificationSettings?.chatting || false,
        handleChange: handleChange,
      },
      {
        keyName: 'interestProduct',
        title: '관심 차량 알림',
        desc: '관심 차량 등록, 변경 알림',
        checked: member?.notificationSettings?.interestProduct || false,
        handleChange: handleChange,
      },
      {
        keyName: 'trade',
        title: '거래 알림',
        desc: '가격 인하 알림',
        checked: member?.notificationSettings?.trade || false,
        handleChange: handleChange,
      },
      {
        keyName: 'driveHistory',
        title: '운행일지 알림',
        desc: '운행일지 작성 알림',
        checked: member?.notificationSettings?.driveHistory || false,
        handleChange: handleChange,
      },
      {
        keyName: 'driveHistoryTime',
        title: '운행일지 시간 설정',
        timeSetting: true,
        time: member?.notificationSettings?.driveHistoryTime,
        handleChange: handleChange,
      },
      {
        keyName: 'marketing',
        title: '마케팅 알림',
        desc: '마케팅 정보 수신 동의',
        date: marketingAgreeDate,
        checked: member?.notificationSettings?.marketing || false,
        handleChange: handleChange,
      },
    ];
  }, [member?.notificationSettings]);

  return (
    <>
      <div className="w-full">
        <MenuHeader title="알림 설정"></MenuHeader>
        <div className="pt-[60px]">
          {notificationSettings?.map((item, index) => (
            <div key={index}>
              {item.keyName === 'driveHistoryTime' && member?.notificationSettings?.driveHistory ? (
                <>
                  <SettingSwitch
                    keyName={item.keyName}
                    title={item.title}
                    desc={item.desc}
                    date={item.date}
                    checked={item.checked}
                    timeSetting={item.timeSetting}
                    time={item.time}
                    handleChange={item.handleChange}
                  ></SettingSwitch>
                </>
              ) : (
                <>
                  {item.keyName !== 'driveHistoryTime' && (
                    <SettingSwitch
                      keyName={item.keyName}
                      title={item.title}
                      desc={item.desc}
                      date={item.date}
                      checked={item.checked}
                      timeSetting={item.timeSetting}
                      time={item.time}
                      handleChange={item.handleChange}
                    ></SettingSwitch>
                  )}
                </>
              )}
              {item.keyName === 'driveHistoryTime' && <div className="bg-[#FAFAFA] w-full h-2"></div>}
            </div>
          ))}
        </div>
        <BasicPopup
          isShow={isShowBasicPopup}
          title={popupInfo?.title}
          textContent={popupInfo?.textContent}
          componentContent={popupInfo?.componentContent}
          textRightBtn={popupInfo.textRightBtn}
          onClickRightBtn={popupInfo.onClickRightBtn}
          textLeftBtn={popupInfo.textLeftBtn}
          onClickLeftBtn={popupInfo.onClickLeftBtn}
        ></BasicPopup>
      </div>
    </>
  );
};

export default NotificationSettings;

import { COMPLETED } from '@/const/productStatus';
import { formatPrice } from '@/utils/common';
import React from 'react';

export interface MyProductPriceProps {
  product: ProductDetailResponse;
}

export default function MyProductPrice({ product }: MyProductPriceProps) {
  const isShowActualSalePrice = () => {
    return !!product.status && COMPLETED == product.status.code && !!product.actualSalePrice;
  };

  return (
    <>
      {isShowActualSalePrice() ? (
        <>{formatPrice(String(product.actualSalePrice))}</>
      ) : (
        product.price && <>{formatPrice(String(product.price))}</>
      )}
    </>
  );
}

import React, { SVGProps } from 'react';

const SvgJobBoldColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#C6A279"
      d="M5 15a3.5 3.5 0 0 1 3.5-3.5h23A3.5 3.5 0 0 1 35 15v15a3.5 3.5 0 0 1-3.5 3.5h-23A3.5 3.5 0 0 1 5 30V15Z"
    />
    <path
      fill="#825F3F"
      fillRule="evenodd"
      d="M17.375 9a2.875 2.875 0 0 0-2.875 2.875 1.5 1.5 0 0 1-3 0A5.875 5.875 0 0 1 17.375 6h5.25a5.875 5.875 0 0 1 5.875 5.875 1.5 1.5 0 0 1-3 0A2.875 2.875 0 0 0 22.625 9h-5.25ZM8.572 16.628a1 1 0 0 1 1.3-.556l10.242 4.103a.75.75 0 0 0 .578-.008l9.41-4.084a1 1 0 0 1 .796 1.834l-9.41 4.085a2.75 2.75 0 0 1-2.117.03L9.128 17.928a1 1 0 0 1-.556-1.3Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgJobBoldColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgJobBoldColorIcon;

import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { createLeaseServices, createNotificationsEvent } from '@/api/AdditionalServices/createAdditionalServices';
import { getLeaseTrucks } from '@/api/AdditionalServices/getAdditionalServices';
import BasicButton from '@/components/Common/Button/BasicButton';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import MenuHeader from '@/components/Header/MenuHeader';
import NewCarLeaseGuideView from '@/components/More/NewCarLeaseService/NewCarLeaseGuideView';
import { IS_ALREADY_APPLY_LEASE_SERVICE, IS_RESERVE_LEASE_TRUCK_NOTIFICATION } from '@/const/additionalService';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import useNavigateBack from '@/hooks/useNavigateBack';
import { memberAtom, updateAdditionalServiceAtom, updateNotificationSettingsAtom } from '@/store/member';

const NewCarLeaseService = () => {
  const [memberAtomData] = useAtom(memberAtom);
  const [, updateAdditionalService] = useAtom(updateAdditionalServiceAtom);
  const [, updateNotificationSettingData] = useAtom(updateNotificationSettingsAtom);

  const [isAlreadyApply, setIsAlreadyApply] = useState(false);
  const [isAlreadySetAlert, setIsAlreadySetAlert] = useState(false);

  const [isNearBottom, setIsNearBottom] = useState(false);
  const threshold = 20;

  const location = useLocation();
  const { showToast } = useToastContext();

  const authRedirect = useAuthRedirect();
  const navigateBack = useNavigateBack();

  const PurchaseAccompanyingServiceRef = useRef<HTMLDivElement>(null);

  const [isPopupShow, setIsPopupShow] = useState(false);

  const { data: truckInfoList } = useQuery(['get-lease-trucks'], () => getLeaseTrucks(), {
    onSuccess: () => {},
    refetchOnMount: false,
  });

  useEffect(() => {
    if (memberAtomData) {
      setIsAlreadyApply(memberAtomData.isAlreadyApplyLeaseService);
      setIsAlreadySetAlert(memberAtomData.isReserveLeaseTruckNotification);
    }
  }, [memberAtomData]);

  const handleScrollBottom = () => {
    const currentScroll = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (currentScroll + viewportHeight < documentHeight) {
      window.scrollBy({
        top: viewportHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + viewportHeight >= documentHeight - threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  const handleClickApplyService = () => {
    if (!authRedirect('/new-car-lease')) {
      return;
    }
    setIsPopupShow(true);
  };

  const handleClickClosePopup = () => {
    setIsPopupShow(false);
  };

  const handleClickOkBtn = () => {
    // postLeaseServices.mutate(truckInfoList[0].id);
    if (memberAtomData?.notificationSettings.id) {
      patchNotificationsEvent.mutate();
    }
    setIsPopupShow(false);
  };

  const patchNotificationsEvent = useMutation(
    () => createNotificationsEvent({ redirectUrl: location.pathname, notificationType: 'EVENT' }),
    {
      onSuccess: () => {
        showToast('차량 입고 알림이 등록되었어요.', 'success', 'bottom');
        setIsAlreadySetAlert(true);
        updateAdditionalService(IS_RESERVE_LEASE_TRUCK_NOTIFICATION);
      },
      onError: (error: AxiosError) => {
        if (error.code === 'ALREADY_RESERVE_EVENT_NOTIFICATION') {
          showToast(error.message, 'error', 'bottom');
        }
      },
    },
  );

  const postLeaseServices = useMutation((leaseTruckId: number) => createLeaseServices(leaseTruckId), {
    onSuccess: () => {
      showToast('견적 상담을 신청했어요.', 'success', 'bottom');
      setIsAlreadyApply(true);
      updateAdditionalService(IS_ALREADY_APPLY_LEASE_SERVICE);
    },
    onError: (error: AxiosError) => {
      if (error.code && error.message) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const handleClickPrev = () => {
    navigateBack();
  };

  return (
    <>
      <MenuHeader title="신차리스" onClickPrevBtn={handleClickPrev}></MenuHeader>

      <div className="w-full pt-[60px] pb-[56px]" ref={PurchaseAccompanyingServiceRef}>
        <NewCarLeaseGuideView data={truckInfoList?.[0]} />

        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
          <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
          <BasicButton
            name={
              isAlreadyApply
                ? '견적 신청완료'
                : isAlreadySetAlert
                ? '입고 알림 받기 완료'
                : isNearBottom
                ? '차량 입고 알림 받기'
                : '아래로 내리기'
            }
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight={'bold'}
            isDisabled={isAlreadyApply || isAlreadySetAlert}
            onClick={isNearBottom ? handleClickApplyService : handleScrollBottom}
          />
        </div>
      </div>

      <ConfirmModal
        isOpen={isPopupShow}
        title="새로운 차량을 준비 중이에요"
        content={
          <>준비된 차량이 모두 소진되어 현재는 신청이 어려워요. 대신 새로운 차량이 추가되면 알림을 보내드릴까요?</>
        }
        leftButtonLabel="닫기"
        onLeftButtonClick={handleClickClosePopup}
        rightButtonLabel="알림받기"
        onRightButtonClick={handleClickOkBtn}
      />
    </>
  );
};

export default NewCarLeaseService;

import React, { SVGProps } from 'react';

const SvgNameTransferColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 30 30" {...props}>
    <rect width={18} height={22} x={3.5} y={4} fill="#1976D2" rx={2} />
    <path stroke="#FAFAFA" strokeLinecap="round" strokeWidth={1.8} d="M7.5 10h10M7.5 14h7M7.5 18h4" />
    <path fill="#737373" d="M23.5 8 25 4.5 26.5 8v17a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8Z" />
  </svg>
);
SvgNameTransferColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgNameTransferColorIcon;

import React, { SVGProps } from 'react';

const SvgEmptyTruckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 120 120" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M18.262 37.797A8.797 8.797 0 0 1 27.059 29h42.886a8.8 8.8 0 0 1 8.52 6.598h6.557a6.598 6.598 0 0 1 5.394 2.798l9.115 12.94a6.597 6.597 0 0 1 1.204 3.8v16.75c0 1.96-.855 3.72-2.208 4.926-.277 8.258-7.06 14.868-15.386 14.868-7.756 0-14.172-5.735-15.24-13.196h-.311c-1.067 7.46-7.483 13.196-15.24 13.196-3.514 0-6.755-1.18-9.346-3.162a15.335 15.335 0 0 1-9.347 3.162c-8.503 0-15.395-6.893-15.395-15.395V37.797Zm8.797-4.398a4.399 4.399 0 0 0-4.399 4.398v38.488c0 6.073 4.924 10.996 10.997 10.996 3.044 0 5.796-1.234 7.789-3.234l1.558-1.563 1.558 1.563c1.993 2 4.745 3.234 7.789 3.234 6.073 0 10.996-4.923 10.996-10.996v-2.2h8.797v2.2c0 6.073 4.924 10.996 10.997 10.996 6.073 0 10.996-4.923 10.996-10.996 0-.163-.003-.325-.01-.486l-.058-1.337 1.16-.666a2.197 2.197 0 0 0 1.108-1.91v-16.75a2.2 2.2 0 0 0-.401-1.267L86.82 40.93a2.2 2.2 0 0 0-1.798-.933H74.344v-2.199c0-2.43-1.97-4.398-4.399-4.398H27.06Zm47.285 9.896h9.448l7.776 12.097H74.344V43.295Zm4.398 4.399v3.299h4.77l-2.122-3.299h-2.648Zm-43.984 28.59a1.1 1.1 0 1 1-2.2 0 1.1 1.1 0 0 1 2.2 0Zm4.399 0a5.498 5.498 0 1 1-10.997.001 5.498 5.498 0 0 1 10.997 0Zm13.193 1.1a1.1 1.1 0 1 0 0-2.199 1.1 1.1 0 0 0 0 2.2Zm0 4.4a5.498 5.498 0 1 0 0-10.997 5.498 5.498 0 0 0 0 10.996Zm31.892-5.5a1.1 1.1 0 1 1-2.199 0 1.1 1.1 0 0 1 2.2 0Zm4.399 0a5.498 5.498 0 1 1-10.996.001 5.498 5.498 0 0 1 10.996 0Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgEmptyTruckIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEmptyTruckIcon;

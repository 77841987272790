import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { postReferralCode } from '@/api/public';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { getReferralCode } from '@/utils/referralCodeUtils';

export default function LoginHandler() {
  const navigate = useNavigate();
  const { updateReferralCodes } = useMemberContext();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const deviceToken = localStorage.getItem('deviceToken');
  const [cookies, setCookie, removeCookie] = useCookies(['redirect_url', 'partner']);
  const { showToast } = useToastContext();

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);

      const partnerCode = cookies.partner;

      if (partnerCode) {
        const code = getReferralCode(partnerCode);
        if (code) {
          postReferralCodeMutation.mutate({ referralCode: code });
        }
      }

      if (deviceToken) {
        apiManager
          .post('/api/v1/fire-base/device-token', { deviceToken: deviceToken })
          .then((response) => {})
          .catch((error) => {});
      }
      if (cookies && cookies.redirect_url) {
        navigate(cookies.redirect_url);
        removeCookie('redirect_url', { path: '/' });
      } else {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, []);

  const postReferralCodeMutation = useMutation((request: { referralCode: string }) => postReferralCode(request), {
    onSuccess: (response) => {
      updateReferralCodes(response.data);
    },
    onError: (error) => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  return <></>;
}

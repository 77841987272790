import React from 'react';

import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface SalesTypeSelectButtonProps {
  onClickLeft: () => void;
  onClickRight: () => void;
}

const SalesTypeSelectButton = ({ onClickLeft, onClickRight }: SalesTypeSelectButtonProps) => {
  return (
    <>
      <div className="flex-col gap-2 flex sm680:flex-row sm680: mt-[30px] px-4">
        <div
          className="bg-primary rounded-[16px] px-4 py-[22px] h-[116px] cursor-pointer w-full"
          onClick={() => onClickLeft()}
        >
          <div className="flex justify-between">
            <span className="font-bold text-[19px] text-gray-0">직거래 셀프 판매</span>
            <ChevronRightIcon color={colors.gray[0]} />
          </div>
          <div className="font-medium text-[14px] leading-[17px] text-gray-0 mt-[14px]">
            <span>
              수수료없이 무료로 등록하세요. <br />
              구매자와 직접 거래하는 방식입니다.
            </span>
          </div>
        </div>

        <div
          className="border border-primary rounded-[16px] px-4 py-[22px] h-[116px] cursor-pointer w-full"
          onClick={() => onClickRight()}
        >
          <div className="flex justify-between">
            <span className="font-bold text-[19px] text-primary">전문가에게 빠른 판매</span>
            <ChevronRightIcon color={colors.primary} />
          </div>
          <div className="font-medium text-[14px] leading-[17px] text-primary mt-[14px]">
            <span>
              직트럭 전문가와 매입 견적
              <br />
              상담을 통해 즉시 차량을 판매하세요.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesTypeSelectButton;

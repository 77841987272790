import { colors } from '@/const/colors';
import { formatNumber } from '@/utils/common';
import React from 'react';
import { ChevronRightIcon } from '@/components/Icon';

export interface FuelingItemViewProps {
  fuelingHistory: FuelingHistory;
}

const FuelingItemView = ({ fuelingHistory }: FuelingItemViewProps) => {
  return (
    <>
      <div className="flex justify-between items-center border border-gray-4 rounded-lg py-2 px-4">
        <div className="flex flex-col">
          <dl className="flex items-center py-[8px] font-normal">
            <dt className="text-gray-7 text-base pr-4">주유 금액(수량)</dt>
            <dd className={`text-gray-8 flex items-center`}>
              {formatNumber(fuelingHistory.price?.toString())}({fuelingHistory.amount}L)
            </dd>
          </dl>
          <dl className="flex items-center py-[8px] font-normal">
            <dt className="text-gray-7 text-base pr-4">예상 유가보조금</dt>
            <dd className={`text-gray-8 flex items-center`}>
              {formatNumber(fuelingHistory.subsidyForFuel?.toString())}원
            </dd>
          </dl>
        </div>
        <ChevronRightIcon color={colors.gray[7]}></ChevronRightIcon>
      </div>
    </>
  );
};

export default FuelingItemView;

import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { getAssuranceProducts, getBanner, getInterestProducts, getRecommendProducts } from '@/api/public';
import BannerCarousel from '@/components/Common/Banner/BannerCarousel';
import TopBanner from '@/components/Common/Banner/TopBanner';
import BaseModal from '@/components/Common/Modal/BaseModal';
import BubbleComponent from '@/components/Common/Tooltip/BubbleComponent';
import AssuranceProduts from '@/components/Home/AssuranceProducts';
import InterestProducts from '@/components/Home/InterestProducts';
import RecommendProducts from '@/components/Home/RecommendProducts';
import SubCards from '@/components/Home/SubCards';
import {
  BellIcon,
  CalendarCheckColorIcon,
  IncreasingTrendGraphColorIcon,
  JobBoldColorIcon,
  LicensePlateBoldColorIcon,
  NewCarLeaseColorIcon,
  PhoneRingOutlineIcon,
  PhoneRingSolidIcon,
} from '@/components/Icon';
import SvgSearchQuestionBoldIcon from '@/components/Icon/SearchQuestionBoldIcon';
import { HOME, HOME_POP_UP } from '@/const/banner';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { SALE } from '@/const/productStatus';
import { IMAGE_BASE_URL } from '@/const/url';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { isMemberLoadedAtom, memberAtom } from '@/store/member';
import { isExistNotReadNotificationAtom } from '@/store/notification';
import { phoneCall } from '@/utils/common';

const businessInfo = [
  { title: '상호', content: '주식회사 직트럭' },
  { title: '대표', content: '양요한' },
  { title: '주소', content: '경기도 평택시 월곡길 9-9' },
  { title: '대표 전화', content: REPRESENTATIVE_NUMBER },
  { title: '사업자 번호', content: '721-87-01975' },
  { title: '통신판매업 신고번호', content: '제 2023-경기평택-1174호' },
];

const Home = () => {
  const [memberAtomData] = useAtom(memberAtom);
  const [isMemberLoaded] = useAtom(isMemberLoadedAtom);
  const [isExistNotReadNotification] = useAtom(isExistNotReadNotificationAtom);

  const userAgent = navigator.userAgent;

  const isNaverInApp = /NAVER/.test(userAgent);
  const isKakaoInApp = /KAKAOTALK/.test(userAgent);

  const isAndroidWeb =
    /Android/i.test(userAgent) && /WebKit/i.test(userAgent) && (!/wv/i.test(userAgent) || isNaverInApp || isKakaoInApp);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceToken = searchParams.get('deviceToken');
  const [isShow, setIsShow] = useState<boolean>(true);
  const [isShowTopBanner, setIsShowTopBanner] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    content: null,
    onClose: () => {},
    isXMarkVisible: false,
    paddingType: 'padding-top-0',
  });

  const subFunctionCards: ButtonCard[] = [
    {
      title: '시세검색',
      path: '/price-trend',
      icon: (
        <IncreasingTrendGraphColorIcon color={colors.gray[6]} width={40} height={40}></IncreasingTrendGraphColorIcon>
      ),
    },
    {
      title: '번호판거래',
      path: '/license',
      icon: <LicensePlateBoldColorIcon color={colors.gray[0]} width={40} height={40}></LicensePlateBoldColorIcon>,
    },
    {
      title: '신차리스',
      path: '/new-car-lease',
      icon: <NewCarLeaseColorIcon color={colors.gray[0]} width={40} height={40}></NewCarLeaseColorIcon>,
    },
    {
      title: '운행일지',
      path: '/drive',
      icon: <CalendarCheckColorIcon color={colors.gray[6]} width={40} height={40}></CalendarCheckColorIcon>,
    },
    {
      title: '일자리구하기',
      path: '/job',
      icon: <JobBoldColorIcon color={colors.gray[6]} width={40} height={40}></JobBoldColorIcon>,
    },
  ];

  const LOCALSTORAGE_KEY = 'homePopupTime';

  const getRandomPopupImage = (popupImages: Banner[]): Banner => {
    const length = popupImages.length;
    const randomIndex = Math.floor(Math.random() * length);
    return popupImages[randomIndex];
  };

  useEffect(() => {
    if (isAndroidWeb) {
      setIsShowTopBanner(true);
    }

    if (deviceToken) {
      localStorage.setItem('deviceToken', deviceToken);
    }

    checkPopupClose() ? setIsShow(false) : setIsShow(true);
  }, []);

  const queryOptions = {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  };

  const {
    data: bannersData = [],
    isLoading: bannersLoading,
    isError: bannersError,
  } = useQuery('banners', getBanner, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60 * 24,
  });

  const filteredBanners = bannersData.filter((banner: Banner) => banner.bannerLocation.code === HOME);
  const filterPopup = bannersData.filter((banner: Banner) => banner.bannerLocation.code === HOME_POP_UP);
  const randomPopupImage = filterPopup.length > 0 ? getRandomPopupImage(filterPopup) : null;

  useEffect(() => {
    if (randomPopupImage) {
      setModalConfig({
        content: renderHomeModal(),
        onClose: () => {},
        isXMarkVisible: false,
        paddingType: 'padding-top-0',
      });
    }
  }, [randomPopupImage]);

  const {
    data: assuranceProducts = [],
    isLoading: assuranceProductsLoading,
    isError: assuranceProductsError,
  } = useQuery('get-assurance-products', getAssuranceProducts, queryOptions);

  const {
    data: recommendProducts = [],
    isLoading: recommendProductsLoading,
    isError: recommendProductsError,
  } = useQuery(['get-recommend-products'], () => getRecommendProducts(), {
    ...queryOptions,
    enabled: !!isMemberLoaded && !!memberAtomData?.id,
  });

  const getMyInterestProducts = async () => {
    try {
      const response = await getInterestProducts(SALE);
      const res: ProductsList[] = response;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const {
    data: interestProducts = [],
    isLoading: interestProductsLoading,
    isError: interestProductsError,
  } = useQuery('get-interest-products', getMyInterestProducts, {
    ...queryOptions,
    enabled: !!isMemberLoaded && !!memberAtomData?.id,
  });

  const closePopup = (expireDays: number) => {
    let expire = new Date();
    expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
    localStorage.setItem(LOCALSTORAGE_KEY, expire.getTime().toString());
  };

  const checkPopupClose = () => {
    const expireDay = localStorage.getItem(LOCALSTORAGE_KEY);
    let today = new Date();

    return today.getTime() <= Number(expireDay) || sessionStorage.getItem('isHomePopup') === 'false';
  };

  const onClickCloseToday = () => {
    closePopup(1);
    setIsShow(false);
  };

  const onClickClose = () => {
    setIsShow(false);
  };

  useEffect(() => {
    sessionStorage.setItem('isHomePopup', `${isShow}`);
  }, [isShow]);

  const onClickPurchaseInquiry = () => {
    navigate('/products/purchase');
  };

  const onClickImage = (item?: Banner | null) => {
    if (item?.link) {
      if (item.type.code === 'INTERNAL') {
        navigate(item.link);
      } else {
        window.open(item.link);
      }
    }
  };

  const onClickCallInquiry = () => {
    if (isMobile) {
      phoneCall(REPRESENTATIVE_NUMBER);
    } else {
      setIsShow(true);
      setModalConfig({
        content: renderCallInquiryModal(),
        isXMarkVisible: true,
        paddingType: 'padding-top-20',
        onClose: () => setIsShow(false),
      });
    }
  };

  const renderCallInquiryModal = () => {
    return (
      <div className="flex flex-col items-right w-full px-2 pb-[14px]">
        <span className="font-medium text-gray-8 text-left text-[14px]">궁금한 사항이 있으신가요?</span>
        <div className="flex items-end justify-between text-left">
          <div className="flex flex-col gap-1 mt-5">
            <p className="font-semibold text-[22px] text-gray-12">전화문의</p>
            <p className="font-semibold text-[30px] text-gray-12 mt-1">1599-6249</p>
          </div>
          <PhoneRingSolidIcon color={colors.primary} width={58} height={58}></PhoneRingSolidIcon>
        </div>
      </div>
    );
  };

  const renderHomeModal = (): React.ReactNode => {
    return (
      <div className="max-h-[840px] min-w-[216px] sm280:h-[302px] h-[403px]">
        <img
          loading="lazy"
          src={randomPopupImage?.contents || ''}
          alt="popup_image"
          className="object-cover w-full h-full"
          width={'100%'}
          onClick={() => onClickImage(randomPopupImage)}
        />
        <p
          className="absolute bottom-0 left-0 p-4 text-[16px] sm280:text-[14px] cursor-pointer text-gray-6 font-medium"
          onClick={onClickCloseToday}
        >
          오늘 하루 보지 않기
        </p>
        <p
          className="absolute bottom-0 right-0 p-4 font-semibold cursor-pointer text-primary text-[18px] sm280:text-[16px]"
          onClick={onClickClose}
        >
          닫기
        </p>
      </div>
    );
  };

  return (
    <>
      <DefaultHelmet page="home" />
      <div className="w-full">
        <div className="pb-16">
          <div className={`flex flex-col pb-[10px] bg-gray-2 ${isShowTopBanner ? 'pt-[109px]' : 'pt-[52px]'} `}>
            <div
              className={`fixed items-center justify-between flex z-30 ${
                isShowTopBanner ? 'top-[48px]' : 'top-0'
              } mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] h-[52px]`}
              style={{
                backdropFilter: 'blur(20px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
              }}
            >
              <img loading="lazy" src={`${IMAGE_BASE_URL}/logo_primary.png`} alt="직트럭 로고" width={50} height={24} />
              <div className="flex gap-[10px]">
                <div onClick={onClickCallInquiry}>
                  <BubbleComponent
                    text="<strong class='text-gray-0 text-[12px] font-medium'>전화 상담하기</strong>"
                    bgColor={colors.primary}
                    padding="4px 6px"
                    borderRadius="4px"
                    edgeArrow="right"
                    width="82px"
                  />
                </div>

                <div
                  className="relative w-[26px] h-[26px] flex justify-center items-center"
                  onClick={onClickCallInquiry}
                >
                  <PhoneRingOutlineIcon color={colors.gray[8]} width={26} height={26} />
                </div>
                <Link to="/notification/#activity" aria-label="알림 확인" role="link" className="flex cursor-pointer">
                  <div className="relative w-[26px] h-[26px] flex justify-center items-center">
                    <BellIcon color={colors.gray[8]} width={26} height={26}></BellIcon>
                    {isExistNotReadNotification && (
                      <div className="absolute top-0 right-0 w-1 h-1 rounded bg-red"></div>
                    )}
                  </div>
                </Link>
              </div>
            </div>

            <BannerCarousel banners={filteredBanners}></BannerCarousel>

            <div className="mt-[36px] mb-[10px] px-4">
              <div className="flex gap-2 sm280:flex-col ">
                <Link
                  to="/sell-car"
                  className="flex-1 flex flex-col bg-gray-0 px-[18px] pt-4 rounded-[12px] w-full shadow-soft relative sm280:min-h-[90px] sm680:min-h-[100px] min-h-[150px]"
                >
                  <span className="text-gray-8 font-bold text-[20px] leading-[24px] break-keep">내차판매</span>
                  <span className="text-gray-7 font-medium text-[12px] leading-[15px] mt-[6px] mb-4">
                    1분만에 빠르게
                    <br className="block sm680:hidden" />
                    판매 등록하기
                  </span>
                  <div className="w-[96px] absolute right-0 bottom-0">
                    <img src={`${IMAGE_BASE_URL}/my-car-sale.png`} alt="내차판매 이미지" />
                  </div>
                </Link>
                <Link
                  to="/products"
                  className="flex-1 flex flex-col bg-gray-0 px-[18px] pt-4 rounded-[12px] w-full shadow-soft relative sm280:min-h-[90px] sm680:min-h-[100px] min-h-[150px]"
                >
                  <span className="text-gray-8 font-bold text-[20px] leading-[24px]">내차구매</span>
                  <span className="text-gray-7 font-medium text-[12px] leading-[15px] mt-[6px] mb-4">
                    찾고 있는 그 차량,
                    <br className="block sm680:hidden" />
                    여기에 있어요!
                  </span>
                  <div className="w-[96px] absolute right-0 bottom-0">
                    <img src={`${IMAGE_BASE_URL}/my-car-purchase.png`} alt="내차판매 이미지" />
                  </div>
                </Link>
              </div>
            </div>
            <SubCards cards={subFunctionCards} />
            <div className="mt-[10px] px-4" onClick={onClickPurchaseInquiry}>
              <div className="flex flex-row items-center justify-center w-full bg-gray-0 py-5 px-[18px] rounded-[12px] shadow-soft">
                <div className="flex flex-col flex-1">
                  <span className="font-bold text-[18px] text-gray-8">내차 구매 문의하기</span>
                  <span className="font-medium text-[12px] text-gray-7 break-keep">원하는 차량이 없을 때</span>
                </div>
                <SvgSearchQuestionBoldIcon color={colors.gray[7]} width={40} height={40}></SvgSearchQuestionBoldIcon>
              </div>
            </div>
          </div>
          <div className="w-full h-10 bg-gradient-to-b from-[#F5F5F5] to-[#ffffff]"></div>
          {memberAtomData && interestProducts?.length > 0 && (
            <>
              <InterestProducts products={interestProducts} />
            </>
          )}
          {recommendProducts?.length > 0 && <RecommendProducts products={recommendProducts} />}

          <AssuranceProduts products={assuranceProducts} />

          <div className="w-full px-4 py-5 bg-gray-2">
            <h3 className="text-gray-7 text-[13px] mb-2">직트럭 사업자 정보</h3>
            <div className="text-gray-6 text-[10px] mb-3">
              <dl>
                {businessInfo.map((item, index) => (
                  <div key={index} className="table-row">
                    <dt className="table-cell min-w-[140px]">{item.title}</dt>
                    <dd>{item.content}</dd>
                  </div>
                ))}
              </dl>
            </div>
            <div className="text-gray-6 text-[10px]">
              <p>ⓒ 2020 주식회사 직트럭 All Rights Reserved</p>
              <p>
                주식회사 직트럭은 통신판매중개자로서 통신판매의 당사자가 아니며, 상품 및 거래정보, 거래에 대해 책임을
                지지않습니다.
              </p>
            </div>
          </div>
        </div>

        <BaseModal
          isOpen={isShow}
          content={modalConfig.content}
          isXMarkVisible={modalConfig.isXMarkVisible}
          paddingType={modalConfig.paddingType}
          onClose={modalConfig.onClose}
        />

        <TopBanner isShow={isShowTopBanner}></TopBanner>
      </div>
    </>
  );
};

export default Home;

import React, { SVGProps } from 'react';

const SvgLoanConsultationOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M21.656 11.774a2.135 2.135 0 0 0-3.032 0l-2.522 2.541a.56.56 0 0 1-.345.164 2.142 2.142 0 0 0-1.982-1.333h-2.438a4.29 4.29 0 0 0-6.133.517L1.455 17.44c-.387.389-.6.907-.6 1.457s.213 1.068.6 1.458l2.168 2.184A2.04 2.04 0 0 0 6.7 22.33l1.885-2.495a.166.166 0 0 1 .132-.066h7.09c.61 0 1.183-.239 1.615-.673l4.235-4.268c.405-.407.628-.95.628-1.527s-.223-1.119-.628-1.527Zm-.919 2.13-4.235 4.266a.974.974 0 0 1-.696.29h-7.09c-.454 0-.89.218-1.165.583l-1.885 2.495a.737.737 0 0 1-.545.295.738.738 0 0 1-.579-.219L2.374 19.43a.758.758 0 0 1 0-1.065l3.762-3.79a.666.666 0 0 0 .052-.056 3 3 0 0 1 4.444-.25c.12.119.284.186.453.186h2.691c.43 0 .791.324.84.754a.795.795 0 0 0 .007.05.642.642 0 0 0-.002.047c0 .47-.379.85-.845.85H9.13a.652.652 0 0 0-.65.655c0 .361.292.654.65.654h4.648a2.15 2.15 0 0 0 2.09-1.682 1.846 1.846 0 0 0 1.154-.543l2.522-2.54a.842.842 0 0 1 1.195 0 .85.85 0 0 1 .247.602.85.85 0 0 1-.247.602Z"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M13.715 11.571a4.714 4.714 0 1 0 0-9.428 4.714 4.714 0 0 0 0 9.428Zm0 1.286a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M10.978 5.13a.471.471 0 0 1 .607.276l.844 2.251.844-2.251a.471.471 0 0 1 .883 0l.845 2.251.844-2.251a.471.471 0 1 1 .883.33l-1.286 3.43a.471.471 0 0 1-.883 0l-.844-2.252-.844 2.252a.471.471 0 0 1-.883 0l-1.286-3.43a.471.471 0 0 1 .276-.606Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M15.387 6.857c0-.26.21-.471.471-.471h1.029a.471.471 0 1 1 0 .943h-1.029a.471.471 0 0 1-.471-.472ZM10.07 6.857c0-.26.211-.471.472-.471h1.028a.471.471 0 1 1 0 .943h-1.028a.471.471 0 0 1-.472-.472Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgLoanConsultationOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgLoanConsultationOutlineIcon;

import React, { SVGProps } from 'react';

const SvgEmptyBellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 120 120" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M57.176 28a23.105 23.105 0 0 0-8.782 1.675 22.822 22.822 0 0 0-7.448 4.871 22.426 22.426 0 0 0-4.97 7.309 22.147 22.147 0 0 0-1.735 8.622V53.2c0 12.463-2.648 19.895-5.14 24.12l-.003.007a.69.69 0 0 0-.004.7.739.739 0 0 0 .272.27c.116.066.25.102.388.103h54.492a.789.789 0 0 0 .388-.104.74.74 0 0 0 .272-.27.692.692 0 0 0-.004-.699l-.003-.006c-2.204-3.739-4.53-9.989-5.036-20.015a24.93 24.93 0 0 1-3.579-1.117l-9.65 3.483c-2.39.862-4.703-1.45-3.84-3.84l3.216-8.912A12.552 12.552 0 0 1 64.998 42c0-3.831 1.768-7.348 4.716-10.103A22.564 22.564 0 0 0 57.176 28Zm15.901 1.386A26.552 26.552 0 0 0 57.204 24H57.2a27.103 27.103 0 0 0-10.304 1.966 26.823 26.823 0 0 0-8.752 5.725 26.426 26.426 0 0 0-5.855 8.613A26.146 26.146 0 0 0 30.24 50.48V53.2c0 11.887-2.521 18.587-4.584 22.087a4.69 4.69 0 0 0-.024 4.745 4.739 4.739 0 0 0 1.742 1.733 4.79 4.79 0 0 0 2.371.635H43.965v.72c0 3.425 1.38 6.704 3.828 9.117A13.115 13.115 0 0 0 57 96c3.448 0 6.76-1.35 9.207-3.763a12.803 12.803 0 0 0 3.828-9.117v-.72H84.254a4.789 4.789 0 0 0 2.37-.635 4.74 4.74 0 0 0 1.743-1.732 4.69 4.69 0 0 0-.024-4.745c-1.77-3.007-3.88-8.376-4.44-17.367.69.052 1.388.079 2.095.079 11.598 0 21-7.163 21-16s-9.402-16-21-16c-4.873 0-9.358 1.265-12.92 3.386ZM66.035 82.4h-18.07v.72c0 2.346.945 4.602 2.636 6.27A9.115 9.115 0 0 0 57 92a9.116 9.116 0 0 0 6.399-2.61 8.803 8.803 0 0 0 2.636-6.27v-.72Zm4.27-35.597-3.026 8.384 9.075-3.276 1.41.562c2.422.965 5.224 1.527 8.234 1.527 5.003 0 9.382-1.55 12.425-3.868 3.04-2.316 4.575-5.228 4.575-8.132s-1.535-5.816-4.575-8.132C95.38 31.549 91.001 30 85.998 30c-5.002 0-9.382 1.55-12.425 3.868-3.04 2.316-4.575 5.228-4.575 8.132 0 1.156.238 2.28.695 3.36l.612 1.443ZM80 42a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm12-3a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgEmptyBellIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEmptyBellIcon;

import React, { forwardRef } from 'react';

import { SearchOutlineIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { THEUNBAN } from '@/const/referralCode';
import '@/css/inputStyle.css';
import usePartnerSession from '@/hooks/usePartnerSession';

interface SearchBoxProps {
  name: string;
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  borderRadius?: number;
  maxHeight?: number;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  type?: string;
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onClick?: () => void;
}

const SearchBox = forwardRef<HTMLInputElement, SearchBoxProps>(
  (
    {
      name,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      error,
      errorMsg,
      fontSize = 16,
      width,
      height,
      readOnly = false,
      type = 'text',
      inputMode = 'text',
      onKeyDown,
      disabled = false,
      onClick,
    },
    ref,
  ) => {
    const isTheunban = usePartnerSession() === THEUNBAN;

    return (
      <div className="relative w-full">
        <div
          className={`searchBox flex border ${
            isTheunban ? 'border-[#cbcbcb]' : 'border-primary'
          } rounded-lg p-2 items-center w-full`}
        >
          <input
            name={name}
            defaultValue={value}
            className="w-11/12 mx-1"
            type="text"
            placeholder={placeholder}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            style={{ outline: 'none' }}
          />
          <SearchOutlineIcon color={colors.gray[6]} width={24} height={24} strokeWidth={0.1}></SearchOutlineIcon>
        </div>
      </div>
    );
  },
);
export default SearchBox;

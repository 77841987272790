import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

interface SubCardsProps {
  cards: ButtonCard[];
}

export default function SubCards({ cards }: SubCardsProps) {
  const handleSaveEntryPoint = () => {
    sessionStorage.setItem('entryPoint', location.pathname);
  };

  return (
    <>
      <div className="grid grid-cols-5 bg-gray-0 shadow-soft mx-4 rounded-[12px] sm280:grid-cols-3 sm360:grid-cols-3">
        {cards.map((item, index) => (
          <div key={index} className={clsx('flex justify-center items-center relative', index > 2 && 'sm360:hidden')}>
            {index > 0 && <div className={clsx('border-l border-gray-3 h-[36px]', index > 2 && 'sm280:hidden')}></div>}

            <Link
              to={item.path}
              className={clsx(
                'flex p-3 items-center justify-center relative rounded-[12px] w-full',
                index > 2 && 'sm280:hidden',
              )}
              onClick={handleSaveEntryPoint}
            >
              <div className="flex flex-col items-center justify-end w-full">
                <div className="flex justify-center items-center w-[40px] h-[40px]">{item.icon}</div>
                <span className="font-semibold text-gray-8 text-[15px] text-center sm280:text-[12px] mt-2">
                  {item.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-2 mx-4 rounded-[12px] gap-2 mt-2 md-only:hidden">
        {cards.map((item, index) => (
          <div key={index} className={clsx(index < 3 && 'hidden', 'flex justify-center items-center relative')}>
            <Link
              to={item.path}
              className="flex py-4 px-3 items-center justify-center bg-gray-0 rounded-[12px] w-full shadow-soft"
              onClick={handleSaveEntryPoint}
            >
              <div className="flex flex-row-reverse items-center justify-between w-full">
                <div className="flex justify-center items-center w-[40px] h-[40px]">{item.icon}</div>
                <div
                  className={clsx(
                    'font-semibold text-gray-8 text-[15px] text-center sm280:text-[12px]',
                    item.title.length > 5 && 'sm280:w-[40px] sm280:break-all sm280:whitespace-pre-wrap',
                  )}
                >
                  {item.title}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}

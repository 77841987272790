import React, { Suspense, lazy, useEffect, useState } from 'react';

import { XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/imageModalStyle.css';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const TransformComponent = lazy(() =>
  import('react-zoom-pan-pinch').then((module) => ({ default: module.TransformComponent })),
);
const TransformWrapper = lazy(() =>
  import('react-zoom-pan-pinch').then((module) => ({ default: module.TransformWrapper })),
);

interface ImageModalProps {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  imageIndex: number;
  imageList: string[];
}

const ImageModal = ({ isShow, setIsShow, imageIndex, imageList }: ImageModalProps) => {
  const [currentIndex, setCurrentIndex] = useState(imageIndex);
  const [startX, setStartX] = useState<number | null>(null);
  const [startMouseX, setStartMouseX] = useState<number | null>(null);

  useEffect(() => {
    setCurrentIndex(imageIndex);
  }, [imageIndex]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const transformComponent = document.querySelector('.image-list-section');
    let isZoomedIn = false;

    if (transformComponent) {
      const children = transformComponent.children;
      const childAtIndex = children[currentIndex];
      const itemContent = childAtIndex.querySelector('.react-transform-component');

      if (itemContent) {
        const scale = getComputedStyle(itemContent).transform.split('(')[1].split(')')[0];
        isZoomedIn = parseFloat(scale) > 1;
      }
    }

    if (startX !== null && !isZoomedIn) {
      const currentX = e.touches[0].clientX;
      const diffX = currentX - startX;

      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          handleSlide(-1);
        } else {
          handleSlide(1);
        }
        setStartX(null);
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setStartMouseX(e.clientX);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (startMouseX !== null) {
      const currentMouseX = e.clientX;
      const diffX = currentMouseX - startMouseX;

      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          handleSlide(-1);
        } else {
          handleSlide(1);
        }

        setStartMouseX(null);
      }
    }
  };

  const onClickBullet = (index: number) => {
    setCurrentIndex(index);
  };

  const handleSlide = (index: number) => {
    const nextIndex = currentIndex + index;
    if (nextIndex >= 0 && nextIndex < imageList.length) {
      setCurrentIndex(nextIndex);
    }
  };

  const handlePrevSlide = () => {
    handleSlide(-1);
  };

  const handleNextSlide = () => {
    handleSlide(1);
  };

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShow]);

  return (
    <>
      {!!imageList.length && isShow && (
        <div
          className="fixed flex-col items-center justify-center z-50 w-full h-screen bg-black top-0 max-w-[720px] pb-[24px] overflow-hidden"
          onTouchStart={handleTouchStart}
          onTouchMoveCapture={handleTouchMove}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={() => setStartMouseX(null)}
        >
          <div className="flex justify-end top-0 m-4 cursor-pointer" onClick={() => setIsShow(false)}>
            <XMarkIcon color={colors.gray[0]}></XMarkIcon>
          </div>

          <div className="image-list-section relative max-h-[800px] max-w-[720px] h-full flex justify-center items-center overflow-hidden">
            {imageList?.map((item, index) => (
              <div
                key={index}
                className="flex justify-center items-center w-full"
                style={{
                  width: '100%',
                  position: 'absolute',
                  left: `${(index - currentIndex) * 100}%`,
                  transition: 'left 0.5s ease',
                }}
              >
                <div className="custom-transform-wrapper">
                  <Suspense fallback={<></>}>
                    <TransformWrapper initialScale={1} minScale={1} maxScale={10}>
                      <TransformComponent>
                        <div>
                          <img src={item} alt="이미지" className="rounded-lg w-full object-center"></img>
                        </div>
                      </TransformComponent>
                    </TransformWrapper>
                  </Suspense>
                </div>
              </div>
            ))}
            <NavigateBeforeIcon
              className={`absolute left-0 top-[50%] cursor-pointer text-white ${currentIndex === 0 ? 'invisible' : ''}`}
              onClick={handlePrevSlide}
              fontSize="large"
            />
            <NavigateNextIcon
              className={`absolute right-0 top-[50%] cursor-pointer text-white ${
                currentIndex === imageList.length - 1 ? 'invisible' : ''
              }`}
              onClick={handleNextSlide}
              fontSize="large"
            />
          </div>
          <div className="absolute bottom-8 w-full">
            <div className="flex justify-center">
              {imageList.map((_, index) => (
                <div
                  key={index}
                  className={`w-3 h-3 rounded-full cursor-pointer m-1 ${
                    index === currentIndex ? 'bg-white' : 'bg-gray-400'
                  }`}
                  onClick={() => onClickBullet(index)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageModal;

import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import { createPurchaseAccompanyingServices } from '@/api/AdditionalServices/createAdditionalServices';
import BasicButton from '@/components/Common/Button/BasicButton';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import MenuHeader from '@/components/Header/MenuHeader';
import { PhoneIcon } from '@/components/Icon';
import AccompanyingGuideView from '@/components/More/PurchaseAccompanyingService/AccompanyingGuideView';
import ApplyServiceForm from '@/components/More/PurchaseAccompanyingService/ApplyServiceForm';
import { IS_ALREADY_APPLY_PURCHASE_ACCOMPANYING_SERVICE } from '@/const/additionalService';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import useNavigateBack from '@/hooks/useNavigateBack';
import { memberAtom, updateAdditionalServiceAtom } from '@/store/member';
import { phoneCall } from '@/utils/common';
import { Fab } from '@mui/material';

export interface SelectedVehicleType {
  chatRoomId: number;
  productId: number;
  truckName: string;
}

const PurchaseAccompanyingService = () => {
  const [memberAtomData] = useAtom(memberAtom);
  const [, updateAdditionalService] = useAtom(updateAdditionalServiceAtom);
  const [isAlreadyApply, setIsAlreadyApply] = useState(false);

  const [isNearBottom, setIsNearBottom] = useState(false);
  const threshold = 20;

  const { showToast } = useToastContext();
  const authRedirect = useAuthRedirect();

  const navigateBack = useNavigateBack();
  const location = useLocation();

  const productInfo = location.state?.productInfo;

  const PurchaseAccompanyingServiceRef = useRef<HTMLDivElement>(null);

  const [isPopupShow, setIsPopupShow] = useState(false);
  const [requestForm, setRequestForm] = useState<AdditionalServicesApplyForm>();
  const [isDisabledRequestButton, setIsDisabledRequestButton] = useState(true);

  const updateRequestForm = (formData: AdditionalServicesApplyForm) => {
    setRequestForm(formData);
  };

  useEffect(() => {
    if (memberAtomData) {
      setRequestForm({
        ...requestForm,
        name: { value: memberAtomData?.name, error: false },
        phoneNumber: { value: memberAtomData?.phoneNumber, error: false },
        productId: productInfo?.productId,
        truckName: productInfo?.truckName,
        chatRoomId: productInfo?.chatRoomId,
      });
      setIsAlreadyApply(memberAtomData.isAlreadyApplyPurchaseAccompanyingService);
    }
  }, [memberAtomData, productInfo]);

  const handleScrollBottom = () => {
    const currentScroll = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (currentScroll + viewportHeight < documentHeight) {
      window.scrollBy({
        top: viewportHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + viewportHeight >= documentHeight - threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  const handleClickApplyService = () => {
    if (!authRedirect('/purchase-accompanying-service')) {
      return;
    }

    setIsPopupShow(true);
  };

  const handleClickClosePopup = () => {
    setIsPopupShow(false);
  };

  const handleClickOkBtn = () => {
    if (requestForm) {
      setIsPopupShow(false);
      const requestData = {
        name: requestForm.name?.value,
        phoneNumber: requestForm.phoneNumber?.value,
        productId: requestForm.productId,
      };
      postPurchaseAccompanyingServices.mutate(requestData);
    }
  };

  const postPurchaseAccompanyingServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createPurchaseAccompanyingServices(request),
    {
      onSuccess: () => {
        showToast('차량 구매 동행 서비스를 신청했어요.', 'success', 'bottom');
        setIsAlreadyApply(true);
        updateAdditionalService(IS_ALREADY_APPLY_PURCHASE_ACCOMPANYING_SERVICE);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickPrev = () => {
    navigateBack();
  };

  useEffect(() => {
    setIsDisabledRequestButton(requestForm?.name?.error || requestForm?.phoneNumber?.error || false);
  }, [requestForm?.name?.error || requestForm?.phoneNumber?.error]);

  return (
    <>
      <MenuHeader title="구매 동행 서비스" onClickPrevBtn={handleClickPrev}></MenuHeader>

      <div className="w-full pt-[60px] pb-[100px]" ref={PurchaseAccompanyingServiceRef}>
        <AccompanyingGuideView></AccompanyingGuideView>
        <ApplyServiceForm
          formData={requestForm || {}}
          updateFormData={(data: AdditionalServicesApplyForm) => updateRequestForm(data)}
        ></ApplyServiceForm>

        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
          <div className="absolute right-[16px] top-[-72px]">
            <Fab
              sx={{
                backgroundColor: colors.gray[0],
                width: 52,
                height: 52,
                borderRadius: '50px',
                '&:hover': {
                  backgroundColor: colors.gray[0],
                },
                zIndex: 100,
              }}
              onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
            >
              <PhoneIcon color={colors.primary}></PhoneIcon>
            </Fab>
          </div>

          <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
          <BasicButton
            name={isAlreadyApply ? '서비스 신청완료' : isNearBottom ? '서비스 신청하기' : '아래로 내리기'}
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight={'bold'}
            isDisabled={isAlreadyApply || (isNearBottom && isDisabledRequestButton)}
            onClick={isNearBottom ? handleClickApplyService : handleScrollBottom}
          />
        </div>
      </div>

      <ConfirmModal
        isOpen={isPopupShow}
        title={`${!!requestForm?.productId ? requestForm?.truckName : ''}`}
        content={
          <span>
            {requestForm?.productId ? (
              <>
                선택한 차량으로 차량 구매 동행
                <br />
                서비스를 신청할까요?
              </>
            ) : (
              <>
                구매 동행 서비스를
                <br />
                신청하시겠어요?
              </>
            )}
          </span>
        }
        rightButtonLabel="신청하기"
        onLeftButtonClick={handleClickClosePopup}
        onRightButtonClick={handleClickOkBtn}
      />
    </>
  );
};

export default PurchaseAccompanyingService;

import React, { SVGProps } from 'react';

const SvgBellColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#FFDC5F"
      fillRule="evenodd"
      d="M19.998 7.086a8.94 8.94 0 0 0-8.94 8.94v.994c0 2.812-1.061 5.374-2.807 7.311a.993.993 0 0 0 .02 1.35c4.164 1.735 13.7 3.873 23.455 0a.994.994 0 0 0 .019-1.35 10.884 10.884 0 0 1-2.806-7.311v-.994A8.94 8.94 0 0 0 20 7.086Zm4.793 22.173c-3.428.427-6.728.321-9.597-.048a4.969 4.969 0 0 0 9.597.048Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFDC5F"
      d="M32.373 15.861c.53.037.996-.364.99-.896a12 12 0 0 0-7.193-10.87c-.488-.213-1.04.058-1.213.561-.173.504.097 1.049.582 1.27a10.072 10.072 0 0 1 5.893 8.904c.013.532.41.994.94 1.031ZM7.728 15.883c-.531.036-.996-.365-.99-.898a12 12 0 0 1 7.209-10.86c.488-.212 1.039.06 1.212.563.172.504-.099 1.048-.584 1.269a10.07 10.07 0 0 0-5.905 8.896c-.014.533-.41.994-.942 1.03Z"
    />
    <circle cx={20} cy={6.932} r={2} fill="#FFDC5F" />
  </svg>
);
SvgBellColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgBellColorIcon;

import React, { ReactNode, forwardRef } from 'react';

import { colors } from '@/const/colors';
import '@/css/inputStyle.css';

interface TextInputLabelLeftProps {
  name: string;
  label: string;
  placeholder: string;
  value: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  maxHeight?: number;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  type?: string;
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  suffix?: ReactNode;
  required?: boolean;
  maxLength?: number;
  bottomPadding?: number;
  minWidth?: number;
  tooltip?: ReactNode;
}

const TextInputLabelLeft = forwardRef<HTMLInputElement, TextInputLabelLeftProps>(
  (
    {
      name,
      label,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      error,
      errorMsg,
      fontSize = 16,
      width,
      height,
      readOnly = false,
      type = 'text',
      inputMode = 'text',
      onKeyDown,
      disabled = false,
      suffix,
      required,
      maxLength,
      bottomPadding = 4,
      minWidth = 100,
      tooltip,
    },
    ref,
  ) => (
    <div className={`relative w-full border-b border-gray-3 pb-${bottomPadding}`}>
      <div className="flex justify-center items-center">
        <label className={`text-base min-w-[${minWidth}px]`}>
          <span className="font-medium text-gray-7 align-middle">{label}</span>
          {required && <span className="font-normal text-red ml-1 align-middle">(필수)</span>}
          {tooltip}
        </label>
        <input
          className="simple-input"
          ref={ref}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          readOnly={readOnly}
          type={type}
          style={{
            fontSize: `${fontSize}px`,
            width: '100%',
            height: height ? `${height}px` : '36px',
            padding: '8px 4px',
            backgroundColor: disabled ? colors.gray[1] : 'transparent',
            color: disabled ? colors.gray[5] : colors.gray[8],
            borderRadius: '0px',
          }}
          inputMode={inputMode}
          onKeyDown={onKeyDown}
          disabled={disabled}
          maxLength={maxLength}
        ></input>
        <span className="absolute top-2 right-2 text-gray-8">{suffix}</span>
      </div>

      {error && (
        <p className={`absolute text-red text-[13px] xxs:text-[12px] ${height ? `top-[${height}px]` : 'top-[36px]'}`}>
          {errorMsg}
        </p>
      )}
    </div>
  ),
);
export default TextInputLabelLeft;

import React from 'react';

import Error from '@/components/Error/Error';
import MenuHeader from '@/components/Header/MenuHeader';
import { ErrorCircleIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

const CommonError = () => {
  return (
    <>
      <MenuHeader title="" />
      <div className="w-full flex flex-col justify-center items-center">
        <Error
          icon={<ErrorCircleIcon color={colors.gray[5]} width={144} height={144}></ErrorCircleIcon>}
          title="요청에 실패했습니다"
          description="잘못된 요청입니다.<br/>정상적인 경로로<br/>다시 시도해주세요."
        />
      </div>
    </>
  );
};

export default CommonError;

import React, { SVGProps } from 'react';

const SvgCarPaymentColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 36 36" {...props}>
    <rect width={26.571} height={17.714} x={6.93} y={11.357} fill="#737373" rx={3} />
    <rect width={26.571} height={17.714} x={2.5} y={6.929} fill="#34A853" rx={3} />
    <path
      fill="#FAFAFA"
      d="M10.988 16.134H9.5v-1.268h1.183L9.753 11h2.046l.777 3.866h1.103L14.576 11h1.848l.897 3.866h1.11L19.2 11h2.046l-.936 3.866H21.5v1.268h-1.495L19.068 20h-2.166l-.864-3.866h-1.09L14.085 20h-2.166l-.93-3.866Zm2.06 1.094h.08l.252-1.094h-.551l.22 1.094Zm2.711-2.362-.226-1.007h-.08l-.225 1.007h.531Zm2.113 2.374h.08l.22-1.106h-.559l.26 1.106Z"
    />
  </svg>
);
SvgCarPaymentColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCarPaymentColorIcon;

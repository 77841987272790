import React, { SVGProps } from 'react';

const SvgSaleTruckOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M1.332 6a2 2 0 0 1 2-2H15.33a2 2 0 0 1 2 2v2h1.36a2 2 0 0 1 1.56.751l1.975 2.467a2 2 0 0 1 .44 1.25v2.865a2 2 0 0 1-2 2h-.669a2.666 2.666 0 1 1-5.333 0H7.998a2.666 2.666 0 1 1-5.33-.112 2 2 0 0 1-1.336-1.89v-9.33Zm1.725 9.94a2.666 2.666 0 0 1 4.584.059h7.38c.234-.405.57-.742.975-.976V6a.667.667 0 0 0-.666-.666H3.332A.667.667 0 0 0 2.665 6v9.332a.666.666 0 0 0 .392.607Zm14.273-1.275a2.666 2.666 0 0 1 2.309 1.333h1.024a.667.667 0 0 0 .666-.666v-2.867a.666.666 0 0 0-.147-.416L19.21 9.583a.666.666 0 0 0-.52-.25h-1.36v5.332ZM5.333 15.998a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666Zm11.998 0a1.333 1.333 0 1 0 .002 2.666 1.333 1.333 0 0 0-.003-2.666h.001Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M12.808 10.16h-.61l-.554 2.516c-.047.334-.311.547-.717.547-.359 0-.67-.117-.774-.462l-.809-2.6H9.22l-.68 2.556c-.082.27-.264.506-.67.506s-.786-.16-.83-.462l-.623-2.6h-.658c-.23 0-.403-.246-.403-.419 0-.334.082-.591.324-.591h.485l-.242-1.256c-.057-.29.082-.56.38-.644.277-.076.61 0 .658.31l.264 1.59h1.362l.16-.709c.048-.225.196-.418.45-.418.3 0 .45.108.532.333l.255.794h1.328l.23-1.376c.021-.205.229-.44.6-.44.299 0 .497.334.44.689L12.34 9.15h.567c.242 0 .45.29.45.503 0 .343-.23.507-.545.507h-.003Zm-5.401 0 .402 1.804.463-1.803h-.865Zm2.932 0 .497 1.72.346-1.72h-.843Z"
    />
  </svg>
);
SvgSaleTruckOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSaleTruckOutlineIcon;

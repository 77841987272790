// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speech-bubble {
  position: relative;
  background-color: var(--bubble-bg-color);
  border-radius: var(--bubble-border-radius);
  padding: var(--bubble-padding, 10px 11px);
  text-align: center;
  display: block;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: var(--bubble-bg-color);
  border-bottom: 0;
  margin-left: -9px;
  margin-bottom: -14px;
  border-width: 18px 8px 0 8px;
}

.speech-bubble.right-edge:after {
  content: '';
  position: absolute;
  left: var(--bubble-width);
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left: 18px solid var(--bubble-bg-color);
  border-right: 0;
  margin-top: -9px;
  margin-right: -14px;
}
`, "",{"version":3,"sources":["webpack://./src/css/bubbleStyle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wCAAwC;EACxC,0CAA0C;EAC1C,yCAAyC;EACzC,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,wCAAwC;EACxC,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,QAAQ;EACR,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,8CAA8C;EAC9C,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".speech-bubble {\n  position: relative;\n  background-color: var(--bubble-bg-color);\n  border-radius: var(--bubble-border-radius);\n  padding: var(--bubble-padding, 10px 11px);\n  text-align: center;\n  display: block;\n}\n\n.speech-bubble:after {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  width: 0;\n  height: 0;\n  border: 20px solid transparent;\n  border-top-color: var(--bubble-bg-color);\n  border-bottom: 0;\n  margin-left: -9px;\n  margin-bottom: -14px;\n  border-width: 18px 8px 0 8px;\n}\n\n.speech-bubble.right-edge:after {\n  content: '';\n  position: absolute;\n  left: var(--bubble-width);\n  top: 50%;\n  width: 0;\n  height: 0;\n  border: 10px solid transparent;\n  border-left: 18px solid var(--bubble-bg-color);\n  border-right: 0;\n  margin-top: -9px;\n  margin-right: -14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import MenuHeader from '@/components/Header/MenuHeader';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import CommonError from '@/pages/Error/CommonError';
import { getTerm } from '@/api/public';
import NotFoundPage from '@/pages/Error/NotFoundPage';

const typeList = {
  USE_TERMS: '서비스 이용약관',
  PERSONAL_INFO_TERMS: '개인정보 처리방침',
  MARKETING: '마케팅 정보 수신 동의 약관',
};

const TermsDetail = () => {
  const { type } = useParams<{ type?: string }>();
  const { state } = useLocation();

  if (type && !(type in typeList)) {
    return <NotFoundPage />;
  }

  const getTerms = async () => {
    const termsType = type;
    try {
      const response = await getTerm();
      const res: Terms[] = response.data;
      const contentsData = res.find((data) => data.termsType.code === termsType)?.contents;
      return contentsData;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const {
    data: termsData,
    isError,
    error,
    isLoading,
  } = useQuery(['get-terms', type], getTerms, {
    enabled: !state?.contents,
  });

  if (isError)
    return (
      <>
        <CommonError></CommonError>
      </>
    );

  const contents = state?.contents || termsData;

  const title = typeList[type as keyof typeof typeList] || 'Unknown Title';

  return (
    <>
      <MenuHeader title={title}></MenuHeader>
      {isLoading && <LoadingSpinner text={'로딩중입니다.'}></LoadingSpinner>}
      <div className="w-full p-4 pt-[60px] pb-[20px]">
        <div dangerouslySetInnerHTML={{ __html: contents }}></div>
      </div>
    </>
  );
};

export default TermsDetail;

// example axios wrapper
import { useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getNotifications } from '@/api/public';
import TabsWithUnreadBadge from '@/components/Common/Tabs/TabsWithUnreadBadge';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import ActivityNotification from '@/components/Notification/ActivityNotification';
import InterestNotification from '@/components/Notification/InterestNotification';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useWebSocket from '@/hooks/useWebSocket';
import CommonError from '@/pages/Error/CommonError';
import { isExistNotReadNotificationAtom } from '@/store/notification';

const NotificationList = () => {
  const navigate = useNavigate();
  const { member } = useMemberContext();
  const { showToast } = useToastContext();
  const queryClient = useQueryClient();
  const id = member?.id;
  const setIsExistNotReadNotification = useSetAtom(isExistNotReadNotificationAtom);

  const [tabNum, setTabNum] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const HASH_ACTIVITY = 'activity';
  const HASH_INTEREST = 'interest';

  const getMyNotifications = async () => {
    try {
      const response = await getNotifications();
      const res: NotificationsResponse = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data, isError, error, isLoading, isFetching, isSuccess, refetch } = useQuery(
    ['get-notification'],
    getMyNotifications,
    {
      onSuccess: (data) => {
        handleHashChange();
      },
      refetchOnWindowFocus: false,
    },
  );

  const handleHashChange = () => {
    const hash = window.location.hash.substring(1);
    if (hash === HASH_ACTIVITY) {
      setTabNum(0);
    } else if (hash === HASH_INTEREST) {
      setTabNum(1);
    }
  };

  const handleTabChange = (newValue: number) => {
    setTabNum(newValue);
    const newHash = newValue === 0 ? HASH_ACTIVITY : HASH_INTEREST;
    navigate(`#${newHash}`, { replace: true });
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    handleHashChange();
  }, []);

  const onClickRightBtn = () => {
    setIsEdit(!isEdit);
  };

  const socket = useWebSocket(`/sub/members/${id}`, {
    onMessage: (message) => {
      try {
        const body = JSON.parse(message?.body);
        if (body.changeType === 'INTEREST_PRODUCT_NOTIFICATION') {
          let messageData: InterestProductNotification = body.data;
          const currentData = queryClient.getQueryData<NotificationsResponse>(['get-notification']);
          if (!currentData) return;

          const newData: NotificationsResponse = {
            ...currentData,
            interestProductNotifications: [messageData.memberNotification, ...currentData.interestProductNotifications],
            notReadInterestProductNotificationCount: currentData.notReadInterestProductNotificationCount + 1,
          };
          queryClient.setQueryData(['get-notification'], newData);

          setIsExistNotReadNotification(true);
        } else if (body.changeType === 'ACTIVITY_NOTIFICATION') {
          const messageData = body.data;

          const currentData = queryClient.getQueryData<NotificationsResponse>(['get-notification']);
          if (!currentData) return;
          const newData: NotificationsResponse = {
            ...currentData,
            activityNotifications: [messageData.memberNotification, ...currentData.activityNotifications],
            notReadActivityNotificationCount: currentData.notReadActivityNotificationCount + 1,
          };

          queryClient.setQueryData(['get-notification'], newData);

          setIsExistNotReadNotification(true);
        }
      } catch (error) {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      }
    },
  });

  // 조건부 렌더링을 return 문 외부로 이동
  let content;
  if (isError) {
    content = <CommonError />;
  } else {
    content = (
      <div className="w-full h-full">
        <div className="bg-white top-[60px] w-full max-w-[720px] fixed z-10">
          <TabsWithUnreadBadge
            value={tabNum}
            leftLabel="활동 알림"
            rightLabel="관심 차량 알림"
            leftCount={data?.notReadActivityNotificationCount || 0}
            rightCount={data?.notReadInterestProductNotificationCount || 0}
            onChange={handleTabChange}
          ></TabsWithUnreadBadge>
        </div>
        <div style={{ marginTop: '60px' }}>
          {tabNum === 0 && (
            <ActivityNotification
              isEditMode={isEdit}
              data={data?.activityNotifications || []}
              notReadNotificationCount={data?.notReadActivityNotificationCount || 0}
              isLoading={isLoading || isFetching}
            />
          )}
          {tabNum === 1 && (
            <InterestNotification
              isEditMode={isEdit}
              data={data?.interestProductNotifications || []}
              notReadNotificationCount={data?.notReadInterestProductNotificationCount || 0}
              isLoading={isLoading || isFetching}
            />
          )}
        </div>
      </div>
    );
  }

  const menuHeaderIcons: MenuHeaderIcon[] = [{ icon: isEdit ? <>닫기</> : <>편집</>, onClickIcon: onClickRightBtn }];

  return (
    <>
      <MenuHeader title="알림" isMain={false} icons={menuHeaderIcons} path="/"></MenuHeader>
      {content}
    </>
  );
};
export default NotificationList;

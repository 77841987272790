import React, { SVGProps } from 'react';

const SvgTruckFrontSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <g fill={props.color || 'currentColor'} clipPath="url(#truck-front-solid-icon_svg__a)">
      <path d="M29.602 18.886h.265c.57 0 .988-.456.988-.988v-6.612a.981.981 0 0 0-.988-.988h-.265c-.038 0-.076 0-.114.038v-1.52a2.027 2.027 0 0 0-2.014-2.014H10.488a2.027 2.027 0 0 0-2.015 2.014v1.52c-.038 0-.076-.038-.114-.038h-.266a.981.981 0 0 0-.988.988v6.574c0 .57.456.988.988.988h.266c.038 0 .076 0 .114-.038v5.738h-.38a.981.981 0 0 0-.988.988v1.71c0 .57.456.988.988.988h1.292v1.938c0 .57.456.988.989.988h2.545c.57 0 .989-.456.989-.988v-1.938h10.184v1.938c0 .57.456.988.988.988h2.508c.57 0 .988-.456.988-.988v-1.938h1.291c.57 0 .988-.456.988-.988v-1.71a.981.981 0 0 0-.988-.988h-.38v-5.7c.077 0 .077.038.115.038ZM10.184 10.07c0-.57.456-.988.988-.988h15.655c.57 0 .988.456.988.988v7.106c0 .57-.456.988-.988.988H11.171a.981.981 0 0 1-.987-.988V10.07Zm3.458 13.718h-2.204a.981.981 0 0 1-.989-.988c0-.532.457-.988.989-.988h2.204c.57 0 .988.456.988.988a.981.981 0 0 1-.989.988Zm8.701 1.672h-6.688v-.684h6.65v.684h.038Zm0-2.014h-6.688V22.8h6.65v.646h.038Zm0-2.014h-6.688v-.684h6.65v.684h.038ZM23.37 22.8c0-.57.457-.988.989-.988h2.203c.57 0 .988.456.988.988s-.456.988-.988.988h-2.203a.981.981 0 0 1-.989-.988Z" />
      <path d="M15.184 20.184h7.651v6.596h-7.651z" />
    </g>
    <defs>
      <clipPath id="truck-front-solid-icon_svg__a">
        <path fill="#fff" d="M0 0h38v38H0z" />
      </clipPath>
    </defs>
  </svg>
);
SvgTruckFrontSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgTruckFrontSolidIcon;

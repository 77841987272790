import React, { SVGProps } from 'react';

const SvgZigtruckCircleColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 52 52" {...props}>
    <rect width={52} height={52} fill="#1E42A6" rx={26} />
    <g fill="#fff" clipPath="url(#zigtruck-circle-color-icon_svg__a)">
      <path d="M5.82 19.249v2.058h3.01S8.32 23.621 5 25.724l1.328 1.671s1.185-.376 3.044-2.678l2.214 2.202 1.56-1.513-2.435-2.549s.83-1.372.83-3.608H5.82ZM9.39 29.061v2.092h5.236s-.025 2.042-1.278 3.495l1.926 1.204S17.116 33.693 17 29.06H9.39ZM14.906 27.395h2.258v-8.993s.044-.318.371-.415h26.792s.348.028.437.37v4.187H42.43v1.69h2.334v3.337H47V16.84s-.072-.692-.736-.841H15.736s-.675.066-.83.874v10.521Z" />
      <path d="M26.61 19.016h7.338v1.943h-5.263v1.428h5.263v1.793h-5.241v1.577h5.24v1.814H26.61v-8.555ZM35.41 19.016v1.926h3.775v1.44H35.41v5.189h6.01v-1.814h-3.885v-1.544h3.885v-5.197h-6.01ZM18.77 29.061v2.103h25.84s.074 1.849-1.287 3.476l1.917 1.195S47 34.015 47 29.061H18.77Z" />
    </g>
    <defs>
      <clipPath id="zigtruck-circle-color-icon_svg__a">
        <path fill="#fff" d="M5 16h42v19.852H5z" />
      </clipPath>
    </defs>
  </svg>
);
SvgZigtruckCircleColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgZigtruckCircleColorIcon;

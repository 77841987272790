import React from 'react';

const Offline = () => {
  return (
    <div className="min-w-[320px]">
      <div className="w-full flex justify-center items-center">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_offline_cover.png"
          alt="오프라인 차량 확인 커버"
          className="w-full"
          loading="lazy"
        />
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">실차 확인하는 방법</h3>
        <p className="pb-8 leading-8">
          차량을 확인할 때는 가급적 밝은날에 하시는게 좋고 공단의 공영 주차장이나 넓은 공터 등 차량을 세워 놓고 여유롭게
          둘러볼 수 있는 곳이 좋습니다.
        </p>
        <p className="pb-8 leading-8">
          차량을 확인할땐 사고나 침수가 있었는지 꼼꼼하게 확인하셔야 하고, 만약 수리할 부분이 있다면 판매자와 수리
          비용에 관한 협의가 필요할 수 있습니다.
          <br />그 외 체크해야 할 항목은 엔진룸, 차량 실내, 타이어 상태, 가변축 작동 여부 등이 있어요. 특장(윙바디,탑차
          등)의 기능적인 부분, 작동 상태도 문제가 없는지 확인해야 합니다. 또한 차량의 특장 사이즈 측정을 위해 줄자처럼
          길이를 잴 수 있는 도구를 이용해 외측,내측 사이즈에 이상이 없는지 확인해야 해요.
        </p>
        <p className="pb-8 leading-8">
          특장(윙바디,탑차 등)의 기능적인 부분, 작동 상태도 문제가 없는지 확인해야 합니다. 또한 차량의 특장 사이즈
          측정을 위해 줄자처럼 길이를 잴 수 있는 도구를 이용해 외측,내측 사이즈에 이상이 없는지 확인해야 해요.
        </p>
        <p className="pb-8 leading-8">
          또한 차량의 특장 사이즈 측정을 위해 줄자처럼 길이를 잴 수 있는 도구를 이용해 외측,내측 사이즈에 이상이 없는지
          확인해야 해요.
        </p>
      </div>

      <div className="text-gray-700 border-b-4 border-gray-100 pt-4 pb-28 px-4">
        <h3 className="font-semibold text-xl py-4">실차 확인 시 주의사항</h3>
        <p className="pb-8 leading-8">중고화물차를 전문가 수준으로 평가하는 것은 쉽지 않아요.</p>
        <p className="pb-8 leading-8">
          직트럭에서는 매물 등록 시 차량의 상세 정보 뿐만 아니라 사고 여부, 수리 여부, 운송 물품, 운행 구간 등에 대한
          정보를 판매자에게 기입하도록 요청하고 있어요.
        </p>
        <p className="pb-8 leading-8">
          상세 정보란에 이러한 내용이 누락된 경우, 꼭 판매자에게 해당 정보를 문의해야 합니다.
          <br /> 또한, 구매 의사가 있다면 수리 비용이 크게 발생될 수 있는 부분을 주의 깊게 살펴보아야 합니다.
        </p>
      </div>
    </div>
  );
};
export default Offline;

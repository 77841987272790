import React, { useState } from 'react';

import NotificationTimeSetting from '../More/NotificationTimeSetting';
import BottomSheetPopup from './Popup/BottomSheetPopup';
import { convertToAMPM } from '@/utils/common';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Switch from '@mui/material/Switch';

export type SwitchProps = {
  keyName: string;
  title: string;
  desc?: string;
  date?: string;
  checked?: boolean;
  timeSetting?: boolean;
  time?: number;
  handleChange: (key: string, checked: boolean) => void;
};

const SettingSwitch = ({ keyName, title, desc, date, checked, timeSetting, time, handleChange }: SwitchProps) => {
  const [isShowTimeSettingPopup, setIsShowSettingPopup] = useState(false);

  const changeIsShow = (isShowTimeSettingPopup: boolean) => {
    setIsShowSettingPopup(isShowTimeSettingPopup);
  };

  const switchStyles = {
    '&.MuiSwitch-root': {
      '& .MuiSwitch-track': {
        color: checked ? 'white' : 'red',
        backgroundColor: checked ? '#1E42A6' : '#E5E7EB',
      },
    },
    display: 'flex',
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#E5E7EB',
      boxSizing: 'border-box',
    },
  };

  const onClickTimeSetting = () => {
    if (timeSetting) {
      setIsShowSettingPopup(true);
    }
  };

  return (
    <>
      <div className="flex items-center px-4 py-2" onClick={onClickTimeSetting}>
        <span className="flex-1">
          <p className="text-[#414141] font-semibold mb-1">{title}</p>
          <p className="text-[#919191] mb-1">{desc}</p>
          <p className="text-[#919191] text-sm">{date}</p>
        </span>
        {checked != null && (
          <Switch
            checked={checked}
            onChange={() => handleChange(keyName, !checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={switchStyles}
          />
        )}
        {timeSetting && (
          <>
            {time != null && <span className="text-base text-gray-8">{convertToAMPM(time)}</span>}
            <ArrowForwardIosRoundedIcon fontSize="medium" className="p-1 text-gray-300"></ArrowForwardIosRoundedIcon>
          </>
        )}
      </div>
      <BottomSheetPopup
        isOpen={!!timeSetting && !!isShowTimeSettingPopup}
        onClose={() => setIsShowSettingPopup(false)}
        title="시간 설정"
      >
        <NotificationTimeSetting defaultTime={time} onClosePopup={() => setIsShowSettingPopup(false)} />
      </BottomSheetPopup>
    </>
  );
};

export default SettingSwitch;

import React, { SVGProps } from 'react';

const SvgDeliveryTruckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M6.063 8.563h.625v.624a.938.938 0 0 0 1.875 0v-.624h.624a.938.938 0 0 0 0-1.876h-.624v-.625a.938.938 0 0 0-1.876 0v.625h-.625a.938.938 0 0 0 0 1.875ZM21.359 13.784l-2.393-2.393a2.2 2.2 0 0 0-1.546-.641h-1.67v-.313a.938.938 0 0 0-1.875 0v8.126H9.314A2.5 2.5 0 0 0 7 17a2.5 2.5 0 0 0-2.314 1.563h-.498a.316.316 0 0 1-.313-.313v-4.063a.938.938 0 0 0-1.875 0v4.063a2.19 2.19 0 0 0 2.188 2.188h.498A2.5 2.5 0 0 0 7 22a2.5 2.5 0 0 0 2.314-1.563h5.372A2.5 2.5 0 0 0 17 22c1.161 0 2.131-.8 2.411-1.875h.401A2.19 2.19 0 0 0 22 17.937V15.33a2.17 2.17 0 0 0-.641-1.546ZM7 20.125a.626.626 0 0 1 0-1.25.626.626 0 0 1 0 1.25Zm10 0a.626.626 0 0 1 0-1.25.626.626 0 0 1 0 1.25Zm3.125-2.188c0 .173-.14.313-.313.313h-.66A2.492 2.492 0 0 0 17 17c-.457 0-.881.133-1.25.348v-4.723h1.67a.32.32 0 0 1 .221.091l2.393 2.393a.313.313 0 0 1 .091.221v2.607Z"
    />
    <path
      fill={props.color || 'currentColor'}
      d="M7.625 13.25a5.632 5.632 0 0 0 5.625-5.625A5.632 5.632 0 0 0 7.625 2 5.632 5.632 0 0 0 2 7.625a5.632 5.632 0 0 0 5.625 5.625Zm0-9.375a3.755 3.755 0 0 1 3.75 3.75 3.755 3.755 0 0 1-3.75 3.75 3.755 3.755 0 0 1-3.75-3.75 3.755 3.755 0 0 1 3.75-3.75Z"
    />
  </svg>
);
SvgDeliveryTruckIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgDeliveryTruckIcon;

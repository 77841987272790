import React from 'react';

import {
  BellColorIcon,
  IncreasingTrendGraphColorIcon,
  LockedShieldColorIcon,
  TruckPreventionColorIcon,
} from '@/components/Icon';

const DirectGuide = () => {
  return (
    <>
      <main className="flex flex-col w-full">
        <div className="bg-gray-1 py-[30px] px-6">
          <div className="flex flex-col sm680:flex-row sm680:justify-between">
            <div className="text-center sm680:text-left">
              <h1 className="hidden font-medium text-[16px] leading-[20px] text-gray-8 mb-2">직거래 셀프 판매</h1>
              <p className="text-[18px] leading-[22px] text-gray-8 break-keep">
                직트럭에서 딜러없이
                <br />
                <span className="text-[20px] leading-[24px] font-bold ">500만원 더 높게 판매하세요</span>
              </p>
              <p className="font-medium text-[12px] text-gray-7 mt-1">(대형화물차 평균 판매 금액 기준)</p>
            </div>

            <div className="flex sm280:flex-col items-center justify-center gap-2 pt-6 pb-2">
              <div className="flex justify-center items-center px-3 py-[10px] bg-blue-10 rounded-[20px] font-semibold text-[12px]">
                #판매 수수료 없는 무료 등록
              </div>
              <div className="flex justify-center items-center px-3 py-[10px] bg-blue-10 rounded-[20px] font-semibold text-[12px]">
                #평균 10일 이내 판매완료
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center py-[46px]">
          <p className="font-bold text-[20px] leading-[24px] text-gray-8 text-center mb-6">
            직거래 판매가 합리적인 이유
          </p>
          <div className="text-gray-8 text-[14px] leading-[18px] text-center font-semibold">
            직거래는 판매자, 구매자가
            <br className="sm280:block hidden" />
            직접 거래하기 때문에
            <br />
            <span className="text-primary">
              중간마진
              <span className="text-[12px] font-medium">
                (딜러마진, 상품화비용, <br className="sm280:block hidden" />
                부대수수료 등)
              </span>
              이 없는
              <br className="sm280:hidden sm680:hidden" /> 합리적인
              <br className="hidden sm280:block" /> 거래가 가능합니다.
            </span>
          </div>
        </div>
        <div className="bg-gray-1 p-[46px] sm280:px-[31px]">
          <p className="text-grayt-8 font-bold text-[20px] text-center">직트럭 제공 서비스</p>
          <div className="sm680:grid sm680:grid-cols-2 sm680:gap-1">
            <dl className="flex mt-[34px]">
              <IncreasingTrendGraphColorIcon width={40} height={40} />
              <div className="ml-2">
                <dt className="text-gray-7 text-[14px] leading-[18px] sm280:text-[12px] sm280:leading-[16px] font-semibold">
                  거래 데이터 기반
                </dt>
                <dd className="text-gray-8 text-[14px] leading-[18px] sm280:text-[12px] sm280:leading-[16px] font-semibold">
                  내 차량의 적정 시세 확인 가능
                </dd>
              </div>
            </dl>
            <dl className="flex mt-[34px]">
              <LockedShieldColorIcon width={40} height={40} />
              <div className="ml-2">
                <dt className="text-gray-7 text-[14px] leading-[18px] sm280:text-[12px] sm280:leading-[16px] font-semibold">
                  안심번호로 개인 정보 보호
                </dt>
                <dd className="text-gray-8 text-[14px] leading-[18px] sm280:text-[12px] sm280:leading-[16px] font-semibold">
                  구매자와 안전하게 전화 연결 가능
                </dd>
              </div>
            </dl>
            <dl className="flex mt-[34px]">
              <BellColorIcon width={40} height={40} />
              <div className="ml-2">
                <dt className="text-gray-7 text-[14px] leading-[18px] sm280:text-[12px] sm280:leading-[16px] font-semibold">
                  빠른 거래를 위한 맞춤 알림 서비스
                </dt>
                <dd className="text-gray-8 text-[14px] leading-[18px] sm280:text-[12px] sm280:leading-[16px] font-semibold">
                  유사 차량을 찾는 고객에게 알림 전송
                </dd>
              </div>
            </dl>
            <dl className="flex mt-[34px]">
              <TruckPreventionColorIcon width={40} height={40} />
              <div className="ml-2">
                <dt className="text-gray-7 text-[14px] leading-[18px] font-semibold sm280:text-[12px] sm280:leading-[16px]">
                  국토부 차량 정보를 반영
                </dt>
                <dd className="text-gray-8 text-[14px] leading-[18px] font-semibold sm280:text-[12px] sm280:leading-[16px]">
                  무분별한 허위 매물 방지까지
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </main>
    </>
  );
};

export default DirectGuide;

import React from 'react';
import { Link } from 'react-router-dom';

import {
  CalendarCheckOutlineIcon,
  ChevronRightIcon,
  HeartOutlineIcon,
  LicenseSettingOutlineIcon,
  SimpleTruckOutlineIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';

export default function ManagementMenus({}) {
  return (
    <div className="px-4">
      <ul>
        <li className="list-none py-5">
          <Link to={'/products/my'} className="flex items-center cursor-pointer">
            <SimpleTruckOutlineIcon color={colors.gray[7]} width={28} height={28}></SimpleTruckOutlineIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">내차 관리</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="list-none py-5">
          <Link to={'/license/my'} className="flex items-center  cursor-pointer">
            <LicenseSettingOutlineIcon color={colors.gray[7]} width={28} height={28}></LicenseSettingOutlineIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">내 번호판 관리</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="list-none py-5">
          <Link to={'/interest'} className="flex items-center  cursor-pointer">
            <HeartOutlineIcon color={colors.gray[7]} width={28} height={28}></HeartOutlineIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">찜한 차량</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="list-none py-5">
          <Link to={'/drive'} className="flex items-center  cursor-pointer">
            <CalendarCheckOutlineIcon color={colors.gray[7]} width={28} height={28}></CalendarCheckOutlineIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">운행 일지</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
      </ul>
    </div>
  );
}

import React, { SVGProps } from 'react';

const SvgCheckCircleBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M1 15C1 7.268 7.268 1 15 1s14 6.268 14 14-6.268 14-14 14S1 22.732 1 15Zm19.184-2.605a1.077 1.077 0 0 0-1.753-1.252l-4.645 6.504-2.332-2.332a1.077 1.077 0 0 0-1.523 1.523l3.23 3.231a1.077 1.077 0 0 0 1.638-.135l5.385-7.539Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgCheckCircleBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCheckCircleBoldIcon;

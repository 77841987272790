import { useNavigate } from 'react-router-dom';

const useAuthRedirect = () => {
  const navigate = useNavigate();

  const authRedirect = (redirectUrl?: string) => {
    if (!localStorage.getItem('token')) {
      navigate('/login', { state: { redirectUrl: redirectUrl } });
      return false; // 로그인되어 있지 않음
    }

    return true; // 로그인되어 있음
  };

  return authRedirect;
};

export default useAuthRedirect;

import React from 'react';

import ChevronRightIcon from '../Icon/ChevronRightIcon';
import { colors } from '@/const/colors';
import { formatNumber } from '@/utils/common';

export interface RepairItemViewProps {
  repairHistory?: RepairHistory;
}

const RepairItemView = ({ repairHistory }: RepairItemViewProps) => {
  return (
    <>
      <div className="flex justify-between items-center pb-3">
        <div className="flex items-center">
          <span className="text-gray-8 font-semibold mr-[6px] text-lg">수리비</span>
        </div>
        <ChevronRightIcon color={colors.gray[7]}></ChevronRightIcon>
      </div>

      <div className="rounded-lg bg-gray-1 px-4 py-2">
        {repairHistory?.repairItems?.map((item, index) => (
          <dl className="flex items-center py-[8px] font-normal" key={index}>
            <dt className="flex-1 text-gray-7 text-base pr-4 text-ellipsis break-all line-clamp-1">{item.name}</dt>
            <dd className={`text-gray-8 flex items-center`}>{formatNumber(item.price?.toString())}원</dd>
          </dl>
        ))}
      </div>
    </>
  );
};

export default RepairItemView;

import React, { ReactNode, useEffect, useState } from 'react';

import UnreadCountBadge from '../Badge/UnReadCountBadge';
import CustomTabPanel from '@/components/Common/Tabs/CustomTabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface TabsWithUnreadBadgeProps {
  children?: ReactNode;
  leftLabel: string;
  leftCount: number;
  rightLabel: string;
  rightCount: number;
  value: number;
  onChange?: (newValue: number) => void | boolean;
}

const TabsWithUnreadBadge = ({
  children,
  leftLabel,
  leftCount,
  rightLabel,
  rightCount,
  value,
  onChange,
}: TabsWithUnreadBadgeProps) => {
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onChange && !!onChange(newValue)) {
      setInternalValue(newValue);
      onChange(newValue);
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        minWidth: '70px',
      },
    };
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ display: 'flex' }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#000000',
                fontSize: '16px',
              },
            }}
            textColor="inherit"
            variant="scrollable"
          >
            <Tab
              label={
                <div className="flex">
                  {leftLabel}
                  {!!leftCount && leftCount > 0 && <UnreadCountBadge count={leftCount}></UnreadCountBadge>}
                </div>
              }
              {...a11yProps(0)}
              sx={{ flex: '1', whiteSpace: 'nowrap', fontSize: '16px' }}
            />
            <Tab
              label={
                <div className="flex">
                  {rightLabel}
                  {!!rightCount && rightCount > 0 && <UnreadCountBadge count={rightCount}></UnreadCountBadge>}
                </div>
              }
              {...a11yProps(1)}
              sx={{ flex: '1', whiteSpace: 'nowrap', fontSize: '16px' }}
            />
          </Tabs>
        </Box>
        {children &&
          React.Children.map(children, (child, index) => (
            <CustomTabPanel key={index} value={internalValue} index={index}>
              {child}
            </CustomTabPanel>
          ))}
      </Box>
    </>
  );
};

export default TabsWithUnreadBadge;

import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getDriveMyVehicleInfo, patchDriveVehicleInfo, postDriveVehicleInfo } from '@/api/public';
import RadioList from '@/components/Common/Input/Radio/RadioList';
import SimpleInput from '@/components/Common/Input/SimpleInput';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import TooltipComponent from '@/components/Common/Tooltip/TooltipComponent';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon } from '@/components/Icon';
import NoAxisWarning from '@/components/Products/NoAxisWarning';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { nonNumericOrDecimalPattern, positiveFloatRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import { formatNumber, formatValueToDecimalPlaces, isUnderFourTons, updateSearchParams } from '@/utils/common';
import { isValidLoadedInnerLength, processTonnageInput } from '@/utils/validation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const DRIVE_VEHICLE_INFO_REGISTER = '운행일지 차량등록';
const DRIVE_VEHICLE_INFO_UPDATE = '차량정보 설정';

const DriveVehicleSetting = () => {
  const navigate = useNavigate();
  const { member, updateDriveVehicleInfo, updateNotificationSettings } = useMemberContext();
  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);

  useEffect(() => {
    if (member) {
      setDriveVehicleInfoId(member?.driveVehicleInfo?.id);
    }
  }, [member]);

  const keyValueList: KeyValueListType = {
    loaded: '적재함 종류',
  };

  const { productEnum, setProductEnum } = useProductEnumContext();
  const { showToast } = useToastContext();
  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>({
    id: 0,
    tons: 0,
    axis: { code: '', desc: '' },
    loaded: { code: '', desc: '' },
    loadedInnerLength: 0,
    fuelEfficiency: 0,
    fee: 0,
    insuranceFee: 0,
    capitalFee: 0,
  });
  const [prevData, setPrevData] = useState<VehicleInfo>({
    id: 0,
    tons: 0,
    axis: { code: '', desc: '' },
    loaded: { code: '', desc: '' },
    loadedInnerLength: 0,
    fuelEfficiency: 0,
    fee: 0,
    insuranceFee: 0,
    capitalFee: 0,
  });
  const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target;
    const convertedValue = String(value).replace(nonNumericOrDecimalPattern, '');

    if (positiveFloatRegex.test(convertedValue.trim()) || convertedValue.trim() === '') {
      const str = convertedValue.trim() === '' ? null : convertedValue.trim();
      const val = Number(str);

      if (name === 'fuelEfficiency') {
        if (val > 100) {
          return;
        }
      }
      setVehicleInfo({ ...vehicleInfo, [name]: str } as VehicleInfo);
    }
  };

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processTonnageInput(value, 1);

    setVehicleInfo({ ...vehicleInfo, [name]: trimmedValue } as VehicleInfo);
  };

  const handleChangeLoadedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const decimalPlaces = 1;
    const formattedValue = formatValueToDecimalPlaces(value, decimalPlaces);

    const isInvalidInput = formattedValue.startsWith('0') && formattedValue.length > 1;

    if (isInvalidInput) {
      setVehicleInfo({ ...vehicleInfo, [name]: '0' } as VehicleInfo);
      return;
    }

    if (positiveFloatRegex.test(formattedValue.trim()) || formattedValue.trim() === '') {
      const str = formattedValue.trim() === '' ? null : formattedValue.trim();
      setVehicleInfo({ ...vehicleInfo, [name]: str } as VehicleInfo);
    }
  };

  const handleChangeFuelEfficiencyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const decimalPlaces = 1;
    const formattedValue = formatValueToDecimalPlaces(value, decimalPlaces);

    setVehicleInfo({ ...vehicleInfo, [name]: formattedValue } as VehicleInfo);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    setVehicleInfo({ ...vehicleInfo, [key]: item } as VehicleInfo);
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const onClickSelectBox = (key: string) => {
    if (!productEnum) return;
    setTitle(key);
    setOptionData(productEnum[key]);
    setIsShow(true);
  };

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      const { updatedParams, error, errorMsg } = updateSearchParams(vehicleInfo, key, valueNum);
      setVehicleInfo(updatedParams);
    }
  };

  const postDriveVehicleInfoMutation = useMutation((request: VehicleInfoRequest) => postDriveVehicleInfo(request), {
    onSuccess: (response) => {
      navigate('/drive');
      showToast('차량 정보가 저장되었어요.', 'success', 'bottom');
      const { member, ...driveVehicleInfo } = response.data;
      updateDriveVehicleInfo(driveVehicleInfo);
      updateNotificationSettings('driveHistory', true);
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const patchDriveVehicleInfoMutation = useMutation(
    (request: { id: number; requestData: VehicleInfoRequest }) => patchDriveVehicleInfo(request),
    {
      onSuccess: (response) => {
        navigate('/drive');
        showToast('차량 정보가 수정되었어요.', 'success', 'bottom');
        updateDriveVehicleInfo(response.data);
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const onClickSaveBtn = () => {
    if (validateForm()) {
      if (!driveVehicleInfoId) {
        const { tons, axis, loaded, loadedInnerLength, fuelEfficiency, fee, insuranceFee, capitalFee } = vehicleInfo;
        const request = {
          tons,
          axis: isUnderFourTons(tons) ? 'NONE' : axis.code,
          loaded: loaded.code,
          loadedInnerLength,
          fuelEfficiency,
          fee: fee,
          insuranceFee: insuranceFee,
          capitalFee: capitalFee,
        };
        postDriveVehicleInfoMutation.mutate(request);
      } else {
        const changedFields: any = {};
        Object.keys(vehicleInfo).forEach((key) => {
          let vehicleInfoValue;
          if (typeof vehicleInfo[key as keyof VehicleInfo] === 'object' && (key === 'axis' || key === 'loaded')) {
            vehicleInfoValue = (vehicleInfo[key as keyof VehicleInfo] as EnumPresenter)?.code ?? null;
          } else {
            vehicleInfoValue = vehicleInfo[key as keyof VehicleInfo] ?? 0;
          }

          const prevDataValue =
            typeof prevData[key as keyof VehicleInfo] === 'object'
              ? (prevData[key as keyof VehicleInfo] as EnumPresenter)?.code
              : prevData[key as keyof VehicleInfo];

          if (vehicleInfoValue !== prevDataValue) {
            changedFields[key as keyof VehicleInfo] = vehicleInfoValue;
          }
        });

        patchDriveVehicleInfoMutation.mutate({ id: vehicleInfo?.id || 0, requestData: changedFields });
      }
    }
  };

  const getMyDriveVehicleInfo = async () => {
    try {
      const response = await getDriveMyVehicleInfo();
      const res: DriveVehicleInfo[] = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data } = useQuery(['get-my-drive-vehicle-info'], getMyDriveVehicleInfo, {
    enabled: !!driveVehicleInfoId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.length !== 0) {
        const { id, tons, axis, loaded, loadedInnerLength, fuelEfficiency, fee, insuranceFee, capitalFee } = data[0];
        setVehicleInfo({ id, tons, axis, loaded, loadedInnerLength, fuelEfficiency, fee, insuranceFee, capitalFee });
        setPrevData({ id, tons, axis, loaded, loadedInnerLength, fuelEfficiency, fee, insuranceFee, capitalFee });
      }
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const setPopupInfoForValidForm = (content: string) => {
    setPopupInfo({
      isShow: true,
      textContent: content,
      textRightBtn: '확인',
      onClickRightBtn: closePopup,
    });
  };

  const validateForm = (): boolean => {
    if (vehicleInfo.tons == null) {
      setPopupInfoForValidForm('톤수를 입력해주세요.');
      return false;
    } else if (vehicleInfo.tons < 1 || vehicleInfo.tons >= 30) {
      setPopupInfoForValidForm('톤수는 1톤 이상~<br/>30톤 미만으로 입력해주세요.');
      return false;
    } else if (!vehicleInfo.axis) {
      setPopupInfoForValidForm('가변축을 입력해주세요.');
      return false;
    } else if (!vehicleInfo.loaded) {
      setPopupInfoForValidForm('적재함 종류를 입력해주세요.');
      return false;
    } else if (!vehicleInfo.loadedInnerLength) {
      setPopupInfoForValidForm('적재함 길이를 입력해주세요.');
      return false;
    } else if (!isValidLoadedInnerLength(vehicleInfo.loadedInnerLength)) {
      setPopupInfoForValidForm('적재함 길이는 1m 이상~<br/>10.5m 이하로 입력해주세요.');
      return false;
    } else if (Number(vehicleInfo?.tons) >= 4.5 && vehicleInfo.loadedInnerLength < 5) {
      setPopupInfoForValidForm('적재함 길이를 5m 이상 입력해주세요.');
      return false;
    } else if (Number(vehicleInfo?.tons) >= 2 && vehicleInfo.loadedInnerLength < 3) {
      setPopupInfoForValidForm('적재함 길이를 3m 이상 입력해주세요.');
      return false;
    } else if (Number(vehicleInfo?.tons) < 2 && vehicleInfo.loadedInnerLength < 2) {
      setPopupInfoForValidForm('적재함 길이를 2m 이상 입력해주세요.');
      return false;
    } else if (!vehicleInfo.fuelEfficiency || vehicleInfo.fuelEfficiency == 0) {
      setPopupInfoForValidForm('연비를 입력해주세요.');
      return false;
    }

    return true;
  };

  const handleBlurTonsInput = () => {
    console.log(vehicleInfo.tons);
    if (isUnderFourTons(vehicleInfo.tons)) {
      setVehicleInfo({
        ...vehicleInfo,
        axis: { code: 'NONE', desc: '없음' },
      });
    }
  };

  return (
    <>
      <MenuHeader
        title={`${driveVehicleInfoId ? DRIVE_VEHICLE_INFO_UPDATE : DRIVE_VEHICLE_INFO_REGISTER}`}
      ></MenuHeader>
      <div className="w-full pt-[60px]">
        <div className="rounded-lg bg-blue-0 p-4 m-4">
          <p className="text-lg font-bold mb-2 text-gray-8">차량의 기본 정보를 입력해주세요.</p>
          <span className="text-gray-8 text-sm break-keep">
            직트럭 운행 일지 이용 시 필요한 차량 정보를 입력해주세요.
          </span>
        </div>
        <section className="py-2 px-4">
          <h3 className="text-gray-8 text-xl font-semibold">차량정보</h3>
          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[120px] text-gray-7">
              톤수<span className="text-base text-red">(필수)</span>
            </dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                placeholder="톤수 입력"
                name="tons"
                value={vehicleInfo?.tons ? String(vehicleInfo?.tons) : ''}
                onChange={(e) => handleChangeTonsInput(e)}
                onBlur={() => handleBlurTonsInput()}
                maxLength={4}
              ></SimpleInput>
              <span className="text-gray-8">t</span>
            </dd>
          </dl>

          <div className="border-b border-gray-3">
            <dl className="flex justify-center items-center py-3">
              <dt className="min-w-[120px] text-gray-7">
                가변축<span className="text-base text-red">(필수)</span>
              </dt>
              <dd className="flex-1 flex justify-between text-gray-8 px-3">
                <RadioList
                  name="axis"
                  list={productEnum?.axis || []}
                  value={vehicleInfo?.axis?.code || ''}
                  onChange={(val) => {
                    onClickItem(val, 'axis');
                  }}
                  isDisabled={isUnderFourTons(vehicleInfo?.tons)}
                ></RadioList>
              </dd>
            </dl>
            <NoAxisWarning tons={vehicleInfo?.tons ? String(vehicleInfo?.tons) : ''} className="mb-[22px]" />
          </div>

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[120px] text-gray-7">
              적재함 종류<span className="text-base text-red">(필수)</span>
            </dt>
            <dd
              className="flex-1 flex justify-between items-center text-gray-8 px-3"
              onClick={() => onClickSelectBox('loaded')}
            >
              <span
                className={`${
                  vehicleInfo?.loaded && vehicleInfo.loaded.desc != '' ? 'text-gray-8' : 'text-gray-5'
                } text-base`}
              >
                {vehicleInfo?.loaded?.desc || '적재함 종류 선택'}
              </span>
              <ChevronDownIcon color={colors.gray[6]}></ChevronDownIcon>
            </dd>
          </dl>

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[120px] text-gray-7">
              적재함 길이<span className="text-base text-red">(필수)</span>
            </dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                placeholder="적재함 길이 입력"
                name="loadedInnerLength"
                value={vehicleInfo?.loadedInnerLength ? String(vehicleInfo?.loadedInnerLength) : ''}
                onChange={(e) => handleChangeLoadedInput(e)}
                onBlur={(e) => handleInputBlur(e, 'loadedInnerLength')}
                maxLength={4}
              ></SimpleInput>
              <span className="text-gray-8">m</span>
            </dd>
          </dl>
          <dl className="flex justify-center items-center py-5">
            <dt className="min-w-[120px] text-gray-7">
              연비<span className="text-base text-red">(필수)</span>
            </dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                placeholder="연비 입력"
                name="fuelEfficiency"
                value={vehicleInfo?.fuelEfficiency ? String(vehicleInfo?.fuelEfficiency) : ''}
                onChange={(e) => handleChangeFuelEfficiencyInput(e)}
                maxLength={4}
              ></SimpleInput>
              <span className="text-gray-8">km/L</span>
            </dd>
          </dl>
        </section>

        <section className="p-4 border-t-8 border-gray-1 pb-24">
          <h3 className="text-gray-8 text-xl font-semibold">고정 비용</h3>

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[120px] text-gray-7">월 지입료</dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                placeholder="지입료 입력"
                name="fee"
                value={vehicleInfo?.fee ? formatNumber(String(vehicleInfo?.fee), true) : ''}
                onChange={(e) => onChangeFormInput(e)}
                maxLength={10}
              ></SimpleInput>
              <span className="text-gray-8">원</span>
            </dd>
          </dl>

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[120px] text-gray-7">
              <span className="align-middle">연간 보험료</span>
              <TooltipComponent
                title=""
                content="연간 보험료를 입력하면 월간으로 환산 후 반영됩니다."
                alignmentTipbox="left"
              />
            </dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                name="insuranceFee"
                placeholder="보험료 입력"
                value={vehicleInfo?.insuranceFee ? formatNumber(String(vehicleInfo?.insuranceFee)) : ''}
                onChange={(e) => onChangeFormInput(e)}
                maxLength={10}
              ></SimpleInput>
              <span className="text-gray-8">원</span>
            </dd>
          </dl>

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[120px] text-gray-7">월 캐피탈 납입료</dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                name="capitalFee"
                placeholder="캐피탈 납입료 입력"
                value={
                  vehicleInfo?.capitalFee && vehicleInfo?.capitalFee !== 0
                    ? formatNumber(String(vehicleInfo?.capitalFee))
                    : ''
                }
                onChange={(e) => onChangeFormInput(e)}
                maxLength={10}
              ></SimpleInput>
              <span className="text-gray-8">원</span>
            </dd>
          </dl>
        </section>
      </div>
      <DownToUpDetailPopup
        isShow={isShow}
        onClosePopup={() => setIsShow(false)}
        title={keyValueList[title]}
        isHeightFull
      >
        <div className="px-4 pb-5">
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={vehicleInfo.loaded.code || ''}>
            {optionData?.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.code}
                control={
                  <Radio
                    icon={<CheckCircleOutlinedIcon sx={{ color: colors.gray[3] }} />}
                    checkedIcon={<CheckCircleIcon sx={{ color: colors.primary }} />}
                  />
                }
                label={item.name || item.desc}
                onClick={() => onClickItem(item, title)}
              />
            ))}
          </RadioGroup>
        </div>
      </DownToUpDetailPopup>
      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-4 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white z-40">
        <ButtonFooter title="차량 정보 저장" onClick={onClickSaveBtn}></ButtonFooter>
      </div>

      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
    </>
  );
};

export default DriveVehicleSetting;

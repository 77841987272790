import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { PhoneIcon } from '../Icon';
import { getPriceTrend, getPublicPriceTrend } from '@/api/public';
import { colors } from '@/const/colors';
import { ACTUAL_REPRESENTATIVE_PHONE_NUMBER, REPRESENTATIVE_NUMBER } from '@/const/common';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatNumber, formatPrice, phoneCall } from '@/utils/common';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Skeleton } from '@mui/material';
import Slider from '@mui/material/Slider';

interface PriceSearchProps {
  priceSearchParams: ProductSearchParams | undefined | ProductDetailResponse;
  isSearchPage?: boolean;
  apiType: 'public' | 'private';
}

interface priceProps {
  showPriceComparison: boolean;
  price?: string | undefined;
}

interface priceRangeType {
  firstRange: string;
  secondRange: string;
  thirdRange: string;
  forthRange: string;
  fifthRange: string;
  sixthRange: string;
  lowPricePercentage: number;
  highPricePercentage: number;
}

interface PricesStandard {
  id: number;
  axis: EnumPresenter;
  loaded: EnumPresenter;
  loadedInnerLength: number;
  tons: string;
  year: string;
}

interface PriceInfoType {
  id: number;
  highPrice: string;
  lowPrice: string;
  priceRange: priceRangeType;
  pricesStandard: PricesStandard;
  level: EnumPresenter;
  pricePercentage: number;
}

interface MarkType {
  value: number;
  label: string;
}

interface LevelPickerType {
  [key: string]: {
    color: string;
    backgroundColor: string;
  };
}

const CarPriceTrendInfo: React.FC<PriceSearchProps & priceProps> = ({
  priceSearchParams,
  apiType = 'public',
  showPriceComparison,
  price,
  isSearchPage,
}) => {
  const { showToast } = useToastContext();

  const levelPicker: LevelPickerType = {
    HIGH: {
      color: '#F5222D',
      backgroundColor: '#FFE3E0',
    },
    MIDDLE: {
      color: '#1E42A6',
      backgroundColor: '#F0F5FF',
    },
    LOW: {
      color: '#52C41A',
      backgroundColor: '#F6FFED',
    },
  };
  const [marks, setMarks] = useState<MarkType[]>([
    {
      value: 0,
      label: '0',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 100,
      label: '100',
    },
  ]);

  const marksWithColor = marks.map((mark) => ({
    ...mark,
    label: <span style={{ color: mark.value === 55 ? '#ef4444' : '#919191' }}>{mark.label}</span>,
  }));

  const [isNone, setIsNone] = useState<boolean>();
  const [circleStyle, setCircleStyle] = useState<React.CSSProperties>({
    position: 'absolute',
    width: '18px',
    height: '18px',
    backgroundColor: levelPicker['MIDDLE']?.color,
    borderRadius: '50%',
    top: '10px',
    left: '0%',
    border: '2px solid white',
  });

  const [isHideMarker, setIsHideMarker] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);

  const params = {
    tons: priceSearchParams?.tons,
    year: priceSearchParams?.year,
    modelId: priceSearchParams?.model?.id,
    loaded: priceSearchParams?.loaded?.code,
    loadedInnerLength: priceSearchParams?.loadedInnerLength,
    axis: priceSearchParams?.axis?.code,
    price: price ? price : null,
    productId: priceSearchParams?.productId ? priceSearchParams?.productId : null,
  };

  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.append(key, value.toString());
  });

  const getPriceAPI = (queryParams: URLSearchParams) => {
    if (apiType === 'public') {
      return getPublicPriceTrend(queryParams);
    } else {
      return getPriceTrend(queryParams);
    }
  };

  const {
    data: priceInfo,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery<PriceInfoType>(['get-price-trend', queryParams, price], () => getPriceAPI(queryParams), {
    enabled: queryParams.size > 5,
    refetchOnWindowFocus: false,
    onSuccess: (responseData) => {
      if (responseData.id) {
        setIsNone(false);
        setMarks([
          {
            value: 0,
            label: formatNumber(responseData.priceRange.firstRange),
          },
          {
            value: 20,
            label: formatNumber(responseData.priceRange.secondRange),
          },
          {
            value: 40,
            label: formatNumber(responseData.priceRange.thirdRange),
          },
          {
            value: 60,
            label: formatNumber(responseData.priceRange.forthRange),
          },
          {
            value: 80,
            label: formatNumber(responseData.priceRange.fifthRange),
          },
          {
            value: 100,
            label: formatNumber(responseData.priceRange.sixthRange),
          },
        ]);

        if (responseData.pricePercentage) {
          const pricePercentage = parseFloat(responseData?.pricePercentage.toString().replace('%', ''));
          if (pricePercentage < 0 || pricePercentage > 100) {
            setIsHideMarker(true);
          } else {
            setCircleStyle({
              position: 'absolute',
              width: '18px',
              height: '18px',
              backgroundColor: levelPicker[responseData?.level?.code]?.color,
              borderRadius: '50%',
              top: '16px',
              left: `calc(${responseData?.pricePercentage} - 9px)`,
              border: '2px solid white',
            });
            if (pricePercentage > 90 && pricePercentage <= 100) {
              setOffset(30);
            } else if (pricePercentage >= 0 && pricePercentage < 10) {
              setOffset(-30);
            }
          }
        }
      } else {
        setIsNone(true);
        setIsHideMarker(true);
      }
    },
    onError: () => {
      showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
    },
  });

  return (
    <div className="my-6">
      {isLoading || isFetching || isNone === undefined ? (
        <Skeleton variant="rounded" width="100%" animation="wave" height={123} sx={{ bgcolor: colors.gray[2] }} />
      ) : isSuccess && !!isNone ? (
        <>
          <p className="text-center mb-10 text-gray-8">해당 차량의 평균 시세 정보가 없습니다.</p>
          {isSearchPage && (
            <div className="flex flex-col justify-center items-center rounded-[10px] bg-blue-0 w-full pt-[26px] pb-5 px-5 text-center ">
              <p className="font-bold text-lg text-gray-8">내차량 시세 상담받기</p>
              <div
                className="bg-white shadow-[0_4px_6px_-1px_rgba(0,0,0,0.4)] rounded-full w-[240px] h-[80px] flex justify-center items-center text-[#1E42A6] font-bold mt-6"
                onClick={() => phoneCall(ACTUAL_REPRESENTATIVE_PHONE_NUMBER)}
              >
                <PhoneIcon color={colors.primary}></PhoneIcon>
                <div className="ml-5 text-lg">
                  대표전화
                  <br />
                  {REPRESENTATIVE_NUMBER}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        isSuccess &&
        !isNone && (
          <>
            {showPriceComparison && (
              <p className="text-center m-6 break-keep">
                해당 차량은 평균 시세 대비{' '}
                <span
                  className="font-semibold"
                  style={{ color: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color }}
                >
                  '{priceInfo?.level?.desc}'
                </span>
                입니다.
              </p>
            )}
            <dl className={`text-center ${isHideMarker ? 'mb-4' : 'mb-12'}`}>
              <dt className="text-gray-500 mb-1">평균시세</dt>
              <dd className="font-semibold text-xl">
                {formatPrice(priceInfo?.lowPrice)} ~ {formatPrice(priceInfo?.highPrice)}
              </dd>
            </dl>
            <div className="relative mx-4">
              <Slider
                style={{ padding: '20px 0' }}
                getAriaLabel={() => 'Minimum distance'}
                value={[
                  priceInfo?.priceRange?.lowPricePercentage || 0,
                  priceInfo?.priceRange?.highPricePercentage || 0,
                ]}
                valueLabelDisplay="off"
                size="medium"
                disableSwap
                marks={marksWithColor}
                sx={{
                  height: '10px',
                  paddingRight: '9px',
                  paddingLeft: '9px',
                  pointerEvents: 'none',
                  '& .MuiSlider-thumb': { width: 0, height: 0 },
                  '& .MuiSlider-thumb:hover': {
                    backgroundColor: '#1e40af',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#1e40af',
                    border: 0,
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: '#d1d5db',
                  },
                  '& .MuiSlider-mark': {
                    display: 'none',
                  },
                }}
              />
              {showPriceComparison && !isHideMarker && (
                <div style={circleStyle}>
                  <div
                    className="absolute bottom-[30px] rounded-full w-20 h-7 bg-lime-100 border border-lime-500 box-border"
                    style={{
                      left: `calc(-33px - ${offset}px)`,
                      borderColor: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color,
                      backgroundColor: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.backgroundColor,
                    }}
                  >
                    <LocalShippingOutlinedIcon
                      className="mx-2 mb-1"
                      sx={{ color: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color }}
                    ></LocalShippingOutlinedIcon>
                    {priceInfo?.level?.desc}
                    <div
                      className="border-dashed border-r box-borders"
                      style={{
                        width: `calc(40px + ${offset}px)`,
                        height: '14px',
                        borderColor: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default CarPriceTrendInfo;

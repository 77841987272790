import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import SliderComponent from '@/components/Common/Input/Slider/SliderComponent';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import {
  TONS_IS_LESS_THAN_27,
  TONS_IS_OVER_1,
  YEAR_IS_LESS_THAN_CURRENT,
  YEAR_IS_OVER_2000,
} from '@/const/errorMessage';
import { positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { licenseSearchParamsAtom } from '@/store/license';
import { getCurrentYear, getInputErrorMessage } from '@/utils/common';

const LicenseSearch = () => {
  const currentYear = getCurrentYear();
  const { member } = useMemberContext();
  const navigate = useNavigate();
  const { licenseEnum } = useLicenseEnumContext();

  const [searchParams] = useAtom(licenseSearchParamsAtom);
  const setSearchParams = useSetAtom(licenseSearchParamsAtom);

  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');
  const [optionData, setOptionData] = useState<OptionType[]>([]);

  const [minYearError, setMinYearError] = useState(false);
  const [maxYearError, setMaxYearError] = useState(false);
  const [minTonsError, setMinTonsError] = useState(false);
  const [maxTonsError, setMaxTonsError] = useState(false);

  const [minYearErrorMsg, setMinYearErrorMsg] = useState('');
  const [maxYearErrorMsg, setMaxYearErrorMsg] = useState('');
  const [minTonsErrorMsg, setMinTonsErrorMsg] = useState('');
  const [maxTonsErrorMsg, setMaxTonsErrorMsg] = useState('');

  const keyValueList: KeyValueListType = {
    useClassification: '번호판 용도',
    licenseType: '번호판 종류',
  };

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber: boolean) => {
    const { name, value } = e.target;
    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(value) && !value.startsWith('-')) {
        const str = value === '' ? null : value;
        setSearchParams({ ...searchParams, [name]: str });

        switch (name) {
          case 'minYear':
            if (Number(value) > Number(searchParams.maxYear)) {
              setMinYearError(true);
              setMinYearErrorMsg(getInputErrorMessage('min', 'year'));
              return;
            } else if (Number(value) < 2000) {
              setMinYearError(true);
              setMinYearErrorMsg(YEAR_IS_OVER_2000);
              return;
            } else {
              setMinYearError(false);
              setMinYearErrorMsg('');
            }
            break;
          case 'maxYear':
            if (Number(value) < Number(searchParams.minYear)) {
              setMaxYearError(true);
              setMaxYearErrorMsg(getInputErrorMessage('max', 'year'));
              return;
            } else if (Number(value) > currentYear + 1) {
              setMaxYearError(true);
              setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
              return;
            } else {
              setMaxYearError(false);
              setMaxYearErrorMsg('');
            }
            break;
        }
      }
    } else if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = value.trim() === '' ? null : value.trim();
      setSearchParams({ ...searchParams, [name]: str });

      switch (name) {
        case 'minTons':
          if (Number(value) > Number(searchParams.maxTons)) {
            setMinTonsError(true);
            setMinTonsErrorMsg(getInputErrorMessage('min', 'tons'));
            return;
          } else if (Number(value) < 1) {
            setMinTonsError(true);
            setMinTonsErrorMsg(TONS_IS_OVER_1);
            return;
          } else {
            setMinTonsError(false);
            setMinTonsErrorMsg('');
          }
          break;
        case 'maxTons':
          if (Number(value) < Number(searchParams.minTons)) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(getInputErrorMessage('max', 'tons'));
            return;
          } else if (Number(value) > 27) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
            return;
          } else {
            setMaxTonsError(false);
            setMaxTonsErrorMsg('');
          }
          break;
      }
    }
  };

  const clear = () => {
    if (member) {
      setSearchParams({
        useClassification: { code: '', desc: '' },
        minYear: '2000',
        maxYear: (currentYear + 1).toString(),
        minTons: '1',
        maxTons: '27',
        licenseSalesType: '',
        licenseType: { code: '', desc: '' },
        page: 1,
        memberId: member.id,
      });
    } else {
      setSearchParams({
        useClassification: { code: '', desc: '' },
        minYear: '2000',
        maxYear: (currentYear + 1).toString(),
        minTons: '1',
        maxTons: '27',
        licenseSalesType: '',
        licenseType: { code: '', desc: '' },
        page: 1,
      });
    }
  };

  const onClickSelectBox = (key: string) => {
    if (!licenseEnum) {
      return;
    }
    const arr: OptionType[] = [];
    setTitle(key);
    setOptionData(licenseEnum[key]);
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    setSearchParams({ ...searchParams, [key]: item });
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const onChangeSliderInput = (e: Event, values: number | number[], key: string) => {
    if (!Array.isArray(values)) {
      return;
    }
    setSearchParams({ ...searchParams, [`min${key}`]: values[0].toString(), [`max${key}`]: values[1].toString() });
  };

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setSearchParams({ ...searchParams, [key]: newVal.code });
  };

  useEffect(() => {
    if (searchParams.minYear && searchParams.maxYear) {
      if (Number(searchParams.minYear) <= Number(searchParams.maxYear)) {
        setMinYearError(false);
        setMinYearErrorMsg('');
        setMaxYearError(false);
        setMaxYearErrorMsg('');
      }
      if (Number(searchParams.minYear) < 2000) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(searchParams.minYear) > currentYear + 1) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
      if (Number(searchParams.maxYear) < 2000) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(searchParams.maxYear) > currentYear + 1) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
    }
  }, [searchParams.minYear, searchParams.maxYear]);

  useEffect(() => {
    if (searchParams.minTons && searchParams.maxTons) {
      if (Number(searchParams.minTons) <= Number(searchParams.maxTons)) {
        setMinTonsError(false);
        setMinTonsErrorMsg('');
        setMaxTonsError(false);
        setMaxTonsErrorMsg('');
      }
      if (Number(searchParams.minTons) < 1) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(searchParams.maxTons) < 1) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(searchParams.minTons) > 27) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
      if (Number(searchParams.maxTons) > 27) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
    }
  }, [searchParams.minTons, searchParams.maxTons]);

  const search = () => {
    navigate('/license', { state: { licenseSearchParams: searchParams } });
  };

  return (
    <>
      <MenuHeader title="번호판 검색" onClickPrevBtn={() => navigate(-1)}></MenuHeader>
      <div className="w-full pt-[60px] pb-[112px] text-gray-8">
        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">연식</p>
          <SliderComponent
            name="Year"
            value={[Number(searchParams.minYear), Number(searchParams.maxYear)]}
            onChange={(event: Event, value: number | number[]) => onChangeSliderInput(event, value, 'Year')}
            step={1}
            min={2000}
            max={currentYear + 1}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minYear"
              placeholder="최소 연식"
              value={searchParams.minYear || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, true)}
              error={minYearError}
              errorMsg={minYearErrorMsg}
              height={36}
              fontSize={18}
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxYear"
              placeholder="최대 연식"
              value={searchParams.maxYear || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, true)}
              error={maxYearError}
              errorMsg={maxYearErrorMsg}
              height={36}
              fontSize={18}
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">톤수</p>
          <SliderComponent
            name="Tons"
            value={[Number(searchParams.minTons) || 0, Number(searchParams.maxTons) || 27]}
            onChange={(e, value) => onChangeSliderInput(e, value, 'Tons')}
            step={1}
            min={1}
            max={27}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minTons"
              placeholder="최소 톤수"
              value={searchParams.minTons || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, false)}
              error={minTonsError}
              errorMsg={minTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxTons"
              placeholder="최대 톤수"
              value={searchParams.maxTons || ''}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, false)}
              error={maxTonsError}
              errorMsg={maxTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t border-b border-gray-3 px-4 py-[30px]">
          <p className="font-semibold mb-2 text-gray-8">거래 방식</p>
          <div className="px-1">
            <RadioList
              name="licenseSalesType"
              list={licenseEnum.licenseSalesType}
              value={searchParams.licenseSalesType || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'licenseSalesType');
              }}
              justify="equal"
            ></RadioList>
          </div>
        </div>
        {searchParams.licenseSalesType !== 'RENTAL' && (
          <>
            <div
              className="flex items-center text-base p-4 border-b border-gray-3"
              onClick={() => onClickSelectBox('licenseType')}
            >
              <p className="w-[100px] font-semibold text-gray-8">번호판 종류</p>
              <span className="flex-1 font-medium mr-2 text-ellipsis break-all line-clamp-1 text-right">
                {searchParams?.licenseType?.desc}
              </span>
              <ChevronRightIcon color={colors.gray[4]}></ChevronRightIcon>
            </div>
            <div
              className="flex items-center text-base p-4 border-b border-gray-3"
              onClick={() => onClickSelectBox('useClassification')}
            >
              <p className="w-[100px] font-semibold text-gray-8">번호판 용도</p>
              <span className="flex-1 font-medium mr-2 text-ellipsis break-all line-clamp-1 text-right">
                {searchParams?.useClassification?.desc}
              </span>
              <ChevronRightIcon color={colors.gray[4]}></ChevronRightIcon>
            </div>
          </>
        )}
      </div>

      <DualFooterButton
        leftButtonText="초기화"
        onClickLeftButton={() => {
          clear();
        }}
        rightButtonText="검색"
        onClickRightButton={() => {
          search();
        }}
      ></DualFooterButton>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={
              title === 'useClassification' && searchParams?.useClassification
                ? searchParams?.useClassification?.code
                : title === 'licenseType' && searchParams?.licenseType
                ? searchParams?.licenseType?.code
                : ''
            }
            onChange={(val) => {
              onClickItem(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default LicenseSearch;

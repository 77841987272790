import React, { SVGProps } from 'react';

const SvgCalendarCheckColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path fill="#D1DDFF" d="M5 13a6 6 0 0 1 6-6h18a6 6 0 0 1 6 6v18a6 6 0 0 1-6 6H11a6 6 0 0 1-6-6V13Z" />
    <path
      fill="#3C5FBF"
      fillRule="evenodd"
      d="M14 3a2 2 0 0 1 2 2v6a2 2 0 1 1-4 0V5a2 2 0 0 1 2-2ZM26 3a2 2 0 0 1 2 2v6a2 2 0 1 1-4 0V5a2 2 0 0 1 2-2Z"
      clipRule="evenodd"
    />
    <path
      fill="#3C5FBF"
      d="M18.5 19h3v3h-3v-3ZM11 19h3v3h-3v-3ZM26 19h3v3h-3v-3ZM26 26h3v3h-3v-3ZM18.5 26h3v3h-3v-3ZM11 26h3v3h-3v-3Z"
    />
  </svg>
);
SvgCalendarCheckColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCalendarCheckColorIcon;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPaper-root {
  width: auto !important;
  height: auto !important;
}

.MuiYearCalendar-root {
  width: auto !important;
  height: 330px !important;
}

.MuiDateCalendar-root {
  width: auto !important;
  min-height: 246px !important;
  max-height: 330px !important;
}

.MuiPickersSlideTransition-root {
  min-height: 230px !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/calendarPopupStyle.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,4BAA4B;EAC5B,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".MuiPaper-root {\n  width: auto !important;\n  height: auto !important;\n}\n\n.MuiYearCalendar-root {\n  width: auto !important;\n  height: 330px !important;\n}\n\n.MuiDateCalendar-root {\n  width: auto !important;\n  min-height: 246px !important;\n  max-height: 330px !important;\n}\n\n.MuiPickersSlideTransition-root {\n  min-height: 230px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

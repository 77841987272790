import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

export const firebaseConfig = {
  apiKey: 'AIzaSyCpf9V1amEGm4UmkYm6Ftzocy3rsTZ8X1A',
  authDomain: 'zigtruck-51b3b.firebaseapp.com',
  projectId: 'zigtruck-51b3b',
  storageBucket: 'zigtruck-51b3b.appspot.com',
  messagingSenderId: '521860606722',
  appId: '1:521860606722:web:7a60d234c8750a0932701b',
  measurementId: 'G-J6XX0N3GY7',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);

// Initialize Firebase
const initializeFirebase = () => {
  app;
};

export { analytics, app, initializeFirebase, performance };

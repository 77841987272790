import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronLeftIcon } from '../Icon';
import MoreMenu, { MoreMenuData } from '../Menu/MoreMenu';
import { colors } from '@/const/colors';
import useNavigateBack from '@/hooks/useNavigateBack';

interface TitleProps {
  title: string;
  path?: string;
  isMain?: boolean;
  isShadow?: boolean | false;
  onClickPrevBtn?: (() => void) | undefined;
  moreMenuIcon?: boolean | false;
  moreMenuData?: MoreMenuData;
  icons?: MenuHeaderIcon[];
}

export interface MenuHeaderIcon {
  icon: React.ReactNode;
  text?: string;
  onClickIcon: () => void;
}

const MenuHeader = ({
  title,
  path,
  isMain,
  isShadow,
  onClickPrevBtn,
  moreMenuIcon,
  moreMenuData,
  icons,
}: TitleProps) => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const onClickBtn = () => {
    if (onClickPrevBtn) {
      onClickPrevBtn();
    } else if (path) {
      navigate(path);
    } else {
      navigateBack();
    }
  };

  return (
    <>
      <div
        className={`fixed items-center flex z-30 top-0 mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] h-15 bg-white ${
          isShadow ? 'shadow' : ''
        }`}
      >
        <div className="flex flex-1">
          {!isMain && (
            <div className="flex items-center justify-center mr-4 cursor-pointer" onClick={onClickBtn}>
              <ChevronLeftIcon color={colors.gray[9]}></ChevronLeftIcon>
            </div>
          )}
          <h1 className="text-xl font-semibold">{title}</h1>
        </div>
        {icons && (
          <div className="flex items-center gap-2">
            {icons.map((icon, index) => (
              <div key={index}>
                <div onClick={icon.onClickIcon} className="flex items-center gap-1 cursor-pointer">
                  {icon.icon}
                  {icon.text && <span className="text-[14px] text-gray-8">{icon.text}</span>}
                </div>
                {icon.text && index < icons.length - 1 && <span className="text-gray-4"> | </span>}
              </div>
            ))}
          </div>
        )}
        {moreMenuIcon && moreMenuData && <MoreMenu id={moreMenuData.id} status={moreMenuData.status} />}
      </div>
    </>
  );
};

export default MenuHeader;

import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { getRepairHistory } from '@/api/public';
import FloatingButton from '@/components/Common/Button/FloatingButton';
import RepairItemBox from '@/components/Drive/Repair/RepairItemBox';
import MenuHeader from '@/components/Header/MenuHeader';
import { PlusSmallIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { TODAY } from '@/const/date';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { repairHistorySearchParamsAtom } from '@/store/repairHistory';
import { asYYYYMM, formatNumber } from '@/utils/common';
import { isSameMonth } from '@/utils/dateUtils';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Button } from '@mui/material';

const RepairList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToastContext();
  const { member } = useMemberContext();

  const [searchParams] = useAtom(repairHistorySearchParamsAtom);
  const setSearchParams = useSetAtom(repairHistorySearchParamsAtom);

  const [baseDate, setBaseDate] = useState(dayjs(location.state.selectedDate) || TODAY);

  useEffect(() => {
    if (member) {
      setSearchParams({
        driveVehicleInfoId: member.driveVehicleInfo.id,
        repairYearAndMonth: baseDate.format('YYYY-MM') || searchParams.repairYearAndMonth,
      });
    }
  }, [member, baseDate]);

  const onClickAddRepair = () => {
    navigate(`/drive/repair/form`);
  };

  const onClickEdit = (id: number) => {
    const repairData = repairHistory?.repairHistoryByBaseDayList
      ?.flatMap((item) => item.repairHistoryResponse)
      .find((item) => item.id === id);
    navigate(`/drive/repair/${id}`, { state: { data: repairData } });
  };

  const getRepairHistoryList = async () => {
    try {
      if (searchParams.driveVehicleInfoId && baseDate.format('YYYY-MM')) {
        const params = new URLSearchParams({
          driveVehicleInfoId: searchParams.driveVehicleInfoId.toString(),
          repairYearAndMonth: baseDate.format('YYYY-MM'),
        });
        const response = await getRepairHistory(params);
        const data: RepairHistoryResponse = response.data;
        return data;
      }
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data: repairHistory, isSuccess } = useQuery(['get-drive-info', searchParams], () => getRepairHistoryList(), {
    onSuccess: () => {},
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    refetchOnWindowFocus: false,
    enabled: !!searchParams.driveVehicleInfoId && !!baseDate.format('YYYY-MM'),
  });

  const getDetailContents = (item: RepairHistory) => {
    let data = {
      key: '',
      label: '',
      value: '',
    };
    data = { key: String(item.id), label: item.title, value: formatNumber(String(item.totalPrice)) + '원' };
    return data;
  };

  const getExpandDetailContents = (item: RepairItemsType[]) => {
    let data: KeyValueLabel[] = [];
    data = item.map((item, index) => {
      return { key: String(index), label: item.name, value: formatNumber(String(item.price)) + '원' };
    });
    return data;
  };

  const onClickPrevMonth = () => {
    setBaseDate(dayjs(baseDate).subtract(1, 'month'));
    setSearchParams({
      driveVehicleInfoId: member?.driveVehicleInfo.id,
      repairYearAndMonth: dayjs(baseDate).subtract(1, 'month').format('YYYY-MM'),
    });
  };

  const onClickNextMonth = () => {
    if (!isSameMonth(TODAY, baseDate)) {
      setBaseDate(dayjs(baseDate).add(1, 'month'));
      setSearchParams({
        driveVehicleInfoId: member?.driveVehicleInfo.id,
        repairYearAndMonth: dayjs(baseDate).add(1, 'month').format('YYYY-MM'),
      });
    }
  };

  return (
    <>
      <MenuHeader title="수리비" path="/drive"></MenuHeader>
      <div className="relative w-full pt-[60px]">
        <div className="px-4 pb-5">
          <div className="flex sm280:flex-col-reverse items-center sm280:items-start justify-between md-only:justify-around bg-blue-0 rounded-lg p-4">
            <div className="text-gray-8 font-semibold pb-1 sm280:mt-2">
              <span className="text-gray-7">총 </span>
              {formatNumber(repairHistory?.totalRepairCostOfMonth?.toString())}원
            </div>
            <div className="border-l border-gray-3 h-[43px] ml-2 pl-2 sm280:hidden"></div>

            <div className="w-[120px] flex items-center justify-center w-[120px]">
              <span className="flex items-center pr-2" onClick={onClickPrevMonth}>
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: 16, color: colors.gray[8] }}></ArrowBackIosNewRoundedIcon>
              </span>
              <span className="text-gray-8 font-semibold text-lg">{asYYYYMM(baseDate)}</span>
              <span className="flex items-center pl-2" onClick={onClickNextMonth}>
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: 16, color: isSameMonth(TODAY, baseDate) ? colors.gray[5] : colors.gray[8] }}
                ></ArrowForwardIosRoundedIcon>
              </span>
            </div>
          </div>
        </div>

        <section className="py-2 px-4 pb-24 border-t-8 border-gray-1">
          {isSuccess && repairHistory && repairHistory?.repairHistoryByBaseDayList?.length > 0 ? (
            <>
              <div className="flex flex-col gap-4 pt-9">
                {repairHistory?.repairHistoryByBaseDayList?.map((item, key) => (
                  <div key={key}>
                    <RepairItemBox
                      title={item.baseDay}
                      data={getDetailContents(item.repairHistoryResponse)}
                      detailData={getExpandDetailContents(item.repairHistoryResponse.repairItems)}
                      onClick={() => onClickEdit(item.repairHistoryResponse.id || 0)}
                    ></RepairItemBox>
                  </div>
                ))}
              </div>
              <FloatingButton
                bgColor={colors.primary}
                textColor={colors.gray[0]}
                icon={<PlusSmallIcon color={colors.gray[0]}></PlusSmallIcon>}
                title={'수리비 추가'}
                minWidth={150}
                minHeight={48}
                onClick={onClickAddRepair}
              ></FloatingButton>
            </>
          ) : (
            isSuccess && (
              <div className="w-full h-full px-4" style={{ height: `calc(100vh - 180px)` }}>
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <span className="text-gray-7 text-lg mt-4 text-center">등록된 수리비가 없습니다.</span>
                  <Button
                    variant="contained"
                    onClick={onClickAddRepair}
                    sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '24px' }}
                    className="w-[200px]"
                  >
                    수리비 추가하기
                  </Button>
                </div>
              </div>
            )
          )}
        </section>
      </div>
    </>
  );
};

export default RepairList;

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';
import { ReactNode } from 'react';

type ToastType = {
  isShow: boolean;
  icon: ReactNode;
  text: string;
  onClickBtn: () => void;
};

const ClickedToast = ({ isShow, icon, text, onClickBtn }: ToastType) => {
  return (
    <>
      {isShow && (
        <div
          className={`fixed w-full z-50 left-0 right-0 mx-auto my-0 min-w-[280px] max-w-[720px] flex justify-center bottom-16 p-[15px] cursor-pointer`}
          onClick={onClickBtn}
        >
          <div
            className={`flex justify-between items-center text-black rounded-lg py-4 px-4 bg-[#FAFAFA] w-full p-[15px] max-w-[720px] shadow-[0px_1px_4px_2px_rgba(0,0,0,0.07)]`}
          >
            <div className="bg-[#1E42A6] rounded-full w-10 h-10 min-w-[40px] min-h-[40px] flex justify-center items-center">
              {icon}
            </div>
            <div className="ml-2 text-sm">{text}</div>
            <NavigateNextIcon></NavigateNextIcon>
          </div>
        </div>
      )}
    </>
  );
};

export default ClickedToast;

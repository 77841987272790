import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon, PencilIcon } from '@/components/Icon';
import ProfileImage from '@/components/More/ProfileImage';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';

const SettingPage = () => {
  const navigate = useNavigate();
  const { member, removeMember } = useMemberContext();
  const { showToast } = useToastContext();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const handleCloseConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const handleLogout = () => {
    const deviceToken = localStorage.getItem('deviceToken');

    apiManager
      .post('/auth/logout', { memberId: member?.id, deviceToken: deviceToken }, { withCredentials: true })
      .then((response) => {
        localStorage.removeItem('token');
        localStorage.removeItem('driveTutorial');
        localStorage.removeItem('driveTutorialReceivedCheck');
        removeMember();
        navigate('/more');
        window.location.reload();
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  };

  const handleClickLogout = () => {
    setIsOpenConfirmModal(true);
  };

  return (
    <>
      <MenuHeader title="설정" path="/more"></MenuHeader>
      <div className="w-full">
        <div className="pt-[60px]">
          <div className="z-10 flex items-center p-4 mx-4 rounded-lg bg-gray-2 jusify-between">
            <div className="flex-1">
              <Link to="/member/name">
                <span className="flex items-center w-full">
                  <p className="overflow-hidden text-gray-9 font-semibold text-[24px] mr-2 leading-[28px]">
                    {member?.name}
                  </p>
                  <PencilIcon color={colors.gray[8]} width={16} height={16}></PencilIcon>
                </span>
              </Link>
              <p className="text-base text-gray-6 leading-[20px]">
                {member?.email ? member.email : member?.phoneNumber}
              </p>
            </div>
            <div className="flex-initial">
              <ProfileImage></ProfileImage>
            </div>
          </div>
          <hr className="mt-6 mb-3 border-4 border-gray-1"></hr>

          <ul className="px-4">
            <li className="py-5 list-none">
              <Link to="/member/phone-number" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">휴대폰 번호 변경</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>

            {!member?.email && (
              <li className="py-5 list-none">
                <Link to="/member/password" className="flex items-center cursor-pointer">
                  <p className="flex-1 font-semibold text-gray-8">비밀번호 변경</p>
                  <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
                </Link>
              </li>
            )}

            <li className="py-5 list-none">
              <Link to="/setting/notification" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">알림 설정</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>

            {/* <li className="py-5 list-none">
              <Link to="/setting/referral" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">추천인 등록</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li> */}
          </ul>

          <hr className="my-3 border-4 border-gray-1"></hr>
          <ul className="px-4">
            <li className="py-5 list-none">
              <Link to="/notice" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">공지사항</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>
            <li className="py-5 list-none">
              <Link to="/terms" className="flex items-center cursor-pointer">
                <p className="flex-1 font-semibold text-gray-8">서비스 이용약관 확인</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            </li>
          </ul>
          <hr className="my-3 border-4 border-gray-1"></hr>
          <ul className="px-4">
            <li className="list-none cursor-pointer" onClick={handleClickLogout}>
              <p className="leading-[52px] font-semibold">로그아웃</p>
            </li>
          </ul>
          <div className="px-4 py-6">
            <p className="text-xl font-semibold">기타</p>
            <div className="pt-2 font-base">
              현재 버전 <span className="text-gray-6">1.0.0</span>
            </div>
            <div />
          </div>
        </div>

        <ConfirmModal
          isOpen={isOpenConfirmModal}
          onClose={handleCloseConfirmModal}
          title="로그아웃"
          content="정말 로그아웃 하시겠어요?"
          leftButtonLabel="닫기"
          rightButtonLabel="로그아웃"
          onLeftButtonClick={handleCloseConfirmModal}
          onRightButtonClick={handleLogout}
        />
      </div>
    </>
  );
};

export default SettingPage;

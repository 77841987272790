import React, { SVGProps } from 'react';

const SvgMagnifyingGlassMinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Zm8.25-.75h.9-.15 2.25a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1 0-1.5h3Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgMagnifyingGlassMinusIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMagnifyingGlassMinusIcon;

import React from 'react';

type TooltipBoxProps = {
  msg: string;
  descMsg: string;
  minWidth?: number;
  maxWidth?: number;
  position?: string;
};

const TooltipBox: React.FC<TooltipBoxProps> = ({ msg, descMsg, minWidth = 250, maxWidth = 680, position = 'left' }) => {
  return (
    <div className="relative">
      <div
        className={`w-auto absolute z-30 ${position}-0 top-0 rounded-lg py-2 px-3 bg-white border border-gray-100 shadow-md`}
        style={{ minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px` }}
      >
        <div className="text-gray-8 break-all text-sm text-left" dangerouslySetInnerHTML={{ __html: msg }}></div>
        <div className="text-gray-8 break-all text-xs text-left" dangerouslySetInnerHTML={{ __html: descMsg }}></div>
      </div>
    </div>
  );
};

export default TooltipBox;

import React from 'react';

import { colors } from '@/const/colors';
import Skeleton from '@mui/material/Skeleton';

const ProductCardSkeleton = () => {
  const skeletonItem = (
    <li className="p-1 mb-4">
      <div className="flex w-full border border-gray-100 rounded-lg h-[15.375rem]">
        <Skeleton variant="rounded" width="100%" height="100%" animation="wave" sx={{ bgcolor: colors.gray[2] }} />
      </div>
      <dt className="font-semibold text-gray-700 text-xl my-2 flex items-center break-keep">
        <p className="flex-1">
          <Skeleton variant="text" width="80%" animation="wave" sx={{ bgcolor: colors.gray[2] }} />
        </p>
      </dt>
      <dd className="text-gray-500 my-2">
        <Skeleton variant="text" width="60%" animation="wave" sx={{ bgcolor: colors.gray[2] }} />
      </dd>
      <dd className="my-2 flex items-center">
        <span className="flex-1">
          <Skeleton variant="text" width="40%" animation="wave" sx={{ bgcolor: colors.gray[2] }} />
        </span>
      </dd>
    </li>
  );

  return (
    <div>
      <ul className="mt-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <React.Fragment key={index}>{skeletonItem}</React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default ProductCardSkeleton;

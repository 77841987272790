import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { colors } from '@/const/colors';
import apiManager from '@/api/AxiosInstance';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import Flicking, { ChangedEvent, Control, ViewportSlot } from '@egjs/react-flicking';
import { Button } from '@mui/material';
import { XMarkIcon } from '@/components/Icon';

interface DownToUpTimeSettingPopupProps {
  title?: string;
  children?: ReactNode;
  isShow: boolean;
  defaultTime?: number;
  changeIsShow: (show: boolean) => void;
}

const DownToUpTimeSettingPopup = ({
  children,
  isShow,
  defaultTime,
  changeIsShow,
  title,
}: DownToUpTimeSettingPopupProps) => {
  const flickingRef = useRef<Flicking | null>(null);
  const flickingAmPmRef = useRef<Flicking | null>(null);
  const { showToast } = useToastContext();
  const { member, updateNotificationSettings } = useMemberContext();
  const [amPmInital, setAmPmInital] = useState(0);
  const [timeInital, setTimeInital] = useState(0);
  const onClickClose = () => {
    changeIsShow(false);
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 30;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;
      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
    });
  };

  const calculateTime = (): number => {
    const amPmActiveIndex = flickingAmPmRef?.current?.control?.activeIndex || 0;
    const timeActiveIndex = flickingRef?.current?.control?.activeIndex || 0;

    let requestTime = 0;

    if (amPmActiveIndex === 0 && timeActiveIndex === 11) {
      requestTime = 0;
    } else if (amPmActiveIndex === 1 && timeActiveIndex === 11) {
      requestTime = 12;
    } else if (amPmActiveIndex === 0) {
      requestTime = timeActiveIndex + 1;
    } else {
      requestTime = timeActiveIndex + 13;
    }

    return requestTime;
  };

  const onClickApplyBtn = () => {
    const requestTime = calculateTime();
    onClickClose();
    patchNotificationSetting(requestTime);
  };

  const patchNotificationSetting = (time: number) => {
    if (!member?.notificationSettings?.id) {
      return;
    }

    let modifyRequest = { driveHistoryTime: time };

    apiManager
      .patch(`/api/v1/notification-settings/${member?.notificationSettings?.id}`, modifyRequest)
      .then((response) => {
        if (response) {
          updateNotificationSettings('driveHistoryTime', time);
          showToast(`운행일지 시간을 설정했습니다.`, 'success', 'bottom');
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };

  const onChangeDate = (e: any) => {
    const { index } = e.currentTarget;
    const panels = e.currentTarget.panels;

    panels.forEach((panel: any) => {
      panel.element.classList.remove('text-gray-8');
      panel.element.classList.remove('text-xl');
    });

    panels[index].element.classList.add('text-gray-8');
    panels[index].element.classList.add('text-xl');
  };

  const updatePanelStyles = (flickingRef: React.RefObject<Flicking>) => {
    if (flickingRef.current) {
      const control = flickingRef.current.control as Control;
      const activeIndex = control.activeIndex;
      const panels = flickingRef.current.panels;
      panels.forEach((panel: any, index: number) => {
        if (index === activeIndex) {
          panel.element.classList.add('text-gray-8');
          panel.element.classList.add('text-xl');
        } else {
          panel.element.classList.remove('text-gray-8');
          panel.element.classList.remove('text-xl');
        }
      });
    }
  };

  useEffect(() => {
    if (isShow) {
      updatePanelStyles(flickingAmPmRef);
      updatePanelStyles(flickingRef);
    }
  }, [isShow]);

  const calculateInitialValues = (defaultTime: number): [number, number] => {
    let amPmInitial = 0;
    let timeInitial = 0;

    if (defaultTime === 0) {
    } else if (defaultTime === 12) {
      amPmInitial = 1;
      timeInitial = 11;
    } else if (defaultTime < 12) {
      timeInitial = defaultTime - 1;
    } else {
      amPmInitial = 1;
      timeInitial = defaultTime - 13;
    }

    return [amPmInitial, timeInitial];
  };

  useEffect(() => {
    if (defaultTime) {
      const [amPmInitial, timeInitial] = calculateInitialValues(defaultTime);
      setAmPmInital(amPmInitial);
      setTimeInital(timeInitial);
    }
  }, [defaultTime]);

  return (
    <>
      {isShow && (
        <div>
          <div className="fixed bg-black opacity-75 w-full h-full justify-center items-center top-0 left-0 inline-flex z-40"></div>
          <div className="fixed z-50 bottom-0 left-0 w-full rounded-t-xl bg-white flex items-center justify-center shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] max-h-[70vh] overflow-scroll">
            <div className="absolute top-0 left-0 m-4 cursor-pointer" onClick={onClickClose}>
              <XMarkIcon color={colors.gray[9]}></XMarkIcon>
            </div>
            <div className="w-full max-h-[70vh] p-5 rounded-t-xl text-[14px] py-4">
              <h4 className="text-center text-base font-bold">{title ? title : '메뉴'}</h4>
              <div className="flex justify-center items-center pt-8 pb-24 gap-8">
                <Flicking
                  ref={flickingAmPmRef}
                  horizontal={false}
                  onReady={updateTransform}
                  onMove={updateTransform}
                  style={{ height: '100px' }}
                  defaultIndex={amPmInital}
                  onChanged={(e: ChangedEvent) => onChangeDate(e)}
                >
                  <div className="date-panel py-1 w-[84px] h-[40px] text-center text-gray-6">오전</div>
                  <div className="date-panel py-1 w-[84px] h-[40px] text-center text-gray-6">오후</div>
                  <ViewportSlot>
                    <div className="date-panel-border absolute top-[27px] left-0 px-[15px] w-[84px] h-[40px] border-t-2 border-b-2 border-gray-7 rounded-sm text-gray-8"></div>
                  </ViewportSlot>
                </Flicking>

                <div className="px-[15px] relative justify-center items-center w-[84px]">
                  <Flicking
                    ref={flickingRef}
                    horizontal={false}
                    onMove={updateTransform}
                    onReady={updateTransform}
                    style={{ height: '100px' }}
                    defaultIndex={timeInital}
                    onChanged={(e: ChangedEvent) => onChangeDate(e)}
                  >
                    {[...Array(12)].map((_, index) => (
                      <div key={index} className={`date-panel py-1 w-[84px] h-[40px] text-center text-gray-6`}>
                        {index + 1}
                      </div>
                    ))}
                    <ViewportSlot>
                      <div className="date-panel-border absolute top-[27px] left-0 px-[15px] w-[84px] h-[40px] border-t-2 border-b-2 border-gray-7 rounded-sm"></div>
                    </ViewportSlot>
                  </Flicking>
                </div>
              </div>
              <div className="flex items-center justify-center fixed w-full h-[80px] mx-auto my-0 px-3 bottom-0 left-0 right-0 h-16 max-w-[720px] min-w-[280px] bg-white">
                <Button
                  variant="contained"
                  className="w-full max-w-[672px] min-w-[180px]"
                  sx={{ fontSize: '16px', fontWeight: 'bold' }}
                  onClick={() => onClickApplyBtn()}
                >
                  적용
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DownToUpTimeSettingPopup;

import React from 'react';

import ConfirmModal from '../Common/Modal/ConfirmModal';

interface ConfirmExitPopup {
  isShow: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmExitPopup = ({ isShow, onClose, onConfirm }: ConfirmExitPopup) => {
  return (
    <ConfirmModal
      isOpen={isShow}
      title={`<span class="font-medium">정말 나가시겠어요?</span>`}
      content={
        <span>
          작성 중인 내용은 임시저장되며
          <br />
          언제든지 재등록이 가능합니다.
        </span>
      }
      leftButtonLabel="계속 등록"
      onLeftButtonClick={onClose}
      rightButtonLabel="나가기"
      onRightButtonClick={onConfirm}
    />
  );
};

export default ConfirmExitPopup;

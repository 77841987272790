import React, { Dispatch, useEffect, useRef, useState } from 'react';

import { XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface TagsProps {
  items: OptionDataType[];
  setItems: Dispatch<React.SetStateAction<OptionDataType[]>>;
  tagWidth: number;
  itemName: string;
  onClickDeleteBtn: (unselectedItem: OptionDataType, key: string) => void;
}

const MultiSelectTags = ({ items, tagWidth, setItems, itemName, onClickDeleteBtn }: TagsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxVisibleTags, setMaxVisibleTags] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const newMaxTags = Math.floor(containerWidth / tagWidth);
        setMaxVisibleTags(newMaxTags);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const visibleTags = items.slice(0, maxVisibleTags);
  const hiddenTagsCount = items.length - maxVisibleTags;

  const onClickDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, unselectedItem: OptionDataType) => {
    e.stopPropagation();

    if (onClickDeleteBtn && itemName) {
      onClickDeleteBtn(unselectedItem, itemName);
    }
    if (setItems) {
      setItems((prevItems) => {
        if (!prevItems) {
          return [];
        }
        const updatedItems = prevItems.filter((item) => item.code !== unselectedItem.code);
        return updatedItems;
      });
    }
  };

  return (
    <div ref={containerRef} className="flex flex-wrap">
      {visibleTags.map((item, index) => (
        <div key={index} className="flex items-center py-1 px-3 mr-2 rounded-[10px] bg-gray-1 text-base">
          <div className="flex items-center">
            <span className="text-gray-8">{item?.desc || item?.name}</span>
            <button className="flex items-center ml-2" onClick={(e) => onClickDelete(e, item)}>
              <XMarkIcon color={colors.gray[7]}></XMarkIcon>
            </button>
          </div>
        </div>
      ))}
      {items.length > maxVisibleTags && (
        <>
          <p>...</p>
          <div className="flex items-center py-1 px-3 mr-2 ml-2 rounded-[10px] bg-gray-1 text-base">
            <p className="text-gray-8">{hiddenTagsCount}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectTags;

import React, { useEffect, useState } from 'react';

import { PlusSmallIcon, UserSolidIcon } from '../Icon';
import apiManager from '@/api/AxiosInstance';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';

interface ProfileImageProps {
  isOnlyShow?: boolean;
}

const ProfileImage = ({ isOnlyShow = false }: ProfileImageProps) => {
  const { member, updateMember } = useMemberContext();
  const { showToast } = useToastContext();
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>(member?.profileImageUrl);

  const onChangeProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('uploadFile', file);

    if (file) {
      apiManager
        .post('/api/v1/members/profile', formData)
        .then((response) => {
          if (response.data && member) {
            updateMember({ ...member, profileImageUrl: response.data.url });
            setProfileImageUrl(response.data.url);
          }
        })
        .catch((error) => {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        });
    }
  };

  const onUploadClick = () => {
    const inputElement = document.getElementById(`upload-profile`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  useEffect(() => {
    setProfileImageUrl(member?.profileImageUrl);
  }, [member?.profileImageUrl]);

  return (
    <div className={`flex items-center justify-center rounded-full bg-white w-[48px] h-[48px] m-1`}>
      <div
        className={`w-[48px] h-[48px] absolute rounded-full ${isOnlyShow ? '' : 'cursor-pointer'} ${
          profileImageUrl ? 'overflow-hidden' : ''
        }`}
        onClick={onUploadClick}
      >
        {profileImageUrl ? (
          <img src={profileImageUrl} alt="프로필이미지" className="w-full h-full" />
        ) : (
          <div className="relative w-[48px] h-[48px] bg-white rounded-full flex justify-center items-center border border-gray-3">
            <UserSolidIcon color={colors.gray[3]}></UserSolidIcon>
            {!isOnlyShow && (
              <div className="absolute bottom-0 right-0 flex items-center justify-center w-5 h-5 rounded-full shadow-md bg-gray-2">
                <PlusSmallIcon color={colors.gray[7]} width={16} height={16}></PlusSmallIcon>
              </div>
            )}
          </div>
        )}

        <input
          id="upload-profile"
          accept="image/*"
          type="file"
          onChange={(e) => onChangeProfileImage(e)}
          className="hidden"
          disabled={isOnlyShow}
        />
      </div>
    </div>
  );
};

export default ProfileImage;

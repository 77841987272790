import React from 'react';

import { colors } from '@/const/colors';
import { Avatar } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/system';

const PurchaseAccompanyingServiceChatListSkeleton = () => {
  const skeletonItem = (
    <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '4px', paddingY: '12px', marginY: '20px' }}>
      <Skeleton variant="circular" width={24} height={24} sx={{ bgcolor: colors.gray[2] }}>
        <Avatar />
      </Skeleton>
      <div className="flex flex-col flex-1 w-full ml-[9px] mx-[30px] gap-1">
        <Skeleton width="80%" variant="rounded" height={17} sx={{ bgcolor: colors.gray[2] }}></Skeleton>
        <Skeleton width="60%" variant="rounded" height={17} sx={{ bgcolor: colors.gray[2] }} />
        <Skeleton width="70%" variant="rounded" height={17} sx={{ bgcolor: colors.gray[2] }} />
      </div>
      <div className="xxs:hidden">
        <Skeleton variant="rounded" width={52} height={52} sx={{ bgcolor: colors.gray[2] }} />
      </div>
    </Box>
  );

  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <React.Fragment key={index}>{skeletonItem}</React.Fragment>
      ))}
    </>
  );
};

export default PurchaseAccompanyingServiceChatListSkeleton;

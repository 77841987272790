import React, { SVGProps } from 'react';

const SvgSearchOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M16.13 6.078c-2.771-2.77-7.28-2.77-10.051 0A7.059 7.059 0 0 0 4 11.103c0 1.9.738 3.685 2.079 5.026a7.059 7.059 0 0 0 5.025 2.078 7.053 7.053 0 0 0 4.456-1.57l3.14 3.14a.757.757 0 0 0 .538.223.762.762 0 0 0 .539-1.3l-3.14-3.14a7.053 7.053 0 0 0 1.57-4.457c0-1.9-.738-3.685-2.078-5.025Zm-1.078 8.973a5.59 5.59 0 0 1-7.896 0 5.589 5.589 0 0 1 0-7.895 5.546 5.546 0 0 1 3.948-1.633c1.493 0 2.895.58 3.948 1.633a5.589 5.589 0 0 1 0 7.895Z"
    />
  </svg>
);
SvgSearchOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSearchOutlineIcon;

import apiManager from '../AxiosInstance';

export const createPurchaseAccompanyingServices = (request: AdditionalServicesApplyForm) => {
  return apiManager.post('/api/v1/purchase-accompanying-services', request);
};

export const createTransferAgencyServices = (request: AdditionalServicesApplyForm) => {
  return apiManager.post('/api/v1/transfer-agency-services', request);
};

export const createCapitalCounselServices = (request: AdditionalServicesApplyForm) => {
  return apiManager.post('/api/v1/capital-counsel-services', request);
};

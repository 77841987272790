import React, { SVGProps } from 'react';

const SvgRadioIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <rect width={24} height={24} fill={props.color || 'currentColor'} rx={12} />
  </svg>
);
SvgRadioIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgRadioIcon;

import { AxiosError } from 'axios';
import React, { Dispatch, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicButton from '@/components/Common/Button/BasicButton';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import CheckBoxList from '@/components/Common/Input/CheckBox/CheckBoxList';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import MultiSelectBox from '@/components/Common/Input/SelectBox/MultiSelectBox';
import SliderComponent from '@/components/Common/Input/Slider/SliderComponent';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { colors } from '@/const/colors';
import { ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS } from '@/const/errorCode';
import {
  COMMON_TOAST_ERROR,
  DISTANCE_IS_LESS_THAN_2_MILLION,
  MAX_IS_BIGGER_ERROR,
  MIN_IS_SMALLER_ERROR,
  TONS_IS_LESS_THAN_27,
  TONS_IS_OVER_1,
  YEAR_IS_LESS_THAN_CURRENT,
  YEAR_IS_OVER_2000,
} from '@/const/errorMessage';
import { positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { formatValueToDecimalPlaces, getCurrentYear, updateSearchParams } from '@/utils/common';
import { validateLength } from '@/utils/validation';

interface ProductInterestRequestType {
  axis: string | null;
  loaded: string | null;
  maxDistance: number | null;
  maxLoadedInnerLength: number | null;
  maxTons: number | null;
  maxYear: number | null;
  minDistance: number | null;
  minLoadedInnerLength: number | null;
  manufacturerCategoriesId: number | undefined | null;
  minTons: number | null;
  minYear: number | null;
  transmission: string | null;
}

const NotificationProductsSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const currentYear = getCurrentYear();
  const { member } = useMemberContext();
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '관심 차량 등록 완료',
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  const settingParams = location?.state?.data;
  const [settingOption, setSettingOption] = useState<InterestProductSetting>({
    manufacturerCategoriesId: new Set(settingParams?.manufacturerCategories),
    minYear: settingParams?.minYear || 2000,
    maxYear: settingParams?.maxYear || getCurrentYear() + 1,
    minTons: settingParams?.minTons || 1,
    maxTons: settingParams?.maxTons || 27,
    minDistance: settingParams?.minDistance || '0',
    maxDistance: settingParams?.maxDistance || 200,
    axis: settingParams?.axis,
    transmission: settingParams?.transmission,
    loaded: new Set(settingParams?.loaded),
    minLoadedInnerLength: settingParams?.minLoadedInnerLength,
    maxLoadedInnerLength: settingParams?.maxLoadedInnerLength,
  });

  const [optionData, setOptionData] = useState<FilterDataType[]>([]);
  const [title, setTitle] = useState('');
  const [isShow, setIsShow] = useState(false);

  const [selectedManufacturerCategories, setSelectedManufacturerCategories] = useState<OptionDataType[]>([]);
  const [selectedLoadeds, setSelectedLoadeds] = useState<OptionDataType[]>([]);
  const [selectedAxis, setSelectedAxis] = useState<OptionDataType[]>([]);
  const [selectedTransmission, setSelectedTransmission] = useState<OptionDataType[]>([]);
  const { showToast } = useToastContext();

  const [loadedMinInnerLengthError, setLoadedMinInnerLengthError] = useState(false);
  const [loadedMaxInnerLengthError, setLoadedMaxInnerLengthError] = useState(false);
  const [loadedMinInnerLengthErrorMsg, setLoadedMinInnerLengthErrorMsg] = useState('');
  const [loadedMaxInnerLengthErrorMsg, setLoadedMaxInnerLengthErrorMsg] = useState('');

  const [minYearError, setMinYearError] = useState(false);
  const [maxYearError, setMaxYearError] = useState(false);
  const [minTonsError, setMinTonsError] = useState(false);
  const [maxTonsError, setMaxTonsError] = useState(false);
  const [minDistanceError, setMinDistanceError] = useState(false);
  const [maxDistanceError, setMaxDistanceError] = useState(false);
  const [minYearErrorMsg, setMinYearErrorMsg] = useState('');
  const [maxYearErrorMsg, setMaxYearErrorMsg] = useState('');
  const [minTonsErrorMsg, setMinTonsErrorMsg] = useState('');
  const [maxTonsErrorMsg, setMaxTonsErrorMsg] = useState('');
  const [minDistanceErrorMsg, setMinDistanceErrorMsg] = useState('');
  const [maxDistanceErrorMsg, setMaxDistanceErrorMsg] = useState('');

  const [axisOptionData, setAxisOptionData] = useState<OptionType[]>([]);
  const [transmissionOptionData, setTransmissionOptionData] = useState<OptionType[]>([]);

  const [filterInfo, setFilterInfo] = useState<ProductsFilterInfo>({
    axis: [{ axis: { code: '', desc: '' }, count: 0 }],
    loaded: [{ loaded: { code: '', desc: '' }, count: 0 }],
    manufacturerCategories: [{ manufacturerCategories: { id: 0, code: '', name: '' }, count: 0 }],
    transmission: [{ transmission: { code: '', desc: '' }, count: 0 }],
  });

  const keyValueList: KeyValueListType = {
    manufacturerCategories: '제조사',
    loaded: '적재함 종류',
  };

  useEffect(() => {
    setSelectedManufacturerCategories(Array.from(settingOption.manufacturerCategoriesId));
    setSelectedLoadeds(Array.from(settingOption.loaded));
  }, []);

  const onChangeSliderInput = (e: Event, values: number | number[], key: string) => {
    if (!Array.isArray(values)) {
      return;
    }
    setSettingOption({ ...settingOption, [`min${key}`]: values[0].toString(), [`max${key}`]: values[1].toString() });
  };

  useEffect(() => {
    apiManager
      .get('/api/v1/public/products/filter-info')
      .then((response) => {
        const responseData: ProductsFilterInfo = response.data;
        setFilterInfo(responseData);

        const transformedAxisData = responseData.axis.map((item) => ({
          code: item.axis.code,
          desc: item.axis.desc,
        }));
        setAxisOptionData(transformedAxisData);

        const transformedTransmissionData = responseData.transmission.map((item) => ({
          code: item.transmission.code,
          desc: item.transmission.desc,
        }));
        setTransmissionOptionData(transformedTransmissionData);
      })
      .catch(() => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  }, []);

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber: boolean) => {
    const { name, value } = e.target;
    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(value) && !value.startsWith('-')) {
        const str = value === '' ? null : value;
        setSettingOption({ ...settingOption, [name]: str });

        switch (name) {
          case 'minYear':
            if (Number(value) > Number(settingOption.maxYear)) {
              setMinYearError(true);
              setMinYearErrorMsg(MIN_IS_SMALLER_ERROR);
              return;
            } else if (Number(value) < 2000) {
              setMinYearError(true);
              setMinYearErrorMsg(YEAR_IS_OVER_2000);
              return;
            } else {
              setMinYearError(false);
              setMinYearErrorMsg('');
            }
            break;
          case 'maxYear':
            if (Number(value) < Number(settingOption.minYear)) {
              setMaxYearError(true);
              setMaxYearErrorMsg(MAX_IS_BIGGER_ERROR);
              return;
            } else if (Number(value) > currentYear + 1) {
              setMaxYearError(true);
              setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
              return;
            } else {
              setMaxYearError(false);
              setMaxYearErrorMsg('');
            }
            break;
          case 'minDistance':
            if (!settingOption.maxDistance) return;

            if (Number(value) > Number(settingOption.maxDistance)) {
              setMinDistanceError(true);
              setMinDistanceErrorMsg(MIN_IS_SMALLER_ERROR);
              return;
            } else if (Number(value) > 200) {
              setMinDistanceError(true);
              setMinDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
              return;
            } else {
              setMinDistanceError(false);
              setMinDistanceErrorMsg('');
            }
            break;
          case 'maxDistance':
            if (!settingOption.minDistance) return;
            if (Number(value) < Number(settingOption.minDistance)) {
              setMaxDistanceError(true);
              setMaxDistanceErrorMsg(MAX_IS_BIGGER_ERROR);
              return;
            } else if (Number(value) > 200) {
              setMaxDistanceError(true);
              setMaxDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
              return;
            } else {
              setMaxDistanceError(false);
              setMaxDistanceErrorMsg('');
            }
            break;
        }
      }
    } else if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = value.trim() === '' ? null : value.trim();
      setSettingOption({ ...settingOption, [name]: str });

      switch (name) {
        case 'minTons':
          if (Number(value) > Number(settingOption.maxTons)) {
            setMinTonsError(true);
            setMinTonsErrorMsg(MIN_IS_SMALLER_ERROR);
            return;
          } else if (Number(value) < 1) {
            setMinTonsError(true);
            setMinTonsErrorMsg(TONS_IS_OVER_1);
            return;
          } else {
            setMinTonsError(false);
            setMinTonsErrorMsg('');
          }
          break;
        case 'maxTons':
          if (Number(value) < Number(settingOption.minTons)) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(MAX_IS_BIGGER_ERROR);
            return;
          } else if (Number(value) > 27) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
            return;
          } else {
            setMaxTonsError(false);
            setMaxTonsErrorMsg('');
          }
          break;
      }
    }
  };

  const onClickSelectBox = (key: string) => {
    if (!filterInfo) {
      return;
    }

    switch (key) {
      case 'manufacturerCategories':
        if (filterInfo.manufacturerCategories) {
          setOptionData(
            filterInfo?.manufacturerCategories.map(
              (item) =>
                ({
                  id: item.manufacturerCategories.id,
                  code: item.manufacturerCategories.code,
                  desc: item.manufacturerCategories.name,
                } as FilterDataType),
            ),
          );
        }
        break;
      case 'loaded':
        setOptionData(
          filterInfo.loaded.map(
            (item) =>
              ({
                code: item.loaded?.code,
                desc: item.loaded?.desc,
              } as FilterDataType),
          ),
        );
        break;
      case 'axis':
        setOptionData(
          filterInfo.axis.map(
            (item) =>
              ({
                code: item?.axis?.code,
                desc: item?.axis?.desc,
              } as FilterDataType),
          ),
        );
        break;

      case 'transmission':
        setOptionData(
          filterInfo.transmission.map(
            (item) =>
              ({
                code: item?.transmission?.code,
                desc: item?.transmission?.desc,
              } as FilterDataType),
          ),
        );
        break;
    }
    setTitle(key);
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    switch (key) {
      case 'manufacturerCategories':
        if (
          selectedManufacturerCategories.includes(item as OptionDataType) ||
          selectedManufacturerCategories.some((selectedItem) => selectedItem.id === item?.id)
        ) {
          onClickDeleteOptionData(setSelectedManufacturerCategories, item);
        } else {
          setSelectedManufacturerCategories((prevItems) => {
            return [...prevItems, item];
          });
        }
        break;
      case 'loaded':
        if (selectedLoadeds.includes(item) || selectedLoadeds.some((selectedItem) => selectedItem.code === item.code)) {
          onClickDeleteOptionData(setSelectedLoadeds, item);
        } else {
          setSelectedLoadeds((prevItems) => {
            return [...prevItems, item];
          });
        }
        break;
      case 'axis':
        if (selectedAxis.includes(item) || selectedAxis.some((selectedItem) => selectedItem.code === item.code)) {
          return;
        } else {
          if (selectedAxis?.length < 1) {
            setSelectedAxis((prevItems) => {
              return [...prevItems, item];
            });
          } else {
            setSelectedAxis(() => {
              return [item];
            });
          }
          setTimeout(() => {
            setSettingOption({
              ...settingOption,
              axis: item as EnumPresenter,
            });
            setIsShow(false);
          }, 200);
        }
        break;

      case 'transmission':
        if (
          selectedTransmission.includes(item) ||
          selectedTransmission.some((selectedItem) => selectedItem.code === item.code)
        ) {
          return;
        } else {
          if (selectedTransmission?.length < 1) {
            setSelectedTransmission((prevItems) => {
              return [...prevItems, item];
            });
          } else {
            setSelectedTransmission(() => {
              return [item];
            });
          }
          setTimeout(() => {
            setSettingOption({
              ...settingOption,
              transmission: item as EnumPresenter,
            });
            setIsShow(false);
          }, 200);
        }
        break;
    }
  };

  const removeItem = (item: OptionDataType, key: string) => {
    setSettingOption((prev) => {
      if (prev[key]) {
        const newSet = new Set(prev[key]);
        newSet.delete(item);
        return {
          ...prev,
          [key]: newSet,
        };
      }
      return prev;
    });
  };

  const onClickDeleteOptionData = (
    setData: Dispatch<React.SetStateAction<OptionDataType[]>>,
    unselectedItem: OptionDataType,
  ) => {
    setData((prevItems) => {
      if (!prevItems) {
        return [];
      }

      const updatedItems = prevItems.filter((item) => item.code !== unselectedItem.code);
      return updatedItems;
    });
  };

  const applySelectedPopUp = (key?: string) => {
    setIsShow(false);
    setSettingOption({
      ...settingOption,
      manufacturerCategoriesId: new Set(selectedManufacturerCategories),
      loaded: new Set(selectedLoadeds),
    });
  };

  const onClickUpdateSetting = () => {
    const { loaded } = settingOption;
    if (loaded?.size === 0) {
      showToast('필수값을 입력해주세요.', 'error', 'bottom');
      return;
    }
    if (id) {
      patchInterestProductMutate(settingOption);
    } else {
      setInterestProductMutate(settingOption);
    }
  };

  const setInterestProduct = async (settingOption: InterestProductSetting): Promise<any> => {
    const {
      axis,
      loaded,
      maxDistance,
      maxLoadedInnerLength,
      maxTons,
      maxYear,
      minDistance,
      minLoadedInnerLength,
      manufacturerCategoriesId,
      minTons,
      minYear,
      transmission,
    } = settingOption;

    const manufacturerCategoriesIdString = Array.from(manufacturerCategoriesId)
      .map((item) => item.id)
      .join(',');
    const loadedString = Array.from(loaded)
      .map((item) => item.code)
      .join(',');

    const requestData: Partial<ProductInterestRequestType> = {
      axis: axis ? axis.code : null,
      loaded: loadedString === '' ? null : loadedString,
      maxDistance: maxDistance ? Number(maxDistance) : null,
      maxLoadedInnerLength: maxLoadedInnerLength,
      maxTons: maxTons,
      maxYear: maxYear,
      minDistance: minDistance ? Number(minDistance) : null,
      minLoadedInnerLength: minLoadedInnerLength,
      manufacturerCategoriesId: manufacturerCategoriesIdString === '' ? null : Number(manufacturerCategoriesIdString),
      minTons: minTons,
      minYear: minYear,
      transmission: transmission ? transmission.code : null,
    };

    if (Number(requestData.minTons) === 1 && Number(requestData.maxTons === 27)) {
      delete requestData.minTons;
      delete requestData.maxTons;
    }
    if (Number(requestData.minYear) === 2000 && Number(requestData.maxYear) === currentYear + 1) {
      delete requestData.minYear;
      delete requestData.maxYear;
    }
    if (Number(requestData.minDistance) === 0 && Number(requestData.maxDistance) === 200) {
      delete requestData.minDistance;
      delete requestData.maxDistance;
    }
    const { data } = await apiManager.post(`/api/v1/interest-product-notification-settings`, requestData);
    return data;
  };

  const closeBasicPopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const { mutate: setInterestProductMutate } = useMutation(setInterestProduct, {
    onSuccess: () => {
      setPopupInfo({
        isShow: true,
        title: '관심 차량 등록 완료',
        textContent: '<span class="text-lg text-gray-8">관심 차량이 신규 입고되면<br/>알림을 보내드릴게요.</span>',
        textLeftBtn: member?.notificationSettings.interestProduct ? '' : '닫기',
        onClickLeftBtn: closeBasicPopup,
        textRightBtn: member?.notificationSettings.interestProduct ? '확인' : '알림 설정',
        onClickRightBtn: () => {
          closeBasicPopup();
          if (member?.notificationSettings.interestProduct) {
            navigate('/notification/products');
          } else {
            navigate('/setting/notification');
          }
        },
        minWidth: 320,
      });
    },
    onError: (error: AxiosError) => {
      if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const patchInterestProductNotificationSettings = async (settingOption: InterestProductSetting): Promise<any> => {
    const {
      axis,
      loaded,
      maxDistance,
      maxLoadedInnerLength,
      maxTons,
      maxYear,
      minDistance,
      minLoadedInnerLength,
      manufacturerCategoriesId,
      minTons,
      minYear,
      transmission,
    } = settingOption;

    const manufacturerCategoriesIdString = Array.from(manufacturerCategoriesId)
      .map((item) => item.id)
      .join(',');
    const loadedString = Array.from(loaded)
      .map((item) => item.code)
      .join(',');

    const requestData: Partial<ProductInterestRequestType> = {
      axis: axis ? axis.code : null,
      loaded: loadedString === '' ? null : loadedString,
      maxDistance: maxDistance ? Number(maxDistance) : null,
      maxLoadedInnerLength: maxLoadedInnerLength,
      maxTons: maxTons,
      maxYear: maxYear,
      minDistance: minDistance ? Number(minDistance) : null,
      minLoadedInnerLength: minLoadedInnerLength,
      manufacturerCategoriesId: manufacturerCategoriesIdString === '' ? null : Number(manufacturerCategoriesIdString),
      minTons: minTons,
      minYear: minYear,
      transmission: transmission ? transmission.code : null,
    };

    if (Number(requestData.minTons) === 1 && Number(requestData.maxTons === 27)) {
      delete requestData.minTons;
      delete requestData.maxTons;
    }
    if (Number(requestData.minYear) === 2000 && Number(requestData.maxYear) === currentYear + 1) {
      delete requestData.minYear;
      delete requestData.maxYear;
    }
    if (Number(requestData.minDistance) === 0 && Number(requestData.maxDistance) === 200) {
      delete requestData.minDistance;
      delete requestData.maxDistance;
    }
    const { data } = await apiManager.patch(`/api/v1/interest-product-notification-settings/${id}`, requestData);
    return data;
  };

  const { mutate: patchInterestProductMutate } = useMutation(patchInterestProductNotificationSettings, {
    onSuccess: () => {
      showToast('수정이 완료되었어요.', 'success', 'bottom');
    },
    onError: (error: AxiosError) => {
      if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      const { updatedParams, error, errorMsg } = updateSearchParams(settingOption, key, valueNum);

      setSettingOption(updatedParams);

      switch (key) {
        case 'minLoadedInnerLength':
          setLoadedMinInnerLengthError(error);
          setLoadedMinInnerLengthErrorMsg(errorMsg);
          break;
        case 'maxLoadedInnerLength':
          setLoadedMaxInnerLengthError(error);
          setLoadedMaxInnerLengthErrorMsg(errorMsg);
          break;
      }
    }
  };

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setSettingOption({ ...settingOption, [key]: newVal });
  };

  const clear = () => {
    setSettingOption({
      manufacturerCategoriesId: new Set(),
      minYear: 2000,
      maxYear: currentYear + 1,
      minTons: 1,
      maxTons: 27,
      minDistance: '0',
      maxDistance: 200,
      axis: { code: '', desc: '' },
      transmission: { code: '', desc: '' },
      loaded: new Set(),
      minLoadedInnerLength: null,
      maxLoadedInnerLength: null,
    });
    setSelectedManufacturerCategories([]);
    setSelectedLoadeds([]);

    setLoadedMaxInnerLengthError(false);
    setLoadedMinInnerLengthError(false);
  };

  useEffect(() => {
    if (settingOption.minYear && settingOption.maxYear) {
      if (Number(settingOption.minYear) <= Number(settingOption.maxYear)) {
        setMinYearError(false);
        setMinYearErrorMsg('');
        setMaxYearError(false);
        setMaxYearErrorMsg('');
      }
      if (Number(settingOption.minYear) < 2000) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(settingOption.minYear) > currentYear + 1) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
      if (Number(settingOption.maxYear) < 2000) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(settingOption.maxYear) > currentYear + 1) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
    }
  }, [settingOption.minYear, settingOption.maxYear]);

  useEffect(() => {
    if (settingOption.minTons && settingOption.maxTons) {
      if (Number(settingOption.minTons) <= Number(settingOption.maxTons)) {
        setMinTonsError(false);
        setMinTonsErrorMsg('');
        setMaxTonsError(false);
        setMaxTonsErrorMsg('');
      }
      if (Number(settingOption.minTons) < 1) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(settingOption.maxTons) < 1) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(settingOption.minTons) > 27) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
      if (Number(settingOption.maxTons) > 27) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
    }
  }, [settingOption.minTons, settingOption.maxTons]);

  useEffect(() => {
    if (settingOption.minDistance && settingOption.maxDistance) {
      if (Number(settingOption.minDistance) <= Number(settingOption.maxDistance)) {
        setMinDistanceError(false);
        setMinDistanceErrorMsg('');
        setMaxDistanceError(false);
        setMaxDistanceErrorMsg('');
      }
      if (Number(settingOption.minDistance) > 200) {
        setMinDistanceError(true);
        setMinDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
      }
      if (Number(settingOption.maxDistance) > 200) {
        setMaxDistanceError(true);
        setMaxDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
      }
    }
  }, [settingOption.minDistance, settingOption.maxDistance]);

  const handleChangeLoadedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = formatValueToDecimalPlaces(value, 1);
    const isInvalidInput = formattedValue.startsWith('0') && formattedValue.length > 1;

    if (isInvalidInput) {
      setSettingOption({ ...settingOption, [name]: '0' });
      return;
    }

    const oppositeLength =
      name === 'minLoadedInnerLength' ? settingOption.maxLoadedInnerLength : settingOption.minLoadedInnerLength;
    const isMin = name === 'minLoadedInnerLength';
    const { isValid, errorMessage, bothValuesValid } = validateLength(formattedValue, 1, 10.5, oppositeLength, isMin);

    const updateErrorState = (isError: boolean, message: string) => {
      if (isMin) {
        setLoadedMinInnerLengthError(isError);
        setLoadedMinInnerLengthErrorMsg(message);
      } else {
        setLoadedMaxInnerLengthError(isError);
        setLoadedMaxInnerLengthErrorMsg(message);
      }
    };

    if (!isValid) {
      updateErrorState(true, errorMessage);
    } else {
      updateErrorState(false, '');
    }

    if (bothValuesValid) {
      setLoadedMinInnerLengthError(false);
      setLoadedMinInnerLengthErrorMsg('');
      setLoadedMaxInnerLengthError(false);
      setLoadedMaxInnerLengthErrorMsg('');
    }

    if (positiveFloatRegex.test(formattedValue.trim()) || formattedValue.trim() === '') {
      setSettingOption({ ...settingOption, [name]: formattedValue.trim() === '' ? null : formattedValue.trim() });
    }
  };

  const getDisabledApplyBtn = () => {
    return (
      loadedMinInnerLengthError ||
      loadedMaxInnerLengthError ||
      minYearError ||
      maxYearError ||
      minTonsError ||
      maxTonsError ||
      minDistanceError ||
      maxDistanceError ||
      selectedLoadeds?.length === 0 ||
      (!!settingOption.minLoadedInnerLength && !settingOption.maxLoadedInnerLength) ||
      (!!settingOption.maxLoadedInnerLength && !settingOption.minLoadedInnerLength)
    );
  };

  return (
    <>
      <MenuHeader title={id ? '관심 차량 수정' : '관심 차량 등록'}></MenuHeader>
      <div className="pt-[60px] pb-[66px] w-full">
        {id == null && (
          <div className="px-4">
            <div className="rounded-lg bg-blue-0 px-4 py-[19px] mb-6 flex flex-col text-gray-8">
              <h4 className="text-lg font-bold text-gray-8 mb-2">관심 차량을 등록해주세요.</h4>
              <p className="text-sm text-gray-8 break-keep">
                찾고있는 차량이 없다면 관심 차량을 등록하고 알림을 받아보세요.
              </p>
            </div>
          </div>
        )}

        <div className="text-base border-t border-gray-3">
          <div className="flex flex-col justify-center pt-4 pb-4 px-4">
            <p className="font-semibold text-gray-8">
              적재함 종류<span className="font-normal text-red">(필수)</span>
            </p>
            <MultiSelectBox
              label="적재함 종류 선택"
              placeholder="적재함 종류 선택"
              items={selectedLoadeds}
              setItems={setSelectedLoadeds}
              onClickDeleteBtn={removeItem}
              itemName="loaded"
              tagWidth={170}
              onClickSelectBox={onClickSelectBox}
              selectBoxKey="loaded"
            />
          </div>
        </div>

        <div className="text-base border-t-8 border-gray-1 px-4 py-[30px] relative">
          <p className="font-semibold mb-2 text-gray-8">적재함 길이(내측 사이즈)</p>
          <div className="flex justify-center items-center mb-2">
            <TextInputLabelOff
              name="minLoadedInnerLength"
              placeholder="최소 길이"
              value={String(settingOption?.minLoadedInnerLength || '')}
              onChange={(e) => handleChangeLoadedInput(e)}
              onBlur={(e) => handleInputBlur(e, 'minLoadedInnerLength')}
              error={loadedMinInnerLengthError}
              errorMsg={loadedMinInnerLengthErrorMsg}
              height={36}
              fontSize={18}
              suffix="m"
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxLoadedInnerLength"
              placeholder="최대 길이"
              value={String(settingOption?.maxLoadedInnerLength || '')}
              onChange={(e) => handleChangeLoadedInput(e)}
              onBlur={(e) => handleInputBlur(e, 'maxLoadedInnerLength')}
              error={loadedMaxInnerLengthError}
              errorMsg={loadedMaxInnerLengthErrorMsg}
              height={36}
              fontSize={18}
              suffix="m"
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">연식</p>
          <SliderComponent
            name="Year"
            value={[Number(settingOption.minYear), Number(settingOption.maxYear)]}
            onChange={(event, value) => onChangeSliderInput(event, value, 'Year')}
            step={1}
            min={2000}
            max={currentYear + 1}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minYear"
              placeholder="최소 연식"
              value={String(settingOption?.minYear || '')}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, true)}
              error={minYearError}
              errorMsg={minYearErrorMsg}
              height={36}
              fontSize={18}
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxYear"
              placeholder="최대 연식"
              value={String(settingOption?.maxYear || '')}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, true)}
              error={maxYearError}
              errorMsg={maxYearErrorMsg}
              height={36}
              fontSize={18}
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">톤수</p>
          <SliderComponent
            name="Tons"
            value={[Number(settingOption.minTons), Number(settingOption.maxTons)]}
            onChange={(e, value) => onChangeSliderInput(e, value, 'Tons')}
            step={1}
            min={1}
            max={27}
          ></SliderComponent>
          <div className="flex justify-center items-center">
            <TextInputLabelOff
              name="minTons"
              placeholder="최소 톤수"
              value={String(settingOption?.minTons || '')}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, false)}
              error={minTonsError}
              errorMsg={minTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
            <p className="text-gray-6 text-base mx-2">~</p>
            <TextInputLabelOff
              name="maxTons"
              placeholder="최대 톤수"
              value={String(settingOption?.maxTons || '')}
              inputMode="numeric"
              onChange={(e) => onChangeFormInput(e, false)}
              error={maxTonsError}
              errorMsg={maxTonsErrorMsg}
              height={36}
              fontSize={18}
              suffix="t"
              maxLength={4}
            ></TextInputLabelOff>
          </div>
        </div>

        <div className="text-base border-t-8 border-gray-1 px-4 py-[30px]">
          <p className="font-semibold mb-2 text-gray-8">가변축</p>
          <div className="px-1">
            <RadioList
              name="axis"
              list={axisOptionData}
              value={settingOption.axis?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'axis');
              }}
            ></RadioList>
          </div>
        </div>

        <div className="text-base border-t border-gray-3">
          <div className="flex flex-col justify-center pt-4 pb-4 px-4">
            <p className="font-semibold text-gray-8">제조사</p>
            <MultiSelectBox
              label="제조사 선택"
              placeholder="제조사 선택"
              items={selectedManufacturerCategories}
              setItems={setSelectedManufacturerCategories}
              onClickDeleteBtn={removeItem}
              itemName="manufacturerCategoriesId"
              tagWidth={120}
              onClickSelectBox={onClickSelectBox}
              selectBoxKey="manufacturerCategories"
            />
          </div>
        </div>

        <div className="text-base border-t-8 border-gray-1 px-4 pt-[30px] pb-[40px]">
          <p className="font-semibold text-gray-8">주행거리</p>
          <SliderComponent
            name="Distance"
            value={[Number(settingOption?.minDistance) || 0, Number(settingOption?.maxDistance) || 200]}
            onChange={(event, value) => onChangeSliderInput(event, value, 'Distance')}
            step={5}
            min={0}
            max={200}
          ></SliderComponent>
          <div className="flex justify-center pb-3">
            <TextInputLabelOff
              name="minDistance"
              placeholder={`최소 주행거리`}
              value={String(settingOption.minDistance || '')}
              onChange={(e) => onChangeFormInput(e, true)}
              error={minDistanceError}
              errorMsg={minDistanceErrorMsg}
              inputMode="numeric"
              suffix="만km"
              maxLength={3}
            />
            <p className="text-[#919191] text-sm mx-2">~</p>
            <TextInputLabelOff
              name="maxDistance"
              placeholder={`최대 주행거리`}
              value={String(settingOption.maxDistance || '')}
              onChange={(e) => onChangeFormInput(e, true)}
              error={maxDistanceError}
              errorMsg={maxDistanceErrorMsg}
              inputMode="numeric"
              suffix="만km"
              maxLength={3}
            />
          </div>
        </div>

        <div className="text-base border-t border-gray-3 px-4 py-[30px]">
          <p className="font-semibold mb-2 text-gray-8">변속기</p>
          <div className="px-1">
            <RadioList
              name="transmission"
              list={transmissionOptionData}
              value={settingOption.transmission?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'transmission');
              }}
            ></RadioList>
          </div>
        </div>
      </div>

      <DualFooterButton
        leftButtonText="초기화"
        onClickLeftButton={clear}
        rightButtonText={id ? '적용' : '등록'}
        onClickRightButton={onClickUpdateSetting}
        disabledRight={getDisabledApplyBtn()}
      ></DualFooterButton>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        {(title === 'manufacturerCategories' || title === 'loaded') && (
          <>
            <div className="px-4 pb-20">
              <CheckBoxList
                optionData={optionData}
                selectedData={title === 'loaded' ? selectedLoadeds : selectedManufacturerCategories}
                onClickItem={(item) => onClickItem(item, title)}
              ></CheckBoxList>
              <div className="flex items-center justify-center fixed w-full px-3 pb-4 pt-0 mx-auto my-0  bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white">
                <BasicButton
                  name="적용"
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  fontWeight="bold"
                  onClick={() => applySelectedPopUp()}
                ></BasicButton>
              </div>
            </div>
          </>
        )}
      </DownToUpDetailPopup>
      <BasicPopup
        isShow={popupInfo.isShow}
        title="관심 차량 등록 완료"
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
        minWidth={popupInfo.minWidth}
      ></BasicPopup>
    </>
  );
};
export default NotificationProductsSettings;

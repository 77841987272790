import React, { SVGProps } from 'react';

const SvgChatOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M20.898 6.276A2.278 2.278 0 0 0 18.622 4H5.382a2.277 2.277 0 0 0-2.277 2.276v8.552a2.278 2.278 0 0 0 2.276 2.276h.828c.114 0 .207.093.207.207v1.655a1.036 1.036 0 0 0 1.655.827l3.531-2.648a.203.203 0 0 1 .124-.041h6.896a2.276 2.276 0 0 0 2.276-2.276V6.276ZM7.658 18.552v-1.241c0-.8-.649-1.449-1.45-1.449h-.827a1.036 1.036 0 0 1-1.034-1.034V6.276A1.038 1.038 0 0 1 5.38 5.24h13.241a1.036 1.036 0 0 1 1.035 1.035v8.552c0 .571-.463 1.034-1.035 1.034h-6.896c-.313 0-.618.101-.869.29l-3.2 2.4Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgChatOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgChatOutlineIcon;

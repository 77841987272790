import React, { ReactNode, createContext, useContext, useState } from 'react';

type LicenseEnumContextType = {
  licenseEnum: LicenseEnum;
  setLicenseEnum: (licenseEnum: LicenseEnum) => void;
};

const LicenseEnumContext = createContext<LicenseEnumContextType | null>(null);

const LicenseEnumProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [licenseEnum, setLicenseEnum] = useState<LicenseEnum>({
    licenseType: [],
    licenseSalesType: [],
    locate: [],
    useClassification: [],
  });
  return <LicenseEnumContext.Provider value={{ licenseEnum, setLicenseEnum }}>{children}</LicenseEnumContext.Provider>;
};

export const useLicenseEnumContext = () => {
  const context = useContext(LicenseEnumContext) as LicenseEnumContextType;
  if (context === undefined) {
    throw new Error('useLicenseEnumContext must be used within a LicesenEnumProvider');
  }
  return context;
};

export { LicenseEnumContext, LicenseEnumProvider };

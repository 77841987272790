import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import BasicPopup from '../Common/Popup/BasicPopup';
import apiManager from '@/api/AxiosInstance';
import { AFTER_CONSULTING, BEFORE_CONSULTING, BEFORE_SALE, SALE } from '@/const/productStatus';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface ProductTypeAndStatusProps {
  id: number;
  type: EnumPresenter | undefined;
  status: EnumPresenter | undefined;
  setProductList?: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
  statusOfSpeedProduct?: EnumPresenter;
}

const showText = (code: string | undefined) => {
  if (code == PRODUCT_TYPE_DIRECT) {
    return (
      <p className="text-gray-7 underline underline-offset-4 text-[14px] sm280:text-[12px]">전문가에게 판매하기</p>
    );
  } else if (code == PRODUCT_TYPE_SPEED) {
    return <p className="text-gray-7 underline underline-offset-4 text-[14px] sm280:text-[12px]">직거래 판매로 변경</p>;
  } else {
    return '';
  }
};

export default function ProductTypeChangeButton({
  id,
  type,
  status,
  setProductList,
  statusOfSpeedProduct,
}: ProductTypeAndStatusProps) {
  const [isShow, setIsShow] = useState(false);
  const [text, setText] = useState('');
  const [rightBtnText, setRightBtnText] = useState('');
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();
  const onClickLeftBtn = (e: MouseEvent) => {
    setIsShow(false);

    e.stopPropagation();
  };

  const handleToastMsg = (requestType: string) => {
    if (requestType == PRODUCT_TYPE_DIRECT) {
      return '직거래 차량으로 변경되었어요.';
    } else {
      return '전문가에게 판매를 요청했어요.';
    }
  };

  const onClickRightBtn = (type: string | undefined, e: MouseEvent) => {
    let requestType = '';
    if (type == PRODUCT_TYPE_DIRECT) {
      requestType = PRODUCT_TYPE_SPEED;
    } else {
      requestType = PRODUCT_TYPE_DIRECT;
    }

    apiManager
      .patch(`/api/v2/products/${id}`, { type: requestType })
      .then((response) => {
        if (setProductList) {
          const responseData: ProductDetailResponse = response.data;
          setProductList((prevData) =>
            prevData.map((item) => {
              if (item.id === responseData.id) {
                return { ...item, type: responseData.type };
              }
              return item;
            }),
          );
        }
        queryClient.invalidateQueries({ queryKey: ['product-search'] });
        const newToastMsg = handleToastMsg(requestType);
        showToast(newToastMsg, 'success', 'bottom');
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });

    e.stopPropagation();

    setIsShow(false);
  };

  const getTextContext = () => {
    if (type?.code == PRODUCT_TYPE_DIRECT) {
      setText(
        `'전문가에게 판매하기'로 변경하시면<br>담당자가 빠르게 상담을 도와드립니다.<br><br>차량을 판매 요청할까요?`,
      );
      setRightBtnText('요청하기');
    } else {
      setText('직거래 차량으로<br>변경하시겠어요?');
      setRightBtnText('변경하기');
    }
  };

  return (
    (statusOfSpeedProduct?.code === AFTER_CONSULTING || statusOfSpeedProduct?.code === BEFORE_CONSULTING) &&
    ((status?.code === SALE && type?.code === PRODUCT_TYPE_DIRECT) || status?.code === BEFORE_SALE) && (
      <>
        <span
          onClick={(e) => {
            getTextContext();
            setIsShow(true);
            e.stopPropagation();
          }}
        >
          {showText(type?.code)}
        </span>
        <BasicPopup
          isShow={isShow}
          textContent={text}
          textLeftBtn="취소"
          onClickLeftBtn={(e: MouseEvent) => onClickLeftBtn(e)}
          textRightBtn={rightBtnText}
          onClickRightBtn={(e: MouseEvent) => onClickRightBtn(type?.code, e)}
        ></BasicPopup>
      </>
    )
  );
}

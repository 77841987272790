import { useSetAtom } from 'jotai';
import React, { useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import { updateMemberNotificationsMark } from '@/api/notification/updateNotification';
import { deleteNotification, getInterestProductsNotificationSettings } from '@/api/public';
import { BellIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { INTEREST_PRODUCT_NOTIFICATION_TYPES } from '@/const/notification';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { isExistNotReadNotificationAtom } from '@/store/notification';
import { calculateTimeAgo, isExternalUrl, isUrlFromOurDomain } from '@/utils/common';
import { Avatar, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';

interface InterestNotificationProps {
  isEditMode: boolean;
  data: Notifications[];
  notReadNotificationCount: number;
  isLoading: boolean;
}

const InterestNotification = ({ isEditMode, data, notReadNotificationCount, isLoading }: InterestNotificationProps) => {
  const { showToast } = useToastContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setIsExistNotReadNotification = useSetAtom(isExistNotReadNotificationAtom);

  const notReadCountRef = useRef(notReadNotificationCount);

  useEffect(() => {
    notReadCountRef.current = notReadNotificationCount;
  }, [notReadNotificationCount]);

  const onClickDelete = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    mutate(id);
  };

  const { mutate } = useMutation(deleteNotification, {
    onSuccess: (deletedId) => {
      const currentData = queryClient.getQueryData<NotificationsResponse>(['get-notification']);
      if (!currentData) return;
      const prevData = { ...currentData };
      const prevNotification = prevData.interestProductNotifications;
      const prevCount = prevData.notReadInterestProductNotificationCount;

      const notificationToDelete = prevNotification?.find((item) => item.id === deletedId);

      const isUnread = notificationToDelete && !notificationToDelete.isRead;

      queryClient.setQueryData(['get-notification'], {
        ...prevData,
        interestProductNotifications: prevNotification?.filter((item) => item.id !== deletedId),
        notReadInterestProductNotificationCount: isUnread ? prevCount - 1 : prevCount,
      });
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const getMyInterestProducts = async () => {
    try {
      const response = await getInterestProductsNotificationSettings();
      const res: InterestProducts[] = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data: interestProductList } = useQuery(['get-interest-products'], getMyInterestProducts, {
    refetchOnWindowFocus: false,
  });

  const updateMemberNotificationsMarkMutation = useMutation((type: string) => updateMemberNotificationsMark(type), {
    onMutate: async () => {
      await queryClient.cancelQueries('get-notification');

      const currentData = queryClient.getQueryData<NotificationsResponse>(['get-notification']);
      if (!currentData) return;

      const prevData = { ...currentData };

      const newInterestProductNotifications = prevData.interestProductNotifications?.map((item) => ({
        ...item,
        isRead: true,
      }));

      queryClient.setQueryData(['get-notification'], {
        ...prevData,
        interestProductNotifications: newInterestProductNotifications,
        notReadInterestProductNotificationCount: 0,
      });
      return { prevData };
    },
    onSuccess: () => {
      const updateData = queryClient.getQueryData<NotificationsResponse>(['get-notification']);
      if (
        updateData?.notReadActivityNotificationCount === 0 &&
        updateData?.notReadInterestProductNotificationCount === 0
      ) {
        setIsExistNotReadNotification(false);
      }
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(['get-notification'], { ...context?.prevData });
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const onClickSetInterestProductBtn = () => {
    if (interestProductList && interestProductList.length > 0) {
      navigate('/notification/products');
    } else {
      navigate('/notification/products/form');
    }
  };

  const onClickRedirect = (redirectUrl: string) => {
    if (isExternalUrl(redirectUrl)) {
      if (isUrlFromOurDomain(redirectUrl)) {
        const url = new URL(redirectUrl);
        navigate(url.pathname);
      } else {
        window.open(redirectUrl);
      }
    } else {
      navigate(redirectUrl);
    }
  };

  const onClickNotification = (memberNotificationsId: number, redirectUrl: string) => {
    onClickRedirect(redirectUrl);
  };

  const skeletonCount = Math.ceil(window.innerHeight / 100);

  useEffect(() => {
    return () => {
      if (notReadCountRef.current > 0) {
        updateMemberNotificationsMarkMutation.mutate(INTEREST_PRODUCT_NOTIFICATION_TYPES);
      }
    };
  }, []);

  return (
    <>
      <div className="w-full h-full min-w-[280px] max-w-[720px] pt-[48px]">
        <div className="flex justify-between bg-white fixed z-10 w-full py-3 top-[109px] pr-4  min-w-[280px] max-w-[720px]">
          <div className="flex items-center justify-center pl-4">
            <BellIcon color={colors.gray[10]} width={18} height={18}></BellIcon>
            <span className="ml-[2px] text-sm xxs:text-xs break-keep">
              알림 받는 관심 차량 {interestProductList?.length}대
            </span>
          </div>
          <BasicButton
            name="관심 차량 설정"
            bgColor={colors.gray[2]}
            borderColor={colors.gray[2]}
            textColor={colors.gray[8]}
            fontSize={13}
            width={104}
            height={34}
            borderRadius={4}
            fontWeight="medium"
            onClick={() => navigate('/notification/products')}
          ></BasicButton>
        </div>

        {isLoading ? (
          <div className="pt-[58px]">
            {Array.from(new Array(skeletonCount)).map((_, index) => (
              <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '16px', height: '96px' }} key={index}>
                <Skeleton variant="circular" width={52} height={52}>
                  <Avatar />
                </Skeleton>
                <div className="w-full ml-[9px] flex-1">
                  <Skeleton width="100%" variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton width="60%" variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton width="10%" variant="text" sx={{ fontSize: '1rem' }} />
                </div>
              </Box>
            ))}
          </div>
        ) : (
          <>
            {data?.length === 0 ? (
              <div className="w-full h-full px-4 " style={{ height: `calc(100vh - 180px)` }}>
                <div className="flex flex-col items-center justify-center w-full h-full text-center">
                  <p className="text-gray-7 text-lg mt-4 text-center mb-[30px]">
                    받은 관심 차량 알림이 없습니다.
                    <br />
                    관심 차량을 설정해주세요.
                  </p>
                  <BasicButton
                    name="관심 차량 등록"
                    bgColor={colors.primary}
                    borderColor={colors.primary}
                    textColor={colors.gray[0]}
                    fontSize={16}
                    width={200}
                    height={48}
                    borderRadius={12}
                    fontWeight="semibold"
                    onClick={() => onClickSetInterestProductBtn()}
                  ></BasicButton>
                </div>
              </div>
            ) : (
              <ul className="min-w-[248px] pb-24 mt-[58px] z-0">
                {data.map((item, index) => {
                  const notification = item?.notification;
                  return (
                    <li
                      key={item.id}
                      className={`relative flex items-top py-4 cursor-pointer px-4 ${item.isRead ? '' : 'bg-blue-1'}`}
                      onClick={() => onClickNotification(item.id, notification?.redirectUrl)}
                    >
                      <div className="rounded-lg w-[52px] h-[52px] min-w-[52px] my-1 mr-2">
                        <img
                          src={notification?.imageUrl}
                          alt="profile-img"
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                      <dl className="flex-1">
                        <dt className="pr-6">
                          {item.notification?.notificationType.code === 'INTEREST_PRODUCT_PRICE_CUT' && (
                            <span className="inline bg-gray-2 text-red rounded-full w-auto px-1.5 py-0.5 text-center text-xs font-semibold mx-1 min-w-[50px]">
                              ₩ 할인
                            </span>
                          )}

                          <span className="inline font-semibold break-all text-ellipsis line-clamp-2 text-gray-8">
                            {notification?.title}
                          </span>
                        </dt>
                        <dd
                          className={`flex-1 max-w-[620px] font-medium text-gray-7 text-sm line-clamp-2 text-ellipsis break-all pr-8 mb-1`}
                        >
                          {notification?.contents}
                        </dd>
                        <dd className="text-xs text-gray-5">{calculateTimeAgo(notification?.sentDate)}</dd>

                        {isEditMode && (
                          <div
                            className="absolute right-0 m-4 cursor-pointer top-2"
                            onClick={(e) => onClickDelete(e, item.id)}
                          >
                            <XMarkIcon color={colors.gray[9]}></XMarkIcon>
                          </div>
                        )}
                      </dl>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default InterestNotification;

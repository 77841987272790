import React, { SVGProps } from 'react';

const SvgVehicleInspectionColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 36 36" {...props}>
    <rect width={18.619} height={19.714} x={2.668} y={6} fill="#D1DDFF" rx={3} />
    <path fill="#3C5FBF" d="M2.668 21.333h30.667v1.381a3 3 0 0 1-3 3H5.668a3 3 0 0 1-3-3v-1.38Z" />
    <path
      fill="#3C5FBF"
      d="M21.285 9.286h4.15a4 4 0 0 1 3.073 1.439l1.54 1.846 2.322 2.71a4 4 0 0 1 .963 2.603v3.45H21.285V9.285Z"
    />
    <circle cx={9.24} cy={24.619} r={2.881} fill="#F5F5F5" stroke="#414141" strokeWidth={3} />
    <circle cx={26.764} cy={24.619} r={2.881} fill="#F5F5F5" stroke="#414141" strokeWidth={3} />
    <path
      fill="#D1DDFF"
      fillRule="evenodd"
      d="M28.19 10.381c.113.108.22.222.322.344l1.539 1.846 2.323 2.71c.133.156.254.321.36.493a.996.996 0 0 1-.398.083h-6.762a1 1 0 0 1-1-1v-3.476a1 1 0 0 1 1-1h2.615Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgVehicleInspectionColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgVehicleInspectionColorIcon;

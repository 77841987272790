import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { IncreasingTrendGraphGray4ColorIcon, PhoneRingSolidIcon, SimpleTruckOutlineIcon } from '../Icon';
import StickyActionBanner from './Banner/StickyActionBanner';
import { getPriceTrend, getPublicPriceTrend } from '@/api/public';
import { colors } from '@/const/colors';
import { ACTUAL_REPRESENTATIVE_PHONE_NUMBER } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatNumber, formatPrice, phoneCall } from '@/utils/common';
import { Skeleton } from '@mui/material';
import Slider from '@mui/material/Slider';

interface PriceSearchProps {
  priceSearchParams: ProductSearchParams | undefined | ProductDetailResponse;
  isShowStickyBanner?: boolean;
  stickBannerPosition?: string;
  apiType: 'public' | 'private';
}

interface priceProps {
  showPriceComparison: boolean;
  price?: string | undefined;
}

interface priceRangeType {
  firstRange: string;
  secondRange: string;
  thirdRange: string;
  forthRange: string;
  fifthRange: string;
  sixthRange: string;
  lowPricePercentage: number;
  highPricePercentage: number;
}

interface PricesStandard {
  id: number;
  axis: EnumPresenter;
  loaded: EnumPresenter;
  loadedInnerLength: number;
  tons: string;
  year: string;
}

interface PriceInfoType {
  id: number;
  highPrice: string;
  lowPrice: string;
  priceRange: priceRangeType;
  pricesStandard: PricesStandard;
  level: EnumPresenter;
  pricePercentage: number;
}

interface MarkType {
  value: number;
  label: string;
}

interface LevelPickerType {
  [key: string]: {
    color: string;
    backgroundColor: string;
  };
}

const CarPriceTrendInfo: React.FC<PriceSearchProps & priceProps> = ({
  priceSearchParams,
  apiType = 'public',
  showPriceComparison,
  price,
  isShowStickyBanner,
  stickBannerPosition = 'bottom-[64px]',
}) => {
  const { showToast } = useToastContext();

  const levelPicker: LevelPickerType = {
    HIGH: { color: '#F5222D', backgroundColor: '#FFE3E0' },
    MIDDLE: { color: '#1E42A6', backgroundColor: '#F0F5FF' },
    LOW: { color: '#34A853', backgroundColor: '#F6FFED' },
  };

  const [marks, setMarks] = useState<MarkType[]>([
    { value: 0, label: '0' },
    { value: 20, label: '20' },
    { value: 40, label: '40' },
    { value: 60, label: '60' },
    { value: 80, label: '80' },
    { value: 100, label: '100' },
  ]);

  const marksWithColor = marks.map((mark) => ({
    ...mark,
    label: <span style={{ color: mark.value === 55 ? '#ef4444' : '#919191' }}>{mark.label}</span>,
  }));

  const [isNone, setIsNone] = useState<boolean>();
  const [circleStyle, setCircleStyle] = useState<React.CSSProperties>({
    position: 'absolute',
    width: '18px',
    height: '18px',
    backgroundColor: levelPicker['MIDDLE']?.color,
    borderRadius: '50%',
    top: '10px',
    left: '0%',
    border: '2px solid white',
  });

  const [isHideMarker, setIsHideMarker] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);

  const params = {
    tons: priceSearchParams?.tons,
    year: priceSearchParams?.year,
    modelId: priceSearchParams?.model?.id,
    loaded: priceSearchParams?.loaded?.code,
    loadedInnerLength: priceSearchParams?.loadedInnerLength,
    axis: priceSearchParams?.axis?.code,
    price: price ? price : null,
    productId: priceSearchParams?.productId ? priceSearchParams?.productId : null,
  };

  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.append(key, value.toString());
  });

  const getPriceAPI = (queryParams: URLSearchParams) => {
    if (apiType === 'public') {
      return getPublicPriceTrend(queryParams);
    } else {
      return getPriceTrend(queryParams);
    }
  };

  const {
    data: priceInfo,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery<PriceInfoType>(['get-price-trend', queryParams, price], () => getPriceAPI(queryParams), {
    enabled: queryParams.size > 5,
    refetchOnWindowFocus: false,
    onSuccess: (responseData) => {
      if (responseData.id) {
        setIsNone(false);
        setMarks([
          {
            value: 0,
            label: formatNumber(responseData.priceRange.firstRange),
          },
          {
            value: 20,
            label: formatNumber(responseData.priceRange.secondRange),
          },
          {
            value: 40,
            label: formatNumber(responseData.priceRange.thirdRange),
          },
          {
            value: 60,
            label: formatNumber(responseData.priceRange.forthRange),
          },
          {
            value: 80,
            label: formatNumber(responseData.priceRange.fifthRange),
          },
          {
            value: 100,
            label: formatNumber(responseData.priceRange.sixthRange),
          },
        ]);

        if (responseData.pricePercentage) {
          const pricePercentage = parseFloat(responseData?.pricePercentage.toString().replace('%', ''));
          if (pricePercentage < 0 || pricePercentage > 100) {
            setIsHideMarker(true);
          } else {
            setCircleStyle({
              position: 'absolute',
              width: '18px',
              height: '18px',
              backgroundColor: levelPicker[responseData?.level?.code]?.color,
              borderRadius: '50%',
              top: '10px',
              left: `calc(${responseData?.pricePercentage} - 9px)`,
              border: '2px solid white',
            });
            if (pricePercentage > 90 && pricePercentage <= 100) {
              setOffset(30);
            } else if (pricePercentage >= 0 && pricePercentage < 10) {
              setOffset(-30);
            }
          }
        }
      } else {
        setIsNone(true);
        setIsHideMarker(true);
      }
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  return (
    <div className="py-6">
      {isLoading || isFetching || isNone === undefined ? (
        <Skeleton variant="rounded" width="100%" animation="wave" height={123} sx={{ bgcolor: colors.gray[2] }} />
      ) : isSuccess && !!isNone ? (
        <>
          <div className="flex flex-col items-center justify-center pb-6">
            <IncreasingTrendGraphGray4ColorIcon width={60} height={60} />
            <p className="my-3 text-center text-gray-8">
              해당 차량의 <br className="hidden sm280:block" />
              평균 시세 정보를 준비중입니다.
            </p>
            <div className="text-gray-6 text-[13px] leading-[18px] text-center">
              시세 정보가 있는 경우 여기에 표시되며,
              <br />
              차량의 주행거리, 차량의 상태, 옵션 등에 따라
              <br />
              시세가 변동될 수 있습니다.
            </div>
          </div>

          <StickyActionBanner
            isShow={!!isShowStickyBanner}
            icon={<PhoneRingSolidIcon color={colors.primary} width={40} height={40} />}
            content={
              <div className="ml-2">
                <div className="text-gray-8 font-medium text-[14px]">내 차량 시세 상담받기</div>
                <div className="text-gray-8 font-semibold text-[17px]">전화문의 1599-6249</div>
              </div>
            }
            onClickBtn={() => phoneCall(ACTUAL_REPRESENTATIVE_PHONE_NUMBER)}
            position={stickBannerPosition}
          />
        </>
      ) : (
        isSuccess &&
        !isNone && (
          <>
            {showPriceComparison && (
              <p className="m-6 text-center break-keep">
                해당 차량은 평균 시세 대비{' '}
                <span
                  className="font-semibold"
                  style={{ color: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color }}
                >
                  '{priceInfo?.level?.desc}'
                </span>
                입니다.
              </p>
            )}
            <dl className={`text-center ${isHideMarker || !showPriceComparison ? 'mb-0' : 'mb-10'}`}>
              <dt className="mb-1 text-gray-500">평균시세</dt>
              <dd className="text-xl font-semibold">
                {formatPrice(priceInfo?.lowPrice)} ~ {formatPrice(priceInfo?.highPrice)}
              </dd>
            </dl>
            <div className="relative mx-4">
              <Slider
                style={{ padding: '12px 0 16px 0' }}
                getAriaLabel={() => 'Minimum distance'}
                value={[
                  priceInfo?.priceRange?.lowPricePercentage || 0,
                  priceInfo?.priceRange?.highPricePercentage || 0,
                ]}
                valueLabelDisplay="off"
                size="medium"
                disableSwap
                marks={marksWithColor}
                sx={{
                  height: '10px',
                  paddingRight: '9px',
                  paddingLeft: '9px',
                  pointerEvents: 'none',
                  '& .MuiSlider-thumb': { width: 0, height: 0 },
                  '& .MuiSlider-thumb:hover': {
                    backgroundColor: '#1e40af',
                  },
                  '& .MuiSlider-track': {
                    backgroundColor: '#1e40af',
                    border: 0,
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: '#d1d5db',
                  },
                  '& .MuiSlider-mark': {
                    display: 'none',
                  },
                }}
              />
              {showPriceComparison && !isHideMarker && (
                <div style={{ ...circleStyle, top: '10px' }}>
                  <div
                    className="absolute bottom-[30px] rounded-full w-20 h-7 border box-border"
                    style={{
                      left: `calc(-33px - ${offset}px)`,
                      borderColor: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color,
                      backgroundColor: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.backgroundColor,
                      color: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color,
                    }}
                  >
                    <SimpleTruckOutlineIcon
                      className="mx-2 mb-1"
                      style={{ display: 'inline' }}
                      color={levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color}
                    />
                    {priceInfo?.level?.desc}
                    <div
                      className="border-r border-dashed box-borders"
                      style={{
                        width: `calc(40px + ${offset}px)`,
                        height: '14px',
                        borderColor: levelPicker[priceInfo?.level?.code as keyof LevelPickerType]?.color,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-5 bg-gray-1 rounded-[12px] text-[14px] leading-[23px] text-gray-7 p-4">
              * 차량 주행거리, 차량 상태, 차량 옵션 등으로 시세 차이가 발생할 수 있습니다.
            </div>
          </>
        )
      )}
    </div>
  );
};

export default CarPriceTrendInfo;

import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';

import { UNAUTHORIZED_ACCESS } from '@/const/errorCode';
import * as Sentry from '@sentry/react';

let isRefreshing = false; // 토큰 갱신 중인지 여부
let refreshSubscribers: Array<(token: string) => void> = []; // 토큰 갱신 후 대기 중인 요청을 처리하는 콜백 리스트

const apiManager: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 500000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

apiManager.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url) {
      if (config.url.includes('refresh')) {
        config.withCredentials = true;
      } else if (!config.url.includes('login')) {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiManager.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errorResponse = error.response;

    let oldToken;
    let oldDecodedToken;
    let oldMemberId;

    if (errorResponse && errorResponse.data) {
      // 토큰이 만료되었을 때 처리
      if (errorResponse.status === 401) {
        if (errorResponse.data.code === 'JWT_TOKEN_EXPIRED' && !originalRequest._retry) {
          if (isRefreshing) {
            // 토큰 갱신 중일 경우 대기
            return new Promise((resolve) => {
              refreshSubscribers.push((newToken) => {
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                resolve(apiManager(originalRequest));
              });
            });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          oldToken = localStorage.getItem('token') || '';
          oldDecodedToken = jwtDecode<Token>(oldToken);
          oldMemberId = JSON.parse(oldDecodedToken.details.toString())['memberId'];

          try {
            const response = await apiManager.post('/auth/refresh-token');
            const newAccessToken = response.data.accessToken;

            localStorage.setItem('token', newAccessToken);

            // 대기 중인 요청 처리
            refreshSubscribers.forEach((callback) => callback(newAccessToken));
            refreshSubscribers = []; // 콜백 초기화
            isRefreshing = false;

            // 갱신 후 원래 요청 다시 시도
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return apiManager(originalRequest);
          } catch (refreshError) {
            const token = localStorage.getItem('token') || '';

            if (!token) {
              Sentry.captureException(new Error(`토큰 갱신 실패: 토큰 X: ${oldMemberId}`), {
                extra: { error: refreshError },
              });
              localStorage.removeItem('token');
              window.location.replace('/login');
              isRefreshing = false;
              return Promise.reject(refreshError);
            }
            try {
              const decodedToken = jwtDecode<Token>(token);
              const memberId = JSON.parse(decodedToken.details.toString())['memberId'];

              Sentry.captureException(new Error(`토큰 갱신 실패 - 멤버 아이디: ${memberId}`), {
                extra: { error: refreshError },
              });
            } catch (decodeError) {
              Sentry.captureException(new Error('토큰 갱신 실패 및 토큰 디코딩 실패'), {
                extra: { refreshError, decodeError },
              });
            }

            localStorage.removeItem('token');
            window.location.replace('/login');
            isRefreshing = false;
            return Promise.reject(refreshError);
          }
        }
        if (
          errorResponse.data.code === 'REFRESH_TOKEN_EXPIRED' ||
          errorResponse.data.code === 'INVALID_REFRESH_TOKEN' ||
          errorResponse.data.code === 'AUTHENTICATION_FAIL'
        ) {
          const token = localStorage.getItem('token') || '';
          const decodedToken = jwtDecode<Token>(token);
          const memberId = JSON.parse(decodedToken.details.toString())['memberId'];
          Sentry.captureException(new Error(`${errorResponse.data.code}: ${memberId}`));

          localStorage.removeItem('token');
          window.location.replace('/login');
        }
      }

      // 권한 없음 처리
      if (errorResponse.status === 403 && errorResponse.data.code === UNAUTHORIZED_ACCESS) {
        window.location.replace('/unauthorized');
      }

      return Promise.reject(errorResponse.data);
    } else {
      return Promise.reject(errorResponse);
    }
  },
);

export default apiManager;

import React from 'react';

import { isUnderFourTons } from '@/utils/common';

export interface NoAxisWarningProps {
  tons: string | undefined;
  className: string;
}

const NoAxisWarning = ({ tons, className = '' }: NoAxisWarningProps) => {
  if (!isUnderFourTons(tons)) return null;

  return (
    <div
      className={`bg-gray-1 px-4 py-[19px] text-gray-7 font-medium text-[14px] leading-[17px] rounded-[10px] ${className}`}
    >
      * 4톤 이하의 차량은 가변축이 없으므로 축 선택이 불가능합니다.
    </div>
  );
};

export default NoAxisWarning;

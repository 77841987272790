import React, { SVGProps } from 'react';

const SvgBarsArrowDownOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M3.707 6.5a.75.75 0 0 1 .75-.75h15.041a.75.75 0 1 1 0 1.5H4.457a.75.75 0 0 1-.75-.75Zm0 5.5a.75.75 0 0 1 .75-.75h9.5a.75.75 0 0 1 0 1.5h-9.5a.75.75 0 0 1-.75-.75Zm0 5.5a.75.75 0 0 1 .75-.75h4.791a.75.75 0 0 1 0 1.5H4.457a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgBarsArrowDownOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgBarsArrowDownOutlineIcon;

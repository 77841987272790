import React, { SVGProps } from 'react';

const SvgEnlargementOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M21.75 3a.75.75 0 0 0-.75-.75h-6.75a.75.75 0 0 0 0 1.5h6v6a.75.75 0 0 0 1.5 0V3Zm-8.351 6.54a.75.75 0 1 0 1.06 1.061l-1.06-1.06Zm7.07-7.07L13.4 9.54l1.06 1.061 7.071-7.07-1.06-1.061ZM2.25 21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 0-1.5h-6v-6a.75.75 0 0 0-1.5 0V21Zm8.351-6.54a.75.75 0 0 0-1.06-1.061l1.06 1.06Zm-7.07 7.07 7.07-7.07-1.06-1.061-7.071 7.07 1.06 1.061Z"
    />
  </svg>
);
SvgEnlargementOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEnlargementOutlineIcon;

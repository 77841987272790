import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getTerm } from '@/api/public';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import MenuHeader from '@/components/Header/MenuHeader';
import CommonError from '@/pages/Error/CommonError';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const Terms = () => {
  const navigate = useNavigate();

  const getTerms = async () => {
    try {
      const response = await getTerm();
      const res: Terms[] = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data: terms, isError, error, isLoading } = useQuery(['get-terms'], getTerms, { staleTime: 600000 });

  if (isError)
    return (
      <>
        <CommonError></CommonError>
      </>
    );

  const navigateToDetail = (item: Terms) => {
    navigate('/terms/' + item.termsType.code, { state: item });
  };
  return (
    <>
      <MenuHeader title="서비스 이용약관"></MenuHeader>
      {isLoading && <LoadingSpinner text={'로딩중입니다.'}></LoadingSpinner>}
      <div className="w-full p-4 pt-[60px]">
        <section className="my-4">
          <h2 className="hidden">이용약관 리스트</h2>
          <ul className="">
            {terms?.map((item) => (
              <li key={item.id} className="flex bg-gray-50 rounded-lg mb-3" onClick={() => navigateToDetail(item)}>
                <div className="flex items-center p-4 w-full">
                  <DescriptionRoundedIcon className="flex-none" htmlColor="#919191"></DescriptionRoundedIcon>
                  <dd className="text-gray-600 font-semibold ml-4 w-full">{item.termsType.desc}</dd>
                  <ArrowForwardIosRoundedIcon
                    fontSize="small"
                    className="text-gray-200 items-end flex-none"
                  ></ArrowForwardIosRoundedIcon>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </>
  );
};

export default Terms;

import React, { useRef, useState } from 'react';
import DaumPostcode, { Address } from 'react-daum-postcode';
import { useMutation, useQueryClient } from 'react-query';

import SearchInput from '../Common/Input/SearchInput';
import BottomSheetPopup from '../Common/Popup/BottomSheetPopup';
import { XMarkIcon } from '../Icon';
import { deleteSearchHistory } from '@/api/public';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { extractCityCounty } from '@/utils/common';

interface AddressSearchFormProps {
  placeholder: string;
  addressPopupTitle: string;
  onCompletePost: (data: Address) => void;
  updateTransferForm: (fullLocate: string, shortLocate: string) => void;
  addressSearachHistories?: SearchHistory[];
}

const postCodeStyle = {
  width: '100%',
  height: 'calc(100vh - 112px)',
};

const AddressSearchForm = ({
  placeholder,
  addressPopupTitle,
  onCompletePost,
  updateTransferForm,
  addressSearachHistories,
}: AddressSearchFormProps) => {
  const [isShowAdressPopup, setIsShowAdressPopup] = useState(false);
  const fakeInputRef = useRef<HTMLInputElement | null>(null);
  const { showToast } = useToastContext();
  const queryClient = useQueryClient();

  const onClickInputAdress = (e: React.MouseEvent) => {
    setIsShowAdressPopup(true);
    if (fakeInputRef?.current) {
      fakeInputRef.current.focus();
    }
  };

  const onClosePopup = () => {
    setIsShowAdressPopup(false);
  };

  const onClickAddress = (item: SearchHistory) => {
    const shortLocate = extractCityCounty(item.keyword);
    updateTransferForm(item.keyword, shortLocate);
  };

  const deleteSearchHistoryMutation = useMutation((searchHistoryId: number) => deleteSearchHistory(searchHistoryId), {
    onMutate: async (id) => {
      await queryClient.cancelQueries('get-search-history-address');
      const currentData = queryClient.getQueryData<SearchHistory[]>(['get-search-history-address']);

      if (!currentData) return;

      const prevData = Array.isArray(currentData) ? [...currentData] : [];

      const updatedData = prevData.filter((item) => item.id !== id);
      queryClient.setQueryData(['get-search-history-address'], updatedData);

      return { prevData };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(['get-search-history-address'], { ...context?.prevData });
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const onClickDeleteSearchHistory = (searchHistoryId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    deleteSearchHistoryMutation.mutate(searchHistoryId);
  };

  return (
    <>
      <div>
        <div onClick={onClickInputAdress}>
          <SearchInput placeholder={placeholder} keyword="" readonly></SearchInput>
        </div>
        <div className="px-4 pt-[30px]">
          <div className="text-sm font-semibold text-gray-8">최근 검색</div>
          {addressSearachHistories && addressSearachHistories.length > 0 ? (
            <ul className="mt-2">
              {addressSearachHistories.map((item, index) => (
                <li
                  key={item.id}
                  className={`flex justify-between items-center ${
                    addressSearachHistories.length !== index + 1 ? 'border-b border-gray-3 ' : ''
                  } py-4`}
                  onClick={() => onClickAddress(item)}
                >
                  <span className="text-sm text-gray-8">{item.keyword}</span>
                  <div onClick={(e) => onClickDeleteSearchHistory(item.id, e)}>
                    <XMarkIcon color={colors.gray[7]}></XMarkIcon>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="pt-6 text-sm text-gray-7">최근 검색 기록이 없습니다.</div>
          )}
        </div>
      </div>
      <BottomSheetPopup
        isOpen={isShowAdressPopup}
        onClose={onClosePopup}
        title={addressPopupTitle}
        isFullHeight
        isBackdrop={false}
      >
        <input
          ref={fakeInputRef}
          style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
          onFocus={() => {
            if (fakeInputRef?.current) {
              fakeInputRef.current.blur();
            }
          }}
          autoFocus
        />
        <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} submitMode={false}></DaumPostcode>
      </BottomSheetPopup>
    </>
  );
};
export default AddressSearchForm;

import { atom } from 'jotai';

const initialFuelingHistoryFormState: FuelingForm = {
  id: null,
  driveVehicleInfoId: null,
  receiptImageUrl: '',
  unitPrice: null,
  amount: null,
  price: null,
  refuelDay: '',
};
const initialFuelingHistorySearchParamsFromState: FuelingHistorySearchParams = {};

export const fuelingHistoryFormAtom = atom<FuelingForm>(initialFuelingHistoryFormState);

export const feulingHistorySearchParamsFromAtom = atom<FuelingHistorySearchParams>(
  initialFuelingHistorySearchParamsFromState,
);

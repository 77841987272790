import { useAtom, useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Outlet, useLocation } from 'react-router-dom';

import OnBoading from '../Login/OnBoading';
import apiManager from '@/api/AxiosInstance';
import SplashComponent from '@/components/Common/SplashComponent';
import Footer from '@/components/Footer';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { THEUNBAN } from '@/const/referralCode';
import { useChatContext } from '@/contexts/Chat/ChatContext';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import usePartnerSession from '@/hooks/usePartnerSession';
import useWebSocket from '@/hooks/useWebSocket';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { isExistNotReadChatMessageAtom } from '@/store/chatting';
import { memberAtom, setIsMemberLoadedAtom } from '@/store/member';
import { isExistNotReadNotificationAtom } from '@/store/notification';
import { isTokenExpired } from '@/utils/common';
import * as Sentry from '@sentry/react';

const mainMenuPathList = [
  '/',
  '/products',
  '/sell-car',
  '/chatting',
  '/more',
  '/products/',
  '/sell-car/',
  '/chatting/',
  '/more/',
];

const MainLayout = () => {
  const isTheunban = usePartnerSession() === THEUNBAN;
  const location = useLocation();

  const [isShowOnBoading, setIsShowOnBoading] = useState<boolean>(false);

  const setIsExistNotReadNotification = useSetAtom(isExistNotReadNotificationAtom);
  const setIsExistNotReadChatMessage = useSetAtom(isExistNotReadChatMessageAtom);

  const [showSplash, setShowSplash] = useState<boolean>();
  const { member, login, getMember } = useMemberContext();

  const [memberAtomData] = useAtom(memberAtom);
  const setIsMemberLoaded = useSetAtom(setIsMemberLoadedAtom);

  const id = memberAtomData ? memberAtomData.id : member?.id;
  const { setProductEnum } = useProductEnumContext();
  const { setLicenseEnum } = useLicenseEnumContext();
  const { setUnreadMessages, setChattingList, setNewChatMessage } = useChatContext();
  const { showToast } = useToastContext();

  const [cookies, setCookie] = useCookies(['refreshToken']);

  useEffect(() => {
    if (isTheunban) {
      if (window.FlutterChannel) {
        window.receiveDataFromFlutter = (accessToken: string, refreshToken: string) => {
          if (accessToken?.trim() && refreshToken?.trim() && !isTokenExpired(accessToken)) {
            localStorage.setItem('token', accessToken);

            setCookie('refreshToken', '', { expires: new Date(0) });

            const expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 30);
            setCookie('refreshToken', refreshToken, { expires: expireDate });
          }
        };

        const requestDataFromFlutter = () => {
          const token = localStorage.getItem('token');
          if (!token || isTokenExpired(token)) {
            Sentry.captureMessage('Requesting token from Flutter.');
            window.FlutterChannel.postMessage('getToken');
          } else {
            Sentry.captureMessage('Token is still valid, no need to request from Flutter.');
          }
        };

        requestDataFromFlutter();
      } else {
        const error = new Error('FlutterChannel is not available');
        Sentry.captureException(error);
      }
    }
  }, [isTheunban]);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isNaverInApp = /NAVER/.test(userAgent);
    const isKakaoInApp = /KAKAOTALK/.test(userAgent);

    const isAndroidWeb =
      /Android/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      (!/wv/i.test(userAgent) || isNaverInApp || isKakaoInApp);

    if (isAndroidWeb && sessionStorage.getItem('isFirst') !== 'false') {
      window.location.replace('intent://www.zigtruck.io/#Intent;package=com.zigtruck.android;scheme=https;end');
    }
    sessionStorage.setItem('isFirst', String(false));
  }, []);

  const getProductEnum = () => {
    apiManager
      .get('/api/v1/public/products/enum')
      .then((response) => {
        const responseData: ProductEnum = response.data;
        setProductEnum(response.data);
      })
      .catch(() => showToast(COMMON_TOAST_ERROR, 'error', 'bottom'));
  };

  const getLicenseEnum = () => {
    apiManager
      .get('/api/v1/public/license/filter-info')
      .then((response) => {
        const responseData: LicenseEnum = response.data;
        setLicenseEnum(response.data);
      })
      .catch(() => showToast(COMMON_TOAST_ERROR, 'error', 'bottom'));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const hasSessionStarted = sessionStorage.getItem('hasSessionStarted');
    if (token && getMember) {
      const decodedToken = jwtDecode<Token>(token);
      const memberId = JSON.parse(decodedToken.details.toString())['memberId'];
      getMember(memberId);
    } else {
      setIsMemberLoaded();
    }

    if (!hasSessionStarted) {
      setShowSplash(true);
      sessionStorage.setItem('hasSessionStarted', 'true');
      setTimeout(() => {
        setShowSplash(false);
      }, 1000);
    }

    getProductEnum();
    getLicenseEnum();
  }, []);

  const socket = useWebSocket(`/sub/members/${id}`, {
    onMessage: (message) => {
      try {
        const body = JSON.parse(message?.body);
        if (body.changeType === 'NEW_CHAT_MESSAGES') {
          const messageData: NewChatMessage = body.data;
          setNewChatMessage(messageData);
          updateChattingList(messageData);
          setUnreadMessages((prev) => ({
            ...prev,
            [body.data.chatRoomId]: (prev[body.data.chatRoomId] || 0) + 1,
          }));
          setIsExistNotReadChatMessage(true);
        } else if (body.changeType === 'INTEREST_PRODUCT_NOTIFICATION' || body.changeType === 'ACTIVITY_NOTIFICATION') {
          setIsExistNotReadNotification(true);
        }
      } catch (error) {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const updateChattingList = (data: NewChatMessage) => {
    setChattingList((prevList) => {
      // chatRoomId가 같은 항목이 이미 존재하는지 확인
      const existingItemIndex = prevList.findIndex((item) => item.chatRoomId === data.chatRoomId);

      if (existingItemIndex !== -1) {
        // chatRoomId가 같은 항목이 존재하면 업데이트
        return prevList.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, lastMessage: data.contents, lastMessageTime: data.createdDate };
          } else {
            return item;
          }
        });
      } else {
        // chatRoomId가 같은 항목이 존재하지 않으면 추가
        const newData: ChattingListResponse = {
          chatRoomId: data.chatRoomId,
          lastMessage: data.contents,
          lastMessageTime: data.createdDate,
          memberName: data.memberName,
          profileImageUrl: data.profileImageUrl,
          productRepresentImageUrl: data.frontSideImageUrl,
          truckNumber: data.truckNumber,
          productId: 0,
          truckName: '',
          price: 0,
        };
        return [...prevList, newData];
      }
    });
  };

  const getShowFooter = () => {
    return !showSplash && mainMenuPathList.includes(location.pathname) && !isShowOnBoading;
  };

  const onClickGoHome = () => {
    setIsShowOnBoading(() => false);
    localStorage.setItem('isFirst', 'false');
  };

  useEffect(() => {
    getShowFooter();
    setIsShowOnBoading(
      localStorage.getItem('isFirst') !== 'false' &&
        !localStorage.getItem('token') &&
        ['/'].includes(location.pathname) &&
        !isTheunban,
    );
  }, [showSplash, location.pathname, isShowOnBoading]);

  useEffect(() => {
    if (window.Android) {
      window.Android.postMessage(JSON.stringify({ type: 'NAVIGATION', path: location.pathname }));
    }
  }, [location.pathname]);

  return (
    <>
      <DefaultHelmet />
      <div id="commonLayoutContainer">
        <main>
          {!isShowOnBoading && (showSplash ? <SplashComponent /> : <Outlet />)}
          {isShowOnBoading && (showSplash ? <SplashComponent /> : <OnBoading onClickGoHome={onClickGoHome} />)}
        </main>

        <Footer isMain={getShowFooter()} />
      </div>
    </>
  );
};

export default MainLayout;

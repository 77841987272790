import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import AlertModal from '@/components/Common/Modal/AlertModal';
import ScrollToTop from '@/components/Common/ScrollToTop';
import ContractForm from '@/components/Contract/ContractForm';
import ContractViewer from '@/components/Contract/ContractViewer';
import MenuHeader from '@/components/Header/MenuHeader';
import { TRANSFEREE, TRANSFEROR } from '@/const/contract';
import useNavigateBack from '@/hooks/useNavigateBack';

const Contract = () => {
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const [title, setTitle] = useState('');
  const [contractId, setContractId] = useState<number>();
  const [isShow, setIsShow] = useState(false);
  const initialContractInfo: ContractInfo = {
    balance: null,
    balancePaymentDate: '',
    carName: '',
    carNumber: '',
    carType: '',
    carUse: '',
    downPayment: null,
    downPaymentDate: '',
    id: 0,
    identificationNumber: '',
    intermediatePayment: null,
    intermediatePaymentDate: '',
    motorType: '',
    tradingAmount: 0,
    transfereeAddress: '',
    transfereeCompleted: false,
    transfereeCompletedDate: '',
    transfereeName: '',
    transfereePhoneNumber: '',
    transfereeRegistrationNumber: '',
    transfereeSignImageUrl: '',
    transferorAddress: '',
    transferorCompleted: false,
    transferorCompletedDate: '',
    transferorName: '',
    transferorPhoneNumber: '',
    transferorRegistrationNumber: '',
    transferorSignImageUrl: '',
    year: '',
    additionalConditions: '',
  };
  const [contractInfo, setContractInfo] = useState<ContractInfo>(initialContractInfo);

  const chatRoomId = location.state.chatRoomId;
  const contractWriterType = location.state.contractWriterType;

  const goPrev = () => {
    if (pageNum === 1) {
      navigateBack();
    } else {
      setPageNum((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (pageNum === 1) {
      setTitle('안심 전자계약서');
    } else if (pageNum === 2 && contractWriterType === TRANSFEROR) {
      setTitle('양도인 계약서 작성하기');
    } else if (pageNum === 2 && contractWriterType === TRANSFEREE) {
      setTitle('양수인 계약서 작성하기');
    }
  }, [pageNum]);

  useEffect(() => {
    const queryParams = new URLSearchParams({
      chatRoomId,
      contractWriterType,
    });

    apiManager
      .get('/api/v1/contracts', { params: queryParams })
      .then((response) => {
        const res: ContractInfo = response.data;
        const { id } = res;
        setContractId(id);
        setContractInfo(res);

        if (contractWriterType === TRANSFEREE && !res?.transferorCompleted) {
          setIsShow(true);
        }
      })
      .catch((error) => console.log(error));
  }, [pageNum]);

  return (
    <>
      <div className="w-full">
        <MenuHeader
          title={title}
          isShadow={true}
          onClickPrevBtn={() => {
            goPrev();
          }}
        ></MenuHeader>

        <div className="p-4 pt-[84px]">
          {pageNum === 1 &&
            (contractInfo?.id === 0 ? (
              <LoadingSpinner text="" />
            ) : (
              <ContractViewer
                contractInfo={contractInfo}
                contractWriterType={contractWriterType}
                setPageNum={setPageNum}
              ></ContractViewer>
            ))}
          {pageNum === 2 && (
            <>
              <ScrollToTop />
              <ContractForm
                chatRoomId={chatRoomId}
                contractInfo={contractInfo}
                setContractInfo={setContractInfo}
                contractWriterType={contractWriterType}
                setPageNum={setPageNum}
              ></ContractForm>
            </>
          )}
        </div>
      </div>

      <AlertModal
        isOpen={isShow}
        content={
          <>
            양수인이 계약서를 작성하기 전,
            <br />
            양도인이 먼저 계약서를 작성해야
            <br className="hidden sm280:block" />
            합니다. <br className="sm280:hidden" />
            양도인에게 계약서 작성을
            <br className="hidden sm280:block" /> 요청 해주세요.
          </>
        }
        onClose={() => setIsShow(false)}
      />
    </>
  );
};

export default Contract;

import React, { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicButton from '@/components/Common/Button/BasicButton';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { colors } from '@/const/colors';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorPhoneNumberMessage, setErrorPhoneNumberMessage] = useState('');
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const [isShow, setIsShow] = useState<boolean>(false);

  const onClickSendTemporaryPassword = () => {
    const queryParams = new URLSearchParams();
    queryParams.append('phoneNumber', phoneNumber);

    setIsClicked(true);

    apiManager
      .get('/api/v1/members/temporary-password?' + queryParams.toString())
      .then((response) => {
        setIsShow(true);
        setErrorPhoneNumberMessage('');
        setIsClicked(false);
      })
      .catch((error) => {
        setIsClicked(false);
        if (error.code === 'MEMBERS_PHONE_NUMBER_NOT_FOUND' || error.code === 'FAIL_SEND_PHONE_MESSAGE') {
          setErrorPhoneNumber(true);
          setErrorPhoneNumberMessage(error?.message);
        }
      });
  };

  const onChangePhoneNumber = (val: string) => {
    if (val.length <= 20) {
      setErrorPhoneNumber(false);
      setErrorPhoneNumberMessage('');
      setPhoneNumber(val);
    }
  };

  const onClickGoToLogin = () => {
    setIsShow(false);
    navigate('/login', { state: { isSocial: false } });
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'phoneNumber') {
        phoneNumberRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    } else {
      phoneNumberRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  };

  useLayoutEffect(() => {
    const initialHeight = document.documentElement.clientHeight;

    if (initialHeight < 560) {
      setIsKeyboardOpen(true);
    }

    const handleResize = () => {
      const currentHeight = window.innerHeight;
      setIsKeyboardOpen(initialHeight > currentHeight);
    };

    const detectMobileKeyboard = () => {
      handleResize();
      scrollIntoView();
    };

    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  return (
    <>
      <div className="w-full h-[100dvh] p-4 flex flex-col justify-between relative min-h-[540px]">
        <MenuHeader title="비밀번호 찾기"></MenuHeader>
        <div></div>
        <div
          className={`absolute ${
            isKeyboardOpen ? 'top-[60px]' : 'top-[15%]'
          } left-4 font-bold text-4xl xxs:text-3xl xs:text-3xl leading-normal ml-2 mt-15`}
        >
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/logo.png"
            alt="직트럭 로고"
            className="mb-4 w-20"
          ></img>
          화물차 거래의
          <br /> 새로운 기준
        </div>
        <div className="pb-[76px]">
          <div className="mb-[75px]">
            <TextInputLabelOff
              ref={phoneNumberRef}
              name="phoneNumber"
              placeholder="휴대폰 번호를 입력하세요."
              value={phoneNumber}
              fontSize={18}
              onChange={(e) => onChangePhoneNumber(e.target.value)}
              onFocus={(e) => scrollIntoView(e)}
              error={errorPhoneNumber}
              errorMsg={errorPhoneNumberMessage}
              type="number"
              inputMode="numeric"
              maxLength={20}
            ></TextInputLabelOff>
          </div>

          <BasicButton
            name="임시 비밀번호 발송"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={52}
            borderRadius={12}
            fontWeight="semibold"
            onClick={() => onClickSendTemporaryPassword()}
            isDisabled={!phoneNumber || errorPhoneNumber || isClicked}
          ></BasicButton>
        </div>
      </div>
      <BasicPopup
        isShow={isShow}
        title="임시 비밀번호 발송 완료"
        textContent={
          '임시 비밀번호를 발송했습니다.<br/>1시간 이내로 임시 비밀번호로 로그인 후<br/>비밀번호를 변경해주세요.'
        }
        textRightBtn="로그인 하러가기"
        onClickRightBtn={onClickGoToLogin}
        textLeftBtn="닫기"
        onClickLeftBtn={() => setIsShow(false)}
      ></BasicPopup>
    </>
  );
};

export default ForgotPassword;

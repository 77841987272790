import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { Suspense, lazy, useEffect, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ReactourStep } from 'reactour';

import { getDriveHistory } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import CustomCalendar from '@/components/Common/Calendar/CustomCalendar';
import BaseModal from '@/components/Common/Modal/BaseModal';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import BottomSheetPopup from '@/components/Common/Popup/BottomSheetPopup';
import SlideUpPopup from '@/components/Common/Popup/SlideUpPopup';
import TooltipComponent from '@/components/Common/Tooltip/TooltipComponent';
import DriveForm from '@/components/Drive/DriveForm';
import DriveListView from '@/components/Drive/DriveListView';
import DriveOnBoading from '@/components/Drive/DriveOnboading';
import FuelingHistoryForm from '@/components/Drive/FuelingHistoryForm';
import OtherExpenseDetail from '@/components/Drive/OtherExpense/OtherExpenseDetail';
import OtherExpenseHistoryForm from '@/components/Drive/OtherExpense/OtherExpenseHistoryForm';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import {
  AccountsReceivableColorIcon,
  CheckCircleBoldIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  MoneyCircleBoldIcon,
  OilingColorIcon,
  OtherExpensesColorIcon,
  OtherIncomeColorIcon,
  PlusCircleBoldIcon,
  PlusSmallIcon,
  VehicleInspectionPlusColorIcon,
  XMarkIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { fetchCategoriesAtom } from '@/store/category';
import { driveSearchParamsAtom } from '@/store/drive';
import { feulingHistorySearchParamsFromAtom } from '@/store/fuelingHistory';
import { isMemberLoadedAtom, memberAtom } from '@/store/member';
import { formatNumber, formatNumberWithNegative, formatYYYYMM, formatYYYYMMDD, getFormatDate } from '@/utils/common';
import { Button } from '@mui/material';

const Tour = lazy(() => import('reactour'));

interface DriveHistorySearchParams {
  driveVehicleInfoId: number;
  baseYearMonth: string;
  baseDay: string;
}

export interface PopupInfo {
  name: string;
  type: string;
}

const popupImage: Banner = {
  id: 0,
  contents: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/driveTutorialPopup.jpg',
  type: { code: '', desc: '' },
  bannerLocation: { code: '', desc: '' },
  fileName: '',
  contentsType: { code: '', desc: '' },
  tracking: false,
  display: false,
};

const DriveHome = () => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const [member] = useAtom(memberAtom);
  const [isMemberLoaded] = useAtom(isMemberLoadedAtom);
  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);
  const [, fetchCategories] = useAtom(fetchCategoriesAtom);
  const [step, setStep] = useState<number>();

  const [isShowOnBoading, setIsShowOnBoading] = useState<boolean>();

  const [isVehicleInfoPopupOpen, setIsVehicleInfoPopupOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({
    income: false,
    sales: false,
    expense: false,
  });

  const [clickedItemIndex, setClickedItemIndex] = useState<number | null>(null);

  const setFuelingHistorySearchParams = useSetAtom(feulingHistorySearchParamsFromAtom);

  const [searchParams] = useAtom(driveSearchParamsAtom);
  const setSearchParams = useSetAtom(driveSearchParamsAtom);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(searchParams.baseDay) || new Date());

  const [isShowPopup, setIsShowPopup] = useState<boolean>();
  const [isShowDriveFormPopup, setIsShowDriveFormPopup] = useState(false);
  const [isShowDetailPopup, setIsShowDetailPopup] = useState(false);
  const [popupInfo, setPopupInfo] = useState({
    type: 'drive',
    name: '일지 추가',
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
  });
  const [alertModalConfig, setAlertModalConfig] = useState<ModalConfig>({
    content: null,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedDeleteList, setSelectedDeleteList] = useState<number[]>([]);

  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [isShowFinalStep, setIsShowFinalStep] = useState(false);

  useEffect(() => {
    if (member) {
      setDriveVehicleInfoId(member?.driveVehicleInfo?.id);
      setSearchParams({
        driveVehicleInfoId: member?.driveVehicleInfo?.id,
        baseYearMonth: formatYYYYMM(selectedDate),
        baseDay: formatYYYYMMDD(selectedDate),
      });
    }
  }, [member, selectedDate]);

  useEffect(() => {
    if (member?.driveVehicleInfo?.id) {
      const params = new URLSearchParams({
        driveVehicleInfoId: String(member?.driveVehicleInfo?.id),
      });
      fetchCategories(params);
    }
  }, [driveVehicleInfoId]);

  useEffect(() => {
    localStorage.removeItem('isFirstDriveHome');
    setIsShowOnBoading(localStorage.getItem('isShowDriveOnBoading') !== 'false');
  }, []);

  const onClickDate = (date: Date) => {
    if (!driveVehicleInfoId) {
      setIsVehicleInfoPopupOpen(true);
      return;
    }

    setSelectedDate(date);
    setIsShowPopup(true);

    if (localStorage.getItem('driveTutorial') === 'true') {
      setTimeout(() => {
        setStep(1);
      }, 500);
    }
  };

  const updateSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  const onClickExpandIcon = (type: string) => {
    setIsExpanded((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const onClickGoToSetting = () => {
    navigate('/drive/vehicle');
  };

  const onClickGoToManagement = (type: string) => {
    if (!driveVehicleInfoId) {
      setIsVehicleInfoPopupOpen(true);
      return;
    }
    setFuelingHistorySearchParams({
      driveVehicleInfoId: driveVehicleInfoId,
      fuelingYearAndMonth: formatYYYYMM(selectedDate),
    });
    navigate(`/drive/${type}`, { state: { selectedDate: selectedDate } });
  };

  const getDriveHistoryData = async (searchParams: DriveHistorySearchParams) => {
    try {
      const params = new URLSearchParams({
        driveVehicleInfoId: searchParams.driveVehicleInfoId.toString(),
        baseYearMonth: searchParams.baseYearMonth,
        baseDay: searchParams.baseDay,
      });
      const response = await getDriveHistory(params);
      const data: DriveInfoResponse = response.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data, isError, isLoading, isFetching, isSuccess, refetch } = useQuery(
    ['get-drive-info', searchParams],
    () => getDriveHistoryData(searchParams),
    {
      onSuccess: () => {},
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      enabled: !!searchParams.driveVehicleInfoId && !!searchParams.baseYearMonth && !!searchParams.baseDay,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60,
      staleTime: 1000 * 5,
      keepPreviousData: true,
    },
  );

  const menuHeaderIcons: MenuHeaderIcon[] = [
    {
      icon: <span className="text-[14px] font-medium text-gray-8">차량정보 수정</span>,
      onClickIcon: onClickGoToSetting,
    },
  ];

  const onClosePopup = () => {
    setIsShowPopup(false);
  };

  const onCloseFormPopup = () => {
    setIsShowDriveFormPopup(false);
    setPopupInfo({
      type: 'drive',
      name: '일지 추가',
    });
  };

  const clearSelectedItem = () => {
    setClickedItemIndex(null);
  };

  const onClickDetail = (type: string, name: string, index?: number) => {
    if (isTourOpen) {
      return;
    }

    if (index !== null && index !== undefined) {
      setClickedItemIndex(index);
    }
    if (type === 'otherExpense') {
      setClickedItemIndex(0);

      setPopupInfo({
        type: type,
        name: name,
      });
      setIsShowDetailPopup(true);
    } else {
      setIsShowDriveFormPopup(true);
      setPopupInfo({
        type: type,
        name: name,
      });
    }
  };

  const onClickAddBtn = (popupInfo: PopupInfo) => {
    if (isTourOpen && popupInfo.type === 'fuel') {
      goToNextStep();
    } else {
      setPopupInfo(popupInfo);
      setIsShowDriveFormPopup(true);
      if (step) {
        setTimeout(() => {
          setStep(step + 1);
        }, 500);
      }
    }
  };

  const onClickDriveAddBtn = () => {
    if (!driveVehicleInfoId) {
      setIsVehicleInfoPopupOpen(true);
      return;
    }

    setPopupInfo({
      type: 'drive',
      name: '일지 추가',
    });
    setIsShowDriveFormPopup(true);
  };

  const onClickFuelAddBtn = () => {
    if (!driveVehicleInfoId) {
      setIsVehicleInfoPopupOpen(true);
      return;
    }

    setPopupInfo({
      type: 'fuel',
      name: '주유비 추가',
    });
    setIsShowDriveFormPopup(true);
  };

  const onClickOtherExpenseAddBtn = () => {
    if (!driveVehicleInfoId) {
      setIsVehicleInfoPopupOpen(true);
      return;
    }

    setPopupInfo({
      type: 'otherExpense',
      name: '기타내역 추가',
    });
    setIsShowDriveFormPopup(true);
  };

  const commonButtonProps = {
    border: '1px solid',
    borderColor: 'none',
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 'medium',
    height: 0,
  };

  const addButtons = [
    {
      ...commonButtonProps,
      name: '일지',
      bgColor: colors.blue[5],
      border: 'none',
      textColor: colors.primary,
      onClick: onClickDriveAddBtn,
      icon: <PlusSmallIcon color={colors.primary} />,
    },
    {
      ...commonButtonProps,
      name: '주유비',
      bgColor: colors.gray[2],
      textColor: colors.gray[8],
      onClick: onClickFuelAddBtn,
      icon: <PlusSmallIcon color={colors.gray[7]} />,
    },
    {
      ...commonButtonProps,
      name: '기타내역',
      bgColor: colors.gray[2],
      textColor: colors.gray[8],
      onClick: onClickOtherExpenseAddBtn,
      icon: <PlusSmallIcon color={colors.gray[7]} />,
    },
  ];

  const steps: ReactourStep[] = [
    {
      selector: '.todayTile abbr',
      content: ({ goTo, inDOM }) => (
        <div
          onClick={() => {
            onClickDate(new Date());
            setTimeout(() => {
              goTo(1);
              setStep(1);
            }, 500);
          }}
          className="flex items-center justify-center break-keep"
        >
          <div className="flex flex-col justify-center items-center bg-gray-0 rounded-md w-[30px] h-[30px] mr-2">
            <span className="text-gray-8 text-[6px] leading-[6px] m-0 p-0">수요일</span>
            <span className="text-gray-9 text-[14px] leading-[14px] font-bold mt-[2px] p-0">25</span>
          </div>
          <div>운행일지를 추가할 날짜를 선택해주세요</div>
        </div>
      ),
      position: 'top',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        padding: '16px 20px',
      },
    },
    {
      selector: '.add-drive-form-btn',
      content: ({ goTo }) => (
        <div
          onClick={() => {
            setIsShowDriveFormPopup(true);
            setTimeout(() => {
              goTo(2);
              setStep(2);
            }, 500);
          }}
          className="flex items-center justify-center break-keep"
        >
          <PlusCircleBoldIcon color={colors.gray[0]} width={30} height={30}></PlusCircleBoldIcon>
          <div className="ml-[10px]">
            내역에서 [+일지] 버튼을 클릭해
            <br />
            오늘의 운행일지를 추가하세요
          </div>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
    {
      selector: '.drive-form-type',
      content: ({ goTo }) => (
        <div
          onClick={() => {
            setTimeout(() => {
              goTo(3);
              setStep(3);
              setIsShowDriveFormPopup(false);
            }, 500);
          }}
          className="flex items-center justify-center break-keep"
        >
          <CheckCircleBoldIcon color={colors.gray[0]} width={30} height={30}></CheckCircleBoldIcon>
          <span className="ml-[10px]">차량이 회차했다면 회차를 선택해주세요.</span>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
    {
      selector: '.addOtherExpenseFormBtn',
      content: ({ goTo }) => (
        <div
          onClick={() => {
            setIsShowDriveFormPopup(true);
            setPopupInfo({
              type: 'otherExpense',
              name: '기타내역 추가',
            });
            setTimeout(() => {
              goTo(4);
              setStep(4);
            }, 500);
          }}
          className="flex items-center justify-center break-keep"
        >
          <PlusCircleBoldIcon color={colors.gray[0]} width={30} height={30} />
          <div className="ml-[10px]">
            [+기타내역] 버튼을 클릭해
            <br />
            기타지출과 기타수익을 추가하세요
          </div>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
    {
      selector: '.cateogryManageTutorial',
      content: ({ goTo }) => (
        <div className="flex items-center justify-center break-keep">
          <CheckCircleBoldIcon color={colors.gray[0]} width={30} height={30} />
          <div className="ml-[10px]">
            지출과 수익 분류에 따라 카테고리를
            <br />
            별도로 관리할 수 있어요
          </div>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },

    {
      selector: '.categoryEditTutorial',
      content: () => (
        <div
          onClick={() => {
            onClickLastTutorial();
            setIsShowDriveFormPopup(false);
          }}
          className="flex items-center justify-center break-keep"
        >
          <CheckCircleBoldIcon color={colors.gray[0]} width={30} height={30} />
          <div className="ml-[10px]">
            편집 기능으로 자유롭게
            <br />
            카테고리를 관리해보세요
          </div>
        </div>
      ),
      position: 'bottom',
      style: {
        backgroundColor: colors.gray[8],
        color: colors.gray[0],
        borderRadius: '10px',
        padding: '16px 20px',
      },
    },
  ];

  useLayoutEffect(() => {
    if (data) {
      if (
        !!driveVehicleInfoId &&
        data?.isFirstDriveHistory &&
        (!localStorage.getItem('driveTutorial') || localStorage.getItem('driveTutorial') === 'true')
      ) {
        localStorage.setItem('driveTutorial', String(true));
        setIsTourOpen(true);
      } else {
        localStorage.setItem('driveTutorial', String(false));
      }
    }
  }, [driveVehicleInfoId, data]);

  const onClickFinalGuidePopup = () => {
    setIsShowFinalStep(false);
    localStorage.setItem('driveTutorial', 'false');
  };

  const onClickLastTutorial = () => {
    setIsTourOpen(false);
    setIsShowPopup(false);
    setIsShowDriveFormPopup(false);
    setIsShowFinalStep(true);
  };

  const onClickDriveTypeTutorial = () => {
    goToNextStep();
    if (step === 2) {
      setIsShowDriveFormPopup(false);
    }
  };

  const onClickCategoryTutorial = () => {
    goToNextStep();
    if (step === 5) {
      onClickLastTutorial();
    }
  };

  const goToNextStep = () => {
    if (step) {
      setStep(step + 1);
    }
  };

  const onClickGoToOutstandingAmountList = () => {
    if (!driveVehicleInfoId) {
      setIsVehicleInfoPopupOpen(true);
      return;
    }

    navigate('/drive/outstanding-amount', { state: { selectedDate: selectedDate } });
  };

  const handleClickStartBtn = () => {
    setIsShowOnBoading(false);
  };

  const handleChangeMode = () => {
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      setSelectedDeleteList([]);
    }
  };

  const updateEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const otherExpenseDetailHeader: MenuHeaderIcon[] = [
    {
      icon: data?.otherExpensesHistory?.length === 0 ? '' : isEditMode ? <>닫기</> : <>편집</>,
      onClickIcon: handleChangeMode,
    },
  ];

  useEffect(() => {
    document.body.style.overflow = isTourOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isTourOpen, isShowDriveFormPopup, isShowPopup, isShowDetailPopup]);

  const handleClickPrevBtn = () => {
    const previousLocation = sessionStorage.getItem('entryPoint');
    if (previousLocation) {
      navigate(previousLocation);
    } else {
      navigate('/');
    }
  };

  const handleClickSkip = () => {
    setIsTourOpen(false);
    setIsShowPopup(false);
    setIsShowDriveFormPopup(false);
    localStorage.setItem('driveTutorial', 'false');
  };

  return (
    <>
      <DefaultHelmet page="drive" />
      <div className="w-full drive-main">
        {isShowOnBoading ? (
          <DriveOnBoading onClickStart={handleClickStartBtn}></DriveOnBoading>
        ) : (
          <>
            <MenuHeader
              title="운행일지"
              icons={!!driveVehicleInfoId ? menuHeaderIcons : []}
              onClickPrevBtn={handleClickPrevBtn}
            />
            <div className="drive-home w-full pt-[60px] h-screen bg-gray-2">
              <div className="fixed items-center flex gap-[6px] z-30 mx-auto left-0 right-0 px-4 pt-[6px] pb-[10px] w-full min-w-[280px] max-w-[720px] max-h-[50px] bg-white top-[60px] shadow-[0_2px_4px_rgba(0,0,0,0.05)]">
                {addButtons.map((button, index) => (
                  <Button
                    key={index}
                    size="small"
                    sx={{
                      backgroundColor: button.bgColor,
                      color: button.textColor,
                      fontSize: button.fontSize,
                      fontWeight: button.fontWeight,
                      height: '34px',
                      borderRadius: button.borderRadius,
                      border: `${button.border ? button.border : '1px solid'} ${button.borderColor}`,
                      paddingY: '0px',
                      paddingX: '12px',
                      justifyContent: 'center',
                      className: 'addDriveBtn',
                      '&:hover': {
                        backgroundColor: button.bgColor,
                        color: button.textColor,
                        borderRadius: button.borderRadius,
                        border: `${button.border ? button.border : '1px solid'} ${button.borderColor}`,
                        fontWeight: button.fontWeight,
                        justifyContent: 'center',
                      },
                    }}
                    onClick={button.onClick}
                  >
                    <span className="inline-block font-semibold align-middle">{button.icon}</span>
                    <span className="break-keep leading-[14px]">{button.name}</span>
                  </Button>
                ))}
              </div>
              <section className={`relative pt-4 mt-[50px] bg-white`}>
                <div className="px-4">
                  <CustomCalendar
                    data={data?.incomeHistoryDayList || []}
                    selectedDate={selectedDate}
                    onClickDate={onClickDate}
                    onClickMonth={updateSelectedDate}
                  />
                </div>

                <div className={`px-4 pt-6 ${!!driveVehicleInfoId ? 'pb-6' : 'pb-[132px]'} bg-gray-2`}>
                  <span className="text-gray-8 font-medium text-[14px]">
                    {getFormatDate(dayjs(selectedDate).format('YYYY-MM-DD')?.toString(), ['month'])} 통계
                  </span>
                  <div className="rounded-lg w-full bg-white p-3 mt-[10px] mb-3 relative min-h-[48px] h-fit">
                    <div className="flex items-center justify-between text-gray-8">
                      <div className="sm680:flex sm680:gap-1">
                        <div className="flex items-center gap-1 font-medium text-gray-8">
                          <MoneyCircleBoldIcon color={'#FFDC5F'} /> 수익
                        </div>

                        <div className="flex items-center">
                          <span className="text-gray-8 font-semibold text-[18px]">
                            {data?.incomeHistoryMonth?.income
                              ? formatNumberWithNegative(String(data.incomeHistoryMonth.income))
                              : 0}
                            원
                          </span>
                          <TooltipComponent
                            title="<span style='font-weight: 500'>* 수익 = 총매출 - 지출 + 유가보조금</span><br/>입력하신 내용을 바탕으로 계산된 금액입니다.<br/>연비(거리), 부가세 등으로 차이가 발생할 수 있습니다."
                            content=""
                            position="left"
                            autoAdjustPosition
                          />
                        </div>
                      </div>
                      <BasicButton
                        name={isExpanded.income ? '접기' : '보기'}
                        bgColor={colors.gray[2]}
                        borderColor={colors.gray[2]}
                        textColor={colors.gray[7]}
                        fontSize={14}
                        height={34}
                        width={48}
                        fontWeight="semibold"
                        onClick={() => onClickExpandIcon('income')}
                      />
                    </div>
                    {isExpanded.income && (
                      <div className="flex flex-col gap-[10px] border-t border-gray-4 pt-2 mt-4">
                        <dl className="flex justify-between mt-[6px]">
                          <dt className="align-middle text-gray-7">
                            <span className="align-middle">매출</span>
                            <TooltipComponent
                              title="주유비, 수리비, 통행료, 고정비용(지입료, 보험료, 캐피탈 이자)을 합산한 금액입니다."
                              content=""
                              alignmentTipbox="left"
                            />
                          </dt>

                          <dd
                            className={`flex font-medium cursor-pointer ${
                              data?.incomeHistoryMonth?.sales ? 'text-gray-8' : 'text-gray-7'
                            }`}
                            onClick={() => onClickExpandIcon('sales')}
                          >
                            {data?.incomeHistoryMonth?.sales
                              ? `+${formatNumber(String(data.incomeHistoryMonth.sales))}`
                              : 0}
                            원
                            {data?.incomeHistoryMonth?.sales ? (
                              isExpanded.sales ? (
                                <ChevronUpIcon color={colors.gray[6]} />
                              ) : (
                                <ChevronDownIcon color={colors.gray[6]} />
                              )
                            ) : null}
                          </dd>
                        </dl>
                        {!!isExpanded.sales && (
                          <div className="flex flex-col gap-[10px] justify-between bg-gray-1 p-4 rounded-lg font-regular text-gray-7">
                            <dl className="flex justify-between">
                              <dt>운송료</dt>
                              <dd>
                                {data?.incomeHistoryMonth?.totalTransportCost
                                  ? formatNumber(String(data.incomeHistoryMonth.totalTransportCost))
                                  : 0}
                                원
                              </dd>
                            </dl>
                            <dl className="flex justify-between">
                              <dt>기타수익</dt>
                              <dd>
                                {data?.incomeHistoryMonth?.otherIncome
                                  ? formatNumber(String(data.incomeHistoryMonth.otherIncome))
                                  : 0}
                                원
                              </dd>
                            </dl>
                          </div>
                        )}

                        <dl className="flex justify-between mt-[6px]">
                          <dt className="align-middle text-gray-7">
                            <span className="align-middle">지출</span>
                            <TooltipComponent
                              title="주유비, 수리비, 통행료, 고정비용(지입료, 보험료, 캐피탈 이자)을 합산한 금액입니다."
                              content=""
                              alignmentTipbox="left"
                            />
                          </dt>
                          <dd
                            className="flex font-medium cursor-pointer text-gray-7"
                            onClick={() => onClickExpandIcon('expense')}
                          >
                            {data?.incomeHistoryMonth?.expense
                              ? `-${formatNumber(String(data.incomeHistoryMonth.expense))}`
                              : 0}
                            원
                            {data?.incomeHistoryMonth?.expense ? (
                              isExpanded.expense ? (
                                <ChevronUpIcon color={colors.gray[6]} />
                              ) : (
                                <ChevronDownIcon color={colors.gray[6]} />
                              )
                            ) : null}
                          </dd>
                        </dl>

                        {!!isExpanded.expense && (
                          <div className="flex flex-col gap-[10px] justify-between bg-gray-1 p-4 rounded-lg font-regular text-gray-7">
                            <dl className="flex justify-between">
                              <dt>고정비</dt>
                              <dd>
                                {data?.incomeHistoryMonth?.fixedCost
                                  ? formatNumber(String(data.incomeHistoryMonth.fixedCost))
                                  : 0}
                                원
                              </dd>
                            </dl>
                            <dl className="flex justify-between">
                              <dt>주유비</dt>
                              <dd>
                                {data?.incomeHistoryMonth?.fuelCost
                                  ? formatNumber(String(data.incomeHistoryMonth.fuelCost))
                                  : 0}
                                원
                              </dd>
                            </dl>
                            <dl className="flex justify-between">
                              <dt>기타지출</dt>
                              <dd>
                                {data?.incomeHistoryMonth?.otherExpensesCost
                                  ? formatNumber(String(data.incomeHistoryMonth.otherExpensesCost))
                                  : 0}
                                원
                              </dd>
                            </dl>
                            <dl className="flex justify-between">
                              <dt>통행료</dt>
                              <dd>
                                {data?.incomeHistoryMonth?.toll
                                  ? formatNumber(String(data.incomeHistoryMonth.toll))
                                  : 0}
                                원
                              </dd>
                            </dl>
                          </div>
                        )}

                        <dl className="flex justify-between mt-[6px]">
                          <dt className="text-gray-7">
                            <span className="align-middle">유가 보조금</span>
                            <TooltipComponent
                              title="[화물자동차 유가보조금 관리 규정]에 따라 유류세 연동 보조금을 자동으로 산출한 결과입니다. (유가연동보조금 제외)"
                              content=""
                              alignmentTipbox="left"
                            />
                          </dt>
                          <dd
                            className={`flex font-medium cursor-pointer ${
                              data?.incomeHistoryMonth?.subsidyForFuel ? 'text-gray-8' : 'text-gray-7'
                            }`}
                          >
                            {data?.incomeHistoryMonth?.subsidyForFuel
                              ? `+${formatNumber(String(data.incomeHistoryMonth.subsidyForFuel))}`
                              : 0}
                            원
                          </dd>
                        </dl>
                      </div>
                    )}
                  </div>

                  <div
                    className="rounded-lg w-full bg-white p-3 my-3 cursor-pointer min-h-[48px] h-fit"
                    onClick={onClickGoToOutstandingAmountList}
                  >
                    <div className="flex items-center justify-between text-gray-8">
                      <div className="sm680:flex sm680:gap-1">
                        <div className="flex items-center gap-1 font-medium text-gray-8">
                          <AccountsReceivableColorIcon color={colors.gray[5]} /> 미수금
                        </div>

                        <div className="flex items-center">
                          <span className="text-gray-8 font-semibold text-[18px]">
                            {data?.incomeHistoryMonth?.outstandingAmount
                              ? formatNumber(String(data.incomeHistoryMonth.outstandingAmount))
                              : 0}
                            원
                          </span>
                          <TooltipComponent
                            title="당월 매출 내역에서 수금 체크가 되어 있지 않은 항목을 합산한 금액입니다."
                            content=""
                            position="left"
                            autoAdjustPosition
                          />
                        </div>
                      </div>
                      <BasicButton
                        name={'내역'}
                        bgColor={colors.gray[2]}
                        borderColor={colors.gray[2]}
                        textColor={colors.gray[7]}
                        fontSize={14}
                        height={34}
                        width={48}
                        fontWeight="semibold"
                        onClick={() => {}}
                      />
                    </div>
                  </div>

                  <div
                    className="rounded-lg w-full bg-white p-3 my-3 cursor-pointer min-h-[48px] h-fit"
                    onClick={() => onClickGoToManagement('fuel')}
                  >
                    <div className="flex items-center justify-between text-gray-8">
                      <div className="sm680:flex sm680:gap-1">
                        <div className="flex items-center gap-1 font-medium text-gray-8">
                          <OilingColorIcon color={colors.green[0]} /> 주유비
                        </div>

                        <div className="flex items-center">
                          <span className="text-gray-8 font-semibold text-[18px]">
                            {data?.incomeHistoryMonth?.fuelCost
                              ? formatNumber(String(data.incomeHistoryMonth.fuelCost))
                              : 0}
                            원
                          </span>
                        </div>
                      </div>
                      <BasicButton
                        name={'내역'}
                        bgColor={colors.gray[2]}
                        borderColor={colors.gray[2]}
                        textColor={colors.gray[7]}
                        fontSize={14}
                        height={34}
                        width={48}
                        fontWeight="semibold"
                        onClick={() => {}}
                      />
                    </div>
                  </div>

                  <div className="flex gap-3 pb-8">
                    <div
                      className="relative flex-1 w-full p-3 bg-white rounded-lg cursor-pointer h-fit sm680:flex sm680:justify-between"
                      onClick={() => onClickGoToManagement('other-expense')}
                    >
                      <div className="sm680:flex sm680:gap-1">
                        <div className="flex items-center gap-1 font-medium text-gray-8">
                          <OtherExpensesColorIcon width={24} height={24} />
                          기타지출
                        </div>
                        <p className="font-bold text-gray-8 overflow-hidden break-keep whitespace-no-wrap overflow-ellipsis sm280:max-w-[86px] max-w-[120px] leading-[34px] text-[18px]">
                          {data?.incomeHistoryMonth?.otherExpensesCost
                            ? formatNumber(String(data.incomeHistoryMonth.otherExpensesCost))
                            : 0}
                          원
                        </p>
                      </div>

                      <div className="flex justify-end">
                        <BasicButton
                          name={'내역'}
                          bgColor={colors.gray[2]}
                          borderColor={colors.gray[2]}
                          textColor={colors.gray[7]}
                          fontSize={14}
                          height={34}
                          width={48}
                          fontWeight="semibold"
                          onClick={() => {}}
                        />
                      </div>
                    </div>

                    <div
                      className="relative flex-1 w-full p-3 bg-white rounded-lg cursor-pointer h-fit sm680:flex sm680:justify-between"
                      onClick={() => onClickGoToManagement('other-expense')}
                    >
                      <div className="sm680:flex sm680:gap-1">
                        <div className="flex items-center gap-1 font-medium text-gray-8">
                          <OtherIncomeColorIcon width={24} height={24} />
                          기타수익
                        </div>
                        <p className="font-bold text-gray-8 overflow-hidden break-keep whitespace-no-wrap overflow-ellipsis sm280:max-w-[86px] max-w-[120px] leading-[34px] text-[18px]">
                          {data?.incomeHistoryMonth?.otherIncome
                            ? formatNumber(String(data.incomeHistoryMonth.otherIncome))
                            : 0}
                          원
                        </p>
                      </div>
                      <div className="flex justify-end">
                        <BasicButton
                          name={'내역'}
                          bgColor={colors.gray[2]}
                          borderColor={colors.gray[2]}
                          textColor={colors.gray[7]}
                          fontSize={14}
                          height={34}
                          width={48}
                          fontWeight="semibold"
                          onClick={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <ConfirmModal
              isOpen={isVehicleInfoPopupOpen}
              content={
                <>
                  운행일지를 이용하기 위해
                  <br />
                  차량 정보를 등록해주세요.
                </>
              }
              onLeftButtonClick={() => setIsVehicleInfoPopupOpen(false)}
              rightButtonLabel="등록하기"
              onRightButtonClick={() => onClickGoToSetting()}
            />

            {isShowPopup && driveVehicleInfoId && (
              <BottomSheetPopup
                isOpen={isShowPopup && !!driveVehicleInfoId}
                onClose={onClosePopup}
                title={`${getFormatDate(dayjs(selectedDate).format('YYYY-MM-DD')?.toString(), ['month', 'day'])} 내역`}
                isBackdrop={false}
              >
                <DriveListView
                  selectedDate={dayjs(selectedDate).format('YYYY-MM-DD')?.toString()}
                  driveVehicleInfoId={driveVehicleInfoId}
                  data={data}
                  driveHistoryList={data?.driveHistoryList || []}
                  refetch={refetch}
                  onClickItem={onClickDetail}
                  onClickAddBtn={onClickAddBtn}
                  onControlTutorial={goToNextStep}
                />
              </BottomSheetPopup>
            )}
            {isShowDetailPopup && driveVehicleInfoId && (
              <BottomSheetPopup
                isOpen={isShowDetailPopup && !!driveVehicleInfoId}
                onClose={() => setIsShowDetailPopup(false)}
                title={popupInfo.name}
                onClear={clearSelectedItem}
                isFullHeight
                isBackdrop={false}
                isXMarkVisible={false}
                isPrevBtnVisible={true}
                icons={otherExpenseDetailHeader}
              >
                <OtherExpenseDetail
                  isEditMode={isEditMode}
                  selectedList={selectedDeleteList}
                  baseDate={selectedDate}
                  updateEditMode={updateEditMode}
                />
              </BottomSheetPopup>
            )}

            {isShowDriveFormPopup && driveVehicleInfoId && (
              <BottomSheetPopup
                isOpen={isShowDriveFormPopup && !!driveVehicleInfoId}
                onClose={onCloseFormPopup}
                title={popupInfo.name}
                onClear={clearSelectedItem}
                isFullHeight
                isBackdrop={false}
              >
                <>
                  {popupInfo.type == 'drive' && (
                    <DriveForm
                      selectedDate={selectedDate}
                      driveVehicleInfoId={driveVehicleInfoId}
                      data={clickedItemIndex !== null ? data?.driveHistoryList[clickedItemIndex] : undefined}
                      onClosePopup={onCloseFormPopup}
                      refetch={refetch}
                      onControlTutorial={onClickDriveTypeTutorial}
                      updateSelectedDate={updateSelectedDate}
                    ></DriveForm>
                  )}
                  {popupInfo.type == 'fuel' && (
                    <FuelingHistoryForm
                      onClosePopup={onCloseFormPopup}
                      selectedDate={selectedDate}
                      driveVehicleInfoId={driveVehicleInfoId}
                      data={clickedItemIndex !== null ? data?.fuelingHistories[clickedItemIndex] : undefined}
                      refetch={refetch}
                    ></FuelingHistoryForm>
                  )}
                  {popupInfo.type === 'otherExpense' && (
                    <OtherExpenseHistoryForm
                      selectedDate={selectedDate}
                      driveVehicleInfoId={driveVehicleInfoId}
                      data={undefined}
                      onClosePopup={onCloseFormPopup}
                      refetch={refetch}
                      onControlTutorial={onClickCategoryTutorial}
                    />
                  )}
                </>
              </BottomSheetPopup>
            )}

            {(isShowPopup || isShowDriveFormPopup) && (
              <div
                className={`fixed bg-black opacity-50 w-full h-full justify-center items-center top-0 left-0 inline-flex z-40`}
              ></div>
            )}
            <Suspense fallback={<></>}>
              <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => {
                  setIsTourOpen(false);
                }}
                goToStep={step}
                closeWithMask={false}
                rounded={10}
                showButtons={false}
                showNumber={false}
                showNavigationNumber={false}
                showNavigation={false}
                showCloseButton={false}
              />
            </Suspense>
            {isTourOpen && (
              <div
                className="flex absolute top-4 right-4 text-gray-0 font-medium text-[16px] z-[100000]"
                onClick={handleClickSkip}
              >
                건너뛰기 <ChevronRightIcon color={colors.gray[0]} />
              </div>
            )}

            <BaseModal
              isOpen={isShowFinalStep}
              onClose={onClickFinalGuidePopup}
              content={
                <>
                  <img
                    loading="lazy"
                    src={popupImage?.contents}
                    alt="popup_image"
                    className="object-cover w-full h-full"
                    onClick={onClickFinalGuidePopup}
                  />
                  <div className={`cursor-pointer absolute top-3 right-3 w-6 h-6 p-2`} onClick={onClickFinalGuidePopup}>
                    <XMarkIcon color={colors.gray[0]}></XMarkIcon>
                  </div>
                </>
              }
              paddingType="none"
            />

            <SlideUpPopup
              isShow={!driveVehicleInfoId && !!isMemberLoaded}
              onClosePopup={onCloseFormPopup}
              iconTitle={
                <div className="flex px-5">
                  <VehicleInspectionPlusColorIcon width={34} height={34} />
                  <span className="text-primary font-bold text-[20px] ml-2">차량정보 등록</span>
                </div>
              }
              onClear={clearSelectedItem}
              isBackdrop={false}
              isShowXMark={false}
            >
              <div className="flex justify-between px-5 pb-6 sm280:block">
                <div className="text-gray-7 text-[14px]">
                  운행일지 작성을 위해
                  <br className="sm680:hidden" />
                  차량의 기본 정보를 입력해주세요.
                </div>
                <div className="sm280:flex sm280:justify-end">
                  <BasicButton
                    name={'등록하기'}
                    bgColor={colors.blue[7]}
                    borderColor={colors.blue[7]}
                    textColor={colors.gray[0]}
                    fontSize={14}
                    height={34}
                    width={74}
                    fontWeight="semibold"
                    onClick={onClickGoToSetting}
                  />
                </div>
              </div>
            </SlideUpPopup>
          </>
        )}
      </div>
    </>
  );
};

export default DriveHome;

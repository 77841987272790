import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon, HeadphoneIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import Button from '@mui/material/Button';

const OneStopServiceRequestButton = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center w-full px-4 py-2 h-18 max-w-[720px] min-w-[280px]">
      <Button
        component="label"
        variant="contained"
        className="w-full flex justify-center items-center"
        sx={{
          background: colors.gray[1],
          color: colors.gray[7],
          borderRadius: '8px',
          height: '72px',
          '&:hover': {
            backgroundColor: colors.gray[1],
          },
        }}
        onClick={() => navigate('/one-stop-service')}
      >
        <div className="flex items-center justify-between w-full my-4">
          <div className="ml-1 flex items-center justify-between">
            <div className="mr-[6px]">
              <HeadphoneIcon color={colors.gray[7]} width={28} height={28} />
            </div>
            <div className="">
              <div className="text-gray-6 font-medium text-[11px] xxs:text-[10.5px] leading-[14px]">
                차량 판매에 어려움이 있으신가요?
              </div>
              <div className="font-bold text-[16px] sm280:text-[14px] leading-[18px] mt-1">
                판매 대행 서비스 신청하기
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default OneStopServiceRequestButton;

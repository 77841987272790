import React, { ReactNode, createContext, useContext, useState } from 'react';

type ProductEnumContextType = {
  productEnum: ProductEnum | null;
  setProductEnum: (newProductEnum: ProductEnum) => void;
};
const ProductEnumContext = createContext<ProductEnumContextType | null>(null);

const ProductEnumProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [productEnum, setProductEnum] = useState<ProductEnum | null>(null);
  return <ProductEnumContext.Provider value={{ productEnum, setProductEnum }}>{children}</ProductEnumContext.Provider>;
};
export const useProductEnumContext = () => {
  const context = useContext(ProductEnumContext) as ProductEnumContextType;
  if (context === undefined) {
    throw new Error('useProductEnumContext must be used within a ProductEnumProvider');
  }
  return context;
};

export { ProductEnumContext, ProductEnumProvider };

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AutoPlay } from '@egjs/flicking-plugins';
import Flicking, { EVENTS } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

interface BannerCarouselProps {
  banners: Banner[];
}

export default function BannerCarousel({ banners }: BannerCarouselProps) {
  const navigate = useNavigate();
  const [bulletList, setBulletList] = useState(banners.map((item, index) => ({ isNow: index === 0 })));
  const flickingRef = useRef<Flicking | null>(null);

  const plugins = [new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: false })];

  const onClickBanner = (item: Banner) => {
    if (item.link) {
      if (item.type.code === 'INTERNAL') {
        navigate(item.link);
      } else {
        window.open(item.link);
      }
    }
  };

  useEffect(() => {
    const bullet = banners.map((item, index) => ({ isNow: index === 0 }));
    setBulletList(bullet);
    if (flickingRef.current) {
      flickingRef.current.on(EVENTS.CHANGED, (event) => {
        const updatedBullet = banners.map((_, index) => ({ isNow: index === event.index }));
        setBulletList(updatedBullet);
      });
    }
  }, [banners]);

  const onClickBullet = (index: number) => {
    if (flickingRef.current) {
      flickingRef.current.moveTo(index).catch(() => void 0);
    }
  };

  return (
    <div className="relative">
      {banners.map((item, index) => (
        <link rel="preload" href={item.contents} as="image" key={index} />
      ))}
      <div className="w-full">
        {!!banners.length && (
          <Flicking
            ref={flickingRef}
            align="center"
            circular={true}
            renderOnlyVisible={true}
            className="z-0"
            moveType="strict"
            plugins={plugins}
          >
            {banners.map((item, index) => (
              <div
                className="flex mx-1 min-w-[220px] max-w-[688px] w-[80%] sm280:w-[220px] sm280:h-[94px] rounded-lg my-1"
                key={index}
                onClick={() => onClickBanner(item)}
              >
                {item.contentsType.code === 'IMAGE' && (
                  <img
                    loading="lazy"
                    src={item.contents}
                    alt="배너 이미지"
                    width="688"
                    height="344"
                    className="object-center w-full h-full rounded-lg"
                    style={{
                      filter: 'drop-shadow(0 0 2px rgba(0, 0, 0, 0.12))',
                      pointerEvents: 'none',
                      aspectRatio: '300 / 128',
                    }}
                  />
                )}
                {item.contentsType.code === 'HTML' && (
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{ __html: item.contents }}
                    style={{
                      pointerEvents: 'none',
                      minWidth: '220px',
                      maxWidth: '688px',
                      minHeight: '94px',
                      maxHeight: '344px',
                    }}
                  />
                )}
              </div>
            ))}
          </Flicking>
        )}
      </div>
      <div className="absolute bottom-[-56px] left-[50%] transform -translate-x-1/2 flex items-end justify-center py-8 cursor-pointer z-20">
        {bulletList.map((data, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full cursor-pointer m-1  ${data.isNow ? 'bg-primary' : 'bg-gray-4'}`}
            onClick={() => onClickBullet(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export const SALES_TYPE_ASSURANCE = 'ASSURANCE';
export const SALES_TYPE_CONSIGNMENT = 'CONSIGNMENT';
export const SALES_TYPE_NORMAL = 'NORMAL';
export const SALES_TYPE_THIRD_PARTY_DEALER = 'THIRD_PARTY_DEALER';
export const PRODUCT_TYPE_DIRECT = 'DIRECT';
export const PRODUCT_TYPE_SPEED = 'SPEED';

export const HASH_PRODUCT_INFO = 'info';
export const HASH_DETAIL = 'detail';
export const HASH_PHOTO = 'photo';
export const HASH_PRICE = 'price';

export const ETC = 'ETC';

export const SALESTYPE = {
  DIRECT: '직거래 셀프 판매',
  SPEED: '전문가에게 빠른 판매',
} as const;

// example axios wrapper
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuHeader from '@/components/Header/MenuHeader';
import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import SalePlan from '@/components/Guide/Sale/SalePlan';
import PreSaleCheckList from '@/components/Guide/Sale/PreSaleCheckList';
import SaleContract from '@/components/Guide/Sale/SaleContract';
import SaleAfterCare from '@/components/Guide/Sale/SaleAfterCare';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

const SaleGuide = () => {
  const labelList: TabType[] = [
    { icon: CalendarMonthOutlinedIcon, title: '판매 계획' },
    { icon: InventoryOutlinedIcon, title: '체크 사항' },
    { icon: HandshakeOutlinedIcon, title: '차량 계약' },
    { icon: ManageAccountsOutlinedIcon, title: '사후 관리' },
  ];
  const [tabNum, setTabNum] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [tabNum]);

  return (
    <>
      <MenuHeader title="화물차 판매 가이드"></MenuHeader>
      <div className="pt-[60px]">
        <div className="bg-white top-[60px] w-full max-w-[720px] fixed">
          <BasicTabs labelList={labelList} value={tabNum} onChange={setTabNum} />
        </div>
        <div style={{ marginTop: '73px' }}>
          {tabNum === 0 && <SalePlan />}
          {tabNum === 1 && <PreSaleCheckList />}
          {tabNum === 2 && <SaleContract />}
          {tabNum === 3 && <SaleAfterCare />}
        </div>
      </div>
    </>
  );
};
export default SaleGuide;

import React, { Dispatch } from 'react';

import MultiSelectTags from '@/components/Common/Tag/MultiSelectTags';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface MultiSelectBoxProps {
  label: string;
  placeholder: string;
  items: OptionDataType[];
  setItems: Dispatch<React.SetStateAction<OptionDataType[]>>;
  onClickDeleteBtn: (unselectedItem: OptionDataType, key: string) => void;
  itemName: string;
  tagWidth: number;
  onClickSelectBox: (key: string) => void;
  selectBoxKey: string;
}

const MultiSelectBox = ({
  label,
  placeholder,
  items,
  setItems,
  onClickDeleteBtn,
  itemName,
  tagWidth,
  onClickSelectBox,
  selectBoxKey,
}: MultiSelectBoxProps) => {
  return (
    <div className="py-3" onClick={() => onClickSelectBox(selectBoxKey)}>
      <div
        className={`relative flex items-center border border-gray-4 ${
          items.length === 0 ? 'h-[52px]' : 'h-[60px]'
        } py-3 px-4 rounded-[10px]`}
      >
        <div className="flex-1">
          {items.length === 0 && <div className="text-gray-5">{placeholder}</div>}
          <MultiSelectTags
            items={items}
            setItems={setItems}
            onClickDeleteBtn={onClickDeleteBtn}
            itemName={itemName}
            tagWidth={tagWidth}
          ></MultiSelectTags>
        </div>
        <div className="absolute right-4">
          <ChevronDownIcon color={colors.gray[5]}></ChevronDownIcon>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectBox;

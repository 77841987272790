import React, { forwardRef } from 'react';

import { CarInspectionColorIcon, CarPaymentBlueColorIcon, VehicleInspectionColorIcon } from '@/components/Icon';

const AccompanyingGuidePopup = forwardRef<HTMLDivElement>(({}, ref) => {
  return (
    <div ref={ref} className="pb-[100px]">
      <div className="flex flex-col items-center justify-center">
        <div className="break-keep text-center max-w-[300px] font-medium text-gray-8">
          중고화물차를 구매하기 전, 차량 전문가가 동행하여 차량 점검부터 적절한 금액 협상까지 도와드리는 서비스입니다.
        </div>

        <div className="bg-gray-1 rounded-[12px] p-5 mt-[30px] mx-4">
          <div className="pb-[22px] border-b border-gray-4">
            <div className="flex items-center">
              <CarInspectionColorIcon width={36} height={36}></CarInspectionColorIcon>
              <div className="text-[18px] font-semibold text-gray-8 ml-2">차량 점검</div>
            </div>
            <dl className="mt-2">
              <dt className="font-medium text-[14px]">사고 여부</dt>
              <dd className="text-gray-7 text-[12px] mb-4">· 차량의 사고 이력을 확인합니다.</dd>
              <dt className="font-medium text-[14px]">각종 누유</dt>
              <dd className="text-gray-7 text-[12px] mb-4">· 엔진 및 기타 부위의 누유 여부를 점검합니다.</dd>
              <dt className="font-medium text-[14px]">엔진 및 미션</dt>
              <dd className="text-gray-7 text-[12px]">· 엔진과 미션 상태를 확인하여 성능을 평가합니다.</dd>
            </dl>
          </div>
          <div className="py-[22px] border-b border-gray-4">
            <div className="flex items-center">
              <VehicleInspectionColorIcon width={36} height={36}></VehicleInspectionColorIcon>
              <div className="text-[18px] font-semibold text-gray-8 ml-2">특장 점검</div>
            </div>
            <dl className="mt-2">
              <dt className="font-medium text-[14px]">제원 상태 측정</dt>
              <dd className="text-gray-7 text-[12px] mb-4">
                · 차량의 제원 상태를 측정하여 정상 범위 내인지 확인합니다.
              </dd>
              <dt className="font-medium text-[14px]">특장 하자 체크</dt>
              <dd className="text-gray-7 text-[12px] mb-4">· 특장(적재함) 부분에 문제점이 있는지 확인합니다.</dd>
              <dt className="font-medium text-[14px]">수리 발생 견적</dt>
              <dd className="text-gray-7 text-[12px]">· 필요한 수리 비용을 산정합니다.</dd>
            </dl>
          </div>
          <div className="pt-[22px]">
            <div className="flex items-center">
              <CarPaymentBlueColorIcon width={36} height={36}></CarPaymentBlueColorIcon>
              <div className="text-[18px] font-semibold text-gray-8 ml-2">추가 서비스</div>
            </div>
            <dl className="mt-2">
              <dt className="font-medium text-[14px]">차량 금액 협의</dt>
              <dd className="text-gray-7 text-[12px] mb-4">· 차량을 점검 후 적절한 가격 협상을 도와드립니다.</dd>
              <dt className="font-medium text-[14px]">계약 절차 협의</dt>
              <dd className="text-gray-7 text-[12px] mb-4">· 계약서 작성 및 절차를 도와드립니다.</dd>
              <dt className="font-medium text-[14px]">차량 잔금 처리</dt>
              <dd className="text-gray-7 text-[12px]">· 저당 및 압류 상태를 확인하고 잔금 처리를 지원합니다.</dd>
            </dl>
          </div>
        </div>
        <div className="text-gray-6 text-[12px] pt-6">※ 해당 서비스는 유료 서비스입니다.</div>
      </div>
    </div>
  );
});

export default AccompanyingGuidePopup;

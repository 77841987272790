import React, { SVGProps } from 'react';

const SvgDeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 144 144" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M61 14a4 4 0 0 0-4 4v10h30V18a4 4 0 0 0-4-4H61Zm-10 4v10H21a3 3 0 1 0 0 6h6.5v91.5c0 5.523 4.477 10 10 10H105c5.523 0 10-4.477 10-10V34h8a3 3 0 1 0 0-6H93V18c0-5.523-4.477-10-10-10H61c-5.523 0-10 4.477-10 10Zm58 16H33.5v91.5a4 4 0 0 0 4 4H105a4 4 0 0 0 4-4V34ZM53.879 64.879a3 3 0 0 1 4.242 0L71.5 78.257 84.879 64.88a3 3 0 1 1 4.242 4.242L75.743 82.5 89.12 95.879a3 3 0 1 1-4.242 4.242L71.5 86.743 58.121 100.12a3 3 0 1 1-4.242-4.242L67.257 82.5 53.88 69.121a3 3 0 0 1 0-4.242Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgDeleteIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgDeleteIcon;

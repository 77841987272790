import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon } from '../Icon';
import { colors } from '@/const/colors';
import '@/css/onBoadingStyle.css';
import Flicking, { EVENTS, MoveStartEvent } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

type OnBoadingType = {
  onClickGoHome: () => void;
};

const OnBoading = ({ onClickGoHome }: OnBoadingType) => {
  const navigate = useNavigate();
  const flickingRef = useRef<Flicking>(null);
  const beforeOnboadingRef = useRef<boolean>(false);
  const [isShowGoHome, setIsShowGoHome] = useState<boolean>(false);
  const onBoadingDataList = [
    {
      title: '차량 시세 제공',
      desc: '시세를 몰라도 OK!',
      descTitle: '데이터를 통해 적절한 시세 제공',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/onboading_1.png',
    },
    {
      title: '간편한 직거래',
      desc: '전문 딜러 없이 개인 간 직접 거래!',
      descTitle: '중간 마진 타파! 수수료 0원',
      titleDesc: '실제 차주와',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/onboading_2.png',
    },
    {
      title: '간편 차량 등록',
      desc: '간편 절차로 번거로움 NO!',
      descTitle: '번호판 입력만으로 차량 조회 가능',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/onboading_3.png',
    },
    {
      title: '최저 금리',
      desc: '차량 대금에 필요한 저금리 대출!',
      descTitle: '협력 캐피탈을 통한 최저 금리!',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/onboading_4.png',
    },
    {
      title: '운행일지',
      desc: '돈 관리를 빠르고 간편하게!',
      descTitle: '화물 운행일지 서비스 제공',
      imageUrl: 'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/onboading_5.png',
    },
  ];
  const bullet = onBoadingDataList.map((item, index) => ({ isNow: index === 0 }));
  const [bulletList, setBulletList] = useState(bullet);
  const onClickBullet = (index: number) => {
    if (flickingRef.current) {
      flickingRef.current.moveTo(index).catch(() => void 0);
      setIsShowGoHome(index === 4);
    }
  };

  useEffect(() => {
    if (flickingRef.current) {
      flickingRef.current.on(EVENTS.CHANGED, (event) => {
        const updatedBullet = onBoadingDataList.map((_, index) => ({ isNow: index === event.index }));
        setBulletList(updatedBullet);
      });
    }
  }, [onBoadingDataList]);

  const handleCarouselChange = (e: MoveStartEvent<Flicking>) => {
    const { direction } = e;
    const { index } = e.currentTarget;

    const isGoHomeVisible = direction === 'NEXT' && index === 3;
    const isNavigateToLogin = direction === 'NEXT' && index === 4;

    setIsShowGoHome(isGoHomeVisible);

    if (isNavigateToLogin) {
      navigate('/login');
      localStorage.setItem('isFirst', 'false');
    }
  };

  const onClickGoToNext = (index: number) => {
    if (flickingRef.current) {
      if (index === 4) {
        navigate('/login');
        localStorage.setItem('isFirst', 'false');
      } else {
        flickingRef.current.next().catch(() => void 0);
      }
    }
  };

  return (
    <div className="onboading relative flex flex-col justify-center min-w-[280px] max-w-[720px] w-full min-h-[540px] h-[100dvh] bg-blue-6">
      <div
        className={`flex items-center justify-end pr-4 py-3 cursor-pointer z-10  ${
          isShowGoHome ? 'visible' : 'invisible'
        }`}
        onClick={onClickGoHome}
      >
        <span className="mr-1">홈으로 가기</span>
        <ChevronRightIcon color={colors.gray[8]} width={16} height={16}></ChevronRightIcon>
      </div>

      <div className="relative h-full flex flex-col justify-center items-center min-h-[392px]">
        <Flicking
          ref={flickingRef}
          align="center"
          circular={false}
          renderOnlyVisible={true}
          className="z-0 w-full"
          moveType="strict"
          circularFallback="bound"
          onMoveStart={(e) => handleCarouselChange(e)}
        >
          {onBoadingDataList.map((data, index) => (
            <div
              className="flex flex-col justify-center items-center w-full h-full"
              key={index}
              onClick={() => onClickGoToNext(index)}
            >
              <h2 className={`text-[23px] pb-10 h-[70px] text-center leading-[28px]`}>
                <p>{data?.titleDesc}</p>
                <span className="text-[28px] font-semibold">{data.title}</span>서비스
              </h2>
              <div className="max-w-[260px] max-h-[355px] min-h-[258px]">
                <img src={data.imageUrl as string} alt="onboading_img" className="w-full h-full object-cover"></img>
              </div>
              <div className="text-lg py-5 text-center leading-[20px]">
                <p>{data.desc}</p>
                <p className="font-semibold">{data.descTitle}</p>
              </div>
            </div>
          ))}
        </Flicking>
      </div>
      <div className="flex items-end justify-center py-8 cursor-pointer z-10">
        {bulletList.map((data, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full cursor-pointer m-1 ${data.isNow ? 'bg-[#737373]' : 'bg-[#BEBEBE]'}`}
            onClick={(e) => onClickBullet(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default OnBoading;

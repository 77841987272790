import React from 'react';

import { colors } from '@/const/colors';
import Skeleton from '@mui/material/Skeleton';

const LicenseListSkeleton = () => {
  const skeletonItem = (
    <li className="border border-gray-4 rounded-[8px] p-4 mb-4">
      <Skeleton variant="text" width="100%" animation="wave" height={28} sx={{ bgcolor: colors.gray[2] }} />
      <hr className="my-3 text-gray-4"></hr>
      <Skeleton variant="text" width="100%" animation="wave" height={28} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="text" width="100%" animation="wave" height={28} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="text" width="100%" animation="wave" height={28} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="text" width="100%" animation="wave" height={28} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="text" width="100%" animation="wave" height={60} sx={{ bgcolor: colors.gray[2] }} />
    </li>
  );

  return (
    <>
      <ul className="mt-4 mx-4">
        {Array.from({ length: 2 }).map((_, index) => (
          <React.Fragment key={index}>{skeletonItem}</React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default LicenseListSkeleton;

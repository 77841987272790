import React, { useEffect, useState } from 'react';

const Timer = () => {
  const INITIAL_TIME = 5 * 60 * 1000; // 5분
  const [timeLeft, setTimeLeft] = useState<number>(INITIAL_TIME);

  useEffect(() => {
    const storedStartTime = sessionStorage.getItem('timerStartTime');
    const storedEndTime = sessionStorage.getItem('timerEndTime');

    if (storedStartTime && storedEndTime) {
      const endTime = Number(storedEndTime);
      const currentTime = Date.now();

      if (currentTime < endTime) {
        setTimeLeft(endTime - currentTime);
      } else {
        sessionStorage.removeItem('timerStartTime');
        sessionStorage.removeItem('timerEndTime');
      }
    } else {
      const newEndTime = Date.now() + INITIAL_TIME;
      sessionStorage.setItem('timerStartTime', String(Date.now()));
      sessionStorage.setItem('timerEndTime', String(newEndTime));
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timer);
          sessionStorage.removeItem('timerStartTime');
          sessionStorage.removeItem('timerEndTime');
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(2, '0');
  const seconds = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');

  return timeLeft > 0 ? (
    <span className="font-normal">
      {minutes}:{seconds}
    </span>
  ) : (
    <span className="text-sm text-red">인증시간이 만료되었습니다.</span>
  );
};

export default Timer;

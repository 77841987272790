import React, { SVGProps } from 'react';

const SvgPhoneRingSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M3 8.286A2.286 2.286 0 0 1 5.286 6H6.33c.655 0 1.227.446 1.386 1.082l.842 3.37a1.429 1.429 0 0 1-.529 1.49l-.985.739c-.103.076-.125.189-.096.267a8.598 8.598 0 0 0 5.103 5.103c.078.029.19.007.267-.096l.74-.985c.344-.46.931-.669 1.489-.53l3.37.843c.636.16 1.082.73 1.082 1.386v1.045A2.286 2.286 0 0 1 16.714 22H15C8.373 22 3 16.627 3 10V8.286Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M22 14c0-6.075-4.925-11-11-11v1c5.523 0 10 4.477 10 10h1Zm-3 0a8 8 0 0 0-8-8v1a7 7 0 0 1 7 7h1Zm-3 0a5 5 0 0 0-5-5v1a4 4 0 0 1 4 4h1Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgPhoneRingSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPhoneRingSolidIcon;

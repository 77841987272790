interface PageMetadata {
  [key: string]: {
    title: string;
    siteUrl: string;
    description?: string;
  };
}

const metadata: PageMetadata = {
  home: {
    title: '중고화물차 직트럭',
    siteUrl: 'https://www.zigtruck.io',
    description: '안전한 직거래, 수수료 0원! 화물차 사고 팔 때, 손해없이 직트럭에서 거래하세요.',
  },
  purchase: {
    title: '내차구매 - 중고 화물차 직거래',
    siteUrl: 'https://www.zigtruck.io/products',
    description: '중고 화물차를 직거래로 구매하세요. 다양한 트럭을 비교하고 최적의 조건으로 구매할 수 있습니다.',
  },
  sale: {
    title: '내차판매 - 중고트럭 빠른 판매',
    siteUrl: 'https://www.zigtruck.io/sell-car',
    description: '중고 화물차를 쉽고 빠르게 판매하세요. 직트럭에서 수수료 없이 최고가 판매를 도와드립니다.',
  },
  license: {
    title: '번호판 거래 - 영업용 번호판 매매',
    siteUrl: 'https://www.zigtruck.io/license',
    description: '영업용 번호판을 합리적인 가격에 사고팔 수 있는 안전한 서비스입니다.',
  },
  drive: {
    title: '운행일지 - 화물차 운행 기록 관리',
    siteUrl: 'https://www.zigtruck.io/drive',
    description:
      '화물 운행일지 서비스로 운행 기록을 빠르고 간편하게 관리하세요. 수익 관리부터 주유비, 기타 지출 & 수익, 유가보조금 관리까지 한곳에서 편리하게 관리하실 수 있습니다.',
  },
};

export default metadata;

import React, { SVGProps } from 'react';

const SvgLockedShieldColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#E7EFFF"
      d="m33.633 6.476-13-4.333a2 2 0 0 0-1.265 0l-13 4.333A2 2 0 0 0 5 8.373v14.663c0 9.464 11.983 13.91 14.528 14.748.314.103.633.114.952.03C23.043 37.139 35 33.435 35 23.036V8.373a2 2 0 0 0-1.367-1.897Z"
    />
    <path
      fill="#3C5FBF"
      fillRule="evenodd"
      d="M20 11.932a3.715 3.715 0 0 1 3.695 3.333h-7.39A3.715 3.715 0 0 1 20 11.932Zm-6 3.333h.298a5.715 5.715 0 0 1 11.404 0H26a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2Zm8 4.465c0 .691-.402 1.295-1 1.617v1.869a1 1 0 1 1-2 0v-1.869c-.598-.322-1-.926-1-1.617 0-1.03.895-1.866 2-1.866s2 .835 2 1.866Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgLockedShieldColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgLockedShieldColorIcon;

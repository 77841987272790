import React, { SVGProps } from 'react';

const SvgEmptyJobIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 120 120" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M46 28h26a5 5 0 0 1 5 5v10H41V33a5 5 0 0 1 5-5Zm-9 15V33a9 9 0 0 1 9-9h26a9 9 0 0 1 9 9v10h10a9 9 0 0 1 9 9v35a9 9 0 0 1-9 9H27a9 9 0 0 1-9-9V52a9 9 0 0 1 9-9h10Zm44 4H27a5 5 0 0 0-5 5v35a5 5 0 0 0 5 5h64a5 5 0 0 0 5-5V52a5 5 0 0 0-5-5H81Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgEmptyJobIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEmptyJobIcon;

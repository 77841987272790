import React, { SVGProps } from 'react';

const SvgPlusCircleBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M15 1C7.268 1 1 7.268 1 15s6.268 14 14 14 14-6.268 14-14S22.732 1 15 1Zm.778 7.778a.778.778 0 1 0-1.556 0v5.444H8.778a.778.778 0 1 0 0 1.556h5.444v5.444a.778.778 0 1 0 1.556 0v-5.444h5.444a.778.778 0 1 0 0-1.556h-5.444V8.778Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgPlusCircleBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPlusCircleBoldIcon;

import React from 'react';

import { XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/baseModalStyle.css';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fade } from '@mui/material';

const BaseModal = ({
  isOpen,
  onClose,
  title,
  content,
  leftButtonLabel,
  onLeftButtonClick,
  rightButtonLabel,
  onRightButtonClick,
  isXMarkVisible = false,
  paddingType = 'padding-top-48',
}: BaseModal) => {
  const screenWidth = window.innerWidth;
  let width = 240;

  if (screenWidth >= 360) {
    width = 320;
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose ? onClose : () => onLeftButtonClick?.()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 100 }}
        PaperProps={{
          className: paddingType,
        }}
      >
        {(title || isXMarkVisible) && (
          <DialogTitle
            id="alert-dialog-title"
            className="text-center"
            sx={{
              width: `${width}px`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {title && (
              <span
                className={`font-semibold text-center text-gray-9 break-keep text-[20px] leading-[26px] sm280:text-[16px] sm280:leading-[22px]`}
                style={isXMarkVisible ? { maxWidth: `${width - 60}px` } : {}}
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
            )}
            {isXMarkVisible && (
              <div className="absolute top-[20px] flex cursor-pointer right-4 ml-4" onClick={onClose}>
                <XMarkIcon color={colors.gray[9]}></XMarkIcon>
              </div>
            )}
          </DialogTitle>
        )}

        <DialogContent
          sx={{
            color: '#414141',
            textAlign: 'center',
            width: `${width}px`,
          }}
        >
          {typeof content === 'string' ? (
            <DialogContentText id="alert-dialog-description">
              <span className="text-gray-8 text-[18px] leading-[25px] sm280:text-[14px] sm280:leading-[20px]">
                {content}
              </span>
            </DialogContentText>
          ) : (
            <div className="text-gray-8 text-[18px] leading-[25px] sm280:text-[14px] sm280:leading-[20px]">
              {content}
            </div>
          )}
        </DialogContent>
        {(!!leftButtonLabel || !!rightButtonLabel) && (
          <div className="flex items-center w-full text-center border-t justify-bewteen">
            {!!leftButtonLabel && onLeftButtonClick && (
              <div
                className="w-full cursor-pointer text-gray-6 font-semibold py-[17px] text-[20px] leading-[20px] sm280:text-[16px] sm280:leading-[16px]"
                onClick={onLeftButtonClick}
              >
                {leftButtonLabel}
              </div>
            )}
            {!!rightButtonLabel && onRightButtonClick && (
              <div
                className="w-full border-l cursor-pointer text-primary font-semibold py-[17px] text-[20px] leading-[20px] sm280:text-[16px] sm280:leading-[16px]"
                onClick={onRightButtonClick}
              >
                {rightButtonLabel}
              </div>
            )}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default BaseModal;

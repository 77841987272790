export const PASSWORD_NOT_MATCH_MESSAGE = '비밀번호가 일치하지 않습니다. 다시 확인해주세요.';
export const PASSWORD_VALIDATION_MESSAGE = '영문, 숫자, 특수문자를 포함해 8~20자리로 입력해주세요.';
export const NAME_VALIDATION_MESSAGE = '이름은 띄어쓰기 없이 한글, 영문, 숫자만 입력해주세요.';
export const NAME_VALIDATION_LENGTH_MESSAGE = '이름은 20자 이하로 입력해주세요.';
export const PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE = '휴대폰 번호는 11자 이하로 입력해주세요.';
export const PHONE_NUMBER_VALIDATION_MESSAGE = '번호를 입력해주세요.';

export const COMMON_TOAST_ERROR = '문제가 생겼어요. 다시 시도해주세요.';
export const MIN_IS_SMALLER_ERROR = '최대값보다 작아야합니다.';
export const MAX_IS_BIGGER_ERROR = '최소값보다 커야합니다.';

export const YEAR_IS_OVER_2000 = '2000 이상으로 입력해주세요.';
export const YEAR_IS_LESS_THAN_CURRENT = `${new Date().getFullYear() + 1} 이하로 입력해주세요.`;

export const TONS_IS_OVER_1 = '1톤 이상으로 입력해주세요.';
export const TONS_IS_LESS_THAN_27 = '27톤 이하로 입력해주세요.';

export const LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE = '1m 이상으로 입력해주세요.';
export const LOADED_INNER_LENGTH_IS_NOT_OVER_TEN_POINT_FIVE = '10.5m 이하로 입력해주세요.';

export const LOADED_INNER_LENGTH_CHECK_AGAIN = '적재함 길이를 다시 한번 확인해주세요.';

export const DISTANCE_IS_LESS_THAN_2_MILLION = '200만km 이하로 입력해주세요.';

export const REQUEST_TRANSFER_AGENCY_SERVICE_ERROR = '신청에 문제가 생겼어요. 고객센터로 문의해주세요.';

export const LOADED_INNER_LENGTH_RANGE_ERROR_MESSAGE = '1m 이상~10.5m 이하로 입력해주세요.';
export const MIN_LENGTH_ERROR_MESSAGE = '1m 이상으로 입력해주세요.';

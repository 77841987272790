import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import {
  deleteInterestProducts,
  getInterestProducts,
  postInterestProductNotificationSettings,
  postInterestProducts,
  postProductInquiry,
  postProductInquiryCall,
} from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ButtonPopup, { ButtonPopupProps } from '@/components/Common/Popup/ButtonPopup';
import ProductInquiryQr from '@/components/Common/Popup/Contents/ProductInquiryQr';
import InterestProductListSkeleton from '@/components/Common/Skeleton/InterestProductListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChatOutlineIcon, HeartOutlineIcon, PhoneRingOutlineIcon } from '@/components/Icon';
import ProductStatus from '@/components/More/ProductStatus';
import ProductType from '@/components/More/ProductType';
import { colors } from '@/const/colors';
import { ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { SALE } from '@/const/productStatus';
import { SALES_TYPE_ASSURANCE, SALES_TYPE_CONSIGNMENT } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { formatPrice, phoneCall } from '@/utils/common';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox } from '@mui/material';

interface InterestProductsList {
  id: number;
  carNumber: string;
  truckName: string;
  productId: number;
  representImageUrl: string;
  year: string;
  distance: string;
  accident: boolean;
  price: string;
  createdDate: string;
  title: string;
  status: EnumPresenter;
  salesType: EnumPresenter;
  salesPeople: SalesPeople;
  safetyNumber: string;
  tons: number;
  loaded: EnumPresenter;
  loadedInnerLength: number;
  axis: EnumPresenter;
  manufacturerCategoriesId: number;
  transmission: EnumPresenter;
}

interface PurchaseInquiryInfo {
  productId: number;
  name: string;
  phoneNumber: string;
  loaded: string;
}

const InterestProductsList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();
  const [unlikeIds, setUnlikeIds] = useState<number[]>([]);
  const [isShowButtonPopup, setIsShowButtonPopup] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const interestProduct = useRef<InterestProductNotificationSettingsRequest | null>(null);
  const inquiryForm = useRef<PurchaseInquiryInfo>();
  let inquiryFormData = inquiryForm.current;
  const [isShowBasicPopup, setIsShowBasicPopup] = useState(false);
  const authRedirect = useAuthRedirect();
  const { member } = useMemberContext();

  useEffect(() => {
    if (member) {
      setPurchaseInquiryInfo({
        name: member.name,
        phoneNumber: member.phoneNumber,
        productId: 0,
        loaded: '',
      });
    }
  }, [member]);

  const [purchaseInquiryInfo, setPurchaseInquiryInfo] = useState<PurchaseInquiryInfo>({
    productId: 0,
    name: '',
    phoneNumber: '',
    loaded: '',
  });

  const [popupInfo, setPopupInfo] = useState<ButtonPopupProps>({
    isShow: false,
    onClose: () => {},
    buttons: [],
  });

  const [basicPopupInfo, setBasicPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });

  const {
    data: interestProductsListInfos,
    error,
    isLoading,
    isFetching,
  } = useQuery<InterestProductsList[]>('interestProducts', () => getInterestProducts(), {
    refetchOnWindowFocus: false,
    onSuccess: () => {},
    onError: () => {},
  });

  const onClickInquiry = (truckName: string, productId: number, safetyNumber: string) => {
    setIsShowButtonPopup(true);

    if (isMobile) {
      setPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: truckName,
        buttons: getButtons(productId, safetyNumber),
      });
    } else {
      setPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: '차량 구매문의',
        buttons: [],
        children: <ProductInquiryQr isNotDirectProduct={false} productId={productId} />,
        minWidth: 520,
      });
    }
  };

  const handleClickCallInquiry = (productId: number, safetyNumber: string, loaded: EnumPresenter) => {
    setIsShowButtonPopup(true);

    const inquiryInfo = {
      name: purchaseInquiryInfo.name,
      phoneNumber: purchaseInquiryInfo.phoneNumber,
      productId,
      loaded: loaded.code || '',
    };

    setPurchaseInquiryInfo(inquiryInfo);

    if (isMobile) {
      if (safetyNumber) {
        phoneCall(safetyNumber);
      }
    } else {
      setPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: '차량 구매문의',
        buttons: [],
        children: (
          <ProductInquiryQr
            isNotDirectProduct
            productId={productId}
            purchaseInquiryInfo={inquiryInfo}
            updateInquiryInfo={updateInquiryInfo}
            handleClickButton={onClickProductPurchaseInquiry}
          />
        ),
        minWidth: 520,
      });
    }
  };

  const postProductPurchasingInquiryMutation = useMutation(
    (request: ProductPurchasingInquiryRequest) => postProductInquiry(request),
    {
      onSuccess: (response) => {
        if (response) {
          onCloseButtonPopup();
          setIsShowBasicPopup(true);
          setBasicPopupInfo({
            isShow: true,
            title: '구매문의 등록 완료',
            textContent: '담당자가 확인 후</br>연락 드릴 예정입니다.',
            textRightBtn: '확인',
            onClickRightBtn: () => {
              setIsShowBasicPopup(false);
            },
            textLeftBtn: '',
            onClickLeftBtn: () => {},
          });
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const updateInquiryInfo = (data: PurchaseInquiryInfo) => {
    inquiryFormData = data;
    setPurchaseInquiryInfo({ ...data });
  };

  const onClickProductPurchaseInquiry = () => {
    const request: ProductPurchasingInquiryRequest = {
      productId: inquiryFormData?.productId,
      name: inquiryFormData?.name || '',
      requestPhoneNumber: inquiryFormData?.phoneNumber || '',
      loaded: inquiryFormData?.loaded || '',
    };
    postProductPurchasingInquiryMutation.mutate(request);
  };

  const onCloseButtonPopup = () => {
    setIsShowButtonPopup(false);
  };

  const startPhoneCall = (productId: number, phoneNumber: string) => {
    if (!authRedirect()) {
      return;
    }

    if (productId) {
      postProductInquiryCallMutation.mutate(productId);
    }
    phoneCall(phoneNumber);
  };

  const startChatting = (productId: number) => {
    if (!authRedirect()) {
      return;
    }

    apiManager
      .post('/api/v1/chat-rooms', { productId })
      .then((response) => {
        if (response) {
          const responseData: ChattingDetailResponse = response.data;
          if (responseData.id) {
            navigate(`/chatting/room/${responseData.id}`);
          } else {
            navigate(`/chatting/room`, { state: { chatRoomData: responseData } });
          }
        }
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const getButtons = (productId: number, phoneNumber: string) => {
    if (!!phoneNumber) {
      return [
        {
          icon: <PhoneRingOutlineIcon color={colors.gray[6]}></PhoneRingOutlineIcon>,
          label: '전화문의',
          onClick: () => startPhoneCall(productId, phoneNumber),
        },
        {
          icon: <ChatOutlineIcon color={colors.gray[6]}></ChatOutlineIcon>,
          label: '채팅문의',
          onClick: () => startChatting(productId),
        },
      ];
    } else {
      return [
        {
          icon: <ChatOutlineIcon color={colors.gray[6]}></ChatOutlineIcon>,
          label: '채팅문의',
          onClick: () => startChatting(productId),
        },
      ];
    }
  };

  const onClickInterestProductRegister = (item: InterestProductsList) => {
    setIsShowBasicPopup(true);

    setBasicPopupInfo({
      isShow: true,
      title: '관심 차량 등록',
      textContent: '비슷한 차량이 입고되면 <br/>알림을 보내드릴까요?',
      textLeftBtn: '닫기',
      textRightBtn: '알림 받기',
      onClickLeftBtn: closeRegisterInterestProductPopup,
      onClickRightBtn: registerInterestProductNotificationSettings,
    });

    const interest: InterestProductNotificationSettingsRequest = {
      minYear: item.year,
      maxYear: item.year,
      minTons: item.tons,
      maxTons: item.tons,
      loaded: item.loaded?.code,
      minLoadedInnerLength: item.loadedInnerLength,
      maxLoadedInnerLength: item.loadedInnerLength,
      axis: item.axis?.code,
      manufacturerCategoriesId: item.manufacturerCategoriesId,
      minDistance: item.distance,
      maxDistance: item.distance,
      transmission: item.transmission?.code,
    };
    interestProduct.current = interest;
  };

  const fetchDetail = (id: number) => {
    navigate('/products/' + id, { state: { isPublicDetail: true } });
  };
  const deleteInterestProductMutation = useMutation((id: number) => deleteInterestProducts(id), {
    onMutate: async (id) => {
      setIsMutating(true);
      await queryClient.cancelQueries('interestProductsList');

      const previousUnlikeIds = unlikeIds;
      setUnlikeIds((prevIdList) => [...prevIdList, id]);
      return { previousUnlikeIds };
    },

    onError: (error, data, context) => {
      if (context?.previousUnlikeIds) {
        setUnlikeIds(context.previousUnlikeIds);
      }
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    onSettled: () => {
      queryClient.invalidateQueries('interestProductsList');
      setIsMutating(false);
    },
  });

  const deleteInterestProduct = (id: number) => {
    if (!isMutating) {
      deleteInterestProductMutation.mutate(id);
    }
  };

  const closeRegisterInterestProductPopup = () => {
    setIsShowBasicPopup(false);
    interestProduct.current = null;
  };

  const clickLikeButton = (item: InterestProductsList) => {
    if (unlikeIds.includes(item.id)) {
      createInterestProduct(item.productId, item.id);
    } else {
      deleteInterestProduct(item.id);
    }
  };

  const registerInterestProductNotificationSettings = () => {
    const requestData: InterestProductNotificationSettingsRequest = {
      axis: interestProduct.current?.axis,
      loaded: interestProduct.current?.loaded || '',
      maxLoadedInnerLength: interestProduct.current?.maxLoadedInnerLength,
      maxTons: interestProduct.current?.maxTons,
      minLoadedInnerLength: interestProduct.current?.minLoadedInnerLength,
      manufacturerCategoriesId: interestProduct.current?.manufacturerCategoriesId
        ? interestProduct.current?.manufacturerCategoriesId
        : undefined,
      minTons: interestProduct.current?.minTons,
      transmission: interestProduct.current?.transmission,
    };

    if (Number(requestData.minTons) === 1 && Number(requestData.maxTons === 27)) {
      delete requestData.minTons;
      delete requestData.maxTons;
    }
    postInterestPRoductNotificationSettingsMutation.mutate(requestData);
    setIsShowBasicPopup(false);
  };

  const postInterestPRoductNotificationSettingsMutation = useMutation(
    (request: InterestProductNotificationSettingsRequest) => postInterestProductNotificationSettings(request),
    {
      onSuccess: () => {
        showToast('차량 입고 알림이 등록되었어요.', 'success', 'bottom');
      },
      onError: (error: any) => {
        if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const postInterestProductMutation = useMutation(
    (request: { productId: number; id: number }) => postInterestProducts(request.productId),
    {
      onMutate: async (data) => {
        setIsMutating(true);
        await queryClient.cancelQueries('interestProductsList');

        const previousUnlikeIds = unlikeIds;
        setUnlikeIds((prevIdList) => prevIdList.filter((interestProductId) => interestProductId !== data.id));

        return { previousUnlikeIds };
      },

      onError: (error, data, context) => {
        if (context?.previousUnlikeIds) {
          setUnlikeIds(context.previousUnlikeIds);
        }
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      onSettled: () => {
        queryClient.invalidateQueries('interestProductsList');
        setIsMutating(false);
      },
    },
  );

  const createInterestProduct = (productId: number, id: number) => {
    if (!isMutating) {
      const request = { productId, id };
      postInterestProductMutation.mutate(request);
    }
  };

  const getProductType = (type: string) => {
    switch (type) {
      case 'NORMAL':
        return { code: type, desc: '직거래' };
      case SALES_TYPE_CONSIGNMENT:
        return { code: type, desc: '판매대행' };
      case SALES_TYPE_ASSURANCE:
        return { code: type, desc: '직트럭 상품용' };
    }
  };

  const postProductInquiryCallMutation = useMutation((productId: number) => postProductInquiryCall(productId), {
    onSuccess: () => {},
    onError: () => {},
  });

  const getButtonInfo = (item: InterestProductsList) => {
    if (item.status.code === SALE) {
      if (
        (item.salesType?.code === SALES_TYPE_ASSURANCE || item.salesType?.code === SALES_TYPE_CONSIGNMENT) &&
        !!item.salesPeople?.phoneNumber
      ) {
        return {
          name: '전화문의 하기',
          function: () => handleClickCallInquiry(item.productId, item.safetyNumber, item.loaded),
        };
      } else {
        return {
          name: '차량 구매문의',
          function: () => {
            onClickInquiry(item.truckName, item.productId, item.safetyNumber);
          },
        };
      }
    } else {
      return {
        name: '비슷한 차량 알림 받기',
        function: () => {
          onClickInterestProductRegister(item);
        },
      };
    }
  };

  return (
    <>
      <MenuHeader title="찜한 차량 목록"></MenuHeader>
      <div className="w-full px-4 pt-[60px] pb-8">
        {isLoading || isFetching ? (
          <>
            <InterestProductListSkeleton></InterestProductListSkeleton>
          </>
        ) : interestProductsListInfos?.length === 0 ? (
          <div className="flex flex-col justify-center items-center w-full min-h-[calc(100vh-60px)]">
            <HeartOutlineIcon color={colors.gray[4]} width={80} height={80}></HeartOutlineIcon>
            <span className="text-gray-7 text-lg mt-8">찜한 차량 내역이 없습니다.</span>
          </div>
        ) : (
          <section className="flex">
            <ul className="grid grid-cols-1 w-full">
              {interestProductsListInfos &&
                interestProductsListInfos?.map((item: InterestProductsList, index: React.Key | null | undefined) => (
                  <li key={index} className="mt-6 border border-gray-3 rounded-[10px]">
                    <div className="flex justify-between flex-1 px-4 pt-4" onClick={() => fetchDetail(item.productId)}>
                      <div className="flex w-[100px] h-[100px] rounded-lg">
                        <img
                          className="rounded-lg w-full h-full object-cover bg-cover"
                          src={item?.representImageUrl}
                          alt="트럭 사진"
                        />
                      </div>
                      <div className="relative flex-1 ml-3">
                        <div className="flex flex-wrap gap-[6px] mr-8">
                          <ProductStatus status={item.status}></ProductStatus>
                          <ProductType type={getProductType(item.salesType.code)}></ProductType>
                          <div
                            className="absolute right-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              clickLikeButton(item);
                            }}
                          >
                            <Checkbox
                              icon={<FavoriteBorder />}
                              checked={!unlikeIds.includes(item.id)}
                              sx={{
                                color: colors.gray[7],
                                '&.Mui-checked': {
                                  color: colors.red,
                                },
                                justifyContent: 'center',
                                padding: '0px',
                              }}
                              checkedIcon={<Favorite />}
                              size="medium"
                            />
                          </div>
                        </div>

                        <dd className="text-gray-8 text-base pt-[8px] font-medium">{item.truckName}</dd>
                        <dd className="flex items-center">
                          <span className="font-semibold text-gray-8 text-lg">{formatPrice(item.price)}</span>
                        </dd>
                      </div>
                    </div>
                    <div className="flex-justify items-center p-4">
                      <BasicButton
                        name={getButtonInfo(item).name}
                        bgColor={colors.gray[0]}
                        borderColor={colors.gray[3]}
                        textColor={colors.gray[6]}
                        fontSize={16}
                        height={52}
                        fontWeight="bold"
                        onClick={getButtonInfo(item).function}
                      ></BasicButton>
                    </div>
                  </li>
                ))}
            </ul>
          </section>
        )}
      </div>
      <ButtonPopup
        isShow={isShowButtonPopup}
        onClose={onCloseButtonPopup}
        title={popupInfo.title}
        buttons={popupInfo.buttons}
        children={popupInfo.children}
        minWidth={216}
      ></ButtonPopup>
      <BasicPopup
        isShow={isShowBasicPopup}
        title={basicPopupInfo.title}
        textContent={basicPopupInfo.textContent}
        textLeftBtn={basicPopupInfo.textLeftBtn}
        textRightBtn={basicPopupInfo.textRightBtn}
        onClickLeftBtn={basicPopupInfo.onClickLeftBtn}
        onClickRightBtn={basicPopupInfo.onClickRightBtn}
      ></BasicPopup>
    </>
  );
};

export default InterestProductsList;

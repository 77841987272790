export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;

export const KAKAO_AUTH_URL = BASE_URL + '/oauth2/authorization/kakao';
export const NAVER_AUTH_URL = BASE_URL + '/oauth2/authorization/naver';
export const KAKAO_REDIRECT_URL = BASE_URL + '/oauth2/code/kakao';

export const WEB_SOCKET_URL = BASE_URL + '/api/web-socket';
export const WOORI_WON_CAR_URL = 'https://wooriwoncar.com/landing/loanNewEvTrck?comcBusiChanDivCd=02';

export const PRODUCT_SALE_URL = '/sell-car';

import React from 'react';

const SaleContract = () => {
  return (
    <div className="min-w-[320px]">
      <div className="w-full flex justify-center items-center">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_sale_contract_cover.png"
          alt="판매 차량 계약 커버"
          className="w-full"
          loading="lazy"
        />
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">계약 준비 서류를 확인하세요</h3>
        <p className="pb-8 leading-8">
          직거래로 차량 계약 시 직트럭에서는 보관과 작성이 편리한 전자계약서를 무료로 제공하고 있어요.
          <br />
          전자계약서를 작성한 후 마이페이지를 통해서 PDF로(PC만 가능) 다운로드가 가능하고 카카오톡으로 공유도 가능해요.
        </p>
        <p className="pb-8 leading-8 text-sm">
          ※ 직트럭은 전자계약서 폼(양식)만 제공합니다. 당사자 계약 가격 및 내용 등에는 관여하지 않습니다.
        </p>
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_contract_image.png"
          alt="계약서 샘플"
          className="w-full"
          loading="lazy"
        />
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 pt-4 pb-28 px-4">
        <h3 className="font-semibold text-xl py-4">계약서 내용을 꼼꼼하게 확인하세요</h3>
        <ol className="leading-8">
          <li className="pb-8">
            1. 계약서 작성 시 사전 협의된 내용이 있다면 특약사항 란에 필수로 작성하시는 걸 권장해요.
            <p>
              예시)
              <br />
              <ul>
                <li>• 차량은 사고가 있거나 침수 차량이 아니어야 한다.</li>
                <li>• 차량 인도 후 약속된 기한 내로 명의 이전을 끝마쳐야 한다.</li>
                <li>• 차량의 저당 및 압류가 있을 시 거래에 문제가 되지 않도록 필수로 해지해야 한다.</li>
              </ul>
            </p>
          </li>

          <li className="pb-8">2. 차량의 자동차 등록증과 차량 표시 내용이 동일한지 확인하세요.</li>
          <li className="pb-8">3. 판매자와 구매자의 인적사항을 명확하게 기입했는지 확인하세요.</li>
        </ol>
      </div>
    </div>
  );
};
export default SaleContract;

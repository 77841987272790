import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DaumPostcode, { Address } from 'react-daum-postcode';
import { useQuery } from 'react-query';

import SimpleInput from '../Common/Input/SimpleInput';
import BottomSheetPopup from '../Common/Popup/BottomSheetPopup';
import AddressSearchForm from './AddressSearchForm';
import { getSearchHistory } from '@/api/public';
import { ChevronRightIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT } from '@/const/drive';
import { DRIVE_HISTORY_LOCATE } from '@/const/searchHistory';

const popupTitle: { [key: string]: string } = {
  transferStartLocation: '상차지 주소 검색',
  transferEndLocation: '하차지 주소 검색',
  transferTransitLocation: '경유지 주소 검색',
};

const inputPlaceholder: { [key: string]: string } = {
  transferStartLocation: '상차지 검색',
  transferEndLocation: '하차지 검색',
  transferTransitLocation: '경유지 검색',
};

interface TransferFormProps {
  driveForm: DriveHistoryForm;
  updateDriveForm: (updateData: DriveHistoryForm) => void;
}

const postCodeStyle = {
  width: '100%',
  height: 'calc(100vh - 112px)',
};

const TransferForm = ({ driveForm, updateDriveForm }: TransferFormProps) => {
  const [isShowAdressPopup, setIsShowAdressPopup] = useState(false);
  const [addressPopupTitle, setAddressPopupTitle] = useState('');
  const [addressInputPlaceholder, setAddressInputPlaceholder] = useState('');
  const [daumPostCodeType, setDaumPostCodeType] = useState('');
  const [transferForm, setTransferForm] = useState<DriveHistoryForm>({
    transferStartFullLocate: driveForm.transferStartFullLocate || '',
    transferEndFullLocate: driveForm.transferEndFullLocate || '',
    transferTransitLocateData:
      driveForm.transferTransitLocateData && driveForm.transferTransitLocateData?.length !== 0
        ? [...driveForm.transferTransitLocateData, { transferTransitFullLocate: '', transferTransitShortLocate: '' }]
        : [{ transferTransitFullLocate: '', transferTransitShortLocate: '' }],
  });

  const [transitLocationIndex, setTransitLocationIndex] = useState(0);
  const transferStartLocationRef = useRef<HTMLInputElement>(null);
  const transferEndLocationRef = useRef<HTMLInputElement>(null);
  const transferTransitLocationRef = useRef<HTMLInputElement>(null);
  const fakeInputRef = useRef<HTMLInputElement | null>(null);
  const isShowAddressSearchForm = useRef<boolean>(false);

  const onClickInputAdress = (name: string, index?: number) => {
    setIsShowAdressPopup(true);
    setAddressPopupTitle(popupTitle[name]);
    setAddressInputPlaceholder(inputPlaceholder[name]);
    setDaumPostCodeType(name);
    if (index !== null && index !== undefined) {
      setTransitLocationIndex(index);
    }
  };

  const onClickDeleteTransit = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();

    if (index === 0) {
      const newItem = { transferTransitFullLocate: '', transferTransitShortLocate: '' };

      setTransferForm((prev) => {
        const updatedTransitLocateData = prev?.transferTransitLocateData?.map((item, i) => {
          if (index === i) {
            return newItem;
          }
          return item;
        });

        return {
          ...prev,
          transferTransitLocateData: updatedTransitLocateData,
        };
      });
    } else {
      const updatedTransitLocations = transferForm?.transferTransitLocateData?.filter((item, i) => i !== index);
      setTransferForm({ ...transferForm, transferTransitLocateData: updatedTransitLocations });
    }
  };

  const onClosePopup = () => {
    setIsShowAdressPopup(false);
  };

  const onCompletePost = (data: Address) => {
    const sido = data.sido.includes('특별자치도') ? data.sido.replace('특별자치도', '') : data.sido;
    const shortLocate = `${sido} ${data.sigungu}`;
    updateTransferForm(data.address, shortLocate);
  };

  const updateTransferForm = (fullLocate: string, shortLocate: string) => {
    if (daumPostCodeType === 'transferTransitLocation') {
      const adress = {
        transferTransitShortLocate: shortLocate,
        transferTransitFullLocate: fullLocate,
      };

      const newItem = { transferTransitFullLocate: '', transferTransitShortLocate: '' };

      setTransferForm((prev) => {
        const updatedTransitLocateData = prev?.transferTransitLocateData?.map((item, index) => {
          if (index === transitLocationIndex) {
            return adress;
          }
          return item;
        });

        const updatedTransferTransitLocateData =
          updatedTransitLocateData && updatedTransitLocateData?.length < 3
            ? updatedTransitLocateData
              ? [...updatedTransitLocateData, newItem]
              : [newItem]
            : updatedTransitLocateData;

        return {
          ...prev,
          transferTransitLocateData: updatedTransferTransitLocateData,
        };
      });
    } else if (daumPostCodeType === 'transferStartLocation') {
      setTransferForm((prev) => ({
        ...prev,
        transferStartFullLocate: fullLocate,
        transferStartShortLocate: shortLocate,
      }));
    } else if (daumPostCodeType === 'transferEndLocation') {
      setTransferForm((prev) => ({
        ...prev,
        transferEndFullLocate: fullLocate,
        transferEndShortLocate: shortLocate,
      }));
    }
    setIsShowAdressPopup(false);
    scrollIntoView();
  };

  useEffect(() => {
    updateDriveForm(transferForm);
  }, [transferForm]);

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'transferStartLocation') {
        transferStartLocationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'transferEndLocation') {
        transferEndLocationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'transferTransitLocation') {
        transferTransitLocationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const getSearchHistories = async () => {
    try {
      const params = new URLSearchParams({
        type: DRIVE_HISTORY_LOCATE,
        page: '1',
        size: '8',
      });
      const response = await getSearchHistory(params);
      const data: SearchHistory[] = response.data.data;
      return data;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data } = useQuery(['get-search-history-address'], getSearchHistories, {
    onSuccess: () => {},
    onError: () => {},
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      isShowAddressSearchForm.current = true;
    } else {
      isShowAddressSearchForm.current = false;
    }
  }, [data]);

  return (
    <>
      <dl className="flex items-center justify-center py-5 border-b border-gray-3">
        <dt className="min-w-[100px] text-gray-7">
          상차지<span className="font-normal text-red">(필수)</span>
        </dt>
        <dd
          className="flex items-center justify-between flex-1 px-3 text-gray-8"
          onClick={() => onClickInputAdress('transferStartLocation')}
        >
          <SimpleInput
            ref={transferStartLocationRef}
            placeholder="상차지 검색"
            name="transferStartLocation"
            value={transferForm.transferStartFullLocate || ''}
            readOnly
          ></SimpleInput>
          <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
        </dd>
      </dl>
      {driveForm.driveHistoryType !== DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT && (
        <>
          {transferForm?.transferTransitLocateData?.map((item, index) => (
            <dl className="flex items-center justify-center py-5 border-b border-gray-3" key={index}>
              <dt className="min-w-[100px] text-gray-7">
                경유지
                {transferForm.transferTransitLocateData &&
                  transferForm.transferTransitLocateData?.length > 1 &&
                  index + 1}
              </dt>
              <dd
                className="flex items-center justify-between flex-1 px-3 text-gray-8"
                onClick={(e) => onClickInputAdress('transferTransitLocation', index)}
              >
                <SimpleInput
                  key={index}
                  placeholder="경유지 검색"
                  name="transferTransitLocation"
                  value={item.transferTransitFullLocate || ''}
                  readOnly
                ></SimpleInput>

                {item.transferTransitFullLocate ? (
                  <div className={`cursor-pointer`} onClick={(e) => onClickDeleteTransit(e, index)}>
                    <XMarkIcon color={colors.gray[7]}></XMarkIcon>
                  </div>
                ) : (
                  <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
                )}
              </dd>
            </dl>
          ))}
        </>
      )}

      <dl className="flex items-center justify-center py-5">
        <dt className="min-w-[100px] text-gray-7">
          하차지<span className="font-normal text-red">(필수)</span>
        </dt>
        <dd
          className="flex items-center justify-between flex-1 px-3 text-gray-8"
          onClick={() => onClickInputAdress('transferEndLocation')}
        >
          <SimpleInput
            placeholder="하차지 검색"
            name="transferEndLocation"
            value={transferForm.transferEndFullLocate || ''}
            readOnly
          ></SimpleInput>
          <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
        </dd>
      </dl>

      {isShowAdressPopup && (
        <BottomSheetPopup
          isOpen={isShowAdressPopup}
          onClose={onClosePopup}
          title={addressPopupTitle}
          isFullHeight
          isBackdrop={false}
        >
          {isShowAddressSearchForm.current ? (
            <AddressSearchForm
              placeholder={addressInputPlaceholder}
              addressSearachHistories={data}
              addressPopupTitle={addressPopupTitle}
              onCompletePost={onCompletePost}
              updateTransferForm={updateTransferForm}
            ></AddressSearchForm>
          ) : (
            <>
              <input
                ref={fakeInputRef}
                style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
                onFocus={() => {
                  if (fakeInputRef?.current) {
                    fakeInputRef.current.blur();
                  }
                }}
                autoFocus
              />
              <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} submitMode={false}></DaumPostcode>
            </>
          )}
        </BottomSheetPopup>
      )}
    </>
  );
};

export default TransferForm;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.license-plate-input::-moz-placeholder {
  color: #c5b100;
}
.license-plate-input::placeholder {
  color: #c5b100;
}
`, "",{"version":3,"sources":["webpack://./src/css/licensePlateInputStyle.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AAFA;EACE,cAAc;AAChB","sourcesContent":[".license-plate-input::placeholder {\n  color: #c5b100;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { SVGProps } from 'react';

const SvgEllipsisHorizontalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M4.5 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgEllipsisHorizontalIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgEllipsisHorizontalIcon;

import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import {
  deleteInterestProducts,
  getInterestProducts,
  postInterestProductNotificationSettings,
  postInterestProducts,
  postProductInquiry,
  postProductInquiryCall,
} from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import AlertModal from '@/components/Common/Modal/AlertModal';
import BaseModal from '@/components/Common/Modal/BaseModal';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import ProductInquiryQr from '@/components/Common/Popup/Contents/ProductInquiryQr';
import InterestProductListSkeleton from '@/components/Common/Skeleton/InterestProductListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChatOutlineIcon, HeartOutlineIcon, PhoneRingOutlineIcon } from '@/components/Icon';
import ProductStatus from '@/components/More/ProductStatus';
import ProductType from '@/components/More/ProductType';
import { colors } from '@/const/colors';
import { ACTUAL_REPRESENTATIVE_PHONE_NUMBER } from '@/const/common';
import { ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS, ALREADY_PRODUCT_INQUIRY_REQUESTED } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { SALE } from '@/const/productStatus';
import { SALES_TYPE_ASSURANCE, SALES_TYPE_CONSIGNMENT } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { getProductTypeBySalesType } from '@/utils/Products/productUtils';
import { formatPrice, phoneCall } from '@/utils/common';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Checkbox } from '@mui/material';

interface InterestProductsList {
  id: number;
  carNumber: string;
  truckName: string;
  productId: number;
  representImageUrl: string;
  year: string;
  distance: string;
  accident: boolean;
  price: string;
  createdDate: string;
  title: string;
  status: EnumPresenter;
  salesType: EnumPresenter;
  salesPeople: SalesPeople;
  safetyNumber: string;
  tons: number;
  loaded: EnumPresenter;
  loadedInnerLength: number;
  axis: EnumPresenter;
  manufacturerCategoriesId: number;
  transmission: EnumPresenter;
  isNotificationEnabled: boolean;
}

interface PurchaseInquiryInfo {
  productId: number;
  name: string;
  phoneNumber: string;
  loaded: string;
}

const InterestProductsList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();
  const [unlikeIds, setUnlikeIds] = useState<number[]>([]);
  const [isDisabledNotificationIds, setIsDisabledNotificationIds] = useState<(number | undefined)[]>([]);

  const [isMutating, setIsMutating] = useState(false);
  const interestProduct = useRef<InterestProductNotificationSettingsRequest | null>(null);
  const inquiryForm = useRef<PurchaseInquiryInfo>();
  let inquiryFormData = inquiryForm.current;
  const authRedirect = useAuthRedirect();
  const { member } = useMemberContext();

  const [onSaleInterestProducts, setOnInterestProducts] = useState<InterestProductsList[]>([]);
  const [notOnSaleInterestProducts, setNotOnSaleInterestProducts] = useState<InterestProductsList[]>([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    content: null,
    onClose: () => {},
  });

  const [confirmModalConfig, setConfirmModalConfig] = useState<ModalConfig>({
    onRightButtonClick: () => {},
  });

  useEffect(() => {
    if (member) {
      setPurchaseInquiryInfo({
        name: member.name,
        phoneNumber: member.phoneNumber,
        productId: 0,
        loaded: '',
      });
    }
  }, [member]);

  const [purchaseInquiryInfo, setPurchaseInquiryInfo] = useState<PurchaseInquiryInfo>({
    productId: 0,
    name: '',
    phoneNumber: '',
    loaded: '',
  });

  const { data, error, isLoading, isFetching } = useQuery<InterestProductsList[]>(
    'interestProducts',
    () => getInterestProducts(),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: () => {},
    },
  );

  useEffect(() => {
    const onSale = data?.filter((item) => item.status?.code === SALE);
    setOnInterestProducts(onSale || []);

    const notOnSale = data?.filter((item) => item.status?.code !== SALE);
    setNotOnSaleInterestProducts(notOnSale || []);
  }, [data]);

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const onClickInquiry = (truckName: string, productId: number, safetyNumber: string) => {
    setIsOpenModal(true);
    if (isMobile) {
      setModalConfig({
        title: `<span class="text-[18px] sm280:text-[16px] break-keep leading-[20px] sm280:leading-[18px] line-clamp-2">${truckName}</span>`,
        content: renderButton(productId, safetyNumber),
        onClose: onCloseModal,
      });
    } else {
      setModalConfig({
        title: '차량 구매문의',
        content: <ProductInquiryQr isNotDirectProduct={false} productId={productId} />,
        onClose: onCloseModal,
      });
    }
  };

  const handleClickCallInquiry = (productId: number, safetyNumber: string, loaded: EnumPresenter) => {
    const inquiryInfo = {
      name: purchaseInquiryInfo.name,
      phoneNumber: purchaseInquiryInfo.phoneNumber,
      productId,
      loaded: loaded.code || '',
    };

    setPurchaseInquiryInfo(inquiryInfo);

    if (isMobile) {
      phoneCall(safetyNumber || ACTUAL_REPRESENTATIVE_PHONE_NUMBER);
    } else {
      setIsOpenModal(true);
      setModalConfig({
        title: '차량 구매문의',
        content: (
          <ProductInquiryQr
            isNotDirectProduct
            productId={productId}
            purchaseInquiryInfo={inquiryInfo}
            updateInquiryInfo={updateInquiryInfo}
            handleClickButton={onClickProductPurchaseInquiry}
          />
        ),
        onClose: onCloseModal,
      });
    }
  };

  const postProductPurchasingInquiryMutation = useMutation(
    (request: ProductPurchasingInquiryRequest) => postProductInquiry(request),
    {
      onSuccess: (response) => {
        if (response) {
          onCloseModal();
          setIsOpenAlertModal(true);
        }
      },
      onError: (error: AxiosError) => {
        if (error.code === ALREADY_PRODUCT_INQUIRY_REQUESTED) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const updateInquiryInfo = (data: PurchaseInquiryInfo) => {
    inquiryFormData = data;
    setPurchaseInquiryInfo({ ...data });
  };

  const onClickProductPurchaseInquiry = () => {
    const request: ProductPurchasingInquiryRequest = {
      productId: inquiryFormData?.productId,
      name: inquiryFormData?.name || '',
      requestPhoneNumber: inquiryFormData?.phoneNumber || '',
      loaded: inquiryFormData?.loaded || '',
    };
    postProductPurchasingInquiryMutation.mutate(request);
  };

  const startPhoneCall = (productId: number, phoneNumber: string) => {
    if (!authRedirect()) {
      return;
    }

    if (productId) {
      postProductInquiryCallMutation.mutate(productId);
    }
    phoneCall(phoneNumber);
  };

  const startChatting = (productId: number) => {
    if (!authRedirect()) {
      return;
    }

    apiManager
      .post('/api/v1/chat-rooms', { productId })
      .then((response) => {
        if (response) {
          const responseData: ChattingDetailResponse = response.data;
          if (responseData.id) {
            navigate(`/chatting/room/${responseData.id}`);
          } else {
            navigate(`/chatting/room`, { state: { chatRoomData: responseData } });
          }
        }
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  };

  const renderButton = (productId: number, phoneNumber: string) => {
    return (
      <div className="flex flex-col gap-3">
        <BasicButton
          icon={<PhoneRingOutlineIcon color={colors.gray[6]} />}
          name="전화문의"
          description="* 판매자의 개인정보 보호를 위해<br/>050 안심번호로 연결됩니다."
          descriptionColor={colors.gray[7]}
          bgColor={colors.gray[0]}
          borderColor={colors.gray[4]}
          textColor={colors.gray[8]}
          fontSize={18}
          height={94}
          fontWeight="medium"
          onClick={() => startPhoneCall(productId, phoneNumber)}
        ></BasicButton>
        <BasicButton
          icon={<ChatOutlineIcon color={colors.gray[6]} />}
          name="채팅문의"
          bgColor={colors.gray[0]}
          borderColor={colors.gray[4]}
          textColor={colors.gray[8]}
          fontSize={18}
          height={52}
          fontWeight="medium"
          onClick={() => startChatting(productId)}
        ></BasicButton>
      </div>
    );
  };

  const getButtons = (productId: number, phoneNumber: string) => {
    if (!!phoneNumber) {
      return [
        {
          icon: <PhoneRingOutlineIcon color={colors.gray[6]}></PhoneRingOutlineIcon>,
          label: '전화문의',
          onClick: () => startPhoneCall(productId, phoneNumber),
        },
        {
          icon: <ChatOutlineIcon color={colors.gray[6]}></ChatOutlineIcon>,
          label: '채팅문의',
          onClick: () => startChatting(productId),
        },
      ];
    } else {
      return [
        {
          icon: <ChatOutlineIcon color={colors.gray[6]}></ChatOutlineIcon>,
          label: '채팅문의',
          onClick: () => startChatting(productId),
        },
      ];
    }
  };

  const onClickInterestProductRegister = (item: InterestProductsList) => {
    setIsOpenConfirmModal(true);
    setConfirmModalConfig({
      onRightButtonClick: () => registerInterestProductNotificationSettings(item.id),
    });

    const interest: InterestProductNotificationSettingsRequest = {
      minYear: item.year,
      maxYear: item.year,
      minTons: item.tons,
      maxTons: item.tons,
      loaded: item.loaded?.code,
      minLoadedInnerLength: item.loadedInnerLength,
      maxLoadedInnerLength: item.loadedInnerLength,
      axis: item.axis?.code,
      manufacturerCategoriesId: item.manufacturerCategoriesId,
      minDistance: item.distance,
      maxDistance: item.distance,
      transmission: item.transmission?.code,
    };
    interestProduct.current = interest;
  };

  const fetchDetail = (id: number) => {
    navigate('/products/' + id, { state: { isPublicDetail: true } });
  };
  const deleteInterestProductMutation = useMutation((id: number) => deleteInterestProducts(id), {
    onMutate: async (id) => {
      setIsMutating(true);
      await queryClient.cancelQueries('interestProductsList');

      const previousUnlikeIds = unlikeIds;
      setUnlikeIds((prevIdList) => [...prevIdList, id]);
      return { previousUnlikeIds };
    },

    onError: (error, data, context) => {
      if (context?.previousUnlikeIds) {
        setUnlikeIds(context.previousUnlikeIds);
      }
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    onSettled: () => {
      queryClient.invalidateQueries('interestProductsList');
      setIsMutating(false);
    },
  });

  const deleteInterestProduct = (id: number) => {
    if (!isMutating) {
      deleteInterestProductMutation.mutate(id);
    }
  };

  const closeRegisterInterestProductPopup = () => {
    setIsOpenConfirmModal(false);
    interestProduct.current = null;
  };

  const clickLikeButton = (item: InterestProductsList) => {
    if (unlikeIds.includes(item.id)) {
      createInterestProduct(item.productId, item.id);
    } else {
      deleteInterestProduct(item.id);
    }
  };

  const registerInterestProductNotificationSettings = (id: number) => {
    const requestData: InterestProductNotificationSettingsRequest = {
      axis: interestProduct.current?.axis,
      loaded: interestProduct.current?.loaded || '',
      maxLoadedInnerLength: interestProduct.current?.maxLoadedInnerLength,
      maxTons: interestProduct.current?.maxTons,
      minLoadedInnerLength: interestProduct.current?.minLoadedInnerLength,
      manufacturerCategoriesId: interestProduct.current?.manufacturerCategoriesId
        ? interestProduct.current?.manufacturerCategoriesId
        : undefined,
      minTons: interestProduct.current?.minTons,
      transmission: interestProduct.current?.transmission,
      id: id,
    };

    if (Number(requestData.minTons) === 1 && Number(requestData.maxTons === 27)) {
      delete requestData.minTons;
      delete requestData.maxTons;
    }
    postInterestProductNotificationSettingsMutation.mutate(requestData);
    setIsOpenConfirmModal(false);
  };

  const postInterestProductNotificationSettingsMutation = useMutation(
    (request: InterestProductNotificationSettingsRequest) => postInterestProductNotificationSettings(request),
    {
      onMutate: async (item) => {
        await queryClient.cancelQueries('interestProductsList');
        const previousIds = isDisabledNotificationIds;
        setIsDisabledNotificationIds((prevIdList) => [...prevIdList, item.id]);
        return { previousIds };
      },
      onSuccess: () => {
        showToast('관심차량이 등록되었어요.', 'success', 'bottom');
      },
      onError: (error: any, data, context) => {
        if (context?.previousIds) {
          setIsDisabledNotificationIds(context.previousIds);
        }
        if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('interestProductsList');
      },
    },
  );

  const postInterestProductMutation = useMutation(
    (request: { productId: number; id: number }) => postInterestProducts(request.productId),
    {
      onMutate: async (data) => {
        setIsMutating(true);
        await queryClient.cancelQueries('interestProductsList');

        const previousUnlikeIds = unlikeIds;
        setUnlikeIds((prevIdList) => prevIdList.filter((interestProductId) => interestProductId !== data.id));

        return { previousUnlikeIds };
      },

      onError: (error, data, context) => {
        if (context?.previousUnlikeIds) {
          setUnlikeIds(context.previousUnlikeIds);
        }
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      onSettled: () => {
        queryClient.invalidateQueries('interestProductsList');
        setIsMutating(false);
      },
    },
  );

  const createInterestProduct = (productId: number, id: number) => {
    if (!isMutating) {
      const request = { productId, id };
      postInterestProductMutation.mutate(request);
    }
  };

  const postProductInquiryCallMutation = useMutation((productId: number) => postProductInquiryCall(productId), {
    onSuccess: () => {},
    onError: () => {},
  });

  const getButtonInfo = (item: InterestProductsList) => {
    if (item.status.code === SALE) {
      if (
        (item.salesType?.code === SALES_TYPE_ASSURANCE || item.salesType?.code === SALES_TYPE_CONSIGNMENT) &&
        !!item.salesPeople?.phoneNumber
      ) {
        return {
          name: '전화문의 하기',
          function: () => handleClickCallInquiry(item.productId, item.safetyNumber, item.loaded),
        };
      } else {
        return {
          name: '차량 구매문의',
          function: () => {
            onClickInquiry(item.truckName, item.productId, item.safetyNumber);
          },
        };
      }
    } else {
      return {
        name: item.isNotificationEnabled ? '비슷한 차량 알림 받기' : '알림 받기 완료',
        function: () => {
          onClickInterestProductRegister(item);
        },
        isDisabled: !item.isNotificationEnabled,
      };
    }
  };

  return (
    <>
      <MenuHeader title="찜한 차량 목록"></MenuHeader>
      <div className="w-full px-4 pt-[60px] pb-8">
        {isLoading || isFetching ? (
          <>
            <InterestProductListSkeleton></InterestProductListSkeleton>
          </>
        ) : data?.length === 0 ? (
          <div className="flex flex-col justify-center items-center w-full min-h-[calc(100vh-60px)]">
            <HeartOutlineIcon color={colors.gray[4]} width={80} height={80}></HeartOutlineIcon>
            <span className="mt-8 text-lg text-gray-7">찜한 차량 내역이 없습니다.</span>
          </div>
        ) : (
          <section className="flex">
            <ul className="grid w-full grid-cols-1">
              {onSaleInterestProducts &&
                onSaleInterestProducts?.map((item: InterestProductsList, index: React.Key | null | undefined) => (
                  <li key={index} className="mt-3 border border-gray-3 rounded-[10px]">
                    <div
                      className="flex justify-between flex-1 px-4 pt-4 cursor-pointer"
                      onClick={() => fetchDetail(item.productId)}
                    >
                      <div className="flex w-[100px] h-[100px] rounded-lg">
                        <img
                          className="object-cover w-full h-full bg-cover rounded-lg"
                          src={item?.representImageUrl}
                          alt="트럭 사진"
                        />
                      </div>
                      <div className="relative flex-1 ml-3">
                        <div className="flex flex-wrap gap-[6px] mr-8">
                          <ProductStatus status={item.status}></ProductStatus>
                          <ProductType type={getProductTypeBySalesType(item.salesType.code)}></ProductType>
                          <div
                            className="absolute right-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              clickLikeButton(item);
                            }}
                          >
                            <Checkbox
                              icon={<FavoriteBorder />}
                              checked={!unlikeIds.includes(item.id)}
                              sx={{
                                color: colors.gray[7],
                                '&.Mui-checked': {
                                  color: colors.red,
                                },
                                justifyContent: 'center',
                                padding: '0px',
                              }}
                              checkedIcon={<Favorite />}
                              size="medium"
                            />
                          </div>
                        </div>

                        <dd className="text-gray-8 text-base pt-[8px] font-medium">{item.truckName}</dd>
                        <dd className="flex items-center">
                          <span className="text-lg font-semibold text-gray-8">{formatPrice(item.price)}</span>
                        </dd>
                      </div>
                    </div>
                    <div className="items-center p-4 flex-justify">
                      <BasicButton
                        name={getButtonInfo(item).name}
                        bgColor={colors.gray[0]}
                        borderColor={colors.gray[3]}
                        textColor={colors.gray[6]}
                        fontSize={16}
                        height={48}
                        fontWeight="bold"
                        onClick={getButtonInfo(item).function}
                      />
                    </div>
                  </li>
                ))}

              {notOnSaleInterestProducts?.length > 0 && (
                <div className="w-full h-[17px] flex justify-center items-center my-6">
                  <div className="border-b border-gray-4 w-full h-[6px] flex-1"></div>
                  <span className="w-[50px] text-[14px] text-gray-6 mx-[10px]">판매종료</span>
                  <div className="border-b border-gray-4 w-full h-[6px] flex-1"></div>
                </div>
              )}
              {notOnSaleInterestProducts?.map((item: InterestProductsList, index: React.Key | null | undefined) => (
                <li key={index} className="mt-3 border border-gray-3 rounded-[10px]">
                  <div className="flex justify-between flex-1 px-4 pt-4">
                    <div className="flex w-[100px] h-[100px] rounded-lg">
                      <img
                        className="object-cover w-full h-full bg-cover rounded-lg"
                        src={item?.representImageUrl}
                        alt="트럭 사진"
                      />
                    </div>
                    <div className="relative flex-1 ml-3">
                      <div className="flex flex-wrap gap-[6px] mr-8">
                        <ProductStatus status={item.status}></ProductStatus>
                        <div
                          className="absolute right-2"
                          onClick={(event) => {
                            event.stopPropagation();
                            clickLikeButton(item);
                          }}
                        >
                          <Checkbox
                            icon={<FavoriteBorder />}
                            checked={!unlikeIds.includes(item.id)}
                            sx={{
                              color: colors.gray[7],
                              '&.Mui-checked': {
                                color: colors.red,
                              },
                              justifyContent: 'center',
                              padding: '0px',
                            }}
                            checkedIcon={<Favorite />}
                            size="medium"
                          />
                        </div>
                      </div>

                      <dd className="text-gray-8 text-base pt-[8px] font-medium">{item.truckName}</dd>
                      <dd className="flex items-center">
                        <span className="text-lg font-semibold text-gray-8">{formatPrice(item.price)}</span>
                      </dd>
                    </div>
                  </div>
                  <div className="items-center p-4 flex-justify">
                    <BasicButton
                      name={isDisabledNotificationIds.includes(item.id) ? '알림 받기 완료' : getButtonInfo(item).name}
                      bgColor={colors.gray[0]}
                      borderColor={colors.gray[3]}
                      textColor={colors.gray[6]}
                      fontSize={16}
                      height={48}
                      fontWeight="bold"
                      onClick={getButtonInfo(item).function}
                      isDisabled={getButtonInfo(item).isDisabled || isDisabledNotificationIds.includes(item.id)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </section>
        )}
      </div>
      <BaseModal
        isOpen={isOpenModal}
        onClose={modalConfig.onClose}
        title={modalConfig.title}
        content={modalConfig.content}
        isXMarkVisible
        paddingType="padding-top-20"
      />
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        title="관심 차량 등록"
        content={
          <>
            비슷한 차량이 입고되면
            <br />
            알림을 보내드릴까요?
          </>
        }
        leftButtonLabel="닫기"
        rightButtonLabel="알림 받기"
        onLeftButtonClick={() => {
          closeRegisterInterestProductPopup();
        }}
        onRightButtonClick={confirmModalConfig.onRightButtonClick}
      />
      <AlertModal
        isOpen={isOpenAlertModal}
        onClose={() => setIsOpenAlertModal(false)}
        content={
          <span>
            담당자가 확인 후 <br />
            연락 드릴 예정입니다.
          </span>
        }
      />
    </>
  );
};

export default InterestProductsList;

import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { patchProductsStatus } from '@/api/public';
import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import ChangeHistory from '@/components/Products/Sale/DetailTab/ChangeHistory';
import SeizureMortgageHistory from '@/components/Products/Sale/DetailTab/SeizureMortgageHistory';
import TradingHistory from '@/components/Products/Sale/DetailTab/TradingHistory';
import VehicleInspectionHistory from '@/components/Products/Sale/DetailTab/VehicleInspectionHistory';
import HistoryCountView from '@/components/Products/Sale/HistoryCountView';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { SALESTYPE } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import DefaultHelmet from '@/metadatas/DefaultHelmet';
import { productsFormAtom } from '@/store/products';
import { asYYYYMMDD, formatShortYear, getFormatDate } from '@/utils/common';

type SalesTypeKey = keyof typeof SALESTYPE;

const ProductsOriginDataInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabNum, setTabNum] = useState(0);
  const { id } = useParams();
  const { showToast } = useToastContext();
  const productOriginData = location.state.productOriginData;
  const salesType = location.state.type;
  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const labelList: TabType[] = [
    { icon: null, title: '압류·저당' },
    { icon: null, title: '소유자변경' },
    { icon: null, title: '검사이력' },
    { icon: null, title: '구조변경' },
  ];
  const buttonList: Button[] = [
    {
      title: '필수 정보 입력하기',
      isDisabled: false,
      onClick: () => onClickMovePage(),
      description: '차량 시세를 확인하기 위한 정보를 입력해주세요.',
    },
  ];

  const goPrev = () => {
    navigate('/sell-car');
  };

  const patchProductsStatusMutation = useMutation(
    (request: { productId: number; status: string }) => patchProductsStatus(request),
    {
      onSuccess: (response) => {
        setProductFormData(response.data);
        navigate(`/products/sales/model/${id}`, {
          state: { productOriginData: productOriginData },
          replace: true,
        });
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/model/${id}`,
  });

  const onClickMovePage = () => {
    updateProductNextStepMutation.mutate({ id: Number(productFormData?.id), type: salesType, status: 'BEFORE_SALE' });
  };

  useEffect(() => {
    if (productFormData.id === 0) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          if (responseData.manufacturerCategories.id === 10) {
            setProductFormData({ ...responseData, model: { id: 45, name: '기타(쌍용 외)' } });
          } else {
            setProductFormData(responseData);
          }
        })
        .catch(() => {});
    }
  }, [productFormData]);

  return (
    <>
      <DefaultHelmet page="sale" />

      <div className="flex flex-col justify-between w-full">
        <MenuHeader
          title={
            SALESTYPE[location.state?.type as SalesTypeKey] || SALESTYPE[productOriginData?.type.code as SalesTypeKey]
          }
          onClickPrevBtn={goPrev}
        ></MenuHeader>
        <div>
          <div className="pb-24 pt-[60px]">
            <h2 className="px-4 text-2xl font-bold text-gray-8">{productOriginData?.ownerName}님의 차량정보</h2>
            <div className="px-4 pb-5">
              <div className="inline-block w-auto p-3 my-6 border-2 border-black rounded-lg bg-yellow">
                <div className="flex items-center justify-center text-3xl font-semibold">
                  {productOriginData?.truckNumber}
                </div>
              </div>
              <div className="table w-full p-4 rounded-lg bg-gray-1">
                <h4 className="mb-4 text-xl font-semibold text-gray-8">차량 기본 정보</h4>
                <dl className="text-lg">
                  {productOriginData?.truckName && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        차량명
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">{productOriginData.truckName}</dd>
                    </div>
                  )}
                  {productOriginData?.carType && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        차종
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">{productOriginData.carType}</dd>
                    </div>
                  )}
                  {productOriginData?.manufacturerCategories && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        제조사
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">
                        {productOriginData.manufacturerCategories?.name}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.year && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        형식
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">
                        {formatShortYear(productOriginData.year)}년형
                      </dd>
                    </div>
                  )}
                  {productOriginData?.firstRegistrationDate && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        연식
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">
                        {getFormatDate(productOriginData.firstRegistrationDate, ['year', 'month'], true)}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.color && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        색상
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">{productOriginData.color.desc}</dd>
                    </div>
                  )}
                  {productOriginData?.inspectionInvalidStartDate && productOriginData?.inspectionInvalidEndDate && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px] break-keep">
                        검사
                        <br className="hidden sm280:block" /> 유효기간
                      </dt>
                      <dd className="font-semibold text-gray-8 break-keep">
                        {asYYYYMMDD(productOriginData?.inspectionInvalidStartDate)}
                        <br className="hidden sm280:block" />~{asYYYYMMDD(productOriginData?.inspectionInvalidEndDate)}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.identificationNumber && (
                    <div className="flex">
                      <dt className="text-gray-7 pb-3 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        차대번호
                      </dt>
                      <dd className="flex-1 font-semibold break-all text-gray-8">
                        {productOriginData?.identificationNumber}
                      </dd>
                    </div>
                  )}
                  {productOriginData?.carUse && (
                    <div className="flex">
                      <dt className="text-gray-7 sm280:min-w-[82px] sm360:min-w-[120px] sm680:min-w-[120px]">
                        차량용도
                      </dt>
                      <dd className="flex-1 font-semibold text-gray-8">{productOriginData?.carUse}</dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
            {productOriginData?.lastOwnerInfo && productOriginData?.lastOwnerInfo.date && (
              <section className="px-4 pt-2 pb-4 my-2 text-gray-8">
                <h3 className="pb-3 text-lg font-semibold">소유자 정보 (현출물자 이력)</h3>
                <dl className="p-4 text-base rounded-lg bg-gray-1">
                  <dt className="mb-1 font-semibold">{productOriginData?.lastOwnerInfo.date}</dt>
                  <dd>{productOriginData?.lastOwnerInfo.content}</dd>
                </dl>
              </section>
            )}
            <HistoryCountView
              productData={productOriginData}
              onChange={setTabNum}
              tabMapping={{ SEIZURE: 0, MORTGAGE: 0, TRADING: 1, TUNING: 3 }}
            ></HistoryCountView>
            <div className="w-full h-2 bg-gray-1"></div>

            <BasicTabs labelList={labelList} value={tabNum} onChange={setTabNum}>
              <SeizureMortgageHistory productOriginData={productOriginData}></SeizureMortgageHistory>
              <TradingHistory productOriginData={productOriginData}></TradingHistory>
              <VehicleInspectionHistory productOriginData={productOriginData}></VehicleInspectionHistory>
              <ChangeHistory productOriginData={productOriginData}></ChangeHistory>
            </BasicTabs>
            <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-gray-0">
              <ButtonFooter buttons={buttonList}></ButtonFooter>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsOriginDataInfo;

import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import SalesTypeSelectButton from '../Common/Button/SalesTypeSelectButton';
import ConfirmModal from '../Common/Modal/ConfirmModal';
import BottomSheetPopup from '../Common/Popup/BottomSheetPopup';
import { patchProductsInfo } from '@/api/products/updateProducts';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import SimilarProductList from '@/components/Products/SimilarProductList';
import { PRODUCTS_CAN_SALES_MAX_THREE } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { DUPLICATE_VEHICLE_WARNING_CONTENT } from '@/const/modal/content';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface ProductProps {
  productOriginData?: ProductDetailResponse | undefined;
  productSearchParams: ProductSearchParams;
}

const PriceTrendResult: React.FC<ProductProps> = ({ productSearchParams, productOriginData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const [isShowWarningPopup, setIsShowWarningPopup] = useState(false);
  const [isShowSelectSalesTypePopup, setIsShowSelectSalesTypePopup] = useState(false);

  const buttonList: Button[] = [
    {
      title: '내 차 판매하러가기',
      isDisabled: false,
      onClick: () => checkAndDisplayDuplicateWarning(),
      description: '입력한 정보로 빠르게 차량을 판매해보세요.',
    },
  ];

  const showSelectSalesTypePopup = () => {
    setIsShowSelectSalesTypePopup(true);
  };

  const checkAndDisplayDuplicateWarning = () => {
    if (location?.state?.productOriginData?.isDuplicateProduct) {
      setIsShowWarningPopup(true);
    } else {
      showSelectSalesTypePopup();
    }
  };

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const [isScrolling, setIsScrolling] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [bubblePosition, setBubblePosition] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.clientWidth;
      setBubblePosition(buttonWidth / 4);
    }
  }, []);

  const updateProductsInfoMutation = useMutation(
    (request: { productId: number; type: string }) => patchProductsInfo(request),
    {
      onSuccess: (response, request) => {
        navigate('/sell-car/guide', {
          state: { type: request.type, productId: request?.productId },
        });
      },
      onError: (error: AxiosError) => {
        if (error.code === PRODUCTS_CAN_SALES_MAX_THREE) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickSalesType = (type: string) => {
    if (productSearchParams?.productId) {
      updateProductsInfoMutation.mutate({ productId: productSearchParams?.productId, type });
    } else {
      navigate('/sell-car/guide', {
        state: { type: type },
      });
    }
  };

  const handleClickSaleHelp = () => {
    navigate('/one-stop-service');
  };

  return (
    <>
      <div className="p-4 mt-6 rounded-lg bg-blue-0">
        <p className="mb-2 text-lg font-bold">
          {productOriginData ? productOriginData.year : productSearchParams.year}년{' '}
          {productOriginData
            ? productOriginData.manufacturerCategories?.name
            : productSearchParams.manufacturerCategories?.name}{' '}
          {productSearchParams?.model?.name} {productSearchParams?.tons}톤{' '}
          {productSearchParams?.axis?.code === 'NONE' ? '' : productSearchParams?.axis?.desc}{' '}
          {productSearchParams?.loaded?.desc}
        </p>
        <p className="text-gray-8 text-[14px] leading-[17px]">해당 차종의 평균 시세 정보를 불러왔어요.</p>
      </div>
      <div className="mt-[6px]">
        <CarPriceTrendInfo
          priceSearchParams={productSearchParams}
          showPriceComparison={false}
          apiType="private"
          isShowStickyBanner={true}
          stickBannerPosition="bottom-[92px]"
        />
        <SimilarProductList similarSearchParams={productSearchParams}></SimilarProductList>
      </div>

      {/* <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pt- pb-12 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white shadow-[0_-2px_4px_rgba(0,0,0,0.05)] z-40">
        <ButtonFooter
          title="내 차 판매하러가기"
          path="/products/sales"
          onClick={checkAndDisplayDuplicateWarning}
        ></ButtonFooter>
        <div className="absolute bottom-4 text-primary">입력한 정보로 빠르게 차량을 판매해보세요.</div>
      </div> */}

      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-gray-0">
        <ButtonFooter buttons={buttonList}></ButtonFooter>
      </div>

      <ConfirmModal
        isOpen={isShowWarningPopup}
        title={location?.state?.productOriginData?.truckName || ''}
        content={DUPLICATE_VEHICLE_WARNING_CONTENT}
        leftButtonLabel="아니오"
        onLeftButtonClick={() => setIsShowWarningPopup(false)}
        rightButtonLabel="네, 맞습니다"
        onRightButtonClick={() => {
          setIsShowWarningPopup(false);
          setIsShowSelectSalesTypePopup(true);
        }}
      />

      <BottomSheetPopup
        isOpen={isShowSelectSalesTypePopup}
        onClose={() => setIsShowSelectSalesTypePopup(false)}
        title="판매 방식 선택"
      >
        <SalesTypeSelectButton
          onClickLeft={() => handleClickSalesType(PRODUCT_TYPE_DIRECT)}
          onClickRight={() => handleClickSalesType(PRODUCT_TYPE_SPEED)}
        />
        <div
          className="my-[30px] text-center font-medium text-[14px] text-gray-7 underline underline-offset-2"
          onClick={handleClickSaleHelp}
        >
          차량 판매에 어려움이 있으신가요?
        </div>
      </BottomSheetPopup>
    </>
  );
};

export default PriceTrendResult;

import React from 'react';
import { Link } from 'react-router-dom';

import GuideSection from './GuideSection';
import MenuHeader from '@/components/Header/MenuHeader';
import {
  ChevronRightIcon,
  Cog6ToothIcon,
  LoanConsultationOutlineIcon,
  SaleTruckOutlineIcon,
  SearchTruckOutlineIcon,
} from '@/components/Icon';
import DocumentTextOutlineIcon from '@/components/Icon/DocumentTextOutlineIcon';
import ManagementMenus from '@/components/More/ManagementMenus';
import ManageMentSubMenus from '@/components/More/ManagementSubMenus';
import ProfileImage from '@/components/More/ProfileImage';
import { colors } from '@/const/colors';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import '@/css/tailwind.css';

const MorePageMain: React.FC = () => {
  const { member } = useMemberContext();

  return (
    <>
      <div className="w-full">
        <div className="fixed z-50 flex items-center justify-between">
          <MenuHeader title="더보기" isMain={true}></MenuHeader>
          {member && (
            <div className="fixed items-right flex z-50 top-0 mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] h-[60px]">
              <Link to="/setting">
                <div className="absolute right-4 top-5">
                  <Cog6ToothIcon color={colors.gray[9]}></Cog6ToothIcon>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="pt-[60px] pb-[160px]">
          <div className="flex flex-col justify-center bg-gray-2 p-4 mx-4 rounded-lg h-[88px]">
            {member ? (
              <div className="z-10 flex items-center jusify-between ">
                <div className="flex-1">
                  <p className="text-gray-9 text-[24px] mr-2 font-semibold leading-[28px]">{member?.name}</p>
                  <p className="text-gray-7 text-base leading-[20px]">
                    {member?.email ? member.email : member?.phoneNumber}
                  </p>
                </div>
                <ProfileImage isOnlyShow></ProfileImage>
              </div>
            ) : (
              <Link to={'/login'} className="flex items-center">
                <p className="flex-1 m-2 font-semibold text-[18px] text-gray-8">회원가입/로그인 하러가기</p>
                <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
              </Link>
            )}
          </div>

          <hr className="mt-6 mb-3 border-4 border-gray-1"></hr>
          {member && (
            <>
              <ManagementMenus></ManagementMenus>
              <hr className="mx-4 my-3" />
            </>
          )}
          <ManageMentSubMenus></ManageMentSubMenus>

          <hr className="border-4 border-gray-1"></hr>
          <div className="px-4">
            <ul>
              <li className="list-none">
                <p className="pt-8 pb-2 font-semibold text-gray-8">부가 서비스</p>
                <Link to={'/one-stop-service'} className="flex items-center py-5 cursor-pointer">
                  <SaleTruckOutlineIcon color={colors.gray[7]} width={28} height={28} />
                  <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">판매대행 서비스</p>
                  <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
                </Link>
                <Link to={'/purchase-accompanying-service'} className="flex items-center py-5 cursor-pointer">
                  <SearchTruckOutlineIcon color={colors.gray[7]} width={28} height={28} />
                  <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">구매동행 서비스</p>
                  <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
                </Link>
                <Link to={'/transfer-agency-service'} className="flex items-center py-5 cursor-pointer">
                  <DocumentTextOutlineIcon color={colors.gray[7]} width={28} height={28} />
                  <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">이전대행 서비스</p>
                  <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
                </Link>
                <Link to={'/capital-counsel-service'} className="flex items-center py-5 cursor-pointer">
                  <LoanConsultationOutlineIcon color={colors.gray[7]} width={28} height={28} />
                  <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">대출상담 서비스</p>
                  <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
                </Link>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-4 border-gray-1"></hr>
          <GuideSection></GuideSection>
        </div>
      </div>
    </>
  );
};

export default MorePageMain;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import PhoneAuth from '@/components/Common/PhoneAuth';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import '@/css/tailwind.css';

const PhoneNumberChange = () => {
  const navigate = useNavigate();
  const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const { member, updateMember } = useMemberContext();
  const [memberInfo, setMemberInfo] = useState<MemberInfo>({
    name: '',
    phoneNumber: '',
    marketing: true,
    authNumber: '',
    authenticate: false,
  });

  const { showToast } = useToastContext();

  useEffect(() => {
    setMemberInfo((prevMemberInfo) => ({
      ...prevMemberInfo,
      authenticate: true,
    }));
  }, [member]);

  const handleChangeButton = () => {
    apiManager
      .patch('/api/v1/members', memberInfo)
      .then((response) => {
        showToast('휴대폰 번호를 변경했어요.', 'success', 'bottom');
        updateMember(response?.data as Member);
        navigate('/setting');
      })
      .catch((error) => {
        setPhoneNumberMessage('휴대폰 번호 변경에 실패했습니다. 다시 확인해주세요.');
        setErrorPhoneNumber(true);
      });
  };

  return (
    <>
      <MenuHeader title="휴대폰 번호 변경"></MenuHeader>
      <div className="w-full p-4 pt-[60px]">
        <section className="pt-6">
          <PhoneAuth
            isSign={false}
            prevPhoneNumber={member?.phoneNumber}
            memberInfo={memberInfo}
            updateMemberInfo={setMemberInfo}
          ></PhoneAuth>
        </section>
        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-gray-0">
          <ButtonFooter
            title="변경하기"
            isDisabled={!memberInfo.isAuthenticateCode}
            onClick={handleChangeButton}
          ></ButtonFooter>
        </div>
      </div>
    </>
  );
};

export default PhoneNumberChange;

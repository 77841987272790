import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import FileUploader from '@/components/Common/Uploader/FileUploader';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';

interface ProductProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
}

interface productImageType {
  id: string;
  name: string;
  url: string;
  imageType: string;
  isLoading?: boolean;
}

interface ProductImageComponentType {
  item: productImageType;
  truckNumber: string;
  index: number;
  productImageListData: productImageType[];
  setProductImageListData: React.Dispatch<React.SetStateAction<productImageType[]>>;
  isLoading: boolean;
}

const ProductImageComponent = ({
  item,
  truckNumber,
  index,
  productImageListData,
  setProductImageListData,
  isLoading,
}: ProductImageComponentType) => {
  const [file, setFile] = useState(productImageListData[index]);

  useEffect(() => {
    const updatedList = productImageListData.map((image: productImageType) =>
      image.id === file.id ? { ...image, url: file.url } : image,
    );
    setProductImageListData(updatedList);
  }, [file]);

  return (
    <>
      <p className="text-gray-7 text-xs mb-1">
        {item.name}
        {index < 2 && <span className="text-red">(필수)</span>}
      </p>
      <FileUploader
        truckNumber={truckNumber}
        imageType={item.imageType}
        fileData={item}
        setFileData={setFile}
        isLoading={isLoading}
        icon="photo"
      ></FileUploader>
    </>
  );
};

const PhotoUploader = ({ productInfo, setProductInfo }: ProductProps) => {
  const { showToast } = useToastContext();
  const { id } = useParams();
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const { productEnum, setProductEnum } = useProductEnumContext();
  const [loadingList, setLoadingList] = useState<boolean[]>([]);

  const [certificateData, setCerticicateData] = useState<productImageType>({
    id: 'certificateImageUrl',
    name: '차량등록증',
    url: productInfo?.productsImage?.certificateImageUrl || '',
    imageType: 'CERTIFICATE_IMAGE',
  });

  const [frontSideImageData, setFrontsideImageData] = useState<productImageType>({
    id: 'frontSideImageUrl',
    name: '앞측면',
    url: productInfo?.productsImage?.frontSideImageUrl || '',
    imageType: 'FRONT_SIDE_IMAGE',
  });

  const optionImageData = () => {
    const optionImages: productImageType[] = [];

    const optionImageUrl = productInfo?.productsImage?.optionImageUrl || [];

    for (let i = 0; i < optionImageUrl?.length - 4; i++) {
      optionImages.push({
        id: `optionImageUrl${i + 5}`,
        name: `옵션${i + 5}`,
        url: optionImageUrl[i + 4] || '',
        imageType: 'OPTION_IMAGE',
      });
    }

    return optionImages;
  };

  const [productImageListData, setProductImageListData] = useState<productImageType[]>([
    {
      id: 'backSideImageUrl',
      name: '뒷측면',
      url: productInfo?.productsImage?.backSideImageUrl || '',
      imageType: 'BACK_SIDE_IMAGE',
    },
    {
      id: 'frontImageUrl',
      name: '전면',
      url: productInfo?.productsImage?.frontImageUrl || '',
      imageType: 'FRONT_IMAGE',
    },
    {
      id: 'backImageUrl',
      name: '후면',
      url: productInfo?.productsImage?.backImageUrl || '',
      imageType: 'BACK_IMAGE',
    },
    {
      id: 'tireImageUrl',
      name: '휠/타이어',
      url: productInfo?.productsImage?.tireImageUrl || '',
      imageType: 'TIRE_IMAGE',
    },
    {
      id: 'engineImageUrl',
      name: '엔진',
      url: productInfo?.productsImage?.engineImageUrl || '',
      imageType: 'ENGINE_IMAGE',
    },
    {
      id: 'insideImageUrl',
      name: '실내',
      url: productInfo?.productsImage?.insideImageUrl || '',
      imageType: 'INSIDE_IMAGE',
    },
    {
      id: 'dashboardImageUrl',
      name: '계기판',
      url: productInfo?.productsImage?.dashboardImageUrl || '',
      imageType: 'DASHBOARD_IMAGE',
    },
    {
      id: 'sheetImageUrl',
      name: '시트',
      url: productInfo?.productsImage?.sheetImageUrl || '',
      imageType: 'SHEET_IMAGE',
    },
    {
      id: 'optionImageUrl1',
      name: '옵션1',
      url: productInfo?.productsImage?.optionImageUrl?.[0] || '',
      imageType: 'OPTION_IMAGE',
    },
    {
      id: 'optionImageUrl2',
      name: '옵션2',
      url: productInfo?.productsImage?.optionImageUrl?.[1] || '',
      imageType: 'OPTION_IMAGE',
    },
    {
      id: 'optionImageUrl3',
      name: '옵션3',
      url: productInfo?.productsImage?.optionImageUrl?.[2] || '',
      imageType: 'OPTION_IMAGE',
    },
    {
      id: 'optionImageUrl4',
      name: '옵션4',
      url: productInfo?.productsImage?.optionImageUrl?.[3] || '',
      imageType: 'OPTION_IMAGE',
    },
    ...optionImageData(),
  ]);

  useEffect(() => {
    setPrevData(productInfo);
  }, []);

  const onClickUploadAll = () => {
    const inputElement = document.getElementById(`upload-images-all`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const filesList = e.target.files;
    uploadProductImage(filesList);
  };

  const uploadProductImage = async (filesList: FileList) => {
    if (filesList.length > 15) {
      showToast('이미지는 최대 15개까지 첨부할 수 있어요.', 'error', 'top');
      return;
    }
    setLoadingList(Array.from({ length: filesList.length }, () => true));

    const reader = new FileReader();
    const formData = new FormData();
    const filesArray = Array.from(filesList);

    filesArray.forEach((file, index) => {
      formData.append(`uploadFile`, file);
    });
    formData.append('truckNumber', productInfo.truckName ? productInfo.truckName : '');

    try {
      setProductImageListData([
        {
          id: 'backSideImageUrl',
          name: '뒷측면',
          url: '',
          imageType: 'BACK_SIDE_IMAGE',
        },
        {
          id: 'frontImageUrl',
          name: '전면',
          url: '',
          imageType: 'FRONT_IMAGE',
        },
        {
          id: 'backImageUrl',
          name: '후면',
          url: '',
          imageType: 'BACK_IMAGE',
        },
        {
          id: 'tireImageUrl',
          name: '휠/타이어',
          url: '',
          imageType: 'TIRE_IMAGE',
        },
        {
          id: 'engineImageUrl',
          name: '엔진',
          url: '',
          imageType: 'ENGINE_IMAGE',
        },
        {
          id: 'insideImageUrl',
          name: '실내',
          url: '',
          imageType: 'INSIDE_IMAGE',
        },
        {
          id: 'dashboardImageUrl',
          name: '계기판',
          url: '',
          imageType: 'DASHBOARD_IMAGE',
        },
        {
          id: 'sheetImageUrl',
          name: '시트',
          url: '',
          imageType: 'SHEET_IMAGE',
        },
        {
          id: 'optionImageUrl1',
          name: '옵션1',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl2',
          name: '옵션2',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl3',
          name: '옵션3',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
        {
          id: 'optionImageUrl4',
          name: '옵션4',
          url: '',
          imageType: 'OPTION_IMAGE',
        },
      ]);

      const path = '/api/v1/products-images/multi';
      const response = await apiManager.post(path, formData, {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      });

      if (response.data) {
        if (filesList) {
          const filesArray = response.data;

          setProductImageListData((prevList) => {
            const newList = prevList.map((image, index) => ({
              id: image.id,
              name: image.name,
              url: index < filesArray.length ? filesArray[index].url.toString() : '',
              imageType: image.imageType,
            }));

            const remainingImagesCount = filesArray.length - prevList.length;
            if (remainingImagesCount > 0) {
              for (let i = prevList.length; i < prevList.length + remainingImagesCount; i++) {
                newList.push({
                  id: `optionImageUrl${i - 7}`,
                  name: `옵션${i - 7}`,
                  url: filesArray[i].url.toString(),
                  imageType: 'OPTION_IMAGE',
                });
              }
            }

            return newList;
          });
        }
      } else {
        console.error('File upload failed');
      }
    } catch (error) {
      showToast('이미지 업로드에 문제가 생겼어요.', 'error', 'bottom');
    } finally {
      setLoadingList(Array.from({ length: filesList.length }, () => false));
    }
  };

  useEffect(() => {
    const optionImages = productImageListData.slice(8, 15).filter((image) => image.url !== '');

    const optionImagesUrls: string[] = optionImages.map((image) => image.url);

    const request: ProductsImages = {
      certificateImageUrl: certificateData.url,
      frontSideImageUrl: frontSideImageData.url,
      backSideImageUrl: productImageListData.find((image) => image.imageType === 'BACK_SIDE_IMAGE')?.url || '',
      frontImageUrl: productImageListData.find((image) => image.imageType === 'FRONT_IMAGE')?.url || '',
      backImageUrl: productImageListData.find((image) => image.imageType === 'BACK_IMAGE')?.url || '',
      tireImageUrl: productImageListData.find((image) => image.imageType === 'TIRE_IMAGE')?.url || '',
      engineImageUrl: productImageListData.find((image) => image.imageType === 'ENGINE_IMAGE')?.url || '',
      insideImageUrl: productImageListData.find((image) => image.imageType === 'INSIDE_IMAGE')?.url || '',
      dashboardImageUrl: productImageListData.find((image) => image.imageType === 'DASHBOARD_IMAGE')?.url || '',
      sheetImageUrl: productImageListData.find((image) => image.imageType === 'SHEET_IMAGE')?.url || '',
      optionImageUrl: optionImagesUrls,
      id: productInfo.productsImage.id,
      engineVideoUrl: '',
    };
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      productsImage: request,
    }));
  }, [productImageListData, certificateData, frontSideImageData]);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[30px]">
        <div>
          <div className="text-base">
            <label className="text-base font-medium mb-3 text-gray-8">차량등록증 업로드</label>
            <div className="grid grid-cols-3 gap-2">
              <FileUploader
                truckNumber={productInfo?.truckNumber || ''}
                imageType="CERTIFICATE_IMAGE"
                fileData={certificateData}
                setFileData={setCerticicateData}
                icon="file"
              ></FileUploader>
            </div>
          </div>
        </div>

        <div>
          <label className="text-base font-medium mb-3 text-gray-8">차량 대표 사진 업로드</label>
          <p className="text-gray-6 text-xs mb-2">TIP. 앞측면을 대표 사진으로 업로드하면 판매 확률이 높아져요.</p>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <p className="text-gray-7 text-xs mb-1">
                앞측면
                <span className="text-red">(필수)</span>
              </p>
              <FileUploader
                truckNumber={productInfo?.truckNumber || ''}
                imageType="FRONT_SIDE_IMAGE"
                fileData={frontSideImageData}
                setFileData={setFrontsideImageData}
              ></FileUploader>
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center">
            <label className="text-base font-medium mb-3 text-gray-8">차량 사진 업로드</label>
            <span className="text-[#919191] text-sm" onClick={onClickUploadAll}>
              한번에 불러오기
            </span>
            <input
              id="upload-images-all"
              accept="image/*"
              multiple
              type="file"
              onChange={(e) => onUpload(e)}
              className="hidden"
            />
          </div>
          <p className="text-gray-6 text-xs mb-3">TIP. 차량 사진은 최대 15장까지 업로드가 가능합니다.</p>

          <div className="grid grid-cols-3 gap-2">
            {productImageListData?.map((item, index) => (
              <div className="flex flex-col" key={item.id}>
                <ProductImageComponent
                  item={item}
                  truckNumber={productInfo?.truckNumber || ''}
                  index={index}
                  productImageListData={productImageListData}
                  setProductImageListData={setProductImageListData}
                  isLoading={loadingList[index]}
                ></ProductImageComponent>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoUploader;

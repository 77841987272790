import React from 'react';
import { Link } from 'react-router-dom';

const SalePlan = () => {
  return (
    <div className="min-w-[320px]">
      <div className="w-full flex justify-center items-center">
        <img
          src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/guide_sale_plan_cover.png"
          alt="판매 계획 수립 커버"
          className="w-full"
          loading="lazy"
        />
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 py-4 px-4">
        <h3 className="font-semibold text-xl py-4">매물을 빠르게 판매하세요</h3>
        <p className="pb-8 leading-8">
          트럭 판매를 고민하고 계신가요?
          <br />
          저희 직트럭에서 도와드리겠습니다.
        </p>
        <p className="pb-8 leading-8">직트럭에서는 크게 두가지 방법으로 빠르게 매물을 판매하실 수 있어요.</p>
        <p className="pb-8 leading-8">
          <span className="font-semibold">1. 차주 A - "지금 당장 판매해야 해요!"</span>
          <br />• 직트럭에서 차량을 빠르게 매입해드려요! <br />
          [내차 판매] 탭에서 [전문가에게 빠른 판매]를 선택하시고 차량 등록 후 매입 견적 연락을 드릴 예정이며, 48시간
          이내에 차량 매입 처리가 가능합니다.
        </p>
        <p className="pb-8 leading-8">
          <span className="font-semibold">2. 차주 B - "급하진 않아서 운행하면서 판매하고싶어요~" </span>
          <br />
          • 직트럭에서 직접 차량을 판매할 수 있어요!
          <br />
          차량 정보만 기입하면 소유하고 있는 차량의 시세를 확인할수있고, 간편하게 차량을 등록할수 있습니다. 차량에
          관심이 있다면 실시간 채팅 및 전화를 통해 상담 할 수 있습니다. <br />
          <br />
          혹여나 전화번호가 노출될까 걱정마세요! 전화번호는 노출되지않고 안심번호로 연결됩니다.
        </p>
      </div>

      <div className="text-gray-700 border-b-8 border-gray-50 pt-4 pb-28 px-4">
        <h3 className="font-semibold text-xl py-4">판매가 지연되는 경우</h3>
        <p className="pb-8 leading-8">
          차량 판매가 더디거나 지연되는 경우가 있어요.
          <br />
          이럴 땐 아래 사항들을 확인하시면 조금 더 빠른 시기에 판매가 가능해질 수 있습니다.
        </p>
        <p className="pb-8 leading-8">
          • 판매하는 차량이 시세보다 높은 가격은 아닌지 체크해야 하며, 영업용 화물차의 경우 차량 선호도 차이(차량
          모델,특장의 종류 등)로 판매가 지연될 수 있습니다.
        </p>
        <p className="pb-8 leading-8">
          • 차량 정보를 오기입하진 않았는지, 차량을 세세하게 확인할 수 있는 사진을 올렸는지 확인해야 해요.
        </p>
        <p className="pb-8 leading-8">
          • 차량을 기존보다 낮은 가격으로 수정하면 직트럭 홈 화면에 [추천 차량]으로 업데이트되고 차량 노출 횟수가
          이전보다 높아집니다.
        </p>
        <div className="text-xs pb-8">
          <p className="text-gray-500 mb-4">관련 서비스</p>
          <Link to="/price-trend">
            <span className="bg-gray-100 py-2 px-4 rounded-lg mr-2">시세 검색</span>
          </Link>
          <Link to="/products">
            <span className="bg-gray-100 py-2 px-4 rounded-lg mr-2">추천 차량</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default SalePlan;

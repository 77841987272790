import React, { useEffect, useState } from 'react';

import { CheckCircleOutlineIcon, CheckCircleSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/inputStyle.css';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

type CheckBoxWithEtcProps = {
  title: string;
  name: string;
  optionList: EnumPresenter[] | undefined;
};
interface ProductProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
}

const CheckBoxWithEtc = ({
  title,
  name,
  optionList,
  productInfo,
  setProductInfo,
}: CheckBoxWithEtcProps & ProductProps) => {
  const MAINTENANCE = 'maintenance';

  const [etc, setEtc] = useState('');
  const [checkList, setCheckList] = useState<EnumPresenter[]>([]);

  const onChangeEtc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEtc(value);
  };

  const handleSingleCheck = (checked: boolean, item: EnumPresenter) => {
    const prev =
      title === MAINTENANCE
        ? productInfo?.maintenance?.maintenanceData || []
        : productInfo?.carOption?.[title]?.option || [];
    if (checked) {
      setCheckList([...prev, item]);
    } else {
      setCheckList(prev.filter((el: any) => el.code !== item.code));
    }
  };

  const isChecked = (item: EnumPresenter): boolean => {
    if (title === MAINTENANCE) {
      return (
        productInfo?.[title]?.maintenanceData?.some((data: EnumPresenter) => {
          return data.code === item.code;
        }) || false
      );
    } else {
      return (
        productInfo?.carOption?.[title]?.option.some((data: EnumPresenter) => {
          return data.code === item.code;
        }) || false
      );
    }
  };

  const getPlaceholderText = () => {
    switch (title) {
      case 'maintenance':
        return '정비 이력 입력';
      case 'normalOption':
      case 'additionalOption':
        return '옵션 입력';
      case 'breakOption':
        return '브레이크 입력';
    }
  };

  useEffect(() => {
    if (title === MAINTENANCE) {
      setProductInfo({
        ...productInfo,
        [title]: { etc: etc, maintenanceData: productInfo?.[title]?.maintenanceData || [] },
      });
    } else {
      setProductInfo((prevProductInfo) => {
        const newProductInfo = { ...prevProductInfo };
        newProductInfo.carOption = {
          ...newProductInfo.carOption,
          [title]: { etc: etc, option: productInfo?.carOption?.[title]?.option },
        };
        return newProductInfo;
      });
    }
  }, [etc]);

  useEffect(() => {
    if (title === MAINTENANCE) {
      setProductInfo({
        ...productInfo,
        [title]: { etc: productInfo?.[title]?.etc || '', maintenanceData: checkList },
      });
    } else {
      setProductInfo((prevProductInfo) => {
        const newProductInfo = { ...prevProductInfo };
        newProductInfo.carOption = {
          ...newProductInfo.carOption,
          [title]: { etc: productInfo?.carOption?.[title]?.etc, option: checkList },
        };
        return newProductInfo;
      });
    }
  }, [checkList]);

  useEffect(() => {
    setProductInfo(productInfo);
    if (title === MAINTENANCE) {
      setEtc(productInfo?.maintenance?.etc || '');
    } else {
      setEtc(productInfo?.carOption?.[title]?.etc || '');
    }
  }, []);

  return (
    <>
      <div className={`bg-gray-1 px-4 ${name ? 'pt-4' : 'pt-[7px]'} pb-4 rounded-lg`}>
        <label className="font-semibold">{name}</label>
        <div className="grid grid-cols-2 gap-1">
          {optionList?.map((item, index) => (
            <div key={index} className="">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckCircleOutlineIcon color={colors.gray[3]} />}
                    checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                    sx={{
                      '&.Mui-checked': {
                        color: colors.primary,
                      },
                    }}
                    onChange={(e) => handleSingleCheck(e.target.checked, item)}
                    checked={isChecked(item)}
                  />
                }
                label={<span className="text-sm">{item.desc}</span>}
              ></FormControlLabel>
            </div>
          ))}
        </div>
        <hr />
        <div className="flex justify-center items-center mt-3">
          <label className="w-20 ">직접입력</label>
          <TextField
            className="w-full"
            placeholder={getPlaceholderText()}
            fullWidth
            multiline
            value={etc}
            onChange={onChangeEtc}
            sx={{
              minHeight: '42px',
              backgroundColor: colors.gray[0],
              borderRadius: '8px',
            }}
            minRows={1}
            maxRows={3}
          />
        </div>
      </div>
    </>
  );
};

export default CheckBoxWithEtc;

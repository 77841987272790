import React, { SVGProps } from 'react';

const SvgSearchQuestionBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <circle cx={16} cy={16} r={12} stroke={props.color || 'currentColor'} strokeWidth={4} />
    <rect
      width={4}
      height={16}
      x={21.945}
      y={24.948}
      fill={props.color || 'currentColor'}
      rx={2}
      transform="rotate(-45 21.945 24.948)"
    />
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M14.186 13.15c0-.12.239-1.05 1.821-1.05.484 0 1.208.213 1.56.74.224.338.134.977-.423 1.35-.066.045-.142.093-.227.147-.385.245-.935.595-1.366 1.083-.539.61-.637 1.357-.637 1.891V18.5c0 .58.49 1.05 1.093 1.05.604 0 1.093-.47 1.093-1.05v-1.189c0-.336.072-.48.118-.533.212-.24.435-.384.78-.606.117-.075.248-.16.397-.26 1.298-.87 2.006-2.712 1.012-4.204-.909-1.365-2.516-1.708-3.4-1.708C13.217 10 12 11.87 12 13.15c0 .58.49 1.05 1.093 1.05.604 0 1.093-.47 1.093-1.05ZM16 22.687a1.094 1.094 0 1 0 0-2.187 1.094 1.094 0 0 0 0 2.188Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgSearchQuestionBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSearchQuestionBoldIcon;

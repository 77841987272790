import { atom, useSetAtom } from 'jotai';

import { getCurrentYear } from '@/utils/common';

const currentYear = getCurrentYear();

const initialProductsFormState: ProductDetailResponse = {
  productsImage: {
    id: 0,
    certificateImageUrl: '',
    frontSideImageUrl: '',
    backSideImageUrl: '',
    frontImageUrl: '',
    backImageUrl: '',
    tireImageUrl: '',
    engineImageUrl: '',
    insideImageUrl: '',
    dashboardImageUrl: '',
    sheetImageUrl: '',
    optionImageUrl: [] as string[] | null,
    engineVideoUrl: '',
  },
  manufacturerCategories: {
    id: 0,
    name: '',
    code: '',
  },
  model: {
    id: 0,
    name: '',
  },
  accidentsHistory: {
    id: 0,
    accidentContents: null,
    createdBy: null,
    createdDate: '',
    modifiedBy: null,
    modifiedDate: '',
    accident: false,
  },
  maintenance: {
    id: 0,
    maintenanceData: [],
    etc: '',
    createdBy: null,
    createdDate: '',
    modifiedBy: null,
    modifiedDate: '',
  },
  carOption: {
    id: 0,
    normalOption: {
      option: [],
      etc: '',
    },
    additionalOption: {
      option: [],
      etc: '',
    },
    breakOption: {
      option: [],
      etc: '',
    },
    createdBy: null,
    createdDate: '',
    modifiedBy: null,
    modifiedDate: '',
  },
  sellerId: 0,
  sellerSafetyNumber: '',
  id: 0,
  productsNumber: 0,
  ownerName: '',
  status: {
    code: '',
    desc: '',
  },
  type: {
    code: '',
    desc: '',
  },
  salesType: null,
  price: null,
  actualSalePrice: null,
  truckNumber: '',
  truckName: '',
  tons: '',
  year: '',
  firstRegistrationDate: '',
  power: '',
  transmission: {
    code: '',
    desc: '',
  },
  axis: {
    code: '',
    desc: '',
  },
  distance: 0,
  loaded: {
    code: '',
    desc: '',
  },
  loadedInnerLength: 0,
  loadedInnerArea: null,
  loadedInnerHeight: null,
  color: {
    code: '',
    desc: '',
  },
  fuel: {
    code: '',
    desc: '',
  },
  transportGoods: '',
  transportStartLocate: null,
  transportEndLocate: null,
  tireStatus: null,
  carUse: '',
  carType: '',
  motorType: '',
  identificationNumber: '',
  originDetail: '',
  detailContent: '',
  hit: 0,
  inspectionInvalidStartDate: '',
  inspectionInvalidEndDate: '',
  modifiedPriceDate: '',
  garage: {
    code: '',
    desc: '',
  },
  carRegisterSearchDate: '',
  tradingHistory: [],
  inspectionHistory: [],
  tuningHistory: [],
  lastOwnerInfo: {
    date: '',
    content: '',
  },
  createdDate: '',
  modifiedDate: '',
  modifiedBy: '',
  isDelete: false,
};

const initialSearchParams: ProductsSearch = {
  keyword: '',
  manufacturerCategoriesId: new Set([]),
  minYear: '2000',
  maxYear: (currentYear + 1).toString(),
  minTons: '1',
  maxTons: '27',
  minDistance: '0',
  maxDistance: '200',
  axis: '',
  transmission: '',
  loaded: new Set([]),
  minLoadedInnerLength: '',
  maxLoadedInnerLength: '',
  salesType: 'ALL',
  productsSortType: { code: 'CREATED_DATE', desc: '기본 정렬순' },
  page: 1,
};

export const productsFormAtom = atom<ProductDetailResponse>(initialProductsFormState);
export const productsSearchParamsAtom = atom<ProductsSearch>(initialSearchParams);

export const useResetProductsForm = () => {
  const setProductsForm = useSetAtom(productsFormAtom);
  return () => setProductsForm(initialProductsFormState);
};

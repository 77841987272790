import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MyProductPrice from '../MyProductPrice';
import apiManager from '@/api/AxiosInstance';
import DraftListingBox from '@/components/Common/DraftListingBox';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import MoreMenu from '@/components/Menu/MoreMenu';
import ProductStatus from '@/components/More/ProductStatus';
import ProductType from '@/components/More/ProductType';
import ProductTypeChangeButton from '@/components/More/ProductTypeChangeButton';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { COMPLETED, SALE } from '@/const/productStatus';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { getProductType, isShowTypeChangeButtonAndMoreMenu } from '@/utils/Products/productUtils';

const MyListing = () => {
  const navigate = useNavigate();
  const [myProductData, setMyProductData] = useState<ProductDetailResponse[]>([]);
  const { showToast } = useToastContext();
  const deleteId = useRef<number>();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState<string | undefined>('');

  const onClickProductList = (item: ProductDetailResponse) => {
    const { status, id } = item;
    if ([SALE, COMPLETED].includes(status.code)) {
      navigate(`/products/${id}`, { state: { isPublicDetail: false } });
    }
  };

  useEffect(() => {
    apiManager
      .get('/api/v1/products/my')
      .then((response) => {
        if (response) {
          const responseData: ProductDetailResponse[] = response.data;
          setMyProductData(responseData);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const onClickDelete = (item: ProductDetailResponse) => {
    if (item) {
      setIsShowPopup(true);
      setPopupTitle(item.truckNumber);
      deleteId.current = item.id;
    }
  };

  const deleteProduct = () => {
    apiManager
      .delete(`/api/v1/products/${deleteId.current}`)
      .then((response) => {
        if (response) {
          setMyProductData((prev) => prev.filter((item) => item.id !== deleteId.current));
        }
        showToast('차량이 삭제되었어요.', 'success', 'bottom');
        setIsShowPopup(false);
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  };

  const isShowDivider =
    myProductData.some((item) => item.status.code === 'BEFORE_SALE') &&
    myProductData.some((item) => item.status.code !== 'BEFORE_SALE');

  const onClickMore = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {myProductData.length > 0 && (
        <div>
          <div className="h-2 w-full bg-gray-1 mt-[50px]"></div>
          <section className="flex flex-col justify-between w-full p-4 pt-6">
            <h2 className="text-xl font-bold ">내가 등록한 차량</h2>
            <div>
              <>
                {myProductData.map((item, index) => (
                  <div key={item.id} className="mb-4">
                    {item.status.code === 'BEFORE_SALE' && (
                      <DraftListingBox
                        data={item}
                        onClickDelete={onClickDelete}
                        updateList={setMyProductData}
                      ></DraftListingBox>
                    )}
                  </div>
                ))}
              </>

              {!!isShowDivider && <div className="h-[1px] w-full bg-gray-4 mb-5"></div>}

              <>
                {myProductData.map((item, index) => (
                  <ul key={item.id} className="mb-3.5 xxs:text-sm">
                    {item.status.code !== 'BEFORE_SALE' && (
                      <>
                        <li className="relative flex p-4 border rounded-lg border-gray-3">
                          <div className="h-[100px] w-[100px] min-w-[80px] min-h-[80px]">
                            <img
                              src={
                                item.productsImage?.frontSideImageUrl ||
                                'https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/truck_img.svg'
                              }
                              alt="트럭이미지"
                              className="object-cover w-full h-full rounded-lg"
                            ></img>
                          </div>
                          <div
                            className={`px-3 flex-1 min-w-[160px] ${
                              item.status.code === SALE || item.status.code === COMPLETED ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => onClickProductList(item)}
                          >
                            <div className="flex flex-wrap gap-1">
                              <ProductStatus status={item.status}></ProductStatus>
                              <ProductType
                                type={getProductType(item.type, item.salesType, item.statusOfSpeedProduct)}
                              ></ProductType>
                            </div>

                            <dt className="pt-2 font-medium text-gray-8">{item?.truckNumber}</dt>
                            <span className="text-lg font-semibold text-gray-9">
                              <MyProductPrice product={item}></MyProductPrice>
                            </span>

                            {isShowTypeChangeButtonAndMoreMenu(item.salesType?.code) && (
                              <>
                                <ProductTypeChangeButton
                                  id={item.id}
                                  type={item.type}
                                  status={item.status}
                                  setProductList={setMyProductData}
                                  statusOfSpeedProduct={item.statusOfSpeedProduct}
                                ></ProductTypeChangeButton>
                                <div className="absolute right-2 top-[60px]" onClick={onClickMore}>
                                  <MoreMenu
                                    id={item.id}
                                    status={item.status.code}
                                    price={Number(item.price)}
                                    setProductList={setMyProductData}
                                    confirmTitle={item.truckNumber}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                ))}
              </>
            </div>
          </section>
        </div>
      )}

      <ConfirmModal
        isOpen={isShowPopup}
        title={popupTitle}
        content="차량을 정말 삭제하시겠어요?"
        rightButtonLabel="삭제하기"
        onLeftButtonClick={() => setIsShowPopup(false)}
        onRightButtonClick={() => deleteProduct()}
      />
    </>
  );
};

export default MyListing;

import React from 'react';

import BasicButton from './Button/BasicButton';
import { colors } from '@/const/colors';
import '@/css/licensePlateInputStyle.css';

interface ErrorInfoProps {
  errorInfo: ErrorInfo | undefined;
  setErrorInfo: React.Dispatch<React.SetStateAction<ErrorInfo | undefined>>;
}
interface LicensePlateInputProps {
  updatePage: (pageNum: number) => void;
  ownerInfo: OwnerInfo;
  setOwnerInfo: React.Dispatch<React.SetStateAction<OwnerInfo>>;
}

const LicensePlateInput: React.FC<LicensePlateInputProps & ErrorInfoProps> = ({
  updatePage,
  ownerInfo,
  setOwnerInfo,
  errorInfo,
  setErrorInfo,
}) => {
  const onChangeLicenseNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorInfo({
      ...errorInfo,
      licenseNumberError: false,
      licenseNumberErrorMessage: '',
      ownerNameError: false,
      ownerNameErrorMessage: '',
    });
    const { value } = e.target;

    const allowedCharacters = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣ᆞᆢㆍᆢᄀᆞᄂᆞᄃᆞᄅᆞᄆᆞᄇᆞᄉᆞᄋᆞᄌᆞᄎᆞᄏᆞᄐᆞᄑᆞᄒᆞ]*$/;

    if (allowedCharacters.test(value.trim())) {
      setOwnerInfo({ ...ownerInfo, licenseNumber: value.trim() });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (e.key === 'Enter') {
      if (!value) {
        setErrorInfo({
          ...errorInfo,
          ownerNameError: false,
          ownerNameErrorMessage: '',
          licenseNumberError: true,
          licenseNumberErrorMessage: '차량 번호를 입력해주세요.',
        });
        return;
      } else {
        updatePage(2);
      }
    }
  };

  const handleButtonClick = () => {
    if (!ownerInfo.licenseNumber) {
      setErrorInfo({
        ...errorInfo,
        ownerNameError: false,
        ownerNameErrorMessage: '',
        licenseNumberError: true,
        licenseNumberErrorMessage: '차량 번호를 입력해주세요.',
      });
      return;
    } else {
      updatePage(2);
    }
  };

  return (
    <>
      <div className="relative">
        <div className="bg-white rounded-lg p-1 shadow-[0_4px_6px_-1px_rgba(0,0,0,0.2)]">
          <div className="bg-yellow rounded-lg border-2 border-gray-7 p-3 flex items-center justify-center">
            <input
              type="text"
              maxLength={9}
              className="license-plate-input bg-transparent border-none focus:outline-none text-[32px] font-semibold w-full justify-center xxs:pr-0 xs:pr-0 xxs:text-[24px] xxs:tracking-tight"
              value={ownerInfo.licenseNumber}
              onChange={onChangeLicenseNumber}
              onKeyDown={handleKeyPress}
              placeholder="경기24아6249"
            ></input>
            <div className="relative">
              <BasicButton
                name="조회"
                bgColor={colors.gray[0]}
                borderColor={colors.gray[0]}
                textColor={colors.gray[8]}
                fontSize={16}
                width={60}
                height={46}
                fontWeight="bold"
                shadow={true}
                onClick={() => handleButtonClick()}
              ></BasicButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicensePlateInput;

import React from 'react';

interface UnreadCountBadgeProps {
  count: number;
}

const UnreadCountBadge = React.memo(({ count }: UnreadCountBadgeProps) => {
  const displayedCount = count < 0 ? 0 : count;

  const badgeText = displayedCount > 99 ? '99+' : displayedCount;

  const badgeWidth = displayedCount > 99 ? 'w-[36px]' : 'w-[22px]';

  return (
    <span
      className={`ml-2 bg-red rounded-[14px] ${badgeWidth} h-[22px] text-center text-[13px] font-medium text-white leading-[22px]`}
    >
      {badgeText}
    </span>
  );
});

export default UnreadCountBadge;

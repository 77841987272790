import React, { useState } from 'react';

import TextInputLabelOff from '../Common/Input/TextInputLabelOff';
import AlertModal from '../Common/Modal/AlertModal';
import { formatNumber, formatPrice } from '@/utils/common';

export interface ActualSalePriceInputPopupProps {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder: string;
  price?: number;
  onClickOk: () => void;
  inputValue: number | undefined;
  setInputValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  useErrorMessage?: boolean;
}

const ActualPriceModal = ({
  isShow,
  setIsShow,
  placeholder,
  price,
  onClickOk,
  inputValue,
  setInputValue,
  useErrorMessage = true,
}: ActualSalePriceInputPopupProps) => {
  const [actualSalePriceError, setActualSalePriceError] = useState(false);
  const [actualSalePriceErrorMessage, setActualSalePriceErrorMessage] = useState('');

  const onChangeActualSalePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    let price = String(value).replace(/[^0-9.]/g, '');
    if (/^[0-9]*$/.test(price) || price === '0') {
      if (Number(price) >= 100000) {
        price = String(99999);
      } else {
        setInputValue(Number(price));
      }
    }
    setInputValue(Number(price));
  };

  const onClickOkBtn = () => {
    if (inputValue || !useErrorMessage) {
      onClickOk();
      closePopup();
    } else {
      setActualSalePriceError(true);
      setActualSalePriceErrorMessage(`${placeholder}을 입력해주세요.`);
    }
  };

  const closePopup = () => {
    setIsShow(false);
    setActualSalePriceError(false);
    setActualSalePriceErrorMessage('');
    setInputValue(undefined);
  };

  return (
    <>
      <AlertModal
        isOpen={isShow}
        onClose={() => {
          onClickOkBtn();
        }}
        title="<span class='font-medium text-[20px] sm280:text-[16px]'>판매 완료로 변경되었어요</span>"
        content={
          <div className="px-[10px]">
            <p className="text-[18px] sm280:text-[14px] mb-[24px]">실제 판매 금액을 입력해주세요.</p>
            <TextInputLabelOff
              name="actualPrice"
              placeholder={placeholder}
              value={(inputValue && formatNumber(String(inputValue), false)) || ''}
              height={36}
              fontSize={17}
              onChange={onChangeActualSalePrice}
              error={useErrorMessage && actualSalePriceError}
              errorMsg={actualSalePriceErrorMessage}
              inputMode="numeric"
              suffix="만원"
              autoFocus
            />
            {!actualSalePriceError && (
              <div className="text-left pt-[6px] text-gray-7 text-[16px] sm280:text-[14px]">
                {price && <>기존 금액 [{formatPrice(String(price))}]</>}
              </div>
            )}
          </div>
        }
        paddingType="padding-top-32"
      />
    </>
  );
};

export default ActualPriceModal;

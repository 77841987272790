import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';

interface SimpleInputProps {
  name: string;
  placeholder: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  borderRadius?: number;
  maxHeight?: number;
  fontSize?: number;
  width?: number;
  height?: number;
  readOnly?: boolean;
  type?: string;
}

const NoneStyleMultilineTextArea = forwardRef<HTMLInputElement, SimpleInputProps>(
  ({ name, placeholder, value, onChange, onFocus, fontSize = 16 }, ref) => (
    <TextField
      inputRef={ref}
      variant="standard"
      margin="normal"
      required
      fullWidth
      multiline
      name={name}
      autoComplete={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
      }}
      style={{
        fontSize: `${fontSize}px`,
        width: '100%',
        margin: '0px',
      }}
    />
  ),
);

export default NoneStyleMultilineTextArea;

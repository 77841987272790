import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import { postProductInquiry } from '@/api/public';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import MultiSelectBox from '@/components/Common/Input/SelectBox/MultiSelectBox';
import SliderComponent from '@/components/Common/Input/Slider/SliderComponent';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import BottomSheetPopup from '@/components/Common/Popup/BottomSheetPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS } from '@/const/errorCode';
import {
  COMMON_TOAST_ERROR,
  DISTANCE_IS_LESS_THAN_2_MILLION,
  MAX_IS_BIGGER_ERROR,
  MIN_IS_SMALLER_ERROR,
  NAME_VALIDATION_LENGTH_MESSAGE,
  NAME_VALIDATION_MESSAGE,
  PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE,
  TONS_IS_LESS_THAN_27,
  TONS_IS_OVER_1,
  YEAR_IS_LESS_THAN_CURRENT,
  YEAR_IS_OVER_2000,
} from '@/const/errorMessage';
import { positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { formatValueToDecimalPlaces, getCurrentYear, updateSearchParams } from '@/utils/common';
import { Validation, validateLength, validateName, validatePhoneNumber } from '@/utils/validation';

const ProductsPurchasingInquiry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentYear = getCurrentYear();

  const isClicked = useRef<boolean>(false);
  const { showToast } = useToastContext();
  const { member } = useMemberContext();

  const [title, setTitle] = useState('');
  const [isShow, setIsShow] = useState(false);

  const [selectedManufacturerCategories, setSelectedManufacturerCategories] = useState<OptionDataType[]>([]);
  const [selectedLoadeds, setSelectedLoadeds] = useState<OptionDataType[]>([]);

  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  const [loadedMinInnerLengthError, setLoadedMinInnerLengthError] = useState(false);
  const [loadedMaxInnerLengthError, setLoadedMaxInnerLengthError] = useState(false);
  const [loadedMinInnerLengthErrorMsg, setLoadedMinInnerLengthErrorMsg] = useState('');
  const [loadedMaxInnerLengthErrorMsg, setLoadedMaxInnerLengthErrorMsg] = useState('');
  const [minYearError, setMinYearError] = useState(false);
  const [maxYearError, setMaxYearError] = useState(false);
  const [minTonsError, setMinTonsError] = useState(false);
  const [maxTonsError, setMaxTonsError] = useState(false);
  const [minDistanceError, setMinDistanceError] = useState(false);
  const [maxDistanceError, setMaxDistanceError] = useState(false);
  const [minYearErrorMsg, setMinYearErrorMsg] = useState('');
  const [maxYearErrorMsg, setMaxYearErrorMsg] = useState('');
  const [minTonsErrorMsg, setMinTonsErrorMsg] = useState('');
  const [maxTonsErrorMsg, setMaxTonsErrorMsg] = useState('');
  const [minDistanceErrorMsg, setMinDistanceErrorMsg] = useState('');
  const [maxDistanceErrorMsg, setMaxDistanceErrorMsg] = useState('');

  const settingParams = location?.state?.data;
  const [settingOption, setSettingOption] = useState<ProductPurchasingInquiry>({
    name: !!member?.name ? member.name : '',
    requestPhoneNumber: !!member?.phoneNumber ? member.phoneNumber : '',
    manufacturerCategoriesId: settingParams?.manufacturerCategories?.id,
    minYear: settingParams?.minYear || 2000,
    maxYear: settingParams?.maxYear || currentYear + 1,
    minTons: settingParams?.minTons || 1,
    maxTons: settingParams?.maxTons || 27,
    minDistance: settingParams?.minDistance || '0',
    maxDistance: settingParams?.maxDistance || 200,
    axis: settingParams?.axis,
    transmission: settingParams?.transmission,
    loaded: settingParams?.loaded,
    minLoadedInnerLength: settingParams?.minLoadedInnerLength,
    maxLoadedInnerLength: settingParams?.maxLoadedInnerLength,
  });

  const [axisOptionData, setAxisOptionData] = useState<OptionType[]>([]);
  const [transmissionOptionData, setTransmissionOptionData] = useState<OptionType[]>([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    content: null,
    onClose: () => {},
    leftButtonLabel: '',
    rightButtonLabel: '',
    onLeftButtonClick: () => {},
    onRightButtonClick: () => {},
  });

  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const validateMemberName = (name: string) => {
    if (name == null) {
      setNameErrorMessage(NAME_VALIDATION_MESSAGE);
      setNameError(true);
    } else {
      if (name.length > 20) {
        setNameErrorMessage(NAME_VALIDATION_LENGTH_MESSAGE);
        setNameError(true);
      } else if (validateName(name) === false) {
        setNameErrorMessage(NAME_VALIDATION_MESSAGE);
        setNameError(true);
      } else {
        setNameErrorMessage('');
        setNameError(false);
      }
    }
  };

  const validateMemberPhoneNumber = (phoneNumber: string) => {
    const phoneNumberValidation = validatePhoneNumber(phoneNumber) as Validation;
    if (phoneNumber.length > 11) {
      setPhoneNumberErrorMessage(PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE);
      setPhoneNumberError(true);
    } else if (phoneNumberValidation.isValid === false) {
      setPhoneNumberErrorMessage(phoneNumberValidation.errorMessage);
      setPhoneNumberError(true);
    } else {
      setPhoneNumberErrorMessage('');
      setPhoneNumberError(false);
    }
  };

  useEffect(() => {
    if (member) {
      const phoneNumber = member.phoneNumber;
      const name = member.name;

      validateMemberName(name);
      validateMemberPhoneNumber(phoneNumber);

      setSettingOption({
        ...settingOption,
        name: nameError ? '' : name,
        requestPhoneNumber: phoneNumberError ? '' : phoneNumber,
      });
    }
  }, [member]);

  const keyValueList: KeyValueListType = {
    manufacturerCategories: '제조사',
    loaded: '적재함 종류',
  };

  const clear = () => {
    setSettingOption({
      name: !!member?.name ? member.name : '',
      requestPhoneNumber: !!member?.phoneNumber ? member.phoneNumber : '',
      manufacturerCategoriesId: { code: '', name: '', desc: '' },
      minYear: 2000,
      maxYear: currentYear + 1,
      minTons: 1,
      maxTons: 27,
      minDistance: '0',
      maxDistance: 200,
      axis: { code: '', desc: '' },
      transmission: { code: '', desc: '' },
      loaded: { code: '', desc: '' },
      minLoadedInnerLength: null,
      maxLoadedInnerLength: null,
    });
    setSelectedManufacturerCategories([]);
    setSelectedLoadeds([]);

    setLoadedMaxInnerLengthError(false);
    setLoadedMinInnerLengthError(false);
  };

  const onChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    validateMemberName(value);
    setSettingOption((prev) => ({ ...prev, name: value }));
  }, []);

  const onChangePhoneNumber = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    validateMemberPhoneNumber(value);
    setSettingOption((prev) => ({ ...prev, requestPhoneNumber: value }));
  }, []);

  const onClickRightBtn = () => {
    const requestData: Partial<ProductPurchasingInquiryRequest> = {
      axis: settingOption.axis ? settingOption.axis.code : null,
      loaded: settingOption.loaded.code === '' ? null : settingOption.loaded.code,
      maxDistance: settingOption.maxDistance ? Number(settingOption.maxDistance) : null,
      maxLoadedInnerLength: settingOption.maxLoadedInnerLength,
      maxTons: settingOption.maxTons,
      maxYear: settingOption.maxYear,
      minDistance: settingOption.minDistance ? Number(settingOption.minDistance) : null,
      minLoadedInnerLength: settingOption.minLoadedInnerLength,
      manufacturerCategoriesId: settingOption.manufacturerCategoriesId?.id,
      minTons: settingOption.minTons,
      minYear: settingOption.minYear,
      transmission: settingOption.transmission ? settingOption.transmission.code : null,
    };

    if (Number(requestData.minTons) === 1 && Number(requestData.maxTons === 27)) {
      delete requestData.minTons;
      delete requestData.maxTons;
    }
    if (Number(requestData.minYear) === 2000 && Number(requestData.maxYear) === currentYear + 1) {
      delete requestData.minYear;
      delete requestData.maxYear;
    }
    if (Number(requestData.minDistance) === 0 && Number(requestData.maxDistance) === 200) {
      delete requestData.minDistance;
      delete requestData.maxDistance;
    }

    apiManager
      .post(`/api/v1/interest-product-notification-settings`, requestData)
      .then((response) => {
        showToast('차량 입고 알림이 등록되었어요.', 'success', 'bottom');
      })
      .catch((error) => {
        if (error && error.code === ALREADY_INTEREST_PRODUCT_NOTIFICATION_SETTINGS) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      });

    handleCloseModal();
  };

  useEffect(() => {
    apiManager
      .get('/api/v1/public/products/filter-info')
      .then((response) => {
        const responseData: ProductsFilterInfo = response.data;
        setFilterInfo(responseData);

        const transformedAxisData = responseData.axis.map((item) => ({
          code: item.axis.code,
          desc: item.axis.desc,
        }));
        setAxisOptionData(transformedAxisData);

        const transformedTransmissionData = responseData.transmission.map((item) => ({
          code: item.transmission.code,
          desc: item.transmission.desc,
        }));
        setTransmissionOptionData(transformedTransmissionData);

        // const selectedLoadedItems = filterData(responseData.loaded, settingOption.loaded, true);
        // setSelectedLoadeds(selectedLoadedItems);

        // const selectedManufacturerItems = filterData(
        //   responseData.manufacturerCategories,
        //   settingOption.manufacturerCategoriesId,
        //   false,
        // );
        // setSelectedManufacturerCategories(selectedManufacturerItems);
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  }, []);

  const onClickItem = (item: OptionDataType, key: string) => {
    switch (key) {
      case 'manufacturerCategories':
        if (
          selectedManufacturerCategories.includes(item) ||
          selectedManufacturerCategories.some((selectedItem) => selectedItem.code === item.code)
        ) {
          return;
        } else {
          if (selectedManufacturerCategories?.length < 1) {
            setSelectedManufacturerCategories((prevItems) => {
              return [...prevItems, item];
            });
          } else {
            setSelectedManufacturerCategories(() => {
              return [item];
            });
          }
          setTimeout(() => {
            setSettingOption({
              ...settingOption,
              manufacturerCategoriesId: item as OptionDataType,
            });
            setIsShow(false);
          }, 200);
        }
        break;
      case 'loaded':
        if (selectedLoadeds.includes(item) || selectedLoadeds.some((selectedItem) => selectedItem.code === item.code)) {
          return;
        } else {
          if (selectedLoadeds?.length < 1) {
            setSelectedLoadeds((prevItems) => {
              return [...prevItems, item];
            });
          } else {
            setSelectedLoadeds(() => {
              return [item];
            });
          }
          setTimeout(() => {
            setSettingOption({
              ...settingOption,
              loaded: item as EnumPresenter,
            });
            setIsShow(false);
          }, 200);
        }
        break;
    }
  };

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber: boolean) => {
    const { name, value } = e.target;
    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(value) && !value.startsWith('-')) {
        const str = value === '' ? null : value;
        setSettingOption({ ...settingOption, [name]: str });

        switch (name) {
          case 'minYear':
            if (Number(value) > Number(settingOption.maxYear)) {
              setMinYearError(true);
              setMinYearErrorMsg(MIN_IS_SMALLER_ERROR);
              return;
            } else if (Number(value) < 2000) {
              setMinYearError(true);
              setMinYearErrorMsg(YEAR_IS_OVER_2000);
              return;
            } else {
              setMinYearError(false);
              setMinYearErrorMsg('');
            }
            break;
          case 'maxYear':
            if (Number(value) < Number(settingOption.minYear)) {
              setMaxYearError(true);
              setMaxYearErrorMsg(MAX_IS_BIGGER_ERROR);
              return;
            } else if (Number(value) > currentYear + 1) {
              setMaxYearError(true);
              setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
              return;
            } else {
              setMaxYearError(false);
              setMaxYearErrorMsg('');
            }
            break;
          case 'minDistance':
            if (!settingOption.maxDistance) return;

            if (Number(value) > Number(settingOption.maxDistance)) {
              setMinDistanceError(true);
              setMinDistanceErrorMsg(MIN_IS_SMALLER_ERROR);
              return;
            } else if (Number(value) > 200) {
              setMinDistanceError(true);
              setMinDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
              return;
            } else {
              setMinDistanceError(false);
              setMinDistanceErrorMsg('');
            }
            break;
          case 'maxDistance':
            if (!settingOption.minDistance) return;
            if (Number(value) < Number(settingOption.minDistance)) {
              setMaxDistanceError(true);
              setMaxDistanceErrorMsg(MAX_IS_BIGGER_ERROR);
              return;
            } else if (Number(value) > 200) {
              setMaxDistanceError(true);
              setMaxDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
              return;
            } else {
              setMaxDistanceError(false);
              setMaxDistanceErrorMsg('');
            }
            break;
        }
      }
    } else if (positiveFloatRegex.test(value.trim()) || value.trim() === '') {
      const str = value.trim() === '' ? null : value.trim();
      setSettingOption({ ...settingOption, [name]: str });

      switch (name) {
        case 'minTons':
          if (Number(value) > Number(settingOption.maxTons)) {
            setMinTonsError(true);
            setMinTonsErrorMsg(MIN_IS_SMALLER_ERROR);
            return;
          } else if (Number(value) < 1) {
            setMinTonsError(true);
            setMinTonsErrorMsg(TONS_IS_OVER_1);
            return;
          } else {
            setMinTonsError(false);
            setMinTonsErrorMsg('');
          }
          break;
        case 'maxTons':
          if (Number(value) < Number(settingOption.minTons)) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(MAX_IS_BIGGER_ERROR);
            return;
          } else if (Number(value) > 27) {
            setMaxTonsError(true);
            setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
            return;
          } else {
            setMaxTonsError(false);
            setMaxTonsErrorMsg('');
          }
          break;
      }
    }
  };

  const onChangeSliderInput = (e: Event, values: number | number[], key: string) => {
    if (!Array.isArray(values)) {
      return;
    }
    setSettingOption({ ...settingOption, [`min${key}`]: values[0].toString(), [`max${key}`]: values[1].toString() });

    switch (key) {
      case 'Year':
        setMinYearError(false);
        setMinYearErrorMsg('');
        setMaxYearError(false);
        setMaxYearErrorMsg('');
        break;
      case 'Distance':
        setMinDistanceError(false);
        setMinDistanceErrorMsg('');
        setMaxDistanceError(false);
        setMaxDistanceErrorMsg('');
        break;
      case 'Tons':
        setMinTonsError(false);
        setMinTonsErrorMsg('');
        setMaxTonsError(false);
        setMaxTonsErrorMsg('');
        break;
    }
  };

  const removeItem = (item: OptionDataType, key: string) => {
    if (key === 'loaded') {
      setSelectedLoadeds([]);
      setSettingOption({ ...settingOption, loaded: { code: '', desc: '' } });
    } else if (key === 'manufacturerCategoriesId') {
      setSelectedManufacturerCategories([]);
      setSettingOption({ ...settingOption, manufacturerCategoriesId: { code: '', desc: '' } });
    }
  };

  const [filterInfo, setFilterInfo] = useState<ProductsFilterInfo>({
    axis: [{ axis: { code: '', desc: '' }, count: 0 }],
    loaded: [{ loaded: { code: '', desc: '' }, count: 0 }],
    manufacturerCategories: [{ manufacturerCategories: { id: 0, code: '', name: '' }, count: 0 }],
    transmission: [{ transmission: { code: '', desc: '' }, count: 0 }],
  });

  const [optionData, setOptionData] = useState<FilterDataType[]>([]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
    navigate('/');
  };

  const onClickUpdateSetting = () => {
    createProductInquiry(settingOption);
  };

  const onClickSelectBox = (key: string) => {
    if (!filterInfo) {
      return;
    }
    switch (key) {
      case 'manufacturerCategories':
        if (filterInfo.manufacturerCategories) {
          setOptionData(
            filterInfo?.manufacturerCategories.map(
              (item) =>
                ({
                  id: item?.manufacturerCategories.id,
                  code: item?.manufacturerCategories?.code,
                  desc: item?.manufacturerCategories?.name,
                } as FilterDataType),
            ),
          );
        }
        break;
      case 'loaded':
        setOptionData(
          filterInfo.loaded.map(
            (item) =>
              ({
                code: item?.loaded?.code,
                desc: item?.loaded?.desc,
              } as FilterDataType),
          ),
        );
        break;
    }
    setTitle(key);
    setIsShow(true);
  };

  const createProductInquiry = (settingOption: ProductPurchasingInquiry) => {
    if (isClicked.current === true) {
      return;
    }
    isClicked.current = true;

    const requestData: ProductPurchasingInquiryRequest = {
      memberId: member?.id,
      name: settingOption?.name,
      requestPhoneNumber: settingOption?.requestPhoneNumber,
      axis: settingOption.axis ? settingOption.axis?.code : null,
      loaded: settingOption.loaded?.code === '' ? null : settingOption.loaded?.code,
      maxDistance: settingOption.maxDistance ? Number(settingOption.maxDistance) : null,
      maxLoadedInnerLength: settingOption.maxLoadedInnerLength,
      maxTons: settingOption.maxTons,
      maxYear: settingOption.maxYear,
      minDistance: settingOption.minDistance ? Number(settingOption.minDistance) : null,
      minLoadedInnerLength: settingOption.minLoadedInnerLength,
      manufacturerCategoriesId: settingOption.manufacturerCategoriesId?.id,
      minTons: settingOption.minTons,
      minYear: settingOption.minYear,
      transmission: settingOption.transmission ? settingOption.transmission.code : null,
    };

    if (Number(requestData.minTons) === 1 && Number(requestData.maxTons === 27)) {
      delete requestData.minTons;
      delete requestData.maxTons;
    }
    if (Number(requestData.minYear) === 2000 && Number(requestData.maxYear) === currentYear + 1) {
      delete requestData.minYear;
      delete requestData.maxYear;
    }
    if (Number(requestData.minDistance) === 0 && Number(requestData.maxDistance) === 200) {
      delete requestData.minDistance;
      delete requestData.maxDistance;
    }

    postProductPurchasingInquiryMutation.mutate(requestData);
  };

  const postProductPurchasingInquiryMutation = useMutation(
    (request: ProductPurchasingInquiryRequest) => postProductInquiry(request),
    {
      onSuccess: (response) => {
        if (response) {
          if (member?.id) {
            setIsOpenModal(true);
            setModalConfig({
              content: (
                <span>
                  알림 받기를 누르면 해당 차량이
                  <br />
                  입고되었을 때 알림을 보내드려요.
                </span>
              ),
              rightButtonLabel: '알림받기',
              onRightButtonClick: onClickRightBtn,
            });
          } else {
            setIsOpenModal(true);
            setModalConfig({
              content: (
                <span className="font-medium text-blue-8">
                  알림을 받으려면 로그인이 필요해요.
                  <br />
                  지금 바로 로그인하고,
                  <br />
                  원하는 차량 정보를 받아보세요!
                </span>
              ),
              rightButtonLabel: '로그인하기',
              onRightButtonClick: () => navigate('/login'),
            });
          }
        }
      },
      onError: (error: AxiosError) => {
        if (error && error.code === 'BAD_REQUEST') {
          showToast('필수값이 존재하지 않습니다.', 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setSettingOption({ ...settingOption, [key]: newVal });
  };

  useEffect(() => {
    if (settingOption.minYear && settingOption.maxYear) {
      if (Number(settingOption.minYear) <= Number(settingOption.maxYear)) {
        setMinYearError(false);
        setMinYearErrorMsg('');
        setMaxYearError(false);
        setMaxYearErrorMsg('');
      }
      if (Number(settingOption.minYear) < 2000) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(settingOption.minYear) > currentYear + 1) {
        setMinYearError(true);
        setMinYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
      if (Number(settingOption.maxYear) < 2000) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_OVER_2000);
      }
      if (Number(settingOption.maxYear) > currentYear + 1) {
        setMaxYearError(true);
        setMaxYearErrorMsg(YEAR_IS_LESS_THAN_CURRENT);
      }
    }
  }, [settingOption.minYear, settingOption.maxYear]);

  useEffect(() => {
    if (settingOption.minTons && settingOption.maxTons) {
      if (Number(settingOption.minTons) <= Number(settingOption.maxTons)) {
        setMinTonsError(false);
        setMinTonsErrorMsg('');
        setMaxTonsError(false);
        setMaxTonsErrorMsg('');
      }
      if (Number(settingOption.minTons) < 1) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(settingOption.maxTons) < 1) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_OVER_1);
      }
      if (Number(settingOption.minTons) > 27) {
        setMinTonsError(true);
        setMinTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
      if (Number(settingOption.maxTons) > 27) {
        setMaxTonsError(true);
        setMaxTonsErrorMsg(TONS_IS_LESS_THAN_27);
      }
    }
  }, [settingOption.minTons, settingOption.maxTons]);

  useEffect(() => {
    if (settingOption.minDistance && settingOption.maxDistance) {
      if (Number(settingOption.minDistance) <= Number(settingOption.maxDistance)) {
        setMinDistanceError(false);
        setMinDistanceErrorMsg('');
        setMaxDistanceError(false);
        setMaxDistanceErrorMsg('');
      }
      if (Number(settingOption.minDistance) > 200) {
        setMinDistanceError(true);
        setMinDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
      }
      if (Number(settingOption.maxDistance) > 200) {
        setMaxDistanceError(true);
        setMaxDistanceErrorMsg(DISTANCE_IS_LESS_THAN_2_MILLION);
      }
    }
  }, [settingOption.minDistance, settingOption.maxDistance]);

  useEffect(() => {
    if (
      settingOption.name &&
      settingOption.requestPhoneNumber &&
      settingOption.loaded?.code &&
      nameError === false &&
      phoneNumberError === false
    ) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [settingOption.name, settingOption.requestPhoneNumber, settingOption.loaded]);

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      const { updatedParams, error, errorMsg } = updateSearchParams(settingOption, key, valueNum);

      setSettingOption(updatedParams);

      switch (key) {
        case 'minLoadedInnerLength':
          setLoadedMinInnerLengthError(error);
          setLoadedMinInnerLengthErrorMsg(errorMsg);
          break;
        case 'maxLoadedInnerLength':
          setLoadedMaxInnerLengthError(error);
          setLoadedMaxInnerLengthErrorMsg(errorMsg);
          break;
      }
    }
  };

  const handleChangeLoadedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = formatValueToDecimalPlaces(value, 1);
    const isInvalidInput = formattedValue.startsWith('0') && formattedValue.length > 1;

    if (isInvalidInput) {
      setSettingOption({ ...settingOption, [name]: '0' });
      return;
    }

    const oppositeLength =
      name === 'minLoadedInnerLength' ? settingOption.maxLoadedInnerLength : settingOption.minLoadedInnerLength;
    const isMin = name === 'minLoadedInnerLength';
    const { isValid, errorMessage, bothValuesValid } = validateLength(formattedValue, 1, 10.5, oppositeLength, isMin);

    const updateErrorState = (isError: boolean, message: string) => {
      if (isMin) {
        setLoadedMinInnerLengthError(isError);
        setLoadedMinInnerLengthErrorMsg(message);
      } else {
        setLoadedMaxInnerLengthError(isError);
        setLoadedMaxInnerLengthErrorMsg(message);
      }
    };

    if (!isValid) {
      updateErrorState(true, errorMessage);
    } else {
      updateErrorState(false, '');
    }

    if (bothValuesValid) {
      setLoadedMinInnerLengthError(false);
      setLoadedMinInnerLengthErrorMsg('');
      setLoadedMaxInnerLengthError(false);
      setLoadedMaxInnerLengthErrorMsg('');
    }

    if (positiveFloatRegex.test(formattedValue.trim()) || formattedValue.trim() === '') {
      setSettingOption({ ...settingOption, [name]: formattedValue.trim() === '' ? null : formattedValue.trim() });
    }
  };

  return (
    <>
      <MenuHeader title="차량 구매문의"></MenuHeader>
      <div className="w-full pt-[60px] pb-[66px]">
        <div className="p-4 m-4 rounded-lg bg-blue-0 text-gray-8">
          <p className="text-lg font-bold">차량 구매 문의를 남겨주세요.</p>
          <p className="mt-3 text-sm">빠른 상담을 위해 구매를 원하시는 차량의 정보를 입력해주세요.</p>
        </div>
        <div className="flex flex-col gap-[30px] px-4">
          <TextInputLabelUp
            name="name"
            label="신청자명"
            placeholder="신청자명을 입력해주세요"
            value={settingOption?.name || ''}
            onChange={onChangeName}
            type="text"
            error={nameError}
            errorMsg={nameErrorMessage}
            required={true}
          ></TextInputLabelUp>

          <TextInputLabelUp
            name="name"
            label="휴대폰 번호"
            placeholder="휴대폰 번호를 입력해주세요"
            value={settingOption?.requestPhoneNumber}
            onChange={onChangePhoneNumber}
            error={phoneNumberError}
            errorMsg={phoneNumberErrorMessage}
            required={true}
          ></TextInputLabelUp>
        </div>
        <hr className="mt-6 border-4 border-gray-1"></hr>
        <div className="min-[280px]">
          <div className="text-base border-b border-gray-3">
            <div className="flex flex-col justify-center px-4 pt-4 pb-4">
              <p className="font-semibold text-gray-8">
                적재함 종류<span className="font-normal text-red">(필수)</span>
              </p>
              <MultiSelectBox
                label="적재함 종류 선택"
                placeholder="적재함 종류 선택"
                items={selectedLoadeds}
                setItems={setSelectedLoadeds}
                onClickDeleteBtn={removeItem}
                itemName="loaded"
                tagWidth={170}
                onClickSelectBox={onClickSelectBox}
                selectBoxKey="loaded"
              />
            </div>
          </div>

          <div className="text-base border-b border-gray-3 px-4 py-[30px] relative">
            <p className="mb-2 font-semibold text-gray-8">적재함 길이 (내측 사이즈)</p>
            <div className="flex items-center justify-center mb-2">
              <TextInputLabelOff
                name="minLoadedInnerLength"
                placeholder="최소 길이"
                value={String(settingOption?.minLoadedInnerLength || '')}
                onChange={(e) => handleChangeLoadedInput(e)}
                onBlur={(e) => handleInputBlur(e, 'minLoadedInnerLength')}
                error={loadedMinInnerLengthError}
                errorMsg={loadedMinInnerLengthErrorMsg}
                height={36}
                fontSize={18}
                suffix="m"
                maxLength={4}
              ></TextInputLabelOff>
              <p className="mx-2 text-base text-gray-6">~</p>
              <TextInputLabelOff
                name="maxLoadedInnerLength"
                placeholder="최대 길이"
                value={String(settingOption?.maxLoadedInnerLength || '')}
                onChange={(e) => handleChangeLoadedInput(e)}
                onBlur={(e) => handleInputBlur(e, 'maxLoadedInnerLength')}
                error={loadedMaxInnerLengthError}
                errorMsg={loadedMaxInnerLengthErrorMsg}
                height={36}
                fontSize={18}
                suffix="m"
                maxLength={4}
              ></TextInputLabelOff>
            </div>
          </div>

          <div className="text-base border-b border-gray-3 px-4 py-[30px]">
            <p className="font-semibold text-gray-8">연식</p>
            <SliderComponent
              name="Year"
              value={[Number(settingOption.minYear), Number(settingOption.maxYear)]}
              onChange={(event, value) => onChangeSliderInput(event, value, 'Year')}
              step={1}
              min={2000}
              max={currentYear + 1}
            ></SliderComponent>
            <div className="flex items-center justify-center">
              <TextInputLabelOff
                name="minYear"
                placeholder="최소 연식"
                value={String(settingOption?.minYear || '')}
                inputMode="numeric"
                onChange={(e) => onChangeFormInput(e, true)}
                error={minYearError}
                errorMsg={minYearErrorMsg}
                height={36}
                fontSize={18}
                maxLength={4}
              ></TextInputLabelOff>
              <p className="mx-2 text-base text-gray-6">~</p>
              <TextInputLabelOff
                name="maxYear"
                placeholder="최대 연식"
                value={String(settingOption?.maxYear || '')}
                inputMode="numeric"
                onChange={(e) => onChangeFormInput(e, true)}
                error={maxYearError}
                errorMsg={maxYearErrorMsg}
                height={36}
                fontSize={18}
                maxLength={4}
              ></TextInputLabelOff>
            </div>
          </div>

          <div className="text-base border-b border-gray-3 px-4 py-[30px]">
            <p className="font-semibold text-gray-8">톤수</p>
            <SliderComponent
              name="Tons"
              value={[Number(settingOption.minTons), Number(settingOption.maxTons)]}
              onChange={(e, value) => onChangeSliderInput(e, value, 'Tons')}
              step={1}
              min={1}
              max={27}
            ></SliderComponent>
            <div className="flex items-center justify-center">
              <TextInputLabelOff
                name="minTons"
                placeholder="최소 톤수"
                value={String(settingOption?.minTons || '')}
                inputMode="numeric"
                onChange={(e) => onChangeFormInput(e, false)}
                error={minTonsError}
                errorMsg={minTonsErrorMsg}
                height={36}
                fontSize={18}
                suffix="t"
                maxLength={4}
              ></TextInputLabelOff>
              <p className="mx-2 text-base text-gray-6">~</p>
              <TextInputLabelOff
                name="maxTons"
                placeholder="최대 톤수"
                value={String(settingOption?.maxTons || '')}
                inputMode="numeric"
                onChange={(e) => onChangeFormInput(e, false)}
                error={maxTonsError}
                errorMsg={maxTonsErrorMsg}
                height={36}
                fontSize={18}
                suffix="t"
                maxLength={4}
              ></TextInputLabelOff>
            </div>
          </div>

          <div className="text-base border-b border-gray-3 px-4 py-[30px]">
            <p className="mb-2 font-semibold text-gray-8">가변축</p>
            <div className="px-1">
              <RadioList
                name="axis"
                list={axisOptionData}
                value={settingOption.axis?.code || ''}
                onChange={(val) => {
                  onChangeRadioInput(val, 'axis');
                }}
              ></RadioList>
            </div>
          </div>

          <div className="text-base border-b border-gray-3">
            <div className="flex flex-col justify-center px-4 pt-4 pb-4">
              <p className="font-semibold text-gray-8">제조사</p>
              <MultiSelectBox
                label="제조사 선택"
                placeholder="제조사 선택"
                items={selectedManufacturerCategories}
                setItems={setSelectedManufacturerCategories}
                onClickDeleteBtn={removeItem}
                itemName="manufacturerCategoriesId"
                tagWidth={120}
                onClickSelectBox={onClickSelectBox}
                selectBoxKey="manufacturerCategories"
              />
            </div>
          </div>

          <div className="text-base border-b border-gray-3 px-4 py-[30px]">
            <p className="font-semibold text-gray-8">주행거리</p>
            <SliderComponent
              name="Distance"
              value={[Number(settingOption?.minDistance) || 0, Number(settingOption?.maxDistance) || 200]}
              onChange={(event, value) => onChangeSliderInput(event, value, 'Distance')}
              step={5}
              min={0}
              max={200}
            ></SliderComponent>
            <div className="flex justify-center pb-3">
              <TextInputLabelOff
                name="minDistance"
                placeholder={`최소 주행거리`}
                value={String(settingOption.minDistance || '')}
                onChange={(e) => onChangeFormInput(e, true)}
                error={minDistanceError}
                errorMsg={minDistanceErrorMsg}
                inputMode="numeric"
                suffix="만km"
                maxLength={3}
              />
              <p className="text-[#919191] text-sm mx-2">~</p>
              <TextInputLabelOff
                name="maxDistance"
                placeholder={`최대 주행거리`}
                value={String(settingOption.maxDistance || '')}
                onChange={(e) => onChangeFormInput(e, true)}
                error={maxDistanceError}
                errorMsg={maxDistanceErrorMsg}
                inputMode="numeric"
                suffix="만km"
                maxLength={3}
              />
            </div>
          </div>

          <div className="text-base px-4 py-[30px]">
            <p className="mb-2 font-semibold text-gray-8">변속기</p>
            <div className="px-1">
              <RadioList
                name="transmission"
                list={transmissionOptionData}
                value={settingOption.transmission?.code || ''}
                onChange={(val) => {
                  onChangeRadioInput(val, 'transmission');
                }}
              ></RadioList>
            </div>
          </div>
        </div>
        <DualFooterButton
          leftButtonText="초기화"
          onClickLeftButton={clear}
          rightButtonText="문의 남기기"
          onClickRightButton={onClickUpdateSetting}
          disabledRight={isDisabledBtn}
        />
        <BottomSheetPopup isOpen={isShow} onClose={() => setIsShow(false)} title={keyValueList[title]}>
          {(title === 'manufacturerCategories' || title === 'loaded') && (
            <>
              <div className="px-4 pb-8">
                <RadioList
                  name={title}
                  list={optionData}
                  value={
                    title === 'manufacturerCategories'
                      ? selectedManufacturerCategories[0]?.code || ''
                      : title === 'loaded'
                      ? selectedLoadeds[0]?.code || ''
                      : ''
                  }
                  onChange={(val) => {
                    onClickItem(val, title);
                  }}
                  horizontal={false}
                ></RadioList>
              </div>
            </>
          )}
        </BottomSheetPopup>
      </div>
      <ConfirmModal
        isOpen={isOpenModal}
        title="구매 문의 등록 완료"
        content={modalConfig.content}
        leftButtonLabel="닫기"
        rightButtonLabel={modalConfig.rightButtonLabel}
        onLeftButtonClick={handleCloseModal}
        onRightButtonClick={modalConfig.onRightButtonClick}
      />
    </>
  );
};

export default ProductsPurchasingInquiry;

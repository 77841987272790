import React from 'react';

import { CheckCircleOutlineIcon, CheckCircleSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { Checkbox } from '@mui/material';

interface CheckBoxComponentProps {
  checked: boolean;
}

const CheckBoxComponent = ({ checked = false }: CheckBoxComponentProps) => {
  return (
    <>
      <Checkbox
        icon={<CheckCircleOutlineIcon color={colors.gray[3]} />}
        checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
        sx={{
          borderWidth: '4px',
          fontSize: '14px',
          borderRadius: '10px',
          paddingX: '0px',
        }}
        checked={checked}
      />
    </>
  );
};

export default CheckBoxComponent;

import React, { forwardRef, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { postProductInquiryCall } from '@/api/public';
import { ChevronLeftIcon, PencilIcon, PhoneRingSolidIcon, TrashIcon } from '@/components/Icon';
import ProductStatus from '@/components/More/ProductStatus';
import { colors } from '@/const/colors';
import {
  CONTRACT_STATUS_BEFORE,
  CONTRACT_STATUS_COMPLETED,
  CONTRACT_STATUS_IN_PROGRESS,
  TRANSFEREE,
  TRANSFEROR,
} from '@/const/contract';
import { DELETED, SALE } from '@/const/productStatus';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { formatPrice, phoneCall, truncateText } from '@/utils/common';

type HeaderProps = {
  chatRoomData: ChattingDetailResponse | undefined;
  onClickDeleteBtn: () => void;
  path?: string;
  actionFunction?: (() => void) | undefined;
};

const ChatRoomHeader = forwardRef<HTMLDivElement, HeaderProps>(
  ({ chatRoomData, onClickDeleteBtn, path, actionFunction }, ref) => {
    const navigate = useNavigate();
    const { member } = useMemberContext();
    const { showToast } = useToastContext();
    const [contractWriterType, setContractWriterType] = useState<string>('');
    const [contractStatus, setContractStatus] = useState('');

    const isBuyer = member?.id === chatRoomData?.buyer?.id;
    const recipient = isBuyer ? chatRoomData?.seller : chatRoomData?.buyer;

    const isProductDeleted = () => chatRoomData?.isDeletedProduct;

    const onClickPhoneCall = () => {
      if (chatRoomData?.id) {
        postProductInquiryCallMutation.mutate(chatRoomData.productId);
        const phoneNumber = chatRoomData?.safetyNumbers?.safetyNumber;
        phoneCall(phoneNumber);
      } else {
        showToast(
          `${truncateText(recipient?.name, 3)}님에게 메시지를 보낸 후, 전화를 하실 수 있어요.`,
          'success',
          'bottom',
        );
      }
    };

    const postProductInquiryCallMutation = useMutation((productId: number) => postProductInquiryCall(productId), {
      onSuccess: () => {},
      onError: () => {},
    });

    const onClickBtn = () => {
      if (actionFunction) {
        actionFunction();
      } else if (path) {
        navigate(path);
      } else {
        navigate(-1);
      }
    };

    useEffect(() => {
      const contractWriterType = member?.id === chatRoomData?.seller.id ? TRANSFEROR : TRANSFEREE;

      if (!chatRoomData?.transferorCompleted && !chatRoomData?.transfereeCompleted) {
        setContractStatus(CONTRACT_STATUS_BEFORE);
      } else if (chatRoomData?.transferorCompleted && !chatRoomData?.transfereeCompleted) {
        setContractStatus(CONTRACT_STATUS_IN_PROGRESS);
      } else if (chatRoomData?.transferorCompleted && chatRoomData?.transfereeCompleted) {
        setContractStatus(CONTRACT_STATUS_COMPLETED);
      }

      setContractWriterType(contractWriterType);
    }, [chatRoomData]);

    const onClickMove = (e: React.MouseEvent, key: string) => {
      e.stopPropagation();
      if (key === 'contract') {
        if (chatRoomData?.id) {
          navigate(`/${key}`, { state: { chatRoomId: chatRoomData.id, contractWriterType } });
        } else {
          showToast(
            `${truncateText(recipient?.name, 3)}님에게 메시지를 보낸 후, 계약서를 작성할 수 있어요.`,
            'success',
            'bottom',
          );
        }
      } else {
        navigate(`/${key}`);
      }
    };

    const getContractStatusBtn = () => {
      switch (contractStatus) {
        case CONTRACT_STATUS_BEFORE:
          return (
            <>
              <span>안심 전자 계약서 작성하기</span>
              <PencilIcon color={colors.gray[8]} width={12} height={12}></PencilIcon>
            </>
          );
        case CONTRACT_STATUS_IN_PROGRESS:
          if (isBuyer) {
            return (
              <>
                <span>안심 전자 계약서 작성하기</span>
                <PencilIcon color={colors.gray[8]} width={12} height={12}></PencilIcon>
              </>
            );
          } else {
            return (
              <>
                <span>안심 전자 계약서 보기</span>
                <PencilIcon color={colors.gray[8]} width={12} height={12}></PencilIcon>
              </>
            );
          }

        case CONTRACT_STATUS_COMPLETED:
          return (
            <>
              <span>안심 전자 계약서 보기</span>
            </>
          );
        default:
          return '안심 전자계약서 상태를 확인하세요.';
      }
    };

    return (
      <>
        <div
          ref={ref}
          className="fixed shrink-0 z-20 top-0 mx-auto left-0 right-0 w-full min-w-[280px] max-w-[720px] bg-white"
        >
          <div className="relative flex justify-between items-center p-4 z-30 shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]">
            <div className="flex">
              <div className="mr-4 cursor-pointer flex justify-center items-center" onClick={onClickBtn}>
                <ChevronLeftIcon color={colors.gray[9]}></ChevronLeftIcon>
              </div>
              <h1 className="flex items-center leading-[28px]">
                <span className="text-xl text-gray-8 font-semibold">{truncateText(recipient?.name, 3)}</span>
                <span className="text-gray-7 text-base xxs:hidden tracking-wider">[{chatRoomData?.truckNumber}]</span>
              </h1>
            </div>
            <div className="flex">
              {isBuyer && chatRoomData?.productStatus?.code === SALE && !chatRoomData?.isDeletedProduct ? (
                <div className="xxs:mx-1 xs:mx-1 cursor-pointer" onClick={onClickPhoneCall}>
                  <PhoneRingSolidIcon color={colors.primary}></PhoneRingSolidIcon>
                </div>
              ) : (
                ''
              )}
              <div className="ml-2" onClick={onClickDeleteBtn}>
                <TrashIcon color={colors.gray[8]}></TrashIcon>
              </div>
            </div>
          </div>
          <div className="pt-4 border-b relative bg-gray-0">
            <div
              className={`flex relative min-w-[250px] px-4 ${
                isProductDeleted() ? 'text-gray-5 cursor-default pb-5' : ''
              } `}
              onClick={() => {
                if (!isProductDeleted()) {
                  navigate(`/products/${chatRoomData?.productId}`);
                }
              }}
            >
              <div className="h-20 w-20">
                <img
                  src={chatRoomData?.productRepresentImageUrl}
                  alt="트럭이미지"
                  className="rounded-lg w-full h-full object-cover"
                ></img>
              </div>
              <div className="pl-4 flex-1 min-w-[160px]">
                <ProductStatus
                  status={isProductDeleted() ? { code: DELETED, desc: '삭제됨' } : chatRoomData?.productStatus}
                ></ProductStatus>

                <p className="font-semibold text-sm xxs:text-xs mt-2 text-ellipsis break-all line-clamp-1">
                  {chatRoomData?.truckName}
                </p>
                <p className="font-semibold text-base mt-1">{formatPrice(chatRoomData?.price)}</p>
              </div>
            </div>
            {!isProductDeleted() && (
              <div className="flex justify-end p-4">
                <div
                  className="flex justify-center items-center gap-[6px] bg-gray-2 rounded-[8px] px-3 py-2 w-auto text-sm font-semibold"
                  onClick={(e) => onClickMove(e, 'contract')}
                >
                  {getContractStatusBtn()}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  },
);

export default ChatRoomHeader;

import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { PASSWORD_NOT_MATCH_MESSAGE, PASSWORD_VALIDATION_MESSAGE } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import '@/css/tailwind.css';
import { validatePassword } from '@/utils/validation';

interface PasswordCheck {
  password: string;
}

interface UpdatePassword {
  newPassword: string;
}

const PasswordChange = () => {
  const navigate = useNavigate();
  const [passwordMessage, setPasswordMessage] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState<PasswordCheck>({ password: '' });
  const [updatePassword, setUpdatePassword] = useState<UpdatePassword>({ newPassword: '' });
  const [isCheckPassword, setIsCheckPassword] = useState(false);

  const [confirmedPasswordMessage, setConfirmedPasswordMessage] = useState('');
  const [errorConfirmedPassword, setErrorConfirmedPassword] = useState(false);

  const { showToast } = useToastContext();

  const isSuccessInputChange = useMemo(() => {
    return passwordCheck.password && !errorPassword && !errorConfirmedPassword;
  }, [passwordCheck.password, updatePassword.newPassword, confirmPassword]);

  const isSuccessChangePassword = useMemo(() => {
    return (
      passwordCheck.password &&
      updatePassword.newPassword &&
      confirmPassword &&
      !errorPassword &&
      !errorConfirmedPassword
    );
  }, [passwordCheck.password, updatePassword.newPassword, confirmPassword]);

  const handleInputChange = () => {
    apiManager
      .post('/api/v1/members/password/check', passwordCheck)
      .then((response) => {
        setIsCheckPassword(!isCheckPassword);
      })
      .catch((error) => {
        setPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorPassword(true);
      });
  };

  const handleChangeButton = () => {
    apiManager
      .patch('/api/v1/members', updatePassword)
      .then((response) => {
        if (response) {
          showToast('변경이 완료되었어요.', 'success', 'bottom');
          navigate('/setting');
        }
      })
      .catch((error) => {
        setPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorPassword(true);
      });
  };

  const onChangeCheckPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const isValidPassword = validatePassword(value);

      setPasswordMessage(isValidPassword ? '' : PASSWORD_VALIDATION_MESSAGE);
      setErrorPassword(!isValidPassword);
      setPasswordCheck({ ...passwordCheck, password: value });
    },
    [passwordCheck],
  );

  const onChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setUpdatePassword({ ...updatePassword, newPassword: value });

      const isValidPassword = validatePassword(value);
      setPasswordMessage(isValidPassword ? '' : PASSWORD_VALIDATION_MESSAGE);
      setErrorPassword(!isValidPassword);

      if (confirmPassword === '') return;

      const passwordsMatch = confirmPassword === value;
      setConfirmedPasswordMessage(passwordsMatch ? '' : PASSWORD_NOT_MATCH_MESSAGE);
      setErrorConfirmedPassword(!passwordsMatch);
    },
    [confirmPassword],
  );

  const confirmNewPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setConfirmPassword((prev) => value);
      if (updatePassword.newPassword === value) {
        setConfirmedPasswordMessage('');
        setErrorConfirmedPassword(false);
      } else {
        setConfirmedPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorConfirmedPassword(true);
      }
    },
    [updatePassword.newPassword],
  );

  return (
    <>
      <MenuHeader title="비밀번호 변경"></MenuHeader>
      <div className="w-full p-4 pt-[60px]">
        {isCheckPassword ? (
          <>
            <section className="flex flex-col gap-[30px] my-4">
              <TextInputLabelUp
                name="newPassword"
                label="새 비밀번호"
                placeholder="영문, 숫자, 특수문자 조합 8~20자리"
                value={updatePassword.newPassword}
                onChange={onChangePassword}
                type="password"
                error={errorPassword && passwordCheck.password.length > 0}
                errorMsg={passwordMessage}
              ></TextInputLabelUp>

              <TextInputLabelUp
                name="confirmNewPassword"
                label="새 비밀번호 확인"
                placeholder="비밀번호를 한번 더 입력해주세요."
                value={confirmPassword}
                onChange={confirmNewPassword}
                type="password"
                error={errorConfirmedPassword}
                errorMsg={confirmedPasswordMessage}
              ></TextInputLabelUp>
            </section>
            <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-3 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white">
              <ButtonFooter
                title="변경하기"
                isDisabled={!isSuccessChangePassword}
                onClick={handleChangeButton}
              ></ButtonFooter>
            </div>
          </>
        ) : (
          <>
            <section className="my-4">
              <TextInputLabelUp
                name="password"
                label="현재 비밀번호"
                placeholder="현재 비밀번호 입력"
                value={passwordCheck.password}
                onChange={onChangeCheckPassword}
                type="password"
                error={errorPassword && passwordCheck.password.length > 0}
                errorMsg={passwordMessage}
              ></TextInputLabelUp>
            </section>
            <div className="flex items-center justify-center fixed w-full mx-auto my-0 p-3 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white">
              <ButtonFooter
                title="변경하기"
                isDisabled={!isSuccessInputChange}
                onClick={handleInputChange}
              ></ButtonFooter>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PasswordChange;

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { asYYYYMM } from '../../utils/common';
import { getTransportInfoOutstandingAmount, patchTransportInfoOutstandingAmount } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import DriveForm from '@/components/Drive/DriveForm';
import TransportInfo from '@/components/Drive/TransportInfo';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronRightIcon, SearchOutlineIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { TODAY } from '@/const/date';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import '@/css/inputStyle.css';
import { formatNumber, getDayOfWeek } from '@/utils/common';
import { isSameMonth } from '@/utils/dateUtils';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Input } from '@mui/material';
import 'dayjs/locale/ko';

dayjs.locale('ko');

interface OutstandingAmountListResponse {
  sales: number;
  outstandingAmount: number;
  driveHistoryWithTransportInfo: driveHistoryWithTransportInfo[];
}

interface driveHistoryWithTransportInfo {
  baseDay: string;
  outstandingAmountOfDay: number;
  driveHistories: DriveHistoryList[];
}

const OutstandingAmountList = () => {
  const { showToast } = useToastContext();
  const location = useLocation();
  const [baseDate, setBaseDate] = useState(dayjs(location.state.selectedDate) || TODAY);
  const [transportCompany, setTransportCompany] = useState('');
  const [data, setData] = useState<OutstandingAmountListResponse>();
  const [isChangedAll, setIsChangedAll] = useState(false);
  const [clickedItem, setClicekedItem] = useState<DriveHistoryList>();
  const [isShowDriveFormPopup, setIsShowDriveFormPopup] = useState(false);
  const { member } = useMemberContext();
  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);
  const [isLoadingBtn, setIsLoadingBtn] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (member) {
      setDriveVehicleInfoId(member?.driveVehicleInfo?.id);
    }
  }, [member]);

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  const onClickPrevMonth = () => {
    setBaseDate(dayjs(baseDate).subtract(1, 'month'));
  };

  const onClickNextMonth = () => {
    if (!isSameMonth(TODAY, baseDate)) {
      setBaseDate(dayjs(baseDate).add(1, 'month'));
    }
  };

  const searchParams = {
    baseDate: baseDate.format('YYYY-MM'),
    transportCompany: transportCompany,
  };

  const getTransportInfoOutstandingAmountList = async () => {
    try {
      const params = new URLSearchParams(searchParams);
      const response = await getTransportInfoOutstandingAmount(params);
      const newData: OutstandingAmountListResponse = response.data;
      setData(newData);
      setIsChangedAll(newData.driveHistoryWithTransportInfo.length < 1);
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { isError, isLoading, isFetching, isSuccess, refetch } = useQuery(
    ['get-outstanding-amount-list', searchParams],
    () => getTransportInfoOutstandingAmountList(),
    {
      onSuccess: () => {},
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      enabled: !!baseDate,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const onChangeTransportCompanyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTransportCompany(value);
  };

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const patchAllOutstandingAmount = () => {
    closePopup();
    if (!data) return;

    const transportInfoIdWithIsReceived = data.driveHistoryWithTransportInfo.flatMap((dayInfo) =>
      dayInfo.driveHistories.flatMap((history) =>
        history.transportInfos.map((info) => ({
          transportInfoId: info.id,
          isReceived: true,
        })),
      ),
    );

    const request: TransportInfoOutstandingAmountModifyRequest = {
      transportInfoIdWithIsReceived,
    };

    patchOutstandingAmount.mutate(request);
    setIsChangedAll(true);
    showToast('수금완료로 변경되었어요.', 'success', 'bottom');
  };

  const onClickPatchAllOutstandingAmountBtn = () => {
    setPopupInfo({
      isShow: true,
      textContent: `${transportCompany ? `'${transportCompany}'` : ''} ${baseDate.format(
        'M',
      )}월 전체 내역을</br> 수금 처리하시겠어요?`,
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '완료',
      onClickRightBtn: patchAllOutstandingAmount,
    });
  };

  const onClickPatchOutstandingAmount = (e: React.MouseEvent<HTMLDivElement>, transportInfo: TransportInfos) => {
    e.stopPropagation();
    if (transportInfo) {
      const request = {
        transportInfoIdWithIsReceived: [
          { transportInfoId: transportInfo.id, isReceived: !transportInfo.isReceivedCost },
        ],
      };
      patchOutstandingAmount.mutate(request);
      showToast(`${!transportInfo.isReceivedCost ? '수금완료' : '미수금으'}로 변경되었어요.`, 'success', 'bottom');
    }
  };

  const calculateOutstandingAmount = (beforeOustandingAmount: number = 0, transportInfos: TransportInfos[]): number => {
    if (transportInfos.length > 1) {
      return 0;
    } else {
      if (transportInfos.length > 0) {
        if (transportInfos[0].isReceivedCost) {
          return beforeOustandingAmount - transportInfos[0].transportCost;
        } else {
          return beforeOustandingAmount + transportInfos[0].transportCost;
        }
      }
    }
    return beforeOustandingAmount;
  };

  const patchOutstandingAmount = useMutation(
    (request: TransportInfoOutstandingAmountModifyRequest) => patchTransportInfoOutstandingAmount(request),
    {
      onMutate: (request) => {
        setIsLoadingBtn({ [request?.transportInfoIdWithIsReceived[0]?.transportInfoId]: true });
      },
      onSuccess: (response) => {
        if (response) {
          const updatedData = {
            ...data,
            outstandingAmount: calculateOutstandingAmount(data?.outstandingAmount, response.data),
            driveHistoryWithTransportInfo: data?.driveHistoryWithTransportInfo.map((dayInfo) => ({
              ...dayInfo,
              driveHistories: dayInfo.driveHistories.map((history) => ({
                ...history,
                transportInfos: history.transportInfos.map((info) => {
                  const updatedInfo = response.data.find((res: TransportInfos) => res.id === info.id);
                  return updatedInfo ? { ...info, isReceivedCost: updatedInfo.isReceivedCost } : info;
                }),
              })),
            })) as driveHistoryWithTransportInfo[],
          };
          setData(updatedData as OutstandingAmountListResponse);
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      onSettled: (response) => {
        setIsLoadingBtn({ [response?.data[0].id]: false });
      },
    },
  );

  const onClickDetail = (driveHistory: DriveHistoryList) => {
    setClicekedItem(driveHistory);
    setIsShowDriveFormPopup(true);
  };

  const onCloseFormPopup = () => {
    setIsShowDriveFormPopup(false);
  };

  return (
    <>
      <MenuHeader title="미수금 내역"></MenuHeader>
      <div className="w-full pt-[60px]">
        <div className="px-4 pb-5">
          <div className="flex sm280:flex-col-reverse items-center sm280:items-start justify-between md-only:justify-around bg-blue-0 rounded-lg p-4">
            <div className="flex-1 sm680:flex sm680:justify-center sm680:items-center sm280:mt-2">
              <p className="pb-1 sm680:pb-0 sm680:mr-4">
                <span className="text-gray-7">매출 </span>
                <span className="text-primary font-semibold">{formatNumber(data?.sales?.toString())}원</span>
              </p>
              <p>
                <span className="text-gray-7">미수금 </span>
                <span className="text-gray-8 font-semibold">{formatNumber(data?.outstandingAmount?.toString())}원</span>
              </p>
            </div>
            <div className="border-l border-gray-3 h-[43px] ml-2 pl-2 sm280:hidden"></div>
            <div className="flex items-center justify-center sm680:flex-1 min-w-[120px] max-w-[240px]">
              <span className="flex items-center pr-2" onClick={onClickPrevMonth}>
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: 16, color: colors.gray[8] }}></ArrowBackIosNewRoundedIcon>
              </span>
              <span className="text-gray-8 font-semibold text-lg">{asYYYYMM(baseDate)}</span>
              <span className="flex items-center pl-2" onClick={onClickNextMonth}>
                <ArrowForwardIosRoundedIcon
                  sx={{ fontSize: 16, color: isSameMonth(TODAY, baseDate) ? colors.gray[5] : colors.gray[8] }}
                ></ArrowForwardIosRoundedIcon>
              </span>
            </div>
          </div>
        </div>
        <div className="border-t-8 border-gray-1 pb-8">
          <div className="flex items-center justify-between px-4 pt-3">
            <div className="flex items-center ">
              <Input
                className="text-sm"
                placeholder={`운송사 검색`}
                endAdornment={
                  <SearchOutlineIcon
                    color={colors.gray[6]}
                    width={20}
                    height={20}
                    strokeWidth={0.1}
                  ></SearchOutlineIcon>
                }
                sx={{
                  fontSize: '14px',
                  borderColor: colors.gray[4],
                  width: '110px',
                  '&:focus, &:active': {
                    borderColor: colors.blue[4],
                  },
                  '&:before': {
                    borderColor: colors.gray[4],
                  },
                }}
                value={transportCompany || ''}
                onChange={onChangeTransportCompanyInput}
              />
            </div>
            <BasicButton
              name="전체 수금처리"
              bgColor={colors.blue[1]}
              borderColor={colors.blue[1]}
              textColor={colors.primary}
              fontSize={14}
              width={100}
              height={37}
              fontWeight="semibold"
              shadow={false}
              borderRadius={8}
              isDisabled={isChangedAll}
              onClick={() => onClickPatchAllOutstandingAmountBtn()}
            ></BasicButton>
          </div>
          {data?.driveHistoryWithTransportInfo && data.driveHistoryWithTransportInfo.length > 0 ? (
            <>
              {data?.driveHistoryWithTransportInfo.map((item, index) => (
                <div className="px-4 pt-9" key={index}>
                  <div className="flex items-center justify-between border-b border-gray-3 pb-[10px]">
                    <span className="text-gray-6 text-sm font-medium">
                      {dayjs(item.baseDay).date()}일 {getDayOfWeek(item.baseDay)}
                    </span>
                    <span className="text-gray-7 text-sm font-medium">
                      {formatNumber(item.outstandingAmountOfDay.toString())}원
                    </span>
                  </div>
                  {item.driveHistories.map((driveHistory, key) => (
                    <div
                      key={key}
                      className={`${key > 0 ? 'pt-6' : 'pt-4'}`}
                      onClick={() => onClickDetail(driveHistory)}
                    >
                      <div className="flex justify-between items-center">
                        <span className="text-gray-8 font-medium">{driveHistory.title}</span>
                        <ChevronRightIcon
                          color={colors.gray[7]}
                          width={20}
                          height={20}
                          strokeWidth={2}
                        ></ChevronRightIcon>
                      </div>
                      {driveHistory.transportInfos.map((data, index) => (
                        <div key={index} className="pt-3">
                          <TransportInfo
                            transportInfo={data}
                            onClickPatchOutstandingAmount={onClickPatchOutstandingAmount}
                            isLoadingBtn={isLoadingBtn}
                          ></TransportInfo>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </>
          ) : (
            <div className="flex items-center justify-center min-h-[calc(100vh-261px)]">
              <span className="text-gray-7 text-lg">미수금 내역이 없습니다.</span>
            </div>
          )}
        </div>
      </div>
      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
      {isShowDriveFormPopup && driveVehicleInfoId && (
        <DownToUpDetailPopup
          isShow={isShowDriveFormPopup && !!driveVehicleInfoId}
          onClosePopup={onCloseFormPopup}
          title={clickedItem?.title}
          isHeightFull
          isBackdrop={true}
        >
          <DriveForm
            selectedDate={new Date(baseDate.format('YYYY-MM-DD').toString())}
            driveVehicleInfoId={driveVehicleInfoId}
            data={clickedItem}
            onClosePopup={onCloseFormPopup}
            refetch={refetch}
          ></DriveForm>
        </DownToUpDetailPopup>
      )}
    </>
  );
};

export default OutstandingAmountList;

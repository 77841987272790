import React, { SVGProps } from 'react';

const SvgLicensePlateBoldColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <rect width={33} height={19} x={5.164} y={12.872} fill="#737373" rx={3.5} />
    <rect width={33.449} height={19.114} x={1} y={9} fill="#FFF065" rx={3.5} />
    <path
      fill="#737373"
      d="m23.297 19.655 3.264-5.087h2.016v5.066h.98v1.29h-.98v1.366H27.05v-1.365h-3.754v-1.27Zm3.776-.02v-3.307h-.075l-2.08 3.242v.064h2.155ZM19.635 22.397c-.558 0-1.06-.096-1.504-.288a2.563 2.563 0 0 1-1.045-.811 2.056 2.056 0 0 1-.395-1.184h1.622c.01.188.074.356.192.501.117.146.275.26.474.342.2.078.422.117.667.117.252 0 .478-.044.677-.133.203-.093.36-.219.47-.379a.887.887 0 0 0 .165-.544.891.891 0 0 0-.176-.56 1.12 1.12 0 0 0-.507-.378 2.003 2.003 0 0 0-.778-.14h-.715v-1.183h.715c.248 0 .47-.043.666-.128.196-.085.349-.206.459-.363a.903.903 0 0 0 .165-.533.936.936 0 0 0-.538-.864 1.307 1.307 0 0 0-.592-.128c-.228 0-.438.04-.63.123a1.113 1.113 0 0 0-.458.341.854.854 0 0 0-.182.507h-1.536c.008-.438.134-.827.379-1.168.245-.342.58-.607 1.003-.795a3.432 3.432 0 0 1 1.424-.288c.526 0 .991.096 1.397.288.409.188.725.45.95.784.223.33.333.699.33 1.104a1.551 1.551 0 0 1-.699 1.333c-.22.15-.475.25-.763.299v.064c.374.046.692.15.955.31s.462.367.597.623c.136.253.201.539.198.859.003.433-.123.825-.379 1.173-.256.345-.611.615-1.066.81a3.933 3.933 0 0 1-1.542.289ZM10.427 21.127l2.774-2.549c.273-.263.485-.482.634-.656.153-.174.27-.346.352-.517a1.29 1.29 0 0 0 .123-.555c0-.224-.053-.42-.16-.586a1.001 1.001 0 0 0-.438-.384 1.387 1.387 0 0 0-.629-.139c-.362 0-.652.108-.87.325-.216.214-.324.503-.324.87h-1.526c-.003-.498.109-.934.336-1.307.231-.373.555-.661.971-.864.416-.203.894-.304 1.434-.304.545 0 1.025.098 1.44.293.416.192.738.46.966.806.23.345.346.74.346 1.184 0 .302-.058.593-.176.874-.117.277-.321.589-.613.934-.288.34-.697.752-1.226 1.231l-1.174 1.142v.042h3.296v1.323h-5.525l-.01-1.163ZM8.975 22.29H7.364v-6.197h-.043l-1.77 1.11v-1.42l1.909-1.215h1.515v7.722Z"
    />
    <circle cx={3.921} cy={11.958} r={0.796} fill="#737373" />
    <circle cx={31} cy={11.958} r={0.796} fill="#737373" />
    <circle cx={31} cy={24.701} r={0.796} fill="#737373" />
    <circle cx={3.921} cy={24.701} r={0.796} fill="#737373" />
  </svg>
);
SvgLicensePlateBoldColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgLicensePlateBoldColorIcon;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import history from '@/utils/common/history';

const useBlockNavigation = (setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>) => {
  const location = useLocation();

  useEffect(() => {
    const unlisten = history.listen((locationData) => {
      if (locationData.action === 'POP') {
        setIsShowPopup(true);
        history.push(location.pathname);
      }
    });

    return () => {
      unlisten();
    };
  }, [location.pathname, setIsShowPopup]);
};

export default useBlockNavigation;

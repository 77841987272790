import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { NAME_VALIDATION_LENGTH_MESSAGE, NAME_VALIDATION_MESSAGE } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import '@/css/tailwind.css';
import { validateName } from '@/utils/validation';

interface UpdatedMember {
  name: string;
}

const NameChange = () => {
  const navigate = useNavigate();
  const { member, updateMember } = useMemberContext();
  const [updatedMember, setUpdatedMember] = useState<UpdatedMember>({ name: '' });
  const [nameMessage, setNameMessage] = useState('');
  const [error, setError] = useState(false);

  const { showToast } = useToastContext();

  useEffect(() => {
    if (member) {
      setUpdatedMember({ ...updatedMember, name: member?.name });
    }
  }, [member]);

  const isSuccess = useMemo(() => {
    return updatedMember.name && !error;
  }, [updatedMember.name]);

  const onChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (validateName(value) === false) {
      setNameMessage(NAME_VALIDATION_MESSAGE);
      setError(true);
    } else if (value.length > 20) {
      setNameMessage(NAME_VALIDATION_LENGTH_MESSAGE);
      setError(true);
    } else {
      setNameMessage('');
      setError(false);
    }

    setUpdatedMember({ ...updatedMember, name: value });
  }, []);

  const handleChangeButton = () => {
    apiManager
      .patch('/api/v1/members', updatedMember)
      .then((response) => {
        if (response) {
          showToast('변경이 완료되었어요', 'success', 'bottom');
          updateMember(response?.data as Member);
          navigate('/setting');
        }
      })
      .catch((error) => {
        setNameMessage('error');
        setError(true);
      });
  };

  return (
    <>
      <MenuHeader title="이름 변경"></MenuHeader>
      <div className="w-full p-4 pt-[60px]">
        <div className="flex justify-between align-center pt-6">
          <TextInputLabelUp
            name="password"
            label="이름"
            placeholder="이름 입력"
            value={updatedMember.name}
            onChange={onChangeName}
            error={error}
            errorMsg={nameMessage}
          ></TextInputLabelUp>
        </div>
        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-3 py-3 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white">
          <ButtonFooter title="변경하기" isDisabled={!isSuccess} onClick={handleChangeButton}></ButtonFooter>
        </div>
      </div>
    </>
  );
};

export default NameChange;

import React, { SVGProps } from 'react';

const SvgHomeOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke={props.color || 'currentColor'}
      strokeLinecap="round"
      strokeWidth={1.4}
      d="M20.3 11.998v7.053c0 .658-.57 1.249-1.346 1.249h-4.806a.137.137 0 0 1-.096-.035c-.02-.018-.022-.034-.022-.044v-4.678c0-.848-.712-1.48-1.519-1.48H11.49c-.807 0-1.519.632-1.519 1.48v4.678c0 .01-.003.026-.022.044a.137.137 0 0 1-.096.035H5.045c-.775 0-1.345-.59-1.345-1.249V12a.3.3 0 0 1 .093-.218l8-7.617-.483-.507.483.507a.3.3 0 0 1 .414 0l.467-.491-.467.49 8 7.618a.3.3 0 0 1 .093.217Z"
    />
  </svg>
);
SvgHomeOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgHomeOutlineIcon;

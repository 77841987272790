import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const usePartnerSession = () => {
  const [cookies, setCookie] = useCookies(['partner']);
  const [partnerType, setPartnerType] = useState<string | null>(null);

  useEffect(() => {
    const partner = new URLSearchParams(window.location.search).get('partner');

    if (partner) {
      setCookie('partner', partner, { path: '/', expires: new Date(Date.now() + 86400 * 1000) }); // 1일 후 만료
      setPartnerType(partner);
    } else {
      setPartnerType(cookies.partner || null);
    }
  }, []);

  return partnerType;
};

export default usePartnerSession;

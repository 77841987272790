import { toJpeg } from 'html-to-image';
import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { downloadContractFile } from '@/api/public';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import { TRANSFEREE, TRANSFEROR } from '@/const/contract';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatNumber, formatPrice, getFormatDate } from '@/utils/common';

interface ContractProps {
  contractInfo: ContractInfo | undefined;
  contractWriterType: string;
  setPageNum: (pageNum: number) => void;
}

const ContractViewer: React.FC<ContractProps> = ({ contractInfo, contractWriterType, setPageNum }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const { showToast } = useToastContext();

  const isDisabledWirteBtn = () => {
    if (contractWriterType === TRANSFEREE) {
      return !contractInfo?.transferorCompleted;
    } else if (isOnlyTransferorCompleted) {
      return false;
    } else {
      return false;
    }
  };

  const isOnlyTransferorCompleted =
    contractWriterType === TRANSFEROR && contractInfo?.transferorCompleted && !contractInfo?.transfereeCompleted;

  const isShowWriteButton =
    (contractWriterType === TRANSFEROR && !contractInfo?.transferorCompleted) ||
    (contractWriterType === TRANSFEREE && !contractInfo?.transfereeCompleted) ||
    isOnlyTransferorCompleted;

  const { mutate, isLoading } = useMutation(downloadContractFile, {
    onSuccess: (response) => {
      const link = document.createElement('a');
      link.href = response.data.url;
      link.download = `계약서_${contractInfo?.carName}.jpeg`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(response.data.url);
      showToast('전자계약서를 다운로드했어요.', 'success', 'bottom');
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const buttons: Button[] = isShowWriteButton
    ? [
        {
          title: '문서 다운로드',
          bgColor: '',
          borderColor: '',
          isDisabled: true,
        },
        {
          title: isOnlyTransferorCompleted ? '수정하기' : '작성하기',
          bgColor: '',
          borderColor: '',
          isDisabled: isDisabledWirteBtn(),
          onClick: () => {
            setPageNum(2);
          },
        },
      ]
    : [
        {
          title: '문서 다운로드',
          bgColor: '',
          borderColor: '',
          isDisabled:
            !contractInfo?.transferorCompleted || !contractInfo?.transfereeCompleted || isDownloading || isLoading,
          onClick: () => {
            downLoadFile();
          },
        },
      ];

  const downLoadFile = async () => {
    setIsDownloading(true);
    try {
      if (componentRef.current) {
        toJpeg(componentRef.current).then((image) => {
          if (image && contractInfo?.id) {
            // base64 인코딩된 이미지 데이터를 Blob으로 변환
            const byteCharacters = atob(image.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });

            const formData = new FormData();
            formData.append('contractFile', blob, `계약서_${contractInfo?.carName}.jpeg`);

            mutate({ contractId: contractInfo.id, formData: formData });
          }
        });
      }
    } catch (error) {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <div
        ref={componentRef}
        style={{
          textAlign: 'center',
          minWidth: '248px',
          paddingBottom: '60px',
          paddingTop: '2px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <h4 style={{ fontWeight: '700', textAlign: 'center' }}>자동차 관리권 양도 양수 계약서</h4>
        <p style={{ fontSize: '0.75rem', textAlign: 'center', marginBottom: '12px', marginTop: '8px' }}>
          매도인과 매수인은 쌍방 합의하에 매매계약을 다음과 같이 체결한다.
        </p>
        <table
          style={{
            textAlign: 'left',
            width: '100%',
            border: '1px solid black',
            borderCollapse: 'collapse',
            margin: '0 0 1rem 0',
            fontSize: '0.75rem',
            wordBreak: 'break-all',
          }}
        >
          <tbody>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ border: '1px solid black', textAlign: 'left', padding: '8px' }} colSpan={6}>
                1.매매할 자동차의 표시
              </th>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>차량번호</th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.carNumber}
              </td>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>차종</th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.carType}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>용도</th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.carUse}
              </td>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>차명</th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.carName}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>년식</th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.year}
              </td>
              <th style={{ textAlign: 'center' }}>
                원동기<br></br>형식
              </th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.motorType}
              </td>
              {/* <th style={{ textAlign: 'center', border: '1px solid black' }}>형식</th>
              <td colSpan={2} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.typeNumber}
              </td> */}
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>차대번호</th>
              <td colSpan={4} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.identificationNumber}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th colSpan={6} style={{ border: '1px solid black', textAlign: 'left', padding: '8px' }}>
                2.계약내용(약정사항)
              </th>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th colSpan={6} style={{ border: '1px solid black', textAlign: 'left', padding: '8px' }}>
                재 1조 위 자동차를 매매함에 있어 매매 금액을 아래와 같이 지불하기로 한다.
              </th>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>매매금액</th>
              <td colSpan={5} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.tradingAmount !== undefined && (
                  <>
                    一金 원정은{' '}
                    <span className="font-semibold">
                      {' '}
                      {contractInfo.tradingAmount ? `${formatPrice(String(contractInfo.tradingAmount))}` : '만원'}
                    </span>
                    정 (₩
                    {formatNumber(String(contractInfo.tradingAmount * 10000))})
                  </>
                )}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>계약금</th>
              <td colSpan={5} style={{ border: '1px solid black', padding: '8px' }}>
                一金{' '}
                <span className="font-semibold">
                  {contractInfo?.downPayment && formatNumber(String(contractInfo?.downPayment))}만원
                </span>
                정은 {contractInfo?.downPaymentDate ? getFormatDate(String(contractInfo?.downPaymentDate)) : '년 월 일'}{' '}
                계약시 지불하고
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center' }}>중도금</th>
              <td colSpan={5} style={{ border: '1px solid black', padding: '8px' }}>
                一金{' '}
                <span className="font-semibold">
                  {contractInfo?.intermediatePayment && formatNumber(String(contractInfo?.intermediatePayment))}
                  만원
                </span>
                정은
                {contractInfo?.intermediatePaymentDate
                  ? getFormatDate(String(contractInfo?.intermediatePaymentDate))
                  : ' 년 월 일'}{' '}
                계약시 지불한다
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th style={{ textAlign: 'center', border: '1px solid black' }}>잔금</th>
              <td colSpan={5} style={{ border: '1px solid black', padding: '8px' }}>
                一金{' '}
                <span className="font-semibold">
                  {contractInfo?.balance && formatNumber(String(contractInfo?.balance))}
                  만원
                </span>
                정은
                {contractInfo?.balancePaymentDate
                  ? getFormatDate(String(contractInfo?.balancePaymentDate))
                  : ' 년 월 일'}{' '}
                계약시 지불한다
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={6} style={{ lineHeight: '24px', border: '1px solid black', padding: '8px' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제2조</p>
                  <p style={{ flex: '1' }}>(당사자 표시) 매도인을 “갑”이라 하고 매수인을 “을”이라 한다.</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제3조</p>
                  <p style={{ flex: '1' }}>
                    (동시이행등) “갑”은 잔금수령과 상환으로 자동차와 소유권 이전등록에 필요한 서류를 “을”에게 인도한다.
                    영업용 차량일 경우 매수인은 지입회사의 규정에 준하여 위수탁관리 계약을 체결한다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제4조</p>
                  <p style={{ flex: '1' }}>
                    (공과금부담) 이 자동차에 대한 제세공과금은 자동차 인도일을 기준으로 하여 그 기준일까지의 분은 “갑”이
                    부담하고 기준일의 다음날부터의 분은 “을”이 부담한다. 영업용 경우 사후 발생하는 차량에 대한 모든
                    보험료 및 제세공과금 지입료부분을 운수회사 규정에 준한다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제5조</p>
                  <p style={{ flex: '1' }}>
                    (하자담보책임) “을”은 이 자동차를 인수한 후에는 이 자동차의 고장 또는 불량 등의 사유로 “갑”에게 그
                    책임을 물을 수 없다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제6조</p>
                  <p style={{ flex: '1' }}>
                    (사고책임) “을”은 이 자동차를 인수한 때부터 발생하는 모든 사고에 대하여 자기를 위하여 운행하는
                    자로서의 책임을 진다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제7조</p>
                  <p style={{ flex: '1' }}>
                    (법률상의 하자책임) 자동차인도일 이전에 발생한 행정처분 또는 이전 등록요건의 불비 기타행정상의
                    하자에 대하여는 “갑”이 그 책임을 진다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제8조</p>
                  <p style={{ flex: '1' }}>
                    (등록지체책임) “을”이 이 매매 목적물을 인수한 후 소정의 기일안에 이전등록을 하지 아니할 때에는 이에
                    대한 모든 책임을 “을”이 진다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제9조</p>
                  <p style={{ flex: '1' }}>
                    (할부승계특약) “갑”이 자동차를 할부로 구입하여 할부금을 완납하지 않은 상태에서 “을”에게 양도하는
                    경우에는 잔여할부금을 “을”이 승계하여 부담할 것인지의 여부를 특약사항란에 기재하여야 한다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제10조</p>
                  <p style={{ flex: '1' }}>
                    (위약금) 매도인이 위약시는 위약금조로 계약금의 배역을 배상하기로 하고 매수인이 위약시는 위약금조로
                    계약금을 포기하기로 한다.
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ width: '40px' }}>제11조</p>
                  <p style={{ flex: '1' }}>(잔금불이행책임) 잔금 약속 불이행시 당 차량을 포기한다.</p>
                </div>
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={6} style={{ lineHeight: '24px', border: '1px solid black', padding: '8px' }}>
                <span style={{ display: 'block', paddingBottom: '8px' }}>특약사항</span>
                매도인, 매수인 쌍방의 매매계약서로 충분히 유효하며,
                <br />
                [자동차등록규칙] 제33조제2항제1호에 따라 이 양도증명서를 작성함.
                <br />
                {!!contractInfo?.additionalConditions &&
                  contractInfo?.additionalConditions.split('\n').map((line, index) => {
                    return (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={6} style={{ lineHeight: '24px', border: '1px solid black', padding: '8px' }}>
                <span style={{ display: 'block', lineHeight: '16px', paddingBottom: '12px' }}>
                  * 대면을 통하지 않고 전자 계약 작성을 하는 위험성에 대해 인지하고 있음을 확인합니다.
                </span>
                <span style={{ display: 'block', lineHeight: '16px' }}>
                  * (주)직트럭은 계약서 양식만 제공하므로 당사자간 거래에는 관여 및 책임지지 않습니다.
                </span>
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th rowSpan={4} style={{ textAlign: 'center', border: '1px solid black' }}>
                (갑)
                <br />
                매도인
              </th>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                주소
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.transferorAddress}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                성명
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                <div style={{ display: 'flex' }}>
                  {contractInfo?.transferorName}{' '}
                  {contractInfo?.transferorSignImageUrl && (
                    <img
                      src={contractInfo?.transferorSignImageUrl}
                      alt="양도인 서명"
                      style={{ maxWidth: '80px', maxHeight: '20px' }}
                    />
                  )}
                </div>
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                전화
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.transferorPhoneNumber}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                주민등록번호(사업자번호)
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.transferorRegistrationNumber}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <th rowSpan={4} style={{ textAlign: 'center', border: '1px solid black' }}>
                (을)
                <br />
                매수인
              </th>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                주소
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.transfereeAddress}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                성명
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                <div style={{ display: 'flex' }}>
                  {contractInfo?.transfereeName}{' '}
                  {contractInfo?.transfereeSignImageUrl && (
                    <img
                      src={contractInfo?.transfereeSignImageUrl}
                      alt="양수인 서명"
                      style={{ maxWidth: '80px', maxHeight: '20px' }}
                    />
                  )}
                </div>
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                전화
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.transfereePhoneNumber}
              </td>
            </tr>
            <tr style={{ border: '1px solid black' }}>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                주민등록번호(사업자번호)
              </td>
              <td colSpan={3} style={{ border: '1px solid black', padding: '8px' }}>
                {contractInfo?.transfereeRegistrationNumber}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-gray-0">
        <ButtonFooter buttons={buttons}></ButtonFooter>
      </div>
    </>
  );
};

export default ContractViewer;

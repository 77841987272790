import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import { getCurrentYear } from '@/utils/common';
import { getTonnageErrorMessage, getYearErrorMessage, processTonnageInput, processYearInput } from '@/utils/validation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LensIcon from '@mui/icons-material/Lens';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface LicensePurchasingInquiry {
  year: string;
  tons: string;
  licenseSalesType: string | null;
  licenseType: EnumPresenter | null;
  useClassification: EnumPresenter | null;
  locate: EnumPresenter | null;
  [key: string]: any;
}

const LicensePurchasingInquiry = () => {
  const currentYear = getCurrentYear();
  const isClicked = useRef<boolean>(false);
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');
  const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const { licenseEnum, setLicenseEnum } = useLicenseEnumContext();

  const { showToast } = useToastContext();

  const yearRef = useRef<HTMLInputElement>(null);
  const tonsRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);

  const [yearError, setYearError] = useState(false);
  const [tonsError, setTonsError] = useState(false);
  const [yearErrorMsg, setYearErrorMsg] = useState('');
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');

  const [isShowBasicPopup, setIsShowBasicPopup] = useState(false);

  useEffect(() => {
    if (!licenseEnum) {
      apiManager
        .get('/api/v1/public/license/filter-info')
        .then((response) => {
          const responseData: LicenseEnum = response.data;
          setLicenseEnum(responseData);
        })
        .catch(() => {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        });
    }
  }, []);

  const [licenseInquiry, setLicenseInquiry] = useState<LicensePurchasingInquiry>({
    year: '',
    tons: '',
    licenseSalesType: 'TRADE',
    licenseType: { code: '', desc: '' },
    useClassification: { code: '', desc: '' },
    locate: { code: '', desc: '' },
  });

  const keyValueList: KeyValueListType = {
    licenseType: '번호판 종류',
    useClassification: '번호판 용도',
    locate: '지역',
  };

  const createLicenseInquiry = () => {
    if (isClicked.current === true) {
      return;
    }
    isClicked.current = true;

    if (licenseInquiry) {
      apiManager
        .post('/api/v1/license-inquiry', {
          year: licenseInquiry?.year,
          tons: licenseInquiry?.tons,
          licenseSalesType: licenseInquiry?.licenseSalesType,
          licenseType: licenseInquiry?.licenseType?.code,
          useClassification: licenseInquiry?.useClassification?.code,
          locate: licenseInquiry?.locate?.code,
        })
        .then(() => {
          setIsShowBasicPopup(true);
        })
        .catch(() => {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        })
        .finally(() => {
          isClicked.current = false;
        });
    }
  };

  const onClickSelectBox = (key: string) => {
    const arr: OptionDataType[] = [];
    setTitle(key);

    if (key === 'licenseType') {
      setOptionData(licenseEnum.licenseType);
    } else if (key === 'useClassification') {
      setOptionData(licenseEnum.useClassification);
    } else {
      setOptionData(licenseEnum.locate);
    }
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    setLicenseInquiry({ ...licenseInquiry, [key]: item } as LicensePurchasingInquiry);
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const handleChangeYearInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const trimmedValue = processYearInput(value);

    setLicenseInquiry({ ...licenseInquiry, [name]: trimmedValue } as LicensePurchasingInquiry);

    const errorMessage = getYearErrorMessage(trimmedValue);

    if (errorMessage) {
      setYearError(true);
      setYearErrorMsg(errorMessage);
    } else {
      setYearError(false);
      setYearErrorMsg('');
    }
  };

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processTonnageInput(value, 1);

    setLicenseInquiry({ ...licenseInquiry, [name]: trimmedValue } as LicensePurchasingInquiry);

    const errorMessage = getTonnageErrorMessage(trimmedValue);

    if (errorMessage) {
      setTonsError(true);
      setTonsErrorMsg(errorMessage);
    } else {
      setTonsError(false);
      setTonsErrorMsg('');
    }
  };

  const isDisabledBtn = () => {
    if (!licenseInquiry?.year || !licenseInquiry?.tons || !licenseInquiry?.licenseSalesType || yearError || tonsError) {
      return true;
    } else if (licenseInquiry?.licenseSalesType === 'TRADE') {
      if (licenseInquiry?.licenseType?.code === 'GENERAL_CARGO') {
        return !licenseInquiry?.licenseType?.code || !licenseInquiry?.locate?.code;
      } else {
        return !licenseInquiry?.licenseType?.code;
      }
    } else {
      return !licenseInquiry?.locate?.code;
    }
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'year') {
        yearRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'tons') {
        tonsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'price') {
        priceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setLicenseInquiry({ ...licenseInquiry, [key]: newVal.code } as LicensePurchasingInquiry);
  };

  const clear = () => {
    setLicenseInquiry({
      year: '',
      tons: '',
      licenseSalesType: 'TRADE',
      licenseType: { code: '', desc: '' },
      useClassification: { code: '', desc: '' },
      locate: { code: '', desc: '' },
    });

    setYearError(false);
    setYearErrorMsg('');
    setTonsError(false);
    setTonsErrorMsg('');
  };

  const closeBasicPopup = () => {
    setIsShowBasicPopup(false);
    navigate('/license', { state: { isForm: true } });
  };

  return (
    <>
      <MenuHeader title="번호판 구매 문의"></MenuHeader>
      <div className="w-full pt-[60px] pb-[100px] px-4 pb- text-gray-8">
        <div className="rounded-lg bg-blue-0 p-4 my-4">
          <p className="text-lg font-bold">번호판 구매 문의를 남겨주세요.</p>
          <p className="text-sm mt-3 text-gray-8 font-light	leading-[17px]">
            번호판 구매 문의를 남겨주시면<br></br>
            확인 후 담당자가 연락드릴 예정입니다.<br></br>
          </p>
        </div>
        <form className="w-full" onSubmit={createLicenseInquiry}>
          <div className="flex flex-col gap-[30px]">
            <TextInputLabelUp
              ref={yearRef}
              name="year"
              label="연식"
              placeholder="연식 입력 ex) 2024"
              value={licenseInquiry?.year || ''}
              onChange={(e) => handleChangeYearInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              inputMode="numeric"
              required
              error={yearError}
              errorMsg={yearErrorMsg}
              maxLength={4}
            ></TextInputLabelUp>

            <TextInputLabelUp
              ref={tonsRef}
              name="tons"
              label="톤수"
              placeholder="톤수 입력 ex) 8.5"
              value={licenseInquiry?.tons || ''}
              onChange={(e) => handleChangeTonsInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              required
              error={tonsError}
              errorMsg={tonsErrorMsg}
              suffix="t"
              maxLength={4}
            ></TextInputLabelUp>

            <div className="text-base">
              <p className="font-semibold mb-2 text-gray-8">
                거래 방식
                <span className="font-normal text-red ml-1">(필수)</span>
              </p>
              <div className="px-1">
                <RadioList
                  name="licenseSalesType"
                  list={licenseEnum.licenseSalesType}
                  value={licenseInquiry.licenseSalesType || ''}
                  onChange={(val) => {
                    onChangeRadioInput(val, 'licenseSalesType');
                  }}
                  justify="equal"
                ></RadioList>
              </div>
            </div>

            {licenseInquiry?.licenseSalesType === 'TRADE' && (
              <>
                <div onClick={() => onClickSelectBox('licenseType')}>
                  <TextInputLabelUp
                    name="licenseType"
                    label="번호판 종류"
                    placeholder="번호판 종류 선택"
                    value={licenseInquiry?.licenseType?.desc || ''}
                    height={36}
                    type="text"
                    readOnly
                    required
                    suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                  />
                </div>
                <div onClick={() => onClickSelectBox('useClassification')}>
                  <TextInputLabelUp
                    name="useClassification"
                    label="번호판 용도"
                    placeholder="번호판 용도 선택"
                    value={licenseInquiry?.useClassification?.desc || ''}
                    type="text"
                    readOnly
                    suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                  />
                </div>

                {licenseInquiry.licenseType?.code === 'GENERAL_CARGO' && (
                  <div onClick={() => onClickSelectBox('locate')}>
                    <TextInputLabelUp
                      name="locate"
                      label="지역"
                      placeholder="지역 선택"
                      value={licenseInquiry?.locate?.desc || ''}
                      type="text"
                      readOnly
                      required
                      suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                    ></TextInputLabelUp>
                  </div>
                )}
              </>
            )}
            {licenseInquiry.licenseSalesType === 'RENTAL' && (
              <div onClick={() => onClickSelectBox('locate')}>
                <TextInputLabelUp
                  name="locate"
                  label="지역"
                  placeholder="지역 선택"
                  value={licenseInquiry?.locate?.desc || ''}
                  type="text"
                  readOnly
                  required
                  suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                ></TextInputLabelUp>
              </div>
            )}
          </div>
        </form>
        <DualFooterButton
          leftButtonText="초기화"
          onClickLeftButton={() => {
            clear();
          }}
          rightButtonText="등록"
          disabledRight={isDisabledBtn()}
          onClickRightButton={() => {
            createLicenseInquiry();
          }}
        ></DualFooterButton>
      </div>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={
              (licenseInquiry && licenseInquiry[title as keyof LicensePurchasingInquiry]?.id) ||
              (licenseInquiry && licenseInquiry[title as keyof LicensePurchasingInquiry]?.code) ||
              ''
            }
            name="radio-buttons-group"
          >
            {optionData?.map((item, index) =>
              title === 'model' ? (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={
                    <Radio
                      icon={<LensIcon sx={{ color: '#E5E7EB' }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: '#1E42A6' }} />}
                    />
                  }
                  label={item.name}
                  onClick={(e) => onClickItem(item, title)}
                />
              ) : (
                <FormControlLabel
                  key={index}
                  value={item.code}
                  control={
                    <Radio
                      icon={<LensIcon sx={{ color: '#E5E7EB' }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: '#1E42A6' }} />}
                    />
                  }
                  label={item.desc}
                  onClick={(e) => onClickItem(item, title)}
                />
              ),
            )}
          </RadioGroup>
        </div>
      </DownToUpDetailPopup>
      <BasicPopup
        isShow={isShowBasicPopup}
        title="구매문의 등록 완료"
        textContent="<p class='text-[20px] px-5'>담당자가 확인 후<br/>연락 드릴 예정입니다.</p>"
        textRightBtn="확인"
        onClickRightBtn={closeBasicPopup}
      ></BasicPopup>
    </>
  );
};

export default LicensePurchasingInquiry;

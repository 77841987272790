import React, { useEffect, useState } from 'react';

import LoadingComponent from './LoadingComponent';

type LoadingSpinnerType = {
  text: string;
  isTextChanging?: boolean;
};

const LoadingSpinner = ({ text, isTextChanging }: LoadingSpinnerType) => {
  const [displayText, setDisplayText] = useState(text);

  useEffect(() => {
    const intervalId = setInterval(() => {
      switch (displayText) {
        case '데이터를 조회중입니다. 잠시만 기다려주세요...':
          setDisplayText('차량 정보 조회 중입니다.');
          break;
        case '차량 정보 조회 중입니다.':
          setDisplayText('데이터 확인 중... 조금 더 기다려주세요.');
          break;
        case '데이터 확인 중... 조금 더 기다려주세요.':
          setDisplayText('차량 실 소유자 정보를 조회 중입니다.');
          break;
        case '차량 실 소유자 정보를 조회 중입니다.':
          setDisplayText('여전히 조회 중이에요. 소요 시간이 조금 더 필요합니다.');
          break;
        case '여전히 조회 중이에요. 소요 시간이 조금 더 필요합니다.':
          setDisplayText('차량의 거래 이력을 조회 중입니다.');
          break;
        case '차량의 거래 이력을 조회 중입니다.':
          setDisplayText('데이터 조회 중입니다. 이제 조금 더 기다려주세요.');
          break;
        case '데이터 조회 중입니다. 이제 조금 더 기다려주세요.':
          setDisplayText('차량의 자동차 검사 이력을 조회 중입니다.');
          break;
        case '차량의 자동차 검사 이력을 조회 중입니다.':
          setDisplayText('조회 작업이 계속되고 있습니다. 조금만 더 기다려주세요.');
          break;
        case '조회 작업이 계속되고 있습니다. 조금만 더 기다려주세요.':
          setDisplayText('차량의 구조 변경 이력을 조회 중입니다.');
          break;
        case '차량의 구조 변경 이력을 조회 중입니다.':
          setDisplayText('데이터 확인 중... 얼마 남지 않았습니다. 조금만 기다려주세요.');
          break;
        case '데이터 확인 중... 얼마 남지 않았습니다. 조금만 기다려주세요.':
          return '데이터를 조회중입니다. 잠시만 기다려주세요...';
        default:
          setDisplayText('데이터를 조회중입니다.');
          break;
      }
    }, 6000);

    return () => clearInterval(intervalId);
  }, [displayText]);

  return (
    <>
      <div className="fixed bg-black opacity-75 w-full h-full justify-center items-center z-40 top-0 left-0 inline-flex"></div>
      <div className="fixed inline-flex flex-col w-full h-full justify-center items-center z-40 top-0 left-0">
        <div className="border shadow-lg rounded-full w-16 h-16 relative bg-white opacity-100">
          <LoadingComponent></LoadingComponent>
        </div>
        <div className="text-white mt-4 break-keep text-center"> {isTextChanging ? displayText : text}</div>
      </div>
    </>
  );
};

export default LoadingSpinner;

import React from 'react';

import { formatNumber } from '../../../utils/common';
import FilledBadge from '@/components/Common/Badge/FilledBadge';
import MenuHeader from '@/components/Header/MenuHeader';
import { IMAGE_BASE_URL } from '@/const/url';

interface NewTruckLeaseInfo {
  id: number;
  truckName: string;
  truckImageUrls: string[];
  monthlyLeaseFee: number;
  residualValue: number;
}

interface NewCarLeaseGuideViewProps {
  data: NewTruckLeaseInfo;
}

const NewCarLeaseGuideView = ({ data }: NewCarLeaseGuideViewProps) => {
  return (
    <>
      <MenuHeader title="신차리스"></MenuHeader>

      <div className="w-full pt-[36px]">
        <div className="flex flex-col items-center justify-center px-6">
          <div className="flex flex-col mb-[40px]">
            <span className="text-gray-6 text-[14px] font-medium">상용차를 이용하는 새로운 방법</span>
            <span className="text-primary font-bold text-[28px]">직트럭 신차리스</span>
          </div>
          <img src={`${IMAGE_BASE_URL}/handshaking.png`} alt="악수 이미지" width={150} height={66} />
          <div className="mb-10 text-center">
            <h4 className="text-gray-8 font-bold text-[17px] sm280:text-[16px] mt-[20px]">
              초기비용 부담 경감 <br className="hidden sm280:block" />& 합리적인 리스료
            </h4>
            <ul className="text-gray-7 font-medium text-[13px] sm280:text-[12px] mt-4 leading-[25px] sm680:flex sm680:flex-wrap sm680:gap-x-6 max-w-[430px] justify-center">
              <li>· 취등록세/공채 등 초기 비용 부담 DOWN</li>
              <li>· 할부 대비 저렴한 월 납입금</li>
              <li>· 비용처리 및 환급 혜택 제공</li>
            </ul>
          </div>
          <img src={`${IMAGE_BASE_URL}/check.png`} alt="체크 이미지" width={150} height={66} />
          <div className="text-center">
            <h4 className="text-gray-8 font-bold text-[17px] sm280:text-[16px] mt-[20px]">사업 맞춤형 특장/래핑</h4>
            <ul className="text-gray-7 font-medium text-[13px] sm280:text-[12px] mt-4 leading-[25px] sm680:flex sm680:flex-wrap sm680:gap-x-6 max-w-[430px] justify-center">
              <li>· 사업에 적합한 다양한 상용차 풀라인업</li>
              <li>· 사업용도별 맞춤 특장/래핑까지 한번에</li>
              <li>· 특장 전문 업체와의 제휴로 합리적인 가격</li>
            </ul>
          </div>

          <div className="mt-20">
            <h4 className="text-gray-8 font-bold text-[22px] mb-6 text-center leading-[26px]">
              화물차리스, <br className="hidden sm280:block sm360:block" />
              어떤점이 좋을까요?
            </h4>
            <div className="flex items-center justify-center mb-20">
              <div className="bg-gray-2 rounded-[12px] w-[126px] sm280:w-[94px] sm680:w-[170px] text-center flex flex-col items-center justify-center text-[14px] sm280:text-[12px] font-medium text-gray-8">
                <div className="bg-gray-6 text-gray-0 rounded-t-[12px] w-full font-semibold text-[15px] sm280:text-[13px] h-[30px] sm280:h-[28px] leading-[30px]">
                  할부/오토론
                </div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 pt-3 sm280:px-[6px] mt-[14px] mb-3 sm280:mb-[10px] sm680:mb-[14px]">
                  인수만 가능
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-gray-4"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] my-3 sm280:my-[10px] sm680:my-[14px]">
                  일반번호판
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-gray-4"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] my-3 sm280:my-[10px] sm680:my-[14px]">
                  있음
                  <div className="text-gray-7 text-[11px] sm280:text-[9px] break-keep leading-[14px] sm280:leading-[12px] mt-[2px]">
                    취등록세, 공채, 특장 작업비 등
                  </div>
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-gray-4"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] my-3 sm280:my-[10px] sm680:my-[14px]">
                  면세
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-gray-4"></div>

                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] my-3 sm280:my-[10px] sm680:my-[14px]">
                  이자비용만 가능
                  <div className="text-gray-7 text-[11px] sm280:text-[9px] break-keep leading-[14px] sm280:leading-[12px] mt-[2px]">
                    예: 2,200만원 차량일 경우 300만원 내외
                  </div>
                </div>

                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-gray-4"></div>

                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] sm280:px-[6px] mb-[14px] mt-3 sm280:mt-[10px] sm680:mt-[14px]">
                  법인대표 <br className="sm680:hidden" />
                  연대보증 요구
                </div>
              </div>
              <div className="bg-transparentrounded-[12px] w-[58px] h-[538px] sm280:h-[462px] sm680:h-[480px] sm280:w-[42px] sm680:w-[90px] text-center flex flex-col items-center justify-center gap-[37px] sm280:gap-[25px] sm680:gap-[28px] text-[12px] sm280:text-[10px] font-semibold text-gray-7">
                <div className="flex flex-col justify-center items-center h-[46px] bg-gray-1 w-full mt-[30px]">
                  만기시
                </div>
                <div className="flex flex-col justify-center items-center h-[46px] bg-gray-1 w-full">번호판</div>
                <div className="flex flex-col justify-center items-center h-[46px] bg-gray-1 w-full">
                  초기
                  <br className="sm680:hidden" />
                  부담
                </div>

                <div className="flex flex-col justify-center items-center h-[46px] bg-gray-1 w-full">
                  부가세
                  <br className="sm680:hidden" />
                  환급
                </div>
                <div className="flex flex-col justify-center items-center h-[46px] bg-gray-1 w-full">
                  비용
                  <br className="sm680:hidden" />
                  처리
                </div>
                <div className="flex flex-col justify-center items-center h-[46px] bg-gray-1 w-full">
                  연대
                  <br className="sm680:hidden" />
                  보증인
                </div>
              </div>

              <div
                className="bg-blue-1 rounded-[12px] w-[126px] sm280:w-[94px] sm680:w-[170px] text-center flex flex-col items-center justify-center text-[14px] sm280:text-[12px] font-medium text-blue-8"
                style={{ boxShadow: '0 0 15px 0 rgba(60, 95, 191, 0.4)' }}
              >
                <div className="bg-blue-8 text-gray-0 rounded-t-[12px] w-full font-semibold text-[15px] sm280:text-[13px] h-[30px] sm280:h-[28px] leading-[30px]">
                  화물차리스
                </div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] pt-3 font-bold my-3 sm280:my-[10px] sm680:my-[14px] leading-[17px]">
                  연장/반납/인수를 자유롭게 선택
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-blue-2"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] font-bold my-3 sm280:my-[10px] sm680:my-[14px]">
                  일반번호판
                  <div className="text-blue-7 text-[11px] sm280:text-[9px] break-keep font-medium leading-[14px] sm280:leading-[12px] mt-[2px]">
                    영업용번호판 중<br className="hidden sm680:block" /> “개별넘버”, “배넘버”도 리스가능
                  </div>
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-blue-2"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] font-bold my-3 sm280:my-[10px] sm680:my-[14px]">
                  없음
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-blue-2"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] font-bold my-3 sm280:my-[10px] sm680:my-[14px]">
                  부가세 환급 가능 <div className="text-blue-7 text-[11px] break-keep font-medium">월리스료 대상</div>
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-blue-2"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] font-bold my-3 sm280:my-[10px] sm680:my-[14px]">
                  가능
                  <div className="text-blue-7 text-[11px] sm280:text-[9px] break-keep font-medium leading-[14px] sm280:leading-[12px] mt-[2px]">
                    예: 월리스료, 유류비 등<br />연 1,500만원까지 비용처리 가능
                  </div>
                </div>
                <div className="h-[0.5px] w-[90px] sm280:w-[70px] sm680:w-[130px] bg-blue-2"></div>
                <div className="flex flex-col justify-center items-center h-[60px] sm280:h-[51px] sm680:h-[47px] px-3 sm280:px-[6px] mb-3 font-bold my-3 sm280:my-[10px] sm680:my-[14px]">
                  법인 단독 <br className="sm680:hidden" />
                  계약 가능
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t-8 border-gray-1">
          <div className="mt-[40px] text-center flex flex-col justify-center items-center px-6">
            <h5 className="text-gray-8 font-bold text-[16px] break-keep sm280:w-[150px] text-center">
              {data?.truckName}
            </h5>
            <div className="flex justify-center gap-2 mt-3">
              <FilledBadge
                text={'디젤 카고 신차'}
                textColor={'text-blue-8'}
                bgColor={'bg-blue-5'}
                rounded={'rounded-full'}
                fontWeight={'font-semibold'}
                paddingX="px-3"
                fontSize="text-[12px]"
              />
              <FilledBadge
                text={'한정 수량'}
                textColor={'text-blue-8'}
                bgColor={'bg-blue-5'}
                rounded={'rounded-full'}
                fontWeight={'font-semibold'}
                paddingX="px-3"
                fontSize="text-[12px]"
              />
            </div>
            <div className="relative">
              {data?.truckImageUrls?.map((item, index) => (
                <div className="relative w-full px-6 pt-6 pb-4" key={index}>
                  <div className="absolute inset-0 z-0 bg-gray-800 opacity-40 rounded-[10px] mx-6 mt-6 mb-4"></div>
                  <div className="absolute inset-0 z-0 flex items-center justify-center">
                    <span className="font-semibold text-[20px] text-white">재고 소진</span>
                  </div>
                  <img src={item} alt="truck" className="w-full" />
                </div>
              ))}
            </div>
          </div>

          <div className="mx-6 sm680:flex sm680:gap-3">
            <dl className="bg-gray-1 text-gray-11 px-5 py-[18px] w-full rounded-[12px] text-[14px]">
              <dt className="font-semibold">색상</dt>
              <dd className="font-medium">외장: 클리어 화이트(흰색)</dd>
              <dd className="mb-5 font-medium">내장: 내장기본색(검정색)</dd>

              <dt className="font-semibold">옵션</dt>
              <dd className="mb-5 font-medium">오토(5단 자동변속기)</dd>

              <dt className="font-semibold">차량가</dt>
              <dd className="font-medium">20,330,000원</dd>
            </dl>

            <div className="sm280:flex-col sm680:flex-col sm280:text-left sm680:text-left px-5 py-[18px] flex bg-gray-1 font-semibold rounded-[12px] mt-3 sm680:mt-0 w-full justify-center sm680:justify-start items-center sm280:items-start sm680:items-start text-center">
              <dl className="px-2 sm280:px-0 sm680:px-0">
                <dt className="text-gray-11 text-[14px]">리스료</dt>
                <dd className="text-gray-8 text-[16px]">월 {formatNumber(String(data?.monthlyLeaseFee))}원</dd>
              </dl>
              <div
                className={
                  'w-[1px] h-[26px] sm280:w-[49px] sm680:w-[49px] sm280:h-[1px] sm680:h-[1px] bg-gray-6 m-4 sm280:mx-0 sm680:mx-0'
                }
              ></div>
              <dl className="px-2 sm280:px-0 sm680:px-0">
                <dt className="text-gray-11 text-[14px]">잔존가치</dt>
                <dd className="text-gray-8 text-[16px]">{formatNumber(String(data?.residualValue))}원</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="text-[14px] mt-5 mb-10 font-medium text-gray-7 text-left px-6">
          <span>[견적 예시 조건]</span>
          <p>
            계약기간: 60개월 / 약정주행거리: 연 4만km 이하 / 무보증 조건 / 정비: 일반형
            <br />
            <br />
            재고 대수가 한정되어 조기에 마감될 수 있으며, 재고는 협력사와 상담 문의를 통해 확인할 수 있습니다.
            <br />
            <br />* 상기 주요 차량 외 차량도 상담 가능.
          </p>
        </div>
        <div className="bg-gray-2 px-4 py-6 text-gray-7 text-[13px] leading-[16px] font-medium pb-[64px]">
          직트럭은 협력사의 신차리스 상품에 직접 관여하지 않습니다. <br className="hidden sm680:block" />
          견적 상담에 따라 리스료는 변동될 수 있습니다.
          <br />
          <br />
          협력사
          <br />
          롯데오토리스 BIZ CAR의 Sales Partner 원드림종합파이낸셜
        </div>
      </div>
    </>
  );
};

export default NewCarLeaseGuideView;

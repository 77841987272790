import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon, ChevronUpIcon } from '../Icon';
import { fetchSimilarProducts } from '@/api/products/getProducts';
import { colors } from '@/const/colors';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatDistanceToThousandKm, formatPrice, getFormatDate } from '@/utils/common';
import { Button } from '@mui/material';

interface ProductProps {
  similarSearchParams: ProductSearchParams | undefined | ProductDetailResponse;
}
interface ParamsType {
  year: string | undefined;
  tons: string | undefined;
  manufacturerCategoriesId: number | undefined;
  modelId: number | undefined;
  loaded: string | undefined;
  loadedInnerLength: number | undefined;
}

const SimilarProductList: React.FC<ProductProps> = ({ similarSearchParams }) => {
  const navigate = useNavigate();
  const [showItem, setShowItem] = useState<ShowItemState>({});
  const { showToast } = useToastContext();

  const params = {
    year: similarSearchParams?.year,
    tons: similarSearchParams?.tons,
    manufacturerCategoriesId: similarSearchParams?.manufacturerCategories?.id,
    modelId: similarSearchParams?.model?.id,
    loaded: similarSearchParams?.loaded?.code,
    loadedInnerLength: similarSearchParams?.loadedInnerLength,
  };
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.append(key, value.toString());
  });

  const {
    data: similarProduct,
    error,
    isLoading,
    isFetching,
  } = useQuery<ProductDetailResponse[]>(
    ['get-similar-products', queryParams],
    () => fetchSimilarProducts(queryParams),
    {
      enabled: queryParams.size > 0,
      refetchOnWindowFocus: false,
      onError: () => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      },
    },
  );

  const showPrice = (item: ProductDetailResponse) => {
    return formatPrice(String(item?.actualSalePrice ? item.actualSalePrice : item?.price));
  };

  const toggleItem = (id: number) => {
    setShowItem((prevShowItem) => ({
      ...prevShowItem,
      [id]: !prevShowItem[id],
    }));
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full">
        <section>
          {similarProduct && similarProduct?.length !== 0 ? (
            <>
              <h2 className="text-lg font-semibold my-6 pt-6 text-gray-8">해당 차종의 최근 판매 내역</h2>
              {similarProduct &&
                similarProduct.map((item, index) => (
                  <div key={index}>
                    {index < 3 && (
                      <ul className="mb-3.5 min-w-[248px] min-h-[160px]">
                        <li
                          className={`flex relative border border-gray-3 p-4 items-center ${
                            showItem[item.id] ? 'rounded-t-lg' : 'rounded-lg'
                          }`}
                          onClick={() => toggleItem(item.id)}
                        >
                          <div className="h-[117px] w-[117px] min-w-[80px]">
                            <img
                              src={item?.productsImage?.frontSideImageUrl}
                              alt="트럭이미지"
                              className="rounded-lg w-full h-full object-cover"
                            ></img>
                          </div>
                          <div className="pl-4 flex-1 flex items-center justify-between min-w-[124px] min-h-[120px]">
                            <dl className="">
                              <dt className="font-semibold">
                                {item.tons}t・{getFormatDate(item.firstRegistrationDate, ['year', 'month'], true)}・
                                {item.loaded?.desc}
                              </dt>
                              <dd className="text-gray-6">
                                {item?.manufacturerCategories.name} {item?.model?.name} {item?.tons}톤{' '}
                                {item?.axis?.desc}
                                {item?.loaded?.desc}
                              </dd>
                              <dd className="font-semibold text-lg mt-2">{showPrice(item)}</dd>
                            </dl>
                            <div>
                              {showItem[item.id] ? (
                                <ChevronUpIcon color={colors.gray[4]}></ChevronUpIcon>
                              ) : (
                                <ChevronDownIcon color={colors.gray[4]}></ChevronDownIcon>
                              )}
                            </div>
                          </div>
                        </li>

                        {showItem[item.id] ? (
                          <dl className="table w-full bg-gray-2 rounded-b-lg border-b border-x border-gray-4 text-gray-8 p-4">
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-7 py-1">톤수</dt>
                              <dd className="table-cell font-semibold">{item?.tons}t</dd>
                            </div>
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-7 py-1">연식</dt>
                              <dd className="table-cell font-semibold">{item?.year}</dd>
                            </div>
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-7 py-1">제조사</dt>
                              <dd className="table-cell font-semibold">{item?.manufacturerCategories?.name}</dd>
                            </div>
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-7 py-1">적재함 종류</dt>
                              <dd className="table-cell font-semibold">{item?.loaded?.desc}</dd>
                            </div>
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-7 py-1">가격</dt>
                              <dd className="table-cell font-semibold">{showPrice(item)}</dd>
                            </div>
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-7 py-1">주행거리</dt>
                              <dd className="table-cell font-semibold">
                                {formatDistanceToThousandKm(String(item?.distance))}만km
                              </dd>
                            </div>
                            <div className="table-row">
                              <dt className="table-cell min-w-[100px] text-gray-500 py-1">가변축</dt>
                              <dd className="table-cell font-semibold">{item?.axis?.desc}</dd>
                            </div>
                          </dl>
                        ) : null}
                      </ul>
                    )}
                  </div>
                ))}
              {!!similarProduct?.length && similarProduct?.length > 3 && (
                <Button
                  className="w-full"
                  onClick={() =>
                    navigate('/products/similar', {
                      state: { queryParams: params, truckName: similarProduct[0]?.truckName },
                    })
                  }
                  sx={{
                    fontSize: '18px',
                    color: '#414141',
                    bgcolor: '#FFFFFF',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    paddingRight: '6px',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                  }}
                >
                  판매 내역&nbsp;<span className="text-primary">{similarProduct?.length}건</span>&nbsp;더보기
                </Button>
              )}
            </>
          ) : null}
        </section>
      </div>
    </>
  );
};

export default SimilarProductList;

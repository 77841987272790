import React, { useEffect, useState } from 'react';
import { NavigationType, useLocation, useNavigate, useNavigationType } from 'react-router-dom';

import OtherExpenseDetail from '@/components/Drive/OtherExpense/OtherExpenseDetail';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import DefaultHelmet from '@/metadatas/DefaultHelmet';

const OtherExpenseDetailList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);

  const [selectedDeleteList, setSelectedDeleteList] = useState<number[]>([]);

  const data = location?.state?.data || [];

  const [isNoData, setIsNoData] = useState(data?.lenth === 0);

  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      navigate(-2);
    }
  }, [navigationType]);

  const handleChangeMode = () => {
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      setSelectedDeleteList([]);
    }
  };

  const menuHeaderIcons: MenuHeaderIcon[] = [
    { icon: isNoData ? '' : isEditMode ? <>닫기</> : <>편집</>, onClickIcon: handleChangeMode },
  ];

  const updateEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const updateIsNodata = (isNodata: boolean) => {
    setIsNoData(isNodata);
  };

  return (
    <>
      <DefaultHelmet page="drive" />

      <MenuHeader title={`기타내역 ${!!data.data ? '' : '추가'}`} icons={menuHeaderIcons}></MenuHeader>
      <div className="w-full pt-[60px]">
        <OtherExpenseDetail
          isEditMode={isEditMode}
          selectedList={selectedDeleteList}
          updateEditMode={updateEditMode}
          updateIsNodata={updateIsNodata}
        />
      </div>
    </>
  );
};

export default OtherExpenseDetailList;

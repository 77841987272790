import React, { ReactNode } from 'react';

export interface ErrorProps {
  icon: ReactNode;
  title: string | undefined;
  description: string;
}

const Error = ({ icon, title, description }: ErrorProps) => {
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center mb-10">
        <span className="mb-12">{icon}</span>
        <span className="font-semibold text-[24px] mt-12 text-gray-8">{title}</span>
        <span
          className="text-gray-7 text-[18px] leading-[25px] mt-5 break-keep text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        ></span>
      </div>
    </>
  );
};

export default Error;

import React from 'react';

import { SearchOutlineIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

export interface SearchInputProps {
  placeholder: string;
  keyword: string;
  readonly?: boolean;
  onChangeKeywordInput?: () => void;
  useFilterClearBtn?: boolean;
  onClickFilterClearBtn?: () => void;
}

const SearchInput = ({ placeholder, keyword, onChangeKeywordInput, readonly = false }: SearchInputProps) => {
  return (
    <div className="px-4">
      <div className="z-10 flex-1 searchBox flex bg-gray-1 rounded-lg items-center pr-4 text-gray-6 rounded-lg ">
        <input
          name="keyword"
          className="w-11/12 bg-gray-1 text-gray-8 mx-4 my-[11px]"
          type="text"
          readOnly={readonly}
          placeholder={placeholder}
          value={keyword || ''}
          onChange={onChangeKeywordInput}
          style={{ outline: 'none' }}
        />
        <SearchOutlineIcon color={colors.gray[6]} strokeWidth={0.1}></SearchOutlineIcon>
      </div>
    </div>
  );
};

export default SearchInput;

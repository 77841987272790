import MenuHeader from '@/components/Header/MenuHeader';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CarGuide = () => {
  const labelList = ['온라인', '오프라인', '차량 계약', '사후 관리'];
  const [tabNum, setTabNum] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <MenuHeader title="차량 확인 가이드"></MenuHeader>
      <div className="w-full p-4 pt-[60px]">
        <ul className="mt-4">
          <li className="flex bg-gray-50 rounded-lg mb-3" onClick={() => navigate('/guide/perchase')}>
            <div className="flex items-center p-4 w-full">
              <DescriptionRoundedIcon className="flex-none" htmlColor="#919191"></DescriptionRoundedIcon>
              <dd className="text-gray-600 font-semibold ml-4 w-full">화물차 구매 가이드</dd>
              <ArrowForwardIosRoundedIcon
                fontSize="small"
                className="text-gray-200 items-end flex-none"
              ></ArrowForwardIosRoundedIcon>
            </div>
          </li>
          <li className="flex bg-gray-50 rounded-lg mb-3" onClick={() => navigate('/guide/sale')}>
            <div className="flex items-center p-4 w-full">
              <DescriptionRoundedIcon className="flex-none" htmlColor="#919191"></DescriptionRoundedIcon>
              <dd className="text-gray-600 font-semibold ml-4 w-full">화물차 판매 가이드</dd>
              <ArrowForwardIosRoundedIcon
                fontSize="small"
                className="text-gray-200 items-end flex-none"
              ></ArrowForwardIosRoundedIcon>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
export default CarGuide;

import React from 'react';

import { ZigtruckRoundedColorIcon } from '@/components/Icon';

interface TopBannerProps {
  isShow: boolean;
}

function TopBanner({ isShow }: TopBannerProps) {
  const onClickBtn = () => {
    window.open('intent://www.zigtruck.io/#Intent;package=com.zigtruck.android;scheme=https;end');
  };

  return (
    <>
      {isShow && (
        <div
          className="flex justify-center items-center w-full h-[70px] absolute top-0 left-0] bg-gray-3 mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] py-4 px-4 xs:px-3 xxs:px-2"
          onClick={onClickBtn}
        >
          <ZigtruckRoundedColorIcon width={40} height={40}></ZigtruckRoundedColorIcon>
          <div className="mx-2 xs:mx-1 xxs:mx-1 text-[15px] xs:tesx-[13px] xxs:text-[13px]">
            앱으로 빠르게 직거래하세요!
          </div>
          <div className="bg-white text-primary font-semibold py-2 px-3 xs:px-2 xxs:px-2 rounded-full text-xs xs:text-xs xxs:text-xs cursor-pointer">
            앱 다운로드
          </div>
        </div>
      )}
    </>
  );
}

export default TopBanner;

import React, { SVGProps } from 'react';

const SvgPhoneRingOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#phone-ring-outline-icon_svg__a)" clipRule="evenodd">
      <path
        stroke={props.color || 'currentColor'}
        strokeWidth={1.5}
        d="M2.457 8.557c0-1.183.96-2.143 2.143-2.143h.98c.614 0 1.15.418 1.299 1.015l.79 3.159a1.34 1.34 0 0 1-.496 1.396l-.924.693c-.096.072-.117.178-.09.251a8.06 8.06 0 0 0 4.784 4.784c.074.027.179.006.25-.09l.694-.924a1.34 1.34 0 0 1 1.396-.496l3.16.79a1.34 1.34 0 0 1 1.014 1.3v.98c0 1.183-.96 2.142-2.143 2.142h-1.607c-6.213 0-11.25-5.037-11.25-11.25V8.557Z"
      />
      <path
        fill={props.color || 'currentColor'}
        fillRule="evenodd"
        d="M-3 14.172c.072 2.056.631 3.99 1.566 5.686v-3.05a11.565 11.565 0 0 1-.407-2.636H-3ZM10.391 2.16V1c6.575.337 11.833 5.66 12.066 12.262h-1.159C21.067 7.3 16.327 2.495 10.391 2.16Zm8.721 11.102a9.264 9.264 0 0 0-8.72-8.79v1.16a8.106 8.106 0 0 1 7.56 7.63h1.16Zm-3.485 0a5.79 5.79 0 0 0-5.236-5.31v1.164a4.633 4.633 0 0 1 4.074 4.146h1.162Z"
      />
    </g>
    <defs>
      <clipPath id="phone-ring-outline-icon_svg__a">
        <rect width={24} height={24} fill="#fff" rx={6} />
      </clipPath>
    </defs>
  </svg>
);
SvgPhoneRingOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPhoneRingOutlineIcon;

import { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';

import { WEB_SOCKET_URL } from '@/const/url';
import { Client, IMessage, StompHeaders } from '@stomp/stompjs';

interface WebSocketOptions {
  onMessage: (message: IMessage) => any;
  connectHeaders?: StompHeaders;
  disconnectHeaders?: StompHeaders;
}

const useWebSocket = (url: string, options: WebSocketOptions) => {
  const [stompClient, setStompClient] = useState<Client | null>(null);
  const stompClientRef = useRef<Client | null>(null);
  const reconnectTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const newConnect = (isInitialConnect: boolean) => {
      const isChatUrl = url.includes('chat');

      const createStompClient = () => {
        const socket = new SockJS(WEB_SOCKET_URL);
        const client = new Client({
          webSocketFactory: () => socket,
          connectHeaders: options.connectHeaders,
          disconnectHeaders: options.disconnectHeaders,
        });
        return client;
      };

      if (isChatUrl || isInitialConnect || !stompClientRef.current) {
        const client = createStompClient();
        stompClientRef.current = client;
        client.activate();

        client.onConnect = (frame) => {
          console.log('Connected: ' + frame);
          const subscription = client.subscribe(url, (message: IMessage) => {
            options.onMessage(message);
          });
          console.log('Subscribed:', subscription.id);
          setStompClient(client);
        };

        client.onStompError = (frame) => {
          console.error('Stomp Error:', frame);
        };

        client.onWebSocketClose = () => {
          console.log('WebSocket 연결이 닫혔습니다.');
          if (options?.disconnectHeaders) {
            return;
          }

          if (reconnectTimerRef.current) {
            clearTimeout(reconnectTimerRef.current);
          }
          reconnectTimerRef.current = setTimeout(() => {
            newConnect(false);
          }, 5000);
        };
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('Reconnecting WebSocket...');
        newConnect(false);
      } else {
        console.log('Disconnecting WebSocket...');

        const client = stompClientRef.current;
        if (client && client.connected) {
          if (options?.disconnectHeaders) {
            client.publish({
              destination: '/app/disconnect',
              headers: options.disconnectHeaders,
              body: '',
            });

            client
              .deactivate()
              .then(() => {
                setStompClient(null);
                console.log('Disconnected from WebSocket.');
              })
              .catch((error) => {
                console.error('Error during deactivation', error);
              });
          }
        }
      }
    };

    newConnect(true); // 초기 연결 시도

    // 페이지의 가시성 상태 변경 이벤트 리스너 등록
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // 컴포넌트가 언마운트될 때 처리
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      const client = stompClientRef.current;
      if (client && client.connected) {
        console.log('Disconnecting from WebSocket...');

        if (options?.disconnectHeaders) {
          client.publish({
            destination: '/app/disconnect',
            headers: options?.disconnectHeaders,
            body: '',
          });
        }

        client
          .deactivate()
          .then(() => {
            stompClientRef.current = null;
            setStompClient(null);
            console.log('Disconnected from WebSocket.');
          })
          .catch((error) => {
            console.error('Error during deactivation', error);
          });
      }
    };
  }, [url]);

  return stompClient;
};

export default useWebSocket;

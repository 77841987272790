import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface PullToRefreshProps {
  children: ReactNode;
  onRefresh: () => void;
  maxDistance: number;
  disabled: boolean;
  loadingComponent: ReactNode;
}

const PullToRefresh = ({ children, onRefresh, maxDistance, disabled, loadingComponent }: PullToRefreshProps) => {
  const spinnerRef = useRef<HTMLDivElement>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [startY, setStartY] = useState(0);
  const [isTouch, setIsTouch] = useState(false);
  const [pulled, setPulled] = useState(false);

  useEffect(() => {
    const touchMoveListener = (e: TouchEvent) => {
      if (!disabled && isTouch && pulled) {
        onMove(e.touches[0].clientY);
      }
    };

    const touchEndListener = () => {
      if (!disabled && isTouch && pulled) {
        handleEnd();
      }
    };

    document.addEventListener('touchmove', touchMoveListener, { passive: false });
    document.addEventListener('touchend', touchEndListener);

    return () => {
      document.removeEventListener('touchmove', touchMoveListener);
      document.removeEventListener('touchend', touchEndListener);
    };
  }, [isTouch, pulled, disabled]);

  const resetToInitial = () => {
    if (spinnerRef.current) {
      spinnerRef.current.style.height = '0';
      spinnerRef.current.style.willChange = 'unset';
    }
    setPulled(false);
    setIsRefreshing(false);
  };

  const onStart = (y: number, touch: boolean) => {
    if (!disabled) {
      setStartY(y);
      setIsTouch(touch);
      setPulled(true);
      if (spinnerRef.current) {
        spinnerRef.current.style.willChange = 'height';
      }
    }
  };

  const onMove = (y: number) => {
    if (!disabled && pulled && spinnerRef.current) {
      const moveY = y;
      const pulledDistance = Math.min(Math.pow(moveY - startY, 0.875), maxDistance);

      if (pulledDistance > 0) {
        spinnerRef.current.style.height = `${pulledDistance}px`;

        if (pulledDistance >= maxDistance) {
          setIsRefreshing(true);
        } else {
          setIsRefreshing(false);
        }
      } else {
        resetToInitial();
      }
    }
  };

  const handleEnd = async () => {
    if (!disabled && pulled) {
      if (isRefreshing) {
        try {
          await onRefresh();
          await new Promise((resolve) => setTimeout(resolve, 500)); // 최대 0.5초까지 기다림
          resetToInitial();
        } catch (error) {
          console.error('Error while refreshing:', error);
        }
      } else {
        setTimeout(() => resetToInitial(), 500); // 0.5초 지연 후 초기화
      }
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (!disabled && window.scrollY === 0) {
      onStart(e.touches[0].clientY, true);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!disabled && window.scrollY === 0) {
      onStart(e.clientY, false);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!disabled && !isTouch && pulled) {
      onMove(e.clientY);
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    if (!disabled && !isTouch) {
      handleEnd();
    }
  };

  return (
    <div>
      <div ref={spinnerRef}>{isRefreshing && loadingComponent}</div>
      <div
        onTouchStart={handleTouchStart}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleEnd}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        {children}
      </div>
    </div>
  );
};

export default PullToRefresh;

import React from 'react';

import { colors } from '@/const/colors';
import Skeleton from '@mui/material/Skeleton';

const JobListSkeleton = () => {
  const skeletonItem = (
    <li className="flex flex-col gap-4 border border-gray-3 rounded-[8px] p-4 mb-6">
      <Skeleton variant="rounded" width={65} height={25} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="rounded" width="80%" height={21} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="rounded" width="100%" animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="rounded" width="100%" animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="rounded" width="100%" animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="rounded" width="100%" animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton variant="rounded" width="100%" animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
      <Skeleton
        variant="rounded"
        width="100%"
        animation="wave"
        height={52}
        sx={{ bgcolor: colors.gray[2], marginTop: '8px' }}
      />
    </li>
  );

  return (
    <>
      <ul className="mx-4 mt-6 mb-3">
        {Array.from({ length: 3 }).map((_, index) => (
          <React.Fragment key={index}>{skeletonItem}</React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default JobListSkeleton;

import React from 'react';
import { Link } from 'react-router-dom';

import {
  CalendarCheckOutlineIcon,
  ChevronRightIcon,
  IncreasingTrendGraphBoldIcon,
  JobBoldIcon,
  LicensePlateBoldIcon,
  MoneyCircleOutlineIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';

const ManageMentSubMenus = () => {
  const handleSaveEntryPoint = () => {
    sessionStorage.setItem('entryPoint', location.pathname);
  };

  return (
    <div className="px-4">
      <ul>
        <li className="py-5 list-none">
          <Link to={'/price-trend'} className="flex items-center cursor-pointer">
            <IncreasingTrendGraphBoldIcon color={colors.gray[7]} width={28} height={28}></IncreasingTrendGraphBoldIcon>
            <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">시세검색</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="py-5 list-none">
          <Link to={'/license'} className="flex items-center cursor-pointer">
            <LicensePlateBoldIcon color={colors.gray[7]} width={28} height={28}></LicensePlateBoldIcon>
            <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">번호판거래</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="py-5 list-none">
          <Link to={'/job'} className="flex items-center cursor-pointer">
            <JobBoldIcon color={colors.gray[7]} width={28} height={28}></JobBoldIcon>
            <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">일자리구하기</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>

        <li className="py-5 list-none">
          <Link to={'/drive'} className="flex items-center cursor-pointer" onClick={handleSaveEntryPoint}>
            <CalendarCheckOutlineIcon color={colors.gray[7]} width={28} height={28} />
            <p className="flex-1 ml-4 text-lg font-semibold text-gray-8">운행일지</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>

        <li className="py-5 list-none">
          <Link
            to={'/new-car-lease'}
            className="flex items-center justify-center cursor-pointer"
            onClick={handleSaveEntryPoint}
          >
            <MoneyCircleOutlineIcon color={colors.gray[7]} width={28} height={28} />
            <div className="flex items-center flex-1 ml-4 text-lg font-semibold text-gray-8">신차리스</div>

            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ManageMentSubMenus;

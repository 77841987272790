import React, { ReactNode } from 'react';

import { Fab } from '@mui/material';

interface FloatingButton {
  bgColor: string;
  textColor: string;
  icon: ReactNode;
  title: string;
  minWidth: number;
  minHeight: number;
  onClick: () => void;
}

function FloatingButton({ bgColor, textColor, icon, title, minWidth, minHeight, onClick }: FloatingButton) {
  return (
    <div className="flex flex-col fixed w-full mx-auto my-0 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px]">
      <div className="text-center py-4">
        <Fab
          sx={{
            backgroundColor: bgColor,
            color: textColor,
            minWidth: minWidth,
            minHeight: minHeight,
            height: minHeight,
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: bgColor,
            },
          }}
          onClick={onClick}
        >
          {icon}
          <span className="ml-1">{title}</span>
        </Fab>
      </div>
    </div>
  );
}

export default FloatingButton;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import PasswordSuffix from '../Common/Input/TextInput/PasswordSuffix';
import TextInputLabelUp from '../Common/Input/TextInputLabelUp';
import AlertModal from '../Common/Modal/AlertModal';
import apiManager from '@/api/AxiosInstance';
import MenuHeader from '@/components/Header/MenuHeader';
import { colors } from '@/const/colors';
import {
  COMMON_TOAST_ERROR,
  NAME_VALIDATION_LENGTH_MESSAGE,
  NAME_VALIDATION_MESSAGE,
  PASSWORD_NOT_MATCH_MESSAGE,
  PASSWORD_VALIDATION_MESSAGE,
} from '@/const/errorMessage';
import { THEUNBAN } from '@/const/referralCode';
import { useToastContext } from '@/contexts/Common/ToastContext';
import usePartnerSession from '@/hooks/usePartnerSession';
import useSetMemberData from '@/hooks/useSetMemberData';
import { getReferralCode } from '@/utils/referralCodeUtils';
import { validateName, validatePassword } from '@/utils/validation';

interface SignUpProps {
  memberInfo: MemberInfo;
  updateMemberInfo: (data: MemberInfo) => void;
  isFirst: boolean;
  setIsFirst: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserRegistrationForm = ({ memberInfo, updateMemberInfo, setIsFirst }: SignUpProps) => {
  const [cookies, setCookie, removeCookie] = useCookies(['redirect_url']);

  const nameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmedPasswordRef = useRef<HTMLInputElement>(null);
  const referralCodeRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const setMemberData = useSetMemberData('');
  const [nameMessage, setNameMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const { showToast } = useToastContext();
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmedPasswordMessage, setConfirmedPasswordMessage] = useState('');
  const [errorConfirmedPassword, setErrorConfirmedPassword] = useState(false);

  const [referralCode, setReferralCode] = useState('');
  const [referralCodeError, setReferralCodeError] = useState(false);
  const [referralCodeErrorMessage, setReferralCodeErrorMessage] = useState('');

  const [isValidCode, setIsValidCode] = useState(false);

  const [shouldFetch, setShouldFetch] = useState(false);

  const [isShowBasicPopup, setIsShowBasicPopup] = useState(false);

  const [loading, setLoading] = useState(false);

  const [nameInput, setNameInput] = useState<string>('');
  const [passwordInput, setPasswordInput] = useState<string>();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmedPassword, setIsShowConfirmedPassword] = useState(false);

  const partnerCode = usePartnerSession();

  useEffect(() => {
    if (partnerCode) {
      const code = getReferralCode(partnerCode);
      setReferralCode(code);
    }
  }, [partnerCode]);

  useEffect(() => {
    updateMemberInfo({ ...memberInfo, name: nameInput });
  }, [nameInput]);

  useEffect(() => {
    updateMemberInfo({ ...memberInfo, password: passwordInput });
  }, [passwordInput]);

  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNameInput(value);

      if (validateName(value) === false) {
        setNameMessage(NAME_VALIDATION_MESSAGE);
        setErrorName(true);
      } else if (value.length > 20) {
        setNameMessage(NAME_VALIDATION_LENGTH_MESSAGE);
        setErrorName(true);
      } else {
        setNameMessage('');
        setErrorName(false);
      }
    },
    [confirmedPassword],
  );

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== null && value !== undefined && value.length > 0) {
      if (validatePassword(value) === false) {
        setPasswordMessage(PASSWORD_VALIDATION_MESSAGE);
        setErrorPassword(true);
      } else {
        setPasswordMessage('');
        setErrorPassword(false);
      }
    }

    if (confirmedPassword === '') {
      setPasswordInput(value);
      return;
    } else {
      if (confirmedPassword === value) {
        setConfirmedPasswordMessage('');
        setErrorConfirmedPassword(false);
      } else {
        setConfirmedPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorConfirmedPassword(true);
      }
    }
    setPasswordInput(value);
  };

  const onChangeConfirmedPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setConfirmedPassword(value);
      if (passwordInput === value) {
        setConfirmedPasswordMessage('');
        setErrorConfirmedPassword(false);
      } else {
        setConfirmedPasswordMessage(PASSWORD_NOT_MATCH_MESSAGE);
        setErrorConfirmedPassword(true);
      }
    },
    [passwordInput],
  );

  const handleChangeReferralCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setReferralCode(value);
    setIsValidCode(false);
  };

  const isSuccessSignUp = useMemo(() => {
    let isValidReferralCode = true;
    if (!partnerCode && referralCode) {
      isValidReferralCode = isValidCode;
    }
    return (
      nameInput &&
      passwordInput &&
      confirmedPassword &&
      !errorName &&
      !errorPassword &&
      !errorConfirmedPassword &&
      isValidReferralCode
    );
  }, [nameInput, passwordInput, confirmedPassword, referralCode, isValidCode]);

  const signUpMember = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { marketing, phoneNumber } = memberInfo;
    const requestData = {
      marketing,
      phoneNumber,
      name: nameInput,
      password: passwordInput,
      referralCode: referralCode,
    };
    apiManager
      .post('/api/v1/members/sign-up', requestData, {
        withCredentials: true,
      })
      .then((response) => {
        const { accessToken } = response.data.authTokenInfo;
        setMemberData(accessToken);
        if (cookies && cookies.redirect_url) {
          navigate(cookies.redirect_url, { replace: true });
          removeCookie('redirect_url', { path: '/' });
        } else {
          navigate(partnerCode === THEUNBAN ? '/products' : '/', { replace: true });
        }
      })
      .catch((error) => {
        if (error.code === 'PARTNER_NOT_FOUND') {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goPrev = () => {
    setIsFirst(true);
  };

  const queryParams = new URLSearchParams();
  queryParams.append('referralCode', referralCode);

  // 추천인 코드 인증 api 호출 주석처리
  // const { data, error, isLoading } = useQuery(['validateReferralCode'], () => getRefferalCodesValidate(queryParams), {
  //   enabled: shouldFetch,
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   onSuccess: () => {
  //     setIsShowBasicPopup(true);
  //     setIsValidCode(true);
  //     setReferralCodeError(false);
  //     setReferralCodeErrorMessage('');
  //   },
  //   onError: (error: AxiosError) => {
  //     setReferralCodeError(true);
  //     setReferralCodeErrorMessage(error.message);
  //     setIsValidCode(false);
  //   },
  //   onSettled: () => {
  //     setShouldFetch(false);
  //   },
  // });

  // const handleClickButton = () => {
  //   setShouldFetch(true);
  // };

  const handleClickOk = () => {
    setIsShowBasicPopup(false);
  };

  const togglePasswordVisibility = (isShow: boolean, setIsShow: React.Dispatch<React.SetStateAction<boolean>>) => {
    setIsShow(!isShow);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'name') {
        nameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'password') {
        passwordRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'confirmedPassword') {
        scrollToBottom();
      } else if (name === 'referralCodeRef') {
        scrollToBottom();
      }
    } else {
      nameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      passwordRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      confirmedPasswordRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      referralCodeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  };

  return (
    <>
      <MenuHeader
        title="전화번호로 회원가입"
        onClickPrevBtn={() => {
          goPrev();
        }}
      ></MenuHeader>
      <div className="flex flex-col justify-between w-full px-4 pt-4 h-[100dvh]">
        <section className="pb-8">
          <p className="pt-6 mt-6 text-base text-primary">마지막 단계 입니다!</p>
          <p className="text-[22px] text-gray-8 font-bold mb-[30px] mt-2">정보를 입력해주세요</p>

          <div className="flex flex-col gap-[30px]">
            <TextInputLabelUp
              ref={nameRef}
              name="name"
              label="이름"
              placeholder="이름을 입력해주세요."
              value={nameInput || ''}
              onChange={onChangeName}
              onFocus={(e) => scrollIntoView(e)}
              error={errorName}
              errorMsg={nameMessage}
              inputMode="text"
              type="text"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  passwordRef.current?.focus();
                }
              }}
            ></TextInputLabelUp>

            <TextInputLabelUp
              ref={passwordRef}
              name="password"
              label="비밀번호"
              placeholder="영문, 숫자, 특수문자 8~20자리"
              value={passwordInput || ''}
              onChange={onChangePassword}
              onFocus={(e) => scrollIntoView(e)}
              error={errorPassword}
              errorMsg={passwordMessage}
              type={isShowPassword ? 'text' : 'password'}
              suffix={
                <PasswordSuffix
                  isShow={isShowPassword}
                  toggleVisibility={() => togglePasswordVisibility(isShowPassword, setIsShowPassword)}
                />
              }
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  confirmedPasswordRef.current?.focus();
                }
              }}
            />

            <TextInputLabelUp
              ref={confirmedPasswordRef}
              name="confirmedPassword"
              label="비밀번호 확인"
              placeholder="비밀번호를 한번 더 입력해주세요."
              value={confirmedPassword}
              onChange={onChangeConfirmedPassword}
              onFocus={(e) => scrollIntoView(e)}
              error={errorConfirmedPassword}
              errorMsg={confirmedPasswordMessage}
              type={isShowConfirmedPassword ? 'text' : 'password'}
              suffix={
                <PasswordSuffix
                  isShow={isShowConfirmedPassword}
                  toggleVisibility={() => togglePasswordVisibility(isShowConfirmedPassword, setIsShowConfirmedPassword)}
                />
              }
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  referralCodeRef.current?.focus();
                }
              }}
            />
            {partnerCode && (
              <div className="relative flex justify-between w-full align-center">
                <TextInputLabelUp
                  ref={referralCodeRef}
                  name="referralCode"
                  label="추천인"
                  placeholder="추천인 코드를 입력해주세요."
                  value={referralCode}
                  onChange={handleChangeReferralCode}
                  onFocus={(e) => scrollIntoView(e)}
                  error={referralCodeError}
                  errorMsg={referralCodeErrorMessage}
                  type="text"
                  disabled={isValidCode || !!partnerCode}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      // enter눌렀을 때 추천인 코드 인증 api호출 하는 부분 주석 처리
                      // if (!partnerCode) {
                      // handleClickButton();
                      // } else {
                      signUpMember();
                      // }
                    }
                  }}
                ></TextInputLabelUp>
                {/* 추천인 코드 인증하기 버튼 주석 처리 */}
                {/* <div className="absolute right-0 bottom-2">
                  <BasicButton
                    name="확인"
                    bgColor={colors.primary}
                    borderColor={colors.primary}
                    textColor={colors.gray[0]}
                    fontSize={14}
                    width={80}
                    height={30}
                    borderRadius={12}
                    fontWeight="semibold"
                    onClick={() => handleClickButton()}
                    isDisabled={!referralCode || isValidCode}
                  ></BasicButton>
                </div> */}
              </div>
            )}
          </div>
        </section>
        <div className="pb-2">
          <BasicButton
            name="완료"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            borderRadius={10}
            fontWeight="semibold"
            onClick={() => signUpMember()}
            isDisabled={!isSuccessSignUp}
          ></BasicButton>
        </div>
      </div>

      <AlertModal isOpen={isShowBasicPopup} content="추천인 등록이 완료되었어요." onClose={handleClickOk} />
    </>
  );
};

export default UserRegistrationForm;

import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import Offline from '@/components/Guide/Perchase/Offline';
import Online from '@/components/Guide/Perchase/Online';
import PerchaseContract from '@/components/Guide/Perchase/PerchaseContract';
import MenuHeader from '@/components/Header/MenuHeader';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PerchaseGuide = () => {
  const labelList: TabType[] = [
    { icon: LanguageOutlinedIcon, title: '온라인' },
    { icon: LocalShippingOutlinedIcon, title: '오프라인' },
    { icon: HandshakeOutlinedIcon, title: '차량 계약' },
  ];
  const [tabNum, setTabNum] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [tabNum]);

  return (
    <>
      <MenuHeader title="화물차 구매 가이드" isMain={false}></MenuHeader>
      <div className="pt-[60px]">
        <div className="bg-white top-[60px] w-full max-w-[720px] fixed">
          <BasicTabs labelList={labelList} value={tabNum} onChange={setTabNum} />
        </div>
        <div style={{ marginTop: '73px' }}>
          {tabNum === 0 && <Online />}
          {tabNum === 1 && <Offline />}
          {tabNum === 2 && <PerchaseContract />}
        </div>
      </div>
    </>
  );
};

export default PerchaseGuide;

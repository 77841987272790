import React, { useState } from 'react';

import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface RepairItemBoxProps {
  title: string;
  data: KeyValueLabel;
  detailData: KeyValueLabel[];
  onClick: (id: number) => void;
}

const RepairItemBox = ({ title, data, detailData, onClick }: RepairItemBoxProps) => {
  const [isExpandDetail, setIsExpandDetail] = useState<boolean>(false);

  const onToggleDetail = () => {
    setIsExpandDetail(!isExpandDetail);
  };

  return (
    <>
      <ul className="grid grid-cols-1 gap-3 w-full">
        <li className="flex flex-col justify-between gap-3 p-3 border rounded-lg px-4">
          <div className="flex items-center pb-3">
            <div
              className="flex flex-1 justify-between items-center border-b-[1px] cursor-pointer"
              onClick={() => onClick(Number(data.key))}
            >
              <p className="text-base font-semibold text-gray-8 pb-3">{title}</p>
              <ChevronRightIcon color={colors.gray[7]}></ChevronRightIcon>
            </div>
          </div>

          <div
            className="flex flex-wrap items-center justify-between text-base cursor-pointer"
            key={data.key}
            onClick={() => onClick(Number(data.key))}
          >
            <span className="text-gray-7 flex-1 pr-4 text-ellipsis break-all line-clamp-1 min-w-fit">{data.label}</span>
            <span className="text-gray-8 flex-2 text-ellipsis break-all line-clamp-1 min-w-fit">{data.value}</span>
          </div>

          {isExpandDetail && detailData?.length > 1 && (
            <>
              <div className="bg-gray-1 rounded-lg p-4 cursor-pointer" onClick={() => onClick(Number(data.key))}>
                {detailData.map((item, index) => (
                  <div className={`flex items-center justify-between ${index === 0 ? 'mt-0' : 'mt-2'}`} key={item.key}>
                    <span className="text-gray-7 text-base pr-[10px] flex-1 text-ellipsis break-all line-clamp-1">
                      {item.label}
                    </span>
                    <span className="text-base text-gray-8 flex-2 text-ellipsis break-all line-clamp-1">
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="cursor-pointer pb-3" onClick={onToggleDetail}>
            {isExpandDetail && detailData?.length > 1 ? (
              <div className="flex items-center justify-center">
                <ChevronUpIcon color={colors.gray[7]} width={16} height={16} strokeWidth={2} />
                <span className="text-gray-7 ml-1">접기</span>
              </div>
            ) : (
              detailData?.length > 1 && (
                <div className="flex items-center justify-center">
                  <ChevronDownIcon color={colors.gray[7]} width={16} height={16} strokeWidth={2} />
                  <span className="text-gray-7 ml-1">펼치기</span>
                </div>
              )
            )}
          </div>
        </li>
      </ul>
    </>
  );
};

export default RepairItemBox;

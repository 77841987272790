import React from 'react';

interface ProductTypeProps {
  type: EnumPresenter | undefined;
}

export default function ProductType({ type }: ProductTypeProps) {
  return (
    <>
      {type && (
        <span
          className={`text-[14px] leading-[18px] rounded-[8px] bg-gray-50 font-semibold py-1 px-2 text-green-0 break-keep`}
        >
          {type?.desc}
        </span>
      )}
    </>
  );
}

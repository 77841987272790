import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import ConfirmModal from '../Common/Modal/ConfirmModal';
import { deleteInterestProductNotificationSettings } from '@/api/public';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { Stack } from '@mui/material';

interface InterestProductsProps {
  data: InterestProducts | undefined;
  refetch: () => void;
}

const InterestProductCard = ({ data, refetch }: InterestProductsProps) => {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);

  const onClickEdit = (id: number | undefined) => {
    navigate(`/notification/products/form/${id}`, { state: { data } });
  };

  const onClickDeleteBtn = (id: number | undefined) => {
    setIsShow(true);
  };

  const onClickDelete = (id: number | undefined) => {
    if (id) {
      mutate(id);
    }
  };

  const { mutate } = useMutation(deleteInterestProductNotificationSettings, {
    onSuccess: () => {
      showToast('관심차량을 삭제했어요.', 'success', 'bottom');
      refetch();
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });
  return (
    <>
      <ul className="grid w-full grid-cols-1 gap-3">
        <li className="p-3 px-4 border rounded-lg text-gray-8">
          <Stack spacing={2}>
            {/* 필수값 */}
            <div className="flex items-center">
              <span className="text-gray-7 pr-4 break-keep w-[90px]">적재함 종류</span>
              <span className="flex-1 text-right text-ellipsis line-clamp-2">
                {data?.loaded?.map((item) => item.desc).join(', ')}
              </span>
            </div>

            {/* 선택값 */}
            {(!!data?.minTons || !!data?.maxTons) && (
              <div className="flex items-center">
                <span className="text-gray-7 pr-4 w-[90px]">톤수</span>
                <span className="flex-1 text-right">
                  {data.minTons === data.maxTons ? `${data.minTons}t` : `${data?.minTons}t ~ ${data?.maxTons}t`}
                </span>
              </div>
            )}

            {(!!data?.minYear || !!data?.maxYear) && (
              <div className="flex items-center justify-between">
                <span className="text-gray-7 text-base pr-4 w-[90px]">연식</span>
                <span className="flex-1 text-right">
                  {data.minYear === data.maxYear ? `${data.minYear}년` : `${data?.minYear}년 ~ ${data?.maxYear}년`}
                </span>
              </div>
            )}

            {(!!data?.minLoadedInnerLength || !!data?.maxLoadedInnerLength) && (
              <div className="flex items-center justify-between">
                <span className="text-gray-7 text-base pr-4 w-[90px]">적재함 길이</span>
                <span className="flex-1 text-right">
                  {data.minLoadedInnerLength === data.maxLoadedInnerLength
                    ? `${data.minLoadedInnerLength}m`
                    : `${data?.minLoadedInnerLength}m ~ ${data?.maxLoadedInnerLength}m`}
                </span>
              </div>
            )}

            {data?.axis && (
              <div className="flex items-center">
                <span className="text-gray-7 text-base pr-4 w-[90px]">가변축</span>
                <span className="flex-1 text-right">{data?.axis?.desc}</span>
              </div>
            )}
            {data?.manufacturerCategories?.length !== 0 && (
              <div className="flex items-center">
                <span className="text-gray-7 text-base pr-4 break-keep w-[90px]">제조사</span>
                <span className="flex-1 text-right text-ellipsis line-clamp-1">
                  {data?.manufacturerCategories?.map((item) => item.name).join(', ')}
                </span>
              </div>
            )}
            {(!!data?.minDistance || !!data?.maxDistance) && (
              <div className="flex items-center">
                <span className="text-gray-7 pr-4 w-[90px]">주행거리</span>
                <span className="flex-1 text-right">
                  {data.minDistance === data.maxDistance
                    ? `${data.minDistance}km`
                    : `${data?.minDistance}km ~ ${data?.maxDistance}km`}
                </span>
              </div>
            )}
            {data?.transmission && (
              <div className="flex items-center">
                <span className="text-gray-7 text-base pr-4 w-[90px]">변속기</span>
                <span className="flex-1 text-right">{data?.transmission?.desc}</span>
              </div>
            )}

            <div className="flex gap-2">
              <BasicButton
                name="수정"
                bgColor={colors.gray[0]}
                borderColor={colors.gray[4]}
                textColor={colors.gray[6]}
                fontSize={16}
                height={40}
                borderRadius={8}
                fontWeight="semibold"
                onClick={() => onClickEdit(data?.id)}
              ></BasicButton>
              <BasicButton
                name="삭제"
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={16}
                height={40}
                borderRadius={8}
                fontWeight="semibold"
                onClick={() => onClickDeleteBtn(data?.id)}
              ></BasicButton>
            </div>
          </Stack>
        </li>
        <ConfirmModal
          isOpen={isShow}
          content={
            <span>
              관심 차량 알림을
              <br />
              삭제할까요?
            </span>
          }
          onLeftButtonClick={() => setIsShow(false)}
          onRightButtonClick={() => onClickDelete(data?.id)}
        />
      </ul>
    </>
  );
};
export default InterestProductCard;

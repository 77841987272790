import React, { ReactNode, createContext, useContext, useState } from 'react';

type ChatContextType = {
  unreadMessages: { [chatRoomId: number]: number };
  setUnreadMessages: React.Dispatch<React.SetStateAction<{ [chatRoomId: number]: number }>>;
  chattingList: ChattingListResponse[];
  setChattingList: React.Dispatch<React.SetStateAction<ChattingListResponse[]>>;
  newChatMessage: NewChatMessage | null;
  setNewChatMessage: React.Dispatch<React.SetStateAction<NewChatMessage | null>>;
};

const ChatContext = createContext<ChatContextType | null>(null);

const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [unreadMessages, setUnreadMessages] = useState<{ [chatRoomId: number]: number }>({});
  const [chattingList, setChattingList] = useState<ChattingListResponse[]>([]);
  const [newChatMessage, setNewChatMessage] = useState<NewChatMessage | null>(null);

  return (
    <ChatContext.Provider
      value={{
        unreadMessages,
        setUnreadMessages,
        chattingList,
        setChattingList,
        newChatMessage,
        setNewChatMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext) as ChatContextType;
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

export { ChatContext, ChatProvider };

import React, { SVGProps } from 'react';

const SvgIncreasingTrendGraphColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="#E8E8E8"
      d="M10.5 12.75c0-2.9 2.35-5.25 5.25-5.25h11.5c2.9 0 5.25 2.35 5.25 5.25v11.5c0 2.9-2.35 5.25-5.25 5.25h-11.5a5.25 5.25 0 0 1-5.25-5.25v-11.5Z"
    />
    <path
      fill="#34A853"
      fillRule="evenodd"
      d="M27.998 11.874a1 1 0 0 1 1-1h4.5a1 1 0 0 1 1 1V16.5a1 1 0 1 1-2 0v-2.236l-9.249 9.119a1 1 0 0 1-1.404-.001l-4.006-3.955-6.78 6.78a1 1 0 0 1-1.415-1.414l7.484-7.483a1 1 0 0 1 1.41-.005l4.01 3.96 8.511-8.39h-2.061a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
    <path
      fill="#737373"
      fillRule="evenodd"
      d="M5.5 5A1.5 1.5 0 0 1 7 6.5v19.25A7.25 7.25 0 0 0 14.25 33H33.5a1.5 1.5 0 0 1 0 3H14.25C8.59 36 4 31.41 4 25.75V6.5A1.5 1.5 0 0 1 5.5 5Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgIncreasingTrendGraphColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgIncreasingTrendGraphColorIcon;

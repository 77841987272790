import React, { SVGProps } from 'react';

const SvgIncreasingTrendGraphBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M23.914 11a1.5 1.5 0 1 0 0 3h4.747l-8.482 8.48-7.24-7.24a1.5 1.5 0 0 0-2.121 0l-7.379 7.378a1.5 1.5 0 1 0 2.122 2.121l6.317-6.317 7.24 7.24a1.5 1.5 0 0 0 2.122 0l9.859-9.857v5.86a1.5 1.5 0 0 0 3 0V12.5a1.5 1.5 0 0 0-1.5-1.5h-8.685Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgIncreasingTrendGraphBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgIncreasingTrendGraphBoldIcon;

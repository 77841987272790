import React, { SVGProps } from 'react';

const SvgSellOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      d="M15.453 11.938h-.61l-.554 2.514c-.048.334-.312.548-.718.548-.358 0-.67-.117-.774-.463l-.808-2.6h-.126l-.68 2.556c-.081.27-.264.507-.67.507-.405 0-.786-.161-.83-.463l-.623-2.6h-.657c-.23 0-.403-.245-.403-.418 0-.334.082-.591.324-.591h.484L8.566 9.67c-.056-.29.082-.559.38-.644.278-.076.611 0 .658.31l.265 1.59h1.362l.16-.708c.047-.225.195-.419.45-.419.299 0 .45.109.532.334l.255.793h1.327l.23-1.376c.022-.204.23-.439.6-.439.3 0 .498.334.441.688l-.242 1.127h.566c.242 0 .45.29.45.504 0 .343-.23.507-.544.507h-.003Zm-5.402 0 .403 1.803.462-1.803h-.865Zm2.932 0 .497 1.718.346-1.718h-.843Z"
    />
    <circle cx={12} cy={12} r={7.3} stroke={props.color || 'currentColor'} strokeWidth={1.4} />
  </svg>
);
SvgSellOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgSellOutlineIcon;

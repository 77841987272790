import React, { SVGProps } from 'react';

const SvgMoneyCircleOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx={11.801} cy={11.8} r={10.25} stroke={props.color || 'currentColor'} strokeWidth={1.5} />
    <path
      stroke={props.color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.797 8.65 2.1 6.497 2.1-6.496 2.1 6.496 2.1-6.496M6.75 11.357H8.325M15.672 11.357h1.575"
    />
  </svg>
);
SvgMoneyCircleOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMoneyCircleOutlineIcon;

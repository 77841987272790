import { formatValueToDecimalPlaces } from './common';
import {
  LOADED_INNER_LENGTH_CHECK_AGAIN,
  LOADED_INNER_LENGTH_IS_NOT_OVER_TEN_POINT_FIVE,
  LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE,
  LOADED_INNER_LENGTH_RANGE_ERROR_MESSAGE,
  PHONE_NUMBER_VALIDATION_MESSAGE,
} from '@/const/errorMessage';
import { ETC } from '@/const/products';
import {
  nameRegex,
  numberRegex,
  onlyNumericPattern,
  passwordRegex,
  phoneNumberRegex,
  positiveIntegerRegex,
} from '@/const/regex';

export interface Validation {
  isValid: boolean;
  errorMessage: string;
}

export const validateName = (name: string) => {
  return nameRegex.test(name);
};

export const validatePassword = (password: string) => {
  return passwordRegex.test(password);
};

export const validateNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }
  return { isValid: numberRegex.test(number) } as Validation;
};

export const validatePositiveInteger = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }
  if (number && !positiveIntegerRegex.test(number)) {
    return { isValid: false, errorMessage: '0이상의 숫자를 입력해주세요.' } as Validation;
  }
  return { isValid: positiveIntegerRegex.test(number) } as Validation;
};

export const validateContractRegistractionNumber = (number: string | null | undefined) => {};

export const validateRegistrationNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }

  const registrationNumber: string = number.replace(/-/g, '');
  if (registrationNumber.length < 10 || registrationNumber.length > 13) {
    return {
      isValid: false,
      errorMessage: '주민등록번호는 13자리, 사업자등록번호는 10자리 또는 13자리로 입력해주세요.',
    } as Validation;
  }

  // 생년월일 유효성 검사
  const year = parseInt(registrationNumber.substring(0, 2), 10);
  const month = parseInt(registrationNumber.substring(2, 2), 10);
  const day = parseInt(registrationNumber.substring(4, 2), 10);

  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return { isValid: false, errorMessage: '올바른 생년월일이 아닙니다.' };
  }
  return { isValid: true } as Validation;
};

export const validateBusinessNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false } as Validation;
  }

  const businessNumber: string = number.replace(/-/g, '');
  if (businessNumber.length !== 10) {
    return { isValid: false, errorMessage: '사업자 등록번호는 10자리로 입력해주세요.' } as Validation;
  }

  const regionCode = parseInt(businessNumber.substring(0, 3), 10);
  if (regionCode < 10 || regionCode > 980) {
    return { isValid: false, errorMessage: '올바른 지역 코드가 아닙니다.' } as Validation;
  }

  // 사업자 등록번호 유효성 검사 알고리즘
  const weights = [1, 3, 7, 1, 3, 7, 1, 3, 5];
  let sum = 0;

  const businessNumberList = businessNumber.split('').map((i) => Number(i));

  weights.forEach(function (d, i) {
    sum += d * businessNumberList[i];
  });

  sum += Math.floor((weights[8] * businessNumberList[8]) / 10);
  const checkDigit = Math.floor(businessNumberList[9]) === (10 - (sum % 10)) % 10;
  if (!checkDigit) {
    return { isValid: false, errorMessage: '올바른 사업자 등록번호가 아닙니다.' } as Validation;
  }

  return { isValid: true } as Validation;
};

export const validatePhoneNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false, errorMessage: PHONE_NUMBER_VALIDATION_MESSAGE } as Validation;
  }
  if (!phoneNumberRegex.test(number)) {
    return { isValid: false, errorMessage: '올바른 휴대폰 번호가 아닙니다.' } as Validation;
  }
  return { isValid: phoneNumberRegex.test(number) } as Validation;
};

export const isValidInput = (inputValue: string, min?: number, max?: number): boolean => {
  const convertedValue = String(inputValue).replace(/[^0-9.]/g, ''); // 숫자와 소수점만 남김
  const value = Number(convertedValue);

  if (!isNaN(value)) {
    if (min !== undefined && value < min) {
      return false;
    }

    if (max !== undefined && value >= max) {
      return false;
    }
  } else {
    return false;
  }

  return true;
};

//연식
export const processYearInput = (value: string) => {
  const convertedValue = value.replace(/[^0-9]/g, '');

  if (convertedValue.startsWith('.')) {
    return '';
  }

  return convertedValue.trim();
};

export const getYearErrorMessage = (value: string) => {
  if (!value) {
    return '연식은 필수값입니다.';
  } else if (Number(value) < 2000 || Number(value) > new Date().getFullYear() + 1) {
    return '2000 이상~2025 이하로 입력해주세요.';
  }
};

//톤수
export const processTonnageInput = (value: string, decimalPlaces: number) => {
  const nonNumericOrDecimalPattern = /[^0-9.]/g;
  const convertedValue = value.replace(nonNumericOrDecimalPattern, '');

  if (convertedValue.startsWith('.')) {
    return '';
  }

  const formattedValue = formatValueToDecimalPlaces(convertedValue, decimalPlaces);
  return formattedValue.trim();
};

export const getTonnageErrorMessage = (value: string) => {
  if (!value) {
    return '톤수는 필수값입니다.';
  } else if (Number(value) < 1 || Number(value) >= 30) {
    return '1톤 이상~30톤 미만으로 입력해주세요.';
  }
  return '';
};

// 주행거리
export const processDistanceInput = (value: string) => {
  const convertedValue = value.replace(onlyNumericPattern, '');
  return convertedValue.trim();
};

export const validateDistance = (value: string | number) => {
  const distance = typeof value === 'string' ? parseFloat(value) : value;

  if (value === '' || value === null || value === undefined) {
    return { isValid: false, errorMessage: '주행거리는 필수값입니다.' };
  }

  if (distance < 1 || distance > 2000000) {
    return { isValid: false, errorMessage: '1 이상~200만km 이하로 입력해주세요.' };
  }

  return { isValid: true, errorMessage: '' };
};

// 마력수

export const processPowerInput = (value: string) => {
  const convertedValue = value.replace(onlyNumericPattern, '');
  return convertedValue.trim();
};

export const validatePower = (value: string | number) => {
  const power = typeof value === 'string' ? parseFloat(value) : value;

  if (value === '' || value === null || value === undefined) {
    return { isValid: false, errorMessage: '마력수는 필수값입니다.' };
  }

  if (power < 1) {
    return { isValid: false, errorMessage: '1 이상으로 입력해주세요.' };
  }

  return { isValid: true, errorMessage: '' };
};

export const validatePrice = (value: string) => {
  const sanitizedPrice = String(value).replace(/[^0-9]/g, '');
  const trimmedValue = sanitizedPrice.trim();
  const price = trimmedValue ? Number(trimmedValue) : undefined;

  if (value === '' || value === null || value === undefined || value === '0') {
    return { isValid: false, errorMessage: '판매 금액을 입력해주세요.' };
  }

  if (price! >= 99999) {
    return {
      isValid: true,
      formattedValue: 99999,
      errorMessage: '',
    };
  }

  return { isValid: true, formattedValue: price, errorMessage: '' };
};

export const validateLength = (
  value: string | number,
  minLimit = 1,
  maxLimit = 10.5,
  oppositeLength?: string | number | null,
  isMin = true,
) => {
  const length = typeof value === 'string' ? parseFloat(value) : value;
  const oppositeValue =
    oppositeLength !== null && oppositeLength !== undefined ? parseFloat(oppositeLength.toString()) : null;
  let isValid = true;
  let bothValuesValid = false;
  let errorMessage = '';

  if (value === '' || value === null || value === undefined || isNaN(length)) {
    return { isValid: true, errorMessage: '' };
  }

  if (length < minLimit) {
    return { isValid: false, errorMessage: LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE };
  }

  if (length > maxLimit) {
    return { isValid: false, errorMessage: LOADED_INNER_LENGTH_IS_NOT_OVER_TEN_POINT_FIVE };
  }

  if (oppositeValue !== null) {
    if (isMin && length > oppositeValue) {
      isValid = false;
      errorMessage = '최대 길이보다 작아야 합니다.';
    } else if (!isMin && length < oppositeValue) {
      isValid = false;
      errorMessage = '최소 길이보다 커야 합니다.';
    } else {
      if (oppositeValue !== null && oppositeValue >= minLimit && oppositeValue <= maxLimit) {
        isValid = true;
        bothValuesValid = true;
      }
    }
  }

  return { isValid, bothValuesValid, errorMessage };
};

export const isValidLoadedInnerLength = (length: number | string) => {
  const numericLength = Number(length);
  return numericLength >= 1 && numericLength <= 10.5;
};

export const validateLoadedInnerLength = (length: number, tons: number, manufacturerCode: string, modelId: number) => {
  if (!length) return { hasError: true, errorMsg: '' };

  if (manufacturerCode !== ETC && modelId !== 45 && !isValidLoadedInnerLength(length)) {
    return { hasError: true, errorMsg: LOADED_INNER_LENGTH_RANGE_ERROR_MESSAGE };
  }
  if (tons >= 4.5 && length < 5) {
    return { hasError: true, errorMsg: LOADED_INNER_LENGTH_CHECK_AGAIN };
  }
  if (tons >= 2 && length < 3) {
    return { hasError: true, errorMsg: LOADED_INNER_LENGTH_CHECK_AGAIN };
  }
  if (length < 2) {
    return { hasError: true, errorMsg: LOADED_INNER_LENGTH_CHECK_AGAIN };
  }
  return { hasError: false, errorMsg: '' };
};

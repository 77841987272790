import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getMyLicense } from '@/api/license/getLicense';
import LicenseListSkeleton from '@/components/Common/Skeleton/LicenseListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import LicenseItemView from '@/components/License/LicenseItemView';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';

const MyLicense = () => {
  const { showToast } = useToastContext();
  const [licenseList, setLicenseList] = useState<License[]>([]);
  const [isNone, setIsNone] = useState<boolean>(false);

  const { data, isLoading, isFetching } = useQuery(['getMyLicense'], () => getMyLicense(), {
    onSuccess: (data) => {
      setLicenseList(data);
      if (data?.length === 0) {
        setIsNone(true);
      }
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'success', 'bottom');
      setIsNone(true);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setIsNone(licenseList?.length === 0);
  }, [licenseList]);

  return (
    <>
      <MenuHeader title="내 번호판 관리" path="/more"></MenuHeader>
      <div className="w-full px-4 pt-[84px] pb-6">
        {isLoading || isFetching ? (
          <LicenseListSkeleton></LicenseListSkeleton>
        ) : isNone ? (
          <div className="flex flex-col justify-center items-center w-full min-h-[calc(100vh-108px)]">
            <img
              src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/license_none_icon.png"
              alt="번호판 내역 없음 아이콘"
            ></img>
            <span className="text-gray-500 text-lg mt-4">번호판 내역이 없습니다.</span>
          </div>
        ) : (
          <section className="flex flex-col gap-3 w-full">
            {licenseList?.map((item, index) => (
              <div key={index}>
                <LicenseItemView data={item} index={index} setLicenseList={setLicenseList} isMyPage></LicenseItemView>
              </div>
            ))}
          </section>
        )}
      </div>
    </>
  );
};

export default MyLicense;

import React from 'react';

import { colors } from '@/const/colors';
import Skeleton from '@mui/material/Skeleton';

const InterestProductListSkeleton = () => {
  const skeletonItem = (
    <li className="flex flex-col gap-4 border border-gray-3 rounded-[8px] p-4 mb-6">
      <div className="flex gap-3">
        <Skeleton variant="rounded" width={100} height={100} sx={{ bgcolor: colors.gray[2] }} />
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex gap-[6px]">
              <Skeleton variant="rounded" width={53} height={24} sx={{ bgcolor: colors.gray[2] }} />
              <div className="xxs:hidden">
                <Skeleton variant="rounded" width={53} height={24} sx={{ bgcolor: colors.gray[2] }} />
              </div>
            </div>
            <Skeleton variant="rounded" width={24} height={24} sx={{ bgcolor: colors.gray[2] }} />
          </div>
          <Skeleton variant="rounded" width="100%" animation="wave" height={19} sx={{ bgcolor: colors.gray[2] }} />
          <Skeleton variant="rounded" width="50%" animation="wave" height={21} sx={{ bgcolor: colors.gray[2] }} />
        </div>
      </div>
      <Skeleton variant="rounded" width="100%" animation="wave" height={60} sx={{ bgcolor: colors.gray[2] }} />
    </li>
  );

  return (
    <>
      <ul className="mt-6">
        {Array.from({ length: 3 }).map((_, index) => (
          <React.Fragment key={index}>{skeletonItem}</React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default InterestProductListSkeleton;

import React from 'react';
import { Link } from 'react-router-dom';

interface SubCardsProps {
  cards: ButtonCard[];
}

export default function SubCards({ cards }: SubCardsProps) {
  return (
    <>
      <div
        className={`grid grid-cols-${cards.length}  ${cards.length == 4 ? 'sm280:grid-cols-2 sm280:gap-0' : ''} gap-2 `}
      >
        {cards.map((item, index) => (
          <Link to={item.path} key={index} className={`flex py-2 rounded-lg items-center justify-center`}>
            <div className="flex flex-col justify-center items-center">
              <div className="pb-2 flex justify-center items-center mb-1 bg-gray-1 rounded-[16px] w-[52px] h-[52px]">
                {item.icon}
              </div>
              <span className="font-semibold text-gray-8 text-[14px] break-keep text-center">{item.title}</span>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

import { ChevronRightIcon, IncreasingTrendGraphBoldIcon, JobBoldIcon, LicensePlateBoldIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

const ManageMentSubMenus = () => {
  return (
    <div className="px-4">
      <ul>
        <li className="list-none py-5">
          <Link to={'/price-trend'} className="flex items-center cursor-pointer">
            <IncreasingTrendGraphBoldIcon color={colors.gray[7]} width={28} height={28}></IncreasingTrendGraphBoldIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">시세 검색</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="list-none py-5">
          <Link to={'/license'} className="flex items-center cursor-pointer">
            <LicensePlateBoldIcon color={colors.gray[7]} width={28} height={28}></LicensePlateBoldIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">번호판 거래</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
        <li className="list-none py-5">
          <Link to={'/job'} className="flex items-center cursor-pointer">
            <JobBoldIcon color={colors.gray[7]} width={28} height={28}></JobBoldIcon>
            <p className="flex-1 font-semibold text-lg ml-4 text-gray-8">일자리 구하기</p>
            <ChevronRightIcon color={colors.gray[5]}></ChevronRightIcon>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ManageMentSubMenus;

import React, { SVGProps } from 'react';

const SvgTruckFrontSideSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 38 38" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M37 24.862a1.263 1.263 0 0 0-1.226-1.246V8.263c0-.697-.566-1.263-1.263-1.263H11.19c-.698 0-1.264.566-1.264 1.263v2.43H7.09c-.516 0-.98.314-1.173.794l-.619 1.547-1.412 3.53v-1.485a.5.5 0 0 0-.5-.5H1.86a.5.5 0 0 0-.5.5v4.684a.5.5 0 0 0 .5.5h.682v3.355c-.66.042-1.181.59-1.181 1.26v1.167c0 .697.565 1.263 1.263 1.263H3.98a3.692 3.692 0 0 0 7.384 0h1.458v.001a3.692 3.692 0 0 0 7.384 0h7.047a3.692 3.692 0 1 0 7.385 0h1.098c.692 0 1.254-.558 1.263-1.248v-1.2Zm-5.113 2.446h.003a.947.947 0 1 1-.7-.935c.402.11.697.477.697.914v.021Zm-15.602-.94a.95.95 0 0 1 1.176.92.947.947 0 0 1-1.895.02h.004v-.021c0-.443.304-.815.715-.919Zm-.55-11.789.89-2.138a.632.632 0 0 1 .583-.388h.842c.349 0 .632.282.632.631V19a.632.632 0 0 1-.632.632h-.902V15.21a.632.632 0 0 0-.632-.632h-.782Zm-1.745.882v-.25c0-.243.136-.453.337-.56L15 13.037a.632.632 0 0 0-.583-.875H7.575a.632.632 0 0 0-.583.389L4.36 18.866a.632.632 0 0 0 .583.874h6.843a.632.632 0 0 0 .583-.388l1.62-3.891Zm0 3.305v.47a.624.624 0 0 1 0-.47Zm-4.422 4.34c0-.524.424-.948.947-.948h1.263a.947.947 0 1 1 0 1.894h-1.263a.947.947 0 0 1-.947-.947Zm-6.316 0c0-.524.424-.948.948-.948h1.263a.947.947 0 1 1 0 1.894H4.2a.947.947 0 0 1-.948-.947Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgTruckFrontSideSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgTruckFrontSideSolidIcon;

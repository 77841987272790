import React, { useState } from 'react';

import CheckBoxWithEtc from '@/components/Common/Input/CheckBox/CheckBoxWithEtcProps';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextAreaLabelOff from '@/components/Common/Input/TextAreaLabelOff';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import BottomSheetPopup from '@/components/Common/Popup/BottomSheetPopup';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import { calculateTextLength } from '@/utils/common';

interface ProductProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
}

const DetailInfo: React.FC<ProductProps> = ({ productInfo, setProductInfo }) => {
  const keyValueList: KeyValueListType = {
    transportStartLocate: '주요운행구간 상차지',
    transportEndLocate: '주요운행구간 하차지',
  };
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const { productEnum, setProductEnum } = useProductEnumContext();
  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');

  const onClickSelectBox = (key: string) => {
    if (!productEnum) return;
    setTitle(key);
    setOptionData(productEnum['garage']);
    setIsShow(true);
  };

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    if (name === 'accident') {
      const booleanValue = value === 'true';
      if (!booleanValue) {
        setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue, accidentContents: '' },
        }));
      } else {
        setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue },
        }));
      }
    } else if (name === 'accidentContents') {
      const length = calculateTextLength(value);

      if (length <= 200) {
        setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { ...prevProductInfo.accidentsHistory, accidentContents: value },
        }));
      }
    } else {
      setProductInfo({ ...productInfo, [name]: value } as ProductDetailResponse);
    }
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    if (key === 'tireStatus') {
      setProductInfo(
        (prevProductInfo: ProductDetailResponse): ProductDetailResponse => ({
          ...prevProductInfo,
          tireStatus: { code: item.code as string, desc: item.desc as string },
        }),
      );
    } else {
      setProductInfo({ ...productInfo, [key]: item } as ProductDetailResponse);
    }

    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const onChangeRadioInput = (newVal: OptionType, key?: string) => {
    if (key === 'accident') {
      const booleanValue = newVal.code === 'true';
      if (!booleanValue) {
        setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue, accidentContents: '' },
        }));
      } else {
        setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          accidentsHistory: { accident: booleanValue },
        }));
      }
    } else if (key === 'tireStatus') {
      setProductInfo({ ...productInfo, [key]: newVal } as ProductDetailResponse);
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[30px]">
        <div>
          <label className="mb-3 text-base font-medium text-gray-8">사고유무</label>
          <div className="px-1">
            <RadioList
              name="accident"
              list={[
                { code: 'false', desc: '없음' },
                { code: 'true', desc: '있음' },
              ]}
              value={`${!!productInfo?.accidentsHistory?.accident}`}
              onChange={(val) => {
                onChangeRadioInput(val, 'accident');
              }}
              justify="equal"
            ></RadioList>
          </div>
        </div>

        <div>
          <label className="mb-3 text-base font-medium text-gray-8">사고 상세내용</label>
          <TextAreaLabelOff
            name="accidentContents"
            placeholder="상세내용 입력"
            value={productInfo?.accidentsHistory?.accidentContents || ''}
            fontSize={18}
            minRows={1}
            maxRows={5}
            onChange={(e) => onChangeFormInput(e)}
            disabled={productInfo?.accidentsHistory?.accident === false}
          ></TextAreaLabelOff>
        </div>

        <div>
          <label className="mb-3 text-base font-medium text-gray-8">차량 정비 이력</label>
          <p className="text-sm text-gray-6">※ 최근 1년 이내로 정비한 항목을 선택해주세요.</p>
          <div className="pt-2">
            <CheckBoxWithEtc
              title="maintenance"
              name=""
              optionList={productEnum?.maintenanceCategories}
              productInfo={productInfo}
              setProductInfo={setProductInfo}
            ></CheckBoxWithEtc>
          </div>
        </div>

        <TextInputLabelUp
          name="transportGoods"
          label="운송물품 입력"
          placeholder="운송물품 입력"
          value={productInfo?.transportGoods || ''}
          onChange={(e) => onChangeFormInput(e)}
          maxLength={30}
        ></TextInputLabelUp>

        <div>
          <label className="mb-3 text-base font-medium text-gray-8">주요운행구간</label>
          <div className="flex items-center justify-center w-full mb-2">
            <div className="w-full" onClick={() => onClickSelectBox('transportStartLocate')}>
              <TextInputLabelOff
                name="transportStartLocate"
                placeholder="상차지 선택"
                value={productInfo?.transportStartLocate?.desc || ''}
                height={36}
                fontSize={18}
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                readOnly
              ></TextInputLabelOff>
            </div>
            <p className="mx-2 text-base text-gray-6">~</p>
            <div className="w-full" onClick={() => onClickSelectBox('transportEndLocate')}>
              <TextInputLabelOff
                name="transportEndLocate"
                placeholder="하차지 선택"
                value={productInfo?.transportEndLocate?.desc || ''}
                height={36}
                fontSize={18}
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                readOnly
              ></TextInputLabelOff>
            </div>
          </div>
        </div>

        <div>
          <label className="mb-3 text-base font-medium text-gray-8">차량 타이어 상태</label>
          <div className="px-1">
            <RadioList
              name="tireStatus"
              list={productEnum?.tireStatus || []}
              value={productInfo?.tireStatus?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'tireStatus');
              }}
              justify="equal"
            ></RadioList>
          </div>
        </div>

        <div>
          <label className="mb-3 text-base font-medium text-gray-8">차량 옵션</label>
          <div className="flex flex-col gap-3">
            <CheckBoxWithEtc
              title="normalOption"
              name="일반 옵션"
              optionList={productEnum?.normalOption}
              productInfo={productInfo}
              setProductInfo={setProductInfo}
            ></CheckBoxWithEtc>
            <CheckBoxWithEtc
              title="additionalOption"
              name="추가 옵션"
              optionList={productEnum?.additionalOption}
              productInfo={productInfo}
              setProductInfo={setProductInfo}
            ></CheckBoxWithEtc>
            <CheckBoxWithEtc
              title="breakOption"
              name="브레이크 옵션"
              optionList={productEnum?.breakOption}
              productInfo={productInfo}
              setProductInfo={setProductInfo}
            ></CheckBoxWithEtc>
          </div>
        </div>
        <div>
          <label className="mb-3 text-base font-medium text-gray-8">차량 상세설명</label>
          <div className="p-4 mt-2 mb-4 rounded-lg bg-blue-0">
            <p className="text-sm text-gray-8">
              기타 옵션 및 수리내역 등 차량의 상세 정보를 남겨주세요. 예) 네고 가능/네고 불가능 등<br></br>
              <br></br>
              <span className="text-red">* 개인 정보 보호를 위해 현재 전화번호 입력은 제한되어있습니다. </span>
            </p>
          </div>

          <TextAreaLabelOff
            name="detailContent"
            placeholder="상세설명 입력"
            value={productInfo?.detailContent || ''}
            fontSize={18}
            minRows={1}
            maxRows={5}
            onChange={(e) => onChangeFormInput(e)}
          ></TextAreaLabelOff>
        </div>
      </div>
      <BottomSheetPopup isOpen={isShow} onClose={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={
              (productInfo && productInfo[title]?.id?.toString()) || (productInfo && productInfo[title]?.code) || ''
            }
            onChange={(val) => {
              onClickItem(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </BottomSheetPopup>
    </div>
  );
};

export default DetailInfo;

// ToastProvider.tsx
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import Toast from '@/components/Common/Popup/Toast';

interface ToastContextType {
  showToast: (message: string, type?: string, position?: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToastContext = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [currentToast, setCurrentToast] = useState<{
    id: number;
    message: string;
    type: string;
    position: string;
  } | null>(null);

  const showToast = useCallback((message: string, type = 'default', position = 'bottom') => {
    const newToast = {
      id: Date.now(),
      message,
      type,
      position,
    };

    setCurrentToast(newToast);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {currentToast && (
        <div className="relative toast-container">
          <Toast key={currentToast.id} {...currentToast} onClose={() => setCurrentToast(null)} />
        </div>
      )}
    </ToastContext.Provider>
  );
};

export { ToastProvider };

import React, { SVGProps } from 'react';

const SvgMinusSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path stroke={props.color || 'currentColor'} strokeLinecap="round" strokeWidth={1.5} d="M5 12h14" />
  </svg>
);
SvgMinusSmallIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMinusSmallIcon;

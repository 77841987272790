import React from 'react';

import LoanCalculator from '@/components/Common/LoanCalculator';

const CapitalGuideView = () => {
  return (
    <>
      <div className="flex sm680:flex-row sm680:gap-6 flex-col items-center justify-center bg-gradient-to-b from-[#C4EAC4] to-[#8FCE8E] py-10">
        <div>
          <p className="text-[28px] font-bold text-center text-[#315230]">
            중고화물차
            <br className="sm280:block hidden" /> 저금리 대출
          </p>
          <div className="grid grid-cols-2 gap-2 text-center mt-6 mb-4">
            <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
              차량대금 <br className="sm280:block hidden" />
              <span className="font-bold">전액 가능</span>
            </div>
            <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
              무방문, <br className="sm280:block hidden" />
              <span className="font-bold">간편서류 접수</span>
            </div>
            <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
              신용점수 <br className="sm280:block hidden" />
              <span className="font-bold">영향 NO</span>
            </div>
            <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
              개인맞춤 <br className="sm280:block hidden" />
              <span className="font-bold">대출상품 상담</span>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center max-w-[248px] w-[248px] h-[196px]">
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/capital_counsel_service1.png"
            alt="구매 동행 서비스 트럭 이미지"
            className="w-full"
          />
        </div>
      </div>
      <section className="px-4 py-[30px]">
        <h3 className="font-semibold text-gray-8 text-[20px] leading-[24px] py-6">
          대출 상담 서비스 신청 전<br />월 할부금을 알아보세요.
        </h3>

        <LoanCalculator />
      </section>
    </>
  );
};

export default CapitalGuideView;

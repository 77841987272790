import React, { ReactNode } from 'react';

import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';

interface StickyActionBannerType {
  isShow: boolean;
  icon: ReactNode;
  content: ReactNode;
  position: string;
  onClickBtn: () => void;
}

const StickyActionBanner = ({ isShow, icon, content, position, onClickBtn }: StickyActionBannerType) => {
  return (
    <>
      {isShow && (
        <div
          className={`fixed w-full z-50 left-0 right-0 mx-auto my-0 min-w-[280px] max-w-[720px] flex justify-center ${position} p-[15px] cursor-pointer`}
          onClick={onClickBtn}
        >
          <div
            className={`flex justify-between items-center text-black rounded-lg py-4 px-4 bg-[#FAFAFA] w-full p-[15px] max-w-[720px] shadow-[0px_1px_4px_2px_rgba(0,0,0,0.07)]`}
          >
            <div className="flex items-center">
              <div className="rounded-full w-10 h-10 min-w-[40px] min-h-[40px] flex justify-center items-center">
                {icon}
              </div>
              {content}
            </div>

            <div className="min-w-[24px]">
              <ChevronRightIcon color={colors.gray[8]} width={24} height={24} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StickyActionBanner;

import React, { SVGProps } from 'react';

const SvgCalendarCheckOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 26 26" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M15.75 6.667v-1.64h-6v1.64a.75.75 0 1 1-1.5 0v-1.64H8a3.5 3.5 0 0 0-3.5 3.5v10a3.5 3.5 0 0 0 3.5 3.5h10a3.5 3.5 0 0 0 3.5-3.5v-10a3.5 3.5 0 0 0-3.5-3.5h-.75v1.64a.75.75 0 0 1-1.5 0Zm1.5-3.917v.778H18a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5v-10a5 5 0 0 1 5-5h.25V2.75a.75.75 0 1 1 1.5 0v.778h6V2.75a.75.75 0 0 1 1.5 0Zm.301 9.259a.75.75 0 1 0-1.102-1.018l-4.442 4.813L9.78 13.33a.75.75 0 0 0-1.115 1.004l2.778 3.084a.75.75 0 0 0 1.108.006l5-5.416Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgCalendarCheckOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgCalendarCheckOutlineIcon;

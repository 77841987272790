import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { BEFORE_SALE, COMPLETED, ORIGIN_DATA_REGISTER, PAUSE, SALE } from '../../const/productStatus';
import ConfirmModal from '../Common/Modal/ConfirmModal';
import BottomSheetPopup from '../Common/Popup/BottomSheetPopup';
import { EllipsisVerticalIcon } from '../Icon';
import ActualPriceModal from './ActualPriceModal';
import apiManager from '@/api/AxiosInstance';
import { patchProductSalePrice } from '@/api/products/updateProducts';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatPrice } from '@/utils/common';

interface MenuDataType {
  code: string;
  desc: string;
  onClick?: () => void;
}

export interface MoreMenuData {
  id: number;
  status: string;
}

export interface MoreMenuProps {
  id: number;
  status?: string;
  setProductList?: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
  setLicenseList?: React.Dispatch<React.SetStateAction<License[]>>;
  isLicense?: boolean;
  confirmTitle?: string;
  price?: number;
}

export default function ProductMoreMenu({
  id,
  status,
  setProductList,
  setLicenseList,
  isLicense = false,
  confirmTitle,
  price,
}: MoreMenuProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

  const [actualSalePrice, setActualSalePrice] = useState<number>();
  const [menus, setMenus] = useState<MenuDataType[]>([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [path, setPath] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState(status || null);
  const { showToast } = useToastContext();
  const [isUsePriceInputPopup, setIsUsePriceInputPopup] = useState(false);

  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    content: '',
    leftButtonLabel: '',
    rightButtonLabel: '',
    onLeftButtonClick: () => {},
    onRightButtonClick: () => {},
  });

  useEffect(() => {
    if (isLicense) {
      setPath('license');
    } else {
      setPath('products');
    }
  }, []);

  const setMenuData = () => {
    switch (selectedStatus) {
      case ORIGIN_DATA_REGISTER:
      case BEFORE_SALE:
        setMenus([
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case SALE:
        setMenus([
          { code: 'COMPLETED', desc: '판매완료' },
          { code: 'PAUSE', desc: '판매중지' },
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case PAUSE:
        setMenus([
          { code: 'SALE', desc: '판매중' },
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case COMPLETED:
        setMenus([{ code: 'DELETE', desc: '삭제하기' }]);
        break;
    }
  };

  const onClickMenu = () => {
    setIsOpenBottomSheet(true);
    setMenuData();
  };

  const closeConfrimModal = () => {
    setIsOpenConfirmModal(false);
  };

  const closeBottomSheet = () => {
    setIsOpenBottomSheet(false);
  };

  const onClickDelete = () => {
    setTimeout(() => {
      setIsOpenConfirmModal(true);
    }, 100);

    setModalConfig({
      title: confirmTitle,
      content: `${isLicense ? '번호판을 정말 삭제하시겠어요?' : '차량을 정말 삭제하시겠어요?'}`,
      rightButtonLabel: '삭제하기',
      onLeftButtonClick: closeConfrimModal,
      onRightButtonClick: () => {
        setIsOpenBottomSheet(false);
        closeBottomSheet();
        closeConfrimModal();
        deleteProduct();
      },
    });
  };

  const deleteProduct = () => {
    apiManager
      .delete(`/api/v1/${path}/${id}`)
      .then((response) => {
        if (!isLicense) {
          showToast('차량이 삭제되었어요.', 'success', 'bottom');
          if (setProductList) {
            setTimeout(() => {
              setProductList((prev) => prev.filter((item) => item.id !== id));
            }, 500);
          }
          const isVehicleDetailPage = /^\/products\/\d+$/.test(location.pathname);
          if (isVehicleDetailPage) {
            navigate('/products', { replace: true });
            queryClient.invalidateQueries({ queryKey: ['product-search'] });
          }
        } else if (setLicenseList) {
          showToast('번호판이 삭제되었어요.', 'success', 'bottom');
          setTimeout(() => {
            setLicenseList((prev) => prev.filter((item) => item.id !== id));
          }, 500);
        }
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });
  };

  const handleToastMsg = (selectedStatus: string, isLicense: boolean) => {
    switch (selectedStatus) {
      case SALE:
        return '판매중으로 변경되었어요.';
      case PAUSE:
        return '판매중지로 변경되었어요.';
      case COMPLETED:
        return isLicense && '판매완료로 변경되었어요.';
      default:
        return '';
    }
  };

  const onClickChangeStatusToCompleted = (selectedStatus: string) => {
    setTimeout(() => {
      setIsOpenConfirmModal(true);
    }, 100);

    setModalConfig({
      content: (
        <div>
          <span className="text-[20px] sm280:text-[16px] font-medium">
            판매 완료로 상태를
            <br />
            변경할까요?
          </span>

          <br />
          <br />
          <div className="text-red text-[18px] sm280:text-[14px] leading-[26px] sm280:leading-[21px]">
            * 판매완료 처리 후 판매중으로
            <br />
            상태 변경이 불가능합니다.
          </div>
        </div>
      ),
      onLeftButtonClick: closeConfrimModal,
      onRightButtonClick: () => {
        onClickChangeStatus(selectedStatus);
      },
    });
  };

  const showActualSalePricePopup = () => {
    setIsOpenConfirmModal(false);
    setIsUsePriceInputPopup(true);
  };

  const onClickChangeStatus = (selectedStatus: string) => {
    if (path) {
      apiManager
        .patch(`/api/v1/${path}/${id}/status`, { status: selectedStatus })
        .then((response) => {
          if (!isLicense) {
            const responseData: ProductDetailResponse = response.data;
            if (setProductList) {
              setProductList((prevData) =>
                prevData.map((item) => {
                  if (item.id === responseData.id) {
                    return { ...item, status: responseData.status };
                  }
                  return item;
                }),
              );
            }
            if (responseData && responseData.status.code == COMPLETED) {
              showActualSalePricePopup();
            }
          } else if (setLicenseList) {
            setIsOpenBottomSheet(false);
            closeConfrimModal();

            const responseData: License = response.data;
            setLicenseList((prevData) =>
              prevData.map((item) => {
                if (item.id === responseData.id) {
                  return { ...item, status: responseData.status };
                }
                return item;
              }),
            );
          }
          setSelectedStatus(selectedStatus);
          const newToastMsg = handleToastMsg(selectedStatus, isLicense);
          if (!!newToastMsg) {
            showToast(newToastMsg, 'success', 'bottom');
          }
        })
        .catch((error) => {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        });
    }
  };

  const onClickMenuItem = (code: string) => {
    switch (code) {
      case 'MODIFY':
        if (isLicense) {
          navigate(`/${path}/form/${id}`, { state: { isModify: true } });
        } else {
          navigate(`/${path}/sales/edit/${id}/#info`);
        }
        break;
      case 'DELETE':
        onClickDelete();
        break;
      case 'COMPLETED':
        onClickChangeStatusToCompleted(code);
        break;
      case 'PAUSE':
      case 'SALE':
        onClickChangeStatus(code);
        setIsOpenBottomSheet(false);
        break;
    }
  };

  const patchActualPriceMutation = useMutation(
    (requset: { id: number; actualSalePrice: number }) => patchProductSalePrice(requset),
    {
      onSuccess: (response) => {
        const responseData: ProductDetailResponse = response.data;
        if (responseData) {
          showToast('실제 판매 가격이 저장되었어요.', 'success', 'bottom');
          if (setProductList) {
            setTimeout(() => {
              setProductList((prevData) =>
                prevData.map((item) => {
                  if (item.id === responseData.id) {
                    return { ...item, actualSalePrice: responseData.actualSalePrice };
                  }
                  return item;
                }),
              );
            }, 1000);
          }
          setIsOpenBottomSheet(false);
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const modifyProductActualSalePrice = () => {
    if (price && actualSalePrice) {
      if (price + 1000 < actualSalePrice || price - 1000 > actualSalePrice) {
        setIsOpenConfirmModal(true);

        setModalConfig({
          content: (
            <>
              <div className="mx-8 font-medium sm280:mx-0">
                입력하신 실제 판매 금액과
                <br />
                기존 금액의 차이가 큽니다.
                <br />
                이대로 입력하시겠어요?
              </div>
              <div className="text-gray-7 text-[16px] sm280:text-[14px] leading-[19px] sm280:leading-[17px] mt-[24px]">
                <div>기존 금액 {formatPrice(String(price))}</div>
                <div>실제 판매 금액 {formatPrice(String(actualSalePrice))}</div>
              </div>
            </>
          ),
          leftButtonLabel: '다시 입력',
          rightButtonLabel: '이대로 입력',
          onLeftButtonClick: () => {
            closeConfrimModal();
            setIsUsePriceInputPopup(true);
          },
          onRightButtonClick: () => {
            updateActualSalePrice();
          },
        });
      } else {
        updateActualSalePrice();
      }
    }
  };

  const updateActualSalePrice = () => {
    if (actualSalePrice) {
      setIsOpenBottomSheet(false);
      closeConfrimModal();

      const request = {
        id: id,
        actualSalePrice: actualSalePrice,
      };
      patchActualPriceMutation.mutate(request);
    }
  };

  return (
    <>
      <button onClick={onClickMenu}>
        <EllipsisVerticalIcon />
      </button>

      <ConfirmModal
        isOpen={isOpenConfirmModal}
        title={modalConfig.title}
        content={modalConfig.content}
        leftButtonLabel={modalConfig.leftButtonLabel}
        rightButtonLabel={modalConfig.rightButtonLabel}
        onLeftButtonClick={modalConfig.onLeftButtonClick}
        onRightButtonClick={modalConfig.onRightButtonClick}
      />
      <ActualPriceModal
        isShow={isUsePriceInputPopup}
        setIsShow={setIsUsePriceInputPopup}
        placeholder="판매 금액"
        price={price}
        onClickOk={modifyProductActualSalePrice}
        inputValue={actualSalePrice}
        setInputValue={setActualSalePrice}
      />

      <BottomSheetPopup
        isOpen={isOpenBottomSheet}
        onClose={closeBottomSheet}
        canClose={() => !isUsePriceInputPopup && !isOpenConfirmModal}
        title="메뉴"
      >
        <div className="p-4">
          {menus?.map((item, index) => (
            <div
              key={index}
              className={`py-4 cursor-pointer ${item.code === 'DELETE' && 'text-red'}`}
              onClick={() => onClickMenuItem(item.code)}
            >
              {item.desc}
            </div>
          ))}
        </div>
      </BottomSheetPopup>
    </>
  );
}

import { useSetAtom } from 'jotai';
import React, { ReactNode, createContext, useContext, useState } from 'react';

import { useToastContext } from '../Common/ToastContext';
import apiManager from '@/api/AxiosInstance';
import { isExistNotReadChatMessageAtom } from '@/store/chatting';
import { isExistNotReadNotificationAtom } from '@/store/notification';

type MemberContextType = {
  member: Member | null;
  login: (member: Member) => void;
  isLoading?: boolean | undefined;
  logout: () => void;
  updateMember: (member: Member) => void;
  removeMember: () => void;
  updateNotificationSettings: (key: string, checked: boolean | number) => void;
  getMember?: (id: number) => void;
  updateDriveVehicleInfo: (data: DriveVehicleInfo) => void;
  updateReferralCodes: (data: ReferralCode) => void;
};

const MemberContext = createContext<MemberContextType | null>(null);

const MemberProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { showToast } = useToastContext();

  const setIsExistNotReadNotification = useSetAtom(isExistNotReadNotificationAtom);
  const setIsExistNotReadChatMessage = useSetAtom(isExistNotReadChatMessageAtom);

  const [member, setMember] = useState<Member | null>(null);

  const login = (member: Member) => {
    setMember(member);
  };

  const logout = () => {
    setMember(null);
  };

  const updateMember = (member: Member) => {
    setMember(member);
  };

  const removeMember = () => {
    setMember(null);
  };

  const updateNotificationSettings = (key: string, checked: boolean | number) => {
    setMember((prevMember) => {
      if (!prevMember) return null;

      const updatedSettings = {
        ...prevMember.notificationSettings,
        [key]: checked,
      };

      const updatedMember = {
        ...prevMember,
        notificationSettings: updatedSettings,
      };

      return updatedMember;
    });
  };

  const updateDriveVehicleInfo = (data: DriveVehicleInfo) => {
    setMember((prevMember) => {
      if (!prevMember) return null;

      return { ...prevMember, driveVehicleInfo: { ...prevMember.driveVehicleInfo, ...data } };
    });
  };

  const updateReferralCodes = (data: ReferralCode) => {
    setMember((prevMember) => {
      if (!prevMember) return null;

      return { ...prevMember, referralCodes: [...prevMember.referralCodes, data] };
    });
  };

  const getMember = (id: number) => {
    apiManager
      .get(`/api/v1/members/${id}`)
      .then((response) => {
        login(response.data);
        setIsExistNotReadNotification(response.data.isExistNotReadNotification);
        setIsExistNotReadChatMessage(response.data.isExistNotReadChatMessage);
      })
      .catch((error) => {
        localStorage.removeItem('token');
        localStorage.removeItem('driveTutorial');
        localStorage.removeItem('driveTutorialReceivedCheck');
      });
  };

  return (
    <MemberContext.Provider
      value={{
        member,
        login,
        logout,
        updateMember,
        removeMember,
        updateNotificationSettings,
        getMember,
        updateDriveVehicleInfo,
        updateReferralCodes,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMemberContext = () => {
  const context = useContext(MemberContext) as MemberContextType;
  if (context === undefined) {
    throw new Error('useMemberContext must be used within a MemberProvider');
  }
  return context;
};

export { MemberContext, MemberProvider };

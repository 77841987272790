import { atom } from 'jotai';

const initialSignUpFormState: MemberInfo = {
  password: '',
  marketing: false,
  terms: false,
  personalInfo: false,
  name: '',
  phoneNumber: '',
  authNumber: '',
  isSentCode: false,
  isAuthenticateCode: false,
};

export const signUpFormAtom = atom<MemberInfo>(initialSignUpFormState);

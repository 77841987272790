import React, { Dispatch, memo, useState } from 'react';
import { useQueryClient } from 'react-query';

import FilledBadge from '../Common/Badge/FilledBadge';
import BasicButton from '../Common/Button/BasicButton';
import ConfirmModal from '../Common/Modal/ConfirmModal';
import apiManager from '@/api/AxiosInstance';
import MoreMenu from '@/components/Menu/MoreMenu';
import ProductStatus from '@/components/More/ProductStatus';
import { colors } from '@/const/colors';
import { LICENSE_PATH } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { COMPLETED, INDIVIDUAL } from '@/const/license';
import { BEFORE_SALE, SALE } from '@/const/productStatus';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { formatNumber } from '@/utils/common';

type LicenseProps = {
  data: License;
  index: number;
  setLicenseList?: Dispatch<React.SetStateAction<License[]>> | undefined;
  isMyPage?: boolean;
  isShowPurchaseButton?: boolean;
  isDisabledButton: boolean;
};

interface LicenseInquiryCreateRequest {
  licenseId: number;
}
const LicenseItemView = memo(
  ({ data, index, setLicenseList, isMyPage, isShowPurchaseButton, isDisabledButton }: LicenseProps) => {
    const queryClient = useQueryClient();
    const authRedirect = useAuthRedirect();
    const { showToast } = useToastContext();
    const [menuStates, setMenuStates] = useState<boolean[]>([]);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalConfig, setModalConfig] = useState<ModalConfig>({
      title: '',
      content: '',
      leftButtonLabel: '',
      rightButtonLabel: '',
      onLeftButtonClick: () => {},
      onRightButtonClick: () => {},
    });
    const [purchaseButtonState, setPurchaseButtonState] = useState(isDisabledButton);

    const isCOMPLETED = (status: EnumPresenter) => {
      if (status == null) {
        return false;
      }

      if (status.code === COMPLETED) {
        return true;
      } else {
        return false;
      }
    };

    const isShowCompletedBadge = (status: EnumPresenter) => {
      return isCOMPLETED(status) && !isMyPage;
    };

    const onClickRequestPurchase = (license: License) => {
      if (!authRedirect(LICENSE_PATH)) {
        return;
      }
      setIsOpenModal(true);
      setModalConfig({
        title: `${license?.tons}톤 ${license?.year}년 ${license?.licenseType?.desc || ''}을 구매 요청할까요?`,
        content: '번호판 구매 요청 시 담당자가 <br/>확인 후 연락드릴 예정입니다.',
        onRightButtonClick: () => {
          setIsOpenModal(false);
          requestPurchase(license);
        },
      });
    };

    const requestPurchase = (license: License) => {
      const request: LicenseInquiryCreateRequest = { licenseId: license?.id };
      apiManager
        .post('/api/v1/license-inquiry', request)
        .then((response) => {
          showToast('번호판 구매를 요청했어요.', 'success', 'bottom');
          setPurchaseButtonState(true);
          queryClient.invalidateQueries({ queryKey: ['get-license-list'] });
        })
        .catch((error) => {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        });
    };

    const toggleMenu = (index: number) => {
      const newMenuStates = [...menuStates];
      for (let i = 0; i < newMenuStates.length; i++) {
        if (i !== index) {
          newMenuStates[i] = false;
        }
      }
      newMenuStates[index] = !newMenuStates[index];
      setMenuStates(newMenuStates);
    };

    const licenseStatus = (status: EnumPresenter, licenseCounselStatus: EnumPresenter) => {
      if (status.code === BEFORE_SALE) {
        return licenseCounselStatus;
      } else {
        return status;
      }
    };

    return (
      <ul className="grid w-full grid-cols-1 gap-4">
        <li key={data?.id} className="p-[18px] border border-gray-4 rounded-xl">
          {isShowCompletedBadge(data?.status) && (
            <div className="mt-1 mb-3">
              <FilledBadge
                textColor="text-gray-0"
                bgColor="bg-gray-8"
                rounded="rounded-lg"
                fontWeight="font-semibold"
                text={'거래완료'}
              ></FilledBadge>
            </div>
          )}
          <div className="flex items-center">
            <span className="flex flex-col flex-1">
              {isMyPage && (
                <p className="min-w-[65px] pb-3">
                  <ProductStatus status={licenseStatus(data.status, data.licenseCounselStatus)}></ProductStatus>
                </p>
              )}

              <p className="text-lg font-semibold break-all xxs:text-base text-gray-8 text-ellipsis line-clamp-1">
                {data?.tons}톤 {data?.year}년 {data?.licenseType?.desc}{' '}
                {data?.licenseType?.code === INDIVIDUAL && (
                  <>(1.5톤 이상 ~ {data?.maxTons ? `${data.maxTons}톤` : ''})</>
                )}
              </p>
            </span>
            {isMyPage && (
              <>
                <MoreMenu
                  isLicense
                  id={data?.id}
                  status={data?.status.code}
                  setLicenseList={setLicenseList}
                  confirmTitle={`${data?.tons}톤 ${data?.year}년 ${data?.licenseType?.desc || ''}`}
                ></MoreMenu>
              </>
            )}
          </div>
          <hr className="my-3 text-gray-4"></hr>
          <div className="flex flex-col gap-2 text-base font-normal text-gray-8 xxs:text-sm">
            <div className="flex items-center justify-between">
              <span className="pr-4 text-gray-7">거래 방식</span>
              <span className="flex-1 font-medium text-right">{data?.licenseSalesType?.desc}</span>
            </div>

            {data?.licenseSalesType?.code === 'TRADE' ? (
              <>
                <div className="flex items-center justify-between">
                  <span className="pr-4 text-gray-7 break-word">번호판 종류</span>
                  <span className="flex-1 font-medium text-right">
                    {data?.licenseType?.desc}{' '}
                    {data?.licenseType?.code === INDIVIDUAL && (
                      <>(1.5톤 이상 ~ {data?.maxTons ? `${data.maxTons}톤` : ''})</>
                    )}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="pr-4 text-gray-7 break-word">번호판 용도</span>
                  <span className="flex-1 font-medium text-right">{data?.useClassification?.desc}</span>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <span className="w-24 pr-4 text-gray-7">보험요율</span>
                  <span className="font-medium">{data?.insuranceRate}%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="w-24 pr-4 text-gray-7">지입료</span>
                  <span className="font-medium">{formatNumber(String(data?.fee))}만원</span>
                </div>
              </>
            )}

            {data?.locate && (
              <div className="flex items-center justify-between">
                <span className="w-24 pr-4 text-gray-7">지역</span>
                <span className="flex-1 font-medium text-right">{data?.locate.desc}</span>
              </div>
            )}
            {(data?.status.code === SALE || isMyPage) && (
              <div className="flex items-center justify-between">
                <span className="w-24 pr-4 text-gray-7">가격</span>
                <span className="font-medium">{formatNumber(String(data?.price))}만원</span>
              </div>
            )}

            {!isShowCompletedBadge(data?.status) && !!isShowPurchaseButton && (
              <div className="mt-2">
                <BasicButton
                  name={`구매 요청 ${purchaseButtonState ? ' 완료' : ''}`}
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  borderRadius={12}
                  fontWeight="bold"
                  isDisabled={purchaseButtonState}
                  onClick={() => onClickRequestPurchase(data)}
                ></BasicButton>
              </div>
            )}
          </div>
        </li>
        <ConfirmModal
          isOpen={isOpenModal}
          title={modalConfig.title}
          content={
            <>
              번호판을 구매 요청시 담당자가
              <br />
              확인 후 연락드릴 예정입니다.
            </>
          }
          rightButtonLabel="요청"
          onLeftButtonClick={() => {
            setIsOpenModal(false);
          }}
          onRightButtonClick={modalConfig.onRightButtonClick}
        />
      </ul>
    );
  },
);

export default LicenseItemView;

export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;
export const numberRegex = /^[0-9]+$/;
export const positiveIntegerRegex = /^\d+$/;
export const nameRegex = /^[a-zA-Z가-힣0-9]+$/;
// 정확한 주민등록번호 정규식 X 숫자와 - 입력 가능하도록
export const registrationNumberRegex = /^[0-9-]*$/;
export const phoneNumberRegex = /^\d{3}\d{3,4}\d{4}$/;
// 휴대폰 형식이 포함되어있는지를 판단하는 정규식
export const phoneNumberPatternRegex = /(?:\b\d{3}[-.\s]?\d{4}[-.\s]?\d{4}\b)|(?:\b\d{11}\b)/;
export const positiveIntegerAndNullRegex = /^(?:[1-9]\d*|0)?$/;
export const positiveFloatRegex = /^(0\.(\d*[1-9]+\d*)?|[1-9]\d*(\.\d*)?|0)$/;
// 숫자와 소수점(.)을 제외한 모든 문자를 찾아내는 정규식
export const nonNumericOrDecimalPattern = /[^\d.]/g;

export const onlyNumericPattern = /[^0-9]/g;

import React, { MouseEvent, ReactElement, useState } from 'react';
import { useQueryClient } from 'react-query';

import ConfirmModal from '../Common/Modal/ConfirmModal';
import apiManager from '@/api/AxiosInstance';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { AFTER_CONSULTING, BEFORE_CONSULTING, BEFORE_SALE, SALE } from '@/const/productStatus';
import { PRODUCT_TYPE_DIRECT, PRODUCT_TYPE_SPEED } from '@/const/products';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface ProductTypeAndStatusProps {
  id: number;
  type: EnumPresenter | undefined;
  status: EnumPresenter | undefined;
  setProductList?: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
  statusOfSpeedProduct?: EnumPresenter;
}

const showText = (code: string | undefined) => {
  if (code == PRODUCT_TYPE_DIRECT) {
    return (
      <p className="text-gray-7 underline underline-offset-4 text-[14px] sm280:text-[12px]">전문가에게 판매하기</p>
    );
  } else if (code == PRODUCT_TYPE_SPEED) {
    return <p className="text-gray-7 underline underline-offset-4 text-[14px] sm280:text-[12px]">직거래 판매로 변경</p>;
  } else {
    return '';
  }
};

export default function ProductTypeChangeButton({
  id,
  type,
  status,
  setProductList,
  statusOfSpeedProduct,
}: ProductTypeAndStatusProps) {
  const [isShow, setIsShow] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement>();
  const [rightBtnText, setRightBtnText] = useState('');
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  const onClickLeftBtn = (e?: MouseEvent<HTMLDivElement>) => {
    setIsShow(false);
    if (e) {
      e.stopPropagation();
    }
  };

  const handleToastMsg = (requestType: string) => {
    if (requestType == PRODUCT_TYPE_DIRECT) {
      return '직거래 차량으로 변경되었어요.';
    } else {
      return '전문가에게 판매를 요청했어요.';
    }
  };

  const onClickRightBtn = (type: string | undefined, e?: MouseEvent<HTMLDivElement>) => {
    let requestType = '';
    if (type == PRODUCT_TYPE_DIRECT) {
      requestType = PRODUCT_TYPE_SPEED;
    } else {
      requestType = PRODUCT_TYPE_DIRECT;
    }

    apiManager
      .patch(`/api/v2/products/${id}`, { type: requestType })
      .then((response) => {
        if (setProductList) {
          const responseData: ProductDetailResponse = response.data;
          setProductList((prevData) =>
            prevData.map((item) => {
              if (item.id === responseData.id) {
                return { ...item, type: responseData.type };
              }
              return item;
            }),
          );
        }
        queryClient.invalidateQueries({ queryKey: ['product-search'] });
        const newToastMsg = handleToastMsg(requestType);
        showToast(newToastMsg, 'success', 'bottom');
      })
      .catch((error) => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      });

    if (e) {
      e.stopPropagation();
    }

    setIsShow(false);
  };

  const getTextContext = () => {
    if (type?.code == PRODUCT_TYPE_DIRECT) {
      setModalContent(
        <>
          <span className="font-semibold text-[20px] sm280:text-[16px]">전문가에게 판매 요청할까요?</span>
          <br />
          <br />
          <span className="text-[18px] sm280:text-[14px]">
            <span className="text-primary">· 담당자가 직접 상담</span>을 도와드려요.
            <br />· 직거래 차량은{' '}
            <span className="text-primary">
              내차구매 목록에서 <br />
              숨김 처리
            </span>
            됩니다.
          </span>
        </>,
      );
      setRightBtnText('요청하기');
    } else {
      setModalContent(
        <span>
          직거래 차량으로
          <br />
          변경하시겠어요?
        </span>,
      );

      setRightBtnText('변경하기');
    }
  };

  return (
    (statusOfSpeedProduct?.code === AFTER_CONSULTING || statusOfSpeedProduct?.code === BEFORE_CONSULTING) &&
    ((status?.code === SALE && type?.code === PRODUCT_TYPE_DIRECT) || status?.code === BEFORE_SALE) && (
      <>
        <span
          onClick={(e) => {
            getTextContext();
            setIsShow(true);
            e.stopPropagation();
          }}
        >
          {showText(type?.code)}
        </span>

        <ConfirmModal
          isOpen={isShow}
          content={modalContent}
          onLeftButtonClick={(e?: MouseEvent<HTMLDivElement>) => onClickLeftBtn(e)}
          rightButtonLabel={rightBtnText}
          onRightButtonClick={(e?: MouseEvent<HTMLDivElement>) => onClickRightBtn(type?.code, e)}
        />
      </>
    )
  );
}

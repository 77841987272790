import React, { Dispatch } from 'react';
import { colors } from '@/const/colors';
import { XCircleIcon } from '@/components/Icon';

interface TagsProps {
  items: OptionDataType[] | undefined;
  setItems?: Dispatch<React.SetStateAction<OptionDataType[]>>;
  itemName?: string;
  onClickDeleteBtn?: (unselectedItem: OptionDataType, key: string) => void;
}

export default function Tags({ items, setItems, itemName, onClickDeleteBtn }: TagsProps) {
  const onClickDelete = (unselectedItem: OptionDataType) => {
    if (onClickDeleteBtn && itemName) {
      onClickDeleteBtn(unselectedItem, itemName);
    }
    if (setItems) {
      setItems((prevItems) => {
        if (!prevItems) {
          return [];
        }

        const updatedItems = prevItems.filter((item) => item.code !== unselectedItem.code);
        return updatedItems;
      });
    }
  };

  return (
    <>
      {items && items.length > 0 && (
        <div className="flex flex-wrap">
          {items?.map((item, index) => (
            <div
              key={index}
              className="flex items-center py-1 px-3 mr-2 mb-2 rounded-2xl bg-gray-1 text-base text-gray-1"
            >
              <p>
                <span className="mr-1 text-gray-7">{item?.key}</span>
                <span className="text-gray-8">{item?.desc || item?.name}</span>
              </p>
              {onClickDeleteBtn && (
                <button className="flex items-center ml-2" onClick={() => onClickDelete(item)}>
                  <XCircleIcon color={colors.gray[4]}></XCircleIcon>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

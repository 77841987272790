import React, { SVGProps } from 'react';

const SvgProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 25 25" {...props}>
    <g clipPath="url(#profile-icon_svg__a)">
      <path fill="#D9D9D9" d="M0 0h25v25H0z" />
      <path
        fill="#F5F5F5"
        fillRule="evenodd"
        d="M12.793 11.207a4.103 4.103 0 1 0 0-8.207 4.103 4.103 0 0 0 0 8.207ZM12.5 26.448c4.694 0 8.5-3.018 8.5-6.741s-3.806-6.742-8.5-6.742S4 15.985 4 19.707s3.806 6.741 8.5 6.741Z"
        clipRule="evenodd"
      />
      <circle cx={12.5} cy={12.5} r={11.5} stroke="#D9D9D9" strokeWidth={2} />
    </g>
    <defs>
      <clipPath id="profile-icon_svg__a">
        <rect width={25} height={25} fill="#fff" rx={12.5} />
      </clipPath>
    </defs>
  </svg>
);
SvgProfileIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgProfileIcon;

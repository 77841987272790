import React, { ReactNode, forwardRef, useEffect, useRef, useState } from 'react';

import { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import { ChevronLeftIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/bottomSheetPopupStyle.css';

interface BottomSheetPopup {
  isOpen: boolean;
  onClose: () => void;
  canClose?: () => boolean;
  title?: string;
  iconTitle?: ReactNode;
  children?: ReactNode;
  isFullHeight?: boolean;
  heightPercentage?: number;
  isBackdrop?: boolean;
  isXMarkVisible?: boolean;
  isPrevBtnVisible?: boolean;
  icons?: MenuHeaderIcon[];
  onClear?: () => void;
  onScroll?: () => void;
}

const BottomSheetPopup = forwardRef<HTMLDivElement, BottomSheetPopup>(
  (
    {
      isOpen,
      onClose,
      canClose,
      title,
      iconTitle,
      children,
      isFullHeight,
      heightPercentage = 100,
      isBackdrop = true,
      isXMarkVisible = true,
      isPrevBtnVisible = false,
      icons,
      onClear,
      onScroll,
    },
    ref,
  ) => {
    const popupElementRef = useRef<HTMLDivElement | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isClosingPopup, setIsClosingPopup] = useState(false);

    const closePopup = async () => {
      if (isClosingPopup) return;

      if (canClose && !canClose()) {
        return;
      }

      setIsClosingPopup(true);
      onClear?.();

      setTimeout(() => {
        setShowPopup(false);
        setIsClosingPopup(false);
        onClose();
      }, 200);
    };

    useEffect(() => {
      if (!!isOpen) {
        setShowPopup(true);
      } else {
        closePopup();
      }
    }, [isOpen]);

    useEffect(() => {
      document.body.style.overflow = showPopup ? 'hidden' : 'auto';

      const popupElement = popupElementRef.current;
      if (!popupElement) return;

      const handleAnimationEnd = () => {
        if (!showPopup) onClose();
      };

      popupElement.addEventListener('animationend', handleAnimationEnd);
      return () => popupElement?.removeEventListener('animationend', handleAnimationEnd);
    }, [showPopup]);

    return (
      <>
        {showPopup && (
          <div>
            {!!isBackdrop && (
              <div
                className={`fixed top-0 left-0 z-40 inline-flex items-center justify-center w-full h-full bg-black ${
                  isClosingPopup ? 'fadeOutBackdrop' : 'fadeInBackdrop'
                }`}
              ></div>
            )}

            <div
              ref={popupElementRef}
              className={`${showPopup ? 'fadeInUpObj' : ''} ${
                isClosingPopup ? 'fadeOutObj' : ''
              } fixed z-50 bottom-0 w-full rounded-t-xl bg-white flex flex-col items-center justify-center shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px]`}
              style={{
                maxHeight: `calc(${heightPercentage}dvh - 60px)`,
              }}
            >
              <div
                className={`w-full rounded-t-xl max-w-[720px] min-w-[280px] ${
                  isFullHeight ? 'h-[calc(100dvh-60px)]' : ''
                }`}
                style={{
                  maxHeight: `calc(${heightPercentage}dvh - 60px)`,
                }}
              >
                <div className="sticky py-4">
                  {isXMarkVisible && (
                    <div className="absolute top-0 right-0 m-4 cursor-pointer" onClick={closePopup}>
                      <XMarkIcon color={colors.gray[9]}></XMarkIcon>
                    </div>
                  )}

                  {icons && (
                    <div className="absolute top-0 right-0 flex items-center gap-2 m-4 cursor-pointer">
                      {icons.map((icon, index) => (
                        <div key={index}>
                          <div onClick={icon.onClickIcon} className="flex items-center gap-1 cursor-pointer">
                            {icon.icon}
                            {icon.text && <span className="text-[14px] text-gray-8">{icon.text}</span>}
                          </div>
                          {icon.text && index < icons.length - 1 && <span className="text-gray-4"> | </span>}
                        </div>
                      ))}
                    </div>
                  )}

                  {isPrevBtnVisible && (
                    <div className="absolute top-0 left-0 flex items-center gap-2 m-4 cursor-pointer">
                      <div className="flex items-center justify-center mr-4 cursor-pointer" onClick={closePopup}>
                        <ChevronLeftIcon color={colors.gray[9]}></ChevronLeftIcon>
                      </div>
                    </div>
                  )}

                  {title && <h4 className="font-bold text-center text-gray-8">{title}</h4>}
                  {iconTitle && iconTitle}
                </div>
                <div
                  className={`overflow-y-auto`}
                  ref={ref}
                  style={{
                    maxHeight: `calc(${heightPercentage}dvh - 112px)`,
                  }}
                  onScroll={onScroll}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  },
);

export default BottomSheetPopup;

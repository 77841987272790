import React, { useEffect, useState } from 'react';

import '@/css/toastStyle.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ToastType {
  id: number;
  message: string;
  type: string;
  position: string;
  onClose: () => void;
}

const Toast = ({ id, message, type, position, onClose }: ToastType) => {
  const [isClosingPopup, setIsClosingPopup] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => setIsClosingPopup(true), 1600);
    const closeTimer = setTimeout(() => onClose(), 1800);

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(closeTimer);
    };
  }, [id, onClose]);

  const getStyle = () => {
    switch (type) {
      case 'error':
        return 'bg-red';
      case 'success':
        return 'bg-gray-8';
      default:
        return '';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorOutlineIcon />;
      case 'success':
        return <CheckCircleIcon />;
      default:
        return null;
    }
  };

  const getPosition = () => {
    switch (position) {
      case 'top':
        return 'top-10';
      case 'bottom':
      default:
        return 'bottom-20';
    }
  };

  return (
    <div
      className={`toast fixed z-[1500] left-0 right-0 mx-auto my-0 px-4 max-w-[720px] flex justify-center ${getPosition()}
      ${isClosingPopup ? 'fadeOut' : 'fadeIn'}
      `}
    >
      <div
        className={`flex items-center text-white opacity-90 rounded-lg py-2 px-3 ${getStyle()} w-auto inline text-[16px] leading-[19px] sm280:text-[14px] sm280:leading-[17px] break-keep`}
      >
        {getIcon()}
        <span className="ml-2">{message}</span>
      </div>
    </div>
  );
};

export default Toast;

import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { createCapitalCounselServices } from '@/api/AdditionalServices/createAdditionalServices';
import { fetchChatRoomList } from '@/api/chat/getChat';
import BasicButton from '@/components/Common/Button/BasicButton';
import ConfirmModal from '@/components/Common/Modal/ConfirmModal';
import MenuHeader from '@/components/Header/MenuHeader';
import { PhoneIcon } from '@/components/Icon';
import ApplyCapitalServiceForm from '@/components/More/CaptialCounselService/ApplyCapitalServiceForm';
import CapitalGuideView from '@/components/More/CaptialCounselService/CapitalGuideView';
import { IS_ALREADY_APPLY_CAPITAL_COUNSEL_SERVICE } from '@/const/additionalService';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import useNavigateBack from '@/hooks/useNavigateBack';
import { memberAtom, updateAdditionalServiceAtom } from '@/store/member';
import { phoneCall } from '@/utils/common';
import { Fab } from '@mui/material';

const CapitalCounselService = () => {
  const [memberAtomData] = useAtom(memberAtom);
  const [, updateAdditionalService] = useAtom(updateAdditionalServiceAtom);

  const [isAlreadyApply, setIsAlreadyApply] = useState(false);

  const [isNearBottom, setIsNearBottom] = useState(false);
  const threshold = 20;

  const { showToast } = useToastContext();

  const authRedirect = useAuthRedirect();

  const navigateBack = useNavigateBack();
  const location = useLocation();
  const productInfo = location.state?.productInfo;

  const productPrice = location.state?.productPrice;

  const PurchaseAccompanyingServiceRef = useRef<HTMLDivElement>(null);

  const [isPopupShow, setIsPopupShow] = useState(false);
  const [requestForm, setRequestForm] = useState<AdditionalServicesApplyForm>();
  const [isDisabledRequestButton, setIsDisabledRequestButton] = useState(true);

  const updateRequestForm = (formData: AdditionalServicesApplyForm) => {
    setRequestForm(formData);
  };

  const {
    data: chattingList,
    isLoading,
    isFetching,
  } = useQuery<ChattingListResponse[]>(['get-chat-room-list'], () => fetchChatRoomList(), {
    onSuccess: () => {},
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!memberAtomData?.id,
  });

  useEffect(() => {
    if (memberAtomData) {
      setRequestForm({
        ...requestForm,
        name: { value: memberAtomData?.name, error: false },
        phoneNumber: { value: memberAtomData?.phoneNumber, error: false },
        productId: productInfo?.productId,
        chatRoomId: productInfo?.chatRoomId,
        truckName: productInfo?.truckName,
      });
      setIsAlreadyApply(memberAtomData.isAlreadyApplyCapitalCounselService);
    }
  }, [memberAtomData, productInfo]);

  const handleScrollBottom = () => {
    const currentScroll = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (currentScroll + viewportHeight < documentHeight) {
      window.scrollBy({
        top: viewportHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + viewportHeight >= documentHeight - threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  const handleClickApplyService = () => {
    if (!authRedirect('/capital-counsel-service')) {
      return;
    }
    setIsPopupShow(true);
  };

  const handleClickClosePopup = () => {
    setIsPopupShow(false);
  };

  const handleClickOkBtn = () => {
    if (requestForm) {
      const requestData = {
        name: requestForm.name?.value,
        phoneNumber: requestForm.phoneNumber?.value,
        productId: requestForm.productId,
      };
      postCapitalCounselServices.mutate(requestData);
      setIsPopupShow(false);
    }
  };

  const postCapitalCounselServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createCapitalCounselServices(request),
    {
      onSuccess: () => {
        showToast('화물차 대출 상담 서비스를 신청했어요.', 'success', 'bottom');
        setIsAlreadyApply(true);
        updateAdditionalService(IS_ALREADY_APPLY_CAPITAL_COUNSEL_SERVICE);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickPrev = () => {
    navigateBack();
  };

  useEffect(() => {
    setIsDisabledRequestButton(requestForm?.name?.error || requestForm?.phoneNumber?.error || false);
  }, [requestForm?.name?.error || requestForm?.phoneNumber?.error, isLoading]);

  return (
    <>
      <MenuHeader title="화물차 대출 상담 서비스" onClickPrevBtn={handleClickPrev}></MenuHeader>

      <div className="w-full pt-[60px] pb-[100px]" ref={PurchaseAccompanyingServiceRef}>
        <CapitalGuideView price={productPrice || 5000} />
        <ApplyCapitalServiceForm
          isShowVehicleSelector={(chattingList && chattingList?.length > 0) || false}
          formData={requestForm || {}}
          updateFormData={(data: AdditionalServicesApplyForm) => updateRequestForm(data)}
        />

        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
          <div className="absolute right-[16px] top-[-72px]">
            <Fab
              sx={{
                backgroundColor: colors.gray[0],
                width: 52,
                height: 52,
                borderRadius: '50px',
                '&:hover': {
                  backgroundColor: colors.gray[0],
                },
                zIndex: 100,
              }}
              onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
            >
              <PhoneIcon color={colors.primary}></PhoneIcon>
            </Fab>
          </div>

          <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
          <BasicButton
            name={isAlreadyApply ? '한도 조회 신청완료' : isNearBottom ? '한도 조회 신청하기' : '아래로 내리기'}
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight={'bold'}
            isDisabled={isAlreadyApply || (isNearBottom && isDisabledRequestButton)}
            onClick={isNearBottom ? handleClickApplyService : handleScrollBottom}
          />
        </div>
      </div>

      <ConfirmModal
        isOpen={isPopupShow}
        title={`${!!requestForm?.productId ? requestForm?.truckName : ''}`}
        content={
          <span>
            {requestForm?.productId ? (
              <>
                선택한 차량으로 화물차 대출 상담
                <br />
                서비스를 신청할까요?
              </>
            ) : (
              <>
                화물차 대출 상담 서비스를
                <br />
                신청하시겠어요?
              </>
            )}
          </span>
        }
        rightButtonLabel="신청하기"
        onLeftButtonClick={handleClickClosePopup}
        onRightButtonClick={handleClickOkBtn}
      />
    </>
  );
};

export default CapitalCounselService;
